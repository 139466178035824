import { FC, useState, useEffect } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import BillTableItem from "./BillTableItem";
import SortableTableHead from "components/SortableTableHead";
import DialogEditBill from "../DialogEditBill";

import comparatorSortByProperty from "utils/comparatorSortByProperty";
import stableSort from "utils/stableSort";
import { UserBill } from "types/Billing";
import { Order } from "types/SemanticTypes";

type UserBillKeys = keyof UserBill;

interface HeadCells {
  label: string;
  id: UserBillKeys;
  tableCellProps?: TableCellProps;
}

const tableCellProps: TableCellProps = {
  width: 104,
};

const headCells: HeadCells[] = [
  {
    label: "Emissão",
    id: "issueDate",
    tableCellProps,
  },
  {
    label: "Vencimento",
    id: "dueDate",
    tableCellProps,
  },
  {
    label: "Pagamento",
    id: "payDate",
    tableCellProps,
  },
  {
    label: "Nome",
    id: "name",
  },
  {
    label: "Descrição",
    id: "description",
  },
  {
    label: "Método",
    id: "paymentMethod",
  },
  {
    label: "Status",
    id: "status",
  },
  {
    label: "Valor",
    id: "value",
  },
];

interface BillTableProps {
  usersBills: UserBill[];
  companyToken: string;
}

const BillTable: FC<BillTableProps> = ({ usersBills, companyToken }) => {
  const [edittedBill, setEdittedBill] = useState<null | UserBill>(null);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<UserBillKeys>("name");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortHandler = (property: UserBillKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (Math.ceil(usersBills.length / rowsPerPage) > page ? 0 : page) {
      setPage(0);
    }
  }, [usersBills, page, rowsPerPage]);

  return (
    <>
      <TableContainer>
        <Table size="small">
          <SortableTableHead
            headCells={headCells}
            onRequestSort={(e, property) => sortHandler(property)}
            order={order}
            orderBy={orderBy}
          >
            <TableCell align="center"></TableCell>
          </SortableTableHead>

          <TableBody>
            {usersBills.length === 0 && (
              <TableRow sx={{ width: "100%" }}>
                <TableCell colSpan={headCells.length + 1}>
                  <Typography variant="body1">Nenhum resultado encontrado</Typography>
                </TableCell>
              </TableRow>
            )}

            {stableSort(usersBills, comparatorSortByProperty(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((userBill) => (
                <BillTableItem
                  userBill={userBill}
                  key={userBill.id}
                  onEdit={() => setEdittedBill(userBill)}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {edittedBill && (
        <DialogEditBill
          open
          onClose={() => setEdittedBill(null)}
          bill={edittedBill}
          companyToken={companyToken}
        />
      )}

      <TablePagination
        labelRowsPerPage={"Linhas por páginas: "}
        labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={usersBills.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default BillTable;
