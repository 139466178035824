import * as yup from "yup";
import { formattedInput } from "components/CurrencyInput";
import { DateTime } from "luxon";

const validationSchema = yup.object().shape({
  accountSource: yup
    .number()
    .required("A conta de origem é obrigatória")
    .typeError("A conta de origem é obrigatória"),
  balance: yup.number().nullable(true).notRequired(),
  accountDestination: yup
    .number()
    .required("A conta de destino é obrigatória")
    .typeError("A conta de destino é obrigatória")
    .notOneOf(
      [yup.ref("accountSource"), null],
      "A conta de destino não deve ser igual a conta de origem",
    ),
  amount: yup
    .string()
    .required("O valor da transferência é obrigatória")
    .test("validNumber", "Digite um valor válido", (v) => {
      if (!v) return true;
      return !!formattedInput(v);
    }),
  date: yup
    .date()
    .required("A data da transferência é obrigatória")
    .max(DateTime.local().endOf("day"), "A data não pode ser no futuro")
    .typeError("Digite uma data válida"),
  globalCategoryId: yup.string().required("A categoria global é obrigatória"),
});

export default validationSchema;
