type SvgProps = React.FC<React.SVGProps<SVGSVGElement>>;

const AnimationLoading: SvgProps = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={150}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <g transform="translate(25 50)">
      <circle r={7} fill="#009221">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.3401360544217687s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.0204081632653061s"
          repeatCount="indefinite"
        />
      </circle>
    </g>
    <g transform="translate(50 50)">
      <circle r={7} fill="#009221">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.17006802721088435s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.0204081632653061s"
          repeatCount="indefinite"
        />
      </circle>
    </g>
    <g transform="translate(75 50)">
      <circle r={7} fill="#009221">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="0s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.0204081632653061s"
          repeatCount="indefinite"
        />
      </circle>
    </g>
  </svg>
);

export default AnimationLoading;
