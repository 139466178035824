import { Controller, Control, useWatch, Path } from "react-hook-form";

import CategorySelect from "../../CategorySelect";
import CustomCategorySelect from "../../CustomCategorySelect";

interface RequiredFields {
  globalCategoryId: number | null;
  categoryId: number | null;
}

interface CategoryFormProps<T extends RequiredFields = RequiredFields> {
  control: Control<T>;
  companyToken: string;
}

function CategoryForm<T extends RequiredFields = RequiredFields>({
  companyToken,
  control,
}: CategoryFormProps<T>) {
  const globalCategoryId = useWatch({
    control,
    name: "globalCategoryId" as Path<T>,
  });

  return (
    <>
      <Controller
        control={control}
        name={"globalCategoryId" as Path<T>}
        render={({ fieldState, field }) => (
          <CategorySelect
            label="Categoria principal"
            companyToken={companyToken}
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name={"categoryId" as Path<T>}
        render={({ fieldState, field }) => (
          <CustomCategorySelect
            label="Categoria personalizada"
            parentId={globalCategoryId}
            companyToken={companyToken}
            withCreateCategory
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </>
  );
}

export default CategoryForm;
