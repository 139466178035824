import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import FileTableItem from "./FileTableItem";
import SortableTableHead from "components/SortableTableHead";

import comparatorSortByProperty from "utils/comparatorSortByProperty";
import { LatestTransactions } from "types/Dashboard";
import stableSort from "utils/stableSort";
import { Order } from "types/SemanticTypes";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";

type FileKeys = keyof LatestTransactions;

interface HeadCells {
  label: string;
  id: FileKeys;
  sortable?: boolean;
}

const headCells: HeadCells[] = [
  {
    label: "Pgto/Venc",
    id: "referenceDate",
    sortable: true,
  },
  {
    label: "Categoria",
    id: "categoryName",
    sortable: false,
  },
  {
    label: "Beneficiário",
    id: "participantName",
    sortable: false,
  },
  {
    label: "Descrição",
    id: "description",
    sortable: false,
  },
  {
    label: "Conta",
    id: "companyBankAccountId",
    sortable: false,
  },
  {
    label: "Valor (R$)",
    id: "value",
    sortable: false,
  },
  {
    label: "Status",
    id: "transactionType",
    sortable: false,
  },
];

interface FilesTableProps {
  files: LatestTransactions[];
  selectedFileId: number[];
  onSelectAllClick?: (event: ChangeEvent<HTMLInputElement>) => void;
  onToggleSelected: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    fileId: number,
  ) => void;
  companyToken: string;
}

const FilesTable: FC<FilesTableProps> = ({
  files,
  selectedFileId,
  onToggleSelected,
  onSelectAllClick,
  companyToken,
}) => {
  const { data: banks } = useQueryGetCompanyBankAccounts(companyToken);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<FileKeys>("referenceDate");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortHandler = (property: FileKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (Math.ceil(files.length / rowsPerPage) > page ? 0 : page) {
      setPage(0);
    }
  }, [files, page, rowsPerPage]);

  const getBankName = useCallback(
    (id: number) => {
      const bank = banks?.find((b) => b.id === id);
      return bank ? bank.alias : "";
    },
    [banks],
  );

  return (
    <>
      <TableContainer>
        <Table size="small">
          <SortableTableHead
            headCells={headCells}
            onRequestSort={(e, property) => sortHandler(property)}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={onSelectAllClick}
            rowsCount={files.length}
            rowsSelected={selectedFileId.length}
          />

          <TableBody>
            {files.length === 0 && (
              <TableRow sx={{ width: "100%" }}>
                <TableCell colSpan={headCells.length}>
                  <Typography variant="body1">Nenhum resultado encontrado</Typography>
                </TableCell>
              </TableRow>
            )}

            {stableSort(files, comparatorSortByProperty(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((file) => (
                <FileTableItem
                  key={file.id}
                  file={file}
                  selected={selectedFileId.includes(file.id)}
                  onToggleSelected={(e, checked) => onToggleSelected(e, checked, file.id)}
                  getBankName={getBankName}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage={"Linhas por páginas: "}
        labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={files.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default FilesTable;
