import { FC } from "react";
import useQueryCompanyPartners from "pages/Home/hooks/useQueryCompanyPartners";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";

import { Order } from "types/OpenCompany";
import PartnerForm from "pages/Home/pages/PartnerForm";
import { Box, CircularProgress, Stack } from "@mui/material";

interface PartnerProps {
  order: Order;
}

const Partner: FC<PartnerProps> = ({ order }) => {
  const { data: company, isLoading: loadingCompany } = useQueryGetCompany(order.companyToken);
  const { data: partners, isLoading: loadingPartners } = useQueryCompanyPartners(
    order.companyToken,
  );
  const isLoading = loadingCompany || loadingPartners;

  return (
    <Box>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}
      {company && partners && !isLoading && (
        <PartnerForm companyInformation={company} partners={partners} />
      )}
    </Box>
  );
};

export default Partner;
