import * as yup from "yup";

const validationSchema = yup.object().shape({
  description: yup
    .string()
    .required("A descrição é obrigatória")
    .max(200, "A descrição é no máximo 200 caracteres"),
  account: yup
    .string()
    .required("A conta analítica é obrigatória")
    .max(30, "A descrição é no máximo 30 caracteres"),
  fromDomain: yup
    .string()
    .required("A conta reduzida é obrigatória")
    .max(10, "A descrição é no máximo 10 caracteres"),
});

export default validationSchema;
