import { FC, useState } from "react";
import { useAuth } from "context/Auth";

import { Box, Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import Head from "components/Head";
import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import UsersControl from "./UsersControl";
import DialogCreateOrUpdateAdminUser from "pages/Home/components/DialogCreateOrUpdateAdminUser";

const AdminUsers: FC = () => {
  const {
    user: { company },
  } = useAuth();
  const [createIsOpen, setCreateIsOpen] = useState(false);

  return (
    <>
      <Head title={`Administração de usuários | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Administração de usuários
        </Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon fontSize="small" />}
          disabled={!company}
          onClick={() => setCreateIsOpen(true)}
        >
          Novo usuário
        </Button>
      </Stack>

      <Box boxShadow={1} mt={2}>
        {company ? (
          <UsersControl companyToken={company.token} />
        ) : (
          <Typography variant="body2">{MESSAGE_NO_COMPANY}</Typography>
        )}
      </Box>

      {company && (
        <DialogCreateOrUpdateAdminUser
          open={createIsOpen}
          onClose={() => setCreateIsOpen(false)}
          companyToken={company.token}
          userType="Guest"
        />
      )}
    </>
  );
};

export default AdminUsers;
