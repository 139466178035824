import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { corrections } from "services/Requests/stockControl";

const useQueryInvoiceCorrection = (companyToken: string, movementId: number) => {
  const query = useQuery([queryKeys.invoiceCorrections, companyToken, movementId], () =>
    corrections({ companyToken, movementId }),
  );
  return query;
};

export default useQueryInvoiceCorrection;
