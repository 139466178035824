import { useMutation, useQueryClient } from "react-query";
import useInvalidateDashboardQueries from "../../Dashboard/hooks/useInvalidateDashboardQueries";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { createOrUpdateTransactionOFX } from "services/Requests/companies";

const useMutationCreateOrUpdateTransaction = (disabledToast = false) => {
  const queryClient = useQueryClient();
  const invalidate = useInvalidateDashboardQueries();

  const mutation = useMutation(createOrUpdateTransactionOFX, {
    onSuccess() {
      if (!disabledToast) {
        toast.success("Operação realizada com sucesso");
      }
      queryClient.invalidateQueries(queryKeys.transactionsSearch, { exact: false });
      queryClient.invalidateQueries(queryKeys.transactions, { exact: false });
      invalidate();
    },
    onError() {
      if (!disabledToast) {
        toast.error("Ocorreu um error inesperado na operação");
      }
    },
  });
  return mutation;
};

export default useMutationCreateOrUpdateTransaction;
