import { FC } from "react";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import { ImportClient } from "../../../../../types/Client";

interface TableResultImportProps {
  importClientsResult: ImportClient[];
}

const TableResultImport: FC<TableResultImportProps> = ({ importClientsResult }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Email</TableCell>
            <TableCell>Empresa</TableCell>
            <TableCell>CNPJ</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {importClientsResult.map((importClient, index) => (
            <TableRow
              key={`${importClient.name}-${index}`}
              sx={{
                "&:last-child td": {
                  border: 0,
                },
              }}
            >
              <TableCell>
                {importClient.status === "ok" ? (
                  <CheckIcon color="success" />
                ) : (
                  <Clear color="error" />
                )}
              </TableCell>

              <TableCell>{importClient.email || "-"}</TableCell>
              <TableCell>{importClient.name || "-"}</TableCell>
              <TableCell>{importClient.cnpj || "-"}</TableCell>
              <TableCell>
                {importClient.status === "ok" ? "Cadastrado com sucesso" : importClient.status}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableResultImport;
