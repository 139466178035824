import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getConfigurationsMonthClosure } from "services/Requests/configuration";
import getSubdomain from "utils/getSubdomain";

const useQueryGetMonthClosure = () => {
  const subdomain = getSubdomain();

  const query = useQuery(queryKeys.monthClosureConfiguration, () =>
    getConfigurationsMonthClosure({ subdomain }),
  );
  return query;
};

export default useQueryGetMonthClosure;
