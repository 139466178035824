import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getPenaltiesAndDiscounts } from "services/Requests/billing";
import getSubdomain from "utils/getSubdomain";

const useQueryGetPenaltiesAndDiscounts = (companyToken: string) => {
  const subdomain = getSubdomain();

  const query = useQuery(queryKeys.penaltiesAndDiscounts, () =>
    getPenaltiesAndDiscounts({ companyToken, subdomain }),
  );
  return query;
};

export default useQueryGetPenaltiesAndDiscounts;
