import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getRevenuesVsExpenses, GetRevenuesVsExpensesPayload } from "services/Requests/dashboard";

const useQueryRevenuesVsExpenses = (payload: GetRevenuesVsExpensesPayload) => {
  const query = useQuery(
    [queryKeys.revenuesVsExpenses, payload.provision, payload.startDate, payload.endDate],
    () => getRevenuesVsExpenses(payload),
    {
      staleTime: 1000 * 60 * 2, // 2 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
    },
  );
  return query;
};

export default useQueryRevenuesVsExpenses;
