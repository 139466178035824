import { FC } from "react";
import useQueryGetTransaction from "../hooks/useQueryGetTransaction";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import ListComponent from "components/ListComponent";

import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { DateTime } from "luxon";

interface DialogTransactionProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  transactionId: number;
  onClose: () => void;
}

const DialogTransaction: FC<DialogTransactionProps> = ({
  companyToken,
  transactionId,
  onClose,
  ...props
}) => {
  const { data: transaction, isLoading } = useQueryGetTransaction(companyToken, transactionId);

  return (
    <Dialog onClose={onClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={onClose}>
        {isLoading && "Carregando..."}
        {transaction && transaction.participantName}
      </DialogTitleWithCloseButton>

      <DialogContent>
        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={464}>
            <CircularProgress />
          </Stack>
        )}

        {transaction && (
          <Box component="ul">
            <ListComponent>
              <Typography fontWeight={600}>Tipo de lançamento</Typography>
              <Typography>
                {transaction.transactionType === "Credit" ? "Crédito" : "Débito"}
              </Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Valor</Typography>
              <Typography>{formatCurrencyBRL(transaction.value)}</Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Emitida em</Typography>
              <Typography>
                {transaction.dateEmission
                  ? DateTime.fromISO(transaction.dateEmission).toFormat("dd/MM/yyyy")
                  : "N/D"}
              </Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Vence em</Typography>
              <Typography>
                {transaction.dueDate
                  ? DateTime.fromISO(transaction.dueDate).toFormat("dd/MM/yyyy")
                  : "N/D"}
              </Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Data de competência</Typography>
              <Typography>
                {transaction.referenceDate
                  ? DateTime.fromISO(transaction.referenceDate).toFormat("dd/MM/yyyy")
                  : "N/D"}
              </Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>
                {transaction.transactionType == "Credit" ? "Recebido" : "Pago"}
              </Typography>
              <Typography>
                {transaction.paymentDate
                  ? DateTime.fromISO(transaction.paymentDate).toFormat("dd/MM/yyyy")
                  : "N/D"}
              </Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Categoria</Typography>
              <Typography>{transaction.categoryName}</Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Cliente</Typography>
              <Typography>{transaction.participantName}</Typography>
            </ListComponent>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button startIcon={<CloseIcon />} variant="contained" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogTransaction;
