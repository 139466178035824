import { FC } from "react";
import useQueryGetTransaction from "pages/Home/pages/MonthClosure/hooks/useQueryGetTransaction";

import { CircularProgress, Dialog, DialogContent, DialogProps, Stack } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import DialogForm from "./DialogForm";

interface DownloadProvisioningDialogProps extends Omit<DialogProps, "onClose" | "id"> {
  id: number;
  companyToken: string;
  onClose: () => void;
}

const DownloadProvisioningDialog: FC<DownloadProvisioningDialogProps> = ({
  onClose,
  companyToken,
  id,
  ...props
}) => {
  const { data: transaction, isLoading } = useQueryGetTransaction(companyToken, id);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Baixar Provisionamento
      </DialogTitleWithCloseButton>

      {isLoading && (
        <DialogContent>
          <Stack alignItems="center" justifyContent="center" height={300}>
            <CircularProgress />
          </Stack>
        </DialogContent>
      )}

      {transaction && (
        <DialogForm onClose={handleClose} transaction={transaction} companyToken={companyToken} />
      )}
    </Dialog>
  );
};

export default DownloadProvisioningDialog;
