import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDashboardPeriod } from "../contexts/DashboardPeriod";
import useQueryGetLatestTransactions from "../hooks/useQueryGetLatestTransactions";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";

import {
  Box,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import CardWrapper from "../../Profile/components/CardWrapper";
import DialogConsultTransaction from "./DialogConsultTransaction";

import { DateTime } from "luxon";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import getOrderStatusLabel from "pages/Home/utils/getOrderStatusLabel";
import getLabelStatus from "pages/Home/utils/getLabelStatus";
import getInvoiceStatusColor from "pages/Home/utils/getInvoiceStatusColor";
import { INVOICE_BASE_URL } from "utils/constants";
import getOrderColorByStatus from "pages/Home/utils/getOrderColorByStatus";
import getStatusColor from "pages/Home/utils/getStatusColor";

interface LatestTransactionsProps {
  companyToken: string;
}

const LatestTransactions: FC<LatestTransactionsProps> = ({ companyToken }) => {
  const { date } = useDashboardPeriod();
  const navigate = useNavigate();

  const { data: bankAccounts } = useQueryGetCompanyBankAccounts(companyToken);
  const {
    data: latestTransactions,
    isLoading,
    isFetching,
  } = useQueryGetLatestTransactions(companyToken, date.start, date.end, 5);

  const [consultTransactionId, setConsultTransactionId] = useState<number | null>(null);

  const getBankName = (bankID: number) => {
    if (!bankID || !bankAccounts) return "-";

    return bankAccounts.find((bank) => bank.id === bankID)?.alias || "-";
  };

  return (
    <CardWrapper title="Últimos lançamentos">
      <Collapse in={isFetching && !isLoading}>
        <LinearProgress />
      </Collapse>

      <Box p={2}>
        {isLoading && (
          <Stack height={300} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {latestTransactions && (
          <Table
            size="small"
            sx={{ opacity: isFetching && !isLoading ? 0.5 : 1, transition: "opacity 500ms ease" }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Categoria</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Conta</TableCell>
                <TableCell align="right">Valor</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>

            <TableBody>
              {latestTransactions.map((transaction) => (
                <TableRow key={transaction.id} hover>
                  <TableCell>
                    {DateTime.fromISO(transaction.dueDate).toFormat("dd/MM/yyyy")}
                  </TableCell>
                  <TableCell>{transaction.categoryName || "-"}</TableCell>
                  <TableCell>{transaction.description || "-"}</TableCell>
                  <TableCell>{getBankName(transaction.companyBankAccountId)}</TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color:
                        transaction.transactionType === "Credit" ? "success.main" : "error.main",
                    }}
                  >
                    {formatCurrencyBRL(transaction.value)}
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" flexWrap="wrap" gap={0.5}>
                      <Chip
                        size="small"
                        label={getLabelStatus(transaction) + " "}
                        color={getStatusColor(transaction)}
                      />
                      {transaction.isInvoice && (
                        <Tooltip title={transaction.invoiceStatus}>
                          <a
                            href={transaction.pathInvoice || undefined}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Chip
                              size="small"
                              color={getInvoiceStatusColor(transaction.invoiceStatus)}
                              clickable={!!transaction.pathInvoice}
                              label={"NFS-e "}
                            />
                          </a>
                        </Tooltip>
                      )}
                      {transaction.orderId && (
                        <Tooltip title={getOrderStatusLabel(transaction.orderStatus)}>
                          <a
                            href={
                              transaction.secureId
                                ? INVOICE_BASE_URL + transaction.secureId
                                : undefined
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Chip
                              color={getOrderColorByStatus(transaction.orderStatus)}
                              size="small"
                              clickable={typeof transaction.secureId === "string"}
                              label={"Fatura "}
                            />
                          </a>
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell align="right" onClick={() => setConsultTransactionId(transaction.id)}>
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {latestTransactions && latestTransactions.length === 0 && (
          <Typography>Nenhum lançamento cadastrado no período.</Typography>
        )}

        <Stack alignItems="flex-end" mt={1}>
          <Link
            href="/lancamentos"
            onClick={(e) => {
              e.preventDefault();
              navigate("/lancamentos");
            }}
          >
            Ver mais
          </Link>
        </Stack>
      </Box>

      {latestTransactions && latestTransactions.length !== 0 && (
        <DialogConsultTransaction
          open={!!consultTransactionId}
          id={consultTransactionId || latestTransactions[0].id}
          onClose={() => setConsultTransactionId(null)}
          keepMounted={false}
          companyToken={companyToken}
        />
      )}
    </CardWrapper>
  );
};

export default LatestTransactions;
