import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

import queryKeys from "react-query/constants";
import { AxiosError } from "axios";
import { createConfiguration } from "services/Requests/invoice";

const useMutationCreateConfiguration = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createConfiguration, {
    onSuccess() {
      toast.success("Nota fiscal configurada com sucesso!");
      queryClient.invalidateQueries(queryKeys.invoiceConfiguration);
      queryClient.invalidateQueries(queryKeys.prefectureByCompany);
      queryClient.invalidateQueries(queryKeys.municipalServiceByCompany);
    },
    onError(error: AxiosError<string>) {
      const errorMessage =
        typeof error?.response?.data === "string"
          ? error?.response?.data
          : "Ocorreu um erro ao configurar a nota fiscal!";
      toast.error(errorMessage);
    },
  });
  return mutation;
};

export default useMutationCreateConfiguration;
