import { FC, useCallback, useEffect, useState, useRef } from "react";

import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import { DateTime } from "luxon";
import { ISODate, Order } from "types/SemanticTypes";
import { TransactionAction } from "services/Requests/companies";
import { TransactionsNormalize, TransactionsItemNormalize } from "types/Company";

import comparatorSortByProperty from "utils/comparatorSortByProperty";
import stableSort from "utils/stableSort";
import getLabelStatus from "pages/Home/utils/getLabelStatus";

import SortableTableHead from "components/SortableTableHead";
import ReleasesTableItem from "./ReleasesTableItem";
import MassActions from "./MassActions";
import ProvisionedTable from "./ProvisionedTable";
import AccomplishedTable from "./AccomplishedTable";
import TableActions from "pages/Home/components/TableActions";
import InfoToPrint from "pages/Home/components/InfoToPrint";
import PrintLandscape from "components/PrintLandscape";

type TransactionItemKeys = keyof TransactionsItemNormalize;

interface HeadCells {
  label: string;
  id: TransactionItemKeys;
  sortable?: boolean;
  tableCellProps?: TableCellProps | Record<string, string>;
}

const headCells: HeadCells[] = [
  {
    label: "Data",
    id: "dueDate",
    tableCellProps: { ["data-f-bold"]: "true", ["data-a-h"]: "center" },
  },
  {
    label: "Competência",
    id: "referenceDate",
    tableCellProps: { ["data-f-bold"]: "true", ["data-a-h"]: "center" },
  },
  {
    label: "Categoria",
    id: "categoryName",
    tableCellProps: { ["data-f-bold"]: "true", ["data-a-h"]: "center" },
  },
  {
    label: "Beneficiário",
    id: "recipient",
    tableCellProps: { ["data-f-bold"]: "true", ["data-a-h"]: "center" },
  },
  {
    label: "Descrição",
    id: "description",
    tableCellProps: { ["data-f-bold"]: "true", ["data-a-h"]: "center" },
  },
  {
    label: "Conta",
    id: "companyBankAccountId",
    sortable: false,
    tableCellProps: { ["data-f-bold"]: "true", ["data-a-h"]: "center" },
  },
  {
    label: "Valor",
    id: "value",
    sortable: false,
    tableCellProps: {
      sx: { "@media print": { fontSize: "10px" } },
      align: "right",
      ["data-a-h"]: "center",
      ["data-f-bold"]: "true",
    },
  },
  {
    label: "Saldo",
    id: "type",
    sortable: false,
    tableCellProps: {
      sx: { "@media print": { fontSize: "10px" } },
      align: "right",
      ["data-a-h"]: "center",
      ["data-f-bold"]: "true",
    },
  },
  {
    label: "NF",
    id: "parcel",
    sortable: false,
    tableCellProps: {
      sx: { "@media print": { fontSize: "10px" } },
      align: "right",
      ["data-a-h"]: "center",
      ["data-f-bold"]: "true",
    },
  },
  {
    label: "Status",
    id: "invoiceStatus",
    sortable: false,
    tableCellProps: { ["data-f-bold"]: "true" },
  },
];

interface ReleasesTableProps {
  transactions: TransactionsNormalize;
  companyToken: string;
  currentPage: number;
  onPageChange: (newPage: number) => void;
  pageSize: number;
  onPageSizeChange: (newPageSize: number) => void;
  selected: number[];
  onChangeSelected: (selected: number[]) => void;
  date: {
    start: ISODate;
    end: ISODate;
  };
  actionFilter: TransactionAction | null;
}

const ReleasesTable: FC<ReleasesTableProps> = ({
  transactions,
  companyToken,
  onPageChange,
  currentPage,
  pageSize,
  onPageSizeChange,
  selected,
  onChangeSelected,
  date,
  actionFilter,
}) => {
  const containerTableRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<TransactionItemKeys>("dueDate");

  const sortHandler = (property: TransactionItemKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = transactions.items.map((item) => item.id);
      onChangeSelected(newSelected);
      return;
    }

    onChangeSelected([]);
  };

  const handleToggleSelected = useCallback(
    (id: number) => {
      let newSelected = selected.slice();

      if (newSelected.includes(id)) {
        newSelected = newSelected.filter((p) => p !== id);
        onChangeSelected(newSelected);
        return;
      }

      newSelected = [...newSelected, id];
      onChangeSelected(newSelected);
    },
    [selected],
  );

  const isLastOfDay = (transactionItem: TransactionsItemNormalize, index: number) => {
    const isDayEqual = (date1: ISODate, date2: ISODate) => {
      return DateTime.fromISO(date1).day === DateTime.fromISO(date2).day;
    };

    if (index + 1 === transactions.items.length) {
      return true;
    } else if (!isDayEqual(transactionItem.dueDate, transactions.items[index + 1].dueDate)) {
      return true;
    } else {
      return false;
    }
  };

  const balanceCalc = (index: number) => {
    let totalBalance = transactions.initialBalance;
    transactions.items.forEach((t, i) => {
      if (i > index) return;

      if (getLabelStatus(t) == "Pago" || getLabelStatus(t) == "Recebido") {
        totalBalance = totalBalance + (t.transactionType == "Credit" ? t.value : t.value * -1);
      }
    });

    return totalBalance;
  };

  useEffect(() => {
    const transactionsIds = transactions.items.map((item) => item.id);
    const idOut = transactionsIds.filter((id) => selected.includes(id));

    if (idOut.length !== selected.length) {
      onChangeSelected(idOut);
    }
  }, [transactions.items, selected]);

  return (
    <Box ref={containerTableRef}>
      <PrintLandscape>
        <Box className="no-print">
          <MassActions
            companyToken={companyToken}
            startDate={date.start}
            earliestBankBalanceDate={transactions.earliestBankBalanceDate}
            existsPreviousBalance={transactions.existeSaldoAnterior}
            initialBalance={transactions.initialBalance}
            selected={selected}
          />

          <Divider sx={{ mt: 1, mb: 2 }} />
        </Box>

        <TableContainer sx={{ "@media print": { "*": { fontSize: "90%" } } }}>
          <InfoToPrint />

          <Table size="small" ref={tableRef} data-cols-width="15,15,25,25,25,10,10,10,10,20">
            <SortableTableHead
              headCells={headCells}
              onRequestSort={(e, property) => sortHandler(property)}
              order={order}
              orderBy={orderBy}
              rowsCount={transactions.items.length}
              rowsSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
            >
              <TableCell className="no-print" />
            </SortableTableHead>

            <TableBody>
              {transactions.items.length === 0 && (
                <TableRow>
                  <TableCell colSpan={headCells.length + 2}>Nenhum item encontrado</TableCell>
                </TableRow>
              )}
              {stableSort(transactions.items, comparatorSortByProperty(order, orderBy)).map(
                (transactionItem, index) => (
                  <ReleasesTableItem
                    transactionItem={transactionItem}
                    key={transactionItem.id}
                    isSelected={selected.includes(transactionItem.id)}
                    onToggleSelected={() => handleToggleSelected(transactionItem.id)}
                    companyToken={companyToken}
                    isLastOfDay={isLastOfDay(transactionItem, index)}
                    totalBalance={balanceCalc(index)}
                  />
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          mt={2.5}
          sx={{
            bgcolor: grey[200],
            p: 3,
            "@media print": {
              bgcolor: "#fff",
              p: 0,
            },
          }}
        >
          <Stack
            flexWrap="wrap"
            gap={2}
            className="no-print"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControl sx={{ minWidth: 125 }}>
              <InputLabel>Linhas por página</InputLabel>
              <Select
                value={pageSize}
                label="Linhas por página"
                onChange={(e) => {
                  onPageSizeChange(Number(e.target.value));
                  onPageChange(1);
                  onChangeSelected([]);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={transactions.totalNumberOfTransactions}>Todos</MenuItem>
              </Select>
            </FormControl>
            <Pagination
              showFirstButton
              showLastButton
              color="primary"
              count={Math.ceil(transactions.totalNumberOfTransactions / pageSize)}
              page={currentPage}
              onChange={(_, newPage) => {
                onChangeSelected([]);
                onPageChange(newPage);
              }}
            />
            <Box width="fit-content" textAlign="right">
              Total: {transactions.totalNumberOfTransactions} itens
            </Box>
          </Stack>

          <Stack
            gap={2}
            mt={2}
            flexWrap="wrap"
            sx={{
              "> *": { flex: 1, flexBasis: 400 },
              "@media print": { "*": { fontSize: "95%" } },
            }}
            direction="row"
          >
            <ProvisionedTable actionFilter={actionFilter} transaction={transactions} />
            <AccomplishedTable actionFilter={actionFilter} transaction={transactions} />
          </Stack>

          {containerTableRef.current && tableRef.current && (
            <TableActions
              className="no-print"
              mt={2}
              containerTableRef={containerTableRef.current}
              tableRef={tableRef.current}
              nameExcel="lancamentos"
              nameTable="lancamentos"
            />
          )}
        </Box>
      </PrintLandscape>
    </Box>
  );
};

export default ReleasesTable;
