import { FC, useState } from "react";
import useQueryInfoAccount from "pages/Home/hooks/useQueryInfoAccount";
import useQueryFinancial from "../hooks/useQueryFinancial";

import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import WithCompany from "components/WithCompany";
import FinancialTable from "./FinancialTable";
import DialogWithdraw from "./DialogWithdraw";

import { DateTime } from "luxon";

const months = {
  1: "Janeiro",
  2: "Fevereiro",
  3: "Março",
  4: "Abril",
  5: "Maio",
  6: "Junho",
  7: "Julho",
  8: "Agosto",
  9: "Setembro",
  10: "Outubro",
  11: "Novembro",
  12: "Dezembro",
} as const;

const years = [0, 1, 2, 3, 4, 5, 6].map((i) => {
  return DateTime.local().minus({ year: i }).year.toString();
});

interface FinancialStatementProps {
  companyToken: string;
}

const FinancialStatement: FC<FinancialStatementProps> = ({ companyToken }) => {
  const { data: infoAccount } = useQueryInfoAccount({
    companyToken,
  });

  const [showWithdraw, setShowWithdraw] = useState(false);
  const [month, setMonth] = useState<number>(DateTime.local().month);
  const [year, setYear] = useState(DateTime.local().year.toString());
  const {
    data: financial,
    isLoading,
    isError,
  } = useQueryFinancial(companyToken, year, month.toString());

  return (
    <>
      <Stack alignItems="center" justifyContent="space-between" direction="row">
        <Stack alignItems="center" gap={1} direction="row">
          <FormControl>
            <InputLabel>Mês</InputLabel>
            <Select label="Mês" value={month} onChange={(e) => setMonth(Number(e.target.value))}>
              {Object.entries(months).map(([key, label]) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>Ano</InputLabel>
            <Select label="Ano" value={year} onChange={(e) => setYear(e.target.value)}>
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack alignItems="center" direction="row" gap={1}>
          {infoAccount && (
            <Typography variant="body1">
              Saldo Disponível:{" "}
              <Typography component="strong" fontWeight={600} variant="inherit">
                {infoAccount.balance}
              </Typography>
            </Typography>
          )}

          <Button
            variant="contained"
            disabled={!infoAccount}
            size="small"
            onClick={() => setShowWithdraw(true)}
          >
            Solicitar retirada
          </Button>
        </Stack>
      </Stack>

      <Divider sx={{ my: 2 }} />

      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}

      {isError && (
        <Typography>Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.</Typography>
      )}

      {!isLoading && !isError && financial && <FinancialTable financial={financial} />}

      {infoAccount && showWithdraw && (
        <DialogWithdraw
          open={showWithdraw}
          onClose={() => setShowWithdraw(false)}
          companyToken={companyToken}
          infoAccount={infoAccount}
        />
      )}
    </>
  );
};

export default WithCompany(FinancialStatement);
