import { memo, useMemo, useState } from "react";
import { useFinancialCategoryId } from "../context/FinancialCategoryId";
import { useTemplate } from "../context/Template/useTemplate";

import { Box, IconButton, Menu, MenuItem, Stack, TextField } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { FinancialCategoryByGroup } from "types/Company";
import GlobalAccountanting from "./GlobalAccountanting";
import DialogUpdateAnalyticalCategory from "../components/DialogCreateOrUpdateAnalyticalCategory";
import DialogTransferAnalyticalCategory from "../components/DialogTransferAnalyticalCategory";
import DialogDeleteSyntheticCategory from "../components/DialogDeleteSyntheticCategory";
import useQueryGetDREAnalitycalAccounts from "../hooks/useQueryGetDREAnalitycalAccounts";
import { useAuth } from "context/Auth";

interface AnalyticalCategoryUIProps {
  nature: FinancialCategoryByGroup["nature"];
  financialCategory: FinancialCategoryByGroup;
}

const AnalyticalCategoryUI = memo(({ nature, financialCategory }: AnalyticalCategoryUIProps) => {
  const { groupId, templateId } = useFinancialCategoryId();
  const { isCompany, financialCategories, companyToken, companyId } = useTemplate();

  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  const [updateCategory, setUpdateCategory] = useState(false);
  const [transferCategory, setTransferCategory] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const handleUpdate = () => setUpdateCategory(true);
  const handleTransfer = () => setTransferCategory(true);
  const handleDelete = () => setDeleteCategory(true);
  
  const {
    user: { company },
  } = useAuth();

  const { data } = useQueryGetDREAnalitycalAccounts(company?.token);
  const othersAccount = useMemo(
    () =>
      isCompany
        ? {
            debtAccount: financialCategory.account,
            creditAccount: financialCategory.account2,
            creditAccountCx: financialCategory.creditAccountCx,
            debtAccountCx: financialCategory.debtAccountCx,
          }
        : undefined,
    [financialCategory],
  );

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="end" gap={1} flex={1}>
        <Box display="grid" width="100%" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={1}>
          <GlobalAccountanting
            type={isCompany ? "account" : "DP"}
            account={financialCategory.debitProv ?? financialCategory.account}
            groupId={groupId}
            id={financialCategory.id}
            name={financialCategory.name}
            othersAccount={othersAccount}
          />
          <GlobalAccountanting
            type={isCompany ? "account2" : "CP"}
            account={financialCategory.creditProv ?? financialCategory.account2}
            groupId={groupId}
            id={financialCategory.id}
            name={financialCategory.name}
            othersAccount={othersAccount}
          />

          {nature !== "Débito" ? (
            <>
              <TextField
                value={financialCategory.debitCx ?? financialCategory.debtAccountCx}
                InputProps={{ readOnly: true }}
                size="small"
                placeholder="Banco"
                fullWidth
              />
              <GlobalAccountanting
                type={isCompany ? "creditAccountCx" : "CC"}
                account={financialCategory.creditCx ?? financialCategory.creditAccountCx}
                groupId={groupId}
                id={financialCategory.id}
                name={financialCategory.name}
                othersAccount={othersAccount}
              />
            </>
          ) : (
            <>
              <GlobalAccountanting
                type={isCompany ? "debtAccountCx" : "DC"}
                account={financialCategory.debitCx ?? financialCategory.debtAccountCx}
                groupId={groupId}
                id={financialCategory.id}
                name={financialCategory.name}
                othersAccount={othersAccount}
              />
              <TextField
                value={financialCategory.creditCx ?? financialCategory.creditAccountCx}
                InputProps={{ readOnly: true }}
                size="small"
                placeholder="Banco"
                fullWidth
              />
            </>
          )}
        </Box>

        <IconButton
          size="small"
          sx={{ flexShrink: 0 }}
          onClick={(e) => setAnchorElMenu(e.currentTarget)}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
      </Stack>

      <Menu
        anchorEl={anchorElMenu}
        open={menuIsOpen}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleTransfer}>Transferência entre categorias</MenuItem>
        <MenuItem onClick={handleUpdate}>Editar</MenuItem>
        <MenuItem onClick={handleDelete}>Excluir</MenuItem>
      </Menu>

      {updateCategory && (
        <DialogUpdateAnalyticalCategory
          open={updateCategory}
          onClose={() => setUpdateCategory(false)}
          templateId={templateId}
          parentCategoryId={financialCategory.parentCategoryId as number}
          financialCategory={financialCategory}
          financialCategories={financialCategories}
          isCompany={isCompany}
          companyToken={companyToken}
          companyId={companyId}
          dreAnalitycalAccounts={data}
        />
      )}
      {transferCategory && (
        <DialogTransferAnalyticalCategory
          open={transferCategory}
          onClose={() => setTransferCategory(false)}
          id={financialCategory.id}
          name={financialCategory.name}
          financialCategories={financialCategories}
        />
      )}

      <DialogDeleteSyntheticCategory
        open={deleteCategory}
        onClose={() => setDeleteCategory(false)}
        id={financialCategory.id}
        isCompany={isCompany}
      />
    </>
  );
});

export default AnalyticalCategoryUI;
