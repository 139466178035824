import { forwardRef, Ref } from "react";
import useQueryCompanyAssignments from "pages/Home/pages/Marketplace/components/Accounting/hooks/useQueryCompanyAssignments";

import {
  CircularProgress,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface CompanyClientSelectInnerProps extends SelectProps<number | string | null> {
  helperText?: string;
  FormControlProps?: FormControlProps;
}

function CompanyClientSelectInner(
  { helperText, FormControlProps, ...props }: CompanyClientSelectInnerProps,
  ref?: Ref<unknown>,
) {
  const {
    isLoading,
    data: assignments,
    isError,
  } = useQueryCompanyAssignments({
    registrationStatus: 2,
    status: 0,
  });

  return (
    <FormControl error={props.error} size={props.size} {...FormControlProps}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        ref={ref}
        {...props}
        value={props.value ?? ""}
        endAdornment={
          isLoading ? (
            <CircularProgress sx={{ position: "relative", left: "-20px" }} size={20} />
          ) : undefined
        }
      >
        {!!assignments && !isLoading && (
          <MenuItem value="" disabled>
            Selecione:{" "}
          </MenuItem>
        )}

        {isError && (
          <MenuItem disabled>
            Ocorreu um erro ao buscar os clientes, tente outra vez mais tarde
          </MenuItem>
        )}

        {!!assignments &&
          assignments.companies.map(({ id, cnpj, name }) => (
            <MenuItem value={id} key={id}>
              {`${cnpj} - ${name}`}
            </MenuItem>
          ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const CompanyClientSelect = forwardRef(CompanyClientSelectInner);
export default CompanyClientSelect;
