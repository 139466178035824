import endpoints from "../endpoints";
import api from "../api";
import objectToFormData from "utils/objectToFormData";
import { ISODate } from "types/SemanticTypes";
import { LatestTransactions } from "types/Dashboard";
import { FilesHistory } from "types/Files";

export interface UploadImagePayload {
  companyToken: string;
  path: string;
  file: File;
  type?: "login" | "top";
}

export const uploadImage = async ({ type = "top", ...payload }: UploadImagePayload) => {
  const endpoint = type === "top" ? endpoints.files.uploadImage : endpoints.files.uploadImageLogin;
  await api.post(endpoint, objectToFormData(payload));
};

export interface ListFilesIntegrationPayload {
  allStatus: boolean;
  companyToken: string;
  endDate: ISODate;
  startDate: ISODate;
  dateType?: "C";
}

export const getListFilesIntegration = async (
  payload: ListFilesIntegrationPayload,
): Promise<LatestTransactions[]> => {
  const endpoint = endpoints.files.listFilesIntegration;
  const { data } = await api.post<LatestTransactions[]>(endpoint, payload);
  return data;
};

export interface GetFileHistoryForIntegrationCompanyPayload {
  companyToken: string;
  year: string;
}

export const getFileHistoryForIntegrationCompany = async (
  payload: GetFileHistoryForIntegrationCompanyPayload,
): Promise<FilesHistory> => {
  const endpoint = endpoints.files.getFileHistoryForIntegrationCompany;
  const { data } = await api.post<FilesHistory>(endpoint, payload);
  return data;
};
