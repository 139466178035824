import useMutationDeleteAccountsFromTo from "../../hooks/useMutationDeleteAccountsFromTo";
import useMutationDeleteAccountFromTo from "../../hooks/useMutationDeleteAccountFromTo";
import useMutationDeleteAllAccountsFromTo from "../../hooks/useMutationDeleteAllAccountsFromTo";
import useMutationDeleteCompanyAccountFromTo from "../../hooks/useMutationDeleteCompanyAccountFromTo";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { ChartOfAccountsFromToGroup } from "types/Accountant";

interface DialogDeleteAccountProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  ids?: number[];
  chartOfAccount?: ChartOfAccountsFromToGroup;
  groupId?: number; // for delete all
  isCompany?: boolean;
}

function DialogDeleteAccount({
  onClose,
  ids,
  chartOfAccount,
  groupId,
  isCompany,
  ...props
}: DialogDeleteAccountProps) {
  const { isLoading: loadingMultiple, mutateAsync: mutateDeleteMultiple } =
    useMutationDeleteAccountsFromTo();
  const { isLoading: loadingSingle, mutateAsync: mutateDeleteSingle } =
    useMutationDeleteAccountFromTo();
  const { isLoading: loadingAll, mutateAsync: mutateDeleteAll } =
    useMutationDeleteAllAccountsFromTo();
  const { isLoading: loadingDeleteCompany, mutateAsync: mutateDeleteCompany } =
    useMutationDeleteCompanyAccountFromTo();
  const isLoading = loadingMultiple || loadingSingle || loadingAll || loadingDeleteCompany;

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDeleteAccount = async () => {
    const runMutations = async () => {
      if (ids) {
        await mutateDeleteMultiple({ ids });
      }
      if (chartOfAccount && !isCompany) {
        await mutateDeleteSingle(chartOfAccount);
      }
      if (chartOfAccount && isCompany) {
        await mutateDeleteCompany(chartOfAccount);
      }
      if (groupId) {
        await mutateDeleteAll({ groupId });
      }
    };

    await runMutations();
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {!!ids && "Excluir contas em massa"}
        {!!chartOfAccount && "Excluir conta"}
        {!!groupId && "Excluir todas as contas"}
      </DialogTitleWithCloseButton>

      <DialogContent>
        {!!ids && (
          <>
            Tem certeza que deseja excluir{" "}
            <Typography variant="body1" fontWeight={600} component="strong">
              {ids.length} contas
            </Typography>{" "}
            selecionadas?
          </>
        )}

        {!!chartOfAccount && (
          <>
            Tem certeza que deseja excluir a conta{" "}
            <Typography variant="body1" fontWeight={600} component="strong">
              {chartOfAccount.account}?
            </Typography>
          </>
        )}

        {!!groupId && (
          <>
            Tem certeza que deseja excluir{" "}
            <Typography variant="body1" fontWeight={600} component="strong">
              todas as contas?
            </Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton variant="contained" loading={isLoading} onClick={handleDeleteAccount}>
          Sim
        </LoadingButton>
        <Button onClick={handleClose} disabled={isLoading} variant="outlined">
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDeleteAccount;
