import { FC, useState } from "react";
import useQueryGroupsForm from "../hooks/useQueryGroupsForm";

import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import GroupForm from "./GroupForm";

import stableSort from "utils/stableSort";
import { DateTime } from "luxon";
import comparatorSortByProperty from "utils/comparatorSortByProperty";
import { GroupForm as IGroupForm } from "types/Marketplace";

interface DialogGroupsProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
}

const DialogGroups: FC<DialogGroupsProps> = (props) => {
  const { data: groupsForm = [], isLoading } = useQueryGroupsForm();
  const [showNewGroupForm, setShowNewGroupForm] = useState(false);
  const [editedGroupForm, setEditedGroupForm] = useState<null | IGroupForm>(null);

  return (
    <Dialog {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={props.onClose}>
        <Stack gap={1} direction="row" alignItems="center">
          Configurar Grupos
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 1 }}
            onClick={() => setShowNewGroupForm(true)}
          >
            Novo grupo
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setShowNewGroupForm(false);
              setEditedGroupForm(null);
            }}
          >
            Grupos
          </Button>
        </Stack>
      </DialogTitleWithCloseButton>

      <DialogContent>
        {isLoading ? (
          <Stack height={300} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Collapse in={showNewGroupForm} mountOnEnter={false}>
              <GroupForm
                isShow={showNewGroupForm}
                editedGroupForm={editedGroupForm}
                onBeforeSave={() => setEditedGroupForm(null)}
              />
            </Collapse>

            <Collapse in={!showNewGroupForm}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Criado em</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {groupsForm.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          Nenhum resultado encontrado
                        </TableCell>
                      </TableRow>
                    )}

                    {stableSort(groupsForm, comparatorSortByProperty("desc", "created")).map(
                      (groupForm) => (
                        <TableRow key={groupForm.id} hover>
                          <TableCell>{groupForm.name}</TableCell>
                          <TableCell>
                            {DateTime.fromISO(groupForm.created).toFormat("dd/MM/yyyy")}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Editar">
                              <IconButton
                                onClick={() => {
                                  setEditedGroupForm(groupForm);
                                  setShowNewGroupForm(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={props.onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogGroups;
