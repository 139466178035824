import useMutationDownloadBatch from "../../hooks/useMutationDownloadBatch";

import {
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { InvoiceStatus } from "types/Company";

interface RequiredInvoiceItem {
  invoiceNumber: string;
  invoiceStatus: InvoiceStatus;
  pathXmlInvoice: string | null;
  pathInvoice: string | null;
}

interface MassActionProps<T extends RequiredInvoiceItem> {
  invoice: T[];
  selectedInvoiceId: string[];
}

function MassAction<T extends RequiredInvoiceItem>({
  invoice,
  selectedInvoiceId,
}: MassActionProps<T>) {
  const { mutate, isLoading } = useMutationDownloadBatch();

  const handleDownloadInvoices = (event: SelectChangeEvent<"xml" | "pdf" | "0">) => {
    if (event.target.value === "0") {
      return;
    }

    const type = event.target.value as "xml" | "pdf";
    mutate({
      items: invoice
        .filter((item) => selectedInvoiceId.includes(item.invoiceNumber))
        .map((item) => ({
          Number: item.invoiceNumber,
          Status: item.invoiceStatus,
          URL: type === "xml" ? item.pathXmlInvoice : item.pathInvoice,
        })),
      type,
    });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      flexWrap="wrap"
    >
      <Stack direction="row" alignItems="center" gap={1} pl={2}>
        <ArrowDownwardIcon color="primary" fontSize="small" />
        <Select<"xml" | "pdf" | "0">
          value="0"
          onChange={handleDownloadInvoices}
          disabled={isLoading}
          size="small"
          endAdornment={
            isLoading ? <CircularProgress sx={{ mr: 1 }} size={20} color="inherit" /> : null
          }
        >
          <MenuItem value="0">Ações em massa</MenuItem>
          <MenuItem value="pdf" disabled={selectedInvoiceId.length === 0}>
            Baixar PDF
          </MenuItem>
          <MenuItem value="xml" disabled={selectedInvoiceId.length === 0}>
            Baixar XML
          </MenuItem>
        </Select>

        {!!selectedInvoiceId.length && (
          <Typography sx={{ mr: 1 }} color="text.secondary" variant="body1">
            {selectedInvoiceId.length}{" "}
            {selectedInvoiceId.length === 1 ? "item selecionado" : "itens selecionados"}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export default MassAction;
