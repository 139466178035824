import { FC } from "react";
import useQueryTemplatesAccountanting from "pages/Home/pages/Accounting/hooks/useQueryTemplatesAccountanting";
import useMutationCreateTemplateFinancial from "../../hooks/useMutationCreateTemplateFinancial";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import ControllerInput from "components/ControllerInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import { TemplateFinancial } from "types/Company";

interface NewOrEditTemplateProps extends DialogProps {
  template?: TemplateFinancial;
}

const NewOrEditTemplate: FC<NewOrEditTemplateProps> = ({ template, onClose, ...props }) => {
  const { mutateAsync, isLoading: isCreating } = useMutationCreateTemplateFinancial();
  const { data: templatesAccountanting, isLoading } = useQueryTemplatesAccountanting();

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: template?.name || "",
      templateAccounting: template?.groupChartOfAccountsToFromId.toString() || "",
    },
  });

  const handleClose = () => {
    if (isCreating) return;

    reset();
    onClose?.({}, "backdropClick");
  };

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      groupChartOfAccountsToFromId: Number(data.templateAccounting),
      name: data.name,
      id: template?.id || 0,
    });
    handleClose();
  });

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {template ? "Editar" : "Novo"} template
      </DialogTitleWithCloseButton>

      <form onSubmit={onSubmit}>
        <DialogContent>
          <Stack gap={2}>
            <ControllerInput
              control={control}
              name="name"
              inputProps={{ label: "Nome do Template" }}
            />

            <FormControl fullWidth>
              <InputLabel>Template Contábil</InputLabel>
              <Controller
                name="templateAccounting"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <Select
                      {...field}
                      value={isLoading ? "" : field.value}
                      label="Template Contábil"
                      error={!!fieldState.error?.message}
                      MenuProps={{
                        sx: {
                          maxHeight: "50vh",
                        },
                      }}
                      endAdornment={
                        isLoading ? (
                          <CircularProgress
                            sx={{ position: "relative", left: "-20px" }}
                            size={20}
                          />
                        ) : undefined
                      }
                    >
                      <MenuItem disabled value="">
                        Selecione:
                      </MenuItem>

                      {templatesAccountanting &&
                        templatesAccountanting.map((template) => (
                          <MenuItem value={template.id} key={template.id}>
                            {template.name}
                          </MenuItem>
                        ))}
                    </Select>

                    {!!fieldState.error?.message && (
                      <Typography
                        variant="body2"
                        sx={{ ml: 2, mt: "3px", fontSize: "0.75rem" }}
                        color="error.main"
                      >
                        {fieldState.error.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </FormControl>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" disabled={isCreating}>
            Cancelar
          </Button>
          <LoadingButton loading={isCreating} variant="contained" type="submit">
            Salvar
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewOrEditTemplate;
