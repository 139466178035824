import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { saveAccountantApps } from "services/Requests/accountantApps";

const useMutationSaveAccountantApps = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(saveAccountantApps, {
    onSuccess(_, { companyId }) {
      toast.success("Aplicativos atualizados com sucesso!");
      queryClient.invalidateQueries([queryKeys.accountantApps, companyId]);
    },
  });

  return mutation;
};

export default useMutationSaveAccountantApps;
