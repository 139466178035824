import { FC, useState } from "react";
import { useQueryClient } from "react-query";
import useMutationMarkAsPresentedToAccountant from "./hooks/useMutationMarkAsPresentedToAccountant";

import { Dialog, DialogContent, DialogProps, Tab, Tabs } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import TabPanel from "components/TabPanel";
import Partner from "./Partner";
import Company from "./Company";
import Notification from "./Notification";
import Comunication from "./Comunication";
import Invoice from "./Invoice";
import Signature from "./Signature";
import ChartOfAccounts from "./ChartOfAccounts";

import { CompanyAssignments } from "types/Admin";
import { Company as ICompany } from "types/Company";
import queryKeys from "react-query/constants";

interface DialogCompanyProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  company: CompanyAssignments | ICompany;
}

interface Tabs {
  label: string;
  Component: FC<{ company: CompanyAssignments | ICompany }>;
}

const tabs: readonly Tabs[] = [
  {
    label: "Empresa",
    Component: Company,
  },
  {
    label: "Sócios",
    Component: Partner,
  },
  {
    label: "Solicitações",
    Component: Comunication,
  },
  {
    label: "Plano de Contas",
    Component: ChartOfAccounts,
  },
  {
    label: "Notificações",
    Component: Notification,
  },
  {
    label: "Assinatura",
    Component: Signature,
  },
  {
    label: "Faturas",
    Component: Invoice,
  },
];

const DialogCompany: FC<DialogCompanyProps> = ({ company, ...props }) => {
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState(0);
  const { mutate } = useMutationMarkAsPresentedToAccountant();

  const handleClose = () => {
    queryClient.invalidateQueries(queryKeys.companyAssignments, { exact: false });
    props.onClose();
  };

  const handleChangeTab = (index: number) => {
    if (tabs[index].label === "Notificações") {
      mutate({ companyToken: company.token });
    }
    setActiveTab(index);
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Empresa: {company.name}
      </DialogTitleWithCloseButton>

      <DialogContent>
        <Tabs
          color="primary"
          value={activeTab}
          onChange={(_, newValue) => handleChangeTab(newValue)}
          sx={{
            borderBottom: 1,
            borderBottomColor: "divider",
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>

        {tabs.map(({ Component, label }, index) => (
          <TabPanel
            key={index}
            value={activeTab}
            index={index}
            keepMounted={label !== "Plano de Contas"}
          >
            <Component company={company} />
          </TabPanel>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default DialogCompany;
