import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import toast from "react-hot-toast";
import { blockUser } from "services/Requests/admin";

const useMutationBlockUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(blockUser, {
    onSuccess() {
      toast.success("Usuário bloqueado com sucesso!");
      queryClient.invalidateQueries(queryKeys.userAdminList);
      queryClient.invalidateQueries(queryKeys.usersCompanies);
    },
  });
  return mutation;
};

export default useMutationBlockUser;
