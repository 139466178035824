import { FC, useState } from "react";
import { useAuth } from "context/Auth";
import { useNavigate } from "react-router-dom";

import { Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import UploadIcon from "@mui/icons-material/Upload";
import LaunchIcon from "@mui/icons-material/Launch";

import Head from "components/Head";
import ReleasesList from "./ReleasesList";
import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";

import DialogReleaseCredit from "pages/Home/components/DialogReleaseCredit";
import DialogReleaseDebit from "pages/Home/components/DialogReleaseDebit";
import TransferAccounts from "./components/TransferAccounts";

const Releases: FC = () => {
  const navigate = useNavigate();
  const [transferAccountsDialogIsOpen, setTransferAccountsDialogIsOpen] = useState(false);
  const [createReleaseIsOpen, setCreateReleaseIsOpen] = useState(false);
  const [dialogDebitIsOpen, setDialogDebitIsOpen] = useState(false);

  const {
    user: { company },
  } = useAuth();

  return (
    <>
      <Head title={`Lançamentos | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap" mb={2}>
        <Typography variant="h4" fontWeight="bold" sx={{ mr: 1 }}>
          Lançamentos
        </Typography>

        <Button
          size="small"
          variant="contained"
          color="success"
          startIcon={<AddIcon />}
          disabled={!company}
          onClick={() => setCreateReleaseIsOpen(true)}
        >
          Créditos
        </Button>
        <Button
          size="small"
          color="error"
          variant="contained"
          startIcon={<RemoveIcon />}
          onClick={() => setDialogDebitIsOpen(true)}
        >
          Débitos
        </Button>
        <Button
          size="small"
          variant="contained"
          startIcon={<ImportExportIcon />}
          onClick={() => setTransferAccountsDialogIsOpen(true)}
        >
          Transferência
        </Button>
        <Button
          size="small"
          variant="contained"
          href="/importar-lancamentos"
          startIcon={<UploadIcon />}
          onClick={(e) => {
            e.preventDefault();
            navigate("/importar-lancamentos");
          }}
        >
          Importar OFX
        </Button>
        {!!company?.hasAppBankingIntegrator && (
          <Button
            size="small"
            variant="contained"
            href="/importacao-integrada"
            startIcon={<LaunchIcon />}
            onClick={(e) => {
              e.preventDefault();
              navigate("/importacao-integrada");
            }}
          >
            Importação automática
          </Button>
        )}
      </Stack>

      {company ? (
        <>
          <ReleasesList companyToken={company.token} />
          <DialogReleaseCredit
            open={createReleaseIsOpen}
            onClose={() => setCreateReleaseIsOpen(false)}
            companyToken={company.token}
          />
          <DialogReleaseDebit
            open={dialogDebitIsOpen}
            onClose={() => setDialogDebitIsOpen(false)}
            companyToken={company.token}
          />
          <TransferAccounts
            open={transferAccountsDialogIsOpen}
            onClose={() => setTransferAccountsDialogIsOpen(false)}
            companyToken={company.token}
          />
        </>
      ) : (
        <Typography>{MESSAGE_NO_COMPANY}</Typography>
      )}
    </>
  );
};

export default Releases;
