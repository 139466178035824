import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getPaymentInfoProfile } from "services/Requests/companies";
import getSubdomain from "utils/getSubdomain";

const useQueryPaymentInfoProfile = (companyToken: string, isAdmin = false) => {
  const subdomain = getSubdomain();

  const query = useQuery([queryKeys.paymentInfoProfile, companyToken], () =>
    getPaymentInfoProfile({ companyToken, subdomain, isAdmin }),
  );
  return query;
};

export default useQueryPaymentInfoProfile;
