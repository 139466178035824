import { Controller, Control, useWatch, Path } from "react-hook-form";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { TransactionType } from "types/CommonData";

interface RequiredFields {
  isInvoice: boolean;
  paymentType: "receive" | "received";
  paymentOrigin: "participant" | "partner";
}

interface RadioFormProps<T extends RequiredFields = RequiredFields> {
  control: Control<T>;
  transactionType?: TransactionType;
}

function RadioForm<T extends RequiredFields = RequiredFields>({
  control,
  transactionType = "Credit",
}: RadioFormProps<T>) {
  const isInvoice = useWatch({
    control,
    name: "isInvoice" as Path<T>,
  });

  return (
    <>
      <Controller
        name={"paymentType" as Path<T>}
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel>Tipo de {transactionType === "Credit" ? "receita" : "despesa"}</FormLabel>
            <RadioGroup {...field} row>
              <FormControlLabel
                value="receive"
                control={<Radio />}
                label={`A ${transactionType === "Credit" ? "receber" : "pagar"}`}
              />
              <FormControlLabel
                value="received"
                control={<Radio />}
                label={transactionType === "Credit" ? "Recebido" : "Pago"}
              />
            </RadioGroup>
          </FormControl>
        )}
      />
      <Controller
        name={"paymentOrigin" as Path<T>}
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel>Origem da {transactionType === "Credit" ? "receita" : "despesa"}</FormLabel>
            <RadioGroup {...field} row>
              <FormControlLabel
                value="participant"
                control={<Radio />}
                label={transactionType === "Credit" ? "Cliente" : "Fornecedor"}
              />
              <FormControlLabel
                disabled={!!isInvoice}
                value="partner"
                control={<Radio />}
                label="Sócio"
              />
            </RadioGroup>
          </FormControl>
        )}
      />
    </>
  );
}

export default RadioForm;
