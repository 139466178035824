import { FC, useMemo } from "react";
import { useCompanyInfo } from "context/CompanyInfo";
import { useFormContext, useWatch, Controller } from "react-hook-form";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Info from "@mui/icons-material/Info";

import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { Service } from "types/ServiceAndPlan";
import { FormFields } from "..";

interface IntroductionProps {
  service: Service;
  onNext: () => void;
}

const Introduction: FC<IntroductionProps> = ({ service, onNext }) => {
  const { control } = useFormContext<FormFields>();
  const {
    companyStyles: { headerLinksColor },
  } = useCompanyInfo();

  const servicePremiumPriceId = useWatch({
    control,
    name: "servicePremiumPriceId",
  });
  const servicePremiumSelected = useMemo(() => {
    return service.premiumPrices.find((premium) => premium.id === Number(servicePremiumPriceId));
  }, [servicePremiumPriceId]);

  return (
    <Box>
      <Typography align="center" variant="body1">
        Cuidamos de todo o processo burocrático para você. Após a análise e sugestão do melhor
        modelo de empresa, você assina toda a documentação e nos responsabilizamos pelo registro do
        processo nos órgãos competentes.
        <br />
        Você não precisa se preocupar com nada!
      </Typography>

      <Box mt={2}>
        <Typography align="center" variant="body1" fontWeight={600}>
          Custo por tipo de empresa:
        </Typography>

        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ bgcolor: "primary.main", "*": { color: headerLinksColor } }}
          p={1}
          mt={0.5}
          mb={2}
        >
          <Controller
            name="servicePremiumPriceId"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl
                error={!!fieldState.error?.message}
                component="fieldset"
                variant="standard"
              >
                <RadioGroup {...field} row>
                  {service.premiumPrices
                    .filter((price) => price.isActive)
                    .map((price) => (
                      <FormControlLabel
                        key={price.id}
                        value={price.id}
                        control={<Radio />}
                        label={
                          <Stack direction="row" alignItems="center" gap={0.5}>
                            {price.companyType}
                            <Tooltip title={price.description}>
                              <Info fontSize="small" />
                            </Tooltip>
                          </Stack>
                        }
                      />
                    ))}
                </RadioGroup>
                {!!fieldState.error?.message && (
                  <FormHelperText>{fieldState.error?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Stack>

        {servicePremiumSelected && (
          <Typography align="center">
            <Typography component="span" variant="h3" fontWeight={600}>
              {formatCurrencyBRL(servicePremiumSelected.priceInCents)}
            </Typography>
            <Typography component="sup" sx={{ position: "relative", top: -18 }}>
              1
            </Typography>
          </Typography>
        )}

        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }} align="center">
          Você será cobrado somente após a análise completa de nossos contadores.
        </Typography>
      </Box>

      <Divider sx={{ my: 3, mx: 1, borderColor: "primary.main" }} />

      <Stack alignItems="end">
        <Button variant="contained" onClick={onNext} endIcon={<NavigateNextIcon />}>
          Próximo passo
        </Button>
      </Stack>
    </Box>
  );
};

export default Introduction;
