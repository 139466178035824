import { FC } from "react";
import { useForm } from "react-hook-form";

import PenaltyField from "./PenaltyField";
import DiscountsFields from "./DiscountsFields";

import { Box, Stack, useMediaQuery } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PenaltiesAndDiscounts } from "types/Billing";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import useMutationUpdatePenaltiesAndDiscounts from "../hooks/useMutationUpdatePenaltiesAndDiscounts";
import { formattedInput } from "components/CurrencyInput";

export interface PenaltiesAndDiscountsForm {
  penaltiesPercent: number;
  penaltiesMora: boolean;
  discountsDays: number;
  discountsPercent: number;
  discountsValues: string;
}

interface PenaltiesAndDiscountsFormProps {
  penaltiesAndDiscounts: PenaltiesAndDiscounts;
  companyToken: string;
}

const PenaltiesAndDiscountsForm: FC<PenaltiesAndDiscountsFormProps> = ({
  penaltiesAndDiscounts,
  companyToken,
}) => {
  const isTablet = useMediaQuery("(max-width:800px)");

  const { mutateAsync } = useMutationUpdatePenaltiesAndDiscounts();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<PenaltiesAndDiscountsForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      penaltiesMora: penaltiesAndDiscounts.penaltiesMora,
      penaltiesPercent: penaltiesAndDiscounts.penaltiesPercent,
      discountsDays: penaltiesAndDiscounts.discountsDays,
      discountsPercent: penaltiesAndDiscounts.discountsPercent,
      discountsValues: penaltiesAndDiscounts.discountsValues || "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    const discountValues = data.discountsValues
      ? formattedInput(data.discountsValues).toString()
      : data.discountsValues;

    await mutateAsync({
      companyToken,
      discountsDays: data.discountsDays,
      discountsPercent: data.discountsPercent,
      discountsValues: discountValues,
      penaltiesMora: data.penaltiesMora,
      penaltiesPercent: data.penaltiesPercent,
    });
  });

  return (
    <Box component="form" onSubmit={onSubmit} noValidate>
      <Box display="grid" gridTemplateColumns={isTablet ? "1fr" : "1fr 1fr"} gap={3}>
        <PenaltyField control={control} />
        <DiscountsFields control={control} />
      </Box>

      <Stack alignItems="center" justifyContent="center" mt={4}>
        <LoadingButton loading={isSubmitting} type="submit" variant="contained">
          Salvar
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default PenaltiesAndDiscountsForm;
