import { FC } from "react";
import { Control, Controller, useWatch } from "react-hook-form";

import { Box, TextField } from "@mui/material";

import { MovementForm } from "../utils/InterfacesForm";
import { cnpjMask, cpfMask, zipCodeMask } from "utils/masks";

import MaskedInput from "react-text-mask";
import StateSelect from "pages/Home/components/StateSelect";
import CitySelect from "pages/Home/components/CitySelect";

interface RecipientProps {
  control: Control<MovementForm>;
  isConsultation: boolean;
}

const Recipient: FC<RecipientProps> = ({ control, isConsultation }) => {
  const recipientState = useWatch({
    control,
    name: "shipping.recipientState",
  });

  const maskBuilderCPFCNPJ = (v: string) => {
    return v.length <= 14 ? [...cpfMask, /\d/g] : cnpjMask;
  };

  return (
    <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" alignItems="center" gap={2} mt={2}>
      <Controller
        name={"shipping.recipientCpfCnpj"}
        control={control}
        render={({ fieldState, field }) => (
          <MaskedInput
            mask={maskBuilderCPFCNPJ(field.value || "")}
            guide={false}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            render={(ref, props) => (
              <TextField
                fullWidth
                label="CPF / CNPJ"
                inputRef={ref}
                {...props}
                sx={{ gridColumn: "span 3" }}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                disabled={isConsultation}
              />
            )}
          ></MaskedInput>
        )}
      />

      <Controller
        control={control}
        name={"shipping.recipientZipCode"}
        render={({ field, fieldState }) => (
          <MaskedInput
            mask={zipCodeMask}
            guide={false}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            render={(ref, props) => (
              <TextField
                fullWidth
                label="CEP"
                inputRef={ref}
                {...props}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                disabled={isConsultation}
              />
            )}
          ></MaskedInput>
        )}
      />

      <Controller
        control={control}
        name="shipping.recipientState"
        render={({ field, fieldState }) => (
          <StateSelect
            {...field}
            label="Estado"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            disabled={isConsultation}
          />
        )}
      />
      <Controller
        control={control}
        name="shipping.recipientCity"
        render={({ field, fieldState }) => (
          <CitySelect
            {...field}
            label="Cidade"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            stateId={recipientState}
            byCityName
            disabled={isConsultation}
          />
        )}
      />
      <Controller
        control={control}
        name={"shipping.recipientNeighborhood"}
        render={({ field, fieldState }) => (
          <TextField
            label="Bairro"
            {...field}
            sx={{ gridColumn: "span 2" }}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            disabled={isConsultation}
          />
        )}
      />
      <Controller
        control={control}
        name={"shipping.recipientStreet"}
        render={({ field, fieldState }) => (
          <TextField
            label="Logradouro"
            {...field}
            sx={{ gridColumn: "span 2" }}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            disabled={isConsultation}
          />
        )}
      />
      <Controller
        control={control}
        name={"shipping.recipientNumber"}
        render={({ field, fieldState }) => (
          <TextField
            label="Número"
            type="number"
            inputMode="numeric"
            inputProps={{ min: 0 }}
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            disabled={isConsultation}
          />
        )}
      />
      <Controller
        control={control}
        name={"shipping.recipientComplement"}
        render={({ field, fieldState }) => (
          <TextField
            label="Complemento"
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            disabled={isConsultation}
          />
        )}
      />
    </Box>
  );
};

export default Recipient;
