import { forwardRef, Ref } from "react";
import useQueryStates from "pages/Home/hooks/useQueryStates";

import {
  CircularProgress,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface StateSelectInnerProps extends SelectProps<string | null> {
  helperText?: string;
  showOnlyCode?: boolean;
  FormControlProps?: FormControlProps;
}

function StateSelectInner(
  { helperText, showOnlyCode = false, FormControlProps, ...props }: StateSelectInnerProps,
  ref?: Ref<unknown>,
) {
  const { isLoading, data: states, isError } = useQueryStates();

  return (
    <FormControl error={props.error} size={props.size} {...FormControlProps}>
      <InputLabel shrink>{props.label}</InputLabel>
      <Select
        ref={ref}
        {...props}
        value={props.value || ""}
        endAdornment={
          isLoading ? (
            <CircularProgress sx={{ position: "relative", left: "-20px" }} size={20} />
          ) : undefined
        }
      >
        {!!states && !isLoading && (
          <MenuItem value="" disabled>
            Selecione:{" "}
          </MenuItem>
        )}

        {isError && (
          <MenuItem disabled>
            Ocorreu um erro ao buscar os estados, tente outra vez mais tarde
          </MenuItem>
        )}

        {!!states &&
          states.map(({ name, state }) => (
            <MenuItem value={state} key={state}>
              {showOnlyCode ? state : name}
            </MenuItem>
          ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const StateSelect = forwardRef(StateSelectInner);
export default StateSelect;
