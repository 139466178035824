import { ChipProps } from "@mui/material";
import { TransactionsItem } from "types/Company";

const getInvoiceStatusColor = (status: TransactionsItem["invoiceStatus"]): ChipProps["color"] => {
  if (status == "Autorizada") {
    return "success";
  }

  if (status == "Negada" || status == "Cancelamento Negado" || status == "Cancelada") {
    return "error";
  }

  if (status === "Aguardando Baixa") return "warning";

  return "info";
};

export default getInvoiceStatusColor;
