import { formattedInput } from "components/CurrencyInput";
import { DateTime } from "luxon";
import { CreateTransactionAccountantPayload } from "services/Requests/companies";
import { InvoiceConfigService } from "types/Company";
import { InvoiceFieldsForm } from ".";

const createInvoiceData = (
  data: InvoiceFieldsForm,
  service: InvoiceConfigService,
  companyToken: string,
) => {
  const value = formattedInput(data.value);
  const serviceOtherState = data.serviceOtherState === "1" ? 1 : 0;

  const dataForService: CreateTransactionAccountantPayload = {
    aliquota: service.aliquota,
    cnae: service.cnae,
    cofins: service.cofins,
    companyBankAccountId: null,
    companyToken,
    csll: data.csll,
    description: data.description,
    dueDate: DateTime.local().toJSDate().toDateString(),
    invoiceItems: [
      {
        aliquota: service.aliquota,
        cnae: service.cnae,
        cofins: data.cofins,
        csll: data.csll,
        describeInvoice: data.description,
        irop: data.irop,
        irpj: data.irpj,
        isManual: 1,
        iss: data.iss,
        issWithHeld: data.issWithHeld === "1" ? 1 : 0,
        lc116: service.lc116,
        outros: service.outros,
        pis: data.pis,
        quantity: 1,
        sendInvoiceLater: 0,
        serviceCity: serviceOtherState === 0 ? undefined : data.serviceCity,
        serviceUF: serviceOtherState === 0 ? undefined : data.serviceUF,
        serviceCodeId: null,
        serviceInvoiceCode: service.codigo,
        serviceInvoiceId: data.serviceCodeId,
        serviceOtherState,
        typeTaxation: "",
        unitValue: value,
      },
    ],
    issWithHeld: data.issWithHeld === "1" ? 1 : 0,
    irop: data.irop,
    irpj: data.irpj,
    isInvoice: true,
    isManual: true,
    isPartner: false,
    iss: data.iss,
    issueDate: DateTime.local().toJSDate().toDateString(),
    lc116: service.lc116,
    outros: service.outros,
    paymentDate: "",
    periodicity: "0",
    pis: data.pis,
    repeat: "noParcel",
    serviceCode: service.codigo,
    serviceOtherState: serviceOtherState,
    serviceCity: serviceOtherState === 0 ? undefined : data.serviceCity,
    serviceUF: serviceOtherState === 0 ? undefined : data.serviceUF,
    transactionType: "Credit",
    notes: data.notes,
    companyParticipantId: data.companyParticipantId,
    value,
  };

  return dataForService;
};

export default createInvoiceData;
