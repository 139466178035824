import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getFileHistoryForIntegrationCompany } from "services/Requests/files";

const useQueryFileHistoryForIntegrationCompany = (companyToken: string, year: string) => {
  const query = useQuery([queryKeys.fileHistoryForIntegrationCompany, companyToken, year], () =>
    getFileHistoryForIntegrationCompany({ companyToken, year }),
  );
  return query;
};

export default useQueryFileHistoryForIntegrationCompany;
