import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { getAccount } from "services/Requests/bankingIntegrator";

const useMutationGetAccount = () => {
  const mutation = useMutation(getAccount, {
    onError: () => {
      toast.error("Ocorreu um erro ao tentar integrar a conta. Tente novamente mais tarde.");
    },
  });

  return mutation;
};

export default useMutationGetAccount;
