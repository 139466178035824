import { FC } from "react";
import useQueryGetCompanyParticipants from "pages/Home/hooks/useQueryGetCompanyParticipants";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { RoleCompanyParticipant } from "types/Company";

interface CompanyParticipantFilterProps {
  companyToken: string;
  value: number | null;
  setValue: (value: number | null) => void;
  role?: RoleCompanyParticipant;
}

const CompanyParticipantFilter: FC<CompanyParticipantFilterProps> = ({
  companyToken,
  value,
  setValue,
  role = 1,
}) => {
  const {
    data: companyParticipants,
    isLoading,
    isError,
  } = useQueryGetCompanyParticipants(companyToken, "", role);

  return (
    <FormControl>
      <InputLabel>{role === 1 ? "Cliente" : "Fornecedor"}</InputLabel>
      <Select
        value={value || 0}
        label={role === 1 ? "Cliente" : "Fornecedor"}
        onChange={(e) => setValue(Number(e.target.value) || null)}
      >
        <MenuItem value={0}>Todos os clientes</MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um erro ao buscar os clientes</MenuItem>}
        {companyParticipants &&
          companyParticipants.map((participant) => (
            <MenuItem key={participant.id} value={participant.id}>
              {participant.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CompanyParticipantFilter;
