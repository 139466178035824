import { FC, useEffect, useMemo } from "react";
import useQueryCompanyGroups from "../../pages/Comunication/hooks/useQueryCompanyGroups";
import { useForm, Controller } from "react-hook-form";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { yupResolver } from "@hookform/resolvers/yup";
import formatBytes from "utils/formatBytes";
import validationSchema from "./validationSchema";
import useMutationCreateTicket from "../../pages/Comunication/hooks/useMutationCreateTicket";
import useMutationUploadFiles from "../../pages/Comunication/hooks/useMutationUploadFiles";

interface NewTicketForm {
  otherSubject: string;
  group: number;
  agent?: number;
  body: string;
  files: FileList;
}

interface DialogNewTicketProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
}

const DialogNewTicket: FC<DialogNewTicketProps> = ({ onClose, companyToken, ...props }) => {
  const { mutateAsync } = useMutationCreateTicket();
  const { mutateAsync: mutationUpload } = useMutationUploadFiles();
  const { data: companyGroups, isLoading, isError } = useQueryCompanyGroups();

  const {
    control,
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<NewTicketForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      otherSubject: "",
      group: undefined,
      agent: undefined,
      body: "",
    },
  });
  const files = watch("files");
  const group = watch("group");
  const groupAccountant = useMemo(() => {
    if (!group || !companyGroups) return null;
    return companyGroups.find((groupItem) => groupItem.id === group)?.groupAccountants || null;
  }, [group]);

  const handleClose = () => {
    if (isSubmitting) return;
    reset({
      agent: undefined,
      body: "",
      files: undefined,
      group: undefined,
      otherSubject: "",
    });
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    const { id } = await mutateAsync({
      agentId: data.agent || null,
      companyToken,
      groupId: data.group,
      message: data.body,
      subject: data.otherSubject,
    });

    if (!data.files || data.files?.length === 0) {
      handleClose();
      return;
    }

    await mutationUpload({
      companyToken,
      files: data.files,
      messageId: id,
    });
    handleClose();
  });

  useEffect(() => {
    setValue("agent", undefined);
  }, [group]);

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="md" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Nova Solicitação
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          <Stack gap={1.5}>
            <Controller
              control={control}
              name="otherSubject"
              render={({ field, fieldState }) => (
                <TextField
                  label="Assunto"
                  {...field}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <Controller
              control={control}
              name="group"
              render={({ field, fieldState }) => (
                <FormControl error={!!fieldState.error?.message}>
                  <InputLabel error={!!fieldState.error?.message}>Atribuir ao grupo</InputLabel>
                  <Select
                    label="Atribuir ao grupo"
                    error={!!fieldState.error?.message}
                    {...field}
                    value={field.value || "void"}
                    onChange={(e) => {
                      field.onChange(e.target.value === "void" ? undefined : e.target.value);
                    }}
                  >
                    <MenuItem value="void">Selecione</MenuItem>
                    {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
                    {isError && (
                      <MenuItem disabled>Ocorreu um error ao tentar carregar os groupos</MenuItem>
                    )}
                    {companyGroups &&
                      companyGroups.map((group) => (
                        <MenuItem value={group.id} key={group.id}>
                          {group.description}
                        </MenuItem>
                      ))}
                  </Select>
                  {!!fieldState.error?.message && (
                    <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="agent"
              render={({ field, fieldState }) => (
                <FormControl error={!!fieldState.error?.message}>
                  <InputLabel error={!!fieldState.error?.message}>Atribuír para</InputLabel>
                  <Select
                    label="Atribuír para"
                    error={!!fieldState.error?.message}
                    {...field}
                    value={field.value || "void"}
                    onChange={(e) => {
                      field.onChange(e.target.value === "void" ? undefined : e.target.value);
                    }}
                  >
                    <MenuItem value="void">Selecione</MenuItem>
                    {!groupAccountant && <MenuItem disabled>Selecione um grupo</MenuItem>}

                    {groupAccountant &&
                      groupAccountant.map(({ accountant }) => (
                        <MenuItem value={accountant.id} key={accountant.id}>
                          {accountant.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {!!fieldState.error?.message && (
                    <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="body"
              render={({ field, fieldState }) => (
                <TextField
                  label="Mensagem"
                  {...field}
                  rows={8}
                  multiline
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <Box>
              <label style={{ width: "fit-content" }}>
                <input
                  type="file"
                  style={{
                    display: "none",
                  }}
                  multiple
                  {...register("files")}
                  name="files"
                />
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Anexar arquivo
                </Button>
              </label>

              {files && Array.from(files).length > 0 && (
                <Box p={1}>
                  {Array.from(files).map((file) => (
                    <Typography key={file.name} variant="body2" lineHeight={1.5}>
                      {file.name} ({formatBytes(file.size)})
                    </Typography>
                  ))}
                </Box>
              )}

              {!!errors.files?.message && (
                <FormHelperText error sx={{ mt: 1 }}>
                  {errors.files?.message}
                </FormHelperText>
              )}
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton loading={isSubmitting} variant="contained" type="submit">
            Enviar
          </LoadingButton>
          <Button disabled={isSubmitting} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogNewTicket;
