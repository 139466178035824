import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { deleteProduct } from "services/Requests/stockControl";

const useMutationDeleteProduct = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteProduct, {
    onSuccess() {
      toast.success("Produto deletado com sucesso");
      queryClient.invalidateQueries(queryKeys.stockControlProducts);
    },
  });
  return mutation;
};

export default useMutationDeleteProduct;
