import { FC, useState } from "react";
import {
  Box,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useQueryGetMessageTypes from "./hooks/useQueryGetMessageTypes";
import EmailMessage from "./EmailMessage";

interface ModelProps {
  companyToken: string;
}

const Model: FC<ModelProps> = ({ companyToken }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const { data, isLoading } = useQueryGetMessageTypes(companyToken);
  const [activeMessage, setActiveMessage] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveMessage(newValue);
  };

  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 2 }}
      >
        Modelos de emails
      </Typography>

      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          border: 1,
          borderColor: "divider",
        }}
      >
        {isLoading && !data && (
          <Stack width={250} gap={2}>
            <Skeleton variant="rectangular" height={48} />
            <Skeleton variant="rectangular" height={48} />
            <Skeleton variant="rectangular" height={48} />
            <Skeleton variant="rectangular" height={48} />
            <Skeleton variant="rectangular" height={48} />
            <Skeleton variant="rectangular" height={48} />
            <Skeleton variant="rectangular" height={48} />
            <Skeleton variant="rectangular" height={48} />
          </Stack>
        )}

        {data && (
          <>
            <Tabs
              orientation={isTablet ? "horizontal" : "vertical"}
              variant="scrollable"
              value={activeMessage}
              onChange={handleChange}
              sx={{
                ...(!isTablet && {
                  borderRight: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  width: 250,
                  maxHeight: 600,
                }),
              }}
            >
              {data.map((messageType) => (
                <Tab key={messageType.id} label={messageType.description} value={messageType.id} />
              ))}
            </Tabs>

            <EmailMessage companyToken={companyToken} idMessage={activeMessage} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Model;
