import { FC } from "react";
import { useAuth } from "context/Auth";
import useMutationDeleteTemplate from "../../hooks/useMutationDeleteTemplate";

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

interface DialogDeleteTemplateProps extends Omit<DialogProps, "onClose" | "id"> {
  onClose: () => void;
  id: number;
}

const DialogDeleteTemplate: FC<DialogDeleteTemplateProps> = ({ onClose, id, ...props }) => {
  const { mutateAsync, isLoading } = useMutationDeleteTemplate();
  const {
    user: { company },
  } = useAuth();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync({
      companyToken: company!.token,
      id,
    });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Excluir template
      </DialogTitleWithCloseButton>

      <DialogContent>Deseja excluir este template?</DialogContent>

      <DialogActions>
        <LoadingButton variant="contained" loading={isLoading} onClick={handleDelete}>
          Sim
        </LoadingButton>
        <Button variant="outlined" onClick={handleClose} disabled={isLoading}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteTemplate;
