import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import toast from "react-hot-toast";
import { copyTemplateFinancial } from "services/Requests/companies";

const useMutationCopyTemplateFinancial = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(copyTemplateFinancial, {
    onSuccess() {
      toast.success("Template copiado com sucesso");
      queryClient.invalidateQueries(queryKeys.templateFinancial);
    },
  });
  return mutation;
};

export default useMutationCopyTemplateFinancial;
