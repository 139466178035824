import { FC, useState } from "react";

import { Box, CircularProgress, Divider, Grow, Stack } from "@mui/material";

import StandardizedPlans from "./StandardizedPlans";
import AdditionalServices from "./AdditionalServices";
import useQueryServiceAndPlans from "../hooks/useQueryServiceAndPlans";

const Services: FC = () => {
  const [filter, setFilter] = useState<"Client" | "Accountant">("Client");
  const { data, isLoading, isFetching } = useQueryServiceAndPlans(filter);

  return (
    <Box>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}

      {data && (
        <>
          <StandardizedPlans isIuguActivate={data.isIuguActivate} serviceAndPlan={data} />

          <Divider sx={{ my: 4 }} />
          <Box position="relative">
            <Grow in={!isLoading && isFetching}>
              <CircularProgress
                size={20}
                sx={{
                  position: "absolute",
                  top: "45px",
                  left: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            </Grow>

            <AdditionalServices
              services={data.customServices}
              filterServices={filter}
              onFilterServices={(user) => setFilter(user)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Services;
