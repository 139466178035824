import { FC, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import formatCurrencyBRL from "utils/formatCurrencyBRL";
import useMutationRequestAdvancements from "../hooks/useMutationRequestAdvancements";

interface RequestAnticipationsProps {
  selecteds: string[];
  total: number;
  companyToken: string;
}

const RequestAnticipations: FC<RequestAnticipationsProps> = ({
  selecteds,
  total,
  companyToken,
}) => {
  const { mutateAsync, isLoading } = useMutationRequestAdvancements();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (isLoading) return;
    setOpen(false);
  };

  const handleAnticipation = async () => {
    await mutateAsync({
      companyToken,
      idsForAdvance: selecteds,
    });
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" disabled={selecteds.length === 0} onClick={() => setOpen(true)}>
        Solicitar antecipação
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Solicitar antecipação</DialogTitle>
        <DialogContent>
          Deseja solicitar o total de {formatCurrencyBRL(total)} em antecipações?
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} onClick={handleAnticipation} variant="contained">
            Sim
          </LoadingButton>
          <Button onClick={handleClose} disabled={isLoading} variant="outlined">
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequestAnticipations;
