import { FC } from "react";
import useMutationUpdateProfile from "../../hooks/useMutationUpdateProfile";
import { useForm, Controller } from "react-hook-form";

import { Box, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";

import { Profile } from "types/User";
import MaskedInput from "react-text-mask";
import { cpfMask, telephoneMask } from "utils/masks";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

interface FormProps {
  profile: Profile;
  onNext: () => void;
}

const Form: FC<FormProps> = ({ profile, onNext }) => {
  const { mutateAsync } = useMutationUpdateProfile();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: profile?.name ?? "",
      cpf: profile?.cpf ?? "",
      crc: profile?.crc ?? "",
      telNumber: profile?.phone ?? "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      ...profile,
      name: data.name,
      cpf: data.cpf,
      crc: data.crc,
      phone: data.telNumber?.replace(/\D/g, ""),
    });
    onNext();
  });

  return (
    <>
      <Typography
        variant="h6"
        fontWeight={600}
        gutterBottom
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        Mais sobre você
      </Typography>
      <Typography variant="body1">
        Para começar, precisamos de mais algumas informações suas
      </Typography>

      <form
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <Box mt={4} mx="auto" width="60%" display="grid" gap={1.5}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Nome"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            name="cpf"
            control={control}
            render={({ fieldState, field }) => (
              <MaskedInput
                mask={cpfMask}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="CPF"
                    inputRef={ref}
                    {...props}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />

          <Controller
            control={control}
            name="crc"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Registro profissional (CRC)"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            name="telNumber"
            control={control}
            render={({ fieldState, field }) => (
              <MaskedInput
                mask={telephoneMask}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="DDD + Telefone"
                    inputRef={ref}
                    {...props}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Box>

        <Stack mt={4} alignItems="end" justifyContent="end">
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            type="submit"
            endIcon={<KeyboardArrowRightIcon />}
          >
            Próximo passo: Sobre a empresa
          </LoadingButton>
        </Stack>
      </form>

      {isSubmitting && <FullscreenLoadingIndicator />}
    </>
  );
};

export default Form;
