import { ChangeEvent, FC, useEffect, useState } from "react";
import useMutationImportExcel from "../../hooks/useMutationImportExcel";
import useMutationImportAccounts from "../../hooks/useMutationImportAccounts";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import toast from "react-hot-toast";
import { ACCOUNT_IMPORT_MODEL } from "utils/constants";

import { ImportAccount } from "types/Accountant";
import ImportAccountsTable from "./ImportAccountsTable";

interface DialogImportAccountsProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  groupId: number;
}

const DialogImportAccounts: FC<DialogImportAccountsProps> = ({ onClose, groupId, ...props }) => {
  const [fileSelected, setFileSelected] = useState<File | null>(null);
  const [importAccounts, setImportAccounts] = useState<ImportAccount[]>([]);

  const { isLoading: isLoadingImport, mutateAsync: mutateImport } = useMutationImportAccounts();
  const { isLoading, mutateAsync: mutateUploadImport } = useMutationImportExcel();

  const handleChangeUploadXLSX = (e: ChangeEvent<HTMLInputElement>) => {
    const targetEl = e.target;

    if (!targetEl.files || targetEl.files.length === 0) {
      toast("Selecione um arquivo", {
        icon: "⚠️",
      });
      return;
    }

    setFileSelected(targetEl.files[0]);
  };

  const handleClose = () => {
    if (isLoading) return;
    setFileSelected(null);
    setImportAccounts([]);
    onClose();
  };

  useEffect(() => {
    if (!fileSelected) return;

    const runMutation = async () => {
      const data = await mutateImport({
        file: fileSelected,
        groupId,
      });
      setImportAccounts(data);
    };

    runMutation();
  }, [fileSelected]);

  const handleChangeImportAccount = (newAccount: ImportAccount, index: number) => {
    setImportAccounts((importAccounts) => {
      const copyImportAccounts = importAccounts.slice();
      copyImportAccounts[index] = newAccount;
      return copyImportAccounts;
    });
  };

  const handleDeleteImportAccount = (index: number) => {
    setImportAccounts((importAccounts) => {
      const copyImportAccounts = importAccounts.slice();
      copyImportAccounts.splice(index, 1);

      return copyImportAccounts;
    });
  };

  const handleImport = async () => {
    await mutateUploadImport({
      accounts: importAccounts,
      groupId,
    });
    handleClose();
  };

  return (
    <>
      <Dialog {...props} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitleWithCloseButton onClose={handleClose}>
          Importar Plano Contábil
        </DialogTitleWithCloseButton>

        <DialogContent>
          <Stack direction="row" gap={4}>
            <label
              htmlFor="contained-button-file"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                onClick={() => setFileSelected(null)}
                onChange={handleChangeUploadXLSX}
                accept=".xlsx"
                id="contained-button-file"
                type="file"
                style={{
                  display: "none",
                }}
              />
              <LoadingButton
                loading={isLoadingImport}
                variant="contained"
                component="span"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                Selecionar arquivo
              </LoadingButton>
            </label>

            <Box>
              <Typography variant="body1">
                Faça o{" "}
                <Link href={ACCOUNT_IMPORT_MODEL} target="_blank">
                  download do modelo
                </Link>
                , preencha com seu plano de contas e faça a importação.
              </Typography>
              {fileSelected && (
                <Typography variant="body1" color="GrayText">
                  {fileSelected.name}
                </Typography>
              )}
            </Box>
          </Stack>

          <Divider sx={{ my: 2 }} />

          <ImportAccountsTable
            importAccounts={importAccounts}
            onChangeAccount={handleChangeImportAccount}
            onDeleteAccount={handleDeleteImportAccount}
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton
            disabled={!fileSelected}
            loading={isLoading}
            variant="contained"
            onClick={handleImport}
          >
            Importar
          </LoadingButton>
          <Button variant="outlined" disabled={isLoading} onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogImportAccounts;
