import { FC, useMemo, useState } from "react";
import {
  Control,
  FieldArrayWithId,
  Controller,
  Path,
  useWatch,
  UseFormSetValue,
} from "react-hook-form";

import { Box, TextField } from "@mui/material";
import MunicipalServiceAutocomplete from "../MunicipalServiceAutocomplete";
import { InvoiceForm } from ".";

interface ServiceProps {
  control: Control<InvoiceForm>;
  index: number;
  field: FieldArrayWithId<InvoiceForm, "services", "id">;
  companyToken: string;
  invoiceState: string;
  setValue: UseFormSetValue<InvoiceForm>;
  isClient: boolean;
}

const Service: FC<ServiceProps> = ({
  control,
  index,
  field,
  companyToken,
  invoiceState,
  setValue,
  isClient,
}) => {
  const [inputValue, setInputValue] = useState(field.descricao);

  const isActiveSimples =
    useWatch({
      control,
      name: "optingSimple",
    }) === "1";

  const showTaxes = useMemo(() => {
    if (!isActiveSimples && isClient && invoiceState !== "DF") {
      return true;
    }

    if (isClient && invoiceState === "DF") {
      return true;
    }

    if (!isClient) {
      return true;
    }

    return false;
  }, [isActiveSimples]);

  const taxes: { name: Path<InvoiceForm>; label: string; disabled: boolean }[] = [
    {
      label: "ISS (%)",
      name: `services.${index}.iss`,
      disabled: false,
    },
    {
      label: "IRPJ (%)",
      name: `services.${index}.irpj`,
      disabled: isActiveSimples,
    },
    {
      label: "INSS (%)",
      name: `services.${index}.irop`,
      disabled: isActiveSimples,
    },
    {
      label: "PIS (%)",
      name: `services.${index}.pis`,
      disabled: isActiveSimples && invoiceState !== "DF",
    },
    {
      label: "COFINS (%)",
      name: `services.${index}.cofins`,
      disabled: isActiveSimples && invoiceState !== "DF",
    },
    {
      label: "CSLL (%)",
      name: `services.${index}.csll`,
      disabled: isActiveSimples,
    },
  ];

  return (
    <Box display="grid" gap={2}>
      {!field.isManual && (
        <Controller
          control={control}
          name={`services.${index}.descricao`}
          render={({ field, fieldState }) => (
            <MunicipalServiceAutocomplete
              companyToken={companyToken}
              {...field}
              onChange={(_, value) => {
                field.onChange(value?.descricao);
                setValue(`services.${index}.codigo`, value?.codigo ?? "");
              }}
              textFieldProps={{
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message,
                label: "Código",
                placeholder: "Digite o Serviço Municipal",
              }}
              inputValue={inputValue}
              onInputChange={(_, newInputValue, reason) => {
                if (reason === "reset" && newInputValue === "") return;
                setInputValue(newInputValue);
              }}
            />
          )}
        />
      )}

      {field.isManual && (
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
          <Controller
            control={control}
            name={`services.${index}.cnae`}
            render={({ field }) => <TextField {...field} label="CNAE" />}
          />
          <Controller
            control={control}
            name={`services.${index}.codigo`}
            render={({ field }) => <TextField {...field} label="Código Serviço Município" />}
          />
          <Controller
            control={control}
            name={`services.${index}.lc116`}
            render={({ field }) => <TextField {...field} label="Código LC 116" />}
          />

          <Controller
            control={control}
            name={`services.${index}.descricao`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                label="Descrição"
                sx={{
                  gridColumn: "1 / -1",
                  mb: 1,
                }}
              />
            )}
          />
        </Box>
      )}

      {showTaxes && (
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
          {taxes.map((t) => (
            <Controller
              name={t.name}
              control={control}
              key={t.name}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  onChange={(value) => {
                    if (t.disabled) return;
                    field.onChange(value);
                  }}
                  label={t.label}
                  sx={{ minWidth: 100, flex: 1 }}
                  type="number"
                  disabled={t.disabled}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Service;
