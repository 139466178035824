import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { transferFinancialCategory } from "services/Requests/accountant";

const useMutationTransferFinancialCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(transferFinancialCategory, {
    onSuccess() {
      toast.success("Categoria transferida com sucesso");
      queryClient.invalidateQueries(queryKeys.globalCategoriesByGroup, { exact: false });
      queryClient.invalidateQueries(queryKeys.clientChartOfAccounts, { exact: false });
    },
  });

  return mutation;
};

export default useMutationTransferFinancialCategory;
