import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMutationDeleteTemplateAccountanting from "../hooks/useMutationDeleteTemplateAccountanting";

import Menu from "@mui/icons-material/Menu";
import {
  IconButton,
  TableCell,
  TableRow,
  Menu as DropdownMenu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Link,
} from "@mui/material";
import { TemplateAccountanting } from "types/Company";
import { LoadingButton } from "@mui/lab";

interface TemplateAccountingTableItem {
  template: TemplateAccountanting;
  onEdit: () => void;
}

const TemplateAccountingTableItem: FC<TemplateAccountingTableItem> = ({ template, onEdit }) => {
  const navigate = useNavigate();
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  const { mutateAsync, isLoading } = useMutationDeleteTemplateAccountanting();
  const [confirmationDelete, setConfirmationDelete] = useState(false);

  const handleDeleteTemplate = async () => {
    await mutateAsync({
      id: template.id,
    });
    setConfirmationDelete(false);
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Link
            href={`/contabil/${template.id}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/contabil/${template.id}`);
            }}
          >
            {template.name}
          </Link>
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={(e) => setAnchorElMenu(e.currentTarget)}>
            <Menu />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        anchorEl={anchorElMenu}
        open={menuIsOpen}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={onEdit}>Editar template</MenuItem>
        <MenuItem onClick={() => setConfirmationDelete(true)}>Excluir</MenuItem>
      </DropdownMenu>

      <Dialog
        open={confirmationDelete}
        onClose={() => setConfirmationDelete(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Excluir template</DialogTitle>
        <DialogContent>Deseja excluir este template?</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirmationDelete(false)}>
            Não
          </Button>
          <LoadingButton variant="contained" loading={isLoading} onClick={handleDeleteTemplate}>
            Sim
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TemplateAccountingTableItem;
