import { useState, useEffect } from "react";

import { Box, Button, Divider, Popover, PopoverProps, Stack, Typography } from "@mui/material";
import PeriodDate from "./PeriodDate";
import { ISODate } from "types/SemanticTypes";
import { DateTime } from "luxon";
import { DateType } from ".";

const today: string = DateTime.local().toISO();

interface PopoverDatesProps<T extends boolean = false> extends PopoverProps {
  startDateInitial?: ISODate;
  endDateInitial?: ISODate;
  erasable?: T;
  onChangeDates: (value: { start: DateType<T>; end: DateType<T> }) => void;
}

function PopoverDates<T extends boolean = false>({
  startDateInitial,
  endDateInitial,
  onChangeDates,
  erasable,
  ...props
}: PopoverDatesProps<T>) {
  const [periodStart, setPeriodStart] = useState<DateTime | null>(
    DateTime.fromISO(startDateInitial || today),
  );
  const [periodEnd, setPeriodEnd] = useState<DateTime | null>(
    DateTime.fromISO(endDateInitial || today),
  );

  const handleClose = () => {
    props?.onClose?.({}, "escapeKeyDown");
  };

  useEffect(() => {
    if (!props.open) {
      setPeriodStart(DateTime.fromISO(startDateInitial || today));
      setPeriodEnd(DateTime.fromISO(endDateInitial || today));
    }
  }, [props.open]);

  const changeForToday = () => {
    onChangeDates({
      start: DateTime.local().toISO(),
      end: DateTime.local().toISO(),
    });
    handleClose();
  };

  const changeToThisWeek = () => {
    onChangeDates({
      start: DateTime.local().startOf("week").toISO(),
      end: DateTime.local().endOf("week").toISO(),
    });
    handleClose();
  };

  const changeToThisMonth = () => {
    onChangeDates({
      start: DateTime.local().startOf("month").toISO(),
      end: DateTime.local().endOf("month").toISO(),
    });
    handleClose();
  };

  const changeToTheLastThirtyDays = () => {
    onChangeDates({
      start: DateTime.local().minus({ month: 1 }).toISO(),
      end: DateTime.local().toISO(),
    });
    handleClose();
  };

  const changeToTheNextThirtyDays = () => {
    onChangeDates({
      start: DateTime.local().toISO(),
      end: DateTime.local().plus({ month: 1 }).toISO(),
    });
    handleClose();
  };

  const changeToASpecificPeriod = () => {
    if (!periodStart || !periodEnd) return;

    onChangeDates({
      start: periodStart.toISO(),
      end: periodEnd.toISO(),
    });
    handleClose();
  };

  const handleErasableDates = () => {
    if (!erasable) return;

    onChangeDates({
      start: null as DateType<T>,
      end: null as DateType<T>,
    });
    handleClose();
  };

  return (
    <Popover
      {...props}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box display="grid" gridTemplateColumns="1fr auto 2fr" gap={2} p={2} width={400}>
        <Stack gap={1}>
          <Button size="small" variant="contained" onClick={changeForToday}>
            Hoje
          </Button>
          <Button size="small" variant="contained" onClick={changeToThisWeek}>
            Esta semana
          </Button>
          <Button size="small" variant="contained" onClick={changeToThisMonth}>
            Este mês
          </Button>
          <Button size="small" variant="contained" onClick={changeToTheLastThirtyDays}>
            Últimos 30 dias
          </Button>
          <Button size="small" variant="contained" onClick={changeToTheNextThirtyDays}>
            Próximos 30 dias
          </Button>
          {erasable && (
            <Button size="small" variant="contained" onClick={handleErasableDates}>
              Limpar
            </Button>
          )}
        </Stack>

        <Divider flexItem orientation="vertical" />

        <Box>
          <Typography variant="body1">Período</Typography>

          <Stack mt={2} gap={2}>
            <PeriodDate
              label="DE"
              maxDate={periodEnd || undefined}
              value={periodStart}
              onChange={(newValue) => setPeriodStart(newValue)}
            />
            <PeriodDate
              label="ATÉ"
              minDate={periodStart || undefined}
              value={periodEnd}
              onChange={(newValue) => setPeriodEnd(newValue)}
            />

            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" gap={1}>
              <Button size="small" onClick={() => props?.onClose?.({}, "backdropClick")}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={!periodStart || !periodEnd}
                onClick={changeToASpecificPeriod}
              >
                Aplicar
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Popover>
  );
}

export default PopoverDates;
