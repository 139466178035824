import { FC, FormEvent, useCallback, useEffect, useState } from "react";
import useMutationSaveGroupForm from "../hooks/useMutationSaveGroupForm";

import {
  Alert,
  Box,
  Button,
  Divider,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";

import FilterForm from "../components/FilterForm";
import removeItemByIndex from "utils/removeItemByIndex";
import { GroupForm as IGroupForm, GroupFormConfigs } from "types/Marketplace";

interface GroupFormProps {
  editedGroupForm?: IGroupForm | null;
  isShow?: boolean;
  onBeforeSave?: () => void;
}

const GroupForm: FC<GroupFormProps> = ({ onBeforeSave, editedGroupForm = null, isShow = true }) => {
  const { mutateAsync, isLoading } = useMutationSaveGroupForm();
  const [nameGroup, setNameGroup] = useState(editedGroupForm?.name || "");
  const [form, setForm] = useState<undefined | string>(undefined);
  const [txtContain, setTxtContain] = useState("");

  const [configs, setConfigs] = useState<GroupFormConfigs>(editedGroupForm?.configs || []);
  const [alert, setAlert] = useState<string | null>(null);

  const resetFields = useCallback(() => {
    setConfigs([]);
    setNameGroup("");
    setForm(undefined);
    setTxtContain("");
  }, []);

  useEffect(() => {
    if (isShow && editedGroupForm) {
      setConfigs((prev) => editedGroupForm?.configs || prev);
      setNameGroup((prev) => editedGroupForm?.name || prev);
    }
    if (isShow) return;

    resetFields();
  }, [isShow, editedGroupForm, resetFields]);

  const handleAddConfig = () => {
    if (!form) {
      setAlert("Selecione um formulário");
      return;
    }

    const hasSameForm = configs.some((config) => config.name === form);
    if (hasSameForm) {
      setAlert("Não selecione um formulário que já foi adicionado");
      return;
    }

    setAlert(null);

    setConfigs((prev) => [
      ...prev,
      {
        name: form,
        txtContains: txtContain,
      },
    ]);
    setForm(undefined);
    setTxtContain("");
  };

  const handleRemoveConfig = (index: number) => {
    setConfigs((prev) => removeItemByIndex(prev, index));
  };

  const handleSubmit = async () => {
    if (!nameGroup || configs.length === 0) {
      setAlert("Preencha com o nome do grupo e selecione ao menos um formulário");
      return;
    }

    setAlert(null);

    await mutateAsync({
      configs,
      name: nameGroup,
      id: editedGroupForm?.id || 0,
    });
    resetFields();
    onBeforeSave?.();
  };

  return (
    <Box>
      {!!alert && (
        <Alert severity="warning" onClose={() => setAlert(null)} sx={{ mb: 2 }}>
          {alert}
        </Alert>
      )}
      <Stack
        gap={1}
        component="form"
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          e.stopPropagation();
          if (isLoading) return;
          handleAddConfig();
        }}
      >
        <TextField
          fullWidth
          label="Nome do grupo"
          value={nameGroup}
          onChange={(e) => setNameGroup(e.target.value)}
          disabled={isLoading}
        />

        <Stack direction="row" gap={1}>
          <FilterForm
            isFilter={false}
            value={form}
            onChange={(e) => setForm(e.target.value === "all" ? undefined : e.target.value)}
            sx={{ flex: 2 }}
            disabled={isLoading}
          />
          <TextField
            fullWidth
            label="Contém o texto"
            value={txtContain}
            onChange={(e) => setTxtContain(e.target.value)}
            sx={{ flex: 2 }}
            disabled={isLoading}
          />
        </Stack>

        <Button variant="contained" type="submit" fullWidth disabled={isLoading}>
          Adicionar
        </Button>
      </Stack>

      <Divider sx={{ mt: 2, mb: 3 }} />

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {configs.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Nenhum formulário adicionado
                </TableCell>
              </TableRow>
            )}

            {configs.map((config, index) => (
              <TableRow hover key={config.name}>
                <TableCell>{config.name}</TableCell>
                <TableCell>{config.txtContains || "-"}</TableCell>
                <TableCell align="right">
                  <Tooltip title="Remover">
                    <IconButton
                      disabled={isLoading}
                      size="small"
                      onClick={() => handleRemoveConfig(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <LoadingButton variant="contained" loading={isLoading} sx={{ mt: 2 }} onClick={handleSubmit}>
        Salvar grupo
      </LoadingButton>
    </Box>
  );
};

export default GroupForm;
