import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { withdrawOsayk } from "services/Requests/billing";

const useMutationWithdrawOsayk = () => {
  const mutation = useMutation(withdrawOsayk, {
    onSuccess() {
      toast.success("Solicitação realizada com sucesso!");
    },
  });
  return mutation;
};

export default useMutationWithdrawOsayk;
