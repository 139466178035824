import { FC, useState } from "react";

import { Box, Tab, Tabs } from "@mui/material";

import BankAccounts from "./BankAccounts";
import Accounting from "./Accounting";
import Financial from "./Financial";
import TabPanel from "components/TabPanel";
import Export from "./Export";

import { CompanyAssignments } from "types/Admin";
import { Company } from "types/Company";

interface ChartOfAccountsProps {
  company: CompanyAssignments | Company;
}

const ChartOfAccounts: FC<ChartOfAccountsProps> = ({ company }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "divider",
        bgcolor: "background.paper",
      }}
      boxShadow={1}
    >
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{
          borderBottom: 1,
          borderBottomColor: "divider",
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Financeiro" />
        <Tab label="Contábil" />
        <Tab label="Contas Bancárias" />
        <Tab label="Exportação" />
      </Tabs>

      <TabPanel value={activeTab} index={0} keepMounted={false}>
        <Financial companyToken={company.token} companyId={company.id} />
      </TabPanel>
      <TabPanel value={activeTab} index={1} keepMounted>
        <Accounting companyToken={company.token} />
      </TabPanel>
      <TabPanel value={activeTab} index={2} keepMounted>
        <BankAccounts companyToken={company.token} />
      </TabPanel>
      <TabPanel value={activeTab} index={3} keepMounted>
        <Export companyToken={company.token} />
      </TabPanel>
    </Box>
  );
};

export default ChartOfAccounts;
