import { FC, useEffect } from "react";
import { useCompanyInfo } from "context/CompanyInfo";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import TextFieldCounter from "pages/Home/components/TextFieldCounter";

import formatCurrencyBRL from "utils/formatCurrencyBRL";
import useCalculatorPlan from "../../hooks/useCalculatorPlan";
import { ServicePlan } from "types/ServiceAndPlan";

interface FormFields {
  accountabilityType: string;
  regimeType: "1" | "2" | "3";
  iAgree: boolean;
  prolabore: number;
  employees: number;
  invoicing: string;
  paymentOption: string;
  value: number;
  planId?: string;
}

interface CalculatorPlanProps {
  isDefaultPlan?: boolean;
  plans: ServicePlan | ServicePlan[];
}

const CalculatorPlan: FC<CalculatorPlanProps> = ({ isDefaultPlan = false, plans }) => {
  const {
    companyStyles: { headerLinksColor },
  } = useCompanyInfo();
  const { control, setValue } = useFormContext<FormFields>();

  const regimeType = useWatch({
    control,
    name: "regimeType",
  });
  const accountabilityType = useWatch({
    control,
    name: "accountabilityType",
  });
  const invoicing = useWatch({
    control,
    name: "invoicing",
  });
  const prolabore = useWatch({
    control,
    name: "prolabore",
  });
  const employees = useWatch({
    control,
    name: "employees",
  });

  const { invoicingByRegime, monthlyPayment, defaultPlan, activesRegimes } = useCalculatorPlan({
    plans,
    accountabilityType,
    regimeType,
    employees,
    prolabore,
    selectedInvoicing: invoicing,
  });

  useEffect(() => {
    const setFirtRegime = () => {
      setValue("regimeType", activesRegimes?.[0]?.id?.toString() as "1" | "2" | "3");
    };

    if (!regimeType) {
      setFirtRegime();
      return;
    }
    const regimeExists = activesRegimes.some((r) => r.id.toString() === regimeType);
    if (!regimeExists) setFirtRegime();
  }, [activesRegimes, regimeType]);

  useEffect(() => {
    setValue("value", monthlyPayment);
    setValue("planId", defaultPlan?.iuguPlanId);
  }, [defaultPlan, monthlyPayment]);

  useEffect(() => {
    const invoicingKeys = Object.keys(invoicingByRegime);

    if (!invoicingKeys.includes(invoicing)) {
      setValue("invoicing", invoicingKeys[0]);
    }
  }, [invoicing, invoicingByRegime]);

  return (
    <Box p={2} bgcolor={grey[100]} display="grid" gap={2} mb={2}>
      {!isDefaultPlan && (
        <Controller
          name="accountabilityType"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl
              error={!!fieldState.error?.message}
              component="fieldset"
              variant="standard"
            >
              <FormLabel>Selecione o tipo de contabilidade pretendido para sua empresa</FormLabel>

              <RadioGroup {...field} row>
                <FormControlLabel value="service" control={<Radio />} label="Serviços" />
                <FormControlLabel value="business" control={<Radio />} label="Comércio" />
              </RadioGroup>
              {!!fieldState.error?.message && (
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      )}

      {activesRegimes.length > 0 && (
        <Box
          display="grid"
          gap={1}
          sx={{
            gridTemplateColumns: {
              md: "1fr 1fr",
              xs: "1fr",
            },
          }}
        >
          <Controller
            name="regimeType"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl
                error={!!fieldState.error?.message}
                component="fieldset"
                variant="standard"
              >
                <FormLabel>Selecione o regime tributário pretendido para sua empresa</FormLabel>

                <RadioGroup {...field} row>
                  {activesRegimes.map((regime) => (
                    <FormControlLabel
                      key={regime.name}
                      value={regime.id.toString()}
                      control={<Radio />}
                      label={regime.tabName}
                    />
                  ))}
                </RadioGroup>
                {!!fieldState.error?.message && (
                  <FormHelperText>{fieldState.error?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />

          <Typography variant="body2" color="text.secondary">
            {regimeType === "1" &&
              "Simples Nacional é um regime tributário facilitado e simplificado para micro e pequenas empresas, que permite o recolhimento de todos os tributos federais, estaduais e municipais em uma única guia."}
            {regimeType === "2" &&
              "No regime de Lucro Presumido o imposto é calculado com base em um percentual legalmente estabelecido sobre o valor das vendas realizadas, independentemente da apuração do lucro e variando conforme a natureza da atividade."}
          </Typography>
        </Box>
      )}

      <Box
        display="grid"
        bgcolor="white"
        sx={{
          gridTemplateColumns: {
            md: "1fr 1fr",
            xs: "1fr",
          },
        }}
      >
        <Stack alignItems="center" justifyContent="center" p={4} gap={2}>
          <Stack justifyContent="center" gap={1}>
            <Typography variant="body1" fontWeight={600} align="center">
              Quantidade de sócios com pró-labore
            </Typography>
            <Controller
              control={control}
              name="prolabore"
              render={({ field, fieldState }) => (
                <TextFieldCounter
                  {...field}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  onAdd={() => {
                    setValue("prolabore", Number(field.value ?? 0) + 1);
                  }}
                  onRemove={() => {
                    setValue("prolabore", Number(field.value ?? 0) - 1);
                  }}
                />
              )}
            />
          </Stack>
          <Stack justifyContent="center" gap={1}>
            <Typography variant="body1" fontWeight={600} align="center">
              Número de funcionários
            </Typography>
            <Controller
              control={control}
              name="employees"
              render={({ field, fieldState }) => (
                <TextFieldCounter
                  {...field}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  onAdd={() => {
                    setValue("employees", Number(field.value ?? 0) + 1);
                  }}
                  onRemove={() => {
                    setValue("employees", Number(field.value ?? 0) - 1);
                  }}
                />
              )}
            />
          </Stack>
          <Stack justifyContent="center" gap={1}>
            <Typography variant="body1" fontWeight={600} align="center">
              Faturamento mensal
            </Typography>
            <Controller
              control={control}
              name="invoicing"
              render={({ field, fieldState }) => (
                <FormControl size="small" error={!!fieldState.error?.message}>
                  <Select {...field} placeholder="Selecione o faturamento: ">
                    {invoicingByRegime.length === 0 && (
                      <MenuItem value="" disabled>
                        Nenhum faturamento encontrado
                      </MenuItem>
                    )}
                    {Object.entries(invoicingByRegime).map(([key, label]) => (
                      <MenuItem key={key} value={key}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!fieldState.error?.message && (
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Stack>
        </Stack>

        <Stack
          alignItems="center"
          justifyContent="center"
          p={4}
          bgcolor="primary.main"
          sx={{ color: headerLinksColor }}
        >
          <Typography color={headerLinksColor}>Valor do plano</Typography>
          <Typography variant="h4" fontWeight={600} color={headerLinksColor}>
            {formatCurrencyBRL(monthlyPayment)}
          </Typography>
          <small>por mês</small>

          {!isDefaultPlan && (
            <>
              <Divider sx={{ my: 2, borderColor: headerLinksColor, width: "100%" }} />

              <Typography
                variant="body2"
                color={headerLinksColor}
                component="small"
                textAlign="center"
                fontSize={12}
                maxWidth="80%"
              >
                Valor estimado com base nas informações fornecidas no cadastro. Você apenas será
                cobrado após a análise completa feita por nossos contadores.
              </Typography>
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default CalculatorPlan;
