import { useEffect } from "react";
import useQueryCounties from "pages/Home/hooks/useQueryCounties";
import useQueryStates from "pages/Home/hooks/useQueryStates";

import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";

import { Control, Controller, Path, UseFormSetValue, useWatch } from "react-hook-form";
import ControllerInput from "components/ControllerInput";
import MaskedInput from "react-text-mask";

import { Counties } from "types/CommonData";
import { zipCodeMask } from "utils/masks";

interface RequiredFields {
  street: string;
  neighborhood: string;
  zipCode: string;
  city: string;
  complement: string;
  state: string;
  streetNumber: number;
}

interface AddressFieldsProps<T extends RequiredFields = RequiredFields> {
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  onChangeCounty?: (county?: Counties) => void;
}

function AddressFields<T extends RequiredFields = RequiredFields>({
  control,
  setValue,
  onChangeCounty,
}: AddressFieldsProps<T>) {
  const state = useWatch({
    control,
    name: "state" as Path<T>,
  });
  const city = useWatch({
    control,
    name: "city" as Path<T>,
  });

  const counties = useQueryCounties(state as string);
  const states = useQueryStates();

  useEffect(() => {
    if (!counties.data) return;

    onChangeCounty?.(counties.data.find((county) => county.id === city));
  }, [counties]);

  useEffect(() => {
    if (!counties.data) return;

    const validCity = counties.data.some((country) => country.id === city);
    if (!validCity) {
      setValue("city" as Path<T>, "" as any);
    }
  }, [state, counties]);

  const getCityValue = (value: number | string | undefined) => {
    if (!counties.data) return "";
    return value ?? "";
  };

  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={400}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 2 }}
      >
        Endereço
      </Typography>

      <Stack gap={2}>
        <Stack gap={2} direction="row">
          <ControllerInput
            control={control}
            name={"street"}
            inputProps={{ label: "Logradouro", sx: { flex: 1 } }}
          />

          <Stack gap={2} direction="row" flex={1}>
            <ControllerInput
              control={control}
              name={"streetNumber"}
              inputProps={{
                label: "Número",
                sx: { flex: 1 },
                type: "number",
                inputProps: {
                  inputMode: "numeric",
                },
              }}
            />
            <ControllerInput
              control={control}
              name={"complement"}
              inputProps={{ label: "Complemento", sx: { flex: 2 } }}
            />
          </Stack>
        </Stack>

        <Stack gap={2} direction="row">
          <ControllerInput
            control={control}
            name={"neighborhood"}
            inputProps={{ label: "Bairro", sx: { flex: 1 } }}
          />

          <Controller
            control={control}
            name={"zipCode" as Path<T>}
            render={({ field, fieldState }) => (
              <MaskedInput
                mask={zipCodeMask}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="CEP"
                    variant="outlined"
                    inputRef={ref}
                    {...props}
                    sx={{ flex: 1 }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              ></MaskedInput>
            )}
          />
        </Stack>

        <Stack gap={2} direction="row">
          <FormControl sx={{ flex: 1 }}>
            <InputLabel>Estado</InputLabel>
            <Controller
              name={"state" as Path<T>}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    {...field}
                    value={!states.data ? "" : field.value ?? ""}
                    label="Estado"
                    error={!!fieldState.error?.message}
                  >
                    <MenuItem disabled value="">
                      {states.isLoading && "Carregando.."}
                      {!!states.data && "Selecione: "}
                      {states.isError && "Erro"}
                    </MenuItem>

                    {states.data &&
                      states.data.map(({ name, state }) => (
                        <MenuItem value={state} key={state}>
                          {name}
                        </MenuItem>
                      ))}
                  </Select>

                  {!!fieldState.error?.message && (
                    <Typography
                      variant="body2"
                      sx={{ ml: 2, mt: "3px", fontSize: "0.75rem" }}
                      color={red[700]}
                    >
                      {fieldState.error.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </FormControl>

          <FormControl fullWidth sx={{ flex: 1 }}>
            <InputLabel>Município</InputLabel>
            <Controller
              name={"city" as Path<T>}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    {...field}
                    value={getCityValue(field.value)}
                    onChange={(e) => {
                      field.onChange(e);

                      const value = e.target.value;
                      if (!value || !counties?.data) {
                        onChangeCounty?.();
                        return;
                      }
                      onChangeCounty?.(counties.data.find((county) => county.id === value));
                    }}
                    disabled={!counties || counties?.isLoading}
                    label="Município"
                    error={!!fieldState.error?.message}
                    endAdornment={
                      counties?.isLoading ? (
                        <CircularProgress sx={{ position: "relative", left: "-20px" }} size={20} />
                      ) : undefined
                    }
                  >
                    <MenuItem disabled value="">
                      Selecione:
                    </MenuItem>

                    {counties?.data &&
                      counties.data.map((county) => (
                        <MenuItem value={county.id} key={county.id}>
                          {county.name}{" "}
                        </MenuItem>
                      ))}
                  </Select>

                  {!!fieldState.error?.message && (
                    <Typography
                      variant="body2"
                      sx={{ ml: 2, mt: "3px", fontSize: "0.75rem" }}
                      color={red[700]}
                    >
                      {fieldState.error.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  );
}

export default AddressFields;
