import { FC, useEffect, useState } from "react";
import useQueryBalance from "../hooks/useQueryBalance";

import {
  CircularProgress,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import SearchInput from "components/SearchInput";
import WithCompany from "components/WithCompany";

interface StockBalanceProps {
  companyToken: string;
}

const StockBalance: FC<StockBalanceProps> = ({ companyToken }) => {
  const [search, setSearch] = useState("");
  const { data: balances, isLoading, isError } = useQueryBalance(companyToken, search);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!balances) return;

    if (Math.ceil(balances.length / rowsPerPage) > page ? 0 : page) {
      setPage(0);
    }
  }, [balances, page, rowsPerPage]);

  const formatNumber = (number: number, toFixed = 2, fixNonDecimals = true) => {
    const isDecimal = (n: number) => n % 1 !== 0;

    if (!fixNonDecimals && !isDecimal(number)) {
      return number.toLocaleString("pt-br");
    }

    return number.toLocaleString("pt-br", {
      minimumFractionDigits: toFixed,
      maximumFractionDigits: toFixed,
    });
  };

  return (
    <>
      <SearchInput
        value={search}
        onChange={(newSearch) => setSearch(newSearch)}
        fullWidth
        label="Buscar produto"
      />

      <Divider sx={{ my: 2 }} />

      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {isError && (
        <Typography>
          Ocorreu um error ao tentar pegar seu estoque, tente outra vez mais tarde
        </Typography>
      )}

      {balances && !isLoading && !isError && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell align="right">Estoque atual</TableCell>
                  <TableCell align="right">Estoque mínimo</TableCell>
                  <TableCell align="right">(%) Sobre estoque mínimo</TableCell>
                  <TableCell align="right">Valor estoque total (R$)</TableCell>
                  <TableCell align="right">Valor médio unitário (R$)</TableCell>
                  <TableCell align="right">Estoque inicial</TableCell>
                  <TableCell align="right">Valor estoque inicial (R$)</TableCell>
                  <TableCell align="right">Quantidade entrada</TableCell>
                  <TableCell align="right">Quantidade saída</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {balances.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={11}>Nenhum resultado</TableCell>
                  </TableRow>
                )}

                {balances
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((balance) => (
                    <TableRow key={balance.id}>
                      <TableCell>{balance.code}</TableCell>
                      <TableCell>
                        {balance.name.length >= 50
                          ? balance.name.substring(0, 50) + "..."
                          : balance.name}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          color:
                            balance.currentQuantity < balance.minimumQuantity
                              ? "error.main"
                              : undefined,
                        }}
                      >
                        {balance.currentQuantity}
                      </TableCell>
                      <TableCell align="right">
                        {formatNumber(balance.minimumQuantity, 2, false)}
                      </TableCell>
                      <TableCell align="right">
                        {formatNumber(balance.porcentageOverMinimum)}
                      </TableCell>
                      <TableCell align="right">{formatNumber(balance.currentVolume)}</TableCell>
                      <TableCell align="right">{formatNumber(balance.averageUnitValue)}</TableCell>
                      <TableCell align="right">
                        {formatNumber(balance.initialQuantity, 2, false)}
                      </TableCell>
                      <TableCell align="right">{formatNumber(balance.initialVolume)}</TableCell>
                      <TableCell align="right">
                        {formatNumber(balance.inQuantity, 2, false)}
                      </TableCell>
                      <TableCell align="right">
                        {formatNumber(balance.outQuantity, 2, false)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            labelRowsPerPage={"Linhas por páginas: "}
            labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={balances.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default WithCompany(StockBalance);
