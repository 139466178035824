import { FC, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import { Viewer } from "@react-pdf-viewer/core";
import {
  getUrlDownloadDrive,
  getUrlPreviewDocumentDrive,
} from "pages/Home/pages/Drive/utils/getUrl";

interface ContractProps {
  onNext: () => void;
  onPrev: () => void;
  downloadToken: string;
}

const Contract: FC<ContractProps> = ({ onNext, onPrev, downloadToken }) => {
  const [iAgree, setIAgree] = useState(false);

  const ErrorDocument = () => (
    <Typography variant="body1" color="error" align="center">
      Não foi possível carregar o documento.
    </Typography>
  );

  return (
    <Box>
      <Typography variant="body1">
        Leia atentamente o nosso Contrato de Prestação de Serviços e aceite os termos para
        prosseguir.
      </Typography>

      <Box
        mt={0.5}
        mb={2}
        maxHeight={500}
        sx={{ border: 1, borderColor: "divider", overflow: "auto", py: 2 }}
      >
        <Viewer
          fileUrl={getUrlPreviewDocumentDrive(downloadToken, "/Contratos+Servicos")}
          renderError={ErrorDocument}
        />
      </Box>

      <Stack
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        sx={{
          flexDirection: {
            md: "row",
            sm: "column",
          },
        }}
      >
        <Link
          target="_blank"
          href={getUrlDownloadDrive(downloadToken, "/Contratos+Servicos")}
          sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
        >
          <PictureAsPdfIcon fontSize="small" />
          Contrato em PDF
        </Link>

        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              sx={{ m: 0, height: "auto" }}
              control={
                <Checkbox
                  onChange={(_, checked) => setIAgree(checked)}
                  checked={iAgree}
                  value={iAgree}
                />
              }
              label="Concordo com o Contrato de Prestação de Serviços"
            />
          </FormGroup>
        </FormControl>
      </Stack>

      <Divider sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Button
          variant="contained"
          type="button"
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onPrev}
        >
          Anterior
        </Button>
        <Button
          disabled={!iAgree}
          variant="contained"
          onClick={onNext}
          endIcon={<KeyboardArrowRightIcon />}
        >
          Próximo passo: Método Pagamento
        </Button>
      </Stack>
    </Box>
  );
};

export default Contract;
