import { FC } from "react";
import { Control, Controller } from "react-hook-form";

import { Box, Divider, Stack, Typography } from "@mui/material";

import { PenaltiesAndDiscountsForm } from "./PenaltiesAndDiscountsForm";
import ControllerInput from "components/ControllerInput";
import CurrencyInput from "components/CurrencyInput";

interface DiscountsFieldsProps {
  control: Control<PenaltiesAndDiscountsForm>;
}

const DiscountsFields: FC<DiscountsFieldsProps> = ({ control }) => {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 1 }}
      >
        Descontos em Pagamentos Antecipados
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Alguns negócios dão desconto em caso de pagamentos realizados antecipadamente, configure uma
        automação para aplicar estas regras de negócio. O sistema irá automaticamente detectar e
        aplicar as regras quando processar o pagamento. Você pode configurar o desconto com
        porcentagem OU com o valor. Sendo que o desconto por valor sobrescreve o desconto por
        percentual, caso os dois valores estejam preenchidos.
      </Typography>

      <Stack gap={2} mt={3}>
        <ControllerInput
          control={control}
          name="discountsDays"
          inputProps={{
            label: "Dias para desconto",
            type: "number",
          }}
        />
        <ControllerInput
          control={control}
          name="discountsPercent"
          inputProps={{
            label: "Percentual de desconto",
            type: "number",
            inputProps: {
              step: "0.1",
            },
          }}
        />

        <Divider sx={{ color: "text.secondary" }}>Ou</Divider>

        <Controller
          control={control}
          name="discountsValues"
          render={({ field, fieldState }) => (
            <CurrencyInput
              {...field}
              inputProps={{
                label: "Valor",
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message,
                sx: { flex: 1 },
              }}
            />
          )}
        />
      </Stack>
    </Box>
  );
};

export default DiscountsFields;
