import { Company } from "types/Company";
import { UserResponse, UserWithCompany } from "types/User";

const normalizeUser = (userResponse: UserResponse & { company?: Company }): UserWithCompany => {
  return {
    ...userResponse,
    userInfo: {
      ...userResponse.userInfo,
      features: userResponse.userInfo.features.split(";"),
    },
  };
};

export default normalizeUser;
