import { FC } from "react";

import { Box } from "@mui/system";
import RoutesThree from "../routes/RoutesThree";

const Content: FC = () => {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#fbfbfb",
        p: 3,
        height: "100%",
        flexGrow: 1,
        overflow: "auto",
      }}
    >
      <RoutesThree />
    </Box>
  );
};

export default Content;
