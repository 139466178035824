import { FC } from "react";

import { Stack } from "@mui/material";

import BankAutoComplete from "components/BankAutoComplete";
import ControllerInput from "components/ControllerInput";
import { Control, Controller } from "react-hook-form";
import { FormCreateAccount } from ".";

interface BankFieldsProps {
  control: Control<FormCreateAccount>;
}

const BankFields: FC<BankFieldsProps> = ({ control }) => {
  return (
    <>
      <Controller
        control={control}
        name="accountBank"
        render={({ field, fieldState }) => (
          <BankAutoComplete
            sx={{ flex: 1 }}
            value={field.value}
            ref={field.ref}
            onBlur={field.onBlur}
            onChange={(_, value) => field.onChange(value)}
            textFieldProps={{
              error: !!fieldState.error?.message,
              helperText: fieldState.error?.message,
            }}
          />
        )}
      />

      <Stack gap={4} direction="row" mt={2}>
        <ControllerInput
          name="accountBranch"
          control={control}
          inputProps={{
            label: "Agência",
            sx: { flex: 1 },
          }}
        />

        <ControllerInput
          name="accountNumber"
          control={control}
          inputProps={{
            label: "Conta",
            sx: { flex: 1 },
          }}
        />
      </Stack>
    </>
  );
};

export default BankFields;
