import { FC, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import useMutationDuplicateBill from "pages/Home/hooks/useMutationDuplicateBill";
import useMutationCreateBill from "../../hooks/useMutationCreateBill";

import { Box, Button, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import CurrencyInput, { formattedInput } from "components/CurrencyInput";

import { DateTime } from "luxon";
import { Bill } from "types/Billing";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import getSubdomain from "utils/getSubdomain";

interface NewInvoiceFormProps {
  ownerId: number;
  payerCompanyId: number;
  companyToken: string;
  onCancel: () => void;
  bill?: Bill;
}

const NewInvoiceForm: FC<NewInvoiceFormProps> = ({
  onCancel,
  ownerId,
  payerCompanyId,
  companyToken,
  bill,
}) => {
  const formRef = useRef<HTMLFormElement>();
  const { mutateAsync: mutateCreate } = useMutationCreateBill();
  const { mutateAsync: mutationDuplicate } = useMutationDuplicateBill();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      value: formatCurrencyBRL(bill?.value ?? 0),
      dueDate: bill?.dueDate ?? null,
      description: bill?.description || "",
    },
  });

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    }
  }, []);

  useEffect(() => {
    if (!bill) return;
    reset({
      value: formatCurrencyBRL(bill?.value ?? 0),
      dueDate: bill?.dueDate ?? null,
      description: bill?.description || "",
    });
  }, [bill]);

  const onSubmit = handleSubmit(async (data) => {
    if (!data.dueDate || typeof data.dueDate !== "string") return;

    const date = DateTime.fromISO(data.dueDate);
    const formattedDate = date.toFormat("ccc LLL dd yyyy", { locale: "en-US" });
    const formattedValue = formattedInput(data.value);

    const runMutations = async () => {
      if (!bill) {
        await mutateCreate({
          customVariables: [{ name: "type", value: "service" }],
          dueDate: formattedDate,
          items: [
            {
              description: data.description,
              quantity: 1,
              value: formattedValue,
            },
          ],
          ownerId,
          companyToken,
          payerCompanyId,
        });
      } else {
        await mutationDuplicate({
          companyToken,
          dueDate: formattedDate,
          id: bill.id,
          item: {
            description: data.description,
            quantity: 1,
            value: formattedValue,
          },
          payerCompanyId,
          ownerId,
          subdomain: getSubdomain(),
        });
      }
    };

    await runMutations();
    if (!bill) {
      reset();
    } else {
      onCancel();
    }
  });

  return (
    <Box
      sx={{ border: 1, borderColor: "divider", p: 3 }}
      component="form"
      onSubmit={onSubmit}
      ref={formRef}
    >
      <Stack direction="row" gap={2}>
        <Controller
          name="value"
          control={control}
          render={({ field, fieldState }) => (
            <CurrencyInput
              {...field}
              inputProps={{
                sx: {
                  flex: 1,
                },
                label: "Valor",
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message,
              }}
            />
          )}
        />

        <Controller
          name="dueDate"
          control={control}
          render={({ field, fieldState }) => (
            <DesktopDatePicker
              label="Vencimento"
              inputFormat="dd/MM/yyyy"
              disablePast
              {...field}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ flex: 1 }}
                  helperText={fieldState.error?.message}
                  error={params.error || !!fieldState.error?.message}
                />
              )}
            />
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              sx={{ flex: 2 }}
              {...field}
              label="Descrição"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Stack>

      <Stack alignItems="end" justifyContent="end" direction="row" gap={1} mt={1}>
        <LoadingButton loading={isSubmitting} variant="contained" disableElevation type="submit">
          {bill ? "Gerar segunda via" : "Faturar"}
        </LoadingButton>
        <Button disabled={isSubmitting} onClick={onCancel}>
          Cancelar
        </Button>
      </Stack>
    </Box>
  );
};

export default NewInvoiceForm;
