import { FC } from "react";

import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import { DateTime } from "luxon";
import { ISODate } from "types/SemanticTypes";

export interface Signature {
  description: string;
  isRecurrent: boolean;
  dueDate: ISODate;
  id: string;
  value?: number;
}

interface SignaturesTableProps {
  signatures: Signature[];
  onDeleteSignature: (id: string) => void;
}

const SignaturesTable: FC<SignaturesTableProps> = ({ signatures, onDeleteSignature }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Descrição</TableCell>
          <TableCell>Valor</TableCell>
          <TableCell>Vencimento</TableCell>
          <TableCell>Recorrente</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {signatures.map(({ id, description, dueDate, isRecurrent, value = 0 }) => (
          <TableRow key={id}>
            <TableCell>{description}</TableCell>
            <TableCell>
              {value.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
            </TableCell>
            <TableCell>{DateTime.fromISO(dueDate).toFormat("dd/MM/yyyy")}</TableCell>
            <TableCell>{isRecurrent ? "Sim" : "Não"}</TableCell>
            <TableCell>
              <IconButton size="small" onClick={() => onDeleteSignature(id)}>
                <Delete fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SignaturesTable;
