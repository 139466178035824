import { FC, useEffect, useState } from "react";
import useMutationAddViewLeadForm from "../../hooks/useMutationAddViewLeadForm";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { Lead } from "types/Marketplace";
import getSubdomain from "utils/getSubdomain";

const typographyStyle: SxProps<Theme> = {
  p: 1.5,
  borderBottom: 1,
  borderColor: "divider",
};

interface DialogContactProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  lead: Lead;
}

const DialogContact: FC<DialogContactProps> = ({ onClose, lead, ...props }) => {
  const { mutateAsync, isLoading } = useMutationAddViewLeadForm();
  const [obs, setObs] = useState("");

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  useEffect(() => {
    setObs(lead.obs || "");
  }, [lead]);

  const handleAdd = async () => {
    await mutateAsync({
      leadId: lead.id,
      subdomain: getSubdomain(),
      obs,
    });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>Contato</DialogTitleWithCloseButton>

      <DialogContent>
        <Box border={1} borderColor="divider">
          <Typography variant="body1" sx={typographyStyle}>
            <Typography component="strong" variant="inherit">
              Nome:
            </Typography>{" "}
            {lead.name}
          </Typography>
          <Typography variant="body1" sx={typographyStyle}>
            <Typography component="strong" variant="inherit">
              Email:
            </Typography>{" "}
            {lead.email}
          </Typography>
          <Typography variant="body1" sx={typographyStyle}>
            <Typography component="strong" variant="inherit">
              Telefone:
            </Typography>{" "}
            {lead.phone}
          </Typography>

          <Box p={2}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Observações"
              value={obs}
              onChange={(e) => setObs(e.target.value)}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleAdd}>
          Salvar
        </LoadingButton>
        <Button variant="outlined" disabled={isLoading} onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogContact;
