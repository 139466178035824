import { FC } from "react";

import { Stack, Typography } from "@mui/material";
import { CompanyParticipants } from "types/Company";
import { NameOfRoles, translateNameOfRoles } from "..";

import ClientItem from "./ClientItem";

interface ClientListCardProps {
  companyParticipants: CompanyParticipants[];
  onEdit: (participant: CompanyParticipants) => void;
  role: NameOfRoles;
  isQueryError: boolean;
  companyToken: string;
}

const ClientListCard: FC<ClientListCardProps> = ({
  companyParticipants,
  onEdit,
  role,
  companyToken,
  isQueryError,
}) => {
  return (
    <Stack gap={2}>
      {companyParticipants.length === 0 && (
        <Typography>{translateNameOfRoles[role]} não foram cadastrados</Typography>
      )}
      {isQueryError && (
        <Typography>Ocorreu um error ao buscar seus {translateNameOfRoles[role]}</Typography>
      )}

      {companyParticipants.map((client) => (
        <ClientItem
          key={client.id}
          client={client}
          onEdit={() => onEdit(client)}
          companyToken={companyToken}
        />
      ))}
    </Stack>
  );
};

export default ClientListCard;
