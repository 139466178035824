import { FC } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { LoadingButton } from "@mui/lab";

import { Controller, useForm } from "react-hook-form";
import { DREAnalytical } from "types/ManagerialIncomeStatement";
import useQueryGetSyntheticAccounts from "../../hooks/useQueryGetSyntheticAccounts";
import useMutationUpdateSyntheticAccounts from "../../hooks/useMutationUpdateSyntheticAccounts";

interface DialogCreateOrEditAnalyticalProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  dre?: DREAnalytical;
  parentId: number;
  company: any;
}

const DialogCreateOrEditAnalytical: FC<DialogCreateOrEditAnalyticalProps> = ({
  dre,
  onClose,
  parentId,
  company,
  ...props
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      title: dre?.title ?? "",
      parentId,
      id: dre?.id
    },
  });

  
  const { data: drelist = []} = useQueryGetSyntheticAccounts(company.token);
  const { mutateAsync, isLoading } = useMutationUpdateSyntheticAccounts();

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      companyToken: company.token,
      parentId: data.parentId,
      title: data.title,
      id: data.id
    });
    handleClose();
  });

  const handleClose = () => { 

    if (!isLoading && onClose) {
      reset({
        title: "",
        parentId
      });
      onClose();
    }
    
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {dre ? "Editar da" : "Nova"} Conta Analitica DRE
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          <Controller
            control={control}
            name="parentId"
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={!!fieldState.error?.message}>
                <InputLabel>Escolha a Conta Sintética</InputLabel>
                <Select {...field} label="Escolha a Conta Sintética" value={field.value}>
                  <MenuItem disabled>Selecione: </MenuItem>
                  {drelist.map((d) => (
                    <MenuItem value={d.id} key={d.id}>
                      {d.account}
                    </MenuItem>
                  ))}
                </Select>
                {!!fieldState.error?.message && (
                  <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="title"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Descrição da Conta Analítica"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                fullWidth
                sx={{ mt: 2 }}
              />
            )}
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" loading={isLoading} variant="contained">
            Salvar
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose} disabled={isSubmitting}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreateOrEditAnalytical;
