import { ChangeEvent, FC, useEffect, useState } from "react";
import useMutationImportClients from "./hooks/useMutationImportClients";
import TableResultImport from "./TableResultImport";

import { CLIENT_IMPORT_MODEL } from "utils/constants";
import getSubdomain from "utils/getSubdomain";
import toast from "react-hot-toast";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const ImportClients: FC = () => {
  const [dialogImportClientsIsOpen, setDialogImportClientsIsOpen] = useState(false);
  const [fileSelected, setFileSelected] = useState<File | null>(null);
  const { mutate, data: importClientsResult, isLoading } = useMutationImportClients();

  const openImportClientDialog = () => setDialogImportClientsIsOpen(true);
  const closeImportClientDialog = () => setDialogImportClientsIsOpen(false);

  const handleChangeUploadXLSX = (e: ChangeEvent<HTMLInputElement>) => {
    const targetEl = e.target;

    if (!targetEl.files || targetEl.files.length === 0) {
      toast("Selecione um arquivo", {
        icon: "⚠️",
      });
      return;
    }

    setFileSelected(targetEl.files[0]);
  };

  useEffect(() => {
    if (!fileSelected) return;

    mutate({
      subdomain: getSubdomain(),
      file: fileSelected,
    });
  }, [fileSelected]);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        disableElevation
        startIcon={<FileUploadIcon />}
        onClick={openImportClientDialog}
      >
        Importar clientes
      </Button>

      <Dialog
        open={dialogImportClientsIsOpen}
        onClose={closeImportClientDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Importar Clientes</DialogTitle>
        <DialogContent dividers>
          <Stack direction="row" gap={4} mb={1}>
            <label
              htmlFor="contained-button-file"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                onClick={() => setFileSelected(null)}
                onChange={handleChangeUploadXLSX}
                accept=".xlsx"
                id="contained-button-file"
                type="file"
                style={{
                  display: "none",
                }}
              />
              <LoadingButton
                loading={isLoading}
                variant="contained"
                component="span"
                disableElevation
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                Selecionar arquivo
              </LoadingButton>
            </label>

            <Box>
              <Typography variant="body1">
                Faça o{" "}
                <Link href={CLIENT_IMPORT_MODEL} target="_blank">
                  download do modelo
                </Link>
                , preencha com os dados dos clientes sem alterar a posição das colunas.
              </Typography>
              {fileSelected && (
                <Typography variant="body1" color="GrayText">
                  {fileSelected.name}
                </Typography>
              )}
            </Box>
          </Stack>

          {importClientsResult && <TableResultImport importClientsResult={importClientsResult} />}
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={closeImportClientDialog}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportClients;
