import { FC, useEffect, useState } from "react";
import useMutationExportTo from "../../hooks/useMutationExportTo";
import useQueryListFilesIntegration from "../../hooks/useQueryListFilesIntegration";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";

import FilesTable from "./FilesTable";
import FilterPeriod from "components/FilterPeriod";

import { DateTime } from "luxon";
import { TypeExport } from "services/Requests/template";
import { getUrlDownloadDrive } from "pages/Home/pages/Drive/utils/getUrl";
import DialogFileHistory from "./DialogFileHistory";

interface ExportProps {
  companyToken: string;
}

const Export: FC<ExportProps> = ({ companyToken }) => {
  const { mutateAsync, isLoading: isLoadingExport } = useMutationExportTo();

  const [date, setDate] = useState({
    start: DateTime.local().startOf("month").toISO(),
    end: DateTime.local().endOf("month").toISO(),
  });
  const [selectedFileId, setSelectedFileId] = useState<number[]>([]);
  const [templateId, setTemplateId] = useState("SAGE");
  const [dateType, setDateType] = useState<undefined | "C">(undefined);
  const [allStatus, setAllStatus] = useState<boolean>(false);

  const [showFileHistory, setShowFileHistory] = useState<boolean>(false);

  const {
    data: files,
    isLoading,
    isFetching,
    isError,
  } = useQueryListFilesIntegration(companyToken, date.start, date.end, allStatus, dateType);

  const handleToggleSelected = (event: unknown, checked: boolean, id: number) => {
    if (checked) {
      setSelectedFileId((prev) => [...prev, id]);
      return;
    }
    setSelectedFileId((prev) => prev.filter((p) => p !== id));
  };

  const handleSelectAllClick = () => {
    if (!files) return;

    setSelectedFileId((prev) => {
      if (prev.length === files.length) {
        return [];
      }
      return files.map((f) => f.id);
    });
  };

  useEffect(() => {
    if (!files) return;

    const filesIds = files.map((f) => f.id);
    setSelectedFileId((prev) => prev.filter((p) => filesIds.includes(p)));
  }, [files]);

  const handleExport = async () => {
    if (selectedFileId.length === 0 || !files) return;

    const filesSelected = files.filter((f) => selectedFileId.includes(f.id));

    const fileToken = await mutateAsync({
      companyToken,
      endDate: new Date(date.end).toDateString(),
      startDate: new Date(date.start).toDateString(),
      releases: filesSelected,
      templateId: templateId as TypeExport,
    });

    window.open(getUrlDownloadDrive(fileToken, "/Integracao"), "_blank")?.focus();
  };

  return (
    <>
      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {isError && <Typography>Ocorreu um erro ao buscar os arquivos.</Typography>}

      {!isLoading && !isError && files && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} pb={2}>
            <Stack direction="row" gap={1}>
              <FilterPeriod
                startDate={date.start}
                endDate={date.end}
                onChangeDates={(newDates) => setDate(newDates)}
                label="Selecionar período para exportar"
              />

              <FormControl size="small">
                <InputLabel>Data</InputLabel>
                <Select
                  label="Data"
                  value={dateType ?? "all"}
                  onChange={(e) => setDateType(e.target.value === "all" ? undefined : "C")}
                >
                  <MenuItem value="all">---</MenuItem>
                  <MenuItem value="C">Competência</MenuItem>
                </Select>
              </FormControl>

              <FormControl size="small">
                <InputLabel>Mostrar</InputLabel>
                <Select
                  label="Mostrar"
                  value={allStatus ? "all" : "some"}
                  onChange={(e) => setAllStatus(e.target.value === "all")}
                >
                  <MenuItem value="all">Todos</MenuItem>
                  <MenuItem value="some">Alguns</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack direction="row" gap={1} mt={2}>
              <FormControl size="small" disabled={isLoadingExport}>
                <InputLabel>Selecione o layout</InputLabel>
                <Select
                  label="Selecione o layout"
                  value={templateId}
                  onChange={(e) => setTemplateId(e.target.value)}
                >
                  <MenuItem value="ALTERDATATXT">ALTERDATA (.txt)</MenuItem>
                  <MenuItem value="DOMINIO">DOMINIO</MenuItem>
                  <MenuItem value="QUESTOR">QUESTOR (.txt)</MenuItem>
                  <MenuItem value="QUESTORCSV">QUESTOR (.csv)</MenuItem>
                  <MenuItem value="SAGE">SAGE</MenuItem>
                </Select>
              </FormControl>

              <LoadingButton
                variant="contained"
                startIcon={<DownloadIcon fontSize="small" />}
                size="small"
                disabled={!selectedFileId.length}
                loading={isLoadingExport}
                onClick={handleExport}
              >
                exportar selecionados ({selectedFileId.length})
              </LoadingButton>
              <Button variant="contained" size="small" onClick={() => setShowFileHistory(true)}>
                Histórico arquivos exportados
              </Button>
            </Stack>
          </Box>

          <Collapse in={!isLoading && isFetching}>
            <LinearProgress sx={{ mb: 1 }} />
          </Collapse>

          <FilesTable
            files={files}
            selectedFileId={selectedFileId}
            onToggleSelected={handleToggleSelected}
            onSelectAllClick={handleSelectAllClick}
            companyToken={companyToken}
          />
        </>
      )}

      {showFileHistory && (
        <DialogFileHistory
          companyToken={companyToken}
          open={showFileHistory}
          onClose={() => setShowFileHistory(false)}
        />
      )}
    </>
  );
};

export default Export;
