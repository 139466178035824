import { useContext } from "react";
import { FilterPeriodContext } from ".";

export const useFilterPeriod = () => {
  const ctx = useContext(FilterPeriodContext);

  if (!ctx) {
    throw new Error("useFilterPeriod must be used within a FilterPeriodProvider");
  }

  return ctx;
};
