import { FC, useEffect, useState } from "react";
import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import ClientTableItem from "./ClientTableItem";

import stableSort from "utils/stableSort";
import comparatorSortByProperty from "utils/comparatorSortByProperty";
import SortableTableHead from "components/SortableTableHead";
import { MinimalistClient, NormalizedClient } from "types/Client";
import { UseQueryResult } from "react-query";

type Order = "asc" | "desc";
type DataTable = keyof MinimalistClient;

interface HeadCells {
  label: string;
  id: DataTable;
}

const headCells: HeadCells[] = [
  {
    label: "Nome",
    id: "companyName",
  },
  {
    label: "Responsável",
    id: "owner",
  },
  {
    label: "Email",
    id: "ownerEmail",
  },
  {
    label: "Telefone",
    id: "phone",
  },
  {
    label: "Criado em",
    id: "createdOn",
  },
  {
    label: "Saldo",
    id: "balance",
  },
];

interface TableClientsProps {
  clientsQuery: UseQueryResult<NormalizedClient[], unknown>;
}

const TableClients: FC<TableClientsProps> = ({ clientsQuery }) => {
  const { isLoading, data = [], isError } = clientsQuery;

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<DataTable>("companyName");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortHandler = (property: DataTable) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (Math.ceil(data.length / rowsPerPage) > page ? 0 : page) {
      setPage(0);
    }
  }, [data, page, rowsPerPage]);

  return (
    <>
      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <SortableTableHead
            headCells={headCells}
            onRequestSort={(e, property) => sortHandler(property)}
            order={order}
            orderBy={orderBy}
            tableCellProps={{ align: "center" }}
          >
            <TableCell align="center"></TableCell>
          </SortableTableHead>

          <TableBody>
            {isLoading && (
              <TableRow sx={{ width: "100%" }}>
                <TableCell colSpan={8}>
                  <Stack alignItems="center" justifyContent="center">
                    <CircularProgress />
                  </Stack>
                </TableCell>
              </TableRow>
            )}

            {data.length === 0 && !isLoading && (
              <TableRow sx={{ width: "100%" }}>
                <TableCell colSpan={7}>
                  <Typography variant="body1">Nenhum resultado encontrado</Typography>
                </TableCell>
              </TableRow>
            )}

            {isError && (
              <TableRow sx={{ width: "100%" }}>
                <TableCell colSpan={7}>
                  <Typography variant="body1">
                    Ocorrreu um erro inesperado, tente novamente mais tarde
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {stableSort(data, comparatorSortByProperty(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((client) => (
                <ClientTableItem client={client} key={client.token} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage={"Linhas por páginas: "}
        labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TableClients;
