import { FC } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { LoadingButton } from "@mui/lab";

import { DREAnalytical } from "types/ManagerialIncomeStatement";
import useMutationDeleteSyntheticAccounts from "../../hooks/useMutationDeleteSyntheticAccounts";

interface DialogDeleteAnalyticalProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  dre?: DREAnalytical;
  parentId: number;
  company: any;
}

const DialogDeleteAnalytical: FC<DialogDeleteAnalyticalProps> = ({
  dre,
  onClose,
  parentId,
  company,
  ...props
}) => {

  const { mutateAsync, isLoading } = useMutationDeleteSyntheticAccounts();

  const handleDelete = async () => {
    await mutateAsync({
      companyToken: company.token,
      parentId: dre?.parentId,
      id: dre?.id
    });
    handleClose();
  };

  const handleClose = () => {
    if (!isLoading && onClose) {
      onClose();
    }
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>Excluir conta</DialogTitleWithCloseButton>

      <DialogContent>Deseja excluir esta conta analítica?</DialogContent>

      <DialogActions>
        <LoadingButton onClick={handleDelete} variant="contained">
          Sim
        </LoadingButton>
        <Button variant="outlined" onClick={handleClose}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteAnalytical;
