import { FC, useState } from "react";

import { Box, Table, TableBody, TableCell, TableContainer, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import SortableTableHead from "components/SortableTableHead";
import SignatureTableItem from "./SignatureTableItem";

import { NormalizeSignature } from "types/Marketplace";
import { Order } from "types/SemanticTypes";
import stableSort from "utils/stableSort";
import comparatorSortByProperty from "utils/comparatorSortByProperty";

type SignatureKeys = keyof NormalizeSignature;

interface HeadCells {
  label: string;
  id: SignatureKeys;
  sortable: boolean;
}

const headCells: HeadCells[] = [
  {
    id: "customer_name",
    label: "Cliente",
    sortable: true,
  },
  {
    id: "plan_name",
    label: "Assinatura",
    sortable: true,
  },
  {
    id: "expires_at",
    label: "Expirar em",
    sortable: true,
  },
  {
    id: "total",
    label: "Total",
    sortable: true,
  },
];

interface SignatureTableProps {
  signature: NormalizeSignature[];
}

const SignatureTable: FC<SignatureTableProps> = ({ signature }) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<SignatureKeys>("customer_name");

  const sortHandler = (property: SignatureKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (signature.length === 0) {
    return (
      <Box p={2} bgcolor={grey[200]}>
        <Typography variant="body1">Nenhuma assinatura encontrada.</Typography>
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table>
        <SortableTableHead
          headCells={headCells}
          onRequestSort={(e, property) => sortHandler(property)}
          order={order}
          orderBy={orderBy}
        >
          <TableCell align="center"></TableCell>
        </SortableTableHead>

        <TableBody>
          {stableSort(signature, comparatorSortByProperty(order, orderBy)).map((signatureItem) => (
            <SignatureTableItem key={signatureItem.id} signature={signatureItem} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SignatureTable;
