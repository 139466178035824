import { FC, useState } from "react";
import useQueryChat from "pages/Home/hooks/useQueryChat";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  LinearProgress,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";

import DialogCloseTicket from "./components/DialogCloseTicket";

import comparatorSortByProperty from "utils/comparatorSortByProperty";
import stableSort from "utils/stableSort";
import { DateTime } from "luxon";
import DialogTicket from "pages/Home/components/DialogTicket";

interface ComunicationTableProps {
  companyToken: string;
}

const ComunicationTable: FC<ComunicationTableProps> = ({ companyToken }) => {
  const { isLoading, isFetching, data } = useQueryChat(companyToken);
  const [ticketIdToClose, setTicketIdToClose] = useState<null | number>(null);
  const [ticketIdToAction, setTicketIdToAction] = useState<null | number>(null);

  return (
    <Box>
      <Collapse in={!isLoading && isFetching}>
        <LinearProgress />
      </Collapse>

      <Box p={2}>
        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={400}>
            <CircularProgress />
          </Stack>
        )}

        {!isLoading && data && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Situação</TableCell>
                  <TableCell>Assunto</TableCell>
                  <TableCell>Atribuído</TableCell>
                  <TableCell>Atualizado em</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {data.chats.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>Nenhuma mensagem encontrada.</TableCell>
                  </TableRow>
                )}

                {stableSort(data.chats, comparatorSortByProperty("desc", "updated")).map((chat) => (
                  <TableRow key={chat.id} hover>
                    <TableCell>{chat.isClosed ? "Encerrada" : "Aberta"}</TableCell>
                    <TableCell>{chat.subject}</TableCell>
                    <TableCell>{chat.responsable}</TableCell>
                    <TableCell>
                      {DateTime.fromISO(chat.updated).toFormat("dd/MM/yyyy HH:mm")}
                    </TableCell>

                    <TableCell align="right">
                      <Stack
                        direction="row"
                        gap={0.5}
                        alignItems="center"
                        justifyContent="end"
                        flexWrap="wrap"
                      >
                        {!chat.isClosed && (
                          <Button
                            variant="contained"
                            startIcon={<ReplyIcon fontSize="small" />}
                            size="small"
                            onClick={() => setTicketIdToAction(chat.id)}
                          >
                            Responder
                          </Button>
                        )}
                        {chat.isClosed && (
                          <Button
                            variant="contained"
                            startIcon={<SearchIcon fontSize="small" />}
                            size="small"
                            onClick={() => setTicketIdToAction(chat.id)}
                          >
                            Visualizar
                          </Button>
                        )}

                        <Button
                          variant="contained"
                          disabled={chat.isClosed}
                          size="small"
                          startIcon={<CheckIcon />}
                          onClick={() => {
                            if (chat.isClosed) return;
                            setTicketIdToClose(chat.id);
                          }}
                        >
                          Fechar solicitação
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      {!!ticketIdToClose && (
        <DialogCloseTicket
          open={!!ticketIdToClose}
          onClose={() => setTicketIdToClose(null)}
          ticketId={ticketIdToClose}
          companyToken={companyToken}
        />
      )}

      {!!ticketIdToAction && (
        <DialogTicket
          open={!!ticketIdToAction}
          onClose={() => setTicketIdToAction(null)}
          ticketId={ticketIdToAction}
          companyToken={companyToken}
        />
      )}
    </Box>
  );
};

export default ComunicationTable;
