import endpoints from "../endpoints";
import publicApi from "../publicApi";
import api from "../api";

import {
  Client,
  ClientDetails,
  ClientPaymentMethod,
  ImportClient,
  NormalizedClient,
} from "types/Client";
import normalizeClient from "utils/normalizeClient";
import objectToFormData from "utils/objectToFormData";
import { Plan } from "types/Plan";
import { BillingSubsAccounts } from "types/Billing";
import {
  IntervalType,
  ServiceAndPlan,
  ServicePremiumPrice,
  ServiceTypes,
} from "types/ServiceAndPlan";
import { HTMLString, ISODate } from "types/SemanticTypes";
import { TypesOfUsers } from "types/UserTypes";
import { CompanyStyles } from "types/Company";
import {
  ClientsConfigLeads,
  GroupFormConfigs,
  GroupFormResponse,
  GroupsForm,
  LeadResponse,
  Leads,
  SignatureResponse,
} from "types/Marketplace";

interface PathImage {
  companyId: number;
  key: null | string;
  value: string | null;
}

export const getPathImageByType = async (subdomain: string, type: "urlLogin" | "urlTop") => {
  const { data } = await publicApi.get<PathImage>(endpoints.marketplace.pathImage, {
    params: {
      key: type,
      subdomain,
    },
  });

  return data;
};

export const getCompanyStyles = async (subdomain: string): Promise<CompanyStyles> => {
  const { data } = await publicApi.get(endpoints.marketplace.styles, {
    params: {
      subdomain,
    },
  });

  // the result comes as a string, not as json
  return JSON.parse(data) as CompanyStyles;
};

export interface UpdateCompanyStyles {
  companyToken: string;
  styles: CompanyStyles;
}

export const updateCompanyStyles = async (payload: UpdateCompanyStyles) => {
  await api.post(endpoints.marketplace.styles, {
    companyToken: payload.companyToken,
    json: JSON.stringify(payload.styles),
  });
};

export const getTypeSubdomain = async (subdomain: string): Promise<"accountant" | "admin"> => {
  const { data } = await publicApi.get(endpoints.marketplace.typeSubdomain, {
    params: {
      key: "subdomain",
      subdomain,
    },
  });

  return data?.value.toLowerCase();
};

export interface GetClientsPayload {
  verificationStatus: "all" | "unverified" | "on-approval" | "approved";
  registrationStatus?: number | null;
  status?: number | null;
}

const transformVerificationStatus: {
  [key in GetClientsPayload["verificationStatus"]]: null | number;
} = {
  all: null,
  unverified: 0,
  "on-approval": 1,
  approved: 2,
};

export const getClients = async (payload: GetClientsPayload): Promise<NormalizedClient[]> => {
  const { data } = await api.post<Client[]>(endpoints.marketplace.clients, {
    ...payload,
    verificationStatus: transformVerificationStatus[payload.verificationStatus],
  });

  return data.map((client) => normalizeClient(client));
};

interface ImportClientsPayload {
  subdomain: string;
  file: File;
}

export const importClients = async (payload: ImportClientsPayload): Promise<ImportClient[]> => {
  const { data } = await api.post(endpoints.marketplace.import, objectToFormData(payload));
  return data;
};

interface GetClientDetails {
  payerCompanyId: number;
  subdomain: string;
}

export const getClientDetails = async (payload: GetClientDetails): Promise<ClientDetails> => {
  const { data } = await api.post(endpoints.marketplace.clientDetails, {
    ...payload,
    maxResults: 100,
  });

  return data;
};

interface GetClientPaymentMethodPayload {
  ownerId: number;
  payerCompanyId: number;
  subdomain: string;
}

export const getClientPaymentMethod = async (
  payload: GetClientPaymentMethodPayload,
): Promise<ClientPaymentMethod> => {
  const { data } = await api.post(endpoints.marketplace.clientPaymentMethod, payload);
  return data;
};

interface GetPlansPayload {
  subdomain: string;
}

export const getPlans = async (payload: GetPlansPayload): Promise<Plan[]> => {
  const { data } = await api.post(endpoints.marketplace.plans, payload);
  return data;
};

export const getAllSubAccounts = async (): Promise<BillingSubsAccounts[]> => {
  const { data } = await api.post<BillingSubsAccounts[]>(endpoints.marketplace.allSubAccounts);
  return data;
};

export interface GetServicesAndPlansPayload {
  subdomain: string;
}

export const getServicesAndPlans = async (
  payload: GetServicesAndPlansPayload,
): Promise<ServiceAndPlan> => {
  const { data } = await api.post<ServiceAndPlan>(endpoints.marketplace.servicesAndPlans, payload);
  return data;
};

export interface CreateUpdateCustomServicePayload {
  bankSlip: boolean;
  companyToken: string;
  creditCard: boolean;
  downloadToken: string | null;
  filename: string | null;
  description: HTMLString;
  documentToken: string;
  id: number;
  name: string;
  quantity: number;
  userType: string;
  isActive: boolean;
  valueCents: number;
  isExpirationDateCreationDay?: boolean;
  parcel?: number;
  daysAfter?: number;
}

export const createUpdateCustomService = async (payload: CreateUpdateCustomServicePayload) => {
  await api.post(endpoints.marketplace.updateCustomService, payload);
};

export interface DeleteCustomServicePayload {
  companyToken: string;
  serviceId: number;
}

export const deleteCustomService = async (payload: DeleteCustomServicePayload) => {
  await api.post(endpoints.marketplace.deleteCustomService, payload);
};

export interface UpdateDefaultServicePayload {
  valueCents: number;
  iuguPlanId: string;
  daysToExpire: null | number;
  premiumPrices: ServicePremiumPrice[];
  id: number;
  name: string;
  serviceTexts: {
    startText: HTMLString;
    endText: HTMLString;
  };
  serviceType: ServiceTypes;
  status: boolean;
  isActive: boolean;
  contractId: number;
  contractName: null | string;
  filename: string | null;
  downloadToken: string | null;
  userType: TypesOfUsers | "NotSet";
  global: boolean;
  agreement: {
    downloadToken: string | null;
    filename: string | null;
  };
  companyToken: string;
  startText: HTMLString;
  endText: HTMLString;
  documentToken: string | null;
  subdomain: string;
  groupName?: string;
}

export const updateDefaultService = async (payload: UpdateDefaultServicePayload) => {
  await api.post(endpoints.marketplace.updateDefaultService, payload);
};

export interface UpdateDefaultPlanPayload {
  bankSlip: boolean;
  companyToken: string;
  creditCard: boolean;
  description: HTMLString;
  documentToken: string;
  endText: HTMLString;
  expiresAt: string;
  interval: number | string;
  intervalType: null | IntervalType;
  isActive: boolean;
  isAutomaticBilling: boolean;
  isExpirationDateCreationDay: boolean;
  name: string;
  payableWith: null;
  payrollValue: number;
  planId: number;
  proLaboreValue: number;
  tierPriceInfoes: {
    active: boolean;
    id: number;
    name: string;
    calcIntervals: {
      from: number;
      id?: number;
      price: number;
      until: number;
    }[];
  }[];
  usePayroll: boolean;
  useProLabore: boolean;
}

export const updateDefaultPlan = async (payload: UpdateDefaultPlanPayload) => {
  await api.post(endpoints.marketplace.updateDefaultPlan, payload);
};

export interface GetAllSubsPayload {
  maxResults: number;
  query: null | string;
  start: 0;
  status: "due" | "suspended" | "active";
  subdomain: string;
}

export const getAllSubs = async (payload: GetAllSubsPayload) => {
  const { data } = await api.post<SignatureResponse>(endpoints.marketplace.getAllSubs, payload);
  return data;
};

export interface GetLeadsPayload {
  endDate: ISODate;
  startDate: ISODate;
  subdomain: string;
  term?: string;
  typeForm?: string;
}

export const getLeads = async (payload: GetLeadsPayload): Promise<Leads> => {
  const { data } = await api.post<LeadResponse[] | null>(endpoints.marketplace.getLeads, {
    ...payload,
    term: payload.term || "",
  });

  if (!data) return [];

  const normalizeData: Leads = data.map((d) => ({
    ...d,
    data: JSON.parse(d.data),
    customFields: JSON.parse(d.customFields),
  }));
  return normalizeData;
};

export const getForms = async (): Promise<string[] | null> => {
  const { data } = await api.post<string[] | null>(endpoints.marketplace.getForms);
  return data;
};

export const getGroupsForm = async (): Promise<GroupsForm> => {
  const { data } = await api.post<GroupFormResponse[]>(endpoints.marketplace.getGroupsForm);

  const normalize: GroupsForm = data.map((d) => ({ ...d, configs: JSON.parse(d.configs) }));
  return normalize;
};

export interface SaveGroupFormPayload {
  id?: number;
  name: string;
  configs: GroupFormConfigs;
}

export const saveGroupForm = async ({ id = 0, configs, name }: SaveGroupFormPayload) => {
  await api.post(endpoints.marketplace.saveGroupForm, {
    id,
    name,
    configs: JSON.stringify(configs),
  });
};

export interface GetClientsConfigLeadsPayload {
  form: number;
  name: string;
}

export const getClientsConfigLeads = async (
  payload: GetClientsConfigLeadsPayload,
): Promise<ClientsConfigLeads> => {
  const { data } = await api.post<ClientsConfigLeads>(
    endpoints.marketplace.getClientsConfigLeads,
    payload,
  );
  return data;
};

export interface AddFormPayload {
  formName: number;
  ownerId: number;
}

export const addForm = async (payload: AddFormPayload) => {
  await api.post(endpoints.marketplace.addForm, payload);
};

export type RemoveFormPayload = AddFormPayload;

export const removeForm = async (payload: RemoveFormPayload) => {
  await api.post(endpoints.marketplace.removeForm, payload);
};

export interface AddViewLeadFormPaylaod {
  leadId: number;
  subdomain: string;
  obs: null | string;
}

export const addViewLeadForm = async (payload: AddViewLeadFormPaylaod) => {
  await api.post(endpoints.marketplace.addViewLeadForm, payload);
};

export interface GetClientPayload {
  companyToken: string;
}

export const getClient = async (payload: GetClientPayload): Promise<NormalizedClient[]> => {
  const { data } = await api.post<NormalizedClient[]>(endpoints.marketplace.getClient, payload);
  return data;
};

export interface GetServicesAndPlansClientPayload {
  companyToken: string;
  subdomain: string;
}

export const getServicesAndPlansClient = async (payload: GetServicesAndPlansClientPayload) => {
  const { data } = await api.post<ServiceAndPlan>(
    endpoints.marketplace.getServicesAndPlansClient,
    payload,
  );
  return data;
};
