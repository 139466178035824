import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { addOrderOpenCompany } from "services/Requests/openCompany";

const useMutationAddOrderOpenCompany = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(addOrderOpenCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.ordersByCompany);
    },
  });
  return mutation;
};

export default useMutationAddOrderOpenCompany;
