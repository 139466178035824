import { FC } from "react";
import useQueryClient from "pages/Home/hooks/useQueryClient";

import { Box, Stack, CircularProgress, Typography } from "@mui/material";

import { CompanyAssignments } from "types/Admin";
import Subscriptions from "pages/Home/pages/Marketplace/components/Clients/TableClients/components/Subscriptions";
import { ClientInformationProvider } from "pages/Home/pages/Marketplace/components/Clients/context/ClientInformation";
import { Company } from "types/Company";

interface SignatureProps {
  company: CompanyAssignments | Company;
}

const Signature: FC<SignatureProps> = ({ company }) => {
  const { data: client, isLoading, isSuccess, isError } = useQueryClient(company.token);

  return (
    <Box>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}

      {client && !isLoading && (
        <ClientInformationProvider client={client} companyToken={company.token}>
          <Subscriptions withPlan={false} />
        </ClientInformationProvider>
      )}

      {!client && isSuccess && !isLoading && <Typography>Nenhum resultado encontrado</Typography>}
      {isError && !isLoading && (
        <Typography>Ocorreu um error ao tentar pegar os dados, tente mais tarde</Typography>
      )}
    </Box>
  );
};

export default Signature;
