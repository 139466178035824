import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { uploadDocumentsApproveDrive } from "services/Requests/companies";

const useMutationUploadDocumentsApproveDrive = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(uploadDocumentsApproveDrive, {
    onSuccess(_, { companyToken }) {
      queryClient.invalidateQueries([queryKeys.infoAccount, companyToken]);
    },
  });
  return mutation;
};

export default useMutationUploadDocumentsApproveDrive;
