import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { saveGroupForm } from "services/Requests/marketplace";

const useMutationSaveGroupForm = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(saveGroupForm, {
    onSuccess(_, { id }) {
      const isCreate = id === 0;
      toast.success(`Grupo ${isCreate ? "criado" : "editado"} com sucesso`);
      queryClient.invalidateQueries(queryKeys.groups);
      queryClient.invalidateQueries(queryKeys.groupsForm);
    },
  });
  return mutation;
};

export default useMutationSaveGroupForm;
