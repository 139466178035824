import { FC, PropsWithChildren } from "react";
import { Box, DialogTitle, DialogTitleProps, IconButton, Stack, Tooltip } from "@mui/material";
import Close from "@mui/icons-material/Close";

interface DialogTitleWithCloseButtonProps extends DialogTitleProps {
  onClose: () => void;
}

const DialogTitleWithCloseButton: FC<PropsWithChildren<DialogTitleWithCloseButtonProps>> = ({
  onClose,
  children,
  ...props
}) => {
  return (
    <DialogTitle {...props}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box>{children}</Box>
        {onClose && (
          <Tooltip title="Fechar">
            <IconButton onClick={onClose}>
              <Close fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </DialogTitle>
  );
};

export default DialogTitleWithCloseButton;
