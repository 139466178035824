import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { createGroup, CreateGroupPayload } from "services/Requests/mailbox";
import getSubdomain from "utils/getSubdomain";

const useMutationCreateGroup = () => {
  const subdomain = getSubdomain();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload: Omit<CreateGroupPayload, "subdomain">) => createGroup({ ...payload, subdomain }),
    {
      onSuccess() {
        toast.success("Grupo criado com sucesso");
        queryClient.invalidateQueries(queryKeys.groups);
      },
    },
  );
  return mutation;
};

export default useMutationCreateGroup;
