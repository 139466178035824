import useMutationCreateFinancialCategory from "../../hooks/useMutationCreateFinancialCategory";
import useMutationUpdateFinancialCategory from "../../hooks/useMutationUpdateFinancialCategory";
import useMutationCreateCompanyFinancialCategory from "../../hooks/useMutationCreateCompanyFinancialCategory";
import useMutationUpdateCompanyFinancialCategory from "../../hooks/useMutationUpdateCompanyFinancialCategory";
import { useForm, Controller } from "react-hook-form";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { FinancialCategoryByGroup, FinancialCategoryByGroupWithChildren } from "types/Company";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import { DREAnalytical } from "types/DRE";

interface DialogCreateOrUpdateAnalyticalCategoryProps<T extends boolean>
  extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  parentCategoryId: number;
  financialCategories: FinancialCategoryByGroupWithChildren[];
  templateId?: T extends true ? undefined : number;
  companyToken?: T extends true ? string : undefined;
  companyId?: T extends true ? number : undefined;
  financialCategory?: FinancialCategoryByGroup;
  dreAnalitycalAccounts?: DREAnalytical[];
  isCompany?: T;
}

function DialogCreateOrUpdateAnalyticalCategory<T extends boolean>({
  onClose,
  financialCategory,
  parentCategoryId,
  templateId,
  companyId,
  companyToken,
  isCompany = false as T,
  financialCategories,
  dreAnalitycalAccounts,
  ...props
}: DialogCreateOrUpdateAnalyticalCategoryProps<T>) {
  const { mutateAsync: mutateCreateFinancial } = useMutationCreateFinancialCategory();
  const { mutateAsync: mutateCreateCompanyFinancial } = useMutationCreateCompanyFinancialCategory();

  const { mutateAsync: mutateUpdateFinancial } = useMutationUpdateFinancialCategory();
  const { mutateAsync: mutateUpdateCompanyFinancial } = useMutationUpdateCompanyFinancialCategory();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: financialCategory?.name || "",
      parentCategoryId: parentCategoryId,
      dreId: financialCategory?.dreId,
    },
  });

  const handleClose = () => {
    if (isSubmitting) return;
    reset({
      name: financialCategory?.name || "",
    });
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    const runMutations = async () => {
      if (financialCategory) {
        const baseUpdate = {
          creditCx: financialCategory.creditCx,
          creditProv: financialCategory.creditProv,
          debitCx: financialCategory.debitCx,
          debitProv: financialCategory.debitProv,
          id: financialCategory.id,
          isPartnerActivity: financialCategory.isPartnerActivity,
          isVisibleFinancialReport: financialCategory.isVisibleFinancialReport,
          name: data.name,
          type: "item" as const,
          parentCategoryId: data.parentCategoryId,
          parentId: data.parentCategoryId,
          dreId: data?.dreId
        };

        if (isCompany) {
          await mutateUpdateCompanyFinancial({
            ...baseUpdate,
            companyToken: companyToken as string,
            companyId: companyId as number,
          });
        } else {
          await mutateUpdateFinancial({
            ...baseUpdate,
            groupId: templateId as number,
          });
        }
      } else {
        if (isCompany) {
          await mutateCreateCompanyFinancial({
            companyToken: companyToken as string,
            parentCategoryId: data.parentCategoryId,
            name: data.name,
          });
        } else {
          await mutateCreateFinancial({
            groupId: templateId as number,
            parentCategoryId: data.parentCategoryId,
            name: data.name,
          });
        }
      }
    };

    await runMutations();
    handleClose();
  });

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {financialCategory ? "Editar" : "Adicionar"} categoria analítica
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          {financialCategory && (
            <Controller
              control={control}
              name="parentCategoryId"
              render={({ field, fieldState }) => (
                <FormControl error={!!fieldState.error?.message} fullWidth sx={{ mb: 2 }}>
                  <InputLabel error={!!fieldState.error?.message}>
                    Nome da categoria sintética
                  </InputLabel>
                  <Select
                    {...field}
                    label="Nome da categoria sintética"
                    error={!!fieldState.error?.message}
                    value={field.value || ""}
                  >
                    <MenuItem disabled value="">
                      Selecione:{" "}
                    </MenuItem>

                    {financialCategories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!fieldState.error?.message && (
                    <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          )}

          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Nome da categoria analítica"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                fullWidth
              />
            )}
          />

          <Controller
            control={control}
            name="dreId"
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState.error?.message} fullWidth sx={{ mt: 2 }}>
                <InputLabel error={!!fieldState.error?.message}>
                  Categoria na DRE Gerencial
                </InputLabel>
                <Select
                  {...field}
                  label="Categoria na DRE Gerencial"
                  error={!!fieldState.error?.message}
                  value={field.value || ""}
                >
                  {dreAnalitycalAccounts?.map((dre) => (
                      <MenuItem key={dre.id} value={dre.id}>
                        {dre.title}
                      </MenuItem>
                    ))}
                </Select>
                {!!fieldState.error?.message && (
                  <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" loading={isSubmitting} variant="contained">
            Salvar
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose} disabled={isSubmitting}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DialogCreateOrUpdateAnalyticalCategory;
