import { FC, useState } from "react";

import { Button, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import ComunicationTable from "pages/Home/pages/Comunication/ComunicationTable";
import { CompanyAssignments } from "types/Admin";
import { Company } from "types/Company";
import DialogNewTicket from "../../DialogNewTicket";

interface ComunicationProps {
  company: CompanyAssignments | Company;
}

const Comunication: FC<ComunicationProps> = ({ company }) => {
  const [createTicketIsOpen, setCreateTicketIsOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setCreateTicketIsOpen(true)}
      >
        Nova Solicitação
      </Button>

      <Divider sx={{ my: 1 }} />

      <ComunicationTable companyToken={company.token} />

      {createTicketIsOpen && (
        <DialogNewTicket
          open={createTicketIsOpen}
          onClose={() => setCreateTicketIsOpen(false)}
          companyToken={company.token}
        />
      )}
    </>
  );
};

export default Comunication;
