import { FC } from "react";
import { useReactToPrint } from "react-to-print";

import { Button, Stack, StackProps } from "@mui/material";
import { grey } from "@mui/material/colors";

import toast from "react-hot-toast";
import TableToExcel from "@linways/table-to-excel";

interface TableActionsProps extends StackProps {
  tableRef: HTMLTableElement;
  containerTableRef: HTMLElement;
  nameExcel: string;
  nameTable: string;
}

const TableActions: FC<TableActionsProps> = ({
  containerTableRef,
  tableRef,
  nameExcel,
  nameTable,
  ...props
}) => {
  const downlaodTable = () => {
    if (!tableRef) {
      toast.error("Tabela não encontrada");
    }

    TableToExcel.convert(tableRef, {
      name: `${nameExcel}.xlsx`,
      sheet: {
        name: "relatório",
      },
    });
  };

  const reactToPrintContent = () => containerTableRef;

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: nameTable,
    removeAfterPrint: true,
  });

  return (
    <Stack
      bgcolor={grey[200]}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      gap={1}
      {...props}
    >
      <Button onClick={downlaodTable} variant="contained">
        Exportar
      </Button>
      <Button variant="contained" onClick={handlePrint}>
        Imprimir
      </Button>
    </Stack>
  );
};

export default TableActions;
