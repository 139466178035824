import { FC, useState } from "react";
import useMutationCancelBill from "../../hooks/useMutationCancelBill";

import {
  TableRow,
  TableCell,
  IconButton,
  Stack,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";

import { Bill } from "types/Billing";
import { DateTime } from "luxon";

interface InvoiceTableItemProps {
  bill: Bill;
  payerCompanyId: number;
  companyToken: string;
  onInvoiceSelected: (bill: Bill) => void;
}

const InvoiceTableItem: FC<InvoiceTableItemProps> = ({
  bill,
  payerCompanyId,
  companyToken,
  onInvoiceSelected,
}) => {
  const [dialogCancelBillIsOpen, setDialogCancelBillIsOpen] = useState(false);
  const { mutate, isLoading } = useMutationCancelBill();

  return (
    <>
      <TableRow
        sx={{
          opacity: isLoading ? 0.5 : 1,
          transition: "opacity 350ms ease",
          "*": {
            pointerEvents: isLoading ? "none" : "all",
          },
        }}
      >
        <TableCell sx={{ px: 1 }}>
          <Tooltip title="Visualizar detalhes da fatura">
            <a href={bill.detailsUrl} target="_blank" rel="noreferrer">
              <IconButton size="small">
                <Search fontSize="small" />
              </IconButton>
            </a>
          </Tooltip>
        </TableCell>

        <TableCell>{bill.description}</TableCell>

        <TableCell>{DateTime.fromISO(bill.dueDate).toFormat("dd/MM/yyyy")}</TableCell>
        <TableCell>
          {bill.payDate ? DateTime.fromISO(bill.payDate).toFormat("dd/MM/yyyy") : "-"}
        </TableCell>
        <TableCell>-</TableCell>
        <TableCell>
          {bill.value.toLocaleString("pt-bt", {
            style: "currency",
            currency: "BRL",
          })}
        </TableCell>
        <TableCell>{bill.status}</TableCell>
        <TableCell>{bill.transactionNumber}</TableCell>
        <TableCell padding="none">
          <Stack direction="row" justifyContent="flex-end">
            {bill.status !== "Paga" && bill.status !== "Cancelada" && (
              <Tooltip title="Segunda via de fatura">
                <IconButton size="small" onClick={() => onInvoiceSelected(bill)}>
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {bill.status !== "Cancelada" && (
              <Tooltip title="Cancelar fatura">
                <IconButton size="small" onClick={() => setDialogCancelBillIsOpen(true)}>
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </TableCell>
      </TableRow>

      <Dialog
        open={dialogCancelBillIsOpen}
        onClose={() => setDialogCancelBillIsOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Cancelar fatura</DialogTitle>

        <DialogContent dividers>Tem certeza que deseja cancelar essa fatura?</DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => setDialogCancelBillIsOpen(false)}>
            Não
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setDialogCancelBillIsOpen(false);
              mutate({ id: bill.id, payerCompanyId, companyToken });
            }}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvoiceTableItem;
