import { FC, useState } from "react";

import { IconButton, MenuItem, TableCell, TableRow } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import DialogDeleteProduct from "../components/DialogDeleteProduct";
import DialogUpdateProduct from "../components/DialogUpdateOrCreateProduct";
import DropdownMenu from "components/DropdownMenu";

import { Product } from "types/StockControl";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface ProductItemProps {
  product: Product;
  companyToken: string;
}

const ProductItem: FC<ProductItemProps> = ({ product, companyToken }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  return (
    <>
      <TableRow hover>
        <TableCell>{product.code}</TableCell>
        <TableCell>{product.name}</TableCell>
        <TableCell align="right">{product.initialQuantity}</TableCell>
        <TableCell align="right">{formatCurrencyBRL(product.averageUnitValue || 0)}</TableCell>
        <TableCell align="right">{product.minimumQuantity}</TableCell>
        <TableCell align="right">{formatCurrencyBRL(product.totalValue || 0)}</TableCell>
        <TableCell align="right">
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MenuIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setIsEdit(true)}>Editar</MenuItem>
        <MenuItem onClick={() => setIsDelete(true)}>Excluir</MenuItem>
      </DropdownMenu>

      {isDelete && (
        <DialogDeleteProduct
          open={isDelete}
          onClose={() => setIsDelete(false)}
          companyToken={companyToken}
          productId={product.id}
        />
      )}

      {isEdit && (
        <DialogUpdateProduct
          open={isEdit}
          onClose={() => setIsEdit(false)}
          product={product}
          companyToken={companyToken}
        />
      )}
    </>
  );
};

export default ProductItem;
