import { ChangeEvent, FC } from "react";

import { Checkbox, Chip, TableCell, TableRow } from "@mui/material";

import { DateTime } from "luxon";
import { LatestTransactions } from "types/Dashboard";
import getLabelStatus from "pages/Home/utils/getLabelStatus";
import getStatusColor from "pages/Home/utils/getStatusColor";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface FileTableItemProps {
  file: LatestTransactions;
  selected: boolean;
  onToggleSelected: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  getBankName: (id: number) => string;
}

const FileTableItem: FC<FileTableItemProps> = ({
  file,
  onToggleSelected,
  selected,
  getBankName,
}) => {
  return (
    <TableRow
      selected={selected}
      hover={!file.exported}
      sx={{ bgcolor: file.exported ? "#fffed1" : undefined }}
    >
      <TableCell padding="checkbox">
        <Checkbox size="small" checked={selected} onChange={onToggleSelected} />
      </TableCell>
      <TableCell>
        {DateTime.fromISO(
          !!file.paymentDate && file.type === "PR" ? file.paymentDate : file.dueDate2,
        ).toFormat("dd/MM/yyyy")}
      </TableCell>
      <TableCell>{file.categoryName}</TableCell>
      <TableCell>{file.participantName || file.partnerName}</TableCell>
      <TableCell>{file.description}</TableCell>
      <TableCell>{getBankName(file.companyBankAccountId)}</TableCell>
      <TableCell sx={{ color: file.transactionType === "Debt" ? "error.main" : "success.main" }}>
        {formatCurrencyBRL(file.value)}
      </TableCell>
      <TableCell>
        <Chip label={getLabelStatus(file)} color={getStatusColor(file)} />
      </TableCell>
    </TableRow>
  );
};

export default FileTableItem;
