import { FC } from "react";
import { TypesOfUsers } from "types/UserTypes";

import Accounting from "../pages/Accounting";
import AccountingItem from "../pages/AccountingItem";
import AccountingUsers from "../pages/AccountingUsers";
import CollectionManagement from "../pages/CollectionManagement";
import ConfigurationNFSe from "../pages/ConfigurationNFSe";
import Dashboard from "../pages/Dashboard";
import Drive from "../pages/Drive";
import Financial from "../pages/Financial";
import FinancialItem from "../pages/FinancialItem";
import FinancialAccounting from "../pages/FinancialAccounting";
import InventoryControl from "../pages/InventoryControl";
import Invoices from "../pages/Invoices";
import Marketplace from "../pages/Marketplace";
import MonthClosure from "../pages/MonthClosure";
import NFSe from "../pages/NFSe";
import ProfileRegistrations from "../pages/ProfileRegistrations";
import Releases from "../pages/Releases";
import Requests from "../pages/Requests";
import Settings from "../pages/Settings";
import UserRegistrations from "../pages/UserRegistrations";
import Users from "../pages/Users";
import Solicitation from "../pages/Solicitation";
import Services from "../pages/Services";
import Profile from "../pages/Profile";
import ChangePassword from "../pages/ChangePassword";
import CompanyAccount from "../pages/CompanyAccount";
import RegistrationClients from "../pages/RegistrationClients";
import Customers from "../pages/Customers";
import Categories from "../pages/Categories";
import PartnersDetails from "../pages/PartnersDetails";
import ImportRelease from "../pages/ImportRelease";
import UserInvoices from "../pages/UserInvoices";
import InvoicesSetUp from "../pages/InvoicesSetUp";
import AdminUsers from "../pages/AdminUsers";
import AdminCompany from "../pages/AdminCompany";
import Comunication from "../pages/Comunication";
import Notification from "../pages/Notification";
import Layouts from "../pages/Layouts";
import Layout from "../pages/Layout";
import IntegratedImport from "../pages/IntegratedImport";
import OnlineAccounting from "../pages/OnlineAccounting";
import ManagerialIncomeStatement from "../pages/ManagerialIncomeStatement";
import ManagerialIncomeStatementReport from "../pages/ManagerialIncomeStatementReport";

interface PathRoutes {
  path: string;
  Component: FC;
  index?: number;
  type?: TypesOfUsers | TypesOfUsers[];
  forNewUser?: boolean;
  ignoreRootSubdomain?: boolean;
}

const pathRoutes: PathRoutes[] = [
  {
    path: "/servicos",
    Component: Services,
    type: ["Guest", "User"],
    forNewUser: true,
  },
  {
    path: "/drive",
    Component: Drive,
    type: ["Guest", "User"],
    forNewUser: true,
    ignoreRootSubdomain: true,
  },
  {
    path: "/pedidos",
    Component: Solicitation,
    type: ["Guest", "User"],
    forNewUser: true,
    ignoreRootSubdomain: true,
  },
  {
    path: "/comunicacao",
    Component: Comunication,
    type: "User",
  },
  {
    path: "/notificacoes",
    Component: Notification,
    type: "User",
  },
  {
    path: "/dashboard",
    type: "User",
    Component: Dashboard,
    index: 33,
    forNewUser: false,
  },
  {
    path: "/lancamentos",
    type: "User",
    index: 100,
    Component: Releases,
    forNewUser: false,
  },
  {
    path: "/controle-de-estoque",
    type: "User",
    index: 101,
    Component: InventoryControl,
    forNewUser: false,
  },
  {
    path: "/drive",
    type: "User",
    index: 114,
    Component: Drive,
    forNewUser: false,
  },
  {
    path: "/fechamento-do-mes",
    type: "User",
    index: 115,
    Component: MonthClosure,
    forNewUser: false,
  },
  {
    path: "/relatorios/resumo-financeiro",
    type: "User",
    index: 107,
    Component: FinancialAccounting,
    forNewUser: false,
  },
  {
    path: "/relatorios/dre-gerencial",
    type: "User",
    Component: ManagerialIncomeStatementReport,
    forNewUser: false,
  },
  {
    path: "/nfs-e/notas-fiscais",
    index: 117,
    type: "User",
    Component: UserInvoices,
    forNewUser: false,
  },
  {
    path: "/nfs-e/configurar",
    index: 118,
    type: "User",
    Component: InvoicesSetUp,
    forNewUser: false,
  },
  {
    path: "/contas",
    index: 120,
    type: "User",
    Component: CompanyAccount,
    forNewUser: false,
  },
  {
    path: "/clientes",
    type: "User",
    index: 121,
    Component: RegistrationClients,
    forNewUser: false,
  },
  {
    path: "/fornecedores",
    type: "User",
    index: 122,
    Component: Customers,
    forNewUser: false,
  },
  {
    path: "/categorias",
    type: "User",
    index: 123,
    Component: Categories,
    forNewUser: false,
  },
  {
    path: "/socios",
    type: "User",
    index: 124,
    Component: PartnersDetails,
    forNewUser: false,
  },
  {
    path: "/administrativo-usuarios",
    type: "User",
    index: 127,
    Component: AdminUsers,
    forNewUser: false,
  },
  {
    path: "/administrativo-empresas",
    type: "User",
    index: 128,
    Component: AdminCompany,
    forNewUser: false,
  },
  {
    path: "/administrativo-perfis",
    type: "User",
    index: 129,
    Component: ProfileRegistrations,
    forNewUser: false,
  },
  {
    path: "/servicos",
    index: 130,
    type: "User",
    Component: Services,
    forNewUser: false,
  },
  {
    path: "/contabilidade-online",
    Component: OnlineAccounting,
    type: "Accountant",
  },
  {
    path: "/configuracoes",
    Component: Settings,
    index: 1,
    type: ["Admin", "Accountant"],
    forNewUser: false,
  },
  {
    path: "/marketplace",
    Component: Marketplace,
    index: 8,
    type: ["Admin", "Accountant"],
    forNewUser: false,
  },
  {
    path: "/importar-lancamentos",
    Component: ImportRelease,
    type: "User",
    forNewUser: false,
  },
  {
    path: "/importacao-integrada",
    Component: IntegratedImport,
    type: "User",
    forNewUser: false,
  },
  {
    path: "/gestao-de-cobranca",
    Component: CollectionManagement,
    index: 12,
    type: ["Admin", "Accountant", "User"],
    forNewUser: false,
  },
  {
    path: "/notas-fiscais",
    Component: Invoices,
    index: 21,
    type: ["Admin", "Accountant"],
    forNewUser: false,
  },

  {
    path: "/usuarios",
    Component: Users,
    type: "Admin",
    forNewUser: false,
  },
  {
    path: "/solicitacoes",
    Component: Requests,
    index: 31,
    type: ["Admin", "Accountant"],
    forNewUser: false,
  },
  {
    path: "/cadastros-usuarios",
    Component: UserRegistrations,
    index: 25,
    type: "Accountant",
    forNewUser: false,
  },
  {
    path: "/cadastros-perfis",
    Component: ProfileRegistrations,
    index: 26,
    type: "Accountant",
    forNewUser: false,
  },
  {
    path: "/financeiro",
    index: 28,
    Component: Financial,
    type: ["Admin", "Accountant"],
    forNewUser: false,
  },
  {
    path: "/dre-gerencial",
    Component: ManagerialIncomeStatement,
    type: ["Admin", "Accountant"],
    forNewUser: false,
  },
  {
    path: "/financeiro/:groupId/:id",
    index: 28,
    Component: FinancialItem,
    type: ["Admin", "Accountant"],
    forNewUser: false,
  },
  {
    path: "/contabil",
    index: 29,
    Component: Accounting,
    type: ["Admin", "Accountant"],
    forNewUser: false,
  },
  {
    path: "/contabil/:id",
    index: 29,
    Component: AccountingItem,
    type: ["Admin", "Accountant"],
    forNewUser: false,
  },
  {
    path: "/layout-exportacao",
    index: 131,
    Component: Layouts,
    type: ["Admin", "Accountant"],
    forNewUser: false,
  },
  {
    path: "/layout/:id",
    index: 131,
    Component: Layout,
    type: ["Admin", "Accountant"],
    forNewUser: false,
  },
  {
    path: "/meu-perfil",
    Component: Profile,
  },
  {
    path: "/alterar-senha",
    Component: ChangePassword,
  },
];

export const pathRoutesCompany: PathRoutes[] = [
  { path: "/usuarios-contabilidade", Component: AccountingUsers, type: "Admin" },
  { path: "/dashboard", Component: Dashboard },
  { path: "/lancamentos", Component: Releases },
  { path: "/controle-de-estoque", Component: InventoryControl },
  { path: "/drive", Component: Drive },
  { path: "/fechamento-do-mes", Component: MonthClosure },
  { path: "/financeiros", Component: FinancialAccounting },
  { path: "/nfs-e", Component: NFSe },
  { path: "/configuracoes-nfs-e", Component: ConfigurationNFSe },
  { path: "/contas", Component: CompanyAccount },
  { path: "/clientes", Component: RegistrationClients },
  { path: "/fornecedores", Component: Customers },
  { path: "/categorias", Component: Categories },
  { path: "/socios", Component: PartnersDetails },
  { path: "/importar-lancamentos", Component: ImportRelease },
  { path: "/importacao-integrada", Component: IntegratedImport },
];

export default pathRoutes;
