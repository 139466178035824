import { FC, useEffect, useState } from "react";
import useQueryUserAdminList from "./hooks/useQueryUserAdminList";

import comparatorSortByProperty from "utils/comparatorSortByProperty";
import SortableTableHead from "components/SortableTableHead";
import UserTableItem from "./UserTableItem";
import stableSort from "utils/stableSort";

import Search from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { Order } from "types/SemanticTypes";
import { UserAdmin } from "types/User";
import useDebounce from "hooks/useDebounce";
import DialogCreateOrUpdateAdminUser from "../DialogCreateOrUpdateAdminUser";

type AdminUsersKeys = keyof UserAdmin;

interface HeadCells {
  label: string;
  id: AdminUsersKeys;
}

const headCells: HeadCells[] = [
  {
    label: "Nome",
    id: "name",
  },
  {
    label: "Login",
    id: "login",
  },
  {
    label: "Email",
    id: "email",
  },
  {
    label: "Status",
    id: "status",
  },
];

export type UserTypeContent = "accountant" | "admin" | "Guest";

export const userTypeTranslate: { [x in UserTypeContent]: string } = {
  accountant: "contador",
  admin: "administrador",
  Guest: "usuário",
};

interface UsersContentProps {
  companyToken: string;
  userType: UserTypeContent;
}

const UsersContent: FC<UsersContentProps> = ({ companyToken, userType }) => {
  const [searchUserAdmin, setSearchUserAdmin] = useState("");
  const searchValue = useDebounce(searchUserAdmin, 500);

  const {
    data = [],
    isLoading,
    isError,
    isFetching,
  } = useQueryUserAdminList(companyToken, searchValue);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<AdminUsersKeys>("name");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [userForEdit, setUserForEdit] = useState<null | UserAdmin>(null);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortHandler = (property: AdminUsersKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (Math.ceil(data.length / rowsPerPage) > page ? 0 : page) {
      setPage(0);
    }
  }, [data, page, rowsPerPage]);

  return (
    <Box position="relative">
      {!isLoading && isFetching && (
        <Box position="absolute" top={60} right={0}>
          <CircularProgress size={20} />
        </Box>
      )}

      <TextField
        size="small"
        fullWidth
        value={searchUserAdmin}
        onChange={(e) => setSearchUserAdmin(e.target.value)}
        disabled={isLoading}
        InputProps={{
          placeholder: `Buscar ${userTypeTranslate[userType]}`,
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize="small" />
            </InputAdornment>
          ),
        }}
      />

      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <SortableTableHead
            headCells={headCells}
            onRequestSort={(e, property) => sortHandler(property)}
            order={order}
            orderBy={orderBy}
          >
            <TableCell align="center"></TableCell>
          </SortableTableHead>

          <TableBody>
            {isLoading && (
              <TableRow sx={{ width: "100%" }}>
                <TableCell colSpan={5}>
                  <Stack alignItems="center" justifyContent="center">
                    <CircularProgress />
                  </Stack>
                </TableCell>
              </TableRow>
            )}

            {data.length === 0 && !isLoading && (
              <TableRow sx={{ width: "100%" }}>
                <TableCell colSpan={5}>
                  <Typography variant="body1">Nenhum resultado encontrado</Typography>
                </TableCell>
              </TableRow>
            )}

            {isError && (
              <TableRow sx={{ width: "100%" }}>
                <TableCell colSpan={7}>
                  <Typography variant="body1">
                    Ocorrreu um erro inesperado, tente novamente mais tarde
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {stableSort(data, comparatorSortByProperty(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <UserTableItem
                  companyToken={companyToken}
                  user={user}
                  key={user.id}
                  onEditUser={(user) => setUserForEdit(user)}
                  userType={userType}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {userForEdit && (
        <DialogCreateOrUpdateAdminUser
          companyToken={companyToken}
          open={!!userForEdit}
          onClose={() => setUserForEdit(null)}
          user={userForEdit}
          userType={userType}
        />
      )}

      <TablePagination
        labelRowsPerPage={"Linhas por páginas: "}
        labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default UsersContent;
