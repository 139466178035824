import { FC, useState } from "react";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import Head from "components/Head";
import TabNavigation, { TabNavigationProps } from "components/TabNavigation";

import getSubdomain from "utils/getSubdomain";
import { FilterPeriodProvider } from "./contexts/FilterPeriod";

import FinancialSummary from "./FinancialSummary";
import CashFlow from "./CashFlow";
import BillsToReceive from "./BillsToReceive";
import BillsReceived from "./BillsReceived";
import BillsToPay from "./BillsToPay";
import PaidBills from "./PaidBills";

const tabs: TabNavigationProps["tabs"] = [
  {
    name: "Resumo Financeiro",
    slug: "resumo-financeiro",
    Component: FinancialSummary,
  },
  {
    name: "Fluxo de caixa",
    slug: "fluxo-de-caixa",
    Component: CashFlow,
  },
  {
    name: "Contas a receber",
    slug: "contas-a-receber",
    Component: BillsToReceive,
  },
  {
    name: "Contas recebidas",
    slug: "contas-recebidas",
    Component: BillsReceived,
  },
  {
    name: "Contas a pagar",
    slug: "contas-a-pagar",
    Component: BillsToPay,
  },
  {
    name: "Contas pagas",
    slug: "contas-pagas",
    Component: PaidBills,
  },
];

const FinancialAccounting: FC = () => {
  const [tabActive, setTabActive] = useState("1");

  const handleChangeTab = (newValue: string) => {
    setTabActive(newValue);
  };

  return (
    <>
      <Head title={`Relatórios Financeiros | ${getSubdomain()}`} />

      <Typography variant="h4" fontWeight="bold">
        Relatórios Financeiros
      </Typography>

      <FilterPeriodProvider>
        <Box mt={2} boxShadow={2} bgcolor="#fff">
          <TabNavigation tabActive={tabActive} tabs={tabs} handleChangeTab={handleChangeTab} />
        </Box>
      </FilterPeriodProvider>
    </>
  );
};

export default FinancialAccounting;
