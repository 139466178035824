import React, { FC, PropsWithChildren, useMemo, useState } from "react";
import { useAuth } from "context/Auth";
import { useCompanyInfo } from "context/CompanyInfo";
import { useNavigate } from "react-router-dom";
import { DRAWER_WIDTH, HEADER_HEIGHT } from "utils/constants";

import {
  Avatar,
  Menu,
  Button,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  Typography,
  Toolbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Logout from "@mui/icons-material/Logout";
import ReplyIcon from "@mui/icons-material/Reply";
import Person from "@mui/icons-material/Person";

import RenderLogo from "../RenderLogo";
import Comunication from "./Comunication";
import Notification from "./Notification";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import useNavigateHomePage from "hooks/useNavigateHomePage";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header: FC = () => {
  const navigate = useNavigate();
  const { user, logout, changeToPreviousUser, previousUser } = useAuth();

  const { imageTopDashboard, companyStyles } = useCompanyInfo();
  const navigatePrimaryPath = useNavigateHomePage();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const isNewAccountant = useMemo(
    () =>
      user.company?.registrationStatus === "Registered" && user.userInfo.userType === "Accountant",
    [user],
  );
  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const TypographyWithCustomColor: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <Typography
      variant="body2"
      sx={{ textTransform: "capitalize", color: companyStyles.headerLinksColor }}
    >
      {children}
    </Typography>
  );

  return (
    <>
      <AppBar
        position="sticky"
        color="transparent"
        sx={{
          bgcolor: companyStyles.headerBg,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: "65px",
          top: 0,
          bottom: 0,
        }}
      >
        <Toolbar sx={{ height: HEADER_HEIGHT + "px" }}>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigatePrimaryPath();
            }}
          >
            <RenderLogo
              src={imageTopDashboard || ""}
              alt="logo da empresa"
              width={200}
              height={50}
              style={{
                objectFit: "contain",
              }}
            />
          </a>

          <Stack marginLeft="auto" direction="row" alignItems="center" gap={1.5} height="100%">
            {!!user.company && user.userInfo.userType === "User" && (
              <>
                <Comunication companyToken={user.company.token} />
                <Notification />
              </>
            )}

            <Button
              onClick={(e) => {
                e.preventDefault();
                handleOpen(e);
              }}
              sx={{ height: "100%" }}
            >
              <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                <Avatar sx={{ width: "30px", height: "30px" }} color="action">
                  {user.userInfo.name.charAt(0)}
                </Avatar>
                <TypographyWithCustomColor>{user.userInfo.name}</TypographyWithCustomColor>
              </Stack>
            </Button>

            {!!previousUser && (
              <IconButton
                color="info"
                onClick={changeToPreviousUser}
                sx={{ height: "100%", borderRadius: 0 }}
              >
                <ReplyIcon />
              </IconButton>
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            if (isNewAccountant) return;
            navigate("/meu-perfil");
          }}
        >
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          Perfil
        </MenuItem>

        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>
    </>
  );
};

export default Header;
