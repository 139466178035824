import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { createUpdateCustomService } from "services/Requests/marketplace";

const useMutationCreateUpdateCustomService = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createUpdateCustomService, {
    onSuccess() {
      queryClient.invalidateQueries(queryKeys.serviceAndPlans);
      toast.success("Atualização do serviço feito com sucesso");
    },
  });
  return mutation;
};

export default useMutationCreateUpdateCustomService;
