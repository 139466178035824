import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { uploadDocumentsDrive } from "services/Requests/companies";

const useMutationUploadDocumentsDrive = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(uploadDocumentsDrive, {
    onSuccess(_, { fileList }) {
      const fileName = fileList.length > 0 ? "Arquivos criados" : "Arquivo criado";
      toast.success(`${fileName} com sucesso`);
      queryClient.invalidateQueries(queryKeys.companyDocumentsDrive, { exact: false });
    },
  });
  return mutation;
};

export default useMutationUploadDocumentsDrive;
