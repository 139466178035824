import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { updateDefaultPlan } from "services/Requests/marketplace";
import queryKeys from "react-query/constants";

const useMutationUpdateDefaultPlan = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateDefaultPlan, {
    onSuccess(_, { planId }) {
      toast.success("Plano atualizado com sucesso");
      queryClient.invalidateQueries(queryKeys.serviceAndPlans);
      queryClient.invalidateQueries([queryKeys.defaultAccountabilityPlan, planId]);
    },
  });
  return mutation;
};

export default useMutationUpdateDefaultPlan;
