import { FC, useEffect, useMemo, useState, useRef } from "react";
import { useForm, DefaultValues } from "react-hook-form";
import useMutationCreateTransaction from "pages/Home/hooks/useMutationCreateTransaction";
import useMutationUpdateTransaction from "pages/Home/hooks/useMutationUpdateTransaction";

import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import RadioForm from "./components/RadioForm";
import DatesForm from "./components/DatesForm";
import BankForm from "./components/BankForm";
import CategoryForm from "./components/CategoryForm";
import ChargeForm from "./components/ChargeForm";
import InvoiceForm from "./components/InvoiceForm";

import { CompanyParticipants, InvoiceConfig, Transaction } from "types/Company";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

import { DateTime } from "luxon";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import getPayloadForCreateTransaction from "./utils/getPayloadForCreateTransaction";
import { UpdateTransactionPayload } from "services/Requests/companies";

export interface ReleaseCreditForm {
  paymentType: "receive" | "received";
  paymentOrigin: "participant" | "partner";
  paymentDate: Date | null;
  companyParticipant: CompanyParticipants | null | number;
  partner: number | null;
  dueDate: Date;
  referenceDate: Date;
  companyBankAccount: number;
  description: string;
  value: string;
  retainedValue: string;
  globalCategoryId: number | null;
  categoryId: number | null;
  periodicity: number;
  parcel: number | undefined;
  createBilling: "0" | "1" | "2";
  daysBefore: "0" | "5";
  isInvoice: boolean;
  updateType?: number;
  invoice: {
    when: "0" | "1";
    issueDate: Date;
    serviceCodeId: number;
    iss: number;
    irpj: number;
    irop: number;
    pis: number;
    cofins: number;
    csll: number;
    issWithHeld: "1" | "0";
    descriptionNfe: string;
    notes: string;
  };
}

interface FormProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  onClose: () => void;
  invoiceConfig: InvoiceConfig;
  transaction?: Transaction;
  isEdit?: boolean;
}

const Form: FC<FormProps> = ({
  isEdit = false,
  companyToken,
  onClose,
  invoiceConfig,
  transaction,
  ...props
}) => {
  const dialogRef = useRef<HTMLElement>();
  const firstRender = useRef(true);
  const isTablet = useMediaQuery("(max-width:1000px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  const [keepDialog, setKeepDialog] = useState(false);
  const mutationCreate = useMutationCreateTransaction();
  const mutationUpdate = useMutationUpdateTransaction();

  const mutateAsync = isEdit ? mutationUpdate.mutateAsync : mutationCreate.mutateAsync;
  const error = isEdit ? mutationUpdate.error : mutationCreate.error;
  const isError = isEdit ? mutationUpdate.isError : mutationCreate.isError;
  const editRecurrence =
    isEdit && transaction && (transaction.parcel > 0 || transaction.periodicity > 0);
  const invoice = transaction?.invoiceItems ? transaction?.invoiceItems[0] : undefined;

  const defaultValues = useMemo<DefaultValues<ReleaseCreditForm>>(
    () => ({
      companyBankAccount: transaction?.companyBankAccountId || undefined,
      paymentType: transaction ? (transaction.paymentDate ? "received" : "receive") : "receive",
      paymentOrigin: transaction
        ? transaction.partnerParticipantId
          ? "partner"
          : "participant"
        : "participant",
      paymentDate: transaction?.paymentDate
        ? DateTime.fromISO(transaction?.paymentDate).toJSDate()
        : null,
      companyParticipant: transaction?.companyParticipantId || null,
      dueDate: transaction?.dueDate
        ? DateTime.fromISO(transaction?.dueDate).toJSDate()
        : DateTime.local().toJSDate(),
      referenceDate: transaction?.referenceDate
        ? DateTime.fromISO(transaction?.referenceDate).toJSDate()
        : DateTime.local().toJSDate(),
      partner: transaction?.partnerParticipantId || null,
      description: transaction?.description || "",
      createBilling: "0",
      periodicity: transaction?.periodicity || undefined,
      value: transaction?.value !== undefined ? formatCurrencyBRL(transaction?.value) : "",
      globalCategoryId: transaction?.globalCategoryId || null,
      categoryId: transaction?.categoryId || undefined,
      retainedValue: transaction?.retainedValue
        ? formatCurrencyBRL(transaction?.retainedValue)
        : "",
      isInvoice: transaction?.isInvoice || false,
      parcel: transaction?.parcel || 0,
      updateType: editRecurrence ? 1 : undefined,
      invoice: {
        when: (transaction?.sendInvoiceLater.toString() as "0" | "1") || undefined,
        issWithHeld: invoice?.issWithHeld ? "1" : "0",
        descriptionNfe: invoice ? invoice.describeInvoice : invoiceConfig.description || "",
        notes: invoice ? invoice.notes : invoiceConfig.notes || "",
        serviceCodeId: invoice?.serviceInvoiceId,
        issueDate: transaction?.issueDate
          ? DateTime.fromISO(transaction?.issueDate).toJSDate()
          : DateTime.local().toJSDate(),
      },
    }),
    [invoiceConfig, isEdit, transaction],
  );

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm<ReleaseCreditForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const paymentType = watch("paymentType");
  const globalCategoryId = watch("globalCategoryId");
  const serviceCodeId = watch("invoice.serviceCodeId");

  const handleClose = () => {
    if (isSubmitting) return;
    reset(defaultValues);
    onClose();
  };

  useEffect(() => {
    if (firstRender.current) {
      return;
    }

    if (paymentType === "receive") {
      setValue("paymentDate", null);
    } else {
      setValue("parcel", undefined);
    }
  }, [paymentType]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = true;
      return;
    }

    setValue("categoryId", null);

    return () => {
      firstRender.current = false;
    };
  }, [globalCategoryId]);

  useEffect(() => {
    if (!serviceCodeId || !invoiceConfig) return;

    const service = invoiceConfig.services.find((s) => s.id === serviceCodeId);
    if (!service) return;

    setValue("invoice.cofins", service.cofins);
    setValue("invoice.csll", service.csll);
    setValue("invoice.irop", service.irop);
    setValue("invoice.irpj", service.irpj);
    setValue("invoice.iss", service.iss);
    setValue("invoice.pis", service.pis);
  }, [serviceCodeId]);

  const onSubmit = handleSubmit(async (data) => {
    const payload = getPayloadForCreateTransaction(
      isEdit,
      data,
      companyToken,
      invoiceConfig,
      transaction?.id,
    );

    try {
      await mutateAsync(payload as UpdateTransactionPayload);
      if (keepDialog) {
        setKeepDialog(false);
        reset(defaultValues);
        return;
      } else {
        handleClose();
      }
    } catch (error) {
      if (dialogRef.current) {
        dialogRef.current.scrollTo({
          left: 0,
          top: 0,
          behavior: "smooth",
        });
      }
    }
  });

  return (
    <Dialog
      TransitionProps={
        {
          ref: dialogRef,
        } as TransitionProps
      }
      onClose={handleClose}
      {...props}
      maxWidth="lg"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitleWithCloseButton onClose={handleClose}>Crédito</DialogTitleWithCloseButton>

      <form onSubmit={onSubmit}>
        <Collapse in={isError}>
          <Alert severity="warning">
            {error?.response?.data.message || "Ocorreu um error inesperado"}
          </Alert>
        </Collapse>

        <DialogContent
          sx={{
            display: "grid",
            gridTemplateColumns: "4fr auto 2fr",
            gap: 3,
            "@media (max-width: 1000px)": {
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Box display="grid" height="fit-content" gridTemplateColumns="1fr 1fr" gap={2}>
            <RadioForm control={control} />
            <DatesForm control={control} companyToken={companyToken} />
            <BankForm control={control} companyToken={companyToken} />
            <CategoryForm control={control} companyToken={companyToken} />
            <ChargeForm
              control={control}
              companyToken={companyToken}
              editRecurrence={editRecurrence}
            />
          </Box>

          <Divider orientation={isTablet ? "horizontal" : "vertical"} flexItem />

          <InvoiceForm control={control} invoiceConfig={invoiceConfig} />
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" loading={isSubmitting} type="submit">
            Salvar
          </LoadingButton>
          {!isEdit && (
            <Button
              variant="outlined"
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                setKeepDialog(true);
              }}
            >
              Salvar e cadastrar outro
            </Button>
          )}
          <Button variant="text" disabled={isSubmitting} onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Form;
