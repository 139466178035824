import { Control, Controller, useWatch, Path } from "react-hook-form";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import ControllerInput from "components/ControllerInput";

const periodicityMeasures = {
  1: "Mensal",
  2: "Anual",
  3: "Semestral",
  4: "Trimestral",
  5: "Bimestral",
  6: "Quinzenal",
  7: "Semanal",
};

interface RequiredFields {
  periodicity: number;
  parcel: number | undefined;
  paymentType: "receive" | "received";
  updateType?: number;
}

interface PeriodicityFormProps<T extends RequiredFields = RequiredFields> {
  control: Control<T>;
  editRecurrence?: boolean;
}

function PeriodicityForm<T extends RequiredFields = RequiredFields>({
  control,
  editRecurrence,
}: PeriodicityFormProps<T>) {
  const paymentType = useWatch({
    control,
    name: "paymentType" as Path<T>,
  });

  return (
    <>
      <Controller
        control={control}
        name={"periodicity" as Path<T>}
        render={({ field, fieldState }) => (
          <FormControl>
            <InputLabel>Repetir</InputLabel>
            <Select
              disabled={paymentType === "received"}
              {...field}
              value={field.value || ""}
              label="Repetir"
              error={!!fieldState.error?.message}
            >
              <MenuItem disabled value="">
                Selecione:{" "}
              </MenuItem>
              {Object.entries(periodicityMeasures).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
              {!!fieldState.error?.message && (
                <FormHelperText error>{fieldState.error?.message}</FormHelperText>
              )}
            </Select>
          </FormControl>
        )}
      />

      <Stack direction="row" gap={0.5} alignItems="center">
        <Typography>Ocorre por</Typography>
        <ControllerInput
          name="parcel"
          control={control}
          inputProps={{
            disabled: paymentType === "received",
            type: "number",
            size: "small",
            sx: { width: 70 },
          }}
        />
        <Typography>vezes</Typography>
      </Stack>

      {editRecurrence && (
        <Controller
          name={"updateType" as Path<T>}
          control={control}
          render={({ field }) => (
            <FormControl sx={{ gridColumn: "-1/1" }}>
              <FormLabel>Alterar recorrência</FormLabel>
              <RadioGroup {...field} row>
                <FormControlLabel value={1} control={<Radio />} label="Somente esta" />
                <FormControlLabel value={3} control={<Radio />} label="A partir desta" />
                <FormControlLabel value={2} control={<Radio />} label="Toda a série" />
              </RadioGroup>
            </FormControl>
          )}
        />
      )}
    </>
  );
}

export default PeriodicityForm;
