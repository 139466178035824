import { FC } from "react";
import { useQueryClient as useQueryClientLib } from "react-query";
import useQueryClient from "pages/Home/hooks/useQueryClient";

import { CircularProgress, IconButton, Stack } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import Invoices from "../../Invoices";
import { Order } from "types/OpenCompany";
import queryKeys from "react-query/constants";

const Loading = () => (
  <Stack alignItems="center" justifyContent="center" height={400}>
    <CircularProgress />
  </Stack>
);

interface InvoiceProps {
  order: Order;
}

const Invoice: FC<InvoiceProps> = ({ order }) => {
  const { data: client, isLoading } = useQueryClient(order.companyToken);
  const queryClient = useQueryClientLib();

  return (
    <>
      {!!client && (
        <IconButton
          onClick={() =>
            queryClient.invalidateQueries([queryKeys.clientBills, client.payerCompanyId])
          }
        >
          <RefreshIcon />
        </IconButton>
      )}

      {isLoading && <Loading />}
      {!!client && !isLoading && (
        <Invoices
          companyToken={order.companyToken}
          ownerId={client.ownerId}
          payerCompanyId={client.payerCompanyId}
        />
      )}
    </>
  );
};

export default Invoice;
