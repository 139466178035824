import { useCallback } from "react";
import { useQuery } from "react-query";

import queryKeys from "react-query/constants";
import { getOsaykPlanByProduct } from "services/Requests/plan";
import { ProductByPlan } from "types/Plan";

import comparatorSortByProperty from "utils/comparatorSortByProperty";
import getSubdomain from "utils/getSubdomain";
import stableSort from "utils/stableSort";

const useQueryOsaykPlanByProduct = (companyToken: string, normalize = false) => {
  const subdomain = getSubdomain();

  const selectorNormalize = useCallback(
    (data: ProductByPlan[]): ProductByPlan[] => {
      if (!normalize) return data;

      const normalized = stableSort(data, comparatorSortByProperty("asc", "price")).filter(
        (plan) => plan.isActive && !plan.global,
      );
      return normalized;
    },
    [normalize],
  );

  const query = useQuery(
    [queryKeys.osaykPlanByProduct, subdomain],
    () =>
      getOsaykPlanByProduct({
        companyToken,
        productName: "Contabilidade Online",
        subdomain,
      }),
    {
      select: selectorNormalize,
    },
  );
  return query;
};

export default useQueryOsaykPlanByProduct;
