import { FC, memo } from "react";
import { useClientInformation } from "../../../context/ClientInformation";
import { useAuth } from "context/Auth";
import useQueryInfoAccount from "pages/Home/hooks/useQueryInfoAccount";
import useQuerySpreads from "pages/Home/hooks/useQuerySpreads";

import { Box, Skeleton, Stack, Typography } from "@mui/material";

import FeesForm from "./FeesForm";

const Fees: FC = () => {
  const {
    user: { company },
  } = useAuth();
  const { token, payerCompanyId } = useClientInformation();
  const { data, isLoading } = useQuerySpreads(
    {
      accountantCompanyId: payerCompanyId,
      companyToken: token,
    },
    !!company,
  );
  const { data: infoAccount, isLoading: isLoadingInfo } = useQueryInfoAccount(
    { companyToken: token },
    !!company,
  );

  return (
    <Box>
      <Typography variant="h6" fontWeight={600} sx={{ mb: 2 }}>
        Configuração dos spreads do contador
      </Typography>

      {!company && <Typography variant="body1">Empresa não localizada</Typography>}

      {company && (isLoading || isLoadingInfo) && (
        <Stack gap={2}>
          <Skeleton variant="rectangular" height={45} />
          <Skeleton variant="rectangular" height={45} />
          <Skeleton variant="rectangular" height={45} />
          <Skeleton variant="rectangular" height={45} />
        </Stack>
      )}

      {company && !isLoading && !isLoadingInfo && data && infoAccount && (
        <FeesForm
          bankSlipCents={infoAccount.commissions.bank_slip_cents}
          ccAdvanceSpread={data.ccAdvanceSpread}
          creditCardPercent={infoAccount.commissions.credit_card_percent}
          withdrawSpread={data.withdrawSpread}
        />
      )}
    </Box>
  );
};

export default memo(Fees);
