import { FC, useState } from "react";

import DialogCreateOrUpdateAdminUser from "pages/Home/components/DialogCreateOrUpdateAdminUser";
import Head from "components/Head";
import UsersContent from "../../components/UsersContent";
import { useAuth } from "context/Auth";
import getSubdomain from "utils/getSubdomain";

import { Box, Button, Stack, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";

const Users: FC = () => {
  const {
    user: { company },
  } = useAuth();

  const [dialogCreateIsOpen, setDialogCreateIsOpen] = useState(false);

  return (
    <>
      <Head title={`Usuários | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Cadastrar Administrador
        </Typography>

        <Button
          variant="contained"
          startIcon={<Add fontSize="small" />}
          size="small"
          disabled={!company}
          onClick={() => setDialogCreateIsOpen(true)}
        >
          Adicionar novo administrador
        </Button>
      </Stack>

      <Box mt={2} p={3} boxShadow={2} bgcolor="#fff">
        {!company ? (
          <Typography variant="body1">Empresa não localizada!</Typography>
        ) : (
          <UsersContent userType="admin" companyToken={company.token} />
        )}
      </Box>

      {!!company && dialogCreateIsOpen && (
        <DialogCreateOrUpdateAdminUser
          companyToken={company.token}
          open={dialogCreateIsOpen}
          userType="admin"
          onClose={() => setDialogCreateIsOpen(false)}
        />
      )}
    </>
  );
};

export default Users;
