import { useMutation, useQueryClient } from "react-query";
import { removeSubAccount  } from "services/Requests/dre";

import toast from "react-hot-toast";
import errorHandlerCompany from "utils/errorHandlerCompany";
import queryKeys from "react-query/constants";

const useMutationDeleteSyntheticAccounts = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(removeSubAccount,
    {
      onSuccess() {
          toast.success("Conta removida com sucesso!");
          queryClient.invalidateQueries(queryKeys.dreList);
      },
      onError: errorHandlerCompany,
    },
  );
  return mutation;
};

export default useMutationDeleteSyntheticAccounts;
