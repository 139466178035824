import { FC } from "react";
import useQueryAllNotificationToAccountant from "../hooks/useQueryAllNotificationToAccountant";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import AllNotifications from "../../AllNotifications";

import { CompanyAssignments } from "types/Admin";
import { Company } from "types/Company";

interface NotificationProps {
  company: CompanyAssignments | Company;
}

const Notification: FC<NotificationProps> = ({ company }) => {
  const {
    data: notifications,
    isLoading,
    isError,
  } = useQueryAllNotificationToAccountant(company.token);

  return (
    <Box>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}

      {isError && (
        <Typography>
          Ocorreu um erro ao tentar pegar as notificações da empresa, por favor, tente mais tarde
        </Typography>
      )}

      {!isLoading && notifications && notifications.length === 0 && (
        <Typography>Nenhuma notificação</Typography>
      )}

      {!isLoading && notifications && notifications.length > 0 && (
        <AllNotifications data={notifications} />
      )}
    </Box>
  );
};

export default Notification;
