import { FC, ChangeEvent, useState, FormEvent, useRef } from "react";
import useMutationReplyTicket from "pages/Home/hooks/useMutationReplyTicket";
import useMutationUploadMessageFiles from "../hooks/useMutationUploadMessageFiles";

import { Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import { CloseRounded } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

interface ChatSendMessageProps {
  companyId: number;
  ticketId: number;
}

const ChatSendMessage: FC<ChatSendMessageProps> = ({ companyId, ticketId }) => {
  const { mutateAsync: mutateReply, isLoading: isLoadingReply } = useMutationReplyTicket();
  const { mutateAsync: mutateUpload, isLoading: isLoadingUpload } =
    useMutationUploadMessageFiles(ticketId);
  const isLoading = isLoadingReply || isLoadingUpload;

  const [files, setFiles] = useState<File[]>([]);
  const [message, setMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>();

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = e.target?.files;

    if (!files) return;
    setFiles(Array.from(files));
  };

  const onSubmitMessage = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (message.trim() === "") return;

    const replyData = await mutateReply({
      companyId,
      message,
      ticketId,
    });

    if (replyData.id && files.length) {
      await mutateUpload({
        companyId,
        files,
        messageId: replyData.id,
      });
    }

    setFiles([]);
    setMessage("");
    inputRef.current?.focus();
  };

  return (
    <Box bgcolor={grey[100]} p={2} mt={1}>
      <form onSubmit={onSubmitMessage}>
        <TextField
          multiline
          rows={2}
          fullWidth
          placeholder="Digite a sua mensagem"
          value={message}
          inputProps={{ autoComplete: "off" }}
          onChange={(e) => setMessage(e.target.value)}
          inputRef={inputRef}
          variant="filled"
        />

        <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" mt={1}>
          <Box>
            <label style={{ display: "flex", alignItems: "center" }}>
              <input
                multiple
                disabled={isLoading}
                type="file"
                style={{
                  display: "none",
                }}
                onChange={handleChangeFile}
              />
              <Button
                disabled={isLoading}
                variant="contained"
                component="span"
                size="small"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                Anexar arquivo
              </Button>
            </label>

            {!!files.length && (
              <Box mt={1}>
                {files.map((file, index) => (
                  <Stack direction="row" gap={0.5} alignItems="center" key={file.name}>
                    <Typography variant="body1">{file.name}</Typography>

                    <IconButton
                      disabled={isLoading}
                      size="small"
                      onClick={() => setFiles((prev) => prev.filter((_, i) => i !== index))}
                    >
                      <CloseRounded fontSize="small" />
                    </IconButton>
                  </Stack>
                ))}
              </Box>
            )}
          </Box>

          <LoadingButton
            size="small"
            endIcon={<SendIcon />}
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
            type="submit"
          >
            Enviar
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default ChatSendMessage;
