import { forwardRef, Ref } from "react";
import useQueryCompanyActivities from "pages/Home/hooks/useQueryCompanyActivities";

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface CompanyActivitiesSelectInnerProps extends SelectProps {
  helperText?: string;
  FormControlProps?: FormControlProps;
}

function CompanyActivitiesSelectInner(
  { helperText, FormControlProps, ...props }: CompanyActivitiesSelectInnerProps,
  ref?: Ref<unknown>,
) {
  const { data: companyActivities, isLoading, isError } = useQueryCompanyActivities();

  return (
    <FormControl {...FormControlProps} size={props.size} error={props.error}>
      <InputLabel>{props.label}</InputLabel>
      <Select ref={ref} {...props} value={props.value ?? ""}>
        <MenuItem disabled value="">
          Selecione:{" "}
        </MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um erro, tente mais tarde</MenuItem>}

        {companyActivities &&
          !isLoading &&
          companyActivities.map(({ id, description }) => (
            <MenuItem key={id} value={id}>
              {description}
            </MenuItem>
          ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const CompanyActivitiesSelect = forwardRef(CompanyActivitiesSelectInner);
export default CompanyActivitiesSelect;
