import { FC, useState } from "react";
import useQueryGetChartOfAccountsFromToGroup from "./hooks/useQueryGetChartOfAccountsFromToGroup";

import { Box, CircularProgress, Collapse, Divider, LinearProgress, Stack } from "@mui/material";
import SearchInput from "components/SearchInput";

import { GroupChartOfAccounts } from "types/Accountant";
import ChartOfAccountsTable from "./ChartOfAccountsTable";

interface ChartOfAccountsControlProps {
  groupChart: GroupChartOfAccounts;
}

const ChartOfAccountsControl: FC<ChartOfAccountsControlProps> = ({ groupChart }) => {
  const [search, setSearch] = useState("");
  const {
    isLoading,
    data: chartOfAccountsFromToGroup,
    isFetching,
  } = useQueryGetChartOfAccountsFromToGroup(groupChart.id, search);

  return (
    <>
      <Collapse in={!isLoading && isFetching}>
        <LinearProgress />
      </Collapse>

      {isLoading && (
        <Stack height={300} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {chartOfAccountsFromToGroup && !isLoading && (
        <>
          <Box p={2}>
            <SearchInput fullWidth value={search} onChange={(newValue) => setSearch(newValue)} />
          </Box>

          <Divider sx={{ my: 1 }} />

          <Box p={2}>
            <ChartOfAccountsTable chartOfAccountsFromToGroup={chartOfAccountsFromToGroup} />
          </Box>
        </>
      )}
    </>
  );
};

export default ChartOfAccountsControl;
