import { FC, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import { NormalizedClient } from "types/Client";

import Subscriptions from "./components/Subscriptions";
import Invoices from "../../../../../components/Invoices";
import Fees from "./components/Fees";
import InvoiceManagement from "./components/InvoiceManagement";
import { ClientInformationProvider } from "../context/ClientInformation";
import Apps from "./components/Apps";

interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

interface DialogClientDetailProps extends DialogProps {
  clientInformation: NormalizedClient;
  companyToken: string;
}

const DialogClientDetail: FC<DialogClientDetailProps> = ({
  clientInformation,
  onClose,
  companyToken,
  ...props
}) => {
  const [tabActive, setTabActive] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabActive(newValue);
  };

  return (
    <ClientInformationProvider client={clientInformation} companyToken={companyToken}>
      <Dialog maxWidth="md" fullWidth onClose={onClose} {...props}>
        <DialogTitle>{clientInformation.companyName || clientInformation.owner}</DialogTitle>

        <DialogContent dividers>
          <Tabs
            value={tabActive}
            onChange={handleChange}
            sx={{
              borderBottom: 1,
              borderBottomColor: "divider",
            }}
          >
            <Tab label="Assinatura" />
            <Tab label="Faturas" />
            <Tab label="Taxas" />
            <Tab label="Gestão de Conta" />
            {clientInformation.userType === "Accountant" && <Tab label="Apps" />}
          </Tabs>

          <TabPanel value={tabActive} index={0}>
            <Subscriptions />
          </TabPanel>
          <TabPanel value={tabActive} index={1}>
            <Invoices
              companyToken={companyToken}
              ownerId={clientInformation.ownerId}
              payerCompanyId={clientInformation.payerCompanyId}
            />
          </TabPanel>
          <TabPanel value={tabActive} index={2}>
            <Fees />
          </TabPanel>
          <TabPanel value={tabActive} index={3}>
            <InvoiceManagement />
          </TabPanel>

          {clientInformation.userType === "Accountant" && (
            <TabPanel value={tabActive} index={4}>
              <Apps companyId={clientInformation.payerCompanyId} />
            </TabPanel>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={(e) => onClose?.(e, "backdropClick")}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </ClientInformationProvider>
  );
};

export default DialogClientDetail;
