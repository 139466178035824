import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getSummary } from "services/Requests/dashboard";
import { ISODate } from "types/SemanticTypes";

const useQueryGetSummary = (companyToken: string, startDate: ISODate, endDate: ISODate) => {
  const query = useQuery(
    [queryKeys.summary, startDate, endDate],
    () => getSummary({ companyToken, endDate, startDate }),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 2, // 2 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
    },
  );
  return query;
};

export default useQueryGetSummary;
