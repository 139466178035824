import { InvoiceForm } from ".";
import { Control, Controller } from "react-hook-form";

import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import { FC } from "react";

interface ConfigRPSNFCeProps {
  control: Control<InvoiceForm>;
}

const ConfigRPSNFCe: FC<ConfigRPSNFCeProps> = ({ control }) => {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={600}
        borderBottom={1}
        borderColor="divider"
        sx={{ mb: 2 }}
      >
        Configuração do RPS para NFC-e
      </Typography>

      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
        <Controller
          control={control}
          name={"serieNFCe"}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Série"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              inputProps={{
                maxLength: 10,
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="sequentialNFCe"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Número da próxima NFC-e"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              type="number"
              inputProps={{
                maxLength: 10,
              }}
            />
          )}
        />

        <Controller
          control={control}
          name={"cscId"}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Id CSC"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              inputProps={{
                maxLength: 50,
              }}
            />
          )}
        />

        <Controller
          control={control}
          name={"cscCode"}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Código CSC"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              inputProps={{
                maxLength: 50,
              }}
            />
          )}
        />
      </Box>

      <FormHelperText>
        <Typography variant="inherit" fontWeight={600} component="strong">
          Observação:
        </Typography>{" "}
        o CSC não será armazenado em nosso servidor.
      </FormHelperText>
    </Box>
  );
};

export default ConfigRPSNFCe;
