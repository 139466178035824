import * as yup from "yup";

const validationSchema = yup.object().shape({
  number: yup
    .number()
    .required("O número é obrigatório")
    .typeError("Digite um número válido")
    .moreThan(-1, "O número deve ser positivo")
    .integer("O número deve ser inteiro")
    .lessThan(100, "O número deve conter 2 dígitos"),
  accessKey: yup
    .string()
    .required("A chave de acesso é obrigatória")
    .max(50, "A chave de acesso deve conter no máximo 50 caracteres"),
  description: yup
    .string()
    .required("A descrição é obrigatória")
    .max(200, "A descrição deve conter no máximo 200 caracteres"),
});

export default validationSchema;
