import React, { FC } from "react";
import MarkButton from "../MarkButton";
import BlockButton from "../BlockButton";
import { ButtonLink } from "../Link";

import FormatStrikethrough from "@mui/icons-material/FormatStrikethrough";
import FormatListNumbered from "@mui/icons-material/FormatListNumbered";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import FormatAlignJustify from "@mui/icons-material/FormatAlignJustify";
import FormatAlignCenter from "@mui/icons-material/FormatAlignCenter";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlined from "@mui/icons-material/FormatUnderlined";
import FormatAlignRight from "@mui/icons-material/FormatAlignRight";
import FormatAlignLeft from "@mui/icons-material/FormatAlignLeft";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import { Divider } from "@mui/material";

export const Preset1: FC = () => (
  <>
    <MarkButton format="bold" Icon={FormatBoldIcon} />
    <MarkButton format="italic" Icon={FormatItalicIcon} />
    <MarkButton format="underline" Icon={FormatUnderlined} />
    <MarkButton format="strikethrough" Icon={FormatStrikethrough} />

    <Divider orientation="vertical" flexItem />

    <BlockButton format="numbered-list" Icon={FormatListNumbered} />
    <BlockButton format="bulleted-list" Icon={FormatListBulleted} />

    <Divider orientation="vertical" flexItem />

    <BlockButton format="left" Icon={FormatAlignLeft} />
    <BlockButton format="center" Icon={FormatAlignCenter} />
    <BlockButton format="right" Icon={FormatAlignRight} />
    <BlockButton format="justify" Icon={FormatAlignJustify} />
  </>
);

export const Preset2: FC = () => (
  <>
    <MarkButton format="bold" Icon={FormatBoldIcon} />
    <MarkButton format="italic" Icon={FormatItalicIcon} />
    <MarkButton format="underline" Icon={FormatUnderlined} />
    <ButtonLink />
  </>
);
