import { FC, useState } from "react";

import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DialogDeleteAccount from "./components/DialogDeleteAccount";

interface MassActionProps {
  itemsSelected: number[];
}

const MassAction: FC<MassActionProps> = ({ itemsSelected }) => {
  const totalSelected = itemsSelected.length;
  const [dialogDeleteIsOpen, setDialogDeleteIsOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    const action = event.target.value;

    if (action === "0") return;
    if (action === "delete") setDialogDeleteIsOpen(true);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
      >
        <Stack direction="row" alignItems="center" gap={1} pl={1.5}>
          <ArrowDownwardIcon color="primary" fontSize="small" />
          <Select value="0" onChange={handleChange} size="small">
            <MenuItem value="0">Ações em massa</MenuItem>
            <MenuItem value="delete" disabled={totalSelected === 0}>
              Excluir
            </MenuItem>
          </Select>

          {!!totalSelected && (
            <Typography sx={{ mr: 1 }} color="text.secondary" variant="body1">
              {totalSelected} {totalSelected === 1 ? "item selecionado" : "itens selecionados"}
            </Typography>
          )}
        </Stack>
      </Stack>

      <DialogDeleteAccount
        open={dialogDeleteIsOpen}
        onClose={() => setDialogDeleteIsOpen(false)}
        ids={itemsSelected}
      />
    </>
  );
};

export default MassAction;
