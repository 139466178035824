import { createContext, Dispatch, SetStateAction } from "react";
import { ISODate } from "types/SemanticTypes";

export interface DateInterface {
  start: ISODate;
  end: ISODate;
}

export interface FilterPeriodContextInterface {
  date: DateInterface;
  setDate: Dispatch<SetStateAction<DateInterface>>;
}

export const FilterPeriodContext = createContext<FilterPeriodContextInterface>(
  {} as FilterPeriodContextInterface,
);
