import { FC, PropsWithChildren } from "react";

const PrintLandscape: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <>
      <style type="text/css" media="print">
        {"\
          @page { size: landscape; }\
        "}
      </style>
      {children}
    </>
  );
};

export default PrintLandscape;
