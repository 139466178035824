import * as yup from "yup";
import validateCNPJ from "utils/validateCNPJ";
import validateCPF from "utils/validateCPF";
import { emptyNumberValidation } from "utils/validations";

const numberValidation = yup
  .number()
  .moreThan(-1, "Digite apenas números positivo")
  .test("match", "Até 3 casas decimais", (value) => {
    if (value === undefined || value === null) return true;
    return /^[0-9]+(\.[0-9]{1,3})?$/.test(value.toString());
  })
  .typeError("Digite apenas números");

const itemValidationWithoutInvoice = {
  productId: yup.number().required("Selecione um produto").typeError("Selecione um produto"),
  unitValue: yup.string().required("Informe o valor unitário"),
  quantity: yup
    .number()
    .required("A quantidade é obrigatória")
    .typeError("Digite apenas números")
    .moreThan(0, "Mínimo de 1 quantidade"),
};

const itemValidation = {
  ...itemValidationWithoutInvoice,
  cfop: yup.string().required("Obrigatório").length(4, "CFOP precisa ter 4 caracteres"),
  pisCst: yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
  pis: numberValidation.max(100, "O máximo é 100%"),
  icmsCst: yup.string().max(4, "Máximo 4 caracteres"),
  icms: numberValidation.max(100, "O máximo é 100%"),
  cofinsCst: yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
};

const itemValidationConsumerInvoice = {
  ...itemValidation,
  municipalTax: emptyNumberValidation.max(100, "O máximo é 100%"),
  stateTax: emptyNumberValidation.max(100, "O máximo é 100%"),
  federalTax: emptyNumberValidation.max(100, "O máximo é 100%"),
};

const itemValidationInvoice = {
  ...itemValidation,
  cofins: numberValidation.max(100, "O máximo é 100%"),
  ipiCst: yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
  ipi: numberValidation.max(100, "O máximo é 100%"),
};

const cpfCnpjValidation = yup
  .string()
  .notRequired()
  .test("cpfOrCnpjIsValid", "Digite um cpf ou cnpj válido", (value) => {
    if (value === undefined || value === null || value === "") return true;

    if (value.length <= 14) {
      const cpfIsValid = validateCPF(value);
      return cpfIsValid;
    }

    const cnpjIsValid = validateCNPJ(value);
    return cnpjIsValid;
  });

const shippingValidation = {
  deliveryAddress: yup.string(),
  recipientCpfCnpj: yup.string().notRequired().when("deliveryAddress", {
    is: "0",
    then: cpfCnpjValidation,
  }),

  carrierUsesIssuerData: yup.string(),
  carrierCpfCnpj: yup.string().notRequired().when("carrierUsesIssuerData", {
    is: "0",
    then: cpfCnpjValidation,
  }),
};

const validationSchema = yup.object().shape({
  nature: yup.string().required("É necessário preencher a Natureza da Operação."),
  dueDate: yup.date().required("Informe a data").typeError("Digite uma data válida"),
  movementDate: yup.date().notRequired().nullable(true).typeError("Digite uma data válida"),

  sendInvoice: yup.string(),
  participantId: yup.mixed().when("sendInvoice", {
    is: "1",
    then: yup
      .number()
      .required("Fornecedor é obrigatório para emitir a NF-e")
      .typeError("Fornecedor é obrigatório para emitir a NF-e"),
  }),

  items: yup.array().when("sendInvoice", {
    is: "0",
    then: yup.array().of(yup.object().shape(itemValidationWithoutInvoice)),
    otherwise: yup.array().when("sendInvoice", {
      is: "1",
      then: yup.array().of(yup.object().shape(itemValidationInvoice)),
      otherwise: yup.array().of(yup.object().shape(itemValidationConsumerInvoice)),
    }),
  }),

  shipping: yup.mixed().when("sendInvoice", {
    is: (value: string) => value === "1" || value === "2",
    then: yup.object().shape(shippingValidation),
    otherwise: yup.mixed(),
  }),

  paymentType: yup.string().when("sendInvoice", {
    is: "2",
    then: yup
      .string()
      .required("Tipo de pagamento é obrigatório")
      .typeError("Tipo de pagamento é obrigatório"),
    otherwise: yup.string().nullable(),
  }),
});

export default validationSchema;
