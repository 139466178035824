import { useQuery } from "react-query";
import { getClientDetails, getClientPaymentMethod } from "services/Requests/marketplace";

import { ClientDetailsWithPaymentMethod } from "types/Client";
import queryKeys from "react-query/constants";
import getSubdomain from "utils/getSubdomain";

const getAllDetailsForClient = async (payerCompanyId: number, ownerId: number) => {
  const subdomain = getSubdomain();

  const [clientDetails, paymentMethod] = await Promise.all([
    getClientDetails({ payerCompanyId, subdomain }),
    getClientPaymentMethod({ ownerId, payerCompanyId, subdomain }),
  ]);

  return {
    ...clientDetails,
    paymentMethod,
  };
};

export const useClientDetails = (payerCompanyId: number, ownerId: number) => {
  const query = useQuery<ClientDetailsWithPaymentMethod>(
    [queryKeys.clientDetails, payerCompanyId],
    () => getAllDetailsForClient(payerCompanyId, ownerId),
  );

  return query;
};
