function addIfValueIsTruthy<T = unknown>(arr: T[], ...args: (T | undefined | null)[]) {
  args.forEach((arg) => {
    if (arg) {
      arr.push(arg);
    }
  });
  return arr;
}

export default addIfValueIsTruthy;
