import { useContext } from "react";
import { TemplateContext } from ".";

export const useTemplate = () => {
  const ctx = useContext(TemplateContext);

  if (!ctx) {
    throw new Error("useTemplate must be used within a TemplateProvider");
  }

  return ctx;
};
