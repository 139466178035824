import { FC, useEffect, useState } from "react";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import TabPanel from "components/TabPanel";
import { ProductByPlan } from "types/Plan";

import About from "./About";
import Company from "./Company";
import Partner from "./Partner";
import Contract from "./Contract";
import Payment from "./Payment";
import DOMPurify from "dompurify";

interface CompanyWorkflowProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  plan: ProductByPlan;
}

const CompanyWorkflow: FC<CompanyWorkflowProps> = ({ onClose, companyToken, plan, ...props }) => {
  const { data: company } = useQueryGetCompany(companyToken);

  const [finish, setFinish] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [biggestTab, setBiggestTab] = useState(0);
  const [confirmClose, setConfirmClose] = useState(false);

  const handleNext = () => setActiveTab(activeTab + 1);
  const handlePrev = () => setActiveTab(activeTab - 1);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:600px) and (max-width:980px)");

  const handleClose = () => {
    setConfirmClose(true);
  };

  useEffect(() => {
    if (activeTab > biggestTab) {
      setBiggestTab(activeTab);
    }
  }, [activeTab, biggestTab]);

  const closeWorkflow = () => {
    setConfirmClose(false);
    onClose();
    setActiveTab(0);
    setBiggestTab(0);
  };

  return (
    <>
      <Dialog
        {...props}
        open={props.open && !finish}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={isTablet || isMobile}
      >
        <DialogTitleWithCloseButton onClose={handleClose}>
          Complete o cadastro da sua empresa na Plataforma Osayk
        </DialogTitleWithCloseButton>

        <DialogContent>
          <Tabs
            color="primary"
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            sx={{
              borderBottom: 1,
              borderBottomColor: "divider",
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Sobre você" />
            <Tab label="Sobre a empresa" disabled={biggestTab < 1} />
            <Tab label="Sobre os sócios" disabled={biggestTab < 2} />
            <Tab label="Contato" disabled={biggestTab < 3} />
            <Tab label="Pagamento" disabled={biggestTab < 4} />
          </Tabs>

          <TabPanel value={activeTab} index={0} keepMounted>
            <About onNext={handleNext} />
          </TabPanel>
          <TabPanel value={activeTab} index={1} keepMounted>
            <Company companyToken={companyToken} onNext={handleNext} onPrev={handlePrev} />
          </TabPanel>
          <TabPanel value={activeTab} index={2} keepMounted>
            <Partner companyToken={companyToken} onNext={handleNext} onPrev={handlePrev} />
          </TabPanel>
          <TabPanel value={activeTab} index={3} keepMounted>
            <Contract downloadToken={plan.downloadToken} onNext={handleNext} onPrev={handlePrev} />
          </TabPanel>
          <TabPanel value={activeTab} index={4} keepMounted>
            <Payment
              onFinish={() => setFinish(true)}
              plan={plan}
              companyToken={companyToken}
              onPrev={handlePrev}
            />
          </TabPanel>
        </DialogContent>
      </Dialog>

      <Dialog
        open={finish}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          window.open(`https://${company?.chosenSubd || "app"}.osayk.com.br`, "_blank");
          setFinish(false);
          closeWorkflow();
        }}
      >
        <DialogTitleWithCloseButton onClose={() => setConfirmClose(false)}>
          Você completou o cadastro da sua empresa na Plataforma Osayk
        </DialogTitleWithCloseButton>

        <DialogContent>
          <Box
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(plan.endText),
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              window.open(`https://${company?.chosenSubd || "app"}.osayk.com.br`, "_blank");
            }}
          >
            Prosseguir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmClose} onClose={() => setConfirmClose(false)}>
        <DialogTitleWithCloseButton onClose={() => setConfirmClose(false)}>
          Cadastro
        </DialogTitleWithCloseButton>
        <DialogContent>Tem certeza que deseja interromper o cadastro de sua empresa?</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeWorkflow}>
            Sim
          </Button>
          <Button variant="outlined" onClick={() => setConfirmClose(false)}>
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompanyWorkflow;
