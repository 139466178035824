import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { createCompanyParticipants } from "services/Requests/companies";

const useMutatioCreateCompanyParticipants = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createCompanyParticipants, {
    onSuccess() {
      toast.success("Cliente cadastrado com sucesso");
      queryClient.invalidateQueries(queryKeys.companyParticipants, { exact: false });
    },
  });
  return mutation;
};

export default useMutatioCreateCompanyParticipants;
