import validateCNPJ from "utils/validateCNPJ";
import * as yup from "yup";

const validationSchema = yup.object({
  cnpj: yup
    .string()
    .required("O CNPJ é obrigatório")
    .test("cnpj validation", "Digite um CNPJ válido", (value) => {
      if (!value) return true;
      return validateCNPJ(value);
    }),
  companyName: yup.string().required("A razão social é obrigatório"),
  mainActivity: yup.object().nullable(),
  street: yup.string().required("O logadouro é obrigatório").typeError("O logadouro é obrigatório"),
  neighborhood: yup.string().required("O bairro é obrigatório").typeError("O bairro é obrigatório"),
  zipCode: yup
    .string()
    .required("O CEP é obrigatório")
    .typeError("O CEP é obrigatório")
    .test("cep validation", "Digite um CEP válido", (value) => {
      if (!value) return true;
      return value.replace(/\D/g, "").length === 8;
    }),
  city: yup.string().required("O município é obrigatório").typeError("O município é obrigatório"),
  complement: yup.string().nullable(),
  state: yup.string().required("O estado é obrigatório").typeError("O estado é obrigatório"),
  streetNumber: yup
    .number()
    .typeError("Digite apenas números")
    .positive("Digite apenas números positivos")
    .integer("Digite apenas números inteiros"),
});

export default validationSchema;
