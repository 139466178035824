import { FC } from "react";
import useQueryGetTransaction from "pages/Home/pages/MonthClosure/hooks/useQueryGetTransaction";
import useQueryGetInvoiceConfig from "pages/Home/hooks/useQueryGetInvoiceConfig";

import Form from "./Form";
import { CircularProgress, DialogProps, Modal, Stack } from "@mui/material";

interface DialogReleaseCreditProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  onClose: () => void;
  transactionId?: number;
  isEdit?: boolean;
}

const DialogReleaseCredit: FC<DialogReleaseCreditProps> = ({
  companyToken,
  onClose,
  transactionId,
  isEdit = false,
  ...props
}) => {
  const { isLoading: isLoadingInvoice, data: invoiceConfig } =
    useQueryGetInvoiceConfig(companyToken);
  const { isLoading: isLoadingTransaction, data: transaction } = useQueryGetTransaction(
    companyToken,
    transactionId,
  );

  const isLoading = isLoadingInvoice || isLoadingTransaction;
  const hasTransactionData = transactionId ? !!transaction : true;
  const hasData = hasTransactionData && !!invoiceConfig;

  return (
    <>
      {isLoading && (
        <Modal open={props.open} onClose={onClose}>
          <Stack p={3} alignItems="center" justifyContent="center" height="100vh">
            <CircularProgress />
          </Stack>
        </Modal>
      )}

      {hasData && !isLoading && props.open && (
        <Form
          isEdit={isEdit}
          onClose={onClose}
          {...props}
          invoiceConfig={invoiceConfig}
          companyToken={companyToken}
          transaction={transaction}
        />
      )}
    </>
  );
};

export default DialogReleaseCredit;
