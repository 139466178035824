import { AnchorHTMLAttributes, DetailedHTMLProps, forwardRef, Ref, PropsWithChildren } from "react";

type AnchorProps = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

function WithLinkWrapperInner(
  { href, children, ...props }: PropsWithChildren<AnchorProps>,
  ref?: Ref<HTMLAnchorElement>,
) {
  if (!href) {
    return <>{children}</>;
  }

  return (
    <a ref={ref} href={href} {...props}>
      {children}
    </a>
  );
}

const WithLinkWrapper = forwardRef(WithLinkWrapperInner);
export default WithLinkWrapper;
