import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { changeSimplePassword } from "services/Requests/registration";

const useMutationChangePassword = () => {
  const mutation = useMutation(changeSimplePassword, {
    onSuccess(data) {
      if (data.result === "Success") {
        toast.success("Sua senha foi alterada com sucesso.");
      } else {
        toast.error("Ocorreu um erro ao alterar a senha, tente novamente mais tarde");
      }
    },
  });

  return mutation;
};

export default useMutationChangePassword;
