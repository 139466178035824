import endpoints from "../endpoints";
import api from "../api";
import {
  Group,
  StatusRequest,
  GetAllChatsResponse,
  Chat,
  ReplyTicketResponse,
  ChatResponse,
  CompanyGroup,
  CreateTicketResponse,
} from "types/Mailbox";

export interface GetGroupsPayload {
  subdomain: string;
}

export const getGroups = async (payload: GetGroupsPayload): Promise<Group[]> => {
  const { data } = await api.post<Group[]>(endpoints.mailbox.getGroups, payload);
  return data;
};

export interface CreateGroupPayload {
  accountants: number[];
  companyToken: string;
  name: string;
  subdomain: string;
}

export const createGroup = async (payload: CreateGroupPayload) => {
  await api.post(endpoints.mailbox.createGroups, payload);
};

export interface UpdateGroupPayload extends Partial<CreateGroupPayload> {
  groupId: number;
  isDeleted?: true;
}

export const updateGroup = async (payload: UpdateGroupPayload) => {
  await api.post(endpoints.mailbox.updateGroup, payload);
};

export interface GetAllChatsPayload {
  lastDays: number;
  status: StatusRequest;
}

export const getAllChats = async (payload: GetAllChatsPayload): Promise<GetAllChatsResponse> => {
  const status = payload.status === "closed" ? "0" : "1";
  const lastDays = payload.lastDays === 0 ? "" : payload.lastDays.toString();

  const { data } = await api.post<GetAllChatsResponse>(endpoints.mailbox.getAllChats, {
    lastDays,
    status,
  });
  return data;
};

export interface GetTicketByIdPayload {
  companyId: number;
  id: number;
}

export const getTicketById = async (payload: GetTicketByIdPayload): Promise<Chat> => {
  const { data } = await api.post<Chat>(endpoints.mailbox.getTicketById, payload);
  return data;
};

export interface OpenTicketPayload {
  companyId: number;
  ticketId: number;
}

export const openTicket = async (payload: OpenTicketPayload) => {
  await api.post(endpoints.mailbox.openTicket, payload);
};

export type CloseTicketPayload = OpenTicketPayload;

export const closeTicket = async (payload: CloseTicketPayload) => {
  await api.post(endpoints.mailbox.closeTicketByCompanyId, payload);
};

export interface ReplyTicketPayload {
  companyId?: number;
  companyToken?: string;
  message: string;
  subdomain: string;
  ticketId: number;
}

export const replyTicket = async (payload: ReplyTicketPayload): Promise<ReplyTicketResponse> => {
  const endpoint = payload.companyId
    ? endpoints.mailbox.replyTicketByCompanyId
    : endpoints.mailbox.replyTicket;

  const { data } = await api.post<ReplyTicketResponse>(endpoint, payload);
  return data;
};

export interface UploadMessageFilesPayload {
  companyId: number;
  messageId: number;
  files: File[] | FileList;
}

export const uploadMessageFiles = async ({
  companyId,
  messageId,
  files,
}: UploadMessageFilesPayload) => {
  files = Array.from(files);

  const formData = new FormData();
  formData.append("companyId", companyId.toString());
  formData.append("messageId", messageId.toString());

  files.forEach((file) => {
    formData.append("file", file);
  });

  await api.post(endpoints.mailbox.uploadByCompanyId, formData);
};

export interface GetChatsPayload {
  companyToken: string;
}

export const getChats = async (payload: GetChatsPayload): Promise<ChatResponse> => {
  const { data } = await api.post<ChatResponse>(endpoints.mailbox.getChats, payload);
  return data;
};

export interface GetCompanyGroupsPayload {
  subdomain: string;
}

export const getCompanyGroups = async (
  payload: GetCompanyGroupsPayload,
): Promise<CompanyGroup[]> => {
  const { data } = await api.post<CompanyGroup[]>(endpoints.mailbox.getCompanyGroups, payload);
  return data;
};

export interface CreateTicketPayload {
  agentId: null | number;
  companyToken: string;
  groupId: number;
  message: string;
  subdomain: string;
  subject: string;
}

export const createTicket = async (payload: CreateTicketPayload): Promise<CreateTicketResponse> => {
  const { data } = await api.post<CreateTicketResponse>(endpoints.mailbox.createTicket, payload);
  return data;
};

export interface UploadFilesPayload {
  companyToken: string;
  messageId: number;
  files: File[] | FileList;
}

export const uploadFiles = async ({ companyToken, messageId, files }: UploadFilesPayload) => {
  files = Array.from(files);

  const formData = new FormData();
  formData.append("companyToken", companyToken);
  formData.append("messageId", messageId.toString());

  files.forEach((file) => {
    formData.append("file", file);
  });

  await api.post(endpoints.mailbox.upload, formData);
};

export interface CloseTicketByCompanyPayload {
  companyToken: string;
  ticketId: number;
}

export const closeTicketByCompany = async (payload: CloseTicketByCompanyPayload) => {
  await api.post(endpoints.mailbox.closeTicket, payload);
};

export interface GetTicketPayload {
  companyToken: string;
  id: number;
}

export const getTicket = async (payload: GetTicketPayload): Promise<Chat> => {
  const { data } = await api.post<Chat>(endpoints.mailbox.getTicket, payload);
  return data;
};
