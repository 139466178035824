import Cookies from "js-cookie";
import { UserWithCompany } from "types/User";
import { COOKIE_TOKEN_NAME } from "utils/constants";

export const createAuthInfo = (user: UserWithCompany) => {
  localStorage.setItem("user", JSON.stringify(user));
  Cookies.set(COOKIE_TOKEN_NAME, user.token, {
    expires: 2,
  });
};

export const removeAuthInfo = () => {
  Cookies.remove(COOKIE_TOKEN_NAME);
  localStorage.removeItem("user");
  localStorage.removeItem("isCompanyView");
  localStorage.removeItem("previousUser");
};

export const getUser = (): UserWithCompany => {
  return JSON.parse(localStorage.getItem("user") ?? "{}");
};

export const getPreviousUser = (): UserWithCompany | null => {
  const previousUser = localStorage.getItem("previousUser");

  if (!previousUser) {
    return null;
  }

  return JSON.parse(previousUser);
};

export const isUserAuthenticated = (): boolean => {
  return !!getUser() && !!Cookies.get(COOKIE_TOKEN_NAME);
};
