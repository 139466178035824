import { createContext } from "react";
import { CompanyStyles } from "types/Company";

export interface ICompanyInfoContext {
  pathImage: string | null;
  imageTopDashboard: string | null;
  subdomain: string;
  companyStyles: CompanyStyles;
  typeSubdomain: "accountant" | "admin";
}

export const CompanyInfoContext = createContext<ICompanyInfoContext>({} as ICompanyInfoContext);
