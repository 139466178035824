import { FC } from "react";
import { useAuth } from "context/Auth";

import { Box, Stack, Typography } from "@mui/material";

import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";

import Head from "components/Head";
import Page from "./Page";

const Solicitation: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <>
      <Head title={`Meus pedidos | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Meus pedidos
        </Typography>
      </Stack>

      <Box mt={2} borderRadius={1} boxShadow={2} bgcolor="#fff">
        {!company ? (
          <Typography sx={{ p: 2 }}>{MESSAGE_NO_COMPANY}</Typography>
        ) : (
          <Page companyToken={company.token} />
        )}
      </Box>
    </>
  );
};

export default Solicitation;
