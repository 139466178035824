import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getGlobalAccountanting } from "services/Requests/accountant";

const useQueryGlobalAccountanting = (groupId: number) => {
  const query = useQuery([queryKeys.globalAccountanting, groupId], () =>
    getGlobalAccountanting({ groupId }),
  );
  return query;
};

export default useQueryGlobalAccountanting;
