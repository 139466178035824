import { forwardRef, Ref, useCallback, useState } from "react";
import useQueryGetCompanyParticipants from "pages/Home/hooks/useQueryGetCompanyParticipants";

import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  IconButton,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { CompanyParticipants, RoleCompanyParticipant } from "types/Company";
import DialogCreateUpdateCompanyParticipant from "../DialogCreateUpdateCompanyParticipant";
import AddIcon from "@mui/icons-material/Add";

type MyAutocompleteProps = Partial<
  AutocompleteProps<CompanyParticipants, false, false, false, "div">
>;
type Value = CompanyParticipants | number | null | undefined;

interface RecipientAutocompleteInnerProps extends Omit<MyAutocompleteProps, "value" | "role"> {
  companyToken: string;
  withCreateParticipant?: boolean;
  textFieldProps?: TextFieldProps;
  value?: Value;
  role?: RoleCompanyParticipant | "";
}
function RecipientAutocompleteInner(
  {
    companyToken,
    textFieldProps,
    value,
    role = "",
    withCreateParticipant = false,
    ...props
  }: RecipientAutocompleteInnerProps,
  ref: Ref<unknown>,
) {
  const [createIsOpen, setCreateIsOpen] = useState(false);
  const { data: companyParticipants = [], isLoading } = useQueryGetCompanyParticipants(
    companyToken,
    "",
    role,
  );

  const getValue = useCallback(
    (value: Value): CompanyParticipants | null => {
      if (value === null || value === undefined) return null;
      if (typeof value !== "number") return value;

      return companyParticipants.find((p) => p.id === value) || null;
    },
    [companyParticipants],
  );

  return (
    <>
      <Autocomplete
        {...props}
        value={getValue(value)}
        options={companyParticipants}
        autoHighlight
        getOptionLabel={(option) => option?.name || ""}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        noOptionsText="Sem opção"
        disabled={isLoading || props.disabled}
        ref={ref}
        renderOption={(props, option) => (
          <Box component="li" {...props} sx={{ display: "block !important" }}>
            <Typography component="strong" fontWeight={600}>
              {option.name}
            </Typography>
            {option.cpfcnpj === "--" ? "" : " " + option.cpfcnpj}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            label="Beneficiário"
            {...textFieldProps}
            {...params}
            inputProps={{
              ...textFieldProps?.inputProps,
              ...params.inputProps,
              autoComplete: "off",
            }}
            InputProps={{
              ...textFieldProps?.InputProps,
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                  {withCreateParticipant && (
                    <IconButton
                      disabled={props.disabled}
                      size="small"
                      color="primary"
                      onClick={() => setCreateIsOpen(true)}
                      sx={{ mr: props?.size === "small" ? 3 : 0 }}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
        )}
      />

      {withCreateParticipant && (
        <DialogCreateUpdateCompanyParticipant
          keepMounted={false}
          open={createIsOpen}
          onClose={() => setCreateIsOpen(false)}
          companyToken={companyToken}
          role={role === 1 ? "client" : "customer"}
        />
      )}
    </>
  );
}

const RecipientAutocomplete = forwardRef(RecipientAutocompleteInner);
export default RecipientAutocomplete;
