import axios from "axios";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { changeDocumentStatus } from "services/Requests/admin";

const useMutationChangeDocumentStatus = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(changeDocumentStatus, {
    onSuccess(_, { status, payerCompanyId }) {
      toast.success(`Status atualizado para: "${status}" com sucesso!`);

      queryClient.invalidateQueries([queryKeys.infoAccountAdmin, payerCompanyId]);
    },
    onError(e) {
      if (axios.isAxiosError(e) && e.request.status === 404) {
        toast.error("Para alterar o status o documento precisa ser anexado!");
        return;
      }
      toast.error("Ocorreu um erro inesperado, tente novamente mais tarde!");
    },
  });
  return mutation;
};

export default useMutationChangeDocumentStatus;
