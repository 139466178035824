import endpoints from "../endpoints";
import api from "../api";

import { AccountantApp } from "types/Accountant";

export interface GetAccountantAppsParams {
  companyId: number;
}

export const getAccountantApps = async (params: GetAccountantAppsParams) => {
  const { data } = await api.get<AccountantApp[]>(endpoints.accountantApps.getAccountantApps, {
    params,
  });
  return data;
};

export interface SaveAccountantAppsPayload {
  apps: AccountantApp[];
  companyId: number;
}

export const saveAccountantApps = async (payload: SaveAccountantAppsPayload) => {
  await api.post(endpoints.accountantApps.saveAccountantApps, payload);
};
