import { FC, useMemo, useRef } from "react";
import useMutationPostProducts from "../../hooks/useMutationPostProducts";
import { Controller, DefaultValues, useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import UnityOfMeasureSelect from "pages/Home/components/UnityOfMeasureSelect";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import CurrencyInput, { formattedInput } from "components/CurrencyInput";
import TaxesAndCodesFields from "./TaxesAndCodesFields";

import { UNIT, PisCstCode, IpiCst } from "utils/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import { Product } from "types/StockControl";
import { DateTime } from "luxon";
import NFCFields from "./NFCFields";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

export interface ProductForm {
  code: string;
  name: string;
  description: string;
  initialQuantity?: number | null;
  minimumQuantity?: number;
  averageUnitValue: string;
  unityOfMeasure: UNIT | null;
  ncm: string;
  cest: string;
  ean: string;
  cfop: string;
  pisCst: PisCstCode | null;
  pis: number;
  icmsCst: string;
  icms: number;
  cofinsCst: PisCstCode | null;
  cofins: number;
  ipiCst: IpiCst;
  ipi: number;
  notes: string;
  taxBenefit: string;
  taxSource: string;
  municipalTax: number | null;
  stateTax: number | null;
  federalTax: number | null;
}

interface DialogUpdateOrCreateProductProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  product?: Product;
}

const DialogUpdateOrCreateProduct: FC<DialogUpdateOrCreateProductProps> = ({
  onClose,
  companyToken,
  product,
  ...props
}) => {
  const keepDialog = useRef(false);
  const { mutateAsync: mutate } = useMutationPostProducts();

  const defaultValues = useMemo<DefaultValues<ProductForm>>(
    () => ({
      code: product?.code || "",
      name: product?.name || "",
      description: product?.description || "",
      initialQuantity: product?.initialQuantity || 0,
      minimumQuantity: product?.minimumQuantity || 0,
      averageUnitValue: formatCurrencyBRL(product?.averageUnitValue ?? 0),
      unityOfMeasure: product?.unityOfMeasure || null,
      ncm: product?.ncm || "",
      cest: product?.cest || "",
      pisCst: product?.pisCst || null,
      pis: product?.pis || 0,
      icms: product?.icms || 0,
      cofins: product?.cofins || 0,
      ipi: product?.ipi || 0,
      cfop: product?.cfop || "",
      cofinsCst: product?.cofinsCst || null,
      ean: product?.ean || "",
      icmsCst: product?.icmsCst || "",
      ipiCst: product?.ipiCst || "",
      notes: product?.notes || "",
      taxBenefit: product?.taxBenefit || "",
      taxSource: product?.taxSource || "",
      municipalTax: product?.municipalTax ?? null,
      federalTax: product?.federalTax ?? null,
      stateTax: product?.stateTax ?? null,
    }),
    [],
  );

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<ProductForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleClose = () => {
    if (isSubmitting) return;
    reset(defaultValues);
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    const formatNumber = (number?: string | number | null) => {
      return number ? number.toString().replace(/,/g, ".") : "";
    };

    await mutate({
      id: product?.id || undefined,
      companyToken,
      averageUnitValue: formattedInput(data.averageUnitValue),
      cest: data.cest,
      code: data.code,
      cofins: formatNumber(data.cofins),
      cofinsCst: data.cofinsCst || "",
      description: data.description,
      ean: data.ean,
      icms: formatNumber(data.icms),
      icmsCst: data.icmsCst,
      initialQuantity: formatNumber(data.initialQuantity),
      ipi: formatNumber(data.ipi),
      ipiCst: data.ipiCst,
      minimumQuantity: formatNumber(data.minimumQuantity),
      name: data.name,
      ncm: data.ncm,
      notes: data.notes,
      pis: formatNumber(data.pis),
      pisCst: data.pisCst || "",
      unityOfMeasure: data.unityOfMeasure || "",
      cfop: data.cfop,
      taxBenefit: data.taxBenefit ?? null,
      taxSource: data.taxSource ?? null,
      municipalTax: Number(data.municipalTax) ?? null,
      stateTax: Number(data.stateTax) ?? null,
      federalTax: Number(data.federalTax) ?? null,
    });

    if (keepDialog.current) {
      reset(defaultValues);
      keepDialog.current = false;
      return;
    }

    handleClose();
  });

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {product ? "Edite" : "Cadastre"} um produto
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
        noValidate
      >
        <DialogContent
          sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 2, rowGap: 3 }}
        >
          <Controller
            control={control}
            name="code"
            render={({ field, fieldState }) => (
              <TextField
                label="Código"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <TextField
                label="Nome"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                sx={{ gridColumn: "2/-1" }}
              />
            )}
          />

          <Controller
            control={control}
            name="description"
            render={({ field, fieldState }) => (
              <TextField
                label="Descrição"
                multiline
                rows={2}
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                sx={{ gridColumn: "1/-1" }}
              />
            )}
          />

          <Controller
            control={control}
            name="initialQuantity"
            render={({ field, fieldState }) => (
              <TextField
                label="Estoque inicial"
                type="number"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="minimumQuantity"
            render={({ field, fieldState }) => (
              <TextField
                label="Estoque mínimo"
                type="number"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="averageUnitValue"
            render={({ field, fieldState }) => (
              <CurrencyInput
                {...field}
                numberMask={{
                  decimalLimit: 5,
                }}
                inputProps={{
                  error: !!fieldState.error?.message,
                  helperText: fieldState.error?.message,
                  label: "Valor médio unitário (R$)",
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="unityOfMeasure"
            render={({ field, fieldState }) => (
              <UnityOfMeasureSelect
                {...field}
                label="Unidade de medida"
                helperText={fieldState.error?.message}
                error={!!fieldState.error?.message}
              />
            )}
          />

          <TaxesAndCodesFields control={control} />
          <NFCFields control={control} />

          <Divider sx={{ gridColumn: "1/-1" }} />

          <Controller
            control={control}
            name="notes"
            render={({ field, fieldState }) => (
              <TextField
                label="Informações adicionais"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                sx={{ gridColumn: product?.createdOn || product?.updatedOn ? "1/span 3" : "1/-1" }}
              />
            )}
          />

          {product && !!product.createdOn && (
            <TextField
              value={DateTime.fromISO(product.createdOn).toFormat("dd/MM/yyyy")}
              disabled
              label="Data de cadastro"
            />
          )}
          {product && !!product.updatedOn && (
            <TextField
              value={DateTime.fromISO(product.updatedOn).toFormat("dd/MM/yyyy")}
              disabled
              label="Última atualização"
            />
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" loading={isSubmitting} type="submit">
            Salvar
          </LoadingButton>
          {!product && (
            <LoadingButton
              variant="outlined"
              loading={isSubmitting}
              onClick={() => {
                keepDialog.current = true;
                onSubmit();
              }}
            >
              Salvar e cadastrar outro
            </LoadingButton>
          )}
          <Button
            disabled={isSubmitting}
            onClick={handleClose}
            variant={product ? "outlined" : undefined}
          >
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogUpdateOrCreateProduct;
