import { FC, useState } from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { TemplateAccountanting } from "types/Company";
import TemplateAccountingTableItem from "./TemplateAccountingTableItem";
import NewOrEditTemplate from "../components/NewOrEditTemplate";

interface TemplateAccountingTableProps {
  templatesAccounting: TemplateAccountanting[];
}

const TemplateAccountingTable: FC<TemplateAccountingTableProps> = ({ templatesAccounting }) => {
  const [edittedTemplate, setEdittedTemplate] = useState<TemplateAccountanting | null>(null);

  const closeEditDialog = () => setEdittedTemplate(null);

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {templatesAccounting.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>Nenhum template cadastrado</TableCell>
              </TableRow>
            )}

            {templatesAccounting.map((template) => (
              <TemplateAccountingTableItem
                key={template.id}
                template={template}
                onEdit={() => setEdittedTemplate(template)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {edittedTemplate && (
        <NewOrEditTemplate
          keepMounted={false}
          open={!!edittedTemplate}
          onClose={closeEditDialog}
          template={edittedTemplate}
        />
      )}
    </>
  );
};

export default TemplateAccountingTable;
