import { FC } from "react";
import useMutationUploadLogo from "../../hooks/useMutationUploadLogo";

import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface LogoProps {
  eNotasId: string | null;
  companyToken: string;
  idInvoice: number;
}

const Logo: FC<LogoProps> = ({ eNotasId, companyToken, idInvoice }) => {
  const { mutateAsync, isLoading } = useMutationUploadLogo();

  const handleUploadLogo = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target.files[0];
    await mutateAsync({
      companyToken,
      file,
      id: idInvoice,
      invoiceType: "NFS-e",
    });
  };

  return (
    <Box>
      <Typography variant="body1" fontWeight={600}>
        Logo
      </Typography>

      {eNotasId ? (
        <label style={{ width: "fit-content" }}>
          <input
            type="file"
            accept=".png"
            style={{
              display: "none",
            }}
            name="files"
            onChange={handleUploadLogo}
            disabled={isLoading}
          />
          <LoadingButton
            variant="contained"
            component="span"
            sx={{
              whiteSpace: "nowrap",
            }}
            loading={isLoading}
          >
            Selecionar imagem
          </LoadingButton>
        </label>
      ) : (
        <Typography variant="body2" color="text.secondary" fontStyle="italic">
          * Necessário configurar primeiro a nota.
        </Typography>
      )}
    </Box>
  );
};

export default Logo;
