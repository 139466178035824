import { FC } from "react";

import { Breadcrumbs, BreadcrumbsProps, SxProps, Tooltip, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import { Theme } from "@mui/system";

import { Paths } from "../../utils/path";

interface PathNavigationProps extends BreadcrumbsProps {
  paths: Paths;
  onChangePaths?: (newPaths: Paths) => void;
}

const PathNavigation: FC<PathNavigationProps> = ({ paths, onChangePaths, ...props }) => {
  const styleInactive: SxProps<Theme> = {
    cursor: "pointer",
    color: "text.secondary",
    "&:hover": { textDecoration: "underline", color: "text.primary" },
  };

  const styleActive: SxProps<Theme> = {
    color: "text.primary",
  };

  const dispatchEventChangePath = (index: number) => {
    const newPaths = [...paths].slice(0, index);
    onChangePaths?.(newPaths);
  };

  return (
    <Breadcrumbs {...props}>
      <Typography
        variant="body1"
        style={{
          display: "flex",
          alignItems: "center",
        }}
        sx={paths.length !== 0 ? styleInactive : styleActive}
        onClick={() => dispatchEventChangePath(0)}
      >
        <HomeIcon fontSize="small" color="inherit" sx={{ mr: 0.5 }} />
        Inicio
      </Typography>

      {paths.map((path, index, arr) => (
        <Typography
          variant="body1"
          color={arr.length - 1 === index ? "text.primary" : "text.secondary"}
          key={path.id}
          sx={arr.length - 1 === index ? styleActive : styleInactive}
          onClick={() => dispatchEventChangePath(index + 1)}
        >
          {path.name}
        </Typography>
      ))}

      {paths.length !== 0 && (
        <Tooltip title="Voltar">
          <Typography
            variant="body1"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => dispatchEventChangePath(paths.length - 1)}
          >
            <ArrowBackIcon sx={{ mr: 0.5, fontSize: "14px" }} />
          </Typography>
        </Tooltip>
      )}
    </Breadcrumbs>
  );
};

export default PathNavigation;
