import { FC, useMemo, useState } from "react";
import useMutationSetPaymentMethod from "../../hooks/useMutationSetPaymentMethod";
import useMutationAddOrderOpenCompany from "../../hooks/useMutationAddOrderOpenCompany";
import useQueryPaymentInfo from "pages/Home/pages/Profile/hooks/useQueryPaymentInfo";
import { useForm, FormProvider, DefaultValues } from "react-hook-form";

import { Dialog, DialogContent, Theme, useMediaQuery } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { ServiceDialogProps } from "..";
import DialogConfirmClose from "../../components/DialogConfirmClose";
import DialogMessageAfterAdd from "../../components/DialogMessageAfterAdd";
import TabsController from "./TabsController";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import toast from "react-hot-toast";
import { createPaymentToken } from "services/Requests/Iugu";

/* eslint-disable camelcase */

export interface FormFields {
  accountabilityType: string;
  regimeType: "1" | "2" | "3";
  iAgree: boolean;
  prolabore: number;
  employees: number;
  invoicing: string;
  paymentOption: string;

  card: {
    number: string;
    name: string;
    monthValidity: Date;
    yearValidity: Date;
    securityCode: number;
  };

  planId?: string;
  value?: number;
}

const DialogFreeOpening: FC<ServiceDialogProps> = ({
  onClose,
  companyToken,
  service,
  ...props
}) => {
  const { data: paymentInfo } = useQueryPaymentInfo(companyToken);
  const { mutateAsync } = useMutationAddOrderOpenCompany();
  const { mutateAsync: mutateSetPayment, isLoading: loadingSetPayment } =
    useMutationSetPaymentMethod();
  const [isSettingPayment, setIsSettingPayment] = useState(false);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const [showMessageAfterAdd, setShowMessageAfterAdd] = useState(false);
  const [confirmExit, setConfirmExit] = useState(false);

  const defaultValues = useMemo<DefaultValues<FormFields>>(
    () => ({
      servicePremiumPriceId: service.premiumPrices[0]?.id?.toString(),
      iAgree: false,
      accountabilityType: "service",
      regimeType: "1",
      employees: 0,
      prolabore: 0,
      invoicing: "",
      paymentOption: "bankslip",
      card: {
        name: "",
        number: "",
        monthValidity: undefined,
        yearValidity: undefined,
        securityCode: undefined,
      },
      planId: undefined,
      value: 0,
    }),
    [service],
  );
  const formMethods = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleClose = () => {
    if (formMethods.formState.isSubmitting) {
      return;
    }

    setShowMessageAfterAdd(false);
    setConfirmExit(false);
    onClose();
  };

  const handleSubmit = formMethods.handleSubmit(async ({ card, ...data }) => {
    if ((!paymentInfo || !paymentInfo.iuguAccountId) && data.paymentOption === "card") {
      toast.error("Informações bancárias da conta não foram encontradas!");
      return;
    }

    const isSuccess = await mutateAsync({
      companyToken,
      numberEmployees: Number(data.employees),
      numberPartners: 1,
      numberProlabore: Number(data.prolabore),
      orderState: 0,
      paymentState: 0,
      planId: data.planId,
      tributationType: data.regimeType === "1" ? 0 : 1,
      typeOrder: 0,
      typeService: 1,
      value: Number(data.value),
      parcels: "0/1",
    });

    const setPayment = async (token?: string) => {
      await mutateSetPayment({
        companyToken,
        description: "Boleto",
        paymentMethod: data.paymentOption === "bankslip" ? "bankslip" : "card",
        typeService: 1,
        paymentToken: token,
      });
      setShowMessageAfterAdd(true);
    };

    const cardPayment = async () => {
      setIsSettingPayment(true);
      if (!paymentInfo?.iuguAccountId) return;

      const year = new Date(card.yearValidity).getFullYear().toString();
      const month = (new Date(card.monthValidity).getMonth() + 1).toString().padStart(2, "0");

      await createPaymentToken(
        {
          cardNumber: card.number,
          fullName: card.name,
          iuguAccountId: paymentInfo.iuguAccountId,
          month,
          year,
          securityCode: card.securityCode,
        },
        async (response) => {
          setIsSettingPayment(false);

          if (response.errors) {
            if (Object.keys(response.errors).length === 0) {
              toast.error("Erro ao processar o cartão de crédito, tente novamente mais tarde.");
            } else {
              toast.error("Verifique os dados do cartão de crédito e tente novamente.");
            }
            return;
          }

          if (response.id) {
            await setPayment(response.id);
          }
        },
      );
    };

    if (isSuccess) {
      if (data.paymentOption === "bankslip") {
        await setPayment();
      } else {
        await cardPayment();
      }
    }
  });

  return (
    <FormProvider {...formMethods}>
      {(loadingSetPayment || isSettingPayment) && <FullscreenLoadingIndicator />}

      <Dialog
        {...props}
        open={props.open && !showMessageAfterAdd}
        onClose={() => setConfirmExit(true)}
        maxWidth="lg"
        fullScreen={!isDesktop}
        fullWidth
      >
        <DialogTitleWithCloseButton onClose={() => setConfirmExit(true)}>
          Faça você mesmo - Abertura de empresa
        </DialogTitleWithCloseButton>

        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(e);
          }}
        >
          <DialogContent>
            <TabsController companyToken={companyToken} service={service} />
          </DialogContent>
        </form>
      </Dialog>

      <DialogMessageAfterAdd
        title="Faça você mesmo - Abertura de empresa"
        message={service.serviceTexts.endText}
        open={showMessageAfterAdd}
        onClose={handleClose}
      />

      <DialogConfirmClose
        open={confirmExit}
        onClose={() => setConfirmExit(false)}
        onConfirm={handleClose}
      />
    </FormProvider>
  );
};

export default DialogFreeOpening;
