import { formattedInput } from "components/CurrencyInput";
import * as yup from "yup";

const emptyNumberValidation = yup
  .number()
  .nullable()
  .transform((value: number | string) => {
    if (value === undefined || value === null || value === "") return null;
    return Number(value.toString().replace(/,/g, "."));
  })
  .test("onlyNumber", "Digite apenas números", (value) => {
    if (value === undefined || value === null) return true;
    return !isNaN(Number(value));
  })
  .test("isPositive", "Digite apenas números positivos", (value) => {
    if (value === undefined || value === null || isNaN(Number(value))) return true;
    return Number(value) >= 0;
  })
  .test("match", "Até 3 casas decimais", (value) => {
    if (value === undefined || value === null) return true;
    return /^[0-9]+(\.[0-9]{1,3})?$/.test(value.toString());
  })
  .typeError("Digite apenas números");

const validationSchema = yup.object().shape({
  code: yup.string().max(20, "Máximo 20 caracteres"),
  name: yup.string().required("O nome do produto é obrigatório").max(100, "Máximo 100 caracteres"),
  description: yup.string().max(200, "Máximo 200 caracteres"),
  initialQuantity: emptyNumberValidation,
  minimumQuantity: emptyNumberValidation,
  averageUnitValue: yup.string().test("validNumber", "Digite um valor válido", (v) => {
    if (!v) return true;
    return formattedInput(v) >= 0;
  }),
  ncm: yup.string().max(8, "Máximo 8 caracteres"),
  cest: yup.string().max(50, "Máximo 50 caracteres"),
  ean: yup.string().max(25, "Máximo 25 caracteres"),
  cfop: yup.string().max(4, "Máximo 4 caracteres"),
  pis: emptyNumberValidation.max(100, "O máximo é 100%"),
  icmsCst: yup.string().max(4, "Máximo 4 caracteres"),
  icms: emptyNumberValidation.max(100, "O máximo é 100%"),
  cofins: emptyNumberValidation.max(100, "O máximo é 100%"),
  ipi: emptyNumberValidation.max(100, "O máximo é 100%"),
  municipalTax: emptyNumberValidation.max(100, "O máximo é 100%"),
  stateTax: emptyNumberValidation.max(100, "O máximo é 100%"),
  federalTax: emptyNumberValidation.max(100, "O máximo é 100%"),
});

export default validationSchema;
