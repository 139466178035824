import { FC } from "react";
import useMutationMoveReleasesFinancial from "../../../hooks/useMutationMoveReleasesFinancial";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import CategorySelect from "./CategorySelect";
import { CategoryWithRelease } from "types/Company";

interface DialogTransferProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  categoriesWithReleases: CategoryWithRelease;
}

const DialogTransfer: FC<DialogTransferProps> = ({
  categoriesWithReleases,
  companyToken,
  onClose,
  ...props
}) => {
  const { mutateAsync } = useMutationMoveReleasesFinancial();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      items: categoriesWithReleases.categoriesItem,
    },
  });
  const { fields } = useFieldArray({
    name: "items",
    control,
  });

  const handleClose = () => {
    if (isSubmitting) return;
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      categories: data.items,
      companyToken,
    });
    handleClose();
  });

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Lançamentos relacionados à categorias
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Nome da categoria</TableCell>
                  <TableCell align="right">Quantidade de lançamentos</TableCell>
                  <TableCell>Nova categoria</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field, index) => (
                  <TableRow key={field.idCategory} hover>
                    <TableCell>
                      {!field.current && "*"}
                      {field.category}
                    </TableCell>
                    <TableCell align="right">{field.count}</TableCell>
                    <TableCell>
                      <Controller
                        control={control}
                        name={`items.${index}.idNewCategory`}
                        render={({ field }) => (
                          <CategorySelect
                            label="Selecione a nova categoria"
                            categories={categoriesWithReleases.globalCategory}
                            FormControlProps={{ fullWidth: true, sx: { minWidth: 300 } }}
                            size="small"
                            {...field}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              {categoriesWithReleases.categoriesItem.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>A conta não possui lançamentos.</TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>

          <Typography fontStyle="italic" variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            * Lançamentos pertencentes a planos financeiros substituídos.
          </Typography>
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" loading={isSubmitting} type="submit">
            Transferir
          </LoadingButton>
          <Button variant="outlined" disabled={isSubmitting} onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogTransfer;
