import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/Auth";
import useNavigateHomePage from "hooks/useNavigateHomePage";

import {
  Box,
  Breadcrumbs,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import UploadIcon from "@mui/icons-material/Upload";
import HelpIcon from "@mui/icons-material/Help";

import Head from "components/Head";
import getSubdomain from "utils/getSubdomain";
import ImportForm from "./ImportForm";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import { ImportOFXResponse } from "types/Company";
import Extracts from "./Extracts";

const ImportRelease: FC = () => {
  const [importResult, setImportResult] = useState<ImportOFXResponse | null>(null);
  const navigatePrimaryPath = useNavigateHomePage();

  const navigate = useNavigate();
  const {
    user: { company },
  } = useAuth();

  return (
    <>
      <Head title={`Importação OFX | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Importação OFX
          <Tooltip
            placement="right"
            title={
              <>
                <Typography color="background.paper">
                  Siga estes passos para importar o seu extrato bancário:
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{ color: "background.paper" }}
                      primary="- Abra uma nova janela e entre no site do seu banco."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{ color: "background.paper" }}
                      primary="- Exporte seu extrato bancário para o formato .OFX."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{ color: "background.paper" }}
                      primary="- Selecione o arquivo exportado e a conta bancária nos campos ao lado."
                    />
                  </ListItem>
                </List>
              </>
            }
          >
            <HelpIcon sx={{ ml: 0.5 }} fontSize="medium" color="primary" />
          </Tooltip>
        </Typography>

        <Breadcrumbs>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigatePrimaryPath();
            }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/lancamentos"
            onClick={(e) => {
              e.preventDefault();
              navigate("/lancamentos");
            }}
          >
            <AttachMoneyIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Lançamentos
          </Link>
          <Typography sx={{ display: "flex", alignItems: "center" }} color="text.primary">
            <UploadIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Importação
          </Typography>
        </Breadcrumbs>
      </Stack>

      {company ? (
        <>
          <Box bgcolor="background.paper" mt={2} p={2} borderTop={2} borderColor="primary.main">
            <ImportForm
              companyToken={company.token}
              onImport={setImportResult}
              hasImport={!!importResult}
              onRequestImport={() => setImportResult(null)}
            />
          </Box>

          {importResult && (
            <Box
              bgcolor="background.paper"
              mt={2}
              boxShadow={2}
              borderTop={2}
              borderColor="primary.main"
            >
              <Extracts importResult={importResult} companyToken={company.token} />
            </Box>
          )}
        </>
      ) : (
        <Typography>{MESSAGE_NO_COMPANY}</Typography>
      )}
    </>
  );
};

export default ImportRelease;
