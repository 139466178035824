import { FC } from "react";
import { Box, Typography } from "@mui/material";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface CardFinancialProps {
  title: string;
  value: number;
  color: string;
}

const CardFinancial: FC<CardFinancialProps> = ({ title, value, color }) => (
  <Box p={2} borderRadius={1} bgcolor="background.paper" border={1} borderColor="divider">
    {title}
    <Typography mt={2} variant="h5" fontWeight={600} color={`${color}.main`}>
      {formatCurrencyBRL(value)}
    </Typography>
  </Box>
);

export default CardFinancial;
