import { FC, useState } from "react";

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import InvoiceTableItem from "./InvoiceTableItem";
import { Transactions } from "types/Company";
import DialogConsultInvoice from "./DialogConsultInvoice";
import MassAction from "./components/MassAction";

interface InvoiceTableProps {
  invoice: Transactions;
  companyToken: string;
}

const InvoiceTable: FC<InvoiceTableProps> = ({ invoice, companyToken }) => {
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string[]>([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [consultInvoiceId, setConsultInvoiceId] = useState<null | number>(null);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectInvoice = (id: string) => {
    setSelectedInvoiceId((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return prev.concat(id);
    });
  };

  return (
    <>
      <MassAction invoice={invoice.items} selectedInvoiceId={selectedInvoiceId} />

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" className="no-print" data-exclude="true">
                <Checkbox
                  size="small"
                  color="primary"
                  indeterminate={
                    selectedInvoiceId.length > 0 && selectedInvoiceId.length < invoice.items.length
                  }
                  checked={
                    invoice.items.length > 0 && selectedInvoiceId.length === invoice.items.length
                  }
                  onChange={(_, isChecked) => {
                    setSelectedInvoiceId(
                      isChecked ? invoice.items.map((i) => i.invoiceNumber) : [],
                    );
                  }}
                />
              </TableCell>
              <TableCell>Nº</TableCell>
              <TableCell>Vencimento</TableCell>
              <TableCell>Emissão</TableCell>
              <TableCell>Beneficiário</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell align="right">Valor (R$)</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell />
              <TableCell align="right" />
            </TableRow>
          </TableHead>

          <TableBody>
            {invoice.items.length === 0 && (
              <TableRow>
                <TableCell colSpan={10}>Nenhum NFS-e encontrado</TableCell>
              </TableRow>
            )}

            {invoice.items
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <InvoiceTableItem
                  invoice={item}
                  key={item.id}
                  selected={selectedInvoiceId.includes(item.invoiceNumber)}
                  onSelect={() => handleSelectInvoice(item.invoiceNumber)}
                  onConsult={() => setConsultInvoiceId(item.id)}
                  companyToken={companyToken}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage={"Linhas por páginas: "}
        labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={invoice.items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {!!consultInvoiceId && (
        <DialogConsultInvoice
          open
          companyToken={companyToken}
          invoiceId={consultInvoiceId}
          onClose={() => setConsultInvoiceId(null)}
        />
      )}
    </>
  );
};

export default InvoiceTable;
