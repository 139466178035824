import { FC } from "react";
import useMUtationGeneratePassDefault from "../hooks/useMUtationGeneratePassDefault";

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

interface DialogGeneratePassDefaultProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyId: number;
}

const DialogGeneratePassDefault: FC<DialogGeneratePassDefaultProps> = ({
  onClose,
  companyId,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMUtationGeneratePassDefault();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleGenerate = async () => {
    await mutateAsync({ companyId });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Gerar senha padrão
      </DialogTitleWithCloseButton>

      <DialogContent>Confirma a geração da senha padrão &quot;123mudar&quot;?</DialogContent>

      <DialogActions>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleGenerate}>
          Sim
        </LoadingButton>
        <Button variant="outlined" disabled={isLoading} onClick={handleClose}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogGeneratePassDefault;
