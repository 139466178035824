import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMutationCopyTemplateFinancial from "../hooks/useMutationCopyTemplateFinancial";
import useMutationDeleteTemplateFinancial from "../hooks/useMutationDeleteTemplateFinancial";

import {
  IconButton,
  TableCell,
  TableRow,
  Menu as DropdownMenu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Menu from "@mui/icons-material/Menu";

import { TemplateFinancial } from "types/Company";

interface TemplateFinancialTableItemProps {
  template: TemplateFinancial;
  onEditTemplate: () => void;
}

const TemplateFinancialTableItem: FC<TemplateFinancialTableItemProps> = ({
  template,
  onEditTemplate,
}) => {
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useMutationCopyTemplateFinancial();
  const { mutateAsync: mutateDelete, isLoading: isLoadingDelete } =
    useMutationDeleteTemplateFinancial();

  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  const [confirmationCopy, setConfirmationCopy] = useState(false);
  const [confirmationDelete, setConfirmationDelete] = useState(false);

  const handleCopyTemplate = async () => {
    await mutateAsync({
      id: template.id,
    });
    setConfirmationCopy(false);
  };

  const handleDeleteTemplate = async () => {
    await mutateDelete({
      id: template.id,
    }),
      setConfirmationDelete(false);
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Link
            href={`/financeiro/${template.groupChartOfAccountsToFromId}/${template.id}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/financeiro/${template.groupChartOfAccountsToFromId}/${template.id}`);
            }}
          >
            {template.name}{" "}
            {template.nameGroupChartOfAccounts && `(${template.nameGroupChartOfAccounts})`}
          </Link>
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={(e) => setAnchorElMenu(e.currentTarget)}>
            <Menu />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        anchorEl={anchorElMenu}
        open={menuIsOpen}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => onEditTemplate()}>Editar template</MenuItem>
        <MenuItem onClick={() => setConfirmationCopy(true)}>Criar cópia</MenuItem>
        <MenuItem onClick={() => setConfirmationDelete(true)}>Excluir</MenuItem>
      </DropdownMenu>

      <Dialog
        open={confirmationCopy}
        onClose={() => setConfirmationCopy(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Copiar template</DialogTitle>
        <DialogContent>Deseja criar uma cópia deste template?</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirmationCopy(false)}>
            Não
          </Button>
          <LoadingButton variant="contained" loading={isLoading} onClick={handleCopyTemplate}>
            Sim
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmationDelete}
        onClose={() => setConfirmationDelete(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Excluir template</DialogTitle>
        <DialogContent>Deseja excluir este template?</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirmationDelete(false)}>
            Não
          </Button>
          <LoadingButton
            variant="contained"
            loading={isLoadingDelete}
            onClick={handleDeleteTemplate}
          >
            Sim
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TemplateFinancialTableItem;
