import { useCallback } from "react";
import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getContracts } from "services/Requests/contract";
import { Contract } from "types/Contract";

const useQueryGetContracts = (companyToken: string, searchContract = "") => {
  const selectSearch = useCallback(
    (data: Contract[]) => {
      const searchClient = (name: string) => {
        if (!searchContract.trim()) return true;

        return name.toLowerCase().search(searchContract.toLowerCase()) !== -1;
      };

      return data.filter((contract) => searchClient(contract.filename));
    },
    [searchContract],
  );

  const query = useQuery([queryKeys.contracts], () => getContracts({ companyToken }), {
    select: !searchContract ? undefined : selectSearch,
  });
  return query;
};

export default useQueryGetContracts;
