import { FC } from "react";

import { Stack, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import ControllerInput from "components/ControllerInput";
import CurrencyInput from "components/CurrencyInput";
import { Control, Controller } from "react-hook-form";
import { FormCreateAccount } from ".";

interface AdditionalFieldsProps {
  control: Control<FormCreateAccount>;
}

const AdditionalFields: FC<AdditionalFieldsProps> = ({ control }) => {
  return (
    <>
      <Stack gap={4} direction="row">
        <Controller
          name="accountBalance"
          control={control}
          render={({ field, fieldState }) => (
            <CurrencyInput
              {...field}
              inputProps={{
                label: "Saldo Inicial (R$)",
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message,
                sx: { flex: 1 },
              }}
            />
          )}
        />

        <Controller
          name="accountDate"
          control={control}
          render={({ field, fieldState }) => (
            <DesktopDatePicker
              label="Data do saldo inicial"
              inputFormat="dd/MM/yyyy"
              {...field}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ flex: 1 }}
                  helperText={fieldState.error?.message}
                  error={params.error || !!fieldState.error?.message}
                />
              )}
            />
          )}
        />
      </Stack>

      <ControllerInput
        name="accountDescription"
        control={control}
        inputProps={{
          label: "Descrição da Conta (campo opcional)",
          sx: { flex: 1 },
          multiline: true,
          rows: 3,
        }}
      />
    </>
  );
};

export default AdditionalFields;
