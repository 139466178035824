import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { removeForm } from "services/Requests/marketplace";

const useMutationRemoveForm = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(removeForm, {
    onSuccess(_, { formName }) {
      toast.success("Formulário removido com sucesso");
      queryClient.invalidateQueries(queryKeys.groupsForm);
      queryClient.invalidateQueries(queryKeys.forms);
      queryClient.invalidateQueries([queryKeys.clientsConfigLeads, formName]);
    },
  });
  return mutation;
};

export default useMutationRemoveForm;
