import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getAllSubs, GetAllSubsPayload } from "services/Requests/marketplace";
import getSubdomain from "utils/getSubdomain";

const useQueryGetAllSubs = (status: GetAllSubsPayload["status"], search: string | null) => {
  const subdomain = getSubdomain();
  const queryKey = [queryKeys.allSubs, status];

  if (search) {
    queryKey.push(search);
  }

  const query = useQuery(
    queryKey,
    () =>
      getAllSubs({
        maxResults: 100,
        query: search,
        start: 0,
        status,
        subdomain,
      }),
    {
      keepPreviousData: true,
    },
  );
  return query;
};

export default useQueryGetAllSubs;
