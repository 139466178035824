import queryKeys from "react-query/constants";
import { useQuery } from "react-query";
import { getInvoideConfig } from "services/Requests/companies";
import { InvoiceConfig } from "types/Company";

const useQueryGetInvoiceConfig = (
  companyToken: string,
  onSuccess?: (data: InvoiceConfig) => void,
) => {
  const query = useQuery(queryKeys.invoiceConfig, () => getInvoideConfig({ companyToken }), {
    onSuccess,
  });
  return query;
};

export default useQueryGetInvoiceConfig;
