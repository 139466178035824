import { FC, memo, useEffect, useMemo, useState } from "react";
import { useAuth } from "context/Auth";
import { useSideMenu } from "context/SideMenuContext";
import { useLocation } from "react-router-dom";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";
import useNavigateHomePage from "hooks/useNavigateHomePage";
import useUpdateEffect from "hooks/useUpdateEffect";

import { MenuList } from "@mui/material";
import { grey } from "@mui/material/colors";
import HomeIcon from "@mui/icons-material/Home";
import { Menu as MenuIcon } from "@mui/icons-material";

import getSideMenuItems from "../../utils/getSideMenuItems";
import userTypeComplies from "utils/userTypeComplies";

import DialogCompany from "pages/Home/components/DialogCompany";
import SideMenuItem from "./SideMenuItem";
import MyCompanyMenuItem from "./MyCompanyMenuItem";

const SideMenuList: FC = () => {
  const { setIsOpen } = useSideMenu();

  const { pathname } = useLocation();
  const navigatePrimaryPath = useNavigateHomePage();

  const { user } = useAuth();
  const { isCompany, setIsCompany } = useSideMenu();
  const [showConfiguration, setShowConfiguration] = useState(false);

  const { data: companyInfo } = useQueryGetCompany(user.company?.token || "");

  const sideMenuItems = useMemo(() => getSideMenuItems(user, !isCompany), [isCompany, user]);

  useUpdateEffect(() => {
    navigatePrimaryPath();
  }, [isCompany]);

  useEffect(() => {
    if (pathname === "/") {
      navigatePrimaryPath();
    }
  }, [pathname]);

  const sideMenuTitle = useMemo(() => {
    if (userTypeComplies(["Accountant", "Admin"], user.userInfo.userType)) {
      return "Administrativo";
    }

    if (!companyInfo) {
      return "";
    }

    let title = "";

    if (companyInfo?.code) {
      title += companyInfo.code;
    }

    const companyName = companyInfo?.companyName || user.company?.name;
    if (companyName) {
      if (companyInfo?.code) {
        title += " - ";
      }
      title += `${companyInfo?.companyName || user.company?.name}`;
    }

    return title;
  }, [user, companyInfo]);

  return (
    <MenuList
      sx={{
        height: "calc(100% - 16px)",
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin",
        "::-webkit-scrollbar": {
          width: "10px",
        },
        "::-webkit-scrollbar-track": {
          background: grey[200],
        },
        "::-webkit-scrollbar-thumb": {
          background: grey[600],
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: grey[700],
        },
      }}
    >
      <SideMenuItem
        onClick={() => setIsOpen((prev) => !prev)}
        title={sideMenuTitle}
        Icon={MenuIcon}
      />

      {sideMenuItems.map((sideMenuItem) => (
        <SideMenuItem key={sideMenuItem.title} {...sideMenuItem} />
      ))}

      {isCompany ? (
        <MyCompanyMenuItem
          onChangeCompany={() => setIsCompany(false)}
          onShowConfiguration={() => {
            if (user?.company && user.company.registrationStatus !== "Registered") {
              setShowConfiguration(true);
            }
          }}
        />
      ) : (
        <SideMenuItem
          onClick={() => setIsCompany(true)}
          title="Minha contabilidade"
          Icon={HomeIcon}
        />
      )}

      {user.company && showConfiguration && (
        <DialogCompany
          company={user.company}
          open={showConfiguration}
          onClose={() => setShowConfiguration(false)}
        />
      )}
    </MenuList>
  );
};

export default memo(SideMenuList);
