import { useContext } from "react";
import { DashboardPeriodContext } from ".";

export const useDashboardPeriod = () => {
  const ctx = useContext(DashboardPeriodContext);

  if (!ctx) {
    throw new Error("useDashboardPeriod must be used within a DashboardPeriodProvider");
  }

  return ctx;
};
