import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getLeads, GetLeadsPayload } from "services/Requests/marketplace";
import getSubdomain from "utils/getSubdomain";

const useQueryLeads = ({
  startDate,
  endDate,
  term,
  typeForm,
}: Omit<GetLeadsPayload, "subdomain">) => {
  const subdomain = getSubdomain();

  const queryKey: (number | string)[] = [queryKeys.leads, startDate, endDate];
  if (typeForm) queryKey.push(typeForm);
  if (term) queryKey.push(term);

  const query = useQuery(queryKey, () =>
    getLeads({ startDate, endDate, term, typeForm, subdomain }),
  );
  return query;
};

export default useQueryLeads;
