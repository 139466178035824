import { FC } from "react";

import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import Search from "@mui/icons-material/Search";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";

import { CompanyPartner } from "types/Company";

interface PartnersCardListProps {
  companyPartners: CompanyPartner[];
  isQueryError: boolean;
  onEdit: (partner: CompanyPartner) => void;
  onDelete: (partner: CompanyPartner) => void;
  onConsult: (partner: CompanyPartner) => void;
}

const PartnersCardList: FC<PartnersCardListProps> = ({
  companyPartners,
  isQueryError,
  onEdit,
  onDelete,
  onConsult,
}) => {
  return (
    <Stack gap={2}>
      {companyPartners.length === 0 && <Typography>Sócios não foram cadastrados</Typography>}
      {isQueryError && <Typography>Ocorreu um error ao buscar seus sócios</Typography>}

      {companyPartners.map((partner) => (
        <Card key={partner.id}>
          <CardContent>
            <Typography variant="h6" fontWeight={600} gutterBottom>
              {partner.name}
            </Typography>
            <Typography color="text.secondary">CPF/CNPJ: {partner.cpfcnpj || "-"}</Typography>
          </CardContent>

          <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"} p={1}>
            <Button
              variant="contained"
              startIcon={<Search />}
              sx={{ flex: 1, flexBasis: "140px" }}
              onClick={() => onConsult(partner)}
            >
              Consultar
            </Button>
            <Button
              variant="contained"
              startIcon={<Edit />}
              color="info"
              sx={{ flex: 1, flexBasis: "140px" }}
              onClick={() => onEdit(partner)}
            >
              Editar
            </Button>
            <Button
              variant="outlined"
              color="error"
              startIcon={<Delete />}
              sx={{ flex: 1, flexBasis: "140px" }}
              onClick={() => onDelete(partner)}
            >
              Excluir
            </Button>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};

export default PartnersCardList;
