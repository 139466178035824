import { FC } from "react";

import { Box, Button, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface IntroductionProps {
  onNext: () => void;
}

const Introduction: FC<IntroductionProps> = ({ onNext }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box>
      <Typography align="center" variant="body1">
        Nessa modalidade você adere ao plano de contabilidade mensal equivalente ao da sua empresa
        quando aberta e você mesmo faz toda a parte burocrática. Fazemos uma análise para definir o
        melhor modelo de empresa e entregamos a documentação pronta com as instruções do que fazer.
      </Typography>

      <Typography
        align="center"
        variant={isMobile ? "h2" : "h1"}
        sx={{
          mt: 1,
          mb: 3,
          fontWeight: 600,
        }}
      >
        R$ 0,00
      </Typography>

      <Stack alignItems="end">
        <Button variant="contained" onClick={onNext} endIcon={<NavigateNextIcon />}>
          Próximo passo
        </Button>
      </Stack>
    </Box>
  );
};

export default Introduction;
