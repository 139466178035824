import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("O nome é obrigatório"),
  type: yup
    .number()
    .required("O tipo de template é obrigatório")
    .typeError("O tipo de template é obrigatório"),
  file: yup.string().required("O formato do arquivo é obrigatório"),
});

export default validationSchema;
