import { FC, PropsWithChildren, useState } from "react";
import useLocalStorage from "hooks/useLocalStorage";

import { CompanyDocumentDrive } from "types/Company";
import { DriveContext } from ".";
import { Paths, pathToDisplay, SEPARATOR_TO_SERVICE } from "../../utils/path";

import DialogDeleteFolder from "../../components/DialogDeleteFolder";
import DialogEditFile from "../../components/DialogEditFile";

interface DriveProviderProps extends PropsWithChildren<unknown> {
  companyToken: string;
}

export const DriveProvider: FC<DriveProviderProps> = ({ children, companyToken }) => {
  const [listingByIcons, setListingByIcons] = useLocalStorage("list-by-icons", false);
  const [documentDriveToDelete, setDocumentDriveToDelete] = useState<CompanyDocumentDrive | null>(
    null,
  );
  const [documentDriveToEdit, setDocumentDriveToEdit] = useState<CompanyDocumentDrive | null>(null);
  const [documentDriveToMove, setDocumentDriveToMove] = useState<CompanyDocumentDrive | null>(null);
  const [paths, setPaths] = useState<Paths>([]);

  const handleToggleListingByIcons = () => setListingByIcons((p) => !p);

  const handleAddNewPath = (document: CompanyDocumentDrive) => {
    setPaths((prev) => [...prev, { id: document.id, name: document.name }]);
  };

  return (
    <DriveContext.Provider
      value={{
        listingByIcons,
        toggleListingByIcons: handleToggleListingByIcons,
        documentDriveToDelete,
        handleChangeDocumentToDelete: (document) => setDocumentDriveToDelete(document),
        documentDriveToEdit,
        handleChangeDocumentToEdit: (doc) => setDocumentDriveToEdit(doc),
        documentDriveToMove,
        handleChangeDocumentToMove: (doc) => setDocumentDriveToMove(doc),
        paths,
        onChangePaths: setPaths,
        addNewPath: handleAddNewPath,
      }}
    >
      {children}

      {documentDriveToDelete && (
        <DialogDeleteFolder
          open={!!documentDriveToDelete}
          onClose={() => setDocumentDriveToDelete(null)}
          companyDocumentDrive={documentDriveToDelete}
          fullPath={pathToDisplay(
            [...paths, { id: documentDriveToDelete.id, name: documentDriveToDelete.name }],
            SEPARATOR_TO_SERVICE,
          )}
        />
      )}

      {(documentDriveToEdit || documentDriveToMove) && (
        <DialogEditFile
          open={!!documentDriveToEdit || !!documentDriveToMove}
          companyToken={companyToken}
          onClose={() => {
            setDocumentDriveToEdit(null);
            setDocumentDriveToMove(null);
          }}
          companyDocumentDrive={
            documentDriveToEdit || (documentDriveToMove as CompanyDocumentDrive)
          }
          isMove={!!documentDriveToMove}
          fullPath={paths}
          onMove={(paths) => setPaths(paths)}
        />
      )}
    </DriveContext.Provider>
  );
};
