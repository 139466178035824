import endpoints from "../endpoints";
import api from "../api";
import { Account, BankingConnectToken, IntegratorAccount } from "types/BankingIntegrator";
import { ImportOFXResponse } from "types/Company";

export const getConnectToken = async () => {
  const { data } = await api.post<BankingConnectToken>(endpoints.bankingIntegrator.getConnectToken);
  return data;
};

export interface GetAccountPayload {
  item: unknown;
  companyToken: string;
  type: null;
}

export const getAccount = async (payload: GetAccountPayload) => {
  const { data } = await api.post<Account>(endpoints.bankingIntegrator.getAccount, payload);
  return data;
};

export interface LinkAccountPayload {
  item: unknown;
  companyToken: string;
  linkedBankAccountId: number;
  account: Account;
}

export const linkAccount = async (payload: LinkAccountPayload) => {
  const { data } = await api.post<unknown>(endpoints.bankingIntegrator.linkAccount, payload);
  return data;
};

export interface GetTransactionsPayload {
  bankAccountId: number;
  companyToken: string;
  from: string; // "2023-05-01";
  to: string; // "2023-05-12";
}

export const getTransactions = async (payload: GetTransactionsPayload) => {
  const { data } = await api.post<ImportOFXResponse>(
    endpoints.bankingIntegrator.getTransactions,
    payload,
  );
  return data;
};

interface GetIntegratorAccountParams {
  companyToken: string;
  bankAccountId: number;
}

export const getIntegratorAccount = async (params: GetIntegratorAccountParams) => {
  const { data } = await api.get<IntegratorAccount>(
    endpoints.bankingIntegrator.getIntegratorAccount,
    {
      params,
    },
  );
  return data;
};

type UpdateAccountKeyParams = GetIntegratorAccountParams;

export const updateAccountKey = async (params: UpdateAccountKeyParams) => {
  const { data } = await api.get(endpoints.bankingIntegrator.updateAccountKey, {
    params,
  });
  return data;
};
