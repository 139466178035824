import { formattedInput } from "components/CurrencyInput";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  accountNick: yup.string().required("Digite um apelido para a conta"),
  accountType: yup.string(),
  accountBank: yup
    .mixed()
    .nullable()
    .when("accountType", (type) => {
      if (type === "1") {
        return yup.mixed().nullable(false).required("Selecione um banco");
      }

      return yup.mixed().nullable(true);
    }),
  accountBranch: yup
    .string()
    .nullable()
    .when("accountType", (type) => {
      if (type === "1") {
        return yup.string().nullable(false).required("Digite a agencia do banco");
      }

      return yup.string().nullable().notRequired();
    }),

  accountNumber: yup
    .string()
    .nullable()
    .when("accountType", (type) => {
      if (type === "1") {
        return yup.string().nullable(false).required("Digite a conta do banco");
      }

      return yup.string().nullable().notRequired();
    }),

  accountBalance: yup
    .string()
    .required("O saldo inicial é obrigatório")
    .test("validPrice", "Digite um saldo válido", (value) => {
      if (!value) return false;
      const price = formattedInput(value);
      return price >= 0;
    }),

  accountDate: yup
    .date()
    .required("A data do saldo inicial é obrigatório")
    .typeError("Digite uma data correta"),
});

export default validationSchema;
