import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { uploadReceipt } from "services/Requests/companies";

const useMutationUploadReceipt = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(uploadReceipt, {
    onSuccess(_, { transactionId }) {
      toast.success("Comprovante anexado com sucesso");
      queryClient.invalidateQueries(queryKeys.transactions, { exact: false });
      queryClient.invalidateQueries([queryKeys.transaction, transactionId], { exact: false });
    },
  });
  return mutation;
};

export default useMutationUploadReceipt;
