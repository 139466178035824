import { TableCell, TableRow } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC } from "react";
import { FinancialSummaryByTypeDTO } from "types/Company";

interface ManagerialIncomeStatementTableItemProps {
  managerialIncomeStatementSummaryByTypeDTO: FinancialSummaryByTypeDTO;
}

const FinancialSummaryTableItem: FC<ManagerialIncomeStatementTableItemProps> = ({
  managerialIncomeStatementSummaryByTypeDTO,
}) => {
  return (
    <>
      <TableRow>
        <TableCell sx={{ bgcolor: grey[200], fontWeight: 600 }} data-f-bold="true">
          {managerialIncomeStatementSummaryByTypeDTO.categoriaSintetica}
        </TableCell>
        <TableCell colSpan={13} sx={{ bgcolor: grey[200] }}></TableCell>
      </TableRow>

      {managerialIncomeStatementSummaryByTypeDTO.summary.map((s, index) => (
        <TableRow key={`${s.categoryName} ${index}`} hover>
          <TableCell>{s.categoryName}</TableCell>

          {s.months.map(({ totalMonth }, index) => (
            <TableCell key={index} align="center" data-a-h="center">
              {totalMonth ? totalMonth.toLocaleString("pt-br") : "-"}
            </TableCell>
          ))}
          <TableCell align="center" data-a-h="center">
            {s.totalYear ? s.totalYear.toLocaleString("pt-br") : "-"}
          </TableCell>
        </TableRow>
      ))}

      {managerialIncomeStatementSummaryByTypeDTO.summary.length === 0 && (
        <TableRow>
          <TableCell colSpan={16} align="center" data-a-h="center">
            Nenhum lançamento no período
          </TableCell>
        </TableRow>
      )}

      {managerialIncomeStatementSummaryByTypeDTO.totalMonths &&
        managerialIncomeStatementSummaryByTypeDTO.summary.length > 0 && (
          <>
            <TableRow sx={{ bgcolor: grey[200] }}>
              <TableCell sx={{ fontWeight: 600 }} data-f-bold="true">
                Total
              </TableCell>
              {managerialIncomeStatementSummaryByTypeDTO.totalMonths.map(
                ({ totalMonth }, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    data-a-h="center"
                    sx={{ fontWeight: 600 }}
                    data-f-bold="true"
                  >
                    {totalMonth ? totalMonth.toLocaleString("pt-br") : "-"}
                  </TableCell>
                ),
              )}
            </TableRow>
            <TableRow sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
              <TableCell sx={{ height: "30px" }} />
            </TableRow>
          </>
        )}
    </>
  );
};

export default FinancialSummaryTableItem;
