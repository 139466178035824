import { FC, useState } from "react";

import { Button } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DialogNewFolder from "./DialogNewFolder";

interface NewFolderProps {
  companyToken: string;
  parentId?: string;
}

const NewFolder: FC<NewFolderProps> = ({ companyToken, parentId }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        startIcon={<FolderIcon fontSize="small" />}
        onClick={() => setIsOpen(true)}
        sx={{ whiteSpace: "nowrap" }}
      >
        Nova pasta
      </Button>

      <DialogNewFolder
        open={isOpen}
        onClose={() => setIsOpen(false)}
        companyToken={companyToken}
        parentId={parentId}
      />
    </>
  );
};

export default NewFolder;
