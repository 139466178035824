import { memo, useState } from "react";
import { useFinancialCategoryId } from "../context/FinancialCategoryId";

import {
  Box,
  Button,
  Collapse,
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { grey } from "@mui/material/colors";

import DialogUpdateSyntheticCategory from "../components/DialogCreateOrUpdateSyntheticCategory";
import DialogDeleteSyntheticCategory from "../components/DialogDeleteSyntheticCategory";
import DialogCreateAnalyticalCategory from "../components/DialogCreateOrUpdateAnalyticalCategory";
import { FinancialCategoryByGroupWithChildren } from "types/Company";
import AnalyticalCategory, { DragItem } from "../AnalyticalCategory";

import { useDrop } from "react-dnd";
import { ITEM } from "../utils/DragDropConstants";
import type { Identifier } from "dnd-core";
import { useTemplate } from "../context/Template/useTemplate";
import { Types } from "../context/Template";

interface SyntheticCategoryUIProps {
  financialCategory: FinancialCategoryByGroupWithChildren;
  indexColumn: number;
  handleProps?: IconButtonProps;
}

const SyntheticCategoryUI = memo(
  ({ financialCategory, indexColumn, handleProps }: SyntheticCategoryUIProps) => {
    const { dispatch, financialCategories, isCompany, companyToken, companyId } = useTemplate();
    const { templateId } = useFinancialCategoryId();

    const [collapsed, setCollapsed] = useState(true);
    const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
    const menuIsOpen = Boolean(anchorElMenu);

    const [editCategory, setEditCategory] = useState(false);
    const [deleteCategory, setDeleteCategory] = useState(false);
    const [createCategory, setCreateCategory] = useState(false);
    const handleEdit = () => setEditCategory(true);
    const handleDelete = () => setDeleteCategory(true);
    const handleAdd = () => setCreateCategory(true);

    const [{ handlerColumnBodyId, canDrop }, dropColumnBody] = useDrop<
      DragItem,
      void,
      { handlerColumnBodyId: Identifier | null; canDrop: boolean }
    >({
      accept: ITEM,
      collect: (monitor) => ({
        handlerColumnBodyId: monitor.getHandlerId(),
        canDrop: monitor.isOver() && monitor.getItem().indexColumn !== indexColumn,
      }),
      drop(item) {
        const dragIndex = item.indexCard;
        const dragColumn = item.indexColumn;

        const hoverIndex = indexColumn;

        if (dragColumn === hoverIndex) return;

        dispatch({
          type: Types.Add_Card_To_Column,
          payload: {
            index: dragIndex,
            indexColumn: dragColumn,
            indexToColumn: hoverIndex,
          },
        });

        item.indexColumn = hoverIndex;
        item.indexCard = financialCategories[indexColumn].children.length;
      },
    });

    const styleCanDrop: SxProps<Theme> = {
      cursor: "grabbing",
      border: 1,
      borderColor: "primary.main",

      "& *": {
        pointerEvents: "none",
        opacity: 0.8,
      },
    };

    return (
      <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          bgcolor="#fff"
          p={2}
          borderBottom={1}
          borderColor={grey[100]}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{
              width: {
                md: 235,
                lg: 365,
              },
            }}
          >
            <IconButton {...handleProps}>
              <DragIndicatorIcon sx={{ color: "text.primary", cursor: "grab" }} />
            </IconButton>

            <Button
              sx={{ color: "text.primary", textAlign: "left", zIndex: 99 }}
              size="small"
              startIcon={
                <ArrowDropDownIcon
                  fontSize="small"
                  sx={{ transform: `rotate(${collapsed ? 0 : -90}deg)` }}
                />
              }
              onClick={() => setCollapsed((prev) => !prev)}
            >
              {financialCategory.name}{" "}
              {financialCategory.nature === null
                ? null
                : financialCategory.nature === "Crédito"
                ? "(Entrada)"
                : "(Saída)"}
            </Button>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end" flex={1} gap={2}>
            <Box display="grid" width="100%" gridTemplateColumns="1fr 1fr" gap={1}>
              <Typography
                sx={{
                  borderRadius: 1,
                  border: 1,
                  borderColor: "divider",
                  p: 0.5,
                  textAlign: "center",
                }}
              >
                Provisão
              </Typography>
              <Typography
                sx={{
                  borderRadius: 1,
                  border: 1,
                  borderColor: "divider",
                  p: 0.5,
                  textAlign: "center",
                }}
              >
                Caixa
              </Typography>
            </Box>

            <IconButton
              size="small"
              sx={{ flexShrink: 0 }}
              onClick={(e) => setAnchorElMenu(e.currentTarget)}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>

        <Collapse
          in={collapsed}
          timeout={{
            enter: 400,
            exit: 0,
          }}
        >
          {collapsed && (
            <Stack
              ref={dropColumnBody}
              data-handler-id={handlerColumnBodyId}
              gap={1}
              p={2}
              bgcolor={grey[50]}
              sx={canDrop ? styleCanDrop : undefined}
            >
              {financialCategory.children.map((child, index) => (
                <AnalyticalCategory
                  key={child.id}
                  financialCategory={child}
                  nature={financialCategory.nature}
                  indexColumn={indexColumn}
                  index={index}
                />
              ))}
            </Stack>
          )}
        </Collapse>

        <Menu
          anchorEl={anchorElMenu}
          open={menuIsOpen}
          onClick={() => setAnchorElMenu(null)}
          onClose={() => setAnchorElMenu(null)}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,

              "&:before": {
                content: "''",
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleAdd}>Adicionar categoria analítica</MenuItem>
          <MenuItem onClick={handleEdit}>Editar</MenuItem>
          <MenuItem onClick={handleDelete}>Excluir</MenuItem>
        </Menu>

        {editCategory && (
          <DialogUpdateSyntheticCategory
            open={editCategory}
            onClose={() => setEditCategory(false)}
            templateId={templateId}
            financialCategory={financialCategory}
            companyToken={companyToken}
            isCompany={isCompany}
            companyId={companyId}
          />
        )}

        {createCategory && (
          <DialogCreateAnalyticalCategory
            open={createCategory}
            onClose={() => setCreateCategory(false)}
            parentCategoryId={financialCategory.id}
            templateId={templateId}
            financialCategories={financialCategories}
            isCompany={isCompany}
            companyToken={companyToken}
          />
        )}

        <DialogDeleteSyntheticCategory
          open={deleteCategory}
          onClose={() => setDeleteCategory(false)}
          id={financialCategory.id}
          isCompany={isCompany}
        />
      </>
    );
  },
);

export default SyntheticCategoryUI;
