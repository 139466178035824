import { TypesOfUsers } from "types/UserTypes";

const userTypeComplies = (type: TypesOfUsers[] | TypesOfUsers, typeUser: TypesOfUsers) => {
  if (Array.isArray(type)) {
    return type.some((t) => t === typeUser);
  }

  return type === typeUser;
};

export default userTypeComplies;
