import { FC, useState } from "react";

import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import useQueryGetProfile from "../../hooks/useQueryGetProfile";
import { CardAction, CardContent } from "../CardWrapper";
import DialogUpdateUserData from "./DialogUpdateUserData";
import { useNavigate } from "react-router-dom";

interface UserDataProps {
  companyToken: string;
}

const UserData: FC<UserDataProps> = ({ companyToken }) => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQueryGetProfile(companyToken);
  const [dialogUpdateUserDataIsOpen, setDialogUpdateUserDataIsOpen] = useState(false);

  return (
    <>
      <CardContent>
        {isLoading && (
          <Stack height={200} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {data && (
          <>
            <Stack gap={2}>
              <TextField label="Nome" fullWidth value={data.name} InputProps={{ readOnly: true }} />
              <TextField
                label="Login / Email"
                fullWidth
                value={data.email}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Celular"
                fullWidth
                value={data.phone}
                InputProps={{ readOnly: true }}
              />
            </Stack>

            {dialogUpdateUserDataIsOpen && (
              <DialogUpdateUserData
                profile={data}
                open={dialogUpdateUserDataIsOpen}
                onClose={() => setDialogUpdateUserDataIsOpen(false)}
              />
            )}
          </>
        )}

        {isError && (
          <Typography variant="body1">
            Ocorreu um erro ao carregar os dados do usuário, tente novamente mais tarde
          </Typography>
        )}
      </CardContent>

      {!isLoading && data && (
        <CardAction>
          <Button variant="contained" onClick={() => setDialogUpdateUserDataIsOpen(true)}>
            Atualizar dados do usuário
          </Button>
          <Button
            href="/alterar-senha"
            onClick={(e) => {
              e.preventDefault();
              navigate("/alterar-senha");
            }}
          >
            Alterar senha
          </Button>
        </CardAction>
      )}
    </>
  );
};

export default UserData;
