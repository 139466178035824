import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton, Link, MenuItem, TableCell, TableRow } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Template } from "types/Template";

import DropdownMenu from "components/DropdownMenu";
import DialogEditTemplate from "./components/DialogCreateOrEditTemplate";
import DialogDeleteTemplate from "./components/DialogDeleteTemplate";

interface TemplateTableItemProps {
  template: Template;
}

const TemplateTableItem: FC<TemplateTableItemProps> = ({ template }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  return (
    <>
      <TableRow key={template.id} hover>
        <TableCell>
          <Link
            href={`/layout/${template.id}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/layout/${template.id}`);
            }}
          >
            {template.name}
          </Link>
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MenuIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setIsEdit(true)}>Editar</MenuItem>
        <MenuItem onClick={() => setIsDelete(true)}>Excluir</MenuItem>
      </DropdownMenu>

      {isEdit && (
        <DialogEditTemplate template={template} onClose={() => setIsEdit(false)} open={isEdit} />
      )}

      {isDelete && (
        <DialogDeleteTemplate id={template.id} onClose={() => setIsDelete(false)} open={isDelete} />
      )}
    </>
  );
};

export default TemplateTableItem;
