import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { deleteOrder } from "services/Requests/openCompany";

const useMutationDeleteOrder = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteOrder, {
    onSuccess() {
      toast.success("Pedido deletado com sucesso!");
      queryClient.invalidateQueries(queryKeys.ordersByAssignments, { exact: false });
    },
  });
  return mutation;
};

export default useMutationDeleteOrder;
