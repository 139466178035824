import { FC } from "react";
import { useAuth } from "context/Auth";

import { Box, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";

const InfoToPrint: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <Stack
      sx={{
        display: "none",
        "@media print": {
          display: "block",
        },
      }}
    >
      <Box>
        <Typography>Empresa: {company?.name || "-"}</Typography>
        <Typography>CNPJ: {company?.cnpj || "-"}</Typography>
      </Box>

      <Box mt={1} mb={3}>
        <Typography>Emissão: {DateTime.local().toFormat("dd/MM/yyyy")}</Typography>
        <Typography>Horário: {DateTime.local().toFormat("HH:mm:ss")}</Typography>
      </Box>
    </Stack>
  );
};

export default InfoToPrint;
