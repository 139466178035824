import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getDunning } from "services/Requests/billing";
import getSubdomain from "utils/getSubdomain";

const useQueryGetDunning = (companyToken: string) => {
  const subdomain = getSubdomain();

  const query = useQuery(queryKeys.dunning, () =>
    getDunning({
      companyToken,
      subdomain,
    }),
  );
  return query;
};

export default useQueryGetDunning;
