import { useQuery } from "react-query";
import { getServicesAndPlans } from "services/Requests/marketplace";
import queryKeys from "react-query/constants";
import getSubdomain from "utils/getSubdomain";
import { useCallback } from "react";
import { ServiceAndPlan } from "types/ServiceAndPlan";

const useQueryServiceAndPlans = (filterByUser?: "Client" | "Accountant") => {
  const subdomain = getSubdomain();

  const selectorFilterUser = useCallback(
    (data: ServiceAndPlan) => {
      return {
        ...data,
        customServices: data.customServices.filter(
          (service) =>
            (filterByUser === "Client" && service.userType !== "Accountant") ||
            (filterByUser === "Accountant" && service.userType === "Accountant"),
        ),
      };
    },
    [filterByUser],
  );

  const query = useQuery(queryKeys.serviceAndPlans, () => getServicesAndPlans({ subdomain }), {
    select: filterByUser ? selectorFilterUser : undefined,
  });
  return query;
};

export default useQueryServiceAndPlans;
