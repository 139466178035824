import { FC } from "react";
import { useSideMenu } from "context/SideMenuContext/useSideMenu";
import { useCompanyInfo } from "context/CompanyInfo";

import MuiDrawer from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { DRAWER_WIDTH } from "../../utils/constants";
import { Toolbar, listItemButtonClasses, menuItemClasses } from "@mui/material";

import SideMenuList from "./SideMenuList";

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }),
);

const SideMenu: FC = () => {
  const { isOpen } = useSideMenu();
  const { companyStyles } = useCompanyInfo();

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      PaperProps={{
        sx: {
          background: `${companyStyles.sidebarBg} !important`,
          border: 0,
        },
      }}
      sx={{
        "*": {
          color: `${companyStyles.sidebarLinkColor} !important`,
        },
        [`.${menuItemClasses.root}:hover`]: {
          background: `${companyStyles.sidebarLinkHoverBg} !important`,
        },
        [`.${listItemButtonClasses.root}:hover`]: {
          background: `${companyStyles.sidebarLinkHoverBg} !important`,
        },
      }}
    >
      <Toolbar />
      <SideMenuList />
    </Drawer>
  );
};

export default SideMenu;
