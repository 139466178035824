import { FC } from "react";

import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import DOMPurify from "isomorphic-dompurify";

interface DialogMessageAfterAddProps {
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
}

const DialogMessageAfterAdd: FC<DialogMessageAfterAddProps> = ({
  onClose,
  open,
  title,
  message,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={onClose}>
        <Stack direction="row" gap={0.5} alignItems="center">
          <CheckCircleIcon fontSize="small" color="success" />
          <Typography variant="inherit" color="success.main" component="span">
            {title}
          </Typography>
        </Stack>
      </DialogTitleWithCloseButton>

      <DialogContent
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(message),
        }}
      />

      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogMessageAfterAdd;
