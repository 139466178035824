import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import SearchInput from "components/SearchInput";
import { FC, useState } from "react";
import useQueryUsersCompanies from "./hooks/useQueryUsersCompanies";
import UsersTable from "./UsersTable";

interface UsersControlProps {
  companyToken: string;
}

const UsersControl: FC<UsersControlProps> = ({ companyToken }) => {
  const [search, setSearch] = useState("");
  const {
    isLoading,
    data: usersCompanies,
    isFetching,
    isError,
  } = useQueryUsersCompanies(companyToken, search, true);

  return (
    <>
      <Collapse in={!isLoading && isFetching}>
        <LinearProgress />
      </Collapse>

      <Box>
        <Box p={2}>
          <SearchInput
            disabled={isLoading}
            value={search}
            onChange={(newSearch) => setSearch(newSearch)}
            label="Buscar usuários"
            fullWidth
          />
        </Box>

        <Divider />

        <Box p={2}>
          {isLoading && (
            <Stack alignItems="center" justifyContent="center" height={300}>
              <CircularProgress />
            </Stack>
          )}

          {isError && (
            <Typography>
              Ocorreu um error ao tentar buscar os usuários, tente novamente mais tarde
            </Typography>
          )}

          {!isLoading && usersCompanies && !isError && (
            <UsersTable companyToken={companyToken} usersCompanies={usersCompanies} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default UsersControl;
