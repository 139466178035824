import styled from "styled-components";

export const EditorWrapper = styled.div`
  #text-editor {
    border: 1px solid #e0e0e0;
    padding: 16px;

    p {
      line-height: 1.4;
    }

    a {
      text-decoration: underline;
    }

    & > [data-slate-node="element"] {
      margin: 16px 0;
    }

    & > [data-slate-node="element"]:first-child {
      margin-top: 0;
    }
    & > [data-slate-node="element"]:last-child {
      margin-bottom: 0;
    }

    ul,
    ol {
      padding-left: 32px;
      list-style: disc;
      margin-bottom: 8px;
    }

    ol {
      list-style: decimal;
    }
  }
`;
