import { FC, useState } from "react";
import useDebounce from "hooks/useDebounce";

import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

interface SearchInputProps extends Omit<TextFieldProps, "onChange" | "value"> {
  onChange: (search: string) => void;
  value?: string;
}

const SearchInput: FC<SearchInputProps> = ({ ...props }) => {
  const [search, setSearch] = useState<string>(props.value || "");
  useDebounce(search, 150, (value) => {
    props.onChange(value);
  });

  return (
    <TextField
      {...props}
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      label={props.label || "Buscar"}
      type="search"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {!!search && (
              <IconButton
                size="small"
                sx={{ mr: 0.5 }}
                onClick={() => {
                  props.onChange("");
                  setSearch("");
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
