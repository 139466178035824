import { useQuery } from "react-query";

import queryKeys from "react-query/constants";

import { getTransactionsWithPagination, TransactionAction } from "services/Requests/companies";
import { DateTypeFilter, FinancialCategory } from "types/Company";
import { ISODate } from "types/SemanticTypes";

const useQueryGetTransactionsWithPagination = (
  companyToken: string,
  startDate: ISODate,
  endDate: ISODate,
  action: TransactionAction | null = null,
  currentPage: number,
  pageSize: number,
  term = "",
  categoryFilter: FinancialCategory | null = null,
  bankAccountId: null | number = null,
  dateType: null | DateTypeFilter = null,
) => {
  const queryKey: (number | string)[] = [queryKeys.transactions, startDate, endDate];

  if (action) queryKey.push(action);
  if (categoryFilter) queryKey.push(categoryFilter.id);
  if (bankAccountId) queryKey.push(bankAccountId);
  if (dateType) queryKey.push(dateType);
  if (term.trim()) queryKey.push(term);
  queryKey.push(...[pageSize, currentPage]);

  const query = useQuery(
    queryKey,
    () =>
      getTransactionsWithPagination({
        companyToken,
        action,
        endDate,
        startDate,
        currentPage,
        pageSize,
        term,
        globalCategoryId: categoryFilter?.id,
        bankAccountId,
        dateType,
      }),
    {
      keepPreviousData: true,
    },
  );
  return query;
};

export default useQueryGetTransactionsWithPagination;
