import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getGroupsForm } from "services/Requests/marketplace";

const useQueryGroupsForm = () => {
  const query = useQuery(queryKeys.groupsForm, getGroupsForm);
  return query;
};

export default useQueryGroupsForm;
