import { useCallback } from "react";
import { useQuery, UseQueryResult } from "react-query";
import queryKeys from "react-query/constants";
import { getGlobalCategoriesByGroup } from "services/Requests/companies";
import {
  FinancialCategoryByGroupWithChildren,
  GetGlobalCategoriesByGroupResponse,
} from "types/Company";
import normalizeFinancialCategories from "utils/normalizeFinancialCategories";

type QueryType<T> = UseQueryResult<
  T extends true ? FinancialCategoryByGroupWithChildren[] : GetGlobalCategoriesByGroupResponse,
  unknown
>;

function useQueryGetGlobalCategoriesByGroup<T extends boolean>(
  groupId: number,
  normalize: T,
): QueryType<T> {
  const select = useCallback(normalizeFinancialCategories, []);

  const query = useQuery(
    [queryKeys.globalCategoriesByGroup, groupId],
    () => getGlobalCategoriesByGroup({ groupId }),
    {
      select: normalize ? select : undefined,
    },
  );
  return query as QueryType<T>;
}

export default useQueryGetGlobalCategoriesByGroup;
