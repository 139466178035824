import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getListFilesIntegration } from "services/Requests/files";
import { ISODate } from "types/SemanticTypes";
import addIfValueIsTruthy from "utils/addIfValueIsTruthy";

const useQueryListFilesIntegration = (
  companyToken: string,
  startDate: ISODate,
  endDate: ISODate,
  allStatus: boolean,
  dateType?: "C",
) => {
  const queryKey = addIfValueIsTruthy(
    [queryKeys.listFilesIntegration, companyToken, startDate, endDate],
    allStatus ? "allStatus" : undefined,
    dateType,
  );

  const query = useQuery(
    queryKey,
    () =>
      getListFilesIntegration({
        allStatus: allStatus,
        startDate,
        endDate,
        companyToken,
        dateType,
      }),
    {
      keepPreviousData: true,
    },
  );
  return query;
};

export default useQueryListFilesIntegration;
