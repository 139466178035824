import { FC } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import useMutationDeleteProduct from "../../hooks/useMutationDeleteProduct";

interface DialogDeleteProductProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  productId: number;
}

const DialogDeleteProduct: FC<DialogDeleteProductProps> = ({
  onClose,
  companyToken,
  productId,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationDeleteProduct();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync({
      companyToken,
      id: productId,
    });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>Excluir produto</DialogTitleWithCloseButton>

      <DialogContent>Deseja excluir este produto?</DialogContent>

      <DialogActions>
        <LoadingButton variant="contained" loading={isLoading} type="submit" onClick={handleDelete}>
          Sim
        </LoadingButton>
        <Button disabled={isLoading} onClick={handleClose} variant="outlined">
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteProduct;
