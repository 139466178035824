import { FC } from "react";
import { useQueryClient } from "react-query";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";

interface ProtectRouterProps {
  children: React.ReactNode;
}

const ProtectRouter: FC<ProtectRouterProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    queryClient.removeQueries();
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default ProtectRouter;
