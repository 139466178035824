import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getPresenceIndicator } from "services/Requests/stockControl";
import { TypeInvoice } from "types/StockControl";

const useQueryPresenceIndicator = (type: TypeInvoice) => {
  const query = useQuery([queryKeys.presenceIndicator, type], () => getPresenceIndicator(type));
  return query;
};

export default useQueryPresenceIndicator;
