import { FC } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";

import useMutationDeleteContract from "./hooks/useMutationDeleteContract";

interface DeleteContractProps extends DialogProps {
  companyToken: string;
  documentToken: string;
}

const DeleteContract: FC<DeleteContractProps> = ({
  companyToken,
  documentToken,
  onClose,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationDeleteContract();

  const handleClose = () => {
    if (isLoading) return;
    onClose?.({}, "escapeKeyDown");
  };

  const handleSubmit = async () => {
    try {
      await mutateAsync({
        documentToken,
        companyToken,
      });
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitle>Excluir contrato</DialogTitle>

      <DialogContent>Deseja excluir este contrato?</DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Não
        </Button>
        <LoadingButton variant="contained" loading={isLoading} onClick={handleSubmit}>
          Sim
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteContract;
