import { FC } from "react";

import validationSchema from "./validationSchema";
import ControllerInput from "components/ControllerInput";

import { Box, Divider, MenuItem, Select, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import stringToSlug from "utils/stringToSlug";
import { AVALIABLE_BANKS, SlugBanks } from "utils/constants";
import { BillingInfo, BillingInfoAdmin } from "types/Billing";

const translatePersonType = {
  [stringToSlug("Pessoa Jurídica")]: "Pessoa Jurídica",
  [stringToSlug("Pessoa Física")]: "Pessoa Física",
};

export const banksAttributes: {
  [key in SlugBanks]: {
    digitAgency: boolean;
    digitAccount: boolean;
    operations: boolean;
  };
} = {
  "acesso-solucoes-de-pagamento": {
    digitAgency: false,
    digitAccount: false,
    operations: false,
  },
  "banco-do-brasil": {
    digitAgency: true,
    digitAccount: true,
    operations: false,
  },
  bradesco: {
    digitAgency: true,
    digitAccount: true,
    operations: false,
  },
  "caixa-economica": {
    digitAgency: false,
    digitAccount: true,
    operations: true,
  },
  itau: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  santander: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  agibank: {
    digitAgency: false,
    digitAccount: false,
    operations: false,
  },
  "banco-banese": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "banco-btg-pactual": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "banco-c6": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "banco-da-amazonia": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "banco-daycoval": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "banco-do-nordeste": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "banco-omni": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "banco-original": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "banco-topazio": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  banestes: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  banpara: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  banrisul: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "bnp-paribas-brasil": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  brb: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "brl-trust-dtvm": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  bs2: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  citibank: {
    digitAgency: false,
    digitAccount: false,
    operations: false,
  },
  "cooperativa-central-de-credito-noroeste-brasileiro": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  cora: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  cresol: {
    digitAgency: true,
    digitAccount: true,
    operations: false,
  },
  "gerencianet-pagamentos-do-brasil": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "global-scm": {
    digitAgency: false,
    digitAccount: false,
    operations: false,
  },
  inter: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "jp-morgan": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  juno: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "mercado-pago": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "mercantil-do-brasil": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  modal: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "money-plus": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  neon: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  next: {
    digitAgency: true,
    digitAccount: true,
    operations: false,
  },
  nubank: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  pagseguro: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  pjbank: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  rendimento: {
    digitAgency: true,
    digitAccount: false,
    operations: false,
  },
  safra: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  sicoob: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  sicredi: {
    digitAgency: false,
    digitAccount: false,
    operations: false,
  },
  stone: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  unicred: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  uniprime: {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "uniprime-norte-do-parana": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
  "via-credi": {
    digitAgency: false,
    digitAccount: true,
    operations: false,
  },
};

export interface FieldSubmit {
  bank: any;
  agency: any;
  digitAgency: any;
  account: any;
  digitAccount: any;
  personType: any;
  operation: string;
}

interface BankDataProps {
  billingInfo: BillingInfoAdmin | BillingInfo;
  onSubmit: (data: FieldSubmit) => void;
  isLoading: boolean;
  hasPersonType?: boolean;
}

const BankData: FC<BankDataProps> = ({
  billingInfo,
  onSubmit,
  isLoading,
  hasPersonType = true,
}) => {
  const bankClient = billingInfo.informations?.bank;
  const personType = billingInfo.informations?.person_type;

  const { control, watch, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    defaultValues: {
      bank: !bankClient ? "" : AVALIABLE_BANKS.find((bank) => bank.name === bankClient)?.slug || "",
      agency: billingInfo.informations?.bank_ag?.split("-")[0] || "",
      digitAgency: billingInfo.informations?.bank_ag?.split("-")[1] || "",
      account: billingInfo.informations?.bank_cc?.split("-")[0] || "",
      digitAccount: billingInfo.informations?.bank_cc?.split("-")[1] || "",
      personType: personType ? stringToSlug(personType) : "",
      operation: "",
    },
  });

  const bankSelected = watch("bank") as SlugBanks;

  const onSubmitFn = handleSubmit((data) => {
    const bank = AVALIABLE_BANKS.find((b) => b.slug === data.bank)?.name;
    const personType = translatePersonType[data.personType];

    if (!bank || !personType) return;

    onSubmit({
      ...data,
      personType,
      bank,
    });
  });

  return (
    <Box flex={1} flexBasis="320px" component="form" onSubmit={onSubmitFn}>
      <Box p={0.5} mb={1} bgcolor={grey[200]}>
        <Typography variant="body1" align="center" fontWeight={600}>
          Dados Bancários
        </Typography>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        <Typography variant="body1" fontWeight={600}>
          Selecione o banco
        </Typography>

        <Controller
          name="bank"
          control={control}
          render={({ field, fieldState }) => (
            <Box>
              <Select
                {...field}
                size="small"
                fullWidth
                MenuProps={{ sx: { maxHeight: "50vh" } }}
                error={!!fieldState.error?.message}
              >
                <MenuItem value="" disabled>
                  Selecione:
                </MenuItem>
                {AVALIABLE_BANKS.map((bank) => (
                  <MenuItem value={bank.slug} key={bank.slug}>
                    {bank.name}
                  </MenuItem>
                ))}
              </Select>
              {!!fieldState.error?.message && (
                <Typography variant="body1">{fieldState.error?.message}</Typography>
              )}
            </Box>
          )}
        />

        <Divider sx={{ my: 1, gridColumn: "span 2" }} />

        <Typography variant="body1" fontWeight={600}>
          Agência
        </Typography>
        <ControllerInput
          name="agency"
          control={control}
          inputProps={{
            size: "small",
            inputProps: { maxLength: 6 },
          }}
        />

        <Divider sx={{ my: 1, gridColumn: "span 2" }} />

        <Typography variant="body1" fontWeight={600}>
          Dígito
        </Typography>
        <ControllerInput
          name="digitAgency"
          control={control}
          inputProps={{
            size: "small",
            disabled: !bankSelected || !banksAttributes[bankSelected].digitAgency,
            inputProps: { maxLength: 1 },
          }}
        />

        <Divider sx={{ my: 1, gridColumn: "span 2" }} />

        <Typography variant="body1" fontWeight={600}>
          Conta
        </Typography>
        <ControllerInput
          name="account"
          control={control}
          inputProps={{
            size: "small",
            inputProps: { maxLength: 10 },
          }}
        />

        <Divider sx={{ my: 1, gridColumn: "span 2" }} />

        <Typography variant="body1" fontWeight={600}>
          Dígito
        </Typography>
        <ControllerInput
          name="digitAccount"
          control={control}
          inputProps={{
            size: "small",
            disabled: !bankSelected || !banksAttributes[bankSelected].digitAccount,
            inputProps: { maxLength: 1 },
          }}
        />

        <Divider sx={{ my: 1, gridColumn: "span 2" }} />

        {bankSelected && banksAttributes[bankSelected].operations && (
          <>
            <Typography variant="body1" fontWeight={600}>
              Operação
            </Typography>
            <ControllerInput
              name="operations"
              control={control}
              inputProps={{
                size: "small",
                inputProps: { maxLength: 3 },
              }}
            />
          </>
        )}

        {hasPersonType && (
          <>
            <Box p={0.5} bgcolor={grey[200]} gridColumn="span 2">
              <Typography variant="body1" align="center" fontWeight={600}>
                Dados para verificação
              </Typography>
            </Box>

            <Controller
              name="personType"
              control={control}
              render={({ field }) => (
                <Select {...field} size="small" fullWidth>
                  <MenuItem value="" disabled>
                    Selecione:
                  </MenuItem>
                  <MenuItem value={stringToSlug("Pessoa Jurídica")}>Pessoa Jurídica</MenuItem>
                  <MenuItem value={stringToSlug("Pessoa Física")}>Pessoa Física</MenuItem>
                </Select>
              )}
            />

            <Divider sx={{ my: 1, gridColumn: "span 2" }} />
          </>
        )}

        <LoadingButton loading={isLoading} variant="contained" type="submit">
          Atualizar conta
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default BankData;
