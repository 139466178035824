import { FC, useState } from "react";
import useMutationReopenMonth from "../hooks/useMutationReopenMonth";

import {
  Box,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import Search from "@mui/icons-material/Search";

import DialogTransaction from "./DialogTransaction";
import DialogConfirmationCloseMonth from "./DialogConfirmationCloseMonth";

import { MonthClosure } from "types/Company";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { DateTime } from "luxon";
import { LoadingButton } from "@mui/lab";

interface MonthClosureDetailsProps {
  monthClosure: MonthClosure;
  companyToken: string;
}

const MonthClosureDetails: FC<MonthClosureDetailsProps> = ({ monthClosure, companyToken }) => {
  const { mutate, isLoading } = useMutationReopenMonth();

  const [consultId, setConsultId] = useState<null | number>(null);
  const [confirmationCloseMonth, setConfirmationCloseMonth] = useState(false);

  const onClick = () => {
    if (!monthClosure.isClosed) {
      setConfirmationCloseMonth(true);
    } else {
      mutate({
        companyToken,
        date: monthClosure.currentMonth,
      });
    }
  };

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ borderBottom: 1, borderColor: "divider" }}>
        Receitas
      </Typography>

      {monthClosure.invoices.length > 0 ? (
        <>
          <Table size="small" sx={{ mt: 1 }}>
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {monthClosure.invoices.map((invoice) => (
                <TableRow key={invoice.id}>
                  <TableCell>{invoice.customerName}</TableCell>
                  <TableCell>{formatCurrencyBRL(invoice.value)}</TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => setConsultId(invoice.id)}
                      size="small"
                      variant="contained"
                      startIcon={<Search fontSize="small" />}
                    >
                      Consultar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box p={2} mx={4} my={1} bgcolor={grey[200]} border={1} borderColor="divider">
            <Typography variant="body1">
              Receita total até o momento{" "}
              <Typography component="strong" variant="body2" color="success.main" fontWeight={600}>
                {formatCurrencyBRL(monthClosure.invoicesTotal)}
              </Typography>
            </Typography>
          </Box>
        </>
      ) : (
        <Box p={2} m={1} bgcolor={grey[200]} border={1} borderColor="divider">
          <Typography variant="body1">Nenhuma receita no período</Typography>
        </Box>
      )}

      <Typography variant="subtitle1" sx={{ borderBottom: 1, borderColor: "divider", mt: 3 }}>
        Despesas
      </Typography>

      {monthClosure.debits.length > 0 ? (
        <>
          <Table size="small" sx={{ mt: 1 }}>
            <TableHead>
              <TableRow>
                <TableCell>Categoria</TableCell>
                <TableCell>Valor</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {monthClosure.debits.map((debit) => (
                <TableRow key={debit.category}>
                  <TableCell>{debit.category}</TableCell>
                  <TableCell>{formatCurrencyBRL(debit.total)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box p={2} mx={4} my={1} bgcolor={grey[200]} border={1} borderColor="divider">
            <Typography variant="body2">
              Despesas e pagamentos total até o momento{" "}
              <Typography component="strong" variant="body2" color="error.main" fontWeight={600}>
                {formatCurrencyBRL(monthClosure.debitsTotal)}
              </Typography>
            </Typography>
          </Box>
        </>
      ) : (
        <Box p={2} m={1} bgcolor={grey[200]} border={1} borderColor="divider">
          <Typography variant="body1">
            Nenhuma despesa ou pagamento cadastrada no período
          </Typography>
        </Box>
      )}

      <LoadingButton
        onClick={onClick}
        sx={{ mt: 4 }}
        variant="contained"
        loading={isLoading}
        startIcon={!monthClosure.isClosed ? <CheckIcon /> : undefined}
      >
        {monthClosure.isClosed
          ? "Reabrir Mês"
          : `Aprovar fechamento de ${DateTime.fromISO(monthClosure.currentMonth).toFormat("LLLL")}`}
      </LoadingButton>

      {consultId && (
        <DialogTransaction
          open={!!consultId}
          onClose={() => setConsultId(null)}
          companyToken={companyToken}
          transactionId={consultId}
        />
      )}

      <DialogConfirmationCloseMonth
        open={confirmationCloseMonth}
        onClose={() => setConfirmationCloseMonth(false)}
        companyToken={companyToken}
        monthToClose={monthClosure.currentMonth}
      />
    </Box>
  );
};

export default MonthClosureDetails;
