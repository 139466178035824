import { FC, useState } from "react";
import useQueryTemplatesAccountanting from "./hooks/useQueryTemplatesAccountanting";

import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";

import NewOrEditTemplate from "./components/NewOrEditTemplate";
import TemplateAccountingTable from "./TemplateAccountingTable";
import Head from "components/Head";
import getSubdomain from "utils/getSubdomain";

const Accounting: FC = () => {
  const { data: templatesAccounting, isLoading, isFetching } = useQueryTemplatesAccountanting();
  const [openNewTemplate, setOpenNewTemplate] = useState(false);

  return (
    <>
      <Head title={`Plano de contas | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h4" fontWeight="bold">
            Lista de Templates
          </Typography>
          <Typography variant="body2">Planos de Contas Financeiro</Typography>
        </Stack>

        <Button variant="contained" startIcon={<Add />} onClick={() => setOpenNewTemplate(true)}>
          Novo template
        </Button>
      </Stack>

      <Box mt={2} boxShadow={2} p={2} bgcolor="#fff" position="relative">
        {isLoading && (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {isFetching && !isLoading && (
          <Stack
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={20}
            left={0}
            width="100%"
          >
            <CircularProgress />
          </Stack>
        )}

        {templatesAccounting && (
          <TemplateAccountingTable templatesAccounting={templatesAccounting} />
        )}
      </Box>

      <NewOrEditTemplate open={openNewTemplate} onClose={() => setOpenNewTemplate(false)} />
    </>
  );
};

export default Accounting;
