import { FC } from "react";
import useQueryRevenuesVsExpenses from "../hooks/useQueryRevenuesVsExpenses";

import { Box, CircularProgress, Collapse, LinearProgress, Stack, Typography } from "@mui/material";

import IncomeAndExpensesChart from "./IncomeAndExpensesChart";
import { useDashboardPeriod } from "../contexts/DashboardPeriod";

type TypeDataChart = "projected" | "performed";

const translateType: Record<TypeDataChart, string> = {
  performed: "Receitas x Despesas Realizadas",
  projected: "Receitas x Despesas Projetadas",
};

interface IncomeAndExpensesProps {
  companyToken: string;
  type?: TypeDataChart;
}

const IncomeAndExpenses: FC<IncomeAndExpensesProps> = ({ companyToken, type = "projected" }) => {
  const {
    date: { end, start },
  } = useDashboardPeriod();

  const {
    data: revenuesVsExpenses,
    isLoading,
    isFetching,
    isError,
  } = useQueryRevenuesVsExpenses({
    companyToken,
    startDate: start,
    endDate: end,
    provision: type === "projected",
  });

  return (
    <Stack
      p={2}
      borderRadius={1}
      border={1}
      borderColor="divider"
      height={330}
      gridColumn={"span 2"}
    >
      <Collapse in={isFetching && !isLoading}>
        <LinearProgress />
      </Collapse>

      <Typography sx={{ flex: "0 0 auto" }} variant="h6" fontWeight={400} color="text.primary">
        {translateType[type]}
      </Typography>

      <Box display="flex" flex={2} maxHeight={500} overflow="hidden" mt={1} width={"100%"}>
        {isLoading && (
          <Stack flex={1} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {isError && <Typography>Não foi possível carregar os dados</Typography>}

        {revenuesVsExpenses && <IncomeAndExpensesChart revenuesVsExpenses={revenuesVsExpenses} />}
      </Box>
    </Stack>
  );
};

export default IncomeAndExpenses;
