import { useQuery } from "react-query";
import { getInfosIUGU } from "services/Requests/billing";
import queryKeys from "react-query/constants";
import toast from "react-hot-toast";

export const useQueryClientBalance = (subdomain: string, enabled: boolean) => {
  const query = useQuery([queryKeys.infoBilling, subdomain], () => getInfosIUGU({ subdomain }), {
    onError() {
      toast.error("Ocorreu um erro ao tentar carregar o saldo do cliente!");
    },
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
  });

  return query;
};
