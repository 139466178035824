import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getInvoiceConfig } from "services/Requests/stockControl";

const useQueryInvoiceConfigStockControl = (companyToken: string) => {
  const query = useQuery(queryKeys.invoiceConfig, () => getInvoiceConfig({ companyToken }));
  return query;
};

export default useQueryInvoiceConfigStockControl;
