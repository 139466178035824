import * as yup from "yup";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("A senha é obrigatória")
    .min(8, "A senha deve ter no mínimo 6 caracteres")
    .max(20, "A senha deve ter no máximo 20 caracteres")
    .test("ifNotHaveNumber", "A senha deve conter um número", (value) => {
      if (!value) return true;
      return value.split("").some((v) => !isNaN(Number(v)));
    })
    .test("ifNotHaveLetter", "A senha deve conter uma letra", (value) => {
      if (!value) return true;
      return value.split("").some((v) => isNaN(Number(v)));
    }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas não conferem")
    .required("A confirmação de senha é obrigatória"),
});

export default validationSchema;
