import { FC, memo } from "react";
import { useDrive } from "../context/Drive";

import { Button, Stack, TableCell, TableRow, Tooltip, useMediaQuery } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DeleteIcon from "@mui/icons-material/Delete";

import { CompanyDocumentDrive } from "types/Company";
import { fileIcons, visibleTypes } from "../utils/fileType";
import { getUrlDownloadDrive, getUrlPreviewDocumentDrive } from "../utils/getUrl";

interface DocumentDriveTableItemProps {
  documentDrive: CompanyDocumentDrive;
}

const DocumentDriveTableItem: FC<DocumentDriveTableItemProps> = ({ documentDrive }) => {
  const {
    handleChangeDocumentToDelete,
    handleChangeDocumentToEdit,
    handleChangeDocumentToMove,
    addNewPath,
  } = useDrive();

  const FileIcon = fileIcons[documentDrive.typeNormalize] || fileIcons.default;
  const Lock = documentDrive.rights === "A" ? LockOpenIcon : LockIcon;
  const isDesktop = useMediaQuery("(min-width:998px)");

  return (
    <TableRow hover>
      <TableCell
        sx={{ cursor: documentDrive.type === "dir" ? "pointer" : undefined }}
        onClick={() => {
          if (documentDrive.type === "dir") {
            addNewPath(documentDrive);
          }
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <FileIcon
            fontSize="small"
            color={documentDrive.userType !== "Accountant" ? "warning" : undefined}
          />
          {documentDrive.name}
        </Stack>
      </TableCell>
      <TableCell>{documentDrive.typeNormalize}</TableCell>
      {isDesktop && (
        <>
          <TableCell>{documentDrive.date.split(" ")[0]}</TableCell>
          <TableCell>{documentDrive.referenceDate.split(" ")[0]}</TableCell>
          <TableCell>{documentDrive.dueDate.split(" ")[0]}</TableCell>
          <TableCell>
            {documentDrive.value && documentDrive.type !== "dir" ? documentDrive.value : "-"}
          </TableCell>
          <TableCell align="center">
            <Lock
              fontSize="small"
              color={documentDrive.userType !== "Accountant" ? "warning" : "success"}
            />
          </TableCell>
        </>
      )}
      <TableCell sx={{ maxWidth: "50%", minWidth: "30%" }}>
        <Stack alignItems="center" flexWrap="wrap" justifyContent="end" direction="row" gap={0.5}>
          {documentDrive.type !== "dir" && (
            <Tooltip title="Download">
              <Button
                href={getUrlDownloadDrive(documentDrive.downloadToken)}
                target="_blank"
                variant="contained"
                sx={{ minWidth: "fit-content" }}
                size="small"
              >
                <FileDownloadIcon fontSize="small" />
              </Button>
            </Tooltip>
          )}
          {visibleTypes.includes(documentDrive.typeNormalize) && (
            <Tooltip title="Visualizar">
              <Button
                href={getUrlPreviewDocumentDrive(documentDrive.downloadToken)}
                target="_blank"
                variant="contained"
                sx={{ minWidth: "fit-content" }}
                size="small"
              >
                <PreviewIcon fontSize="small" />
              </Button>
            </Tooltip>
          )}
          {documentDrive.type !== "dir" && documentDrive.rights === "A" && (
            <Tooltip title="Editar detalhes">
              <Button
                variant="contained"
                sx={{ minWidth: "fit-content" }}
                size="small"
                onClick={() => handleChangeDocumentToEdit(documentDrive)}
              >
                <EditIcon fontSize="small" />
              </Button>
            </Tooltip>
          )}
          {documentDrive.type !== "dir" && documentDrive.rights === "A" && (
            <Tooltip title="Mover">
              <Button
                onClick={() => handleChangeDocumentToMove(documentDrive)}
                variant="contained"
                sx={{ minWidth: "fit-content" }}
                size="small"
              >
                <DriveFileMoveIcon fontSize="small" />
              </Button>
            </Tooltip>
          )}
          {documentDrive.rights === "A" && (
            <Tooltip title="Deletar">
              <Button
                variant="contained"
                sx={{ minWidth: "fit-content" }}
                size="small"
                onClick={() => handleChangeDocumentToDelete(documentDrive)}
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </Tooltip>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default memo(DocumentDriveTableItem);
