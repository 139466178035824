import { FC } from "react";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import RecipientAutocomplete from "pages/Home/components/RecipientAutocomplete";
import { DatePicker } from "@mui/x-date-pickers";

import { Control, Controller, useWatch } from "react-hook-form";
import { MovementForm } from "../utils/InterfacesForm";

interface BasicOperationsProps {
  control: Control<MovementForm>;
  companyToken: string;
  isConsultation: boolean;
}

const BasicOperations: FC<BasicOperationsProps> = ({ control, companyToken, isConsultation }) => {
  const operationType = useWatch({
    control,
    name: "operationType",
  });

  return (
    <Box display="grid" gap={2} gridTemplateColumns="repeat(4, 1fr)" alignItems="center">
      <Controller
        name="operationType"
        control={control}
        render={({ field }) => (
          <FormControl disabled={isConsultation}>
            <FormLabel>Tipo de operação</FormLabel>
            <RadioGroup {...field} row>
              <FormControlLabel value="Entrada" control={<Radio />} label="Entrada" />
              <FormControlLabel value="Saída" control={<Radio />} label="Saída" />
            </RadioGroup>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name={"participantId"}
        render={({ fieldState, field }) => (
          <RecipientAutocomplete
            disabled={isConsultation}
            companyToken={companyToken}
            value={field.value}
            ref={field.ref}
            onBlur={field.onBlur}
            onChange={(_, value) => field.onChange(value?.id)}
            role={operationType === "Saída" ? 1 : 2}
            withCreateParticipant
            sx={{ gridColumn: "2/-1" }}
            textFieldProps={{
              label: operationType === "Saída" ? "Cliente" : "Fornecedor",
              error: !!fieldState.error?.message,
              helperText: fieldState.error?.message,
            }}
          />
        )}
      />

      <Controller
        name="sendInvoice"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl disabled={isConsultation} error={!!fieldState.error?.message}>
            <InputLabel>Emitir nota agora?</InputLabel>
            <Select {...field} label="Emitir nota agora?">
              <MenuItem value={"0"}>Não</MenuItem>
              <MenuItem value={"1"}>Emitir NF-e</MenuItem>
              {operationType === "Saída" && <MenuItem value={"2"}>Emitir NFC-e</MenuItem>}
            </Select>
            {!!fieldState.error?.message && (
              <FormHelperText error>{fieldState.error?.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Controller
        name="nature"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            disabled={isConsultation}
            value={field.value || ""}
            label="Natureza da operação"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        name={"movementDate"}
        control={control}
        render={({ field, fieldState }) => (
          <DatePicker
            label="Data do movimento"
            inputFormat="dd/MM/yyyy"
            {...field}
            allowSameDateSelection
            disabled={isConsultation}
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={isConsultation}
                helperText={fieldState.error?.message}
                error={params.error || !!fieldState.error?.message}
              />
            )}
          />
        )}
      />

      <Controller
        name={"dueDate"}
        control={control}
        render={({ field, fieldState }) => (
          <DatePicker
            label="Data do vencimento"
            inputFormat="dd/MM/yyyy"
            {...field}
            allowSameDateSelection
            disabled={isConsultation}
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={isConsultation}
                helperText={fieldState.error?.message}
                error={params.error || !!fieldState.error?.message}
              />
            )}
          />
        )}
      />
    </Box>
  );
};

export default BasicOperations;
