import { FC, useState } from "react";
import useQueryTemplateFinancial from "./hooks/useQueryTemplateFinancial";

import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";

import NewOrEditTemplate from "./components/NewOrEditTemplate";
import TemplateFinancialTable from "./TemplateFinancialTable";
import Head from "components/Head";
import getSubdomain from "utils/getSubdomain";

const Financial: FC = () => {
  const { data: templatesFinancial, isLoading, isFetching } = useQueryTemplateFinancial();
  const [openNewTemplate, setOpenNewTemplate] = useState(false);

  return (
    <>
      <Head title={`Plano de contas | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h4" fontWeight="bold">
            Lista de Templates
          </Typography>
          <Typography variant="body2">Planos de Contas Financeiro</Typography>
        </Stack>

        <Button
          size="small"
          variant="contained"
          startIcon={<Add fontSize="small" />}
          onClick={() => setOpenNewTemplate(true)}
        >
          Novo template
        </Button>
      </Stack>

      <Box mt={2} boxShadow={2} p={2} bgcolor="#fff" position="relative">
        {isLoading && (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {isFetching && !isLoading && (
          <Stack
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={20}
            left={0}
            width="100%"
          >
            <CircularProgress />
          </Stack>
        )}

        {templatesFinancial && <TemplateFinancialTable templatesFinancial={templatesFinancial} />}
      </Box>

      <NewOrEditTemplate open={openNewTemplate} onClose={() => setOpenNewTemplate(false)} />
    </>
  );
};

export default Financial;
