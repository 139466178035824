import * as yup from "yup";

const validationSchema = yup.object().shape({
  newId: yup
    .number()
    .typeError("O nome da nova categoria analítica é obrigatório")
    .required("O nome da nova categoria analítica é obrigatório"),
});

export default validationSchema;
