import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import toast from "react-hot-toast";
import { deleteTemplateFinancial } from "services/Requests/companies";

const useMutationDeleteTemplateFinancial = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteTemplateFinancial, {
    onSuccess() {
      toast.success("Template deletado com sucesso");
      queryClient.invalidateQueries(queryKeys.templateFinancial);
    },
  });
  return mutation;
};

export default useMutationDeleteTemplateFinancial;
