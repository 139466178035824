import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";
import useMutationUpdateDunning from "../hooks/useMutationUpdateDunning";

import DunningFields from "./DunningFields";
import ControllerInput from "components/ControllerInput";

import { Box, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { BillingInfo, Dunning } from "types/Billing";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

export interface SettingsForm {
  charge: number;
  expire: number;
  invoiceName: string;
}

interface SettingsFormProps {
  dunning: Dunning[];
  infoAccount: BillingInfo;
  companyToken: string;
}

const SettingsForm: FC<SettingsFormProps> = ({ dunning, infoAccount, companyToken }) => {
  const { mutateAsync } = useMutationUpdateDunning();

  const defaultValues = useMemo(() => {
    const chargeDay = dunning.find((d) => d.action === "charge")?.day as number;
    const expireDay = dunning.find((d) => d.action === "expire")?.day as number;

    return {
      charge: chargeDay * 1,
      expire: expireDay - 3 * chargeDay,
      invoiceName: infoAccount.name,
    };
  }, [dunning, infoAccount]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SettingsForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      charge: data.charge,
      expire: data.expire,
      invoiceName: data.invoiceName,
      companyToken,
    });
  });

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 1 }}
      >
        Fluxo de Cobrança e Faturamento
      </Typography>
      <Typography variant="body2">
        A cobrança na plataforma funciona de acordo com o seguinte fluxo de ações, iniciando-se com
        o vencimento da fatura:
      </Typography>

      <Box mt={3}>
        <DunningFields control={control} />
      </Box>

      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 2, mt: 4 }}
      >
        Nome apresentado na fatura
      </Typography>
      <ControllerInput name="invoiceName" control={control} />

      <Stack alignItems="center" justifyContent="center" mt={2}>
        <LoadingButton loading={isSubmitting} variant="contained" type="submit">
          Salvar
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default SettingsForm;
