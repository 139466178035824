import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { Service, ServiceAndPlan, ServicePlan } from "types/ServiceAndPlan";
import CardService from "./CardService";

interface AccountantStandardizedPlansProps {
  serviceAndPlan: ServiceAndPlan;
  isIuguActivate: boolean;
  onEditService: (service: Service | ServicePlan) => void;
}

const AccountantStandardizedPlans: FC<AccountantStandardizedPlansProps> = ({
  isIuguActivate,
  onEditService,
  serviceAndPlan,
}) => {
  const services = useMemo(() => {
    const plans = serviceAndPlan.plans;
    const services = serviceAndPlan.defaultServices.filter(
      (service) => service.serviceType !== "Others",
    );

    if (serviceAndPlan.premiumService) {
      return [...plans, ...services, serviceAndPlan.premiumService];
    }

    return [...plans, ...services];
  }, [serviceAndPlan]);

  return (
    <Box mt={2} gap={2} display="grid" gridTemplateColumns="repeat( auto-fit, minmax(250px, 1fr) )">
      {services.map((service, index) => (
        <CardService
          key={index}
          service={service}
          isIuguActivate={isIuguActivate}
          onClick={() => onEditService(service)}
        />
      ))}
    </Box>
  );
};

export default AccountantStandardizedPlans;
