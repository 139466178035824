import { FC } from "react";
import useQueryAccountantApps from "../../../hooks/useQueryAccountantApps";

import { CircularProgress, Stack, Typography } from "@mui/material";
import Control from "./Control";

interface AppsProps {
  companyId: number;
}

const Apps: FC<AppsProps> = ({ companyId }) => {
  const { data, isLoading, isError } = useQueryAccountantApps(companyId);

  return (
    <>
      {isLoading && (
        <Stack height={300} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {isError && (
        <Typography>Ocorreu um erro ao carregar as aplicações. Tente novamente</Typography>
      )}

      {!isLoading && !!data && !isError && <Control accountantApp={data} companyId={companyId} />}
    </>
  );
};

export default Apps;
