import { CustomElement, CustomText } from "../slate";

const emptyText: CustomText[] = [{ text: "" }];

export const createParagraphNode = (
  children: (CustomText | CustomElement)[] = emptyText,
): CustomElement => ({
  type: "paragraph",
  children,
});
