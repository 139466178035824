import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getModalities } from "services/Requests/stockControl";

const useQueryModalities = () => {
  const query = useQuery(queryKeys.modalities, getModalities);
  return query;
};

export default useQueryModalities;
