import { FC, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useAuth } from "../../../../context/Auth";
import Head from "../../../../components/Head";
import getSubdomain from "../../../../utils/getSubdomain";

import ImportClients from "./ImportClients";
import TabNavigation from "../../../../components/TabNavigation";
import Clients from "./components/Clients";
import Fees from "./components/Fees";
import Opportunities from "./components/Opportunities";
import Legalization from "./components/Legalization";
import Accounting from "./components/Accounting";

const accountantTabs = [
  {
    name: "Contabilidade",
    slug: "contabilidade",
    Component: Accounting,
  },
  {
    name: "Legalização",
    slug: "legalizacao",
    Component: Legalization,
  },
  {
    name: "Oportunidades",
    slug: "oportunidades",
    Component: Opportunities,
  },
];

const adminTabs = [
  {
    name: "Clientes",
    slug: "clientes",
    Component: Clients,
  },
  {
    name: "Taxas",
    slug: "taxas",
    Component: Fees,
  },
  {
    name: "Oportunidades",
    slug: "oportunidades",
    Component: Opportunities,
  },
];

const Marketplace: FC = () => {
  const {
    user: { userInfo },
  } = useAuth();
  const tabs = userInfo.userType !== "Admin" ? accountantTabs : adminTabs;

  const [tabActive, setTabActive] = useState("1");

  const handleChangeTab = (newValue: string) => {
    setTabActive(newValue);
  };

  return (
    <>
      <Head title={`Clientes | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Clientes
        </Typography>

        {userInfo.userType !== "Admin" && <ImportClients />}
      </Stack>

      <Box mt={2} boxShadow={2} bgcolor="#fff">
        <TabNavigation tabActive={tabActive} tabs={tabs} handleChangeTab={handleChangeTab} />
      </Box>
    </>
  );
};

export default Marketplace;
