import { FC, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  InputLabel,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";

import { HTMLStringToDescendant, toHTMLString } from "components/TextEditor/utils/functions";
import TabPanel from "components/TabPanel";
import TabContent from "./TabContent";
import { Descendant } from "slate";
import { AccountabilityPlan, IntervalType } from "types/ServiceAndPlan";
import GeneralPlanForm from "./GeneralPlanForm";
import LeafForm from "./LeafForm";
import TextEditor from "components/TextEditor";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import useMutationUpdateDefaultPlan from "../../../hooks/useMutationUpdateDefaultPlan";
import useQueryGetContracts from "pages/Home/hooks/useQueryGetContracts";
import { formattedInput } from "components/CurrencyInput";

const intervalTypeToRecorrenceID: { [x in IntervalType]: number } = {
  annual: 12,
  months: 1,
  quarterly: 3,
  semiannual: 6,
  weeks: 7,
};

export interface PlanForm {
  name: string;
  agreement: string;
  isActive: boolean;
  creditCard: boolean;
  bankSlip: boolean;
  periodicity: number;
  isManualBilling: boolean;
  parcel?: string;
  isSameDayExp?: boolean;
  periodParcel?: number;
  tierPrice: {
    active: boolean;
    name: string;
    tierId: number;
    calcIntervals: {
      from: string;
      calcId: number;
      price: string;
      until: string;
    }[];
  }[];
  payrollValue: string;
  prolaboreValue: string;
  usePayroll: boolean;
  useProlabore: boolean;
}

interface PlanFormProps {
  plan: AccountabilityPlan;
  onClose: () => void;
  companyToken: string;
}

const PlanForm: FC<PlanFormProps> = ({ companyToken, onClose, plan }) => {
  const { data: contracts, isLoading: isLoadingContracts } = useQueryGetContracts(companyToken);
  const { mutateAsync, isLoading } = useMutationUpdateDefaultPlan();

  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTab = (_: unknown, newValue: number) => {
    setActiveTab(newValue);
  };

  const [startText, setStartText] = useState<Descendant[]>(() => {
    return HTMLStringToDescendant(plan.startText || plan.description || "");
  });
  const [successPlan, setSuccessPlan] = useState<Descendant[]>(() => {
    return HTMLStringToDescendant(plan.endText || "");
  });

  const { handleSubmit, control } = useForm<PlanForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: plan.name,
      agreement: plan.filename || "",
      isActive: plan.isActive,
      bankSlip: plan.bankSlip,
      creditCard: plan.creditCard,
      periodicity: plan.intervalType ? intervalTypeToRecorrenceID[plan.intervalType] : 1,
      parcel: plan.interval ? plan.interval.toString() : "0",
      periodParcel:
        plan.intervalType === "weeks"
          ? plan.interval >= 1 && plan.interval <= 7
            ? plan.interval
            : 1
          : undefined,
      isSameDayExp: plan.isSameDayExp,
      isManualBilling: !plan.isAutomaticBilling,
      tierPrice: plan.tierPriceInfoes.map((tier) => ({
        active: tier.active,
        name: tier.name,
        tierId: tier.id,
        calcIntervals: tier.calcIntervals.map((calc) => ({
          from: formatCurrencyBRL(calc.from),
          calcId: calc.id,
          price: formatCurrencyBRL(calc.price),
          until: formatCurrencyBRL(calc.until),
        })),
      })),
      prolaboreValue: formatCurrencyBRL(plan.prolaboreValue),
      payrollValue: formatCurrencyBRL(plan.payrollValue),
      usePayroll: plan.usePayroll,
      useProlabore: plan.useProlabore,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "tierPrice",
  });

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    if (!contracts) return;

    const documentToken = contracts.find(
      (contract) => contract.filename === data.agreement,
    )?.downloadToken;

    if (!documentToken) return;

    const intervalType = Object.entries(intervalTypeToRecorrenceID).find(
      (entrie) => entrie[1] === data.periodicity,
    )?.[0] as IntervalType;

    const tierPriceInfoes = data.tierPrice.map((tier) => ({
      active: tier.active,
      id: tier.tierId,
      name: tier.name,
      calcIntervals: tier.calcIntervals.map((calcInterval) => ({
        from: formattedInput(calcInterval.from),
        id: calcInterval.calcId,
        price: formattedInput(calcInterval.price),
        until: formattedInput(calcInterval.until),
      })),
    }));

    await mutateAsync({
      bankSlip: data.bankSlip,
      companyToken,
      creditCard: data.creditCard,
      description: toHTMLString(startText),
      documentToken,
      endText: toHTMLString(successPlan),
      expiresAt: plan.expiresAt,
      intervalType,
      interval: intervalType === "weeks" ? data.periodParcel || 1 : data.parcel || 1,
      isActive: data.isActive,
      isAutomaticBilling: !data.isManualBilling,
      isExpirationDateCreationDay: data.isSameDayExp || false,
      name: data.name,
      payableWith: plan.payableWith,
      payrollValue: formattedInput(data.payrollValue),
      proLaboreValue: formattedInput(data.prolaboreValue),
      planId: plan.planId,
      useProLabore: data.useProlabore,
      usePayroll: data.usePayroll,
      tierPriceInfoes: tierPriceInfoes,
    });
    handleClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          sx={{
            borderBottom: 1,
            borderBottomColor: "divider",
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Geral" value={0} />
          {plan.identifier !== "pf-default" && <Tab label="Folha" value={"leaf"} />}
          {plan.identifier !== "pf-default" &&
            plan.tierPriceInfoes.map((tab) => <Tab label={tab.name} value={tab.id} key={tab.id} />)}
        </Tabs>

        <TabPanel value={activeTab} index={0} keepMounted={true}>
          <GeneralPlanForm control={control} companyToken={companyToken} />

          <Divider sx={{ my: 2 }} />

          <Stack mt={2} gap={2}>
            <Box>
              <InputLabel sx={{ mb: 0.5, pl: 2 }}>Texto de introdução</InputLabel>
              <TextEditor value={startText} onChange={(newValue) => setStartText(newValue)} />
            </Box>

            <Box>
              <InputLabel sx={{ mb: 0.5, pl: 2 }}>Texto de conclusão</InputLabel>
              <TextEditor value={successPlan} onChange={(newValue) => setSuccessPlan(newValue)} />
            </Box>
          </Stack>
        </TabPanel>

        {plan.identifier !== "pf-default" && (
          <>
            <TabPanel value={activeTab} index={"leaf"} keepMounted={true}>
              <LeafForm control={control} />
            </TabPanel>

            {fields.map((field, index) => (
              <TabPanel value={activeTab} index={field.tierId} key={field.tierId} keepMounted>
                <TabContent control={control} index={index} />
              </TabPanel>
            ))}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          disabled={isLoadingContracts}
          variant="contained"
        >
          Salvar
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default PlanForm;
