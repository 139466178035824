import { FC } from "react";
import useQueryGetDunning from "../hooks/useQueryGetDunning";

import { Box, CircularProgress, Collapse, LinearProgress, Stack } from "@mui/material";

import useQueryInfoAccount from "pages/Home/hooks/useQueryInfoAccount";
import SettingsForm from "./SettingsForm";

interface SettingsQueriesProps {
  companyToken: string;
}

const SettingsQueries: FC<SettingsQueriesProps> = ({ companyToken }) => {
  const {
    data: dunning,
    isLoading: isLoadingDunning,
    isFetching: isFetchingDunning,
  } = useQueryGetDunning(companyToken);
  const {
    data: infoAccount,
    isLoading: isLoadingInfo,
    isFetching: isFetchingInfo,
  } = useQueryInfoAccount({ companyToken });
  const isLoading = isLoadingInfo || isLoadingDunning;
  const isFetching = isFetchingInfo || isFetchingDunning;

  return (
    <Box>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" mt={2} height={200}>
          <CircularProgress />
        </Stack>
      )}

      <Collapse in={!isLoading && isFetching}>
        <LinearProgress />
      </Collapse>

      {dunning && infoAccount && (
        <SettingsForm dunning={dunning} infoAccount={infoAccount} companyToken={companyToken} />
      )}
    </Box>
  );
};

export default SettingsQueries;
