import { useCallback } from "react";
import { useQuery } from "react-query";
import queryKeys from "react-query/constants";

import { getTransactionsSearch, GetTransactionsSearchPayload } from "services/Requests/companies";
import { TransactionType } from "types/CommonData";
import { TransactionsItem } from "types/Company";

interface Payload extends GetTransactionsSearchPayload {
  transactionType: TransactionType;
}

const useQueryGetTransactionsSearch = ({
  action,
  companyToken,
  endDate,
  startDate,
  term,
  bankAccountId,
  transactionType,
}: Payload) => {
  const selectTransactionType = useCallback(
    (data: TransactionsItem[]) => {
      return data.filter((d) => d.transactionType === transactionType);
    },
    [transactionType],
  );

  const queryKey: (number | string)[] = [queryKeys.transactionsSearch, startDate, endDate];
  if (bankAccountId) queryKey.push(bankAccountId);
  if (term.trim()) queryKey.push(term);

  const query = useQuery(
    queryKey,
    () =>
      getTransactionsSearch({
        action,
        companyToken,
        endDate,
        startDate,
        term,
        bankAccountId,
      }),
    {
      keepPreviousData: true,
      select: selectTransactionType,
    },
  );
  return query;
};

export default useQueryGetTransactionsSearch;
