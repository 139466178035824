import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getGroupChartOfAccountsFromTo } from "services/Requests/accountant";

const useQueryGetGroupChartOfAccounts = (id: number) => {
  const query = useQuery(
    [queryKeys.groupChartOfAccounts, id],
    () => getGroupChartOfAccountsFromTo({ id }),
    {
      retry: 0,
    },
  );
  return query;
};

export default useQueryGetGroupChartOfAccounts;
