type ObjectType<T> = {
  [key in keyof T]: string | Blob | File | number | boolean | null | undefined;
};

function getFormData<T>(object: ObjectType<T>): FormData {
  const formData = new FormData();

  for (const key in object) {
    let value = object[key];
    if (typeof value === "number" || typeof value === "boolean") value = value.toString();
    if (value === null || value === undefined) continue;

    formData.append(key, value);
  }

  return formData;
}

export default getFormData;
