import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getDre } from "services/Requests/dre";

const useQueryGetDre = (companyToken?: string) => {
  const query = useQuery([queryKeys.dreList], () =>
  getDre(companyToken),
  );
  return query;
};

export default useQueryGetDre;
