import { FC, MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import useQueryChat from "pages/Home/hooks/useQueryChat";
import { useCompanyInfo } from "context/CompanyInfo";

import { Badge, IconButton, Tooltip } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";

interface ComunicationProps {
  companyToken: string;
}

const Comunication: FC<ComunicationProps> = ({ companyToken }) => {
  const {
    companyStyles: { headerLinksColor },
  } = useCompanyInfo();
  const navigate = useNavigate();
  const { data } = useQueryChat(companyToken);

  const handleNavigate: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    navigate("/comunicacao");
  };

  return (
    <Tooltip title="Comunicação">
      <IconButton
        component="a"
        href="/comunicacao"
        onClick={handleNavigate}
        sx={{ height: "100%", borderRadius: 0 }}
      >
        <Badge badgeContent={data?.totalUnread || 0} color="warning" max={99}>
          <MailIcon sx={{ color: headerLinksColor }} />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default Comunication;
