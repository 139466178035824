import endpoints from "../endpoints";
import api from "../api";
import { ProductByPlan } from "types/Plan";

export interface GetDefaultAccountabilityPlanPayload {
  CompanyToken: string;
  planId: number;
}

export const getDefaultAccountabilityPlan = async (
  payload: GetDefaultAccountabilityPlanPayload,
) => {
  const { data } = await api.post(endpoints.plan.defaultAccountabilityPlan, payload);
  return data;
};

export interface GetOsaykPlanByProductPayload {
  companyToken: string;
  productName: "Contabilidade Online";
  subdomain: string;
}

export const getOsaykPlanByProduct = async (payload: GetOsaykPlanByProductPayload) => {
  const { data } = await api.post<ProductByPlan[]>(endpoints.plan.osaykPlanByProduct, payload);
  return data;
};
