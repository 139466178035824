import { FC, Fragment, useMemo, useState } from "react";
import { useAuth } from "context/Auth";

import { Box, Stack, Typography } from "@mui/material";

import TabNavigation from "components/TabNavigation";
import getSubdomain from "utils/getSubdomain";

import Head from "components/Head";
import Create from "./Create";
import Invoice from "./Invoice";
import Products from "./Products";
import Movements from "./Movements";
import StockBalance from "./StockBalance";
import ConfigInvoice from "./ConfigInvoice";

interface Tab {
  name: string;
  slug: string;
  Component: FC;
  perm?: number;
}

const tabs: Tab[] = [
  {
    name: "Produtos",
    slug: "produtos",
    Component: Products,
    perm: 102,
  },
  {
    name: "Movimentações",
    slug: "movimentacoes",
    Component: Movements,
    perm: 103,
  },
  {
    name: "Saldo do Estoque",
    slug: "saldo-do-estoque",
    Component: StockBalance,
    perm: 104,
  },
  {
    name: "Notas Emitidas",
    slug: "notas-emitidas",
    Component: Invoice,
    perm: 105,
  },
  {
    name: "Configurar NF-e e NFC-e",
    slug: "configurar-nfe",
    Component: ConfigInvoice,
    perm: 106,
  },
];

const InventoryControl: FC = () => {
  const {
    user: { userInfo },
  } = useAuth();
  const [tabActive, setTabActive] = useState("1");

  const tabsSanitized = useMemo((): Tab[] => {
    return userInfo.userType === "User"
      ? tabs
      : tabs.map((t) => ({ Component: t.Component, name: t.name, slug: t.slug }));
  }, [userInfo]);

  const handleChangeTab = (newValue: string) => {
    setTabActive(newValue);
  };

  return (
    <>
      <Head title={`Controle de Estoque | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Controle de Estoque
        </Typography>
        <Create />
      </Stack>

      <Box mt={2} boxShadow={2} bgcolor="#fff">
        <TabNavigation
          tabActive={tabActive}
          tabs={tabsSanitized}
          handleChangeTab={handleChangeTab}
        />
      </Box>
    </>
  );
};

export default InventoryControl;
