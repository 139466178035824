import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { toggleConfigurationsMonthClosure } from "services/Requests/configuration";
import getSubdomain from "utils/getSubdomain";

const useMutationToggleMonthClosure = () => {
  const subdomain = getSubdomain();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (blockReleases: boolean) => toggleConfigurationsMonthClosure({ blockReleases, subdomain }),
    {
      onSuccess() {
        queryClient.invalidateQueries(queryKeys.monthClosureConfiguration);
      },
      onError() {
        toast.error("Erro ao tentar modificar as permissões de edição de lançamentos");
        queryClient.invalidateQueries(queryKeys.monthClosureConfiguration);
      },
    },
  );
  return mutation;
};

export default useMutationToggleMonthClosure;
