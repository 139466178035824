import { ChangeEvent, FC, useMemo, useState } from "react";

import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Layout } from "types/Template";

interface ColumnItemProps {
  layout: Layout;
  onDelete: (layout: Layout) => void;
  onEdit: (layout: Layout) => void;
}

const ColumnItem: FC<ColumnItemProps> = ({ layout, onDelete, onEdit }) => {
  const [editLayout, setEditLayout] = useState(layout);
  const editted = useMemo(() => {
    return JSON.stringify(editLayout) != JSON.stringify(layout);
  }, [editLayout, layout]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, field: keyof Layout) => {
    setEditLayout({
      ...editLayout,
      [field]: e.target.value,
    });
  };
  const createHandler = (field: keyof Layout) => (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e, field);
  };

  return (
    <Box
      display="grid"
      alignItems="center"
      justifyContent="space-between"
      gap={4}
      p={2}
      border={1}
      borderColor={grey[300]}
      borderRadius={1}
      gridTemplateColumns="1fr 3fr 1fr"
    >
      <Typography variant="body1" fontWeight={500}>
        {layout.name}
      </Typography>

      <Stack direction="row" alignItems="center" gap={1}>
        <TextField
          value={editLayout.columname}
          placeholder="Nome coluna"
          size="small"
          sx={{ maxWidth: 100 }}
          inputProps={{
            maxLength: 50,
          }}
          onChange={createHandler("columname")}
        />
        <TextField
          value={editLayout.format}
          placeholder="Formato"
          size="small"
          sx={{ maxWidth: 100 }}
          inputProps={{
            maxLength: 12,
          }}
          onChange={createHandler("format")}
        />
        <TextField
          value={editLayout["length"]}
          placeholder="Tamanho"
          size="small"
          sx={{ maxWidth: 100 }}
          inputProps={{
            maxLength: 12,
          }}
          onChange={createHandler("length")}
        />
        <TextField
          value={editLayout.direction}
          placeholder="Alinhamento"
          size="small"
          sx={{ maxWidth: 100 }}
          inputProps={{
            maxLength: 1,
          }}
          onChange={createHandler("direction")}
        />
        <TextField
          value={editLayout.complete}
          placeholder="Completar"
          size="small"
          sx={{ maxWidth: 100 }}
          inputProps={{
            maxLength: 1,
          }}
          onChange={createHandler("complete")}
        />
      </Stack>

      <Stack direction="row" justifyContent="end" gap={1}>
        {editted && (
          <Button variant="contained" size="small" onClick={() => onEdit(editLayout)}>
            Salvar
          </Button>
        )}
        <Button variant="contained" size="small" onClick={() => onDelete(layout)}>
          Excluir
        </Button>
      </Stack>
    </Box>
  );
};

export default ColumnItem;
