import { FC, useState } from "react";
import useMutationResendPaymentMethod from "./hooks/useMutationResendPaymentMethod";
import { useAuth } from "context/Auth";

import { Chip, IconButton, MenuItem, Select, TableCell, TableRow, Tooltip } from "@mui/material";
import DropdownMenu from "components/DropdownMenu";
import MenuIcon from "@mui/icons-material/Menu";

import DialogCompany from "pages/Home/components/DialogCompany";
import DialogActivateCompany from "./Integrations/DialogUpdateCompanyStatus";
import DialogDeleteCompany from "./Integrations/DialogDeleteCompany";
import DialogGeneratePassDefault from "./Integrations/DialogGeneratePassDefault";

import { DateTime } from "luxon";
import { CompanyAssignments, CompanyAssignmentsResponse, CompanyStatus } from "types/Admin";
import useMutationAssignAccountant from "../../hooks/useMutationAssignAccountant";

interface CompanyAssignmentsTableItemProps {
  companyAssignments: CompanyAssignments;
  accountants: CompanyAssignmentsResponse["accountants"];
  registrationStatus: number;
  accountantId?: number;
}

const translateStatus: Record<CompanyStatus, string> = {
  Prospect: "Aguardando aprovação",
  Registered: "Cadastro Incompleto",
  Approved: "Aprovada",
};

const CompanyAssignmentsTableItem: FC<CompanyAssignmentsTableItemProps> = ({
  companyAssignments,
  accountants,
  accountantId,
  registrationStatus,
}) => {
  const { mutate: mutateResendPayment, isLoading: loadingResend } =
    useMutationResendPaymentMethod();
  const { mutate: mutateAssignAccountant, isLoading: loadingAssign } =
    useMutationAssignAccountant();
  const isLoading = loadingResend || loadingAssign;
  const { loginTheClient } = useAuth();

  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  const [accountant, setAccountant] = useState(accountantId);
  const [showDetails, setShowDetails] = useState(false);
  const [showDialogUpdateStatus, setShowDialogUpdateStatus] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showDialogGeneratePassDefault, setShowDialogGeneratePassDefault] = useState(false);

  const handleResendPaymentMethod = () => {
    mutateResendPayment({
      companyToken: companyAssignments.token,
    });
  };
  const handleAssignAccountant = () => {
    if (!accountant) return;
    mutateAssignAccountant({
      accountantId: accountant,
      companyId: companyAssignments.id,
    });
  };

  return (
    <>
      <TableRow
        hover
        selected={menuIsOpen}
        sx={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? "none" : undefined }}
      >
        <TableCell align="center">
          {companyAssignments.totalMessagesUnread > 0 && (
            <Tooltip title="Mensagens não lidas">
              <Chip label={companyAssignments.totalMessagesUnread} color="error" size="small" />
            </Tooltip>
          )}
        </TableCell>
        <TableCell>{companyAssignments.code}</TableCell>
        <TableCell sx={{ cursor: "pointer" }} onClick={() => setShowDetails(true)}>
          {companyAssignments.name}
        </TableCell>
        <TableCell>{companyAssignments.cnpj}</TableCell>
        <TableCell>{companyAssignments.ownerName}</TableCell>
        <TableCell>
          {DateTime.fromISO(companyAssignments.createdOn).toFormat("dd/MM/yyyy")}
        </TableCell>
        <TableCell>{translateStatus[companyAssignments.status]}</TableCell>

        <TableCell>
          <Select
            value={accountant ?? 0}
            onChange={(e) => setAccountant(Number(e.target.value))}
            size="small"
            sx={{ maxWidth: 150 }}
          >
            <MenuItem value={0} disabled>
              Selecione
            </MenuItem>
            {accountants.map((accountant) => (
              <MenuItem key={accountant.id} value={accountant.id}>
                {accountant.name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>

        <TableCell align="right">
          <IconButton size="small" onClick={({ currentTarget }) => setAnchorElMenu(currentTarget)}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        anchorEl={anchorElMenu}
        open={menuIsOpen}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
      >
        <MenuItem onClick={() => loginTheClient(companyAssignments.token)}>
          Logar no cliente
        </MenuItem>
        {registrationStatus === -1 && (
          <MenuItem onClick={() => setShowDialogUpdateStatus(true)}>Ativar empresa</MenuItem>
        )}
        {registrationStatus === 0 && (
          <MenuItem onClick={() => setShowDialogUpdateStatus(true)}>Migrar Cliente</MenuItem>
        )}
        <MenuItem onClick={() => setShowDetails(true)}>Acessar informações</MenuItem>
        {companyAssignments.code <= 0 && (
          <MenuItem onClick={() => setShowDetails(true)}>Definir Código</MenuItem>
        )}
        {companyAssignments.code > 0 && (
          <MenuItem onClick={handleAssignAccountant}>
            Associar Contador{accountant !== accountantId && "*"}
          </MenuItem>
        )}
        <MenuItem onClick={() => setShowDialogGeneratePassDefault(true)}>
          Gerar Senha Padrão
        </MenuItem>
        {registrationStatus === 2 && (
          <MenuItem onClick={handleResendPaymentMethod}>Reenviar Met. Pgto.</MenuItem>
        )}
        {registrationStatus !== -1 && (
          <MenuItem onClick={() => setShowDialogUpdateStatus(true)}>Inativar Empresa</MenuItem>
        )}
        <MenuItem onClick={() => setShowDeleteDialog(true)}>Excluir</MenuItem>
      </DropdownMenu>

      {showDetails && (
        <DialogCompany
          open={showDetails}
          onClose={() => setShowDetails(false)}
          company={companyAssignments}
        />
      )}

      {showDialogUpdateStatus && (
        <DialogActivateCompany
          open={showDialogUpdateStatus}
          onClose={() => setShowDialogUpdateStatus(false)}
          companyId={companyAssignments.id}
          type={registrationStatus !== -1 ? "deactivate" : "activate"}
        />
      )}

      {showDeleteDialog && (
        <DialogDeleteCompany
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          companyToken={companyAssignments.token}
          companyName={companyAssignments.name}
        />
      )}

      {showDialogGeneratePassDefault && (
        <DialogGeneratePassDefault
          open={showDialogGeneratePassDefault}
          onClose={() => setShowDialogGeneratePassDefault(false)}
          companyId={companyAssignments.id}
        />
      )}
    </>
  );
};

export default CompanyAssignmentsTableItem;
