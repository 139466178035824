import { FC, useEffect, useMemo } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "context/Auth";

interface Tabs {
  name: string;
  slug: string;
  Component: FC;
  perm?: number;
}

export interface TabNavigationProps {
  tabActive: string;
  handleChangeTab: (newTab: string) => void;
  tabs: Tabs[];
}

const TabNavigation: FC<TabNavigationProps> = ({ tabActive, handleChangeTab, tabs }) => {
  const {
    user: { userInfo },
  } = useAuth();

  const tabsAfterPermissions = useMemo(() => {
    const hasSomePermission = tabs.some((tab) => !!tab.perm);
    if (!hasSomePermission) return tabs;

    const userFeatures = userInfo.features;
    const userHaveAllPermissions = userFeatures[0] === "00";
    if (userHaveAllPermissions) return tabs;

    return tabs.filter((tab) => {
      if (tab?.perm) {
        return userFeatures.includes(tab.perm.toString());
      }
      return true;
    });
  }, [userInfo, tabs]);

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const findTabIndexBySlug = (slug: string) => {
    const index = tabsAfterPermissions.findIndex((tab) => tab.slug === slug);
    return index === -1 ? undefined : index;
  };

  const handleChange = (newTab: string) => {
    const tab = tabsAfterPermissions[Number(newTab) - 1];

    if (tab) {
      navigate(`${pathname}?tab=${tab.slug}`);
    }

    handleChangeTab(newTab);
  };

  useEffect(() => {
    const initialTab = new URLSearchParams(search).get("tab");

    if (initialTab) {
      const index = findTabIndexBySlug(initialTab);
      handleChange(index ? (index + 1).toString() : "1");
    } else {
      handleChange("1");
    }
  }, [pathname, search]);

  if (tabsAfterPermissions.length === 0) {
    return <Typography sx={{ p: 2 }}>Você não tem permissão para acessar nenhum item</Typography>;
  }

  return (
    <TabContext value={tabActive}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={(_, newTab) => handleChange(newTab)}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabsAfterPermissions.map((tab, index) => (
            <Tab key={tab.slug} label={tab.name} value={(index + 1).toString()} />
          ))}
        </TabList>
      </Box>

      {tabsAfterPermissions.map(({ Component, slug }, index) => (
        <TabPanel key={slug} value={(index + 1).toString()}>
          <Component />
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default TabNavigation;
