import { forwardRef, Ref } from "react";
import useQueryBanks from "pages/Home/hooks/useQueryBanks";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { Bank } from "types/CommonData";

type MyAutocompleteProps = Partial<AutocompleteProps<Bank, false, false, false, "div">>;
type Value = Bank | number | null | undefined;

interface BankAutoCompleteInnerProps extends Omit<MyAutocompleteProps, "value"> {
  textFieldProps?: TextFieldProps;
  value: Value;
}

function BankAutoCompleteInner(
  { textFieldProps, ...props }: BankAutoCompleteInnerProps,
  ref?: Ref<unknown>,
) {
  const { data: banks = [], isLoading } = useQueryBanks();

  const getValueBank = (value: Value) => {
    if (value === null || value === undefined) return undefined;
    if (typeof value !== "number") return value;

    if (!banks) return undefined;
    return banks.find((bank) => bank.id === value);
  };

  return (
    <Autocomplete
      {...props}
      value={getValueBank(props.value)}
      options={banks}
      autoHighlight
      getOptionLabel={(option) => `${option.id.toString().padStart(3, "0")} ${option.name}`}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      noOptionsText="Sem opção"
      disabled={isLoading}
      ref={ref}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography component="strong" fontWeight={600} sx={{ mr: 1 }}>
            {option.id.toString().padStart(3, "0")}
          </Typography>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Banco"
          {...textFieldProps}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
            ...textFieldProps?.inputProps,
          }}
          InputProps={{
            ...textFieldProps?.InputProps,
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

const BankAutoComplete = forwardRef(BankAutoCompleteInner);
export default BankAutoComplete;
