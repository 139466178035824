import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { deleteDocumentDrive } from "services/Requests/companies";

const useMutationDeleteDocumentDrive = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteDocumentDrive, {
    onSuccess() {
      toast.success("Documento deletado com sucesso");
      queryClient.invalidateQueries(queryKeys.companyDocumentsDrive, { exact: false });
    },
  });
  return mutation;
};

export default useMutationDeleteDocumentDrive;
