import { TransactionsItem } from "types/Company";

const filterByCompanyParticipant = (
  transactionItems: TransactionsItem[],
  participantId: number,
) => {
  return transactionItems.filter((item) => item.companyParticipantId === participantId);
};

export default filterByCompanyParticipant;
