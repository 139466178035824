import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getTransactionsMatch, GetTransactionsMatchPayload } from "services/Requests/companies";

const useQueryGetTransactionsMatch = ({
  companyToken,
  date,
  id,
  value,
}: GetTransactionsMatchPayload) => {
  const query = useQuery([queryKeys.transactionsMatch, id, date, value], () =>
    getTransactionsMatch({
      date,
      id,
      value,
      companyToken,
    }),
  );
  return query;
};

export default useQueryGetTransactionsMatch;
