import { FC } from "react";
import { useAuth } from "context/Auth";

import { Box } from "@mui/material";
import getSubdomain from "utils/getSubdomain";

import SelectPeriod from "./SelectPeriod";
import FinancialSummary from "./FinancialSummary";
import RevenuesOrExpenses from "./RevenuesOrExpenses";
import IncomeAndExpenses from "./IncomeAndExpenses";
import Head from "components/Head";
import { DashboardPeriodProvider } from "./contexts/DashboardPeriod";
import LatestTransactions from "./LatestTransactions";

const Dashboard: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <DashboardPeriodProvider>
      <Head title={`Dashboard | ${getSubdomain()}`} />

      <SelectPeriod />

      {company && <FinancialSummary companyToken={company.token} />}

      <Box
        display="grid"
        sx={(theme) => ({
          gridTemplateColumns: "1fr 1fr",
          [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "1fr",
          },
        })}
        gap={2}
        mt={2}
        mb={2}
      >
        {company && (
          <>
            <RevenuesOrExpenses companyToken={company.token} type="Credit" />
            <RevenuesOrExpenses companyToken={company.token} type="Debt" />

            <IncomeAndExpenses companyToken={company.token} type="projected" />
            <IncomeAndExpenses companyToken={company.token} type="performed" />
          </>
        )}
      </Box>

      {company && <LatestTransactions companyToken={company.token} />}
    </DashboardPeriodProvider>
  );
};

export default Dashboard;
