import * as yup from "yup";

const subItemSchemaValidation = yup.object().shape({
  description: yup.string().required("A descrição é obrigatória"),
  value: yup.string().required("O valor é obrigatório"),
});

const validationSchema = yup.object().shape({
  subItems: yup.array().of(subItemSchemaValidation),
});

export default validationSchema;
