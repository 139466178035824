import { useContext } from "react";
import { DriveContext } from ".";

export const useDrive = () => {
  const ctx = useContext(DriveContext);

  if (!ctx) {
    throw new Error("useDrive must be used within a DriveProvider");
  }

  return ctx;
};
