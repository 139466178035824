import { FC, useState } from "react";

import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { ISODate } from "types/SemanticTypes";
import { DateTime } from "luxon";

import DialogSettlementForm from "pages/Home/components/DialogSettlementForm";
import DialogReleaseDelete from "pages/Home/components/DialogReleaseDelete";

interface MassActionsProps {
  earliestBankBalanceDate: ISODate;
  startDate: ISODate;
  initialBalance: number;
  existsPreviousBalance: boolean;
  selected: number[];
  companyToken: string;
}

const MassActions: FC<MassActionsProps> = ({
  earliestBankBalanceDate,
  startDate,
  existsPreviousBalance,
  initialBalance,
  selected,
  companyToken,
}) => {
  const totalSelected = selected.length;
  const [dialogSettlementIsOpen, setDialogSettlementIsOpen] = useState(false);
  const [dialogDeleteIsOpen, setDialogDeleteIsOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    const action = event.target.value;

    if (action === "0") return;
    if (action === "pay") setDialogSettlementIsOpen(true);
    if (action === "delete") setDialogDeleteIsOpen(true);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
      >
        <Stack direction="row" alignItems="center" gap={1} pl={2}>
          <ArrowDownwardIcon color="primary" fontSize="small" />
          <Select value="0" onChange={handleChange} size="small">
            <MenuItem value="0">Ações em massa</MenuItem>
            <MenuItem value="pay" disabled={totalSelected === 0}>
              Pagar e receber
            </MenuItem>
            <MenuItem value="delete" disabled={totalSelected === 0}>
              Excluir
            </MenuItem>
          </Select>

          {!!totalSelected && (
            <Typography sx={{ mr: 1 }} color="text.secondary" variant="body1">
              {totalSelected} {totalSelected === 1 ? "item selecionado" : "itens selecionados"}
            </Typography>
          )}
        </Stack>

        <Typography>
          {existsPreviousBalance ? (
            <>Saldo anterior a {DateTime.fromISO(startDate).toFormat("dd/MM/yyyy")}</>
          ) : (
            <>Saldo em {DateTime.fromISO(earliestBankBalanceDate).toFormat("dd/MM/yyyy")}</>
          )}

          <Typography
            component="strong"
            sx={{ fontWeight: 600, color: initialBalance > 0 ? "success.main" : "error.main" }}
          >
            {": " + formatCurrencyBRL(initialBalance)}
          </Typography>
        </Typography>
      </Stack>

      <DialogSettlementForm
        open={dialogSettlementIsOpen}
        onClose={() => setDialogSettlementIsOpen(false)}
        companyToken={companyToken}
        transactionIds={selected}
      />

      <DialogReleaseDelete
        open={dialogDeleteIsOpen}
        onClose={() => setDialogDeleteIsOpen(false)}
        companyToken={companyToken}
        hasParcel={false}
        id={selected}
      />
    </>
  );
};

export default MassActions;
