import { useQuery } from "react-query";
import { getAnalitycalAccounts } from "services/Requests/dre";

const useQueryGetDREAnalitycalAccounts = (companyToken?: string) => {
  const query = useQuery("", () =>
  getAnalitycalAccounts(companyToken),
  );
  return query;
};

export default useQueryGetDREAnalitycalAccounts;