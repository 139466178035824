import { FC, useState } from "react";
import useMutationUpdateLayout from "./hooks/useMutationUpdateLayout";

import { Box, Button, Collapse, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { Layout } from "types/Template";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";

interface FieldsProps {
  layouts: Layout[];
  companyToken: string;
  id: number;
}

const Fields: FC<FieldsProps> = ({ layouts, companyToken, id }) => {
  const [collapsed, setCollapsed] = useState(true);
  const { mutate, isLoading } = useMutationUpdateLayout();

  const handleAddColumn = (layout: Layout) => {
    const newLayout: Layout = {
      columname: layout.columname,
      name: layout.name,
      format: layout.format,
      length: layout.length,
      direction: layout.direction,
      parentId: 1,
      id: layouts.length + 1,
      type: "item",
    };

    mutate({
      companyToken,
      id,
      items: [...layouts, newLayout],
    });
  };

  return (
    <>
      <Box overflow="hidden" borderRadius={1} border={1} borderColor="divider">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          bgcolor="#fff"
          p={2}
          borderBottom={1}
          borderColor={grey[100]}
        >
          <Button
            sx={{ color: "text.primary", textAlign: "left" }}
            size="small"
            startIcon={
              <ArrowDropDownIcon
                fontSize="small"
                sx={{ transform: `rotate(${collapsed ? 0 : -90}deg)` }}
              />
            }
            onClick={() => setCollapsed((prev) => !prev)}
          >
            Campos
          </Button>
        </Stack>

        <Collapse in={collapsed}>
          <Stack gap={1} p={2} bgcolor={grey[100]}>
            {layouts
              .filter((l) => !l.parentId)
              .map((layout) => (
                <Stack
                  key={layout.id}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  p={2}
                  border={1}
                  borderColor={grey[300]}
                  borderRadius={1}
                >
                  <Typography>{layout.name}</Typography>
                  <Button variant="contained" size="small" onClick={() => handleAddColumn(layout)}>
                    Adicionar coluna
                  </Button>
                </Stack>
              ))}
          </Stack>
        </Collapse>
      </Box>
      {isLoading && <FullscreenLoadingIndicator />}
    </>
  );
};

export default Fields;
