import * as yup from "yup";

export const validationSchemaForUserGuest = yup.object().shape({
  name: yup.string().required("O nome do administrador é obrigatório"),
  email: yup
    .string()
    .email("Digite um email válido")
    .required("O email do administrador é obrigatório"),
});

export const validationSchema = yup.object().shape({
  name: yup.string().required("O nome do administrador é obrigatório"),
  login: yup.string().required("O login do administrador é obrigatório"),
  email: yup
    .string()
    .email("Digite um email válido")
    .required("O email do administrador é obrigatório"),
});
