import { FC } from "react";
import { useAuth } from "context/Auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useMutationChangePassword from "./hooks/useMutationChangePassword";

import { Box, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ControllerInput from "components/ControllerInput";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import Head from "components/Head";
import getSubdomain from "utils/getSubdomain";

const ChangePassowrd: FC = () => {
  const navigate = useNavigate();
  const {
    user: { company },
  } = useAuth();

  const { mutateAsync, isLoading } = useMutationChangePassword();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!company) return;

    try {
      await mutateAsync({
        companyToken: company.token,
        newPassword: data.confirmPassword,
        passwordConfirm: data.confirmPassword,
      });
      navigate(-1);
    } catch (e) {
      console.log(e);
    }
  });

  return (
    <>
      <Head title={`Alterar senha | ${getSubdomain()}`} />

      <Typography variant="h4" fontWeight="bold">
        Alterar Senha
      </Typography>

      <Box
        component="form"
        onSubmit={onSubmit}
        boxShadow={1}
        mt={2}
        bgcolor="#fff"
        maxWidth="400px"
      >
        {!company ? (
          <Typography variant="body1">Empresa não localizada!</Typography>
        ) : (
          <>
            <Stack gap={2} p={2}>
              <ControllerInput
                control={control}
                name="password"
                inputProps={{
                  label: "Nova senha",
                  fullWidth: true,
                  type: "password",
                }}
              />

              <ControllerInput
                control={control}
                name="confirmPassword"
                inputProps={{
                  label: "Repita a nova senha",
                  fullWidth: true,
                  type: "password",
                }}
              />
            </Stack>

            <Stack direction="row" p={2} sx={{ borderTop: 1, borderTopColor: "divider" }}>
              <LoadingButton loading={isLoading} variant="contained" type="submit">
                Alterar
              </LoadingButton>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default ChangePassowrd;
