import { FC } from "react";
import Add from "@mui/icons-material/Add";
import { Box, Button, Checkbox, Divider, FormControlLabel, Stack } from "@mui/material";
import { Control, Controller, UseFieldArrayReturn } from "react-hook-form";
import { CreatePlanForm } from ".";

import PaymentRecurrenceField from "./PaymentRecurrenceField";

interface PaymentRecurrenceProps {
  control: Control<CreatePlanForm>;
  paymentRecurrences: UseFieldArrayReturn<CreatePlanForm, "paymentRecurrence", "id">;
}

const PaymentRecurrence: FC<PaymentRecurrenceProps> = ({ control, paymentRecurrences }) => {
  const createField = () => {
    paymentRecurrences.append({
      periodicity: 3,
      percentDiscount: 0,
      recurrencePriceDescription: "",
    });
  };

  return (
    <Box>
      {!!paymentRecurrences.fields.length && (
        <Stack gap={2} mb={2}>
          {paymentRecurrences.fields.map((field, index, arr) => (
            <>
              <PaymentRecurrenceField
                key={field.id}
                control={control}
                field={field}
                index={index}
                onRemove={paymentRecurrences.remove}
              />
              {index !== arr.length - 1 && <Divider sx={{ width: "80%", mx: "auto" }} />}
            </>
          ))}
        </Stack>
      )}

      <Button variant="contained" startIcon={<Add />} onClick={createField}>
        Adicionar recorrência
      </Button>
      <Box mt={1}>
        <Controller
          control={control}
          name="isManualBilling"
          render={({ field }) => (
            <FormControlLabel
              sx={{ m: 0, height: "auto" }}
              control={
                <Checkbox
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  checked={!!field.value}
                  value={!!field.value}
                  inputRef={field.ref}
                />
              }
              label="Desativar assinatura automática"
            />
          )}
        />
      </Box>

      <Divider sx={{ mt: 4 }} />
    </Box>
  );
};

export default PaymentRecurrence;
