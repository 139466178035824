import { forwardRef, Ref } from "react";

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { TypeInvoice } from "types/StockControl";
import useQueryPresenceIndicator from "pages/Home/hooks/useQueryPresenceIndicator";

interface PresenceIndicatorSelectInnerProps extends SelectProps {
  type: TypeInvoice;
  FormControlProps?: FormControlProps;
  helperText?: string;
}

function PresenceIndicatorSelectInner(
  { type, helperText, FormControlProps, ...props }: PresenceIndicatorSelectInnerProps,
  ref: Ref<unknown>,
) {
  const { data: presenceIndicators = [], isLoading, isError } = useQueryPresenceIndicator(type);

  return (
    <FormControl
      disabled={props.disabled}
      error={props.error}
      size={props.size}
      {...FormControlProps}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select {...props} ref={ref} value={props.value || ""}>
        <MenuItem disabled value="">
          Selecione:{" "}
        </MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && (
          <MenuItem disabled>Ocorreu um erro ao buscar os indicadores de presença</MenuItem>
        )}

        {presenceIndicators.map((indicator) => (
          <MenuItem key={indicator.id} value={indicator.id}>
            {indicator.description}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const PresenceIndicatorSelect = forwardRef(PresenceIndicatorSelectInner);
export default PresenceIndicatorSelect;
