import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { updateCompanyParticipant } from "services/Requests/companies";

const useMutatioUpdateCompanyParticipants = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCompanyParticipant, {
    onSuccess(_, { id }) {
      toast.success("Cliente editado com sucesso");
      queryClient.invalidateQueries(queryKeys.companyParticipants, { exact: false });
      queryClient.invalidateQueries([queryKeys.individualCompanyParticipant, id]);
    },
  });
  return mutation;
};

export default useMutatioUpdateCompanyParticipants;
