import { Box, Typography } from "@mui/material";
import Head from "components/Head";
import { useAuth } from "context/Auth";
import { FC } from "react";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import getSubdomain from "utils/getSubdomain";
import DREList from "./DREList";

const ManagerialIncomeStatement: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <>
      <Head title={`DRE Gerencial | ${getSubdomain()}`} />

      <Typography variant="h4" fontWeight="bold" sx={{ mr: 1 }}>
        Template da DRE Gerencial
      </Typography>

      <Box borderRadius={1} boxShadow={1} p={2} mt={2}>
        {!company ? <Typography variant="body1">{MESSAGE_NO_COMPANY}</Typography> : <DREList />}
      </Box>
    </>
  );
};

export default ManagerialIncomeStatement;
