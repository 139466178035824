import { Stack, StackProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import { forwardRef, Ref } from "react";
import { Preset1, Preset2 } from "./Preset";

const renderPreset: { [key: number]: JSX.Element } = {
  1: <Preset1 />,
  2: <Preset2 />,
};

export interface ToolbarProps extends StackProps {
  preset?: 1 | 2;
}

const Toolbar = forwardRef(
  ({ preset, ...props }: ToolbarProps, ref: Ref<HTMLDivElement> | undefined) => (
    <Stack
      {...props}
      ref={ref}
      p={1}
      bgcolor={grey[300]}
      borderBottom={1}
      borderColor={grey[300]}
      gap={1}
      direction="row"
      flexWrap="wrap"
    >
      {renderPreset[preset || 1]}
    </Stack>
  ),
);

Toolbar.displayName = "Toolbar";
export default Toolbar;
