import { forwardRef, Ref } from "react";
import useQueryProducts from "pages/Home/pages/InventoryControl/hooks/useQueryProducts";

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface ProductSelectInnerProps extends SelectProps<number> {
  companyToken: string;
  helperText?: string;
  FormControlProps?: FormControlProps;
}

function ProductSelectInner(
  { companyToken, helperText, FormControlProps, ...props }: ProductSelectInnerProps,
  ref?: Ref<unknown>,
) {
  const { data: products = [], isLoading, isError } = useQueryProducts(companyToken);

  return (
    <FormControl size={props.size} error={props.error} {...FormControlProps}>
      <InputLabel>{props.label}</InputLabel>
      <Select ref={ref} {...props} value={props.value || 0}>
        <MenuItem disabled value={0}>
          Selecione:{" "}
        </MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um erro ao buscar as categorias</MenuItem>}

        {products.map((product) => (
          <MenuItem key={product.id} value={product.id}>
            {product.name} {!!product.code && `(${product.code})`}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const ProductSelect = forwardRef(ProductSelectInner);
export default ProductSelect;
