import { FC } from "react";
import useQueryGetTicketById from "../hooks/useQueryGetTicketById";

import { Box, CircularProgress, Collapse, LinearProgress, Stack, Typography } from "@mui/material";

import ChatStatus from "./ChatStatus";
import ChatGroup from "./ChatGroup";
import ChatSendMessage from "./ChatSendMessage";
import { ChatInformation } from "types/Mailbox";

interface ChatContentProps {
  chat: ChatInformation;
  onOpenOrCloseTicket: () => void;
}

const ChatContent: FC<ChatContentProps> = ({ chat, onOpenOrCloseTicket }) => {
  const { data: ticket, isLoading, isFetching } = useQueryGetTicketById(chat.companyId, chat.id);

  if (isLoading) {
    return (
      <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack width="100%">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={2}
        px={4}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Box>
          <Typography variant="h6">{chat.subject}</Typography>
          <Typography variant="body2" color="text.secondary">
            {chat.companyName}
          </Typography>
        </Box>

        <Typography variant="body1" color="text.secondary">
          #{chat.id.toString().padStart(7, "0")}
        </Typography>
      </Stack>

      {isLoading && (
        <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {ticket && (
        <>
          <ChatStatus
            ticket={ticket}
            onOpenOrCloseTicket={onOpenOrCloseTicket}
            companyId={chat.companyId}
          />
          <ChatGroup ticket={ticket} chatInformation={chat} />
          <Collapse in={isFetching && !isLoading}>
            <LinearProgress />
          </Collapse>
          <ChatSendMessage companyId={chat.companyId} ticketId={ticket.id} />
        </>
      )}
    </Stack>
  );
};

export default ChatContent;
