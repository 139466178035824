import { FC, useEffect, useRef, useState } from "react";
import { useTemplate } from "../context/Template/useTemplate";
import useMutationUpdateGlobalCategoriesAccount from "../hooks/useMutationUpdateGlobalCategoriesAccount";

import GlobalAccountantingAutoComplete from "components/GlobalAccountantingAutoComplete";
import {
  GlobalAccountanting as InterfaceGlobalAccountanting,
  GlobalCategoryType,
} from "types/Accountant";
import useMutationUpdateCompanyChartOfAccounts from "../hooks/useMutationUpdateCompanyChartOfAccounts";

export type CompanyAccountantingType = "account" | "account2" | "creditAccountCx" | "debtAccountCx";

type AccountantingType = GlobalCategoryType | CompanyAccountantingType;

const serviceType: Record<CompanyAccountantingType, string> = {
  account: "debtAccount",
  account2: "creditAccount",
  creditAccountCx: "creditAccountCx",
  debtAccountCx: "debtAccountCx",
};

interface GlobalAccountantingProps {
  type: AccountantingType;
  account: string | null;
  groupId: number;
  id: number;
  name: string;
  othersAccount?: {
    debtAccount: string | null;
    creditAccount: string | null;
    creditAccountCx: string | null;
    debtAccountCx: string | null;
  };
}

const GlobalAccountanting: FC<GlobalAccountantingProps> = ({
  type,
  account,
  groupId,
  id,
  name,
  othersAccount,
}) => {
  const { isCompany, companyToken } = useTemplate();
  const previosGlobalAccountant = useRef<InterfaceGlobalAccountanting | null>(null);

  const { isLoading: loadingUpdate, mutateAsync: mutationUpdate } =
    useMutationUpdateGlobalCategoriesAccount();
  const { isLoading: loadingUpdateCompany, mutateAsync: mutationUpdateCompany } =
    useMutationUpdateCompanyChartOfAccounts();
  const isLoading = loadingUpdate || loadingUpdateCompany;

  const [value, setValue] = useState<null | InterfaceGlobalAccountanting>(null);
  const [inputValue, setInputValue] = useState(account || "");

  const handleUpdate = async () => {
    if (isLoading || previosGlobalAccountant.current?.id === value?.id || !value) return;

    if (!isCompany && type.length === 2) {
      await mutationUpdate({
        account: value.name,
        accountId: value.id,
        id,
        type: type as GlobalCategoryType,
      });
    } else {
      await mutationUpdateCompany({
        categoryId: id,
        ...othersAccount,
        [serviceType[type as CompanyAccountantingType]]: value.name,
        companyToken: companyToken as string,
        id: value.id,
        type: 0,
        name,
      });
    }

    previosGlobalAccountant.current = value;
  };

  const getPlaceholder = () => {
    return (
      {
        account: "Débito",
        account2: "Crédito",
        DP: "Débito",
        DC: "Crédito",
        creditAccountCx: "Crédito",
        CC: "Crédito",
        debtAccountCx: "Débito",
        CP: "Débito",
      }[type] || type
    );
  };

  useEffect(() => {
    setInputValue(account ?? "");
  }, [account]);

  return (
    <GlobalAccountantingAutoComplete
      size="small"
      disabled={isLoading}
      fullWidth
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue);
      }}
      textFieldProps={{ placeholder: getPlaceholder() }}
      groupId={groupId}
      onBlur={handleUpdate}
      onSelect={handleUpdate}
      inputValue={inputValue}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === "reset" && newInputValue === "") return;
        setInputValue(newInputValue);
      }}
      isCompany={isCompany}
      companyToken={companyToken}
    />
  );
};

export default GlobalAccountanting;
