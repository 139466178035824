import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { updateFromTo } from "services/Requests/accountant";

const useMutationUpdateFromTo = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateFromTo, {
    onSuccess(_, { groupChartOfAccountsToFromId }) {
      toast.success("Conta contábil atualizada com sucesso!");
      queryClient.invalidateQueries([
        queryKeys.chartOfAccountsFromToGroup,
        groupChartOfAccountsToFromId,
      ]);
    },
  });
  return mutation;
};

export default useMutationUpdateFromTo;
