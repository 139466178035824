import { FC, useState } from "react";
import useMutationUpdateLayout from "./hooks/useMutationUpdateLayout";

import { Box, Button, Collapse, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { Layout } from "types/Template";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";
import ColumnItem from "./ColumnItem";

interface ColumnsProps {
  layouts: Layout[];
  companyToken: string;
  id: number;
}

const Columns: FC<ColumnsProps> = ({ layouts, companyToken, id }) => {
  const [collapsed, setCollapsed] = useState(true);
  const { mutate, isLoading } = useMutationUpdateLayout();

  const handleDeleteColumn = (layout: Layout) => {
    mutate({
      companyToken,
      id,
      items: layouts.filter((l) => l.id !== layout.id),
    });
  };

  const handleEditLayout = (layout: Layout) => {
    mutate({
      companyToken,
      id,
      items: layouts.map((l) => (l.id === layout.id ? layout : l)),
    });
  };

  return (
    <>
      <Box overflow="hidden" borderRadius={1} border={1} borderColor="divider">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          bgcolor="#fff"
          p={2}
          borderBottom={1}
          borderColor={grey[100]}
        >
          <Button
            sx={{ color: "text.primary", textAlign: "left" }}
            size="small"
            startIcon={
              <ArrowDropDownIcon
                fontSize="small"
                sx={{ transform: `rotate(${collapsed ? 0 : -90}deg)` }}
              />
            }
            onClick={() => setCollapsed((prev) => !prev)}
          >
            Colunas
          </Button>
        </Stack>

        <Collapse in={collapsed}>
          <Stack gap={1} p={2} bgcolor={grey[100]}>
            {layouts
              .filter((l) => !!l.parentId)
              .map((layout) => (
                <ColumnItem
                  key={layout.id}
                  layout={layout}
                  onDelete={handleDeleteColumn}
                  onEdit={handleEditLayout}
                />
              ))}
          </Stack>
        </Collapse>
      </Box>
      {isLoading && <FullscreenLoadingIndicator />}
    </>
  );
};

export default Columns;
