import { FC, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grow,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import Search from "@mui/icons-material/Search";

import { Group } from "types/Mailbox";
import GroupTableItem from "./GroupTableItem";
import CreateOrUpdateGroup from "./CreateOrUpdateGroup";

import useQueryGetGroups from "./hooks/useQueryGetGroups";
import { useAuth } from "context/Auth";
import toast from "react-hot-toast";

const Solicitation: FC = () => {
  const {
    user: { company },
  } = useAuth();
  const [searchSolicitation, setSearchSolicitation] = useState("");
  const [createGroupIsOpen, setCreateGroupIsOpen] = useState(false);
  const [groupEditing, setGroupEditing] = useState<null | Group>(null);

  const { data: groups, isLoading, isError, isFetching } = useQueryGetGroups(searchSolicitation);

  const openCreateGroup = () => {
    if (!company) {
      toast("Empresa não localizada", {
        icon: "⚠️",
      });
      return;
    }
    setCreateGroupIsOpen(true);
  };

  const closeCreateGroup = () => {
    setGroupEditing(null);
    setCreateGroupIsOpen(false);
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} mb={4}>
        <Button
          sx={{ whiteSpace: "nowrap" }}
          variant="contained"
          startIcon={<Add />}
          onClick={openCreateGroup}
        >
          Novo grupo
        </Button>

        <TextField
          size="small"
          value={searchSolicitation}
          sx={{ flex: 1 }}
          onChange={(e) => setSearchSolicitation(e.target.value)}
          InputProps={{
            placeholder: "Buscar grupo",
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Box position="relative">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome do grupo</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Stack alignItems="center" justifyContent="center">
                      <CircularProgress />
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
              {groups && !groups.length && (
                <TableRow>
                  <TableCell colSpan={2}>Nenhum grupo encontrado</TableCell>
                </TableRow>
              )}
              {isError && (
                <TableRow>
                  <TableCell colSpan={3}>Ocorreu um error ao carregar os grupos</TableCell>
                </TableRow>
              )}

              {groups &&
                groups.map((group) => (
                  <GroupTableItem
                    key={group.id}
                    group={group}
                    onEdit={() => {
                      openCreateGroup();
                      setGroupEditing(group);
                    }}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Grow in={isFetching && !isLoading}>
          <Stack
            position="absolute"
            top={50}
            left={0}
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <CircularProgress />
          </Stack>
        </Grow>
      </Box>

      {company && createGroupIsOpen && (
        <CreateOrUpdateGroup
          keepMounted={false}
          group={groupEditing || undefined}
          companyToken={company.token}
          open={createGroupIsOpen}
          onClose={closeCreateGroup}
        />
      )}
    </Box>
  );
};

export default Solicitation;
