import * as yup from "yup";

const validationSchema = yup.object().shape({
  ofxFile: yup.mixed().test("testType", "O arquivo de importação é obrigatório", (v) => {
    if (!(v instanceof FileList)) {
      return false;
    }
    return v.length === 1;
  }),
  bankId: yup
    .number()
    .required("A conta para importação é obrigatória")
    .typeError("A conta para importação é obrigatória"),
});

export default validationSchema;
