import { forwardRef, MouseEvent, Ref } from "react";

import { Button, StandardTextFieldProps, TextField } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

interface TextFieldCounterInnerProps extends Omit<StandardTextFieldProps, "InputProps"> {
  onAdd: (e?: MouseEvent<HTMLButtonElement>) => void;
  onRemove: (e?: MouseEvent<HTMLButtonElement>) => void;
}

const TextFieldCounterInner = (
  { onAdd, onRemove, ...props }: TextFieldCounterInnerProps,
  ref: Ref<unknown>,
) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      variant="standard"
      sx={{
        ...props.sx,
        "& input[type=number]": {
          MozAppearance: "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      }}
      inputProps={{
        ...props?.inputProps,
        sx: {
          ...props?.inputProps?.sx,
          textAlign: "center",
        },
      }}
      InputProps={{
        startAdornment: (
          <Button
            disabled={Number(props.value) === 0}
            size="small"
            variant="contained"
            color="error"
            sx={{ minWidth: 32 }}
            onClick={onRemove}
          >
            <RemoveIcon fontSize="small" />
          </Button>
        ),
        endAdornment: (
          <Button
            sx={{ minWidth: 32 }}
            size="small"
            variant="contained"
            color="success"
            onClick={onAdd}
          >
            <AddIcon fontSize="small" />
          </Button>
        ),
      }}
    />
  );
};

const TextFieldCounter = forwardRef(TextFieldCounterInner);
export default TextFieldCounter;
