import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { updateProfile } from "services/Requests/registration";

const useMutationUpdateProfile = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.registrationProfile);
    },
  });
  return mutation;
};

export default useMutationUpdateProfile;
