import useMutationUpdateFinancialCategory from "../../hooks/useMutationUpdateFinancialCategory";
import useMutationCreateFinancialCategory from "../../hooks/useMutationCreateFinancialCategory";
import useMutationCreateCompanyFinancialCategory from "../../hooks/useMutationCreateCompanyFinancialCategory";
import { useForm, Controller } from "react-hook-form";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { FinancialCategoryByGroup } from "types/Company";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import useMutationUpdateCompanyFinancialCategory from "../../hooks/useMutationUpdateCompanyFinancialCategory";

interface DialogCreateOrUpdateSyntheticCategoryProps<T extends boolean>
  extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  templateId?: T extends true ? undefined : number;
  companyToken?: T extends true ? string : undefined;
  companyId?: T extends true ? number : undefined;
  financialCategory?: FinancialCategoryByGroup;
  isCompany?: T;
}

function DialogCreateOrUpdateSyntheticCategory<T extends boolean>({
  onClose,
  templateId,
  financialCategory,
  isCompany = false as T,
  companyToken,
  companyId,
  ...props
}: DialogCreateOrUpdateSyntheticCategoryProps<T>) {
  const { mutateAsync: mutateCreateFinancial } = useMutationCreateFinancialCategory();
  const { mutateAsync: mutateCreateCompanyFinancial } = useMutationCreateCompanyFinancialCategory();

  const { mutateAsync: mutateUpdateFinancial } = useMutationUpdateFinancialCategory();
  const { mutateAsync: mutateUpdateCompanyFinancial } = useMutationUpdateCompanyFinancialCategory();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: financialCategory?.name || "",
      nature: financialCategory?.nature || undefined,
    },
  });

  const handleClose = () => {
    if (isSubmitting) return;
    reset({
      name: financialCategory?.name || "",
      nature: financialCategory?.nature || undefined,
    });
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    const nature: undefined | "credit" | "debit" = data.nature
      ? data.nature === "Crédito"
        ? "credit"
        : "debit"
      : undefined;

    const runMutations = async () => {
      if (financialCategory) {
        if (isCompany) {
          await mutateUpdateCompanyFinancial({
            companyToken: companyToken as string,
            companyId: companyId as number,
            nature,
            id: financialCategory.id,
            isVisibleFinancialReport: financialCategory.isVisibleFinancialReport,
            name: data.name,
            parentId: financialCategory.parentCategoryId,
            type: "container",
          });
        } else {
          await mutateUpdateFinancial({
            groupId: templateId as number,
            nature,
            id: financialCategory.id,
            isVisibleFinancialReport: financialCategory.isVisibleFinancialReport,
            name: data.name,
            parentId: financialCategory.parentCategoryId,
            type: "container",
          });
        }
      } else {
        if (isCompany) {
          await mutateCreateCompanyFinancial({
            companyToken: companyToken as string,
            nature,
            name: data.name,
          });
        } else {
          await mutateCreateFinancial({
            groupId: templateId as number,
            nature,
            name: data.name,
          });
        }
      }
    };

    await runMutations();
    handleClose();
  });

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="md" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {financialCategory ? "Editar" : "Adicionar"} categoria sintética
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Nome da categoria sintética"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                fullWidth
                sx={{ mb: 2 }}
              />
            )}
          />

          <Controller
            name="nature"
            control={control}
            render={({ field }) => (
              <FormControl>
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="Crédito"
                    control={<Radio size="small" />}
                    label="Entrada"
                  />
                  <FormControlLabel value="Débito" control={<Radio size="small" />} label="Saída" />
                </RadioGroup>
              </FormControl>
            )}
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" loading={isSubmitting} variant="contained">
            Salvar
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose} disabled={isSubmitting}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DialogCreateOrUpdateSyntheticCategory;
