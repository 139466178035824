import { FC } from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Route, Routes, HashRouter } from "react-router-dom";
import queryClient from "./react-query/queryClient";

import { Settings } from "luxon";
import { CompanyInfoProvider } from "./context/CompanyInfo";
import { AuthProvider } from "./context/Auth";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AdapterLuxon from "@mui/lab/AdapterLuxon";

import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// components
import Home from "./pages/Home";
import Login from "pages/Auth/Login";
import Register from "pages/Auth/Register";
import Forgot from "pages/Auth/Forgot";

// styles
import GlobalStyles from "./styles/GlobalStyles";
import ProtectRouter from "./components/ProtectRouter";

const App: FC = () => {
  Settings.defaultLocale = "pt-br";

  return (
    <DndProvider backend={HTML5Backend}>
      <LocalizationProvider dateAdapter={AdapterLuxon} locale="pt-br">
        <QueryClientProvider client={queryClient}>
          <HashRouter>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
              <CompanyInfoProvider>
                <AuthProvider>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/cadastro" element={<Register />} />
                    <Route path="/cadastro-contador" element={<Register accountant />} />
                    <Route path="/redefinir-senha" element={<Forgot />} />
                    <Route
                      path="/*"
                      element={
                        <ProtectRouter>
                          <Home />
                        </ProtectRouter>
                      }
                    />
                  </Routes>
                </AuthProvider>
              </CompanyInfoProvider>
            </Worker>
          </HashRouter>

          <GlobalStyles />
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </LocalizationProvider>
    </DndProvider>
  );
};

export default App;
