import { OrderStatus } from "types/Company";

const getOrderStatusLabel = (status: OrderStatus): string => {
  const labels: { [x in OrderStatus]: string } = {
    canceled: "Cancelada",
    paid: "Pago",
    pending: "Pendente",
    refunded: "Reembolsado",
    waiting: "Aguardando emissão",
    expired: "Expirado",
  };

  return labels[status];
};

export default getOrderStatusLabel;
