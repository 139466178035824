import { FC, memo, useState } from "react";
import useQueryBills from "../../hooks/useQueryBills";

import { Box, Button, CircularProgress, Grow, Skeleton, Stack } from "@mui/material";
import Add from "@mui/icons-material/Add";

import InvoicesTable from "./InvoicesTable";
import NewInvoiceForm from "./NewInvoiceForm";
import { Bill } from "types/Billing";

interface InvoicesProps {
  companyToken: string;
  payerCompanyId: number;
  ownerId: number;
}

const Invoices: FC<InvoicesProps> = ({ companyToken, payerCompanyId, ownerId }) => {
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [duplicateBill, setDuplicateBill] = useState<Bill | null>(null);
  const { isLoading, isFetching, data: bills } = useQueryBills(payerCompanyId, companyToken);

  return (
    <Box position="relative">
      {isLoading && (
        <>
          <Stack direction="row" gap={2} sx={{ span: { flex: 1 } }} mb={1}>
            <Skeleton variant="text" height={25} />
            <Skeleton variant="text" height={25} />
            <Skeleton variant="text" height={25} />
            <Skeleton variant="text" height={25} />
            <Skeleton variant="text" height={25} />
            <Skeleton variant="text" height={25} />
          </Stack>

          <Skeleton variant="text" height={30} />
        </>
      )}

      {isFetching && !isLoading && (
        <Box position="absolute" top={0} right={0} zIndex={5}>
          <CircularProgress size={15} />
        </Box>
      )}

      {bills && (
        <InvoicesTable
          bills={bills}
          payerCompanyId={payerCompanyId}
          companyToken={companyToken}
          onInvoiceSelected={(bill) => setDuplicateBill(bill)}
        />
      )}

      {!isLoading && (
        <Box mt={2}>
          <Button
            onClick={() => setFormIsOpen((prev) => !prev)}
            variant="contained"
            size="small"
            startIcon={<Add fontSize="small" />}
            disableElevation
          >
            Nova fatura avulsa
          </Button>

          <Grow in={formIsOpen || !!duplicateBill} mountOnEnter unmountOnExit>
            <Box>
              {formIsOpen && (
                <NewInvoiceForm
                  ownerId={ownerId}
                  payerCompanyId={payerCompanyId}
                  companyToken={companyToken}
                  onCancel={() => setFormIsOpen(false)}
                />
              )}
              {duplicateBill && (
                <NewInvoiceForm
                  ownerId={ownerId}
                  payerCompanyId={payerCompanyId}
                  companyToken={companyToken}
                  onCancel={() => setDuplicateBill(null)}
                  bill={duplicateBill}
                />
              )}
            </Box>
          </Grow>
        </Box>
      )}
    </Box>
  );
};

export default memo(Invoices);
