import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getAllSubAccounts } from "services/Requests/marketplace";

const useQueryAllSubAccounts = () => {
  const query = useQuery(queryKeys.allSubAccounts, getAllSubAccounts);
  return query;
};

export default useQueryAllSubAccounts;
