import { useCallback } from "react";
import { useQuery } from "react-query";

import queryKeys from "react-query/constants";

import { getTransactions, TransactionAction } from "services/Requests/companies";
import { DateTypeFilter, FinancialCategory, Transactions } from "types/Company";
import { ISODate } from "types/SemanticTypes";

import filterByCategory from "../utils/filterByCategory";
import filterByCompanyParticipant from "../utils/filterByCompanyParticipant";

const useQueryGetTransactions = (
  companyToken: string,
  startDate: ISODate,
  endDate: ISODate,
  action: TransactionAction = "FC",
  accountBankId?: number | null,
  search = "",
  categoryFilter?: FinancialCategory | null,
  companyParticipantFilter?: number | null,
  dateTypeFilter?: DateTypeFilter | null,
) => {
  const queryKey: (number | string)[] = [queryKeys.transactions, startDate, endDate, action];

  if (accountBankId) queryKey.push(accountBankId);
  if (dateTypeFilter) queryKey.push(dateTypeFilter);

  const selectorFn = useCallback(
    (data: Transactions) => {
      let transactionsItems = data.items;

      if (search && search.trim()) {
        transactionsItems = transactionsItems.filter((item) => {
          return item.description.toLowerCase().search(search.toLowerCase()) !== -1;
        });
      }

      if (categoryFilter) {
        transactionsItems = filterByCategory(transactionsItems, categoryFilter);
      }

      if (companyParticipantFilter) {
        transactionsItems = filterByCompanyParticipant(transactionsItems, companyParticipantFilter);
      }

      return {
        ...data,
        items: transactionsItems,
      };
    },
    [search, categoryFilter, companyParticipantFilter],
  );

  const query = useQuery(
    queryKey,
    () =>
      getTransactions({
        companyToken,
        action,
        endDate,
        startDate,
        bankAccountId: accountBankId,
        dateType: dateTypeFilter,
      }),
    {
      keepPreviousData: true,
      select: search === undefined ? undefined : selectorFn,
    },
  );
  return query;
};

export default useQueryGetTransactions;
