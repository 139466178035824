import { FC, useMemo } from "react";
import { Control, Controller, useWatch } from "react-hook-form";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MovementForm } from "../utils/InterfacesForm";
import TotalValue from "./TotalValue";
import PresenceIndicatorSelect from "pages/Home/components/PresenceIndicatorSelect";

interface AdditionalInformationProps {
  control: Control<MovementForm>;
  isConsultation: boolean;
}

const AdditionalInformation: FC<AdditionalInformationProps> = ({ control, isConsultation }) => {
  const sendInvoice = useWatch({
    control,
    name: "sendInvoice",
  });
  const isInvoice = useMemo(() => sendInvoice === "1" || sendInvoice === "2", [sendInvoice]);

  const labelNotesField = useMemo(() => {
    const label = "Informações adicionais da movimentação";
    if (!isInvoice) return label;

    return label + " / Descrição da " + (sendInvoice == "1" ? "NF-e" : "NFC-e");
  }, [sendInvoice, isInvoice]);

  return (
    <Box display="grid" gap={2} gridTemplateColumns="repeat(6, 1fr)" alignItems="center">
      <Controller
        name="notes"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            value={field.value || ""}
            label={labelNotesField}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            sx={{ gridColumn: "span 3" }}
            disabled={isConsultation}
          />
        )}
      />

      {isInvoice && (
        <>
          <Controller
            name="presenceIndicator"
            control={control}
            render={({ field, fieldState }) => (
              <PresenceIndicatorSelect
                {...field}
                disabled={isConsultation}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                FormControlProps={{
                  sx: { gridColumn: "span 2" },
                }}
                label="Indicador de Presença do Consumidor"
                type={sendInvoice == "1" ? "NFe" : "NFCe"}
              />
            )}
          />

          <Controller
            name="finalConsumer"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl disabled={isConsultation} error={!!fieldState.error?.message}>
                <InputLabel>Consumidor</InputLabel>
                <Select {...field} label="Consumidor" value={field.value || ""}>
                  <MenuItem disabled value="">
                    Selecione:{" "}
                  </MenuItem>
                  <MenuItem value="1">Final</MenuItem>
                  {sendInvoice == "1" && <MenuItem value="0">Normal</MenuItem>}
                </Select>
                {!!fieldState.error?.message && (
                  <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </>
      )}

      <TotalValue control={control} />

      <Controller
        name="generateTransaction"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            sx={{ m: 0, height: "auto", gridColumn: "1/4", gridRow: "2" }}
            control={
              <Checkbox
                onBlur={field.onBlur}
                onChange={(e) => {
                  field.onChange(e);
                }}
                checked={field.value}
                value={field.value}
                inputRef={field.ref}
                disabled={isConsultation}
              />
            }
            label="Gerar Lançamento"
          />
        )}
      />
    </Box>
  );
};

export default AdditionalInformation;
