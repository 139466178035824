import { useCallback } from "react";
import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getGroups } from "services/Requests/mailbox";
import { Group } from "types/Mailbox";
import getSubdomain from "utils/getSubdomain";

const useQueryGetGroups = (search = "") => {
  const subdomain = getSubdomain();

  const selectSearch = useCallback(
    (data: Group[]) => {
      const searchGroup = (name: string) => {
        if (!search.trim()) return true;

        return name.toLowerCase().search(search.toLowerCase()) !== -1;
      };

      return data.filter((group) => searchGroup(group.description));
    },
    [search],
  );

  const query = useQuery(queryKeys.groups, () => getGroups({ subdomain }), {
    select: search ? selectSearch : undefined,
  });
  return query;
};

export default useQueryGetGroups;
