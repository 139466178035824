import endpoints from "../endpoints";
import api from "../api";
import {
  CNAEs,
  CompanyActivities,
  Order,
  OrderAccountant,
  Orders,
  ProfileOpenCompany,
} from "types/OpenCompany";
import { CompanyInformation } from "types/Company";

export const ordersByAssignments = async (): Promise<Orders> => {
  const { data } = await api.get<Orders>(endpoints.openCompany.ordersByAssignments);
  return data;
};

export const getLegals = async (): Promise<OrderAccountant[]> => {
  const { data } = await api.get<OrderAccountant[]>(endpoints.openCompany.getLegals);
  return data;
};

export type DeleteOrderPayload = Order;

export const deleteOrder = async (payload: DeleteOrderPayload) => {
  await api.post(endpoints.openCompany.deleteOrder, payload);
};

export type ChangeStateOrderOpenCompanyPayload = Order;

export const changeStateOrderOpenCompany = async (payload: ChangeStateOrderOpenCompanyPayload) => {
  await api.post(endpoints.openCompany.changeStateOrderOpenCompany, payload);
};

export interface GetProfilePayload {
  companyToken: string;
}

export const getProfile = async (payload: GetProfilePayload) => {
  const { data } = await api.post<ProfileOpenCompany>(endpoints.openCompany.getProfile, payload);
  return data;
};

export const getCompanyActivities = async () => {
  const { data } = await api.get<CompanyActivities>(endpoints.openCompany.getCompanyActivities);
  return data;
};

export interface GetCNAEsByActivityPayload {
  companyActivityId: number;
}

export const getCNAEsByActivity = async (payload: GetCNAEsByActivityPayload) => {
  const { data } = await api.post<CNAEs>(endpoints.openCompany.getCNAEsByActivity, payload);
  return data;
};

export interface UpdateCompanyPayload extends CompanyInformation {
  subdomain: string;
}

export const updateCompany = async (payload: UpdateCompanyPayload) => {
  const { data } = await api.post<boolean>(endpoints.openCompany.updateCompany, payload);
  return data;
};

export interface AddOrderOpenCompanyPayload {
  companyToken: string;
  orderState: number;
  typeOrder: number;
  paymentState: number;
  parcels: string;
  typeService: number;
  value: number;
  numberEmployees?: number;
  numberPartners?: number;
  numberProlabore?: number;
  planId?: string;
  tributationType?: number;
}

export const addOrderOpenCompany = async (payload: AddOrderOpenCompanyPayload) => {
  const { data } = await api.post<boolean>(endpoints.openCompany.addOrderOpenCompany, payload);
  return data;
};

export interface GetOrdersByCompanyPayload {
  companyToken: string;
}

export const getOrdersByCompany = async (payload: GetOrdersByCompanyPayload) => {
  const { data } = await api.post<Orders>(endpoints.openCompany.getOrdersByCompany, payload);
  return data;
};
