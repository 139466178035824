import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getBanks } from "services/Requests/commonData";

const useQueryBanks = () => {
  const query = useQuery(queryKeys.bank, getBanks, {
    staleTime: 1000 * 60 * 30, // 30 minutes
    cacheTime: 1000 * 60 * 60, // 1 hour
  });
  return query;
};

export default useQueryBanks;
