import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { updateCompanyStyles } from "services/Requests/marketplace";

import toast from "react-hot-toast";

const useMutationUpdateCompanyStyles = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCompanyStyles, {
    onSuccess() {
      toast.success("O visual da empresa foi atualizado com sucesso");
      queryClient.invalidateQueries(queryKeys.styles, {
        exact: false,
      });
    },
  });
  return mutation;
};

export default useMutationUpdateCompanyStyles;
