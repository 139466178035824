import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCompanyPartners } from "services/Requests/companies";

const useQueryCompanyPartners = (companyToken: string) => {
  const query = useQuery([queryKeys.companyPartners, companyToken], () =>
    getCompanyPartners({ companyToken }),
  );

  return query;
};

export default useQueryCompanyPartners;
