import { FC, useState } from "react";
import { SideMenuContext } from ".";
import useLocalStorage from "hooks/useLocalStorage";

interface SideMenuProviderProps {
  children: React.ReactNode;
}

export const SideMenuProvider: FC<SideMenuProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isCompany, setIsCompany] = useLocalStorage("isCompanyView", true);

  return (
    <SideMenuContext.Provider value={{ isOpen, setIsOpen, isCompany, setIsCompany }}>
      {children}
    </SideMenuContext.Provider>
  );
};
