import { FC, useState } from "react";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DialogConfigLeads from "./DialogConfigLeads";

const ConfigLeads: FC = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const closeDialog = () => setDialogIsOpen(false);
  const openDialog = () => setDialogIsOpen(true);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        startIcon={<VisibilityIcon fontSize="small" />}
        onClick={openDialog}
      >
        Configurar visualizações
      </Button>

      <DialogConfigLeads open={dialogIsOpen} onClose={closeDialog} />
    </>
  );
};

export default ConfigLeads;
