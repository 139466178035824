import { CompanyPartner, NewCompanyPartner } from "types/Company";
import { PartnerFields } from "../PartnerForm";

const create = (partner?: Partial<CompanyPartner>): PartnerFields => ({
  cpf: partner?.cpfcnpj ?? "",
  name: partner?.name ?? "",
  share: partner?.share ?? 0,
  partnerId: partner?.id,
});

const insert = (partner: PartnerFields, responsible: number, index: number): NewCompanyPartner => ({
  name: partner.name,
  share: partner.share,
  cpfcnpj: partner.cpf.replace(/\D/g, ""),
  inCharge: index === responsible,
  updateAction: "Insert",
});

const edited = (
  partners: PartnerFields[],
  responsible: number,
  oldPartners: CompanyPartner[] | undefined,
): CompanyPartner[] => {
  if (!oldPartners) return [];

  return oldPartners.map((partner) => {
    const partnerEdited = partners.find((p) => p.partnerId === partner.id);
    const partnerEditedIndex = partners.findIndex((p) => p.partnerId === partner.id);

    if (!partnerEdited) {
      return {
        ...partner,
        updateAction: "Delete",
      };
    }

    const edited: CompanyPartner = {
      ...partner,
      name: partnerEdited.name,
      share: partnerEdited.share,
      cpfcnpj: partnerEdited.cpf.replace(/\D/g, ""),
      inCharge: partnerEditedIndex === responsible,
      updateAction: "Update",
    };

    return edited;
  });
};

export { create, edited, insert };
