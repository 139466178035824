import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getPaymentModes } from "services/Requests/stockControl";

const useQueryPaymentModes = () => {
  const query = useQuery(queryKeys.paymentModes, () => getPaymentModes());
  return query;
};

export default useQueryPaymentModes;
