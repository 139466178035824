import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { updateCompanyStatus } from "services/Requests/admin";

const useMutationUpdateCompanyStatus = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCompanyStatus, {
    onSuccess(_, { status }) {
      toast.success(`Empresa ${status === -1 ? "desativada" : "ativada"} com sucesso!`);
      queryClient.invalidateQueries(queryKeys.companyAssignments, {
        exact: false,
      });
    },
  });
  return mutation;
};

export default useMutationUpdateCompanyStatus;
