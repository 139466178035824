import { FC } from "react";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { CompanyAssignments } from "types/Admin";
import { Company as ICompany } from "types/Company";
import Form from "./Form";

interface CompanyProps {
  company: CompanyAssignments | ICompany;
}

const Company: FC<CompanyProps> = ({ company }) => {
  const { data: companyInformation, isLoading, isError } = useQueryGetCompany(company.token);

  return (
    <Box>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}

      {isError && (
        <Typography>
          Ocorreu um erro ao tentar pegar os dados da empresa, por favor, tente mais tarde
        </Typography>
      )}

      {!isLoading && companyInformation && <Form company={companyInformation} />}
    </Box>
  );
};

export default Company;
