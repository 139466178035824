import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { updateIntegratorAccount } from "services/Requests/companies";

const useMutationUpdateIntegratorAccount = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateIntegratorAccount, {
    onSuccess(_, { companyToken }) {
      toast.success("Conta integrada com sucesso, agora os lançamentos já podem ser importados.");
      queryClient.invalidateQueries([queryKeys.companyBankAccounts, companyToken]);
    },
  });
  return mutation;
};

export default useMutationUpdateIntegratorAccount;
