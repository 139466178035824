import { FC, useMemo, useState } from "react";
import useMutationCreateCorrection from "../hooks/useMutationCreateCorrection";
import { Controller, DefaultValues, useForm } from "react-hook-form";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import TabPanel from "components/TabPanel";

import { CorrectionResponse } from "types/StockControl";
import { DialogInvoiceCorrectionProps } from ".";
import CorrectionIssued from "./CorrectionIssued";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

interface CorrectionForm {
  number: number;
  accessKey: string;
  description: string;
}

interface DialogAfterDataProps extends DialogInvoiceCorrectionProps {
  invoiceCorrection: CorrectionResponse;
}

const DialogAfterData: FC<DialogAfterDataProps> = ({
  companyToken,
  movementId,
  invoiceCorrection,
  onClose,
  ...props
}) => {
  const { mutateAsync } = useMutationCreateCorrection();

  const [activeTab, setActiveTab] = useState(0);

  const defaultValues = useMemo<DefaultValues<CorrectionForm>>(
    () => ({
      number: undefined,
      accessKey: invoiceCorrection.accessKey ?? "",
      description: "",
    }),
    [invoiceCorrection],
  );

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
  } = useForm<CorrectionForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleClose = () => {
    if (isSubmitting) return;
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      companyToken,
      movementId,
      accessKey: data.accessKey,
      description: data.description,
      id: 0,
      number: Number(data.number),
    });
    reset(defaultValues);
    setActiveTab(1);
  });

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Carta de Correção
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Emitir" />
            <Tab label="Emitidas" />
          </Tabs>

          <TabPanel
            value={activeTab}
            index={0}
            keepMounted
            sx={{ border: 1, borderColor: "divider" }}
          >
            <Box display="grid" gap={2}>
              <Box display="grid" gridTemplateColumns="1fr 3fr" gap={2}>
                <Controller
                  control={control}
                  name="number"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Número"
                      type="number"
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="accessKey"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Chave de Acesso"
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>

              <Controller
                control={control}
                name="description"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Descrição"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
          </TabPanel>

          <TabPanel
            value={activeTab}
            index={1}
            keepMounted
            sx={{ border: 1, borderColor: "divider" }}
          >
            <Box display="grid" gap={3}>
              {invoiceCorrection.corrections.map((correction, i, arr) => (
                <CorrectionIssued
                  key={correction.id}
                  correction={correction}
                  isLast={i === arr.length - 1}
                  companyToken={companyToken}
                  movementId={movementId}
                />
              ))}
            </Box>
          </TabPanel>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            type="submit"
            onClick={() => {
              setActiveTab(0);
            }}
          >
            Salvar
          </LoadingButton>
          <Button disabled={isSubmitting} onClick={handleClose} variant="outlined">
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogAfterData;
