import { FC, useState } from "react";
import useMutationWithdraw from "../hooks/useMutationWithdraw";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import CurrencyInput, { formattedInput } from "components/CurrencyInput";
import { BillingInfo } from "types/Billing";

interface DialogWithdrawProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  onClose: () => void;
  infoAccount: BillingInfo;
}

const DialogWithdraw: FC<DialogWithdrawProps> = ({
  companyToken,
  infoAccount,
  onClose,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationWithdraw();
  const [alert, setAlert] = useState<null | string>(null);
  const [amount, setAmount] = useState("");

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleWithdrawal = async () => {
    const formattedAmount = formattedInput(amount);
    const balance = formattedInput(infoAccount.balance);

    if (balance === 0 || balance < formattedAmount || formattedAmount === 0) {
      setAlert("Saldo insuficiente para retirada!");
      return;
    }

    if (formattedAmount < 5) {
      setAlert("O valor da retirada tem que ser no mínimo 5!");
      return;
    }

    await mutateAsync({
      companyToken,
      amount: formattedAmount,
    });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Solicitar Retirada
      </DialogTitleWithCloseButton>

      <DialogContent>
        {!!alert && (
          <Alert severity="error" sx={{ mb: 3 }} onClose={() => setAlert(null)}>
            {alert}
          </Alert>
        )}

        <TextField value={infoAccount.balance} disabled fullWidth label="Saldo Disponível" />

        <CurrencyInput
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          inputProps={{
            label: "Valor para retirar",
            sx: { mt: 2 },
            fullWidth: true,
          }}
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={handleWithdrawal}
          loading={isLoading}
          variant="contained"
          startIcon={<ImportExportIcon />}
        >
          Retirar
        </LoadingButton>
        <Button
          disabled={isLoading}
          variant="outlined"
          startIcon={<CloseIcon />}
          onClick={handleClose}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogWithdraw;
