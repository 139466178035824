import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { forgotPassword } from "../../../../services/Requests/registration";

const useForgotPassword = () => {
  const navigate = useNavigate();

  const mutation = useMutation(forgotPassword, {
    onSuccess(data) {
      if (data?.result === "NotFound") {
        toast.error("Email não foi encontrado.");
        return;
      }

      if (data?.result === "Success") {
        toast.success(
          "Nova senha gerada com sucesso. Verifique sua caixa de entrada e utilize a senha enviada. Para sua segurança, altere sua senha no primeiro acesso.",
        );
        navigate("/login");
        return;
      }
    },
  });

  return mutation;
};

export default useForgotPassword;
