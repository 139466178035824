import { useCallback } from "react";
import { useQuery } from "react-query";

import queryKeys from "react-query/constants";
import { ordersByAssignments } from "services/Requests/openCompany";
import { Order, Orders, OrderState } from "types/OpenCompany";

const useQueryOrdersByAssignments = (orderStateFilter?: OrderState, search = "") => {
  const selectFn = useCallback(
    (data: Orders) => {
      const applyOrderStateFilter = (order: Order) => {
        if (orderStateFilter === undefined) return true;
        if (orderStateFilter === 0 && ![11, 12].includes(order.orderState)) return true;

        return orderStateFilter === order.orderState;
      };

      const searchOrder = (order: Order) => {
        if (!search.trim()) return true;
        return order.companyName.toLowerCase().search(search.toLowerCase()) !== -1;
      };

      return data.filter((order) => applyOrderStateFilter(order) && searchOrder(order));
    },
    [orderStateFilter, search],
  );

  const query = useQuery(queryKeys.ordersByAssignments, ordersByAssignments, {
    select: selectFn,
  });
  return query;
};

export default useQueryOrdersByAssignments;
