import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getLegals } from "services/Requests/openCompany";

const useQueryLegals = () => {
  const query = useQuery(queryKeys.getLegals, getLegals);
  return query;
};

export default useQueryLegals;
