import { FC, useMemo } from "react";
import useMutationCreateConfiguration from "./hooks/useMutationCreateConfiguration";
import { DefaultValues, useForm } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";
import { Alert, Box, Stack } from "@mui/material";

import ControllerServices from "./ControllerServices";
import Certificate from "./Certificate";
import ConfigRPS from "./ConfigRPS";
import Company from "./Company";
import Access from "./Access";

import { InvoiceConfiguration, PrefectureInvoice } from "types/Invoice";
import { cityHasSpecialRule, createService, specialMessage } from "./utils";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import Aedf from "./Aedf";
import { CreateConfigurationPayload } from "services/Requests/invoice";

export interface InvoiceServiceForm {
  serviceId?: number;
  descricao: string;
  isManual: boolean;
  cnae: string;
  codigo: string;
  lc116: string;
  iss: number;
  irpj: number;
  irop: number;
  pis: number;
  cofins: number;
  csll: number;
}

export interface InvoiceForm {
  insc: string;
  especial: string;
  cultural: string;
  optingSimple: string;
  file: FileList;

  certificatePassword: string;
  prefectureLogin: string;
  prefecturePass: string;
  prefectureToken: string;
  prefecturePhrase: string;
  aedf: string;
  services: InvoiceServiceForm[];

  serie: string;
  nfeSerie: string;
  sequentialNFe: number;
  nextRPS: number;
  nextLot: number;
  description: string;
  notes: string;
  sendInvoice: string;
  sendInvoiceInterval: string;

  // NFC-e
  serieNFCe: string;
  sequentialNFCe: number;
  cscId: string;
  cscCode: string;
}

export type InvoiceType = "NFS-e" | "NF-e" | "NFC-e";

interface InvoiceSetupProps {
  invoiceConfiguration: InvoiceConfiguration;
  prefecture?: PrefectureInvoice;
  prefectureError?: string;
  companyToken: string;
  hasServiceList: boolean;
  isClient?: boolean;
  invoiceType?: InvoiceType;
}

const InvoiceSetup: FC<InvoiceSetupProps> = ({
  invoiceConfiguration,
  prefecture,
  prefectureError,
  companyToken,
  hasServiceList,
  isClient = false,
  invoiceType = "NFS-e",
}) => {
  const { mutateAsync } = useMutationCreateConfiguration();

  const requestCertificate =
    prefecture?.tipoAutenticacao === "1" ||
    prefecture?.assinaturaDigital === "2" ||
    invoiceConfiguration.state === "DF";
  const requestLoginPass =
    prefecture?.tipoAutenticacao === "2" ||
    prefecture?.tipoAutenticacao === "5" ||
    (invoiceType === "NFS-e" && cityHasSpecialRule(invoiceConfiguration.city));
  const requestToken = prefecture?.tipoAutenticacao === "3" || prefecture?.tipoAutenticacao === "5";
  const requestPhrasePass = prefecture?.tipoAutenticacao == "4";

  const servicesDefaultValues = useMemo<DefaultValues<InvoiceServiceForm>[]>(() => {
    return invoiceConfiguration.services.length > 0
      ? invoiceConfiguration.services
          .filter((s) => s.updateAction !== "Delete")
          .map((s) => createService(s, hasServiceList))
      : [createService(undefined, hasServiceList)];
  }, [invoiceConfiguration, hasServiceList]);

  const defaultValues = useMemo<DefaultValues<InvoiceForm>>(
    () => ({
      insc: invoiceConfiguration.insc ?? "",
      especial: invoiceConfiguration.especialRegime ?? "",
      cultural: invoiceConfiguration.culturaIncentive ?? "",
      optingSimple: invoiceConfiguration.optingSimple.toString() ?? "",
      file: undefined,
      prefectureLogin: "",
      prefecturePass: "",
      prefectureToken: "",
      prefecturePhrase: "",
      services: servicesDefaultValues,
      certificatePassword: "",
      serie: invoiceConfiguration.serie ?? "",
      nfeSerie: invoiceConfiguration.serieNFe ?? "",
      sequentialNFe: invoiceConfiguration.sequentialNFe ?? 0,
      nextRPS: invoiceConfiguration.nextRPS ?? 0,
      nextLot: invoiceConfiguration.nextLot ?? 0,
      description: invoiceConfiguration.description ?? "",
      notes: invoiceConfiguration.notes ?? "",
      sendInvoice: invoiceConfiguration.sendInvoice ?? "",
      sendInvoiceInterval: invoiceConfiguration.sendInvoiceInterval ?? "",
      aedf: invoiceConfiguration?.aedf ?? "",
      sequentialNFCe: invoiceConfiguration.sequentialNFCe ?? 0,
      serieNFCe: invoiceConfiguration.serieNFCe ?? "",
      cscId: "",
      cscCode: "",
    }),
    [],
  );

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    register,
    setValue,
  } = useForm<InvoiceForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const onSubmit = handleSubmit(async (data) => {
    let createConfiguration: CreateConfigurationPayload = {
      companyToken,
      password: data.certificatePassword,
      id: invoiceConfiguration.id,
      specialRegime: data.especial,
      cultural: Number(data.cultural),
      simple: Number(data.optingSimple),
      insc: invoiceType === "NFS-e" ? data.insc : invoiceConfiguration.stateRegistration,
      serie: data.serie,
      nextRPS: Number(data.nextRPS),
      nextLot: Number(data.nextLot),
      description: data.description,
      notes: data.notes,
      sendInvoice: data.sendInvoice,
      sendInvoiceInterval: Number(data.sendInvoiceInterval),
      prefectureLogin: data.prefectureLogin,
      prefecturePass: data.prefecturePass,
      prefectureToken: data.prefectureToken,
      prefecturePhrase: data.prefecturePhrase,
      aedf: data.aedf,
      invoiceType: invoiceType === "NFC-e" ? "NF-e" : invoiceType,
      nfeSerie: data.nfeSerie,
      nfeNumber: Number(data.sequentialNFe),
      services: data.services.map(({ serviceId, ...s }) => ({
        ...s,
        id: serviceId,
        updateAction: serviceId ? "Update" : "Insert",
      })),
      file: data.file[0],
    };

    if (invoiceType === "NFC-e") {
      createConfiguration = {
        ...createConfiguration,
        nfceSerie: data.serieNFCe,
        nfceNumber: data.sequentialNFCe,
        cscId: data.cscId,
        cscCode: data.cscCode,
      };
    }

    await mutateAsync(createConfiguration);
  });

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit(e);
      }}
    >
      {!!prefectureError && (
        <Alert sx={{ mb: 2 }} severity="error">
          {prefectureError}
        </Alert>
      )}

      <Box display="grid" gap={3}>
        <Company
          invoiceType={invoiceType}
          control={control}
          prefecture={prefecture}
          invoiceConfiguration={invoiceConfiguration}
          companyToken={companyToken}
        />

        {requestCertificate && (
          <Certificate
            control={control}
            invoiceConfiguration={invoiceConfiguration}
            register={register}
            invoiceType={invoiceType}
          />
        )}

        {(requestLoginPass || requestToken) && (
          <Access
            control={control}
            requestLoginPass={requestLoginPass}
            requestToken={requestToken}
            requestPhrasePass={requestPhrasePass}
            specialMessage={specialMessage(invoiceConfiguration)}
          />
        )}

        {prefecture?.usaAEDF && <Aedf control={control} />}

        {invoiceType === "NFS-e" && (
          <ControllerServices
            control={control}
            companyToken={companyToken}
            invoiceState={invoiceConfiguration.state}
            hasServiceList={hasServiceList}
            setValue={setValue}
            isClient={isClient}
          />
        )}

        <ConfigRPS invoiceType={invoiceType} control={control} isClient={isClient} />
      </Box>

      <Stack alignItems="end" justifyContent="end" p={2} bgcolor={grey[200]} mt={3}>
        <LoadingButton loading={isSubmitting} variant="contained" type="submit">
          Salvar
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default InvoiceSetup;
