import { useCallback } from "react";
import { useQuery } from "react-query";

import queryKeys from "react-query/constants";
import { getUsersBills, GetUsersBillsPayload } from "services/Requests/billing";
import getSubdomain from "utils/getSubdomain";

import { ISODate } from "types/SemanticTypes";
import { UserBill } from "types/Billing";

const useQueryGetUsersBills = (
  companyToken: string,
  startDate: ISODate,
  endDate: ISODate,
  statusFilter: GetUsersBillsPayload["statusFilter"] = null,
  searchBill = "",
) => {
  const selectSearch = useCallback(
    (data: UserBill[]) => {
      if (!searchBill) return data;

      const searchClient = (name: string) => {
        if (!searchBill.trim()) return true;

        return name.toLowerCase().search(searchBill.toLowerCase()) !== -1;
      };

      return data.filter((bill) => searchClient(bill.name));
    },
    [searchBill],
  );

  const subdomain = getSubdomain();

  const queryKey = [queryKeys.usersBills, startDate, endDate];
  if (statusFilter) queryKey.push(statusFilter);

  const query = useQuery(
    queryKey,
    () =>
      getUsersBills({
        companyToken,
        createdAtFrom: startDate,
        createdAtTo: endDate,
        maxResults: 1000,
        subdomain,
        statusFilter,
      }),
    {
      select: selectSearch,
    },
  );
  return query;
};

export default useQueryGetUsersBills;
