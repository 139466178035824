import { FC, useState } from "react";

import { Button, Checkbox, IconButton, MenuItem, Stack, TableCell, TableRow } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import MenuIcon from "@mui/icons-material/Menu";

import DropdownMenu from "components/DropdownMenu";
import DialogDeleteInvoice from "./DialogDeleteInvoice";

import { DateTime } from "luxon";
import { TransactionsItem } from "types/Company";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface InvoiceTableItemProps {
  invoice: TransactionsItem;
  onSelect: () => void;
  onConsult: () => void;
  selected: boolean;
  companyToken: string;
}

const InvoiceTableItem: FC<InvoiceTableItemProps> = ({
  invoice,
  onSelect,
  onConsult,
  selected,
  companyToken,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuIsOpen = Boolean(anchorEl);
  const [showDelete, setShowDelete] = useState(false);

  return (
    <>
      <TableRow hover selected={menuIsOpen || selected}>
        <TableCell padding="checkbox" className="no-print">
          <Checkbox size="small" color="primary" checked={selected} onChange={onSelect} />
        </TableCell>

        <TableCell>{invoice.invoiceNumber != "0" ? invoice.invoiceNumber : "-"}</TableCell>
        <TableCell>{DateTime.fromISO(invoice.dueDate).toFormat("dd/MM/yyyy")}</TableCell>
        <TableCell>
          {invoice.dateEmission
            ? DateTime.fromISO(invoice.dateEmission).toFormat("dd/MM/yyyy")
            : "-"}
        </TableCell>
        <TableCell>{invoice.participantName}</TableCell>
        <TableCell>
          {invoice.description.length > 50
            ? invoice.description.substring(0, 50) + "..."
            : invoice.description}
        </TableCell>
        <TableCell
          align="right"
          sx={{ color: invoice.transactionType === "Credit" ? "success.main" : "error.main" }}
        >
          {formatCurrencyBRL(invoice.value)}
        </TableCell>
        <TableCell align="center">{invoice.invoiceStatus}</TableCell>
        <TableCell>
          <Stack>
            {!!invoice.pathInvoice && (
              <Button
                variant="text"
                size="small"
                startIcon={<PictureAsPdfIcon fontSize="small" />}
                component="a"
                target="_blank"
                href={invoice.pathInvoice}
              >
                PDF
              </Button>
            )}
            {!!invoice.pathXmlInvoice && (
              <Button
                variant="text"
                size="small"
                startIcon={<SnippetFolderIcon fontSize="small" />}
                component="a"
                target="_blank"
                href={invoice.pathXmlInvoice}
              >
                XML
              </Button>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        open={menuIsOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem onClick={onConsult}>Consultar</MenuItem>
        {invoice.invoiceStatus !== "SolicitandoCancelamento" &&
          invoice.invoiceStatus !== "Cancelada" && (
            <MenuItem onClick={() => setShowDelete(true)}>Cancelar</MenuItem>
          )}
      </DropdownMenu>

      <DialogDeleteInvoice
        open={showDelete}
        onClose={() => setShowDelete(false)}
        id={invoice.id}
        companyToken={companyToken}
      />
    </>
  );
};

export default InvoiceTableItem;
