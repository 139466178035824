import { useQuery } from "react-query";
import queryKeys from "react-query/constants";

import { getServicesAndPlansClient } from "services/Requests/marketplace";
import getSubdomain from "utils/getSubdomain";

const useQueryServicesAndPlansClient = (companyToken: string) => {
  const subdomain = getSubdomain();

  const query = useQuery([queryKeys.servicesAndPlansClient, companyToken], () =>
    getServicesAndPlansClient({ companyToken, subdomain }),
  );
  return query;
};

export default useQueryServicesAndPlansClient;
