import { FC } from "react";
import { Control, useFieldArray, UseFormSetValue } from "react-hook-form";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { InvoiceForm } from ".";
import Service from "./Service";
import { createService } from "./utils";

interface ControllerServicesProps {
  control: Control<InvoiceForm>;
  companyToken: string;
  invoiceState: string;
  hasServiceList: boolean;
  setValue: UseFormSetValue<InvoiceForm>;
  isClient: boolean;
}

const ControllerServices: FC<ControllerServicesProps> = ({
  control,
  companyToken,
  invoiceState,
  hasServiceList,
  setValue,
  isClient,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: "services",
  });

  const handleDelete = (index: number) => {
    remove(index);
  };

  const handleAddService = (isManual?: boolean) => {
    append(createService(undefined, hasServiceList, isManual));
  };

  return (
    <Box>
      <Typography variant="h6" fontWeight={600} borderBottom={1} borderColor="divider">
        Serviço padrão
      </Typography>

      <Box mt={2} display="grid" gap={2}>
        {fields.map((field, index, arr) => (
          <Box key={field.id}>
            {arr.length !== 0 && (
              <Stack alignItems="end">
                <Button
                  size="small"
                  startIcon={<DeleteIcon fontSize="small" />}
                  sx={{ mb: 0.5 }}
                  onClick={() => handleDelete(index)}
                >
                  Excluir
                </Button>
              </Stack>
            )}

            <Service
              control={control}
              index={index}
              field={field}
              companyToken={companyToken}
              invoiceState={invoiceState}
              setValue={setValue}
              isClient={isClient}
            />
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </Box>

      <Box mt={1}>
        <Button startIcon={<AddIcon />} onClick={() => handleAddService()}>
          Adicionar serviço
        </Button>
        {invoiceState === "DF" && (
          <Button startIcon={<AddIcon />} onClick={() => handleAddService(true)}>
            Adicionar serviço manualmente
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ControllerServices;
