import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { CreatePlanForm } from ".";
import { PaymentMethodFields } from "../../Fields";
import CurrencyInput from "components/CurrencyInput";
import ControllerInput from "components/ControllerInput";

interface ChargeFieldsProps {
  control: Control<CreatePlanForm>;
}

const ChargeFields: FC<ChargeFieldsProps> = ({ control }) => {
  const isTablet = useMediaQuery("(max-width:650px)");

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{ borderBottom: 1, borderBottomColor: "divider", fontWeight: 600, mb: 2 }}
      >
        Cobrança
      </Typography>

      <Stack direction={isTablet ? "column" : "row"} gap={2}>
        <PaymentMethodFields control={control} />

        <Stack flex={2} gap={1} direction="row">
          <Controller
            name="price"
            control={control}
            render={({ field, fieldState }) => (
              <CurrencyInput
                {...field}
                inputProps={{
                  label: "Plano Mensal",
                  error: !!fieldState.error?.message,
                  helperText: fieldState.error?.message,
                }}
              />
            )}
          />

          <ControllerInput
            name="useLicenses"
            control={control}
            inputProps={{
              label: "Licenças",
              type: "number",
            }}
          />

          <Controller
            name="unitPrice"
            control={control}
            render={({ field, fieldState }) => (
              <CurrencyInput
                {...field}
                inputProps={{
                  label: "Cada",
                  error: !!fieldState.error?.message,
                  helperText: fieldState.error?.message,
                }}
              />
            )}
          />
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" gap={1} mt={1}>
        <Typography>Vencimento todo dia</Typography>{" "}
        <ControllerInput
          control={control}
          name="parcel"
          inputProps={{ size: "small", type: "number" }}
        />
        <Typography sx={{ ml: 1 }}>ou</Typography>{" "}
        <Controller
          control={control}
          name="isSameDayExp"
          render={({ field }) => (
            <FormControlLabel
              sx={{ m: 0, height: "auto" }}
              control={
                <Checkbox
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  checked={!!field.value}
                  value={!!field.value}
                  inputRef={field.ref}
                  size="small"
                />
              }
              label="exatamente na data de adesão"
            />
          )}
        />
      </Stack>

      <ControllerInput
        control={control}
        name="priceDescription"
        inputProps={{
          label: "Descrição de preço",
          fullWidth: true,
          sx: { mt: 3 },
        }}
      />

      <Divider sx={{ mt: 4 }} />
    </Box>
  );
};

export default ChargeFields;
