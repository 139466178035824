import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";

import {
  activeSubscription,
  cancelSubscription,
  CancelSubscriptionPayload,
} from "services/Requests/billing";
import { ClientDetailsWithPaymentMethod } from "types/Client";
import { Subscription } from "types/Subscription";
import getSubdomain from "utils/getSubdomain";

interface ToggleStatusSubscriptionArgs extends Omit<CancelSubscriptionPayload, "subdomain"> {
  action: "activate" | "deactivate";
}

const fnToggleStatusSubscription = ({
  action,
  ...payload
}: ToggleStatusSubscriptionArgs): Promise<Subscription> => {
  const subdomain = getSubdomain();

  if (action === "deactivate") {
    return cancelSubscription({
      ...payload,
      subdomain,
    });
  } else {
    return activeSubscription({
      ...payload,
      subdomain,
    });
  }
};

const useMutationToggleStatusSubscription = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(fnToggleStatusSubscription, {
    onSuccess(data, { payerCompanyId, id, action }) {
      if (action === "activate") {
        toast.success(
          "Solicitação para reativação de assinatura registrada com sucesso!\nPor favor aguarde um pouco para a solicitação ser processada.",
        );
      } else {
        toast.success(
          "Solicitação para cancelamento de assinatura registrada com sucesso!\nPor favor aguarde um pouco para a solicitação ser processada.",
        );
      }

      const clientDetailsKey = [queryKeys.clientDetails, payerCompanyId];

      const allSubscription = queryClient.getQueryData<ClientDetailsWithPaymentMethod>([
        queryKeys.clientDetails,
        payerCompanyId,
      ]);

      if (!allSubscription || !allSubscription.subscriptionList.items) {
        queryClient.invalidateQueries(clientDetailsKey);
        queryClient.invalidateQueries(queryKeys.allSubs, {
          exact: false,
        });
        return;
      }

      const newSubscriptionList = allSubscription.subscriptionList.items.map((item) => {
        if (item.id === id) return data;
        return item;
      });

      queryClient.setQueryData(clientDetailsKey, {
        ...allSubscription,
        subscriptionList: {
          items: newSubscriptionList,
        },
      });
    },
  });

  return mutation;
};

export default useMutationToggleStatusSubscription;
