import { Box } from "@mui/system";
import { SideMenuProvider } from "../../context/SideMenuContext";

import Header from "../../components/Header";
import SideMenu from "../../components/SideMenu";
import Content from "./Content";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Home = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.Intercom("update");
  }, [pathname]);

  return (
    <SideMenuProvider>
      <Box display="flex" flexDirection="column">
        <Header />
        <Box display="flex">
          <SideMenu />

          <Content />
        </Box>
      </Box>
    </SideMenuProvider>
  );
};

export default Home;
