import { FC } from "react";

import {
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { AllNotificationWithMonthAndYear } from "types/Notifications";
import { DateTime } from "luxon";
import groupBy from "utils/groupBy";

interface AllNotificationsProps {
  data: AllNotificationWithMonthAndYear[];
}

const AllNotifications: FC<AllNotificationsProps> = ({ data }) => {
  const groupNotifications = groupBy(data, (n) => n.monthAndYear);

  const renderNotificationItems = (monthAndYear: string) => {
    const notifications = groupNotifications.get(monthAndYear);
    if (!notifications) return;

    return notifications.map((notification) => (
      <TableRow hover key={notification.id}>
        <TableCell>
          <CalendarMonthIcon fontSize="small" /> {notification.message}
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {DateTime.fromISO(notification.date).toFormat("dd 'de' LLLL 'de' yyyy")}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Stack gap={2}>
      {Array.from(groupNotifications.keys()).map((monthAndYear) => (
        <Box p={2} boxShadow={2} bgcolor="#fff" key={monthAndYear}>
          <Typography variant="h6" gutterBottom>
            {monthAndYear}
          </Typography>

          <Divider />

          <Table>
            <TableBody>{renderNotificationItems(monthAndYear)}</TableBody>
          </Table>
        </Box>
      ))}
    </Stack>
  );
};

export default AllNotifications;
