import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import useMutationValidateParticipantCPFCNPJ from "./hooks/useMutationValidateParticipantCPFCNPJ";
import useMutatioCreateCompanyParticipants from "./hooks/useMutatioCreateCompanyParticipants";
import useMutatioUpdateCompanyParticipants from "./hooks/useMutatioUpdateCompanyParticipants";

import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  useMediaQuery,
} from "@mui/material";
import BasicDataFields from "./BasicDataFields";
import BankInformationFields from "./BankInformationFields";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import AddressFields from "components/AddressFields";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import { Bank } from "types/CommonData";
import { CompanyParticipants } from "types/Company";
import tranformToMaskType from "utils/tranformToMaskType";
import { telephoneMask } from "utils/masks";
import { CreateCompanyParticipantPayload } from "services/Requests/companies";
import { NameOfRoles, nameOfRolesToNumber } from "../RegistryClientsList";

export interface CreateClientForm {
  cpfcnpj: string;
  name: string;
  municipalEnrollment: string;
  stateRegistration: string;
  telNumber: string;
  email: string;

  street: string;
  neighborhood: string;
  zipCode: string;
  city: string;
  complement: string;
  state: string;
  streetNumber: number;

  bank: Bank | number | null;
  agency: number;
  account: number;
  isRegisterAsSupplier: boolean;
}

interface DialogCreateUpdateCompanyParticipantProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  role: NameOfRoles;
  client?: CompanyParticipants;
}

const DialogCreateUpdateCompanyParticipant: FC<DialogCreateUpdateCompanyParticipantProps> = ({
  onClose,
  companyToken,
  role,
  client,
  ...props
}) => {
  const isTablet = useMediaQuery("(max-width:800px)");
  const [saveAnother, setSaveAnother] = useState(false);

  const { mutateAsync: mutateValidateCPFCNPJ } = useMutationValidateParticipantCPFCNPJ();
  const { mutateAsync: mutateCreate } = useMutatioCreateCompanyParticipants();
  const { mutateAsync: mutateUpdate } = useMutatioUpdateCompanyParticipants();
  const [alert, setAlert] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm<CreateClientForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cpfcnpj: client?.cpfcnpj.replace(/-/g, "") || "",
      name: client?.name || "",
      municipalEnrollment: client?.municipalEnrollment || undefined,
      stateRegistration: client?.stateRegistration || undefined,
      telNumber:
        client && client.phone ? tranformToMaskType([...telephoneMask, /\d/g], client.phone) : "",
      email: client?.email || "",
      state: client?.address?.state || "",
      street: client?.address?.street || "",
      streetNumber: client?.address?.streetNumber
        ? Number(client?.address?.streetNumber)
        : undefined,
      city: client?.address?.countyId || "",
      complement: client?.address?.complement || "",
      neighborhood: client?.address?.neighborhood || "",
      zipCode: client?.address?.zipCode || "",
      bank: client?.bankAccount?.bankNumber || null,
      account: client?.bankAccount?.account ? Number(client?.bankAccount?.account) : undefined,
      agency: client?.bankAccount?.agency ? Number(client?.bankAccount?.agency) : undefined,
      isRegisterAsSupplier: client ? client.roles.split(", ").length > 1 : false,
    },
  });

  const resetAllFields = () => {
    reset({
      cpfcnpj: "",
      name: "",
      municipalEnrollment: "",
      stateRegistration: "",
      telNumber: "",
      email: "",
      state: "",
      street: "",
      city: "",
      complement: "",
      neighborhood: "",
      zipCode: "",
      bank: null,
      isRegisterAsSupplier: false,
      account: undefined,
      agency: undefined,
      streetNumber: undefined,
    });
  };

  const handleClose = () => {
    if (isSubmitting) return;
    setAlert(null);
    resetAllFields();
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    const cleanCPFCNPJ = data.cpfcnpj ? data.cpfcnpj.replace(/\D/g, "") : undefined;
    const cleanDefaultCPFCNPJ = client?.cpfcnpj ? client?.cpfcnpj.replace(/\D/g, "") : undefined;
    const cleanTelNumber = data.telNumber ? data.telNumber.replace(/\D/g, "") : undefined;

    if (
      (!client && cleanCPFCNPJ) ||
      (client && cleanCPFCNPJ && cleanCPFCNPJ !== cleanDefaultCPFCNPJ)
    ) {
      await mutateValidateCPFCNPJ({
        companyToken,
        cpfcnpj: cleanCPFCNPJ,
      });
    }

    const dataForCreate: CreateCompanyParticipantPayload = {
      companyToken,
      name: data.name,
      role: nameOfRolesToNumber[role],
      registerAsSupplier: data.isRegisterAsSupplier,
      bankAccount: {
        account: data.account ? data.account.toString() : null,
        agency: data.agency ? data.agency.toString() : null,
        bankNumber: typeof data.bank === "number" ? data.bank : data.bank?.id,
      },
      address: {
        complement: data.complement,
        neighborhood: data.neighborhood,
        streetNumber:
          typeof data.streetNumber === "number" ? data.streetNumber.toString() : data.streetNumber,
        zipCode: data.zipCode,
        countyId: data.city,
        state: data.state,
        street: data.street,
      },
      cpfcnpj: cleanCPFCNPJ,
      email: data.email,
      municipalEnrollment: data.municipalEnrollment,
      stateRegistration:
        typeof data.stateRegistration === "string"
          ? data.stateRegistration.toString()
          : data.stateRegistration,
      phone: cleanTelNumber,
    };

    if (!client) {
      await mutateCreate(dataForCreate);
    } else {
      await mutateUpdate({
        ...dataForCreate,
        id: client.id,
      });
    }

    resetAllFields();
    if (saveAnother) {
      setSaveAnother(false);
    } else {
      handleClose();
    }
  });

  return (
    <Dialog
      {...props}
      onClose={handleClose}
      maxWidth={isTablet ? false : "md"}
      fullScreen={isTablet}
      fullWidth
    >
      <DialogTitleWithCloseButton onClose={handleClose}>
        {client ? "Editar" : "Novo"} {role === "client" ? "Cliente" : "Fornecedor"}
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          {!!alert && (
            <Alert severity="error" onClose={() => setAlert(null)} sx={{ mb: 1 }}>
              {alert}
            </Alert>
          )}

          <Stack gap={4}>
            <BasicDataFields
              control={control}
              companyToken={companyToken}
              onAlert={setAlert}
              setValue={setValue}
            />
            <AddressFields control={control} setValue={setValue} />
            <BankInformationFields control={control} role={role} />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" disabled={isSubmitting} onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
            Salvar
          </LoadingButton>
          {!client && (
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
              onClick={() => {
                setSaveAnother(true);
              }}
            >
              Salvar e adicionar outro
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreateUpdateCompanyParticipant;
