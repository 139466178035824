import { FC } from "react";
import useMutationDeleteTransaction from "pages/Home/hooks/useMutationDeleteTransaction";

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

interface DialogDeleteInvoiceProps extends Omit<DialogProps, "id" | "onClose"> {
  onClose: () => void;
  id: number;
  companyToken: string;
}

const DialogDeleteInvoice: FC<DialogDeleteInvoiceProps> = ({
  companyToken,
  onClose,
  id,
  ...props
}) => {
  const { isLoading, mutateAsync } = useMutationDeleteTransaction();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync({
      companyToken,
      id,
    });
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} {...props} fullWidth maxWidth="sm">
      <DialogTitleWithCloseButton onClose={handleClose}>
        Excluir lançamento
      </DialogTitleWithCloseButton>

      <DialogContent>Deseja excluir este lançamento?</DialogContent>

      <DialogActions>
        <LoadingButton onClick={handleDelete} loading={isLoading} variant="contained">
          Excluir
        </LoadingButton>
        <Button disabled={isLoading} onClick={handleClose} variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteInvoice;
