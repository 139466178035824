import { FC, useState } from "react";
import { useAuth } from "context/Auth";

import Add from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";

import DialogCreateUpdateCompanyParticipant from "../../components/DialogCreateUpdateCompanyParticipant";
import RegistryClientsList from "../../components/RegistryClientsList";
import Head from "components/Head";
import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";

const Customers: FC = () => {
  const {
    user: { company },
  } = useAuth();

  const [createCompanyParticipant, setCreateCompanyParticipant] = useState(false);

  return (
    <>
      <Head title={`Fornecedores | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Fornecedores
        </Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<Add fontSize="small" />}
          disabled={!company}
          onClick={() => setCreateCompanyParticipant(true)}
        >
          Novo fornecedor
        </Button>
      </Stack>

      <Box boxShadow={1} p={2} mt={2}>
        {company ? (
          <RegistryClientsList companyToken={company.token} role="customer" />
        ) : (
          <Typography variant="body2">{MESSAGE_NO_COMPANY}</Typography>
        )}
      </Box>

      {company && (
        <DialogCreateUpdateCompanyParticipant
          keepMounted={false}
          open={createCompanyParticipant}
          onClose={() => setCreateCompanyParticipant(false)}
          companyToken={company.token}
          role="customer"
        />
      )}
    </>
  );
};

export default Customers;
