import { FC } from "react";

import { Form } from ".";
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";

import ControllerInput from "components/ControllerInput";
import CurrencyInput from "components/CurrencyInput";
import TextEditor from "components/TextEditor";
import { Descendant } from "slate";

interface ServiceFieldsProps {
  control: Control<Form>;
  description: Descendant[];
  onChangeDescription: (newValue: Descendant[]) => void;
}

const ServiceFields: FC<ServiceFieldsProps> = ({ control, description, onChangeDescription }) => {
  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2}>
        <ControllerInput
          control={control}
          name="name"
          inputProps={{
            label: "Nome do serviço",
            sx: { flex: 2 },
          }}
        />

        <Controller
          name="value"
          control={control}
          render={({ field, fieldState }) => (
            <CurrencyInput
              {...field}
              inputProps={{
                sx: {
                  flex: 1,
                },
                label: "Valor",
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message,
              }}
            />
          )}
        />
      </Stack>
      <FormControl fullWidth sx={{ my: 2 }}>
        <InputLabel>Tipo de cliente</InputLabel>
        <Controller
          name="typeClient"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <Select
                {...field}
                label="Tipo de cliente"
                fullWidth
                MenuProps={{ sx: { maxHeight: "50vh" } }}
                error={!!fieldState.error?.message}
              >
                <MenuItem value="" disabled>
                  Selecione:
                </MenuItem>
                <MenuItem value="NotSet">Todos os clientes</MenuItem>
                <MenuItem value="Accountant">Contadores</MenuItem>
                <MenuItem value="Freemium">Freemium</MenuItem>
                <MenuItem value="Premium">Premium</MenuItem>
              </Select>
              {!!fieldState.error?.message && (
                <Typography variant="body1">{fieldState.error?.message}</Typography>
              )}
            </>
          )}
        />
      </FormControl>

      <Box>
        <InputLabel sx={{ mb: 0.5, pl: 2 }}>Descrição</InputLabel>
        <TextEditor value={description} onChange={onChangeDescription} />
      </Box>
    </Stack>
  );
};

export default ServiceFields;
