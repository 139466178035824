import { FC } from "react";
import WithCompany from "components/WithCompany";
import InvoicesIssued from "pages/Home/components/InvoicesIssued";

interface InvoiceProps {
  companyToken: string;
}

const Invoice: FC<InvoiceProps> = ({ companyToken }) => {
  return <InvoicesIssued companyToken={companyToken} isStockControl issueInvoice={false} />;
};

export default WithCompany(Invoice);
