import { FC } from "react";
import { useAuth } from "context/Auth";

import { Typography } from "@mui/material";

import Head from "components/Head";
import CompaniesTable from "./CompaniesTable";
import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";

const AdminCompany: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <>
      <Head title={`Administração de empresas | ${getSubdomain()}`} />

      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Administração de empresas
      </Typography>

      {company ? (
        <CompaniesTable companyToken={company.token} />
      ) : (
        <Typography>{MESSAGE_NO_COMPANY}</Typography>
      )}
    </>
  );
};

export default AdminCompany;
