import { FC, useState } from "react";

import { Box, Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";
import Search from "@mui/icons-material/Search";

import NewContract from "./NewContract";
import ContractTable from "./ContractTable";

import { useAuth } from "context/Auth";

const Contracts: FC = () => {
  const {
    user: { company },
  } = useAuth();
  const [searchContract, setSearchContract] = useState("");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} mb={4}>
        <Button
          sx={{ whiteSpace: "nowrap" }}
          variant="contained"
          startIcon={<Add />}
          disabled={!company}
          onClick={() => setDialogIsOpen(true)}
        >
          Novo contrato
        </Button>

        <TextField
          size="small"
          disabled={!company}
          value={searchContract}
          sx={{ flex: 1 }}
          onChange={(e) => setSearchContract(e.target.value)}
          InputProps={{
            placeholder: "Buscar contratos",
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {!company ? (
        <Typography variant="h6">Nenhuma empresa vinculada ao seu perfil</Typography>
      ) : (
        <ContractTable companyToken={company.token} searchContract={searchContract} />
      )}

      {company && (
        <NewContract
          keepMounted={false}
          companyToken={company.token}
          open={dialogIsOpen}
          onClose={() => setDialogIsOpen(false)}
        />
      )}
    </Box>
  );
};

export default Contracts;
