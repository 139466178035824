import { useQuery } from "react-query";
import { getUsersAdmList } from "services/Requests/admin";
import queryKeys from "react-query/constants";
import { useCallback } from "react";
import { UserAdmin } from "types/User";

const useQueryUserAdminList = (companyToken: string, search?: string) => {
  const selectorFn = useCallback(
    (data: UserAdmin[]) => {
      if (!search || !search.trim()) return data;

      return data.filter((user) => {
        return user.name.toLowerCase().search(search.toLowerCase()) !== -1;
      });
    },
    [search],
  );

  const query = useQuery(queryKeys.userAdminList, () => getUsersAdmList({ companyToken }), {
    select: search === undefined ? undefined : selectorFn,
  });
  return query;
};

export default useQueryUserAdminList;
