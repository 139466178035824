import { FC, useState } from "react";
import { useFilterPeriod } from "../contexts/FilterPeriod";
import useQueryGetTransactions from "../hooks/useQueryGetTransactions";

import { Box, CircularProgress, Collapse, Divider, LinearProgress, Stack } from "@mui/material";

import PaidBillsTable from "./PaidBillsTable";
import FilterPeriod from "components/FilterPeriod";
import CategoryFilter from "../components/CategoryFilter";
import SearchInput from "components/SearchInput";
import DateTypesFilter from "../components/DateTypesFilter";

import { DateTypeFilter, FinancialCategory } from "types/Company";
import CompanyParticipantFilter from "../components/CompanyParticipantFilter";
import BankAccountFilter from "../components/BankAccountFilter";

interface PaidBillsProps {
  companyToken: string;
}

const PaidBillsFilters: FC<PaidBillsProps> = ({ companyToken }) => {
  const [companyParticipant, setCompanyParticipant] = useState<null | number>(null);
  const [categoryFilter, setCategoryFilter] = useState<FinancialCategory | null>(null);
  const [selectedAccountBankId, setSelectedAccountBankId] = useState<null | number>(null);
  const [dateTypeFilter, setDateTypeFilter] = useState<null | DateTypeFilter>(null);
  const [search, setSearch] = useState("");

  const {
    date: { start, end },
    setDate,
  } = useFilterPeriod();
  const {
    data: transactions,
    isLoading,
    isFetching,
  } = useQueryGetTransactions(
    companyToken,
    start,
    end,
    "PA",
    selectedAccountBankId,
    search,
    categoryFilter,
    companyParticipant,
    dateTypeFilter,
  );

  const totalBills = transactions?.items.reduce((acc, item) => acc + item.value, 0);

  return (
    <Box>
      <Collapse in={!isLoading && isFetching}>
        <LinearProgress />
      </Collapse>

      <Stack gap={2} direction="row" mt={0.5} flexWrap="wrap">
        <FilterPeriod
          endDate={end}
          startDate={start}
          onChangeDates={(dates) => setDate(dates)}
          label="Período"
          size="medium"
        />

        <DateTypesFilter value={dateTypeFilter} setValue={setDateTypeFilter} type={3} />

        <CategoryFilter
          companyToken={companyToken}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
        />

        <BankAccountFilter
          value={selectedAccountBankId}
          setValue={setSelectedAccountBankId}
          companyToken={companyToken}
        />

        <CompanyParticipantFilter
          companyToken={companyToken}
          value={companyParticipant}
          setValue={setCompanyParticipant}
          role={2}
        />

        <SearchInput label="Buscar contas a receber" onChange={(value) => setSearch(value)} />
      </Stack>

      <Divider sx={{ mt: 2, mb: 4 }} />
      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
      {transactions && (
        <PaidBillsTable
          transactions={transactions}
          companyToken={companyToken}
          totalBills={totalBills}
        />
      )}
    </Box>
  );
};

export default PaidBillsFilters;
