import { AxiosError } from "axios";
import toast from "react-hot-toast";

interface ErrorType {
  entries: {
    description: null | string;
    entityType: string;
    problems: { [x: string]: string };
  }[];
}

const errorHandlerCompany = (error: AxiosError<ErrorType>) => {
  const errorData = error.response?.data;
  if (errorData?.entries) {
    const messages = errorData.entries.map((entry) => {
      const valuesProblems = Object.values(entry.problems);
      const valuesRemoveBreak = valuesProblems.map((problem) =>
        problem.replace(/<br\s*[/]?>/gi, "\n"),
      );

      return valuesRemoveBreak.join("");
    });

    toast.error("Ocorreu um erro ao atualizar os dados da empresa:\n" + messages.join(""));
    return;
  }

  toast.error("Ocorreu um erro ao atualizar os dados da empresa, tente mais tarde");
};

export default errorHandlerCompany;
