import { FC } from "react";
import useQueryInvoiceConfigStockControl from "../../hooks/useQueryInvoiceConfigStockControl";
import useQueryMovement from "../../hooks/useQueryMovement";

import { Dialog, DialogContent, DialogProps } from "@mui/material";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { DialogMovement } from "./DialogMovement";

interface LoadingDataProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  onClose: () => void;
  movementId?: number;
  isConsultation?: boolean;
}

export const LoadingData: FC<LoadingDataProps> = ({
  companyToken,
  movementId,
  isConsultation = false,
  ...props
}) => {
  const {
    data: invoiceConfig,
    isLoading: loadingInvoice,
    isError: errorInvoice,
  } = useQueryInvoiceConfigStockControl(companyToken);
  const {
    data: movement,
    isLoading: loadingMovement,
    isError: errorMovement,
  } = useQueryMovement(companyToken, movementId);

  const isLoading = loadingMovement || loadingInvoice;

  if (isLoading && props.open) {
    return <FullscreenLoadingIndicator />;
  }

  if (errorMovement || errorInvoice) {
    return (
      <Dialog {...props} maxWidth="sm" fullWidth>
        <DialogTitleWithCloseButton onClose={props.onClose}>
          Movimentação
        </DialogTitleWithCloseButton>
        <DialogContent>
          Ocorreu um error ao pegar os dados, tente novamente mais tarde
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <DialogMovement
      companyToken={companyToken}
      invoiceConfig={invoiceConfig}
      movement={movement}
      isConsultation={isConsultation}
      {...props}
    />
  );
};
