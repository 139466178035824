import { FC, useState } from "react";

import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import ComunicationTable from "pages/Home/pages/Comunication/ComunicationTable";
import DialogNewTicket from "../../DialogNewTicket";
import { Order } from "types/OpenCompany";

interface ComunicationProps {
  order: Order;
}

const Comunication: FC<ComunicationProps> = ({ order }) => {
  const [createTicketIsOpen, setCreateTicketIsOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setCreateTicketIsOpen(true)}
      >
        Nova Solicitação
      </Button>

      <ComunicationTable companyToken={order.companyToken} />

      {createTicketIsOpen && (
        <DialogNewTicket
          open={createTicketIsOpen}
          onClose={() => setCreateTicketIsOpen(false)}
          companyToken={order.companyToken}
        />
      )}
    </>
  );
};

export default Comunication;
