import { FC, useRef, useState } from "react";
import useMutationUpdateCompanyBankAccountAccountanting from "../../hooks/useMutationUpdateCompanyBankAccountAccountanting";

import { TableCell, TableRow } from "@mui/material";
import GlobalAccountantingAutoComplete from "components/GlobalAccountantingAutoComplete";

import { CompanyBankAccounts } from "types/Company";
import { GlobalAccountanting } from "types/Accountant";

interface BankAccountItemProps {
  bankAccount: CompanyBankAccounts;
  companyToken: string;
}

const BankAccountItem: FC<BankAccountItemProps> = ({ bankAccount, companyToken }) => {
  const previousAccountanting = useRef<null | GlobalAccountanting>(null);
  const [inputValue, setInputValue] = useState(bankAccount.accountanting);
  const [value, setValue] = useState<null | GlobalAccountanting>(null);

  const { mutateAsync, isLoading } = useMutationUpdateCompanyBankAccountAccountanting();

  const handleUpdate = async () => {
    if (!value || value.id === previousAccountanting.current?.id) return;

    await mutateAsync({
      ...bankAccount,
      accountanting: value.name,
      companyToken,
    });
    previousAccountanting.current = value;
  };

  return (
    <TableRow hover>
      <TableCell>{bankAccount.alias}</TableCell>
      <TableCell>
        {!!bankAccount.agency && (
          <>
            Banco: {bankAccount.bankFormat} <br />
            Agência: {bankAccount.agency} <br />
            Conta: {bankAccount.account}
          </>
        )}
      </TableCell>
      <TableCell>
        <GlobalAccountantingAutoComplete
          isCompany
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
          inputValue={inputValue}
          companyToken={companyToken}
          onInputChange={(_, newInputValue, reason) => {
            if (reason === "reset" && newInputValue === "") return;
            setInputValue(newInputValue);
          }}
          size="small"
          disabled={isLoading}
          onSelect={handleUpdate}
          onBlur={handleUpdate}
        />
      </TableCell>
    </TableRow>
  );
};

export default BankAccountItem;
