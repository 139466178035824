import { FC } from "react";
import useMutationUpdateCategory from "../hooks/useMutationUpdateCategory";
import { useForm } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { FinancialCategory } from "types/Company";
import ControllerInput from "components/ControllerInput";

interface DialogEditCategoryProps extends Omit<DialogProps, "onClose"> {
  category: FinancialCategory;
  companyToken: string;
  onClose: () => void;
}

const DialogEditCategory: FC<DialogEditCategoryProps> = ({
  category,
  companyToken,
  onClose,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationUpdateCategory();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: category.name,
    },
  });

  const handleClose = () => {
    if (isLoading) return;
    reset({
      name: category.name,
    });
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      companyToken,
      categoryId: category.id,
      color: category.color,
      costCenterId: null,
      creditAccountCashAsset: null,
      creditAccountProvision: null,
      debitAccountCashAsset: null,
      debitAccountProvision: null,
      globalCategoryId: category.parentUId ? Number(category.parentUId) : null,
      isVisibleFinancialReport: category.isVisibleFinancialReport,
      name: data.name,
      order: category.order,
      parentCategoryId: null,
    });
    handleClose();
  });

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Editar categoria personalizada
      </DialogTitleWithCloseButton>

      <form onSubmit={onSubmit}>
        <DialogContent>
          <ControllerInput
            control={control}
            name="name"
            inputProps={{ label: "Nome da categoria", fullWidth: true }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined" disabled={isLoading}>
            Cancelar
          </Button>
          <LoadingButton type="submit" loading={isLoading} variant="contained">
            Salvar
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogEditCategory;
