import { FC } from "react";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface BankAccountFilterProps {
  companyToken: string;
  value: number | null;
  setValue: (value: number | null) => void;
}

const BankAccountFilter: FC<BankAccountFilterProps> = ({ companyToken, value, setValue }) => {
  const { data: bankAccounts, isLoading, isError } = useQueryGetCompanyBankAccounts(companyToken);

  return (
    <FormControl>
      <InputLabel>Conta</InputLabel>
      <Select
        value={value || 0}
        label="Conta"
        onChange={(e) => setValue(Number(e.target.value) || null)}
      >
        <MenuItem value={0}>Todos as contas</MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um erro ao buscar as contas</MenuItem>}
        {bankAccounts &&
          bankAccounts.map((bank) => (
            <MenuItem key={bank.id} value={bank.id}>
              {bank.bankName || bank.alias}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default BankAccountFilter;
