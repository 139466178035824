import * as React from "react";
import { SvgIcon as MuiSvgIcon, SvgIconProps, styled } from "@mui/material";

const SvgIcon = styled(MuiSvgIcon, {
  name: "BarCodeIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(() => ({
  fill: "currentColor",
  strokeWidth: "0",
}));

const BarCodeIcon: React.FunctionComponent<SvgIconProps> = ({
  viewBox = "0 0 24 24",
  focusable = "false",
  ...props
}) => {
  return (
    <SvgIcon {...props} viewBox={viewBox} focusable={focusable}>
      <g>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M2 4h2v16H2V4zm4 0h1v16H6V4zm2 0h2v16H8V4zm3 0h2v16h-2V4zm3 0h2v16h-2V4zm3 0h1v16h-1V4zm2 0h3v16h-3V4z"></path>
      </g>
    </SvgIcon>
  );
};

export default BarCodeIcon;
