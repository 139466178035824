import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("O nome é obrigatório")
    .test("is-name", "O nome deve conter apenas letras", (value) => {
      return /^[a-zA-Z\s]+$/.test(value || "");
    })
    .min(3, "O nome deve ter no mínimo 3 caracteres")
    .max(50, "O nome deve ter no máximo 50 caracteres"),
  login: yup.string().required("O login/email é obrigatório"),
  cellPhone: yup
    .string()
    .required("O telefone é obrigatório")
    .test("cellPhone", "Digite um celular válido", (value) => {
      if (!value) return true;
      const cellPhoneOnlyNumber = value.replace(/\D/g, "");
      return cellPhoneOnlyNumber.length === 10 || cellPhoneOnlyNumber.length === 11;
    }),
});

export default validationSchema;
