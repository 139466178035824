import { ChangeEvent, FC } from "react";
import { useExtract } from "../context/Extract";

import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { ImportOFXTransaction } from "types/Company";

interface FileInputProps {
  extract: ImportOFXTransaction;
}

const FileInput: FC<FileInputProps> = ({ extract }) => {
  const { fileList, onChangeFile } = useExtract();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target?.files;
    onChangeFile(Array.from(files || []));
  };

  if (extract.transactionValue >= 0) {
    return <></>;
  }

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Tooltip title="Anexar comprovante">
        <IconButton color="info" component="label" size="small" disabled={extract.check}>
          <input hidden type="file" onChange={onChange} multiple />
          <AttachFileIcon fontSize="small" color="info" />
        </IconButton>
      </Tooltip>

      {fileList.length !== 0 && (
        <Typography variant="body2" color="text.secondary">
          {fileList.map((file) => file.name).join(", ")}
        </Typography>
      )}
    </Stack>
  );
};

export default FileInput;
