import { useMutation, useQueryClient } from "react-query";
import { requestAdvancements, RequestAdvancementsPaylaod } from "services/Requests/billing";

import getSubdomain from "utils/getSubdomain";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";

const useMutationRequestAdvancements = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<RequestAdvancementsPaylaod, "subdomain">) =>
      requestAdvancements({ ...payload, subdomain }),
    {
      onSuccess() {
        toast.success("Solicitação realizada com sucesso.");
        queryClient.invalidateQueries(queryKeys.allTransactions, { exact: false });
      },
      onError() {
        toast.error("Ocorreu um erro ao solicitar a antecipação.");
      },
    },
  );
  return mutation;
};

export default useMutationRequestAdvancements;
