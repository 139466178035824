import { FC } from "react";

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  Stack,
  useMediaQuery,
} from "@mui/material";

import ServiceForm from "./ServiceForm";
import PlanForm from "./PlanForm";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { Service, ServicePlan } from "types/ServiceAndPlan";
import useQueryGetDefaultAccountabilityPlan from "../../hooks/useQueryGetDefaultAccountabilityPlan";

const isService = (obj: Service | ServicePlan): obj is Service => {
  return "serviceType" in obj;
};

export interface FormEditPlan {
  name: string;
  isActive: boolean;
  agreement: string;
  services: {
    name: string;
    serviceId: number;
    price: string;
    isActive: boolean;
  }[];
}

interface DialogEditPlanProps extends DialogProps {
  companyToken: string;
  service: Service | ServicePlan;
}

const DialogEditPlan: FC<DialogEditPlanProps> = ({ onClose, companyToken, service, ...props }) => {
  const queryPlan = useQueryGetDefaultAccountabilityPlan(
    companyToken,
    isService(service) ? undefined : service.planId,
  );
  const isMobile = useMediaQuery("(max-width:500px)");

  const handleClose = () => {
    onClose?.({}, "escapeKeyDown");
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="md" fullScreen={isMobile} fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {isService(service) ? service.groupName || service.name : service.name}
      </DialogTitleWithCloseButton>

      {queryPlan && queryPlan.isLoading && (
        <DialogContent>
          <Stack sx={{ height: "70vh" }} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        </DialogContent>
      )}

      {isService(service) && (
        <ServiceForm service={service} onClose={handleClose} companyToken={companyToken} />
      )}

      {queryPlan && queryPlan.data && (
        <PlanForm plan={queryPlan.data} onClose={handleClose} companyToken={companyToken} />
      )}
    </Dialog>
  );
};

export default DialogEditPlan;
