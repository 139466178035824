import { useState } from "react";

import { TextField, TextFieldProps, useTheme } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

import PopoverDates from "./PopoverDates";
import { DateTime } from "luxon";
import { ISODate } from "types/SemanticTypes";

export type DateType<T extends boolean = false> = T extends true ? string | null : string;

interface FilterPeriodProps<T extends boolean = false>
  extends Omit<Partial<TextFieldProps>, "inputProps" | "value" | "onClick" | "onChange"> {
  erasable?: T;
  onChangeDates: (value: { start: DateType<T>; end: DateType<T> }) => void;
  startDate?: ISODate;
  endDate?: ISODate;
}

function FilterPeriod<T extends boolean = false>({
  startDate,
  endDate,
  onChangeDates,
  erasable,
  ...props
}: FilterPeriodProps<T>) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const fromISOToFormat = (iso: ISODate) => DateTime.fromISO(iso).toFormat("dd/MM/yyyy");

  return (
    <>
      <TextField
        {...props}
        size={props.size || "small"}
        sx={{
          "*": { color: "text.primary", cursor: "pointer !important" },
          minWidth: 270,
          ...props.sx,
        }}
        disabled
        onClick={(e) => setAnchorEl((prev) => (prev ? null : e.currentTarget))}
        value={
          startDate && endDate
            ? `${fromISOToFormat(startDate)} até ${fromISOToFormat(endDate)}`
            : ""
        }
        inputProps={{
          sx: {
            bgcolor: `${theme.palette.background.paper} !important`,
            color: `${theme.palette.text.primary} !important`,
          },
        }}
        InputProps={{
          endAdornment: (
            <ArrowDropDown
              sx={{
                transform: `rotate(${open ? "180" : "0"}deg)`,
                transition: `transform 350ms ${theme.transitions.easing.easeInOut}`,
              }}
            />
          ),
          sx: { bgcolor: `${theme.palette.background.paper} !important` },
        }}
      />

      <PopoverDates
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        startDateInitial={startDate}
        endDateInitial={endDate}
        onChangeDates={onChangeDates}
        erasable={erasable}
      />
    </>
  );
}

export default FilterPeriod;
