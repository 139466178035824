import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { duplicateBill } from "services/Requests/billing";

const useMutationDuplicateBill = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(duplicateBill, {
    onSuccess(_, { companyToken, payerCompanyId }) {
      toast.success("Segunda via da fatura gerada com sucesso!");
      queryClient.invalidateQueries([queryKeys.paymentInfo, companyToken]);
      queryClient.invalidateQueries([queryKeys.clientBills, payerCompanyId]);
    },
  });
  return mutation;
};

export default useMutationDuplicateBill;
