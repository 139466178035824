import { useContext } from "react";
import { SideMenuContext } from ".";

export const useSideMenu = () => {
  const context = useContext(SideMenuContext);

  if (!context) {
    throw new Error("useSideMenu must be used within a SideMenuProvider");
  }
  return context;
};
