import { FC, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import TabPanel from "components/TabPanel";
import ServiceFields from "./ServiceFields";
import { formattedInput } from "components/CurrencyInput";
import PropertiesAndBilling from "./PropertiesAndBilling";

import useMutationCreateUpdateCustomService from "../../../hooks/useMutationCreateUpdateCustomService";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { useForm } from "react-hook-form";

import validationSchema from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";

import toast from "react-hot-toast";
import { CustomService } from "types/ServiceAndPlan";
import { CreateUpdateCustomServicePayload } from "services/Requests/marketplace";
import useQueryGetContracts from "pages/Home/hooks/useQueryGetContracts";

import { HTMLStringToDescendant, toHTMLString } from "components/TextEditor/utils/functions";
import { EMPTY } from "components/TextEditor/utils/constants";
import { Descendant } from "slate";

export const periodicity = {
  semanal: 7,
  mensal: 1,
  trimestral: 3,
  semestral: 6,
  anual: 12,
  "sem recorrência": -1,
};

export const periodicityWithoutNoRecorrence = {
  semanal: 7,
  mensal: 1,
  trimestral: 3,
  semestral: 6,
  anual: 12,
};

export interface Form {
  name: string;
  value: string;
  typeClient: "NotSet" | "Accountant" | "Freemium" | "Premium";
  isActive: boolean;
  agreement: string;
  bankSlip: boolean;
  creditCard: boolean;
  periodicity: number;
  parcel?: string;
  isSameDayExp?: boolean;
  periodParcel?: number;
  daysAfter?: string;
}

interface DialogServiceProps extends DialogProps {
  companyToken: string;
  service?: CustomService;
}

const DialogService: FC<DialogServiceProps> = ({ onClose, companyToken, service, ...props }) => {
  const [value, setValue] = useState(0);
  const { data: contracts } = useQueryGetContracts(companyToken);

  const [serviceDescription, setServiceDescription] = useState<Descendant[]>(() => {
    return service ? HTMLStringToDescendant(service.description) : EMPTY;
  });

  const handleChange = (_: unknown, newValue: number) => {
    setValue(newValue);
  };

  const { mutateAsync, isLoading } = useMutationCreateUpdateCustomService();
  const { handleSubmit, control, reset } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: service?.name || "",
      isActive: service?.isActive || false,
      value: formatCurrencyBRL(service?.value || 0.0),
      typeClient: service?.userType || "NotSet",
      bankSlip: service ? service.bankSlip : true,
      creditCard: service ? service.creditCard : true,
      periodicity: service?.quantity || -1,
      parcel: service?.parcel?.toString() || "0",
      periodParcel: service && service.quantity === 7 ? service.parcel : 2,
      agreement: service?.filename || "",
      daysAfter: service?.daysAfter?.toString() || "",
      isSameDayExp: service?.isExpirationDateCreationDay || false,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    const findContract = contracts?.find((contract) => contract.filename === data.agreement);

    try {
      let payload: CreateUpdateCustomServicePayload = {
        bankSlip: data.bankSlip,
        creditCard: data.creditCard,
        documentToken: findContract?.downloadToken || "",
        downloadToken: findContract?.downloadToken || null,
        filename: findContract?.filename || null,
        id: service?.id || 0,
        name: data.name,
        userType: data.typeClient,
        valueCents: formattedInput(data.value),
        description: toHTMLString(serviceDescription),
        isActive: data.isActive,
        quantity: data.periodicity,
        isExpirationDateCreationDay: [7, 1].includes(data.periodicity)
          ? !!data.isSameDayExp
          : false,
        companyToken,
      };

      if (data.periodicity === -1) {
        payload = {
          ...payload,
          daysAfter: Number(data.daysAfter) || 0,
        };
      }

      if ([1, 3, 6, 12].includes(data.periodicity)) {
        payload = {
          ...payload,
          parcel: Number(data.parcel || "0"),
        };
      }

      if (data.periodicity === 7) {
        payload = {
          ...payload,
          parcel: data.periodParcel || 1,
        };
      }

      await mutateAsync(payload);
      reset();
      onClose?.({}, "backdropClick");
    } catch (e) {
      toast.error("Ocorreu um error na atualização de serviço");
    }
  });

  return (
    <Dialog onClose={onClose} {...props} maxWidth="md" fullWidth scroll="body">
      <DialogTitle>{!service ? "Criar" : "Editar"} Serviço</DialogTitle>

      <form onSubmit={onSubmit}>
        <DialogContent>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              borderBottom: 1,
              borderBottomColor: "divider",
            }}
          >
            <Tab label="Serviço" value={0} />
            <Tab label="Propriedades e cobrança" value={1} />
          </Tabs>

          <TabPanel value={value} index={0} keepMounted={true}>
            <ServiceFields
              control={control}
              description={serviceDescription}
              onChangeDescription={(newValue) => setServiceDescription(newValue)}
            />
          </TabPanel>

          <TabPanel value={value} index={1} keepMounted={true}>
            <PropertiesAndBilling companyToken={companyToken} control={control} />
          </TabPanel>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => onClose?.({}, "backdropClick")}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            disabled={!contracts}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogService;
