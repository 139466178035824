import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { updateDefaultService } from "services/Requests/marketplace";

const useMutationUpdateDefaultService = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateDefaultService, {
    onSuccess() {
      queryClient.invalidateQueries(queryKeys.serviceAndPlans);
      toast.success("Serviço atualizado com sucesso!");
    },
  });
  return mutation;
};

export default useMutationUpdateDefaultService;
