import { FC, useEffect, useMemo, useState } from "react";
import { useExtract } from "../../context/Extract";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, IconButton } from "@mui/material";

import SearchTab from "../SearchTab";
import { ImportOFXTransaction, TransactionMatch } from "types/Company";

interface ControlReleasesProps {
  transactionMatch: TransactionMatch;
  companyToken: string;
  extract: ImportOFXTransaction;
}

const ControlReleases: FC<ControlReleasesProps> = ({ extract, transactionMatch, companyToken }) => {
  const { onChangeExtract, requestSave } = useExtract();
  const [currentRealeaseIndex, setCurrentRealeaseIndex] = useState(0);
  const currentRelease = useMemo(
    () => transactionMatch.provisioned[currentRealeaseIndex],
    [currentRealeaseIndex, transactionMatch],
  );

  useEffect(() => {
    onChangeExtract(extract, "description2", currentRelease.ofxDescription);
    onChangeExtract(extract, "globalCategoryId", currentRelease.globalCategoryId);
    onChangeExtract(extract, "categoryId", currentRelease.categoryId);
    onChangeExtract(extract, "partnerParticipantId", currentRelease.companyPartnerId);
    onChangeExtract(extract, "companyParticipantId", currentRelease.companyParticipantId);
    onChangeExtract(extract, "forPartiner", false);
    onChangeExtract(extract, "transactionId", currentRelease.id);

    requestSave(extract);
  }, [currentRelease]);

  return (
    <Box display="grid" gridTemplateColumns="1fr 5fr 1fr" alignItems="start" gap={2}>
      <IconButton
        sx={{ width: "fit-content", justifySelf: "center" }}
        disabled={currentRealeaseIndex === 0}
        onClick={() => setCurrentRealeaseIndex((prev) => prev - 1)}
      >
        <ArrowLeftIcon />
      </IconButton>

      <SearchTab
        extract={extract}
        companyToken={companyToken}
        transactionType={currentRelease.type.toUpperCase() === "CREDIT" ? "Credit" : "Debt"}
      />

      <IconButton
        sx={{ width: "fit-content", justifySelf: "center" }}
        disabled={transactionMatch.provisioned.length - 1 === currentRealeaseIndex}
        onClick={() => setCurrentRealeaseIndex((prev) => prev + 1)}
      >
        <ArrowRightIcon />
      </IconButton>
    </Box>
  );
};

export default ControlReleases;
