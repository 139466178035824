import * as yup from "yup";
import { DateTime } from "luxon";

const minDate = DateTime.local(2000).toJSDate();
const maxDate = DateTime.now().plus({ years: 3 }).endOf("year");

const validationSchema = yup.object().shape({
  fileName: yup.string().required("O nome do arquivo é obrigatória"),
  referenceDate: yup
    .date()
    .required("Informe a data")
    .typeError("Digite uma data válida")
    .min(minDate, "Digite uma data maior que a data mínima")
    .max(maxDate, "Digite uma data menor que a data máxima"),
  dueDate: yup
    .date()
    .notRequired()
    .nullable(true)
    .typeError("Digite uma data válida")
    .min(minDate, "Digite uma data maior que a data mínima")
    .max(maxDate, "Digite uma data menor que a data máxima"),
  value: yup.string(),
});

export default validationSchema;
