import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getPieChart, GetPieChartPayload } from "services/Requests/dashboard";

const useQueryPieChartData = (payload: GetPieChartPayload) => {
  const query = useQuery(
    [queryKeys.pieChartData, payload.startDate, payload.endDate, payload.type, payload.group],
    () => getPieChart(payload),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 2, // 2 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
    },
  );
  return query;
};

export default useQueryPieChartData;
