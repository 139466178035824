import { Order } from "types/SemanticTypes";

function descendingComparator<Type>(a: Type, b: Type, orderBy: keyof Type) {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (typeof valueA === "string" && typeof valueB === "string") {
    const dateA = new Date(valueA);
    const dateB = new Date(valueB);
    if (dateA.toString() !== "Invalid Date" && dateB.toString() !== "Invalid Date") {
      if (dateB < dateA) {
        return -1;
      }
      if (dateB > dateA) {
        return 1;
      }
      return 0;
    }
  }

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function comparatorSortByProperty<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | object | null | boolean },
  b: { [key in Key]: number | string | object | null | boolean },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default comparatorSortByProperty;
