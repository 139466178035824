import { FC, memo, useRef } from "react";

import { IconButton, Stack, SxProps, Typography } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Theme } from "@mui/system";
import { grey } from "@mui/material/colors";

import { FinancialCategoryByGroup } from "types/Company";
import AnalyticalCategoryUI from "./AnalyticalCategoryUI";

import { useDrag, useDrop, XYCoord } from "react-dnd";
import type { Identifier } from "dnd-core";
import { ITEM } from "../utils/DragDropConstants";
import { useTemplate } from "../context/Template/useTemplate";
import { Types } from "../context/Template";

export interface DragItem {
  id: number;
  indexColumn: number;
  indexCard: number;
}

interface AnalyticalCategoryProps {
  financialCategory: FinancialCategoryByGroup;
  nature: FinancialCategoryByGroup["nature"];
  indexColumn: number;
  index: number;
  disabled?: boolean;
}

const AnalyticalCategory: FC<AnalyticalCategoryProps> = ({
  financialCategory,
  indexColumn,
  index,
  nature,
}) => {
  const { dispatch, onEndDragItem } = useTemplate();
  const itemRef = useRef<HTMLDivElement | null>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: ITEM,
    item: () => ({
      id: financialCategory.id,
      indexColumn,
      indexCard: index,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging: (monitor) => monitor.getItem().id === financialCategory.id,
    end: onEndDragItem,
  });

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ITEM,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),

    hover(item, monitor) {
      if (!itemRef.current) return;
      const dragIndex = item.indexCard;
      const hoverIndex = index;

      const dragColumn = item.indexColumn;
      const hoverColumn = indexColumn;

      if (dragIndex === hoverIndex && dragColumn === hoverColumn) return;

      const hoverBoundingRect = itemRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset() as XYCoord;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      dispatch({
        type: Types.Move_Card,
        payload: {
          indexFrom: dragIndex,
          indexFromColumn: dragColumn,
          indexTo: hoverIndex,
          indexToColumn: hoverColumn,
        },
      });

      item.indexCard = hoverIndex;
      item.indexColumn = indexColumn;
    },
  });

  drop(preview(itemRef));

  const styleDragging: SxProps<Theme> = {
    pointerEvents: "none",
    cursor: "grabbing",
    borderStyle: "dashed",
    borderwidth: "4px",
    borderColor: "divider",
    background: "transparent",

    "& > *": {
      opacity: 0,
    },
  };

  return (
    <>
      <Stack
        border={1}
        borderColor={grey[300]}
        bgcolor={grey[100]}
        p={1}
        ref={itemRef}
        data-handler-id={handlerId}
        sx={isDragging ? styleDragging : undefined}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        gap={1}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{
            width: {
              md: 230,
              lg: 360,
            },
          }}
        >
          <IconButton ref={drag}>
            <DragIndicatorIcon sx={{ color: "text.primary", cursor: "grab" }} />
          </IconButton>
          <Typography variant="body2" color="text.secondary" sx={{ wordBreak: "break-word" }}>
            {financialCategory.name}
          </Typography>
        </Stack>

        <AnalyticalCategoryUI nature={nature} financialCategory={financialCategory} />
      </Stack>
    </>
  );
};

export default memo(AnalyticalCategory);
