import { createContext, Dispatch, SetStateAction } from "react";
import { ISODate } from "types/SemanticTypes";

export interface DateInterface {
  start: ISODate;
  end: ISODate;
}

export interface DashboardPeriodContextInterface {
  date: DateInterface;
  setDate: Dispatch<SetStateAction<DateInterface>>;
}

export const DashboardPeriodContext = createContext<DashboardPeriodContextInterface>(
  {} as DashboardPeriodContextInterface,
);
