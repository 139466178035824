import { FC } from "react";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";

import { Checkbox, Chip, TableCell, TableRow, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { DateTime } from "luxon";
import { TransactionsItem } from "types/Company";
import getLabelStatus from "pages/Home/utils/getLabelStatus";
import getStatusColor from "pages/Home/utils/getStatusColor";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface TableItemReleaseProps {
  transaction: TransactionsItem;
  companyToken: string;
  selected: boolean;
  onToggleSelected: () => void;
}

const TableItemRelease: FC<TableItemReleaseProps> = ({
  transaction,
  companyToken,
  selected,
  onToggleSelected,
}) => {
  const { data: bankAccounts } = useQueryGetCompanyBankAccounts(companyToken);

  const getBankName = (bankID: number) => {
    if (!bankID || !bankAccounts) return "-";

    return bankAccounts.find((bank) => bank.id === bankID)?.alias || "-";
  };

  return (
    <TableRow hover selected={selected} sx={{ opacity: transaction.ofxId ? 0.6 : 1 }}>
      <TableCell padding="checkbox">
        {transaction.ofxId ? (
          <Tooltip title="Conciliado">
            <CheckIcon fontSize="small" />
          </Tooltip>
        ) : (
          <Checkbox size="small" color="primary" checked={selected} onClick={onToggleSelected} />
        )}
      </TableCell>
      <TableCell>{DateTime.fromISO(transaction.dueDate).toFormat("dd/MM/yyyy")}</TableCell>
      <TableCell>{transaction.categoryName}</TableCell>
      <TableCell>{transaction.participantName || transaction.partnerName}</TableCell>
      <TableCell>{transaction.description}</TableCell>
      <TableCell>{getBankName(transaction.companyBankAccountId)}</TableCell>
      <TableCell
        align="right"
        sx={{ color: transaction.transactionType === "Debt" ? "error.main" : "success.main" }}
      >
        {formatCurrencyBRL(transaction.value)}
      </TableCell>
      <TableCell align="center">
        <Chip
          size="small"
          label={getLabelStatus(transaction)}
          color={getStatusColor(transaction)}
        />
      </TableCell>
    </TableRow>
  );
};

export default TableItemRelease;
