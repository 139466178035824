import { FC, useState } from "react";
import { useAuth } from "context/Auth";

import { IconButton, MenuItem, TableCell, TableRow } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import DropdownMenu from "components/DropdownMenu";
import { DateTime } from "luxon";
import { Company } from "types/Company";

const translateStatus: Record<string, string> = {
  Prospect: "Aguardando aprovação",
  Registered: "Cadastro Incompleto",
  Approved: "Aprovada",
};

interface CompaniesTableItemProps {
  company: Company;
}

const CompaniesTableItem: FC<CompaniesTableItemProps> = ({ company }) => {
  const { changeCompany } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <TableRow>
        <TableCell>{company.name}</TableCell>
        <TableCell>{company.cnpj}</TableCell>
        <TableCell>{DateTime.fromISO(company.createdOn).toFormat("dd/MM/yyyy")}</TableCell>
        <TableCell>
          {company.cnpj != null
            ? translateStatus[company.registrationStatus]
            : "Abertura de empresa"}
        </TableCell>
        <TableCell align="right">
          <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MenuIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => changeCompany(company)}>Acessar empresa</MenuItem>
      </DropdownMenu>
    </>
  );
};

export default CompaniesTableItem;
