import { FC, useMemo } from "react";
import useMutationTransferFinancialCategory from "../../hooks/useMutationTransferFinancialCategory";
import { useForm, Controller } from "react-hook-form";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import { FinancialCategoryByGroupWithChildren } from "types/Company";

interface DialogTransferAnalyticalCategoryProps extends Omit<DialogProps, "onClose" | "id"> {
  onClose: () => void;
  id: number;
  name: string;
  financialCategories: FinancialCategoryByGroupWithChildren[];
}

const DialogTransferAnalyticalCategory: FC<DialogTransferAnalyticalCategoryProps> = ({
  onClose,
  id,
  name,
  financialCategories,
  ...props
}) => {
  const financialCategoriesOnlyChildren = useMemo(
    () => financialCategories.flatMap((f) => f.children),
    [financialCategories],
  );

  const { mutateAsync } = useMutationTransferFinancialCategory();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{ newId: number }>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      newId: undefined,
    },
  });

  const handleClose = () => {
    if (isSubmitting) return;
    reset({
      newId: undefined,
    });
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      newId: data.newId,
      id,
    });

    handleClose();
  });

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Tranferência entre categorias
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          <Alert severity="warning">
            Ao transferir para uma nova categoria, todos os lançamentos e categorias personalizadas
            vinculados à categoria antiga serão transferidos para a nova.
          </Alert>

          <TextField
            fullWidth
            label="Nome da categoria analítica atual"
            disabled
            value={name}
            sx={{ mb: 2, mt: 4 }}
          />

          {financialCategories && (
            <Controller
              control={control}
              name="newId"
              render={({ field, fieldState }) => (
                <FormControl error={!!fieldState.error?.message} fullWidth sx={{ mb: 2 }}>
                  <InputLabel error={!!fieldState.error?.message}>
                    Nome da nova categoria analítica
                  </InputLabel>
                  <Select
                    {...field}
                    label="Nome da nova categoria analítica"
                    error={!!fieldState.error?.message}
                    value={field.value || ""}
                  >
                    <MenuItem disabled value="">
                      Selecione:{" "}
                    </MenuItem>

                    {financialCategoriesOnlyChildren
                      .filter((c) => c.id !== id)
                      .map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                  </Select>

                  {!!fieldState.error?.message && (
                    <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" loading={isSubmitting} variant="contained">
            Salvar
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose} disabled={isSubmitting}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogTransferAnalyticalCategory;
