import { FC, useEffect, useState } from "react";

import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import ChartOfAccountTableItem from "./ChartOfAccountTableItem";
import SortableTableHead from "components/SortableTableHead";

import { ChartOfAccountsFromToGroup } from "types/Accountant";
import { Order } from "types/SemanticTypes";
import comparatorSortByProperty from "utils/comparatorSortByProperty";
import stableSort from "utils/stableSort";
import MassAction from "./MassAction";

type ChartOfAccountsFromToGroupKeys = keyof ChartOfAccountsFromToGroup;

interface HeadCells {
  label: string;
  id: ChartOfAccountsFromToGroupKeys;
  sortable: boolean;
}

const headCells: HeadCells[] = [
  {
    id: "account",
    label: "Conta Analítica",
    sortable: true,
  },
  {
    id: "fromDominio",
    label: "Conta Reduzida",
    sortable: true,
  },
  {
    id: "description",
    label: "Descrição da Conta",
    sortable: true,
  },
];

interface ChartOfAccountsTableProps {
  chartOfAccountsFromToGroup: ChartOfAccountsFromToGroup[];
}

const ChartOfAccountsTable: FC<ChartOfAccountsTableProps> = ({ chartOfAccountsFromToGroup }) => {
  const [selectedAccountId, setSelectedAccountId] = useState<number[]>([]);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<ChartOfAccountsFromToGroupKeys>("description");

  const sortHandler = (property: ChartOfAccountsFromToGroupKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = () => {
    setSelectedAccountId((prev) => {
      if (prev.length === chartOfAccountsFromToGroup.length) {
        return [];
      }
      return chartOfAccountsFromToGroup.map((c) => c.id);
    });
  };

  const handleToggleSelected = (event: unknown, checked: boolean, id: number) => {
    if (checked) {
      setSelectedAccountId((prev) => [...prev, id]);
      return;
    }
    setSelectedAccountId((prev) => prev.filter((p) => p !== id));
  };

  useEffect(() => {
    const chartOfAccountsIds = chartOfAccountsFromToGroup.map((c) => c.id);
    setSelectedAccountId((prev) => prev.filter((p) => chartOfAccountsIds.includes(p)));
  }, [chartOfAccountsFromToGroup]);

  return (
    <>
      <MassAction itemsSelected={selectedAccountId} />
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader>
          <SortableTableHead
            headCells={headCells}
            onRequestSort={(e, property) => sortHandler(property)}
            order={order}
            orderBy={orderBy}
            rowsCount={chartOfAccountsFromToGroup.length}
            rowsSelected={selectedAccountId.length}
            onSelectAllClick={handleSelectAllClick}
          >
            <TableCell>Classificação</TableCell>
            <TableCell>Natureza</TableCell>
            <TableCell align="center"></TableCell>
          </SortableTableHead>

          <TableBody>
            {chartOfAccountsFromToGroup.length === 0 && (
              <TableRow>
                <TableCell colSpan={headCells.length + 1}>
                  Nenhuma conta contábil cadastrada
                </TableCell>
              </TableRow>
            )}

            {stableSort(chartOfAccountsFromToGroup, comparatorSortByProperty(order, orderBy)).map(
              (chartOfAccount) => (
                <ChartOfAccountTableItem
                  key={chartOfAccount.id}
                  chartOfAccount={chartOfAccount}
                  selected={selectedAccountId.includes(chartOfAccount.id)}
                  onToggleSelected={(e, checked) =>
                    handleToggleSelected(e, checked, chartOfAccount.id)
                  }
                />
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ChartOfAccountsTable;
