import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getMessageTypes } from "services/Requests/communications";

const useQueryGetMessageTypes = (companyToken: string) => {
  const query = useQuery(queryKeys.messagesTypes, () => getMessageTypes({ companyToken }));
  return query;
};

export default useQueryGetMessageTypes;
