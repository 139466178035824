import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { updateCompanyFinancialCategory } from "services/Requests/accountant";

const useMutationUpdateCompanyFinancialCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCompanyFinancialCategory, {
    onSuccess(_, { companyToken }) {
      toast.success("Categoria atualizada com sucesso");
      queryClient.invalidateQueries([queryKeys.clientChartOfAccounts, companyToken]);
    },
  });

  return mutation;
};

export default useMutationUpdateCompanyFinancialCategory;
