import useQueryCompanyGlobalAccountanting from "pages/Home/hooks/useQueryCompanyGlobalAccountanting";
import useQueryGlobalAccountanting from "pages/Home/hooks/useQueryGlobalAccountanting";
import AccountantingAutoComplete, {
  AccountantingAutoCompleteProps,
} from "./AccountantingAutoComplete";

type AutocompleteProps = Omit<AccountantingAutoCompleteProps, "globalAccountanting" | "isLoading">;

interface QueryCompanyGlobalAccountantProps extends AutocompleteProps {
  companyToken: string;
}

const QueryCompanyGlobalAccountant = ({
  companyToken,
  ...props
}: QueryCompanyGlobalAccountantProps) => {
  const { data, isLoading } = useQueryCompanyGlobalAccountanting(companyToken);
  return <AccountantingAutoComplete globalAccountanting={data} isLoading={isLoading} {...props} />;
};

interface QueryGroupGlobalAccountantProps extends AutocompleteProps {
  groupId: number;
}

const QueryGroupGlobalAccountant = ({ groupId, ...props }: QueryGroupGlobalAccountantProps) => {
  const { data, isLoading } = useQueryGlobalAccountanting(groupId);
  return <AccountantingAutoComplete globalAccountanting={data} isLoading={isLoading} {...props} />;
};

interface GlobalAccountantingAutoCompleteProps<T extends boolean> extends AutocompleteProps {
  isCompany: T;
  groupId?: T extends false ? number : undefined;
  companyToken?: T extends true ? string : undefined;
}

function GlobalAccountantingAutoComplete<T extends boolean>({
  isCompany = false as T,
  groupId,
  companyToken,
  ...props
}: GlobalAccountantingAutoCompleteProps<T>) {
  if (isCompany && companyToken) {
    return <QueryCompanyGlobalAccountant companyToken={companyToken} {...props} />;
  }

  if (!isCompany && groupId) {
    return <QueryGroupGlobalAccountant groupId={groupId} {...props} />;
  }

  return null;
}

export default GlobalAccountantingAutoComplete;
