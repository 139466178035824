import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getTemplatesToFrom } from "services/Requests/companies";

const useQueryTemplatesToFrom = () => {
  const query = useQuery(queryKeys.templatesToFrom, getTemplatesToFrom);
  return query;
};

export default useQueryTemplatesToFrom;
