import { FC } from "react";
import useQueryCompanyPartners from "pages/Home/hooks/useQueryCompanyPartners";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";

import PartnerForm from "pages/Home/pages/PartnerForm";
import { Box, CircularProgress, Stack } from "@mui/material";
import { CompanyAssignments } from "types/Admin";
import { Company } from "types/Company";

interface PartnerProps {
  company: CompanyAssignments | Company;
}

const Partner: FC<PartnerProps> = ({ company }) => {
  const { data: companyInformation, isLoading: loadingCompany } = useQueryGetCompany(company.token);
  const { data: partners, isLoading: loadingPartners } = useQueryCompanyPartners(company.token);
  const isLoading = loadingCompany || loadingPartners;

  return (
    <Box>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}
      {companyInformation && partners && !isLoading && (
        <PartnerForm companyInformation={companyInformation} partners={partners} disabledForm />
      )}
    </Box>
  );
};

export default Partner;
