import { FC } from "react";

import { Stack } from "@mui/material";
import ControllerInput from "components/ControllerInput";

import { Control, useWatch } from "react-hook-form";
import { SettingsForm } from "./SettingsForm";

interface DunningFieldsProps {
  control: Control<SettingsForm>;
}

const DunningFields: FC<DunningFieldsProps> = ({ control }) => {
  const chargeDay = useWatch({
    control,
    name: "charge",
  });
  const expireDay = useWatch({
    control,
    name: "expire",
  });

  return (
    <Stack gap={3}>
      <ControllerInput
        control={control}
        name="charge"
        inputProps={{
          label: "Informe o número de dias que será feito a cobrança após o vencimento",
          helperText: "(serão feitas 3 tentativas nesse intervalo de dias)",
          type: "number",
        }}
      />
      <ControllerInput
        control={control}
        name="expire"
        inputProps={{
          label: "Informe o número de dias que expira a fatura após as tentativas de cobrança",
          helperText: `* A soma total não pode ultrapassar de 30 dias (${
            chargeDay * 3 + expireDay * 1
          })`,
          type: "number",
        }}
      />
    </Stack>
  );
};

export default DunningFields;
