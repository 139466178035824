import { useMutation, useQueryClient } from "react-query";
import { updateCompanyRegistrationStatus } from "services/Requests/admin";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";

const useMutationUpdateCompanyRegistrationStatus = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCompanyRegistrationStatus, {
    onSuccess() {
      toast.success("O status do cliente foi alterado com sucesso!");
      queryClient.invalidateQueries(queryKeys.clients);
    },
  });
  return mutation;
};

export default useMutationUpdateCompanyRegistrationStatus;
