import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getTemplatesAccountanting } from "services/Requests/companies";

const useQueryTemplatesAccountanting = () => {
  const query = useQuery(queryKeys.templateAccountanting, getTemplatesAccountanting);
  return query;
};

export default useQueryTemplatesAccountanting;
