import { FC } from "react";
import useQueryClient from "pages/Home/hooks/useQueryClient";

import { Box, Stack, CircularProgress } from "@mui/material";

import { Order } from "types/OpenCompany";
import Subscriptions from "pages/Home/pages/Marketplace/components/Clients/TableClients/components/Subscriptions";
import { ClientInformationProvider } from "pages/Home/pages/Marketplace/components/Clients/context/ClientInformation";

interface SignatureProps {
  order: Order;
}

const Signature: FC<SignatureProps> = ({ order }) => {
  const { data: client, isLoading } = useQueryClient(order.companyToken);

  return (
    <Box>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}

      {client && !isLoading && (
        <ClientInformationProvider client={client} companyToken={order.companyToken}>
          <Subscriptions withPlan={false} />
        </ClientInformationProvider>
      )}
    </Box>
  );
};

export default Signature;
