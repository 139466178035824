import { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogProps,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { UserAdmin } from "types/User";
import { UserTypeContent, userTypeTranslate } from "../UsersContent";
import useMutationBlockUser from "../UsersContent/hooks/useMutationBlockUser";
import useMutationUnBlockUser from "../UsersContent/hooks/useMutationUnBlockUser";
import { UserCompany } from "types/Admin";

interface DialogDeleteAdminUser extends DialogProps {
  user: UserAdmin | UserCompany;
  userType: UserTypeContent;
}

const DialogBlockOrUnBlockUser: FC<DialogDeleteAdminUser> = ({
  user,
  onClose,
  userType,
  ...props
}) => {
  const { isLoading: isLoadingBlock, mutateAsync: mutateBlock } = useMutationBlockUser();
  const { isLoading: isLoadingUnBlock, mutateAsync: mutationUnBlock } = useMutationUnBlockUser();

  const handleClose = () => {
    if (isLoadingBlock || isLoadingUnBlock || !onClose) return;
    onClose({}, "escapeKeyDown");
  };

  const handleBlockUser = async () => {
    await mutateBlock(user);
    handleClose();
  };

  const handleUnBlockUser = async () => {
    await mutationUnBlock(user);
    handleClose();
  };

  return (
    <Dialog maxWidth="sm" fullWidth {...props} onClose={handleClose}>
      <DialogTitle>
        {user.status === "Active" ? "Bloquear" : "Desbloquear"} {userTypeTranslate[userType]}
      </DialogTitle>

      <DialogContent dividers>
        Tem certeza que deseja {user.status === "Active" ? "bloquear" : "desbloquear"} o{" "}
        {userTypeTranslate[userType]}?
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isLoadingBlock || isLoadingUnBlock}
          variant="outlined"
          onClick={handleClose}
        >
          Não
        </Button>
        <LoadingButton
          loading={isLoadingBlock || isLoadingUnBlock}
          variant="contained"
          onClick={() => {
            if (user.status === "Active") {
              handleBlockUser();
            } else {
              handleUnBlockUser();
            }
          }}
        >
          Sim
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBlockOrUnBlockUser;
