import { FC } from "react";
import useNavigateHomePage from "hooks/useNavigateHomePage";

import { Link, Stack, Typography } from "@mui/material";

const NotFound: FC = () => {
  const navigatePrimaryPath = useNavigateHomePage();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100%",
        width: "100%",
        p: 5,
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "4xl",
          fontWeight: "bold",
          mb: -2,
        }}
      >
        404
      </Typography>
      <Typography
        variant="h4"
        sx={{
          color: "text.primary",
          mb: 2,
        }}
      >
        Page not found
      </Typography>

      <Link
        href="/"
        onClick={(e) => {
          e.preventDefault();
          navigatePrimaryPath();
        }}
      >
        Ir para a página inicial
      </Link>
    </Stack>
  );
};

export default NotFound;
