import { ChangeEvent, FC, useState } from "react";

import { Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import toast from "react-hot-toast";
import useMutationUploadContract from "./hooks/useMutationUploadContract";

interface NewContractProps extends DialogProps {
  companyToken: string;
}

const NewContract: FC<NewContractProps> = ({ companyToken, onClose, ...props }) => {
  const { mutateAsync, isLoading } = useMutationUploadContract();
  const [file, setFile] = useState<File | null>(null);

  const handleClose = () => {
    if (isLoading) return;
    onClose?.({}, "escapeKeyDown");
  };

  const handleChangeUploadPDF = (e: ChangeEvent<HTMLInputElement>) => {
    const targetEl = e.target;

    if (!targetEl.files || targetEl.files.length === 0) {
      toast("Selecione um arquivo", {
        icon: "⚠️",
      });
      return;
    }

    if (targetEl.files[0].size / 1000000 > 10) {
      toast("O arquivo deve ser no máximo de 10mb", {
        icon: "⚠️",
      });
      return;
    }

    setFile(targetEl.files[0]);
  };

  const handleSubmit = async () => {
    if (!file) {
      toast("Selecione um arquivo", {
        icon: "⚠️",
      });
      return;
    }

    try {
      await mutateAsync({
        companyToken,
        file,
      });
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitle>Criar Contrato</DialogTitle>

      <DialogContent>
        <Typography>Anexar arquivo</Typography>
        <Box mt={0.5}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Button
              sx={{ width: "fit-content" }}
              variant="contained"
              component="label"
              startIcon={<Upload />}
            >
              <input hidden accept=".pdf" type="file" onChange={handleChangeUploadPDF} />
              Procurar
            </Button>

            {file && <Typography>{file.name}</Typography>}
          </Stack>

          <Typography variant="body2" sx={{ fontSize: "12px", color: grey[500] }}>
            Apenas arquivos PDF, no máximo 10 MB.
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton variant="contained" loading={isLoading} onClick={handleSubmit}>
          Salvar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewContract;
