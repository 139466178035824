import { FC, useMemo } from "react";
import useMutationUpdateCompanyData from "pages/Home/pages/Profile/components/CompanyData/hooks/useMutationUpdateCompanyData";
import useMutationGuessCounty from "pages/Home/hooks/useMutationGuessCounty";
import jsonp from "jsonp";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CNAEAutocomplete from "pages/Home/components/CNAEAutocomplete";
import AddressForm from "pages/Home/pages/Profile/components/CompanyData/components/AddressForm";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { Controller, DefaultValues, useForm, useWatch } from "react-hook-form";
import MaskedInput from "react-text-mask";
import { BACKOFFICE } from "utils/constants";
import { cnpjMask } from "utils/masks";
import { CompanyInformation } from "types/Company";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";
import validateCNPJ from "utils/validateCNPJ";
import endpoints from "services/endpoints";
import { CNPJCPFInfo } from "types/External";

interface Form {
  cnpj: string;
  activity: string | null;
  tributationType: string;
  name: string;
  chosenSubd: string;
  backoffice: string;
  systemName: string;

  street: string;
  neighborhood: string;
  zipCode: string;
  city: string;
  complement: string;
  state: string;
  streetNumber: string;
}

interface CompanyFormProps {
  company: CompanyInformation;
  onPrev: () => void;
  onNext: () => void;
}

const CompanyForm: FC<CompanyFormProps> = ({ company, onNext, onPrev }) => {
  const { mutateAsync: mutateGuessCounty } = useMutationGuessCounty();
  const { mutateAsync } = useMutationUpdateCompanyData();

  const defaultValues = useMemo<DefaultValues<Form>>(
    () => ({
      cnpj: company?.cnpj ?? "",
      activity: company?.activity ?? "",
      tributationType: company?.tributationType?.toString() ?? "",
      name: company?.companyName,
      chosenSubd: company?.chosenSubd ?? "",
      backoffice: company?.backoffice ?? "",
      systemName: company?.systemName ?? "",
      state: company.state,
      city: company.countyId,
      complement: company.complement,
      neighborhood: company.neighborhood,
      street: company.street,
      streetNumber: company.streetNumber,
      zipCode: company.zipCode,
    }),
    [],
  );

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
  } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const backoffice = useWatch({
    control,
    name: "backoffice",
  });
  const cnpj = useWatch({
    control,
    name: "cnpj",
  });

  const onSubmit = handleSubmit(async (data) => {
    const address = {
      street: data.street,
      neighborhood: data.neighborhood,
      zipCode: data.zipCode,
      countyId: data.city,
      complement: data.complement,
      state: data.state,
      streetNumber: data?.streetNumber?.toString(),
    };

    await mutateAsync({
      ...company,
      ...address,
      companyName: data.name,
      systemName: data.backoffice === "Outro" ? data.systemName : "",
      cnpj: data.cnpj,
      activity: data.activity || "",
      backoffice: data.backoffice,
      chosenSubd: data.chosenSubd,
      tributationType: Number(data.tributationType) ?? 0,
      address: {
        ...company.address,
        ...address,
      },
    });
    onNext();
  });

  const handleBlurCNPJ = async () => {
    if (!cnpj) return;
    const cleanCNPJ = cnpj.replace(/\D/g, "");

    if (!validateCNPJ(cleanCNPJ)) {
      return;
    }

    try {
      jsonp(
        `${endpoints.external.receitaws}/${cleanCNPJ}`,
        undefined,
        async (_, data: CNPJCPFInfo) => {
          const company = data;

          setValue("complement", company.complemento);
          setValue("neighborhood", company.bairro);
          setValue("streetNumber", company.numero);
          setValue("street", company.logradouro);

          setValue("name", company.nome);
          setValue("state", company.uf);
          setValue("zipCode", company.cep?.replace(/\D/g, ""));

          let cnae = company.atividade_principal
            ? company.atividade_principal[0]?.code?.match(/^[\d.-]+/)?.[0]
            : null;
          if (cnae) {
            cnae =
              cnae.substring(0, cnae.lastIndexOf("-")) +
              "/" +
              cnae.substring(cnae.lastIndexOf("-") + 1);
            setValue("activity", cnae);
          }

          const county = await mutateGuessCounty({ name: company.municipio, state: company.uf });
          if (data && county?.id) {
            setValue("city", county.id);
          }
        },
      );
    } catch (err) {
      console.error("[3zF7] Erro on search CNPJ", err);
    }
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <Typography variant="h6" fontWeight={600} sx={{ borderBottom: 1, borderColor: "divider" }}>
          Dados da sua empresa
        </Typography>

        <Box display="grid" gridTemplateColumns="1fr 3fr" mt={3} gap={3}>
          <Controller
            name="cnpj"
            control={control}
            render={({ fieldState, field }) => (
              <MaskedInput
                mask={cnpjMask}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="CNPJ"
                    variant="outlined"
                    inputRef={ref}
                    {...props}
                    onBlur={(e) => {
                      props.onBlur(e);
                      handleBlurCNPJ();
                    }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              ></MaskedInput>
            )}
          />

          <Controller
            name="activity"
            control={control}
            render={({ fieldState, field }) => (
              <CNAEAutocomplete
                {...field}
                onChange={(_, newValue) => {
                  field.onChange(typeof newValue === "string" ? newValue : newValue?.code ?? null);
                }}
                textFieldProps={{
                  helperText: fieldState.error?.message,
                  error: !!fieldState.error?.message,
                  label: "Atividade principal",
                }}
              />
            )}
          />

          <Controller
            name="tributationType"
            control={control}
            render={({ fieldState, field }) => (
              <FormControl error={!!fieldState.error?.message}>
                <InputLabel error={!!fieldState.error?.message}>Regime tributário</InputLabel>
                <Select {...field} value={field.value ?? ""} label="Regime tributário">
                  <MenuItem disabled value="">
                    Selecione:{" "}
                  </MenuItem>
                  <MenuItem value="1">Simples nacional</MenuItem>
                  <MenuItem value="2">Lucro presumido</MenuItem>
                </Select>
                {!!fieldState.error?.message && (
                  <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />

          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Razão Social"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                InputLabelProps={{
                  shrink: field.value ? true : undefined,
                }}
              />
            )}
          />
        </Box>

        <Box display="grid" gridTemplateColumns="3fr 1fr" mt={3} gap={3}>
          <Controller
            name="chosenSubd"
            control={control}
            render={({ field, fieldState }) => (
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography>https:// </Typography>
                <TextField
                  fullWidth
                  {...field}
                  label="URL pretendida (subdomínio)"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
                <Typography>.osayk.com.br</Typography>
              </Stack>
            )}
          />

          <Controller
            name="backoffice"
            control={control}
            render={({ fieldState, field }) => (
              <FormControl error={!!fieldState.error?.message}>
                <InputLabel error={!!fieldState.error?.message}>Regime tributário</InputLabel>
                <Select {...field} value={field.value ?? ""} label="Regime tributário">
                  <MenuItem disabled value="">
                    Selecione:{" "}
                  </MenuItem>
                  {BACKOFFICE.map(({ slug, label }) => (
                    <MenuItem key={slug} value={slug}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                {!!fieldState.error?.message && (
                  <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />

          {backoffice === "Outro" && (
            <Controller
              name="systemName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Nome do sistema"
                  sx={{ gridColumn: "2/-1" }}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}
        </Box>

        <Box mt={3}>
          <AddressForm control={control} />
        </Box>

        <Stack mt={4} direction="row" alignItems="center" justifyContent="space-between">
          <Button variant="contained" startIcon={<KeyboardArrowLeftIcon />} onClick={onPrev}>
            Anterior
          </Button>
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            type="submit"
            endIcon={<KeyboardArrowRightIcon />}
          >
            Próximo passo: Sobre a empresa
          </LoadingButton>
        </Stack>
      </form>

      {isSubmitting && <FullscreenLoadingIndicator />}
    </>
  );
};

export default CompanyForm;
