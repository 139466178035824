import { FC, useState } from "react";
import { useAuth } from "context/Auth";
import usePrefetchAllSubAccounts from "../Fees/hooks/usePrefetchAllSubAccounts";
import useQueryLeads from "./hooks/useQueryLeads";

import { CircularProgress, Collapse, Divider, LinearProgress, Stack } from "@mui/material";
import { DateTime } from "luxon";

import LeadsTable from "./LeadsTable";
import FilterPeriod from "components/FilterPeriod";
import SearchInput from "components/SearchInput";
import FilterForm from "./components/FilterForm";
import Groups from "./Groups";
import ConfigLeads from "./ConfigLeads";

const Opportunities: FC = () => {
  const {
    user: { userInfo },
  } = useAuth();
  const isAdmin = userInfo.userType === "Admin";

  usePrefetchAllSubAccounts();
  const [search, setSearch] = useState("");
  const [typeForm, setTypeForm] = useState<undefined | string>(undefined);
  const [date, setDate] = useState({
    start: DateTime.local().startOf("month").toISO(),
    end: DateTime.local().endOf("month").toISO(),
  });

  const {
    data: leads = [],
    isLoading,
    isFetching,
  } = useQueryLeads({
    startDate: date.start,
    endDate: date.end,
    term: search,
    typeForm,
  });

  return (
    <>
      <Collapse in={!isLoading && isFetching}>
        <LinearProgress sx={{ mb: 1 }} />
      </Collapse>

      <Stack gap={2}>
        <Stack direction="row" gap={1} alignItems="center">
          <FilterPeriod endDate={date.end} startDate={date.start} onChangeDates={setDate} />
          {isAdmin && (
            <FilterForm
              label="Formulários"
              value={typeForm}
              onChange={(e) => setTypeForm(e.target.value === "all" ? undefined : e.target.value)}
              size="small"
            />
          )}
          <SearchInput value={search} onChange={(value) => setSearch(value)} size="small" />
        </Stack>

        {isAdmin && (
          <Stack direction="row" gap={1} alignItems="center">
            <Groups />
            <ConfigLeads />
          </Stack>
        )}
      </Stack>

      <Divider sx={{ my: 2 }} />

      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {!isLoading && <LeadsTable leads={leads} />}
    </>
  );
};

export default Opportunities;
