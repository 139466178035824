import { FC } from "react";
import Synthetic from "./Synthetic";
import { Stack } from "@mui/material";
import useQueryGetDre from "./hooks/useQueryGetDre";
import { useAuth } from "context/Auth";

const DREList: FC = () => {
  const { user: { company }, } = useAuth();
  const { data: drelist = []} = useQueryGetDre(company?.token);

  return (
    <Stack gap={1}>
      {drelist.map((item) => (
        <Synthetic key={item.id} dre={item}  />
      ))}
    </Stack>
  );
};

export default DREList;
