import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getTemplatesFinancialCategories } from "services/Requests/companies";

const useQueryTemplateFinancial = () => {
  const query = useQuery(queryKeys.templateFinancial, getTemplatesFinancialCategories);
  return query;
};

export default useQueryTemplateFinancial;
