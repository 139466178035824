import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { resendPaymentMethod } from "services/Requests/companies";

const useMutationResendPaymentMethod = () => {
  const mutation = useMutation(resendPaymentMethod, {
    onSuccess() {
      toast.success("Modo de pagamento reenviado com sucesso");
    },
  });
  return mutation;
};

export default useMutationResendPaymentMethod;
