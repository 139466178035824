import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { deleteAccountFromTo } from "services/Requests/accountant";

const useMutationDeleteAccountFromTo = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteAccountFromTo, {
    onSuccess() {
      toast.success("Conta contábil deletada com sucesso.");
      queryClient.invalidateQueries(queryKeys.chartOfAccountsFromToGroup, {
        exact: false,
      });
    },
  });
  return mutation;
};

export default useMutationDeleteAccountFromTo;
