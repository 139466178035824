import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC } from "react";
import { CardContent } from "../CardWrapper";

const Contract: FC = () => {
  return (
    <CardContent>
      <TableContainer sx={{ mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell>Link para contrato</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>Licenciamento</TableCell>
              <TableCell>
                <Link href="https://bit.ly/2zXLulh" target="_blank">
                  Contrato em PDF
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  );
};

export default Contract;
