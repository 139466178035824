import { FC, useState, MouseEventHandler } from "react";
import { useLocation } from "react-router-dom";
import { useSideMenu } from "context/SideMenuContext/useSideMenu";
import { useCompanyInfo } from "context/CompanyInfo";

import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Collapse,
  Fade,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

import { SideMenuItem as SideMenuItemProps } from "../../utils/getSideMenuItems";
import WithLink from "./WithLink";
import stringToSlug from "../../utils/stringToSlug";

const SideMenuItemWithChildren: FC<SideMenuItemProps> = ({ title, children, Icon }) => {
  const { companyStyles } = useCompanyInfo();
  const { pathname } = useLocation();
  const { isOpen } = useSideMenu();
  const [collapsed, setCollapsed] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dropdownOpen = Boolean(anchorEl);

  const handleMouseEnter: MouseEventHandler<HTMLLIElement> | undefined = (event) => {
    if (isOpen) return;
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      component="li"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setAnchorEl(null)}
      position="relative"
    >
      <ListItemButton
        onClick={() => setCollapsed(!collapsed)}
        sx={{
          py: 1,
          gap: 1.5,
          borderLeft: "5px solid transparent",
          transition: "0.2s",
          background: collapsed || dropdownOpen ? companyStyles.sidebarLinkHoverBg : undefined,
          "&:hover, &:focus": {
            background: `${companyStyles.sidebarLinkHoverBg} !important`,
            ".arrow-down": { opacity: collapsed ? 1 : 0 },
          },
        }}
      >
        {!!Icon && (
          <ListItemIcon sx={{ minWidth: "fit-content", m: 0 }}>
            <Icon fontSize="small" />
          </ListItemIcon>
        )}
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            fontSize: 14,
            fontWeight: "light",
            lineHeight: "20px",
          }}
          sx={{ opacity: isOpen ? undefined : 0 }}
        />

        <KeyboardArrowDown
          sx={{
            mr: -1,
            opacity: 0,
            transform: collapsed ? "rotate(-180deg)" : "rotate(0)",
            transition: "0.2s",
          }}
          className="arrow-down"
        />
      </ListItemButton>

      <Collapse in={collapsed && isOpen} timeout={isOpen ? undefined : 0}>
        {children?.map((child, index, arr) => (
          <WithLink link={child.link} key={stringToSlug(child.title)}>
            <ListItemButton
              sx={{
                py: 1,
                px: 3,
                gap: 1.5,
                borderLeft: "5px solid transparent",
                borderLeftColor:
                  pathname === child.link ? companyStyles.sidebarLinkHoverBorder : "transparent",
                transition: "0.2s",
                background: companyStyles.sidebarSubmenuBg,
              }}
              selected={pathname === child.link}
              divider={index === arr.length - 1}
            >
              <ListItemIcon sx={{ minWidth: "fit-content" }}>•</ListItemIcon>
              <ListItemText
                color="text.primary"
                primary={child.title}
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: "light",
                  lineHeight: "20px",
                }}
              />
            </ListItemButton>
          </WithLink>
        ))}
      </Collapse>

      <Popper open={dropdownOpen} anchorEl={anchorEl} placement="right-start" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                background: `${companyStyles.sidebarBg} !important`,
                color: companyStyles.sidebarLinkColor,
                minWidth: 150,
                pb: 1,
              }}
            >
              <Typography sx={{ px: 2, py: 1 }} color="inherit">
                {title}
              </Typography>

              <Box bgcolor={companyStyles.sidebarSubmenuBg}>
                {children?.map((child) => (
                  <WithLink link={child.link} key={stringToSlug(child.title)}>
                    <Typography
                      component="span"
                      color="inherit"
                      display="block"
                      sx={{
                        py: 1,
                        px: 2,
                        borderLeft:
                          pathname === child.link
                            ? `5px solid ${companyStyles.sidebarLinkHoverBorder}`
                            : undefined,
                      }}
                    >
                      •{" "}
                      <Typography component="span" ml={0.5} color="inherit">
                        {child.title}
                      </Typography>
                    </Typography>
                  </WithLink>
                ))}
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default SideMenuItemWithChildren;
