import axios from "axios";

import Cookies from "js-cookie";
import { BASE_URL_API, COOKIE_TOKEN_NAME } from "../utils/constants";
import { removeAuthInfo } from "context/Auth/utils";

const api = axios.create({
  baseURL: BASE_URL_API,
  headers: {
    "content-type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Headers": "*",
  },
});

api.interceptors.request.use((config) => {
  const token = Cookies.get(COOKIE_TOKEN_NAME);

  if (token && !config?.headers?.Authorization) {
    config.headers = {
      Authorization: `Osayk ${token}`,
      ...config.headers,
    };
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      removeAuthInfo();
      window.location.reload();
      window.location.replace("/#/login");
    }
    return Promise.reject(error);
  },
);

export default api;
