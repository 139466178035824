import * as yup from "yup";

const validationSchema = yup.object().shape({
  penaltiesPercent: yup
    .number()
    .required("O campo é obrigatório")
    .typeError("Digite um valor válido")
    .max(100, "A porcentagem máxima é de 100%")
    .test("isPositive", "Digite apenas valores positivos", (value) => {
      if (value === undefined) return true;
      return value >= 0;
    }),

  penaltiesMora: yup.boolean(),

  discountsDays: yup
    .number()
    .required("O campo é obrigatório")
    .typeError("Digite um valor válido")
    .integer("Digite apenas valores inteiros")
    .test("isPositive", "Digite apenas valores positivos", (value) => {
      if (value === undefined) return true;
      return value >= 0;
    }),

  discountsPercent: yup.mixed().when("discountsValues", (discountsValue) => {
    if (discountsValue === "") {
      return yup
        .number()
        .typeError("Digite apenas números")
        .required("O campo é obrigatório")
        .test("isPositive", "Digite apenas valores positivos", (value) => {
          if (value === undefined) return true;
          return value >= 0;
        });
    }

    return yup.mixed().test("numberValid", "Digite apenas número", (value) => {
      if (!value) return true;
      return !isNaN(Number(value));
    });
  }),

  discountsValues: yup.string(),
});

export default validationSchema;
