import { createContext, Dispatch } from "react";
import { FinancialCategoryByGroupWithChildren } from "types/Company";
import { TemplateActions } from "./TemplateActions";

export interface TemplateContextInterface {
  financialCategories: FinancialCategoryByGroupWithChildren[];
  dispatch: Dispatch<TemplateActions>;
  onEndDragItem: () => void;
  onEndDragContainer: () => void;
  isCompany: boolean;
  companyToken?: string;
  companyId?: number;
}

export const TemplateContext = createContext({} as TemplateContextInterface);
