import { Controller, Control, useWatch, Path } from "react-hook-form";

import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import RecipientAutocomplete from "../../RecipientAutocomplete";
import CompanyPartnersSelect from "../../CompanyPartnersSelect";
import { CompanyParticipants } from "types/Company";
import { TransactionType } from "types/CommonData";
import { DateTime } from "luxon";
import { useState } from "react";

interface RequiredFields {
  paymentType: "receive" | "received";
  paymentOrigin: "participant" | "partner";
  paymentDate: Date | null;
  companyParticipant: CompanyParticipants | null | number;
  partner: number | null;
  dueDate: Date;
  referenceDate: Date;
}

interface DatesFormProps<T extends RequiredFields = RequiredFields> {
  control: Control<T>;
  companyToken: string;
  transactionType?: TransactionType;
}

function DatesForm<T extends RequiredFields = RequiredFields>({
  control,
  companyToken,
  transactionType = "Credit",
}: DatesFormProps<T>) {
  const paymentType = useWatch({
    control,
    name: "paymentType" as Path<T>,
  });
  const paymentOrigin = useWatch({
    control,
    name: "paymentOrigin" as Path<T>,
  });
  const currentDay = DateTime.local().toISO();
  const [paymentReceivedDate, setPaymentReceivedDate] = useState(currentDay);

  return (
    <>
      <Controller
        name={"paymentDate" as Path<T>}
        control={control}
        render={({ field, fieldState }) => (
          <DatePicker
            disabled={paymentType === "receive"}
            label={`${transactionType === "Credit" ? "Recebido" : "Pago"} em`}
            inputFormat="dd/MM/yyyy"
            {...field}
            value={paymentReceivedDate}
            onChange={(newValue) => newValue && setPaymentReceivedDate(newValue)}
            allowSameDateSelection
            renderInput={(params) => (
              <TextField
                {...params}
                defaultValue={currentDay}
                helperText={fieldState.error?.message}
                error={params.error || !!fieldState.error?.message}
              />
            )}
          />
        )}
      />

      {paymentOrigin === "participant" && (
        <Controller
          control={control}
          name={"companyParticipant" as Path<T>}
          render={({ fieldState, field }) => (
            <RecipientAutocomplete
              companyToken={companyToken}
              value={field.value as CompanyParticipants}
              ref={field.ref}
              onBlur={field.onBlur}
              onChange={(_, value) => field.onChange(value)}
              role={transactionType === "Credit" ? 1 : 2}
              withCreateParticipant
              textFieldProps={{
                label: transactionType === "Credit" ? "Cliente" : "Fornecedor",
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message,
              }}
            />
          )}
        />
      )}

      {paymentOrigin === "partner" && (
        <Controller
          control={control}
          name={"partner" as Path<T>}
          render={({ fieldState, field }) => (
            <CompanyPartnersSelect
              label="Sócio"
              companyToken={companyToken}
              {...field}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />
      )}

      <Controller
        name={"dueDate" as Path<T>}
        control={control}
        render={({ field, fieldState }) => (
          <DatePicker
            label="Vence em"
            inputFormat="dd/MM/yyyy"
            {...field}
            allowSameDateSelection
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={fieldState.error?.message}
                error={params.error || !!fieldState.error?.message}
              />
            )}
          />
        )}
      />
      <Controller
        name={"referenceDate" as Path<T>}
        control={control}
        render={({ field, fieldState }) => (
          <DatePicker
            label="Data de competência"
            inputFormat="dd/MM/yyyy"
            {...field}
            allowSameDateSelection
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={fieldState.error?.message}
                error={params.error || !!fieldState.error?.message}
              />
            )}
          />
        )}
      />
    </>
  );
}

export default DatesForm;
