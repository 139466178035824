import { FC, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "context/Auth";
import useMutationCreateProfile from "../hooks/useMutationCreateProfile";
import useMutationUpdateProfile from "../hooks/useMutationUpdateProfile";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import ControllerInput from "components/ControllerInput";
import AccessCheckbox from "./AccessCheckbox";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import { CompanyProfile } from "types/Company";
import { getAccessData } from "../utils/access";
import getSubdomain from "utils/getSubdomain";
import { CreateProfilePayload } from "services/Requests/companies";

interface DialogUpdateCreateProfileProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  profile?: CompanyProfile;
}

const DialogUpdateCreateProfile: FC<DialogUpdateCreateProfileProps> = ({
  onClose,
  profile,
  ...props
}) => {
  const {
    user: { userInfo, company },
  } = useAuth();
  const { mutateAsync: mutateCreate } = useMutationCreateProfile();
  const { mutateAsync: mutateUpdate } = useMutationUpdateProfile();

  const access = useMemo(() => getAccessData(userInfo.userType), [userInfo]);
  const initialAccess = useMemo(() => {
    if (!profile) {
      return access.map((a) => [a.value, a.children.map((c) => c.value)]).flat(2);
    }
    return profile.areas;
  }, [profile]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: profile?.name || "",
    },
  });

  const [activeAccess, setActiveAccess] = useState<number[]>(initialAccess);

  const resetFields = () => {
    reset({ name: profile?.name || "" });
    setActiveAccess(initialAccess);
  };

  const handleClose = () => {
    if (isSubmitting) return;
    resetFields();
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    const userType = userInfo.userType;
    const subdomain =
      userType === "Admin" || userType === "Accountant" ? getSubdomain() : undefined;
    const companyToken = subdomain ? undefined : company?.token;

    const payload: CreateProfilePayload = {
      areas: activeAccess,
      name: data.name,
      companyToken,
      subdomain,
    };

    if (profile) {
      await mutateUpdate({
        ...payload,
        id: profile.id,
      });
    } else {
      await mutateCreate(payload);
    }
    handleClose();
  });

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>Cadastro Perfil</DialogTitleWithCloseButton>

      <form onSubmit={onSubmit}>
        <DialogContent>
          <ControllerInput
            control={control}
            name="name"
            inputProps={{ fullWidth: true, label: "Nome do perfil (obrigatório)" }}
          />

          <Box mt={2}>
            <Typography
              variant="h6"
              fontWeight={400}
              sx={{ borderBottom: 1, borderBottomColor: "divider" }}
            >
              Controle de acesso
            </Typography>

            <Stack gap={3} mt={1}>
              {access.map((a) => (
                <AccessCheckbox
                  access={a}
                  key={a.value}
                  activeAccess={activeAccess}
                  onChangeActiveAccess={(access) => setActiveAccess(access)}
                />
              ))}
            </Stack>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined" disabled={isSubmitting}>
            Cancelar
          </Button>
          <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
            Salvar
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogUpdateCreateProfile;
