import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";

import { updateCompanyProfile, UpdateCompanyProfilePayload } from "services/Requests/companies";
import errorHandlerCompany from "utils/errorHandlerCompany";
import getSubdomain from "utils/getSubdomain";
import toast from "react-hot-toast";

const useMutationUpdateCompanyProfile = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<UpdateCompanyProfilePayload, "subdomain">) =>
      updateCompanyProfile({
        ...payload,
        subdomain,
      }),
    {
      onSuccess(data, { companyToken }) {
        if (data) {
          toast.success("Dados atualizados com sucesso!");
          queryClient.invalidateQueries([queryKeys.company, companyToken]);
          queryClient.invalidateQueries(queryKeys.ordersByAssignments, { exact: false });
        }
      },
      onError: errorHandlerCompany,
    },
  );
  return mutation;
};

export default useMutationUpdateCompanyProfile;
