import { FC, useState, useMemo } from "react";
import { useFilterPeriod } from "../contexts/FilterPeriod";
import useQueryGetTransactions from "../hooks/useQueryGetTransactions";

import { Box, CircularProgress, Collapse, Divider, LinearProgress, Stack } from "@mui/material";

import CashFlowTable from "./CashFlowTable";
import BankAccountFilter from "../components/BankAccountFilter";
import FilterPeriod from "components/FilterPeriod";
import CategoryFilter from "../components/CategoryFilter";
import SearchInput from "components/SearchInput";

import { FinancialCategory } from "types/Company";

interface CashFlowFiltersProps {
  companyToken: string;
}

const CashFlowFilters: FC<CashFlowFiltersProps> = ({ companyToken }) => {
  const [search, setSearch] = useState("");
  const [categoryFilter, setCategoryFilter] = useState<FinancialCategory | null>(null);
  const [selectedAccountBankId, setSelectedAccountBankId] = useState<null | number>(null);
  const {
    date: { end, start },
    setDate,
  } = useFilterPeriod();

  const {
    data: transactions,
    isLoading,
    isFetching,
  } = useQueryGetTransactions(companyToken, start, end, "FC", selectedAccountBankId, search);

  const totalTotalBalance = useMemo(() => {
    if (!transactions) return;

    let totalFilteredValue2 = 0;

    transactions.items.forEach((t) => {
      let localValue = t.value != null && t.value != undefined ? t.value : 0;
      if (localValue != 0 && t.transactionType == "Debt") {
        localValue = localValue * -1;
      }

      totalFilteredValue2 = totalFilteredValue2 + localValue;
    });

    return totalFilteredValue2 + transactions.initialBalance;
  }, [transactions]);

  return (
    <Box>
      <Collapse in={!isLoading && isFetching}>
        <LinearProgress />
      </Collapse>

      <Stack gap={2} direction="row" mt={0.5} flexWrap="wrap">
        <FilterPeriod
          size="medium"
          label="Período"
          endDate={end}
          startDate={start}
          onChangeDates={(dates) => setDate(dates)}
        />

        <BankAccountFilter
          companyToken={companyToken}
          value={selectedAccountBankId}
          setValue={(value) => setSelectedAccountBankId(value)}
        />

        <CategoryFilter
          companyToken={companyToken}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
        />

        <SearchInput onChange={(value) => setSearch(value)} />
      </Stack>

      <Divider sx={{ mt: 2, mb: 4 }} />

      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
      {transactions && (
        <CashFlowTable
          transactions={transactions}
          companyToken={companyToken}
          totalTotalBalance={totalTotalBalance}
        />
      )}
    </Box>
  );
};

export default CashFlowFilters;
