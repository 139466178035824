function createAndDownloadBlobFile(body: ArrayBuffer, filename: string, extension = "pdf") {
  const linkElement = document.createElement("a");
  try {
    const blob = new Blob([body], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);

    const fileName = `${filename}.${extension}`;

    linkElement.setAttribute("href", url);
    linkElement.setAttribute("download", fileName);

    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    linkElement.dispatchEvent(clickEvent);
  } catch (ex) {
    console.log(ex);
  }
}

export default createAndDownloadBlobFile;
