import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { addCompanyAccountFromTo } from "services/Requests/accountant";

const useMutationAddCompanyAccountFromTo = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(addCompanyAccountFromTo, {
    onSuccess(_, { companyToken }) {
      toast.success("Nova conta contábil criada com sucesso!");
      queryClient.invalidateQueries([queryKeys.companyChartOfAccountsFromTo, companyToken]);
      queryClient.invalidateQueries([queryKeys.chartOfAccountsFromToGroup, companyToken], {
        exact: false,
      });
    },
  });
  return mutation;
};

export default useMutationAddCompanyAccountFromTo;
