import { useCallback } from "react";
import { useQuery } from "react-query";

import queryKeys from "react-query/constants";
import { getAllNotificationsToAccountant } from "services/Requests/notifications";

import { DateTime } from "luxon";
import { AllNotification, AllNotificationWithMonthAndYear } from "types/Notifications";
import comparatorSortByProperty from "utils/comparatorSortByProperty";
import stableSort from "utils/stableSort";

const useQueryAllNotificationToAccountant = (companyToken: string) => {
  const sortNotifications = useCallback(
    (data: AllNotification[]): AllNotificationWithMonthAndYear[] => {
      return stableSort(data, comparatorSortByProperty("desc", "date")).map((notification) => ({
        ...notification,
        monthAndYear: DateTime.fromISO(notification.date).toFormat("LLLL/yyyy"),
      }));
    },
    [],
  );

  const query = useQuery(
    [queryKeys.allNotificationsToAccountant, companyToken],
    () => getAllNotificationsToAccountant({ companyToken }),
    {
      staleTime: 1000 * 60 * 2, // 2 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
      select: sortNotifications,
    },
  );
  return query;
};

export default useQueryAllNotificationToAccountant;
