import { FC, useState } from "react";

import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import TabNavigation, { TabNavigationProps } from "components/TabNavigation";
import Configuration from "./Configuration";
import IssueInvoice from "./IssueInvoice";
import Head from "components/Head";

import getSubdomain from "utils/getSubdomain";

const tabs: TabNavigationProps["tabs"] = [
  {
    Component: IssueInvoice,
    name: "Emitidas",
    slug: "emitidas",
    perm: 22,
  },
  {
    Component: Configuration,
    name: "Configurações",
    slug: "configuracoes",
    perm: 23,
  },
];

const Invoices: FC = () => {
  const [tabActive, setTabActive] = useState("1");

  const handleChangeTab = (newValue: string) => {
    setTabActive(newValue);
  };

  return (
    <>
      <Head title={`Notas Fiscais | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Notas Fiscais
        </Typography>
      </Stack>

      <Box mt={2} boxShadow={2} bgcolor="#fff">
        <TabNavigation tabActive={tabActive} tabs={tabs} handleChangeTab={handleChangeTab} />
      </Box>
    </>
  );
};

export default Invoices;
