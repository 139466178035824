import { FC, useState } from "react";

import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import SortableTableHead from "components/SortableTableHead";
import TableItemRelease from "./TableItemRelease";

import comparatorSortByProperty from "utils/comparatorSortByProperty";
import stableSort from "utils/stableSort";
import { TransactionsItem } from "types/Company";
import { Order } from "types/SemanticTypes";

type TransactionItemKeys = keyof TransactionsItem;

interface HeadCells {
  label: string;
  id: TransactionItemKeys;
  sortable: boolean;
}

const headCells: HeadCells[] = [
  {
    id: "dueDate",
    label: "Data",
    sortable: true,
  },
];

interface TableReleaseProps {
  transactionsItem: TransactionsItem[];
  companyToken: string;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectRelease: (releaseId: number) => void;
  releaseSelected: number[];
}

const TableRelease: FC<TableReleaseProps> = ({
  transactionsItem,
  companyToken,
  onSelectAllClick,
  onSelectRelease,
  releaseSelected,
}) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<TransactionItemKeys>("dueDate");

  const sortHandler = (property: TransactionItemKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table size="small">
        <SortableTableHead
          headCells={headCells}
          onRequestSort={(e, property) => sortHandler(property)}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={onSelectAllClick}
          rowsCount={transactionsItem.filter((t) => !t.ofxId).length}
          rowsSelected={releaseSelected.length}
        >
          <TableCell>Categoria</TableCell>
          <TableCell>Beneficiário</TableCell>
          <TableCell>Descrição</TableCell>
          <TableCell>Conta</TableCell>
          <TableCell align="right">Valor</TableCell>
          <TableCell align="center">Status</TableCell>
        </SortableTableHead>

        <TableBody>
          {transactionsItem.length === 0 && (
            <TableRow>
              <TableCell colSpan={headCells.length + 1}>Não foram encontrado lançamentos</TableCell>
            </TableRow>
          )}

          {stableSort(transactionsItem, comparatorSortByProperty(order, orderBy)).map((t) => (
            <TableItemRelease
              key={t.id}
              companyToken={companyToken}
              transaction={t}
              selected={releaseSelected.includes(t.id)}
              onToggleSelected={() => onSelectRelease(t.id)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableRelease;
