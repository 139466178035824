import { FC } from "react";
import { useAuth } from "context/Auth";

import PaidBillsFilters from "./PaidBillsFilters";

import { Box, Typography } from "@mui/material";
import { MESSAGE_NO_COMPANY } from "utils/constants";

const PaidBills: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <Box>
      {company ? (
        <PaidBillsFilters companyToken={company.token} />
      ) : (
        <Typography>{MESSAGE_NO_COMPANY}</Typography>
      )}
    </Box>
  );
};

export default PaidBills;
