import { FC, useEffect, useRef } from "react";
import { useTemplate } from "./context/Template/useTemplate";
import { useScroll } from "hooks/useScroll";
import { useDragDropManager } from "react-dnd";

import { Stack, Typography } from "@mui/material";
import SyntheticCategory from "./SyntheticCategory";

const FinancialCategories: FC = () => {
  const { financialCategories } = useTemplate();
  const listRef = useRef<null | HTMLDivElement>(null);

  const { updatePosition } = useScroll(listRef);

  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  useEffect(() => {
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getSourceClientOffset()?.y as number;
      updatePosition({ position: offset, isScrollAllowed: true });
    });
    return unsubscribe;
  }, [monitor, updatePosition]);

  return (
    <>
      <Stack gap={1} width="100%" maxHeight="75vh" sx={{ overflowY: "auto" }} ref={listRef}>
        {financialCategories.map((financialCategory, index) => (
          <SyntheticCategory
            key={financialCategory.id}
            financialCategory={financialCategory}
            indexColumn={index}
          />
        ))}
      </Stack>

      {financialCategories.length === 0 && <Typography>Nenhuma categoria cadastrada</Typography>}
    </>
  );
};

export default FinancialCategories;
