import { FC, useState } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

import DialogConsultInvoice from "./DialogConsultInvoice";
import DialogDeleteInvoice from "./DialogDeleteInvoice";

import { TransactionsItem } from "types/Company";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import getLabelStatus from "pages/Home/utils/getLabelStatus";
import { DateTime } from "luxon";

interface InvoiceCardProps {
  invoice: TransactionsItem;
  companyToken: string;
}

const InvoiceCard: FC<InvoiceCardProps> = ({ invoice, companyToken }) => {
  const [showConsultDialog, setShowConsultDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <>
      <Box p={3} border={1} borderColor="divider">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1">
            {DateTime.fromISO(invoice.dueDate).toFormat("dd/MM/yyyy")}
          </Typography>
          <Typography
            variant="body1"
            color={invoice.transactionType === "Credit" ? "success.main" : "error.main"}
          >
            {formatCurrencyBRL(invoice.value)}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1">
            {invoice.description.length > 50
              ? invoice.description.substring(0, 50) + "..."
              : invoice.description}
          </Typography>
          <Typography variant="body1">{getLabelStatus(invoice)}</Typography>
        </Stack>

        <Stack mt={2} gap={0.5}>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            fullWidth
            onClick={() => setShowConsultDialog(true)}
          >
            Consultar
          </Button>
          {invoice.invoiceStatus !== "SolicitandoCancelamento" &&
            invoice.invoiceStatus !== "Cancelada" && (
              <Button
                variant="contained"
                startIcon={<DeleteIcon />}
                fullWidth
                color="error"
                onClick={() => setShowDeleteDialog(true)}
              >
                Cancelar
              </Button>
            )}
        </Stack>
      </Box>

      {showConsultDialog && (
        <DialogConsultInvoice
          companyToken={companyToken}
          open={showConsultDialog}
          onClose={() => setShowConsultDialog(false)}
          invoiceId={invoice.id}
        />
      )}

      <DialogDeleteInvoice
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        id={invoice.id}
        companyToken={companyToken}
      />
    </>
  );
};

export default InvoiceCard;
