import { FC, useState } from "react";
import useMutationDeleteParticipant from "../../../hooks/useMutationDeleteParticipant";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { CompanyParticipants } from "types/Company";
import QueryParticipant from "../QueryParticipant";
import ClientTableItem from "./ClientTableItem";
import ClientCardItem from "./ClientCardItem";

interface ClientItemProps {
  client: CompanyParticipants;
  companyToken: string;
  onEdit: () => void;
}

const ClientItem: FC<ClientItemProps> = ({ client, companyToken, onEdit }) => {
  const isTablet = useMediaQuery("(max-width:728px)");

  const { mutateAsync, isLoading } = useMutationDeleteParticipant();

  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [dialogQueryShow, setDialogQueryShow] = useState(false);

  const handleDeleteClient = async () => {
    await mutateAsync({
      companyToken,
      participantId: client.id,
    });
    setConfirmationDelete(false);
  };

  const clientItemProps = {
    client,
    onEdit,
    onDelete: () => setConfirmationDelete(true),
    onQuery: () => setDialogQueryShow(true),
  };

  return (
    <>
      {isTablet ? (
        <ClientCardItem {...clientItemProps} />
      ) : (
        <ClientTableItem {...clientItemProps} />
      )}

      {dialogQueryShow && (
        <QueryParticipant
          open={dialogQueryShow}
          onClose={() => setDialogQueryShow(false)}
          keepMounted={false}
          companyToken={companyToken}
          participantId={client.id}
          onEdit={onEdit}
        />
      )}

      <Dialog
        open={confirmationDelete}
        onClose={() => {
          if (!isLoading) setConfirmationDelete(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Excluir {client.name}</DialogTitle>
        <DialogContent>Tem certeza que deseja excluir {client.name}?</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={() => setConfirmationDelete(false)}
          >
            Não
          </Button>
          <LoadingButton variant="contained" loading={isLoading} onClick={handleDeleteClient}>
            Sim
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientItem;
