import { FC } from "react";
import { useAuth } from "context/Auth";
import { Box, Typography } from "@mui/material";
import { MESSAGE_NO_COMPANY } from "utils/constants";

import BillsReceivedFilters from "./BillsReceivedFilters";

const BillsReceived: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <Box>
      {company ? (
        <BillsReceivedFilters companyToken={company.token} />
      ) : (
        <Typography>{MESSAGE_NO_COMPANY}</Typography>
      )}
    </Box>
  );
};

export default BillsReceived;
