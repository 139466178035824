import { FC, useState } from "react";
import useQueryGroupsForm from "../hooks/useQueryGroupsForm";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import SearchInput from "components/SearchInput";
import ConfigLeadsList from "./ConfigLeadsList";

interface DialogConfigLeadsProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
}

const DialogConfigLeads: FC<DialogConfigLeadsProps> = (props) => {
  const { data: groupsForm = [], isLoading, isError } = useQueryGroupsForm();

  const [formSelectedId, setFormSelectedId] = useState<null | number>(null);
  const [search, setSearch] = useState<string>("");

  return (
    <Dialog {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={props.onClose}>
        Configurar Leads
      </DialogTitleWithCloseButton>

      <DialogContent sx={{ p: 0 }}>
        <Stack gap={2} direction="row" alignItems="center" py={2} px={3}>
          <FormControl sx={{ minWidth: "30%" }}>
            <InputLabel>Formulários</InputLabel>
            <Select
              label="Formulários"
              value={formSelectedId || ""}
              onChange={(e) => setFormSelectedId(Number(e.target.value))}
            >
              <MenuItem value="" disabled>
                Selecione:
              </MenuItem>
              {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
              {isError && <MenuItem disabled>Ocorreu um erro ao carregar os formulários</MenuItem>}

              {groupsForm.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <SearchInput value={search} onChange={(newValue) => setSearch(newValue)} />
        </Stack>

        <Divider />

        <Box>
          {formSelectedId ? (
            <ConfigLeadsList form={formSelectedId} name={search} />
          ) : (
            <Typography variant="body1" align="center" py={2} px={3}>
              Selecione algum formulário
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={props.onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfigLeads;
