import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { deleteTemplate } from "services/Requests/template";

const useMutationDeleteTemplate = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteTemplate, {
    onSuccess: () => {
      toast.success("Template deletado com sucesso");
      queryClient.invalidateQueries(queryKeys.templates);
    },
    onError: () => {
      toast.error("Erro ao deletar template, tente novamente mais tarde");
    },
  });

  return mutation;
};

export default useMutationDeleteTemplate;
