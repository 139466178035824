import { FC, useEffect, useMemo, useState } from "react";
import { useAuth } from "context/Auth";
import { useLocation } from "react-router-dom";

import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import DialogCreateProduct from "./components/DialogUpdateOrCreateProduct";
import DialogCreateMovement from "./components/DialogUpdateOrCreateMovement";

const Create: FC = () => {
  const {
    user: { company },
  } = useAuth();
  const { search } = useLocation();
  const slugActivate = useMemo(() => new URLSearchParams(search).get("tab"), [search]);

  const [createProductIsOpen, setCreateProductIsOpen] = useState(false);
  const [createMovementIsOpen, setCreateMovementIsOpen] = useState(false);

  useEffect(() => {
    if (slugActivate !== "produtos") {
      setCreateProductIsOpen(false);
    }

    if (slugActivate !== "movimentacoes") {
      setCreateMovementIsOpen(false);
    }
  }, [slugActivate]);

  if (slugActivate === "produtos") {
    return (
      <>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setCreateProductIsOpen(true)}
          disabled={!company}
        >
          Cadastrar produto
        </Button>
        {!!company && (
          <DialogCreateProduct
            open={createProductIsOpen}
            onClose={() => setCreateProductIsOpen(false)}
            companyToken={company.token}
          />
        )}
      </>
    );
  }

  if (slugActivate === "movimentacoes") {
    return (
      <>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setCreateMovementIsOpen(true)}
          disabled={!company}
        >
          Cadastrar movimentação
        </Button>
        {!!company && (
          <DialogCreateMovement
            open={createMovementIsOpen}
            onClose={() => setCreateMovementIsOpen(false)}
            companyToken={company.token}
          />
        )}
      </>
    );
  }

  return null;
};

export default Create;
