import { FC, useMemo, useState } from "react";
import { useDrive } from "./context/Drive";
import useQueryCompanyDocumentsDrive from "./hooks/useQueryCompanyDocumentsDrive";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AppsIcon from "@mui/icons-material/Apps";
import TableRowsIcon from "@mui/icons-material/TableRows";

import FilterPeriod from "components/FilterPeriod";
import SearchInput from "components/SearchInput";
import NewFolder from "./components/NewFolder";
import DriveList from "./DriveList";
import DialogNewFile from "./components/DialogNewFile";
import PathNavigation from "./components/PathNavigation";

type ContentType = ".doc" | ".pdf" | ".jpg" | ".txt";

const contentTypeFilter: Record<ContentType, string> = {
  ".doc": "Word",
  ".pdf": "PDF",
  ".jpg": "Imagem",
  ".txt": "Texto",
};

interface PageProps {
  companyToken: string;
}

const Page: FC<PageProps> = ({ companyToken }) => {
  const { listingByIcons, toggleListingByIcons, paths, onChangePaths } = useDrive();
  const currentParentId = useMemo(() => paths[paths.length - 1]?.id || null, [paths]);

  const [dialogNewFileIsOpen, setDialogNewFileIsOpen] = useState(false);

  // filters
  const [contentType, setContentType] = useState<null | ContentType>(null);
  const [date, setDate] = useState<{ start: string | null; end: string | null }>({
    end: null,
    start: null,
  });
  const [search, setSearch] = useState("");

  const {
    data: companyDocumentsDrive,
    isLoading,
    isFetching,
  } = useQueryCompanyDocumentsDrive({
    companyToken,
    contentType,
    endDate: date?.end || null,
    startDate: date?.start || null,
    fileName: search,
    parentId: currentParentId,
  });

  return (
    <>
      <Stack gap={1}>
        <Stack direction="row" gap={1} p={2} boxShadow={1}>
          <FormControl sx={{ minWidth: "15%" }}>
            <InputLabel>Tipo de Documento</InputLabel>
            <Select
              label="Tipo de Documento"
              value={contentType || "all"}
              onChange={(e) =>
                setContentType(e.target.value === "all" ? null : (e.target.value as ContentType))
              }
            >
              <MenuItem value="all">Todos</MenuItem>
              {Object.entries(contentTypeFilter).map(([contentType, label]) => (
                <MenuItem key={contentType} value={contentType}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FilterPeriod
            erasable
            endDate={date?.end || undefined}
            startDate={date?.start || undefined}
            onChangeDates={(dates) => setDate(dates)}
            size="medium"
            label="Período"
          />

          <SearchInput
            value={search}
            onChange={(newSearch) => setSearch(newSearch)}
            label="Buscar documentos"
          />
        </Stack>

        <Box boxShadow={2}>
          <Collapse in={!isLoading && isFetching}>
            <LinearProgress />
          </Collapse>

          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              bgcolor={grey[100]}
              gap={2}
              p={2}
              borderBottom={1}
              borderColor="divider"
            >
              <PathNavigation paths={paths} onChangePaths={onChangePaths} maxItems={6} />

              <Stack direction="row" gap={1}>
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<CloudUploadIcon fontSize="small" />}
                  onClick={() => setDialogNewFileIsOpen(true)}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Carregar documentos
                </Button>

                <NewFolder companyToken={companyToken} parentId={currentParentId || undefined} />

                <Button
                  size="small"
                  variant="contained"
                  onClick={toggleListingByIcons}
                  sx={{ minWidth: "auto" }}
                >
                  {listingByIcons ? (
                    <TableRowsIcon fontSize="small" />
                  ) : (
                    <AppsIcon fontSize="small" />
                  )}
                </Button>
              </Stack>
            </Stack>

            {isLoading && (
              <Stack alignItems="center" justifyContent="center" height={400}>
                <CircularProgress />
              </Stack>
            )}

            {!isLoading && companyDocumentsDrive && (
              <DriveList companyDocumentsDrive={companyDocumentsDrive} />
            )}
          </Box>
        </Box>
      </Stack>

      {dialogNewFileIsOpen && (
        <DialogNewFile
          companyToken={companyToken}
          onClose={() => setDialogNewFileIsOpen(false)}
          open={dialogNewFileIsOpen}
          parentId={currentParentId || undefined}
        />
      )}
    </>
  );
};

export default Page;
