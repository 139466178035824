import { FC, useMemo, useEffect, useState } from "react";
import { useForm, DefaultValues } from "react-hook-form";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";
import useMutationCreateTransaction from "pages/Home/hooks/useMutationCreateTransaction";
import useQueryGetBalance from "pages/Home/hooks/useQueryGetBalance";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import OriginForm from "./OriginForm";
import DestinyForm from "./DestinyForm";
import { DateTime } from "luxon";
import { formattedInput } from "components/CurrencyInput";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import axios, { AxiosError } from "axios";

export interface TransferAccountsForm {
  accountSource: number;
  balance: number | null;
  accountDestination: number;
  amount: string;
  date: Date;
  globalCategoryId: string;
}

interface TransferAccountsProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
}

const TransferAccounts: FC<TransferAccountsProps> = ({ companyToken, onClose, ...props }) => {
  const { mutateAsync } = useMutationCreateTransaction();
  const { data: bankAccounts = [] } = useQueryGetCompanyBankAccounts(companyToken);
  const [alert, setAlert] = useState<null | string>(null);

  const defaultValues = useMemo<DefaultValues<TransferAccountsForm>>(
    () => ({
      accountSource: undefined,
      balance: undefined,
      accountDestination: undefined,
      date: DateTime.local().toJSDate(),
      globalCategoryId: undefined,
    }),
    [],
  );

  const {
    control,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<TransferAccountsForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const accountSource = watch("accountSource");

  const { data } = useQueryGetBalance(companyToken, accountSource);

  const handleClose = () => {
    if (isSubmitting) return;
    reset(defaultValues);
    setAlert(null);
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    setAlert(null);
    const bankDestiny = bankAccounts.find(
      (bankAccount) => bankAccount.id === data.accountDestination,
    );
    const date = new Date(data.date).toDateString();

    try {
      await mutateAsync({
        companyBankAccountId: data.accountSource,
        companyToken,
        description: `Transferência para ${bankDestiny?.alias || bankDestiny?.bankName}`,
        dueDate: date,
        globalCategoryId: Number(data.globalCategoryId),
        isInvoice: false,
        issueDate: date,
        parcel: 0,
        paymentDate: date,
        periodicity: 0,
        transactionType: "Debt",
        value: formattedInput(data.amount),
      });
      handleClose();
    } catch (error) {
      let message = "Ocorreu um erro inesperado, tente novamente mais tarde.";
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string }>;
        message = axiosError.response?.data.message ?? message;
      }
      setAlert(message);
    }
  });

  useEffect(() => {
    if (!data || !accountSource) {
      setValue("balance", null);
      return;
    }

    setValue("balance", data.balance);
  }, [data, accountSource]);

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Transferência entre contas
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          {!!alert && (
            <Alert severity="error" onClose={() => setAlert(null)} sx={{ mb: 2 }}>
              {alert}
            </Alert>
          )}

          <Stack gap={2}>
            <OriginForm companyToken={companyToken} control={control} />
            <DestinyForm companyToken={companyToken} control={control} />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
            startIcon={<CurrencyExchangeIcon />}
          >
            Transferir
          </LoadingButton>
          <Button
            onClick={handleClose}
            disabled={isSubmitting}
            variant="outlined"
            startIcon={<CloseIcon />}
          >
            Fechar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TransferAccounts;
