import { forwardRef, Ref, useState } from "react";
import useQueryGetFinancialCategories from "pages/Home/hooks/useQueryGetFinancialCategories";

import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DialogCreateCategory from "pages/Home/pages/Categories/DialogCreateCategory";

import { FinancialCategory } from "types/Company";

interface CustomCategorySelectInnerProps extends SelectProps {
  companyToken: string;
  withCreateCategory?: boolean;
  helperText?: string;
  parentId?: number | null;
}

function CustomCategorySelectInner(
  {
    companyToken,
    withCreateCategory = false,
    parentId,
    helperText,
    ...props
  }: CustomCategorySelectInnerProps,
  ref?: Ref<unknown>,
) {
  const [createCategory, setCreateCategory] = useState(false);
  const {
    data: financialCategories = [],
    isLoading,
    isError,
  } = useQueryGetFinancialCategories(companyToken);

  const childCategories = (
    financialCategories.map((f) => f.children).flat(Infinity) as FinancialCategory[]
  ).find((c) => c.id === parentId)?.children;

  return (
    <>
      <FormControl size={props.size}>
        <InputLabel>{props.label}</InputLabel>
        <Select
          ref={ref}
          {...props}
          endAdornment={
            <>
              {withCreateCategory && (
                <IconButton
                  disabled={props.disabled || false}
                  sx={{ mr: 2 }}
                  size="small"
                  color="primary"
                  onClick={() => setCreateCategory(true)}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              )}
              {props.endAdornment}
            </>
          }
          value={props.value || ""}
        >
          <MenuItem disabled value={""}>
            Selecione:{" "}
          </MenuItem>
          {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
          {isError && <MenuItem disabled>Ocorreu um erro ao buscar as categorias</MenuItem>}
          {(!childCategories || childCategories?.length === 0) && (
            <MenuItem disabled>Nenhuma categoria personalizada</MenuItem>
          )}

          {childCategories &&
            childCategories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                <ListItemIcon sx={{ minWidth: 18 }}>●</ListItemIcon>
                {category.name}
              </MenuItem>
            ))}
        </Select>
        {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
      </FormControl>

      {createCategory && (
        <DialogCreateCategory
          globalCategoryId={parentId || undefined}
          open={createCategory}
          onClose={() => setCreateCategory(false)}
          companyToken={companyToken}
        />
      )}
    </>
  );
}

const CustomCategorySelect = forwardRef(CustomCategorySelectInner);
export default CustomCategorySelect;
