import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { deleteCompanyAccountFromTo } from "services/Requests/accountant";

const useMutationDeleteCompanyAccountFromTo = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteCompanyAccountFromTo, {
    onSuccess() {
      toast.success("Conta contábil deletada com sucesso.");
      queryClient.invalidateQueries(queryKeys.companyChartOfAccountsFromTo, { exact: false });
      queryClient.invalidateQueries([queryKeys.chartOfAccountsFromToGroup], {
        exact: false,
      });
    },
  });
  return mutation;
};

export default useMutationDeleteCompanyAccountFromTo;
