import { formattedInput } from "components/CurrencyInput";
import * as yup from "yup";

const emptyValidation = yup
  .number()
  .nullable()
  .transform((value) => {
    if (value === undefined || value === null || isNaN(value)) return null;
    return typeof value === "string" ? Number(value) : value;
  });

const priceValidation = yup
  .string()
  .required("O preço é obrigatório")
  .test("validPrice", "Digite um preço válido", (value) => {
    if (!value) return false;
    const price = formattedInput(value);
    return price >= 0;
  });

const availableValuesForParcel = [1, 3, 6, 12];

const validationSchema = yup.object().shape({
  name: yup.string().required("O nome do plano é obrigatório"),
  agreement: yup.string().required("Selecione um contrato"),
  isActive: yup.boolean(),
  bankSlip: yup.boolean(),
  creditCard: yup.boolean(),
  isSameDayExp: yup.boolean(),
  periodicity: emptyValidation.when("isSameDayExp", {
    is: false,
    then: yup
      .number()
      .nullable(false)
      .typeError("Selecione uma opção")
      .required("A recorrência é obrigatório"),
  }),
  periodParcel: emptyValidation.when("periodicity", (periodicity) => {
    const periodicityValueWeeks = 7;

    if (periodicity !== periodicityValueWeeks) {
      return emptyValidation;
    }

    return emptyValidation.when("isSameDayExp", {
      is: false,
      then: yup
        .number()
        .nullable(false)
        .typeError("Selecione um dia da semana")
        .required("A recorrência é obrigatório"),
    });
  }),
  parcel: emptyValidation.when("periodicity", (periodicity) => {
    if (!availableValuesForParcel.includes(periodicity)) {
      return emptyValidation;
    }

    return emptyValidation.when("isSameDayExp", {
      is: false,
      then: yup
        .number()
        .nullable(false)
        .typeError("Digite apenas números")
        .test("invalidDay", "Digite um dia válido", (value) => {
          if (value === undefined || value === null) return true;
          return value >= 1 && value <= 31;
        })
        .integer("Digite apenas números inteiros")
        .required("O dia de recorrência é obrigatório"),
    });
  }),
  isManualBilling: yup.boolean(),
  tierPrice: yup.array().of(
    yup.object().shape({
      name: yup.string().required("O nome é obrigatório"),
      calcIntervals: yup.array().of(
        yup.object().shape({
          from: priceValidation,
          price: priceValidation,
          until: priceValidation,
        }),
      ),
    }),
  ),
  usePayroll: yup.boolean(),
  useProlabore: yup.boolean(),
  prolaboreValue: priceValidation,
  payrollValue: priceValidation,
});

export default validationSchema;
