import { FC } from "react";
import { useAuth } from "context/Auth";

import { Box, Divider, Stack, Typography } from "@mui/material";
import TopImage from "./TopImage";
import LoginImage from "./LoginImage";
import Colors from "./Colors";

import { MESSAGE_NO_COMPANY } from "utils/constants";

const Appearance: FC = () => {
  const {
    user: { company },
  } = useAuth();
  return (
    <Box>
      {company ? (
        <>
          <Stack direction="row" alignItems="start" gap={4} flexWrap="wrap">
            <TopImage companyToken={company.token} />
            <LoginImage companyToken={company.token} />
          </Stack>
          <Divider sx={{ my: 4 }} />
          <Colors companyToken={company.token} />
        </>
      ) : (
        <Typography>{MESSAGE_NO_COMPANY}</Typography>
      )}
    </Box>
  );
};

export default Appearance;
