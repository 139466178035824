import { FC, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import useMutationUpdatePartners from "../Profile/components/CompanyData/hooks/useMutationUpdatePartners";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { grey, red } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import validationSchema from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanyInformation, CompanyPartner, NewCompanyPartner } from "types/Company";
import Partner from "./Partner";
import buildData from "./utils/buildData";

export interface PartnerFields {
  partnerId?: number;
  name: string;
  share: number;
  wageCompesation: boolean;
  occupation: string;
  maritalStatus: number | null;
  communityProperty: number | null;
  cpf: string;
  street: string;
  neighborhood: string;
  zipCode: string;
  city: string;
  complement: string;
  state: string;
  streetNumber: number;
  inCharge: boolean;
}

export interface PartnerFormField {
  partners: PartnerFields[];
  responsible: number;
}

interface PartnerFormProps {
  companyInformation: CompanyInformation;
  partners: CompanyPartner[];
  onChangePage?: () => void;
  onClose?: () => void;
  disabledForm?: boolean;
}

const PartnerForm: FC<PartnerFormProps> = ({
  companyInformation,
  partners,
  onChangePage,
  onClose,
  disabledForm = false,
}) => {
  const [percentPartners, setPercentPartners] = useState(() =>
    partners.reduce((acc, p) => acc + p.share, 0),
  );
  const { isLoading: updateIsLoading, mutateAsync } = useMutationUpdatePartners();

  const { control, handleSubmit, setValue, watch, getValues } = useForm<PartnerFormField>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      partners: partners.map(buildData.partnerField),
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "partners",
  });

  const onSubmit = handleSubmit(async (data) => {
    if (disabledForm) return;
    const partnersEdited = buildData.partnersEdited(data.partners, data.responsible, partners);

    const newPartners = (data.partners as PartnerFields[])
      .map((partner, index): NewCompanyPartner | undefined =>
        partner.partnerId !== undefined
          ? undefined
          : buildData.newCompanyPartner(partner, data.responsible, index),
      )
      .filter((p) => !!p) as NewCompanyPartner[];

    await mutateAsync({
      companyToken: companyInformation.companyToken,
      partners: [...partnersEdited, ...newPartners],
    });
    onClose?.();
  });

  const createPartnerField = (partner?: CompanyPartner) => {
    append(buildData.partnerField(partner));
  };

  useEffect(() => {
    if (!partners) return;
    const fields = getValues("partners");

    partners.forEach((partner) => {
      if (fields.some((field) => field.partnerId === partner.id)) return;
      createPartnerField(partner);
    });
  }, [partners, fields]);

  useEffect(() => {
    const responsible = getValues("responsible");

    if (fields && fields.length > 0 && !fields[responsible]) {
      const fieldWithCharge = fields.findIndex((field) => field.inCharge);
      setValue("responsible", fieldWithCharge === -1 ? 0 : fieldWithCharge);
    }
  }, [fields]);

  useEffect(() => {
    const subscription = watch(() => {
      const fields = getValues("partners");

      if (!fields) return;
      const total = fields.reduce((acc, field) => acc + Number(field.share), 0);
      setPercentPartners(total);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Typography variant="subtitle1" fontWeight={600} gutterBottom>
        Dados dos sócios da {companyInformation.companyName || "sua empresa"}
      </Typography>
      <Divider sx={{ mb: 3 }} />

      <Stack gap={4} mb={2}>
        {fields.map((field, index) => (
          <Box key={field.id} position="relative">
            {fields.length !== 1 && !disabledForm && (
              <Button
                color="error"
                size="small"
                startIcon={<DeleteIcon fontSize="small" />}
                sx={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                }}
                onClick={() => remove(index)}
              >
                Excluir
              </Button>
            )}

            <Partner
              control={control}
              field={field}
              index={index}
              setValue={setValue}
              watch={watch}
              disabled={disabledForm}
            />
          </Box>
        ))}
      </Stack>

      {!disabledForm && (
        <Button startIcon={<AddIcon />} onClick={() => createPartnerField()}>
          Adicionar sócio
        </Button>
      )}

      <Stack
        alignItems="center"
        justifyContent="center"
        p={2}
        mt={4}
        bgcolor={grey[200]}
        sx={{
          border: 1,
          borderColor: percentPartners > 100 ? red[400] : grey[200],
        }}
      >
        <Typography>Total de participação dos sócios: {percentPartners.toFixed(2)}%</Typography>
        {percentPartners !== 100 && (
          <Typography variant="body1" color={red[400]}>
            A porcentagem de todos os sócios tem que ser igual a 100%
          </Typography>
        )}
      </Stack>

      {!disabledForm && (
        <Stack
          direction="row"
          alignContent="center"
          justifyContent={onChangePage ? "space-between" : "end"}
          mt={2}
        >
          {onChangePage && (
            <Button variant="contained" onClick={() => onChangePage()} disabled={updateIsLoading}>
              Anterior
            </Button>
          )}

          <LoadingButton
            loading={updateIsLoading}
            variant="contained"
            disabled={percentPartners !== 100}
            onClick={() => onSubmit()}
          >
            Salvar
          </LoadingButton>
        </Stack>
      )}
    </form>
  );
};

export default PartnerForm;
