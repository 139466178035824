import { Contract } from ".";
import { Box, Checkbox, FormControlLabel, Stack, useMediaQuery } from "@mui/material";
import { Control, Controller, Path } from "react-hook-form";

export interface RequiredFields {
  isActive: boolean;
  agreement: string;
}

interface ActiveAndContractProps<T extends RequiredFields = RequiredFields> {
  control: Control<T>;
  companyToken: string;
}

export function ActiveAndContract<T extends RequiredFields = RequiredFields>({
  control,
  companyToken,
}: ActiveAndContractProps<T>) {
  const isTablet = useMediaQuery("(max-width:650px)");

  return (
    <Stack direction={isTablet ? "column" : "row"} gap={1} flexWrap="wrap">
      <Box flexShrink={0} flex={1}>
        <Controller
          control={control}
          name={"isActive" as Path<T>}
          render={({ field }) => (
            <FormControlLabel
              sx={{ m: 0, height: "auto" }}
              control={
                <Checkbox
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  checked={!!field.value}
                  value={field.value}
                  inputRef={field.ref}
                />
              }
              label="Plano ativo"
            />
          )}
        />
      </Box>

      <Box flex={2}>
        <Contract companyToken={companyToken} control={control} />
      </Box>
    </Stack>
  );
}
