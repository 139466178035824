import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCompany } from "services/Requests/companies";

const useQueryGetCompany = (companyToken: string) => {
  const query = useQuery([queryKeys.company, companyToken], () => getCompany({ companyToken }));
  return query;
};

export default useQueryGetCompany;
