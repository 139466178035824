import { FC, useEffect, useState } from "react";

import { Box, Button, ClickAwayListener, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Info from "@mui/icons-material/Info";
import TextEditor from "components/TextEditor";
import ControllerInput from "components/ControllerInput";
import SendTestEmail from "./SendTestEmail";

import {
  HTMLStringToDescendant,
  toHTMLString,
  toOnlyText,
} from "components/TextEditor/utils/functions";
import { EMPTY } from "components/TextEditor/utils/constants";
import { MessageEmail } from "types/Communications";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import validationSchema from "./validationSchema";
import useQueryUpdateMessage from "../hooks/useQueryUpdateMessage";

interface Form {
  title: string;
  footer: string;
}

interface EmailMessageFormProps {
  message: MessageEmail;
  companyToken: string;
}

const EmailMessageForm: FC<EmailMessageFormProps> = ({ message, companyToken }) => {
  const { mutate, isLoading } = useQueryUpdateMessage();
  const [bodyValue, setBodyValue] = useState(
    message.body ? HTMLStringToDescendant(message.body) : EMPTY,
  );

  const [tooltipTagsIsOpen, setTooltipTagsIsOpen] = useState(false);
  const handleTooltipClose = () => setTooltipTagsIsOpen(false);
  const handleToggleTooltip = () => setTooltipTagsIsOpen((prev) => !prev);

  const { control, handleSubmit, reset } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: message.title || "",
      footer: message.footer || "",
    },
  });

  useEffect(() => {
    reset({
      title: message.title || "",
      footer: message.footer || "",
    });
    setBodyValue(message.body ? HTMLStringToDescendant(message.body) : EMPTY);
  }, [message]);

  const onSubmit = handleSubmit((data) => {
    if (!toOnlyText(bodyValue).trim()) {
      toast.error("Digite alguma mensagem para o corpo do email");
      return;
    }

    mutate({
      body: toHTMLString(bodyValue),
      companyToken,
      footer: data.footer,
      title: data.title,
      type: message.type,
    });
  });

  return (
    <>
      <Stack component="form" gap={2} onSubmit={onSubmit}>
        <ControllerInput
          name="title"
          control={control}
          inputProps={{
            label: "Título da mensagem",
            fullWidth: true,
          }}
        />

        <Box>
          <TextEditor
            value={bodyValue}
            onChange={(newValue) => setBodyValue(newValue)}
            preset={2}
            editableProps={{
              style: {
                maxHeight: 300,
                overflowY: "auto",
              },
            }}
          />
        </Box>

        <ControllerInput
          name="footer"
          control={control}
          inputProps={{
            label: "Rodapé da mensagem",
            fullWidth: true,
          }}
        />

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipTagsIsOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <Typography variant="body1">
                    Você pode usar as seguintes tags dentro dos seus emails para uma comunicação
                    mais personalizada: [[nomeDoCliente]] e [[nomeDaEmpresa]] (estas duas referem-se
                    aos seus clientes), [[nomeEmpresaContador]] (refere-se à sua empresa) e
                    [[servicoSolicitado]] (refere-se ao serviço solicitado pelo cliente que busca um
                    contador).
                  </Typography>
                }
              >
                <Button onClick={handleToggleTooltip} startIcon={<Info />}>
                  Tags de tratamento
                </Button>
              </Tooltip>
            </div>
          </ClickAwayListener>

          <LoadingButton loading={isLoading} variant="contained" type="submit">
            Salvar
          </LoadingButton>
        </Stack>

        <Divider sx={{ my: 1 }} />
      </Stack>

      <Box mt={2}>
        <SendTestEmail companyToken={companyToken} type={message.type} />
      </Box>
    </>
  );
};

export default EmailMessageForm;
