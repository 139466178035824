import { FC, useState } from "react";
import useMutationCreateSubscription from "../../../hooks/useMutationCreateSubscription";
import { useQueryPlans } from "../../../hooks/useQueryPlans";
import { useClientInformation } from "../../../context/ClientInformation";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import CurrencyInput from "components/CurrencyInput";
import { DateTime } from "luxon";
import SignaturesTable, { Signature } from "./SignaturesTable";

const planValidation = yup
  .string()
  .required("Escolha um plano")
  .test("verifyEmpty", (value) => {
    return !(value === undefined || value.trim() === "");
  });

const validationSchema = {
  dueDate: yup
    .string()
    .required("A data de vencimento é obrigatória")
    .typeError("A data de vencimento é obrigatória")
    .test("verifyWrongFormatDate", "Digite uma data correta", (value) => {
      if (!value) return true;
      return DateTime.fromISO(value).isValid;
    }),
  description: yup.string().required("A descrição é obrigatória"),
  value: yup.string().nullable().notRequired(),
};

const buildYupValidation = (withPlan: boolean) =>
  yup.object().shape(withPlan ? { ...validationSchema, plan: planValidation } : validationSchema);

interface SignatureFormProps {
  withPlan: boolean;
}

const SignatureForm: FC<SignatureFormProps> = ({ withPlan }) => {
  const { mutateAsync, isLoading } = useMutationCreateSubscription();
  const plansQuery = useQueryPlans();

  const [signatures, setSignatures] = useState<Signature[]>([]);
  const { ownerId, payerCompanyId, companyToken } = useClientInformation();

  const { control, handleSubmit, reset, getValues } = useForm({
    resolver: yupResolver(buildYupValidation(withPlan)),
    defaultValues: {
      plan: "",
      dueDate: null,
      description: "",
      value: "",
      isRecurrent: false,
    },
  });

  const handleSubmitSignatures = async () => {
    const planId = getValues("plan");
    const date = getValues("dueDate");

    if (!date || typeof date !== "string") return;

    const formattedDate = DateTime.fromISO(date).toFormat("ccc LLL dd yyyy", { locale: "en-US" });

    const items = signatures.map((signature, index) => {
      const obj = {
        description: signature.description,
        id: index + 1,
      };

      if (!signature.value) return obj;

      return {
        ...obj,
        value: signature.value,
      };
    });

    await mutateAsync({
      companyToken,
      dueDate: formattedDate,
      items,
      ownerId,
      payerCompanyId,
      planId: withPlan ? planId : undefined,
    });

    reset();
    setSignatures([]);
  };

  const onSubmit = handleSubmit((data) => {
    if (!data.dueDate || typeof data.dueDate !== "string") return;

    const newSignatures: Signature = {
      description: data.description.trim(),
      dueDate: data.dueDate,
      id: Date.now().toString(),
      isRecurrent: data.isRecurrent,
      value: !data.value
        ? undefined
        : Number(data.value.replace(/(R\$ )|\./g, "").replace(/,/g, ".")),
    };

    setSignatures((prev) => [newSignatures, ...prev]);
    reset({
      ...data,
      description: "",
      value: "",
    });
  });

  return (
    <Box sx={{ border: 2, borderColor: "divider", mt: 4, p: 4 }}>
      <Box component="form" onSubmit={onSubmit}>
        <Typography variant="h6">Cadastro de nova assinatura</Typography>

        <Box my={2}>
          {withPlan && (
            <FormControl fullWidth>
              <InputLabel>Plano</InputLabel>
              <Controller
                name="plan"
                control={control}
                defaultValue={""}
                render={({ field, fieldState }) => (
                  <>
                    <Select
                      {...field}
                      disabled={signatures.length !== 0}
                      label="Plano"
                      error={!!fieldState.error?.message}
                      MenuProps={{
                        sx: {
                          maxHeight: "50vh",
                        },
                      }}
                      endAdornment={
                        plansQuery.isLoading ? (
                          <CircularProgress
                            sx={{ position: "relative", left: "-20px" }}
                            size={20}
                          />
                        ) : undefined
                      }
                    >
                      <MenuItem disabled value="">
                        Selecione:
                      </MenuItem>

                      {plansQuery.data &&
                        plansQuery.data.map((plan) => (
                          <MenuItem value={plan.id} key={plan.id}>
                            {plan.name} -{" "}
                            {plan.prices[0]
                              ? (plan.prices[0].value_cents / 1000).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: plan.prices[0].currency,
                                })
                              : "R$ 0"}
                          </MenuItem>
                        ))}
                    </Select>

                    {!!fieldState.error?.message && (
                      <Typography
                        variant="body2"
                        sx={{ ml: 2, mt: "3px", fontSize: "0.75rem" }}
                        color={red[700]}
                      >
                        {fieldState.error.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </FormControl>
          )}
        </Box>

        <Controller
          name="dueDate"
          control={control}
          render={({ field, fieldState }) => (
            <DesktopDatePicker
              label="Vencimento"
              inputFormat="dd/MM/yyyy"
              disablePast
              disabled={signatures.length !== 0}
              {...field}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={fieldState.error?.message}
                  error={params.error || !!fieldState.error?.message}
                />
              )}
            />
          )}
        />

        <Stack direction="row" gap={2} mt={2}>
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                sx={{ flex: 2 }}
                {...field}
                label="Descrição"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="value"
            control={control}
            render={({ field, fieldState }) => (
              <CurrencyInput
                {...field}
                inputProps={{
                  sx: {
                    flex: 1,
                  },
                  label: "Valor",
                  error: !!fieldState.error?.message,
                  helperText: fieldState.error?.message,
                }}
              />
            )}
          />

          <Box flexShrink={0}>
            <Controller
              name="isRecurrent"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ m: 0, height: "auto" }}
                  control={
                    <Checkbox
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      checked={field.value}
                      value={field.value}
                      inputRef={field.ref}
                    />
                  }
                  label="Recorrente?"
                />
              )}
            />
          </Box>
        </Stack>

        <Stack alignItems="flex-end" justifyContent="flex-end" mt={2}>
          <Button variant="contained" type="submit" disableElevation size="small">
            Adicionar item
          </Button>
        </Stack>
      </Box>

      <Collapse in={signatures.length !== 0}>
        <Box mt={2}>
          <SignaturesTable
            signatures={signatures}
            onDeleteSignature={(id) => {
              setSignatures((prevSignature) => prevSignature.filter((prev) => prev.id !== id));
            }}
          />

          <Stack alignItems="flex-end" mt={1}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={handleSubmitSignatures}
              disabled={signatures.length === 0}
            >
              Assinar
            </LoadingButton>
          </Stack>
        </Box>
      </Collapse>
    </Box>
  );
};

export default SignatureForm;
