import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getConfiguration } from "services/Requests/invoice";

const useQueryInvoiceConfiguration = (companyToken: string) => {
  const query = useQuery(queryKeys.invoiceConfiguration, () => getConfiguration({ companyToken }), {
    staleTime: 1000 * 60 * 20, // 20 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
  });
  return query;
};

export default useQueryInvoiceConfiguration;
