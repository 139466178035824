import { FC } from "react";
import useQueryInfoAccount from "pages/Home/hooks/useQueryInfoAccount";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";

interface InfoItemProps {
  title: string;
  label: string;
}

const InfoItem = ({ label, title }: InfoItemProps) => (
  <Stack p={3} py={4} alignItems="center" justifyContent="center">
    <Typography variant="h4" fontWeight={600} textAlign="center">
      {title}
    </Typography>
    <Typography
      textAlign="center"
      sx={{ textTransform: "uppercase" }}
      variant="body1"
      color="success.main"
    >
      {label}
    </Typography>
  </Stack>
);

interface PanelInformationProps {
  companyToken: string;
}

const PanelInformation: FC<PanelInformationProps> = ({ companyToken }) => {
  const { data: infoAccount, isLoading } = useQueryInfoAccount({ companyToken });

  return (
    <Box>
      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {infoAccount && (
        <Box
          sx={{
            "& > div:not(:last-child)": {
              borderBottom: 1,
              borderColor: "divider",
            },
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            sx={{
              "& > div:not(:last-child)": {
                borderRight: 1,
                borderColor: "divider",
              },
            }}
          >
            <InfoItem label="Recebimentos este mês" title={infoAccount.volume_this_month} />
            <InfoItem label="Recebimentos mês anterior" title={infoAccount.volume_last_month} />
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            sx={{
              "& > div:not(:last-child)": {
                borderRight: 1,
                borderColor: "divider",
              },
            }}
          >
            <InfoItem label="Saldo da conta" title={infoAccount.balance} />
            <InfoItem label="Saldo disponível" title={infoAccount.balance_available_for_withdraw} />
            <InfoItem label="Em trânsito" title={infoAccount.protected_balance} />
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            sx={{
              "& > div:not(:last-child)": {
                borderRight: 1,
                borderColor: "divider",
              },
            }}
          >
            <InfoItem label="A receber" title={infoAccount.receivable_balance} />
            <InfoItem label="A pagar" title={infoAccount.payable_balance} />
            <InfoItem
              label="Total de assinaturas ativas"
              title={infoAccount.total_active_subscriptions.toString()}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PanelInformation;
