import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { useAuth } from "context/Auth";

import toast from "react-hot-toast";
import { UserInfo } from "types/User";
import { updateProfileToken, UpdateProfileTokenPayload } from "services/Requests/registration";
import getSubdomain from "utils/getSubdomain";

const useMutationUpdateProfile = () => {
  const subdomain = getSubdomain();
  const queryClient = useQueryClient();
  const { setUser } = useAuth();

  const mutation = useMutation(
    (payload: Omit<UpdateProfileTokenPayload, "subDomain">) =>
      updateProfileToken({ ...payload, subDomain: subdomain }),
    {
      onSuccess(_, variables) {
        toast.success("Dados cadastrais atualizados com sucesso.");
        queryClient.invalidateQueries([queryKeys.profile, variables.companyToken]);

        setUser((prev) => {
          const userInfo = prev.userInfo;

          const newUserInfo: UserInfo = {
            ...userInfo,
            name: variables.name || userInfo.name,
            userName: variables.email || userInfo.userName,
          };

          return {
            ...prev,
            userInfo: newUserInfo,
          };
        });
      },
    },
  );
  return mutation;
};

export default useMutationUpdateProfile;
