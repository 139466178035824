import { FC } from "react";
import { useAuth } from "context/Auth";

import { Typography } from "@mui/material";

import getSubdomain from "utils/getSubdomain";
import Head from "components/Head";
import Page from "./Page";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import { DriveProvider } from "./context/Drive";

interface DriveProps {
  isPage?: boolean;
  companyToken?: string;
}

const Drive: FC<DriveProps> = ({ isPage = true, companyToken }) => {
  const {
    user: { company },
  } = useAuth();

  return (
    <>
      {isPage && <Head title={`Drive | ${getSubdomain()}`} />}

      <Typography variant={isPage ? "h4" : "h5"} fontWeight="bold" gutterBottom>
        Drive
      </Typography>

      {company ? (
        <DriveProvider companyToken={companyToken ?? company.token}>
          <Page companyToken={companyToken ?? company.token} />
        </DriveProvider>
      ) : (
        <Typography>{MESSAGE_NO_COMPANY}</Typography>
      )}
    </>
  );
};

export default Drive;
