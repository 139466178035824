import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getProfile } from "services/Requests/openCompany";

const useQueryProfile = (companyToken: string) => {
  const query = useQuery([queryKeys.openCompanyProfile, companyToken], () =>
    getProfile({ companyToken }),
  );
  return query;
};

export default useQueryProfile;
