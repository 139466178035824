import { FC, useState } from "react";

import { Box, Tab, Tabs, Theme, useMediaQuery } from "@mui/material";

import TabPanel, { TabPanelProps } from "components/TabPanel";
import { ServicePlan } from "types/ServiceAndPlan";

import CalculatorTab from "../../components/CalculatorTab";
import CompanyTab from "../../components/CompanyTab";
import PartnerTab from "../../components/PartnerTab";
import ContractTerms from "../../components/ContractTerms";

interface TabsControllerProps {
  companyToken: string;
  service: ServicePlan;
}

const TabsController: FC<TabsControllerProps> = ({ companyToken, service }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [activeTab, setActiveTab] = useState(0);

  const handleNext = () => setActiveTab(activeTab + 1);
  const handleBack = () => setActiveTab(activeTab - 1);

  const tabPanelProps: TabPanelProps = {
    keepMounted: true,
    value: activeTab,
    sx: {
      p: isMobile ? 2 : 3,
    },
    index: 0,
  };

  return (
    <Box>
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{
          borderBottom: 1,
          borderBottomColor: "divider",
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Introdução" />
        <Tab label="Sua empresa" disabled={activeTab < 1} />
        <Tab label="Sócios" disabled={activeTab < 2} />
        <Tab label="Contrato" disabled={activeTab < 3} />
      </Tabs>

      <TabPanel {...tabPanelProps} index={0}>
        <CalculatorTab onNext={handleNext} plan={service} />
      </TabPanel>
      <TabPanel {...tabPanelProps} index={1}>
        <CompanyTab companyToken={companyToken} onBack={handleBack} onNext={handleNext} otherForm />
      </TabPanel>
      <TabPanel {...tabPanelProps} index={2}>
        <PartnerTab companyToken={companyToken} onBack={handleBack} onNext={handleNext} />
      </TabPanel>
      <TabPanel value={activeTab} index={3} keepMounted>
        <ContractTerms downloadToken={service.downloadToken} onBack={handleBack} />
      </TabPanel>
    </Box>
  );
};

export default TabsController;
