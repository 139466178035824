import { useAuth } from "context/Auth";
import { useCallback } from "react";
import { useQuery } from "react-query";

import queryKeys from "react-query/constants";
import { getUsersCompanies } from "services/Requests/admin";
import { UserCompany, UsersCompanies } from "types/Admin";

const useQueryUsersCompanies = (companyToken: string, search = "", filtered = false) => {
  const {
    user: { userInfo },
  } = useAuth();

  const selectorFn = useCallback(
    (data: UsersCompanies) => {
      const filterUser = (user: UserCompany) => {
        if (!filtered) return true;
        return user.id !== userInfo.id && user.status !== "Delete";
      };

      const searchUser = (user: UserCompany) => {
        if (!search.trim()) return true;

        return user.name.toLowerCase().search(search.toLowerCase()) !== -1;
      };

      return data.filter((user) => searchUser(user) && filterUser(user));
    },
    [filtered, search],
  );

  const query = useQuery(queryKeys.usersCompanies, () => getUsersCompanies({ companyToken }), {
    select: selectorFn,
  });
  return query;
};

export default useQueryUsersCompanies;
