import { forwardRef, Ref } from "react";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface BankAccountSelectInnerProps extends SelectProps {
  companyToken: string;
  helperText?: string;
  onlyIntegrated?: boolean;
}

function BankAccountSelectInner(
  { companyToken, helperText, onlyIntegrated = false, ...props }: BankAccountSelectInnerProps,
  ref: Ref<unknown>,
) {
  const {
    data: bankAccounts = [],
    isLoading,
    isError,
  } = useQueryGetCompanyBankAccounts(companyToken, onlyIntegrated);

  return (
    <FormControl size={props.size}>
      <InputLabel>{props.label}</InputLabel>
      <Select {...props} ref={ref} value={props.value || ""}>
        <MenuItem disabled value="">
          Selecione:{" "}
        </MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um error ao buscar os bancos</MenuItem>}

        {bankAccounts.map((bank) => (
          <MenuItem key={bank.id} value={bank.id}>
            {bank.alias || bank.bankName}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const BankAccountSelect = forwardRef(BankAccountSelectInner);
export default BankAccountSelect;
