import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getAccountantApps } from "services/Requests/accountantApps";

const useQueryAccountantApps = (companyId: number) => {
  const query = useQuery([queryKeys.accountantApps, companyId], () =>
    getAccountantApps({ companyId }),
  );
  return query;
};

export default useQueryAccountantApps;
