import { forwardRef, Ref } from "react";
import useQueryCompanyPartners from "pages/Home/hooks/useQueryCompanyPartners";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface CompanyPartnersSelectInnerProps extends SelectProps {
  companyToken: string;
  helperText?: string;
}

function CompanyPartnersSelectInner(
  { companyToken, helperText, ...props }: CompanyPartnersSelectInnerProps,
  ref?: Ref<unknown>,
) {
  const { data: partners = [], isLoading, isError } = useQueryCompanyPartners(companyToken);

  return (
    <FormControl size={props.size}>
      <InputLabel>{props.label}</InputLabel>
      <Select ref={ref} {...props} value={props.value || ""}>
        <MenuItem disabled value="">
          Selecione:{" "}
        </MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um erro ao os sócios</MenuItem>}

        {partners.map((partner) => (
          <MenuItem key={partner.id} value={partner.id}>
            {partner.name}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const CompanyPartnersSelect = forwardRef(CompanyPartnersSelectInner);
export default CompanyPartnersSelect;
