import { FC } from "react";

import { Typography } from "@mui/material";

import Head from "components/Head";
import ServicesList from "./ServicesList";
import getSubdomain from "utils/getSubdomain";

const Services: FC = () => {
  return (
    <>
      <Head title={`Marketplace | ${getSubdomain()}`} />

      <Typography variant="h4" fontWeight="bold" align="center" sx={{ mb: 4 }}>
        Selecione um dos serviços abaixo
      </Typography>

      <ServicesList />
    </>
  );
};

export default Services;
