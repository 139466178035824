import * as yup from "yup";

const validationSchema = yup.object().shape({
  servicePremiumPriceId: yup
    .string()
    .required("Escolha um serviço")
    .typeError("Escolha um serviço"),
  iAgree: yup
    .boolean()
    .required("Você precisa aceitar os termos e condições")
    .typeError("Você precisa aceitar os termos e condições")
    .isTrue("Você precisa aceitar os termos e condições"),
});

export default validationSchema;
