import endpoints from "../endpoints";
import api from "../api";

import {
  BillingInfo,
  BillingInfoAdmin,
  Bill,
  Spreads,
  BillingInfoAdminResponse,
  BillingInfoResponse,
  UserBill,
  Dunning,
  PenaltiesAndDiscountsResponse,
  PenaltiesAndDiscounts,
  FinancialResponse,
  AnticipationResponse,
} from "types/Billing";
import { Subscription } from "types/Subscription";
import { ISODate } from "types/SemanticTypes";
import normalizeBillingInfo from "utils/normalizeBillingInfo";
import getSubdomain from "utils/getSubdomain";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

/* eslint-disable camelcase */

interface GetInfosIUGU {
  subdomain: string;
}

export const getInfosIUGU = async (payload: GetInfosIUGU): Promise<BillingInfo> => {
  const { data } = await api.post(endpoints.billing.infosIUGU, {
    subdomain: payload.subdomain,
  });

  return data;
};

export interface GetInfoAccountPayload {
  companyToken: string;
}

export const getInfoAccount = async (payload: GetInfoAccountPayload): Promise<BillingInfo> => {
  const subdomain = getSubdomain();
  const { data } = await api.post<BillingInfoResponse>(endpoints.billing.infoAccount, {
    ...payload,
    subdomain,
  });
  return normalizeBillingInfo(data);
};

interface GetInfoAccountAdminPayload {
  payerCompanyId: number;
  subdomain: string;
}

export const getInfoAccountAdmin = async (
  payload: GetInfoAccountAdminPayload,
): Promise<BillingInfoAdmin> => {
  const { data } = await api.post<BillingInfoAdminResponse>(
    endpoints.billing.infoAccountAdmin,
    payload,
  );
  return normalizeBillingInfo(data) as BillingInfoAdmin;
};

interface GetBillsPayload {
  maxResults: number;
  payerCompanyId: number;
  subdomain: string;
  companyToken: string;
}

export const getBills = async (payload: GetBillsPayload): Promise<Bill[]> => {
  const { data } = await api.post<Bill[]>(endpoints.billing.bills, payload);
  return data;
};

interface CancelBillPayload {
  companyToken: string;
  id: string;
  payerCompanyId: number;
  subdomain: string;
}

export const cancelBill = async (payload: CancelBillPayload) => {
  const { data } = await api.post(endpoints.billing.cancelBill, payload);
  return data;
};

export interface CreateBillPayload {
  companyToken: string;
  customVariables: {
    name: string;
    value: string;
  }[];
  dueDate: string; // ex.: "Thu Jul 07 2022"
  items: {
    description: string;
    quantity: number;
    value?: number;
    price_cents?: number | string;
  }[];
  ownerId?: number;
  payerCompanyId?: number;
  subdomain: string;
}

export const createBill = async (payload: CreateBillPayload) => {
  const { data } = await api.post(endpoints.billing.createBill, {
    companyToken: payload.companyToken,
    items: payload.items,
    ownerId: payload.ownerId,
    payerCompanyId: payload.payerCompanyId,
    subdomain: payload.subdomain,
    custom_variables: payload.customVariables,
    due_date: payload.dueDate,
  });
  return data;
};

export interface CancelSubscriptionPayload {
  companyToken: string;
  id: string;
  payerCompanyId: number;
  subdomain: string;
}

export const cancelSubscription = async (
  payload: CancelSubscriptionPayload,
): Promise<Subscription> => {
  const { data } = await api.post<Subscription>(endpoints.billing.cancelSubscription, payload);
  return data;
};

export interface ActiveSubscriptionPayload {
  companyToken: string;
  id: string;
  payerCompanyId: number;
  subdomain: string;
}

export const activeSubscription = async (
  payload: ActiveSubscriptionPayload,
): Promise<Subscription> => {
  const { data } = await api.post<Subscription>(endpoints.billing.activeSubscription, payload);
  return data;
};

export interface DeleteSubscriptionPayload {
  companyToken: string;
  id: string;
  payerCompanyId: number;
  subdomain: string;
}

export const deleteSubscription = async (
  payload: DeleteSubscriptionPayload,
): Promise<Subscription | null> => {
  const { data } = await api.post(endpoints.billing.deleteSubscription, payload);
  return data;
};

export interface CreateSubscriptionPayload {
  companyToken: string;
  dueDate: string; // "Thu Jul 07 2022"
  items: {
    id: number;
    description: string;
    value?: number;
  }[];
  ownerId: number;
  payerCompanyId: number;
  planId?: string;
  subdomain: string;
}

export const createSubscription = async (payload: CreateSubscriptionPayload) => {
  const { data } = await api.post(endpoints.billing.createSubscription, payload);
  return data;
};

export interface GetSpreadsPayload {
  companyToken: string;
  subdomain: string;
  accountantCompanyId?: number | null;
}

export const getSpreads = async ({
  accountantCompanyId = null,
  ...payload
}: GetSpreadsPayload): Promise<Spreads> => {
  const { data } = await api.post<Spreads>(endpoints.billing.spreads, {
    accountantCompanyId,
    ...payload,
  });
  return data;
};

export interface UpdateSpreadsPayload {
  ccAdvanceSpread: number;
  companyId: number;
  creditCardSpread: string | number;
  invoiceSpread: string;
  subdomain: string;
  withdrawSpread: string | number;
}

export const updateSpreads = async (payload: UpdateSpreadsPayload): Promise<void> => {
  await api.post(endpoints.billing.updateSpreads, payload);
};

export interface DuplicateBillPayload {
  companyToken: string;
  dueDate: ISODate;
  id: string;
  item: {
    value: number;
    description: string;
    quantity: number;
  };
  payerCompanyId: number;
  ownerId?: number;
  subdomain: string;
}

export const duplicateBill = async (payload: DuplicateBillPayload) => {
  const { data } = await api.post(endpoints.billing.duplicateBill, {
    companyToken: payload.companyToken,
    custom_variables: [{ name: "type", value: "duplicate" }],
    due_date: payload.dueDate,
    id: payload.id,
    items: [payload.item],
    payerCompanyId: payload.payerCompanyId,
    subdomain: payload.subdomain,
    ownerId: payload.ownerId,
  });

  return data;
};

export interface GetUsersBillsPayload {
  companyToken: string;
  createdAtFrom: ISODate;
  createdAtTo: ISODate;
  maxResults: number;
  subdomain: string;
  statusFilter?: "expired" | "canceled" | "pending" | "paid" | null;
}

export const getUsersBills = async (payload: GetUsersBillsPayload): Promise<UserBill[]> => {
  const { data } = await api.post<UserBill[]>(endpoints.billing.getUsersBills, payload);
  return data;
};

export interface CancelBillByAccountantPayload {
  companyToken: string;
  id: string;
}

export const cancelBillByAccountant = async (payload: CancelBillByAccountantPayload) => {
  await api.post(endpoints.billing.cancelBillByAccountant, payload);
};

export interface DuplicateBillByAccountantPayload {
  id: string;
  companyToken: string;
  subdomain: string;
  due_date: string;
  item: { price_cents: number; description: string };
}

export const duplicateBillByAccountant = async ({
  item,
  ...payload
}: DuplicateBillByAccountantPayload) => {
  await api.post(endpoints.billing.duplicateBillByAccountant, {
    ...payload,
    custom_variables: { name: "type", value: "duplicate" },
    items: [{ ...item, quantity: 1 }],
  });
};

export interface OverdueSettingsPayload {
  companyToken: string;
  subdomain: string;
}

export const getOverdueSettings = async (payload: OverdueSettingsPayload) => {
  const { data } = await api.post(endpoints.billing.overdueSettings, payload);
  return data;
};

export interface GetDunningPayload {
  companyToken: string;
  subdomain: string;
}

export const getDunning = async (payload: GetDunningPayload) => {
  const { data } = await api.post<Dunning[]>(endpoints.billing.getDunning, payload);
  return data;
};

export interface UpdateDunningPayload {
  charge: number;
  companyToken: string;
  expire: number;
  invoiceName: string;
  subdomain: string;
}

export const updateDunning = async (payload: UpdateDunningPayload) => {
  await api.post(endpoints.billing.updateDunning, payload);
};

export interface GetPenaltiesAndDiscountsPayload {
  companyToken: string;
  subdomain: string;
}

export const getPenaltiesAndDiscounts = async (
  payload: GetPenaltiesAndDiscountsPayload,
): Promise<PenaltiesAndDiscounts> => {
  const { data } = await api.post<PenaltiesAndDiscountsResponse>(
    endpoints.billing.getPenaltiesAndDiscounts,
    payload,
  );
  return {
    discountsDays: Number(data.discountsDays),
    discountsPercent: Number(data.discountsPercent),
    discountsValues: data.discountsValues
      ? formatCurrencyBRL(Number(data.discountsValues))
      : data.discountsValues,
    penaltiesMora: data.penaltiesMora === "true",
    penaltiesPercent: Number(data.penaltiesPercent),
  };
};

export interface UpdatePenaltiesAndDiscountsPayload {
  companyToken: string;
  discountsDays: number;
  discountsPercent: string | number;
  discountsValues: string;
  penaltiesMora: boolean;
  penaltiesPercent: number;
  subdomain: string;
}

export const updatePenaltiesAndDiscounts = async (payload: UpdatePenaltiesAndDiscountsPayload) => {
  await api.post(endpoints.billing.updatePenaltiesAndDiscounts, payload);
};

export interface WithdrawOsaykPayload {
  subdomain: string;
  amount: number;
}

export const withdrawOsayk = async (payload: WithdrawOsaykPayload) => {
  await api.post(endpoints.billing.withdrawOsayk, payload);
};

export type Withdraw3rdPayload = WithdrawOsaykPayload;

export const withdraw3rd = async (payload: Withdraw3rdPayload) => {
  await api.post(endpoints.billing.withdraw3rd, payload);
};

export interface WithdrawPayload extends WithdrawOsaykPayload {
  companyToken: string;
}

export const withdraw = async (payload: WithdrawPayload) => {
  const { data } = await api.post<{ id: string; amount: number }>(
    endpoints.billing.withdraw,
    payload,
  );
  return data;
};

export interface UpdateSubscriptionPayload extends Omit<CreateSubscriptionPayload, "items"> {
  items: {
    id?: string;
    price?: string;
    price_cents?: number;
    quantity?: number;
    total?: string;
    description: string;
    value: number;
    recurrent: boolean;
    _destroy: null | boolean;
  }[];
  id: string;
}

export const updateSubscription = async (payload: UpdateSubscriptionPayload) => {
  const { data } = await api.post(endpoints.billing.updateSubscription, payload);
  return data;
};

export interface GetFinancialPayload {
  companyToken: string;
  maxResults: null | number;
  month: string;
  subdomain: string;
  year: string;
}

export const getFinancial = async (payload: GetFinancialPayload): Promise<FinancialResponse> => {
  const { data } = await api.post<FinancialResponse>(endpoints.billing.getFinancial, payload);
  return data;
};

export interface GetAllTransactionsPayload {
  companyToken: string;
  maxResults: number;
  subdomain: string;
}

export const getAllTransactions = async (payload: GetAllTransactionsPayload) => {
  const { data } = await api.post<AnticipationResponse>(
    endpoints.billing.getAllTransactions,
    payload,
  );
  return data;
};

export interface RequestAdvancementsPaylaod {
  companyToken: string;
  subdomain: string;
  idsForAdvance: string[];
}

export const requestAdvancements = async (payload: RequestAdvancementsPaylaod) => {
  await api.post(endpoints.billing.requestAdvancements, payload);
};
