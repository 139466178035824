import { FC } from "react";

import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Lock, Menu } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import { FinancialCategory } from "types/Company";

interface CategoryItemProps {
  category: FinancialCategory;
  indent: number;
  onClickMenu: (el: HTMLButtonElement, category: FinancialCategory) => void;
  hasDivider?: boolean;
}

const CategoryItem: FC<CategoryItemProps> = ({
  category,
  indent,
  onClickMenu,
  hasDivider = true,
}) => {
  const hasChildren = category.children.length > 0;
  const colorText: "text.secondary" | "text.primary" = category.isGlobal
    ? "text.secondary"
    : "text.primary";

  return (
    <Box component="div" borderBottom={hasDivider ? 1 : 0} borderColor="divider">
      <Box
        sx={{
          "&:hover": {
            bgcolor: grey[200],
          },
        }}
        borderBottom={hasChildren ? 1 : 0}
        borderColor="divider"
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          gap={2}
          p={1}
          pl={2 * indent === 0 ? 1 : 2 * indent}
        >
          <Stack gap={1} direction="row" alignItems="center">
            <Typography
              variant={category.parentUId ? "body2" : "subtitle1"}
              fontWeight={category.parentUId ? 400 : 600}
              color={colorText}
              whiteSpace="nowrap"
            >
              {category.name}
            </Typography>

            {category.isGlobal && (
              <Tooltip title="Esta categoria nativa não pode ser editada.">
                <Lock fontSize="small" color="disabled" />
              </Tooltip>
            )}
          </Stack>

          {!category.isGlobal && (
            <IconButton size="small" onClick={(e) => onClickMenu(e.currentTarget, category)}>
              <Menu fontSize="small" />
            </IconButton>
          )}
        </Stack>
      </Box>

      {hasChildren && (
        <Box sx={{ "& > div .padding": { pl: 2 } }}>
          {category.children.map((categoryChild, index, arr) => (
            <CategoryItem
              category={categoryChild}
              key={categoryChild.id}
              hasDivider={index !== arr.length - 1}
              indent={indent + 1}
              onClickMenu={onClickMenu}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CategoryItem;
