import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { linkAccount } from "services/Requests/bankingIntegrator";

const useMutationLinkAccount = () => {
  const mutation = useMutation(linkAccount, {
    onError: () => {
      toast.error("Não foi possível integrar a conta, verifique seus dados e tente novamente.");
    },
  });
  return mutation;
};

export default useMutationLinkAccount;
