import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { addForm } from "services/Requests/marketplace";

const useMutationAddForm = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(addForm, {
    onSuccess(_, { formName }) {
      toast.success("Formulário adicionado com sucesso");
      queryClient.invalidateQueries(queryKeys.groupsForm);
      queryClient.invalidateQueries(queryKeys.forms);
      queryClient.invalidateQueries([queryKeys.clientsConfigLeads, formName]);
    },
  });
  return mutation;
};

export default useMutationAddForm;
