import { FC, useState } from "react";
import useMutationSaveAccountantApps from "../../../hooks/useMutationSaveAccountantApps";

import { Box, Typography, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { AccountantApp } from "types/Accountant";
import { LoadingButton } from "@mui/lab";

interface ControlProps {
  accountantApp: AccountantApp[];
  companyId: number;
}

const Control: FC<ControlProps> = ({ accountantApp, companyId }) => {
  const { mutate, isLoading } = useMutationSaveAccountantApps();
  const [apps, setApps] = useState(accountantApp);

  const handleChange = (checked: boolean, key: string) => {
    setApps((prevApps) => {
      return prevApps.map((app) => {
        if (app.key === key) {
          return { ...app, value: checked ? "true" : "false" };
        }
        return app;
      });
    });
  };

  const handleSave = () => {
    mutate({
      apps,
      companyId,
    });
  };

  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ mb: 2, borderBottom: 1, borderColor: "divider" }}
      >
        Apps do contador
      </Typography>

      <FormGroup>
        {apps.map((app) => (
          <FormControlLabel
            control={<Checkbox checked={app.value === "true"} />}
            key={app.key}
            label={app.name}
            onChange={(_, checked) => {
              handleChange(checked, app.key);
            }}
            disabled={isLoading}
          />
        ))}
      </FormGroup>

      <LoadingButton
        loading={isLoading}
        sx={{ mt: 4 }}
        size="small"
        variant="contained"
        onClick={handleSave}
      >
        Atualizar
      </LoadingButton>
    </Box>
  );
};

export default Control;
