import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getAllChats } from "services/Requests/mailbox";
import { StatusRequest } from "types/Mailbox";

const useQueryGetAllChats = (statusRequest: StatusRequest = "open", lastDays = 0) => {
  const query = useQuery([queryKeys.allChats, statusRequest, lastDays], () =>
    getAllChats({
      lastDays,
      status: statusRequest,
    }),
  );
  return query;
};

export default useQueryGetAllChats;
