import { FC, MouseEventHandler, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSideMenu } from "context/SideMenuContext/useSideMenu";
import { useCompanyInfo } from "context/CompanyInfo";

import {
  Box,
  Fade,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { SideMenuItem as SideMenuItemProps } from "../../utils/getSideMenuItems";
import SideMenuItemWithChildren from "./SideMenuItemWithChildren";
import WithLink from "./WithLink";

const SideMenuItem: FC<SideMenuItemProps & { onClick?: () => void }> = (props) => {
  const { title, link, children, Icon, onClick } = props;
  const { companyStyles } = useCompanyInfo();
  const { pathname } = useLocation();
  const { isOpen } = useSideMenu();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  if (!link && children && children.length > 0) {
    return <SideMenuItemWithChildren {...props} />;
  }

  const handleMouseEnter: MouseEventHandler<HTMLLIElement> | undefined = (event) => {
    if (isOpen || title.trim() === "") return;
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (isOpen && open) {
      setAnchorEl(null);
      return;
    }

    if (open && title.trim() === "") {
      setAnchorEl(null);
      return;
    }
  }, [isOpen, title, open]);

  return (
    <Box
      component="li"
      key={title}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setAnchorEl(null)}
      position="relative"
    >
      <WithLink link={link}>
        <ListItemButton
          sx={{
            py: 1,
            gap: 1.5,
            borderLeft: "5px solid transparent",
            borderLeftColor:
              pathname === link ? companyStyles.sidebarLinkHoverBorder : "transparent",
            transition: "0.2s",
            "&:hover, &:focus": {
              background: `${companyStyles.sidebarLinkHoverBg} !important`,
            },
          }}
          selected={pathname === link}
        >
          <ListItemIcon sx={{ minWidth: "fit-content" }}>
            {Icon ? <Icon fontSize="small" /> : "•"}
          </ListItemIcon>
          <ListItemText
            color="text.primary"
            primary={title}
            primaryTypographyProps={{
              fontSize: 14,
              fontWeight: "light",
              lineHeight: "20px",
              whiteSpace: isOpen ? "normal" : "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            sx={{ opacity: isOpen ? 1 : 0 }}
          />
        </ListItemButton>
      </WithLink>

      <Popper open={open} anchorEl={anchorEl} placement="right-start" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                background: `${companyStyles.sidebarBg} !important`,
                color: companyStyles.sidebarLinkColor,
                borderLeft:
                  pathname === link
                    ? `5px solid ${companyStyles.sidebarLinkHoverBorder}`
                    : undefined,
              }}
            >
              <Typography sx={{ p: 2 }} color="inherit">
                {title}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default SideMenuItem;
