import { FC, useState } from "react";
import { useAuth } from "context/Auth";

import Add from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";

import Head from "components/Head";
import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import ProfileList from "./ProfileList";
import DialogCreateProfile from "./DialogUpdateCreateProfile";

const ProfileRegistrations: FC = () => {
  const {
    user: { company },
  } = useAuth();
  const [dialogCreateProfileIsOpen, setDialogCreateProfileIsOpen] = useState(false);

  return (
    <>
      <Head title={`Cadastro de Perfis | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Cadastro de Perfis
        </Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<Add fontSize="small" />}
          disabled={!company}
          onClick={() => setDialogCreateProfileIsOpen(true)}
        >
          Adicionar novo perfil
        </Button>
      </Stack>

      <Box boxShadow={1} p={2} mt={2}>
        {company ? <ProfileList /> : <Typography variant="body2">{MESSAGE_NO_COMPANY}</Typography>}
      </Box>

      <DialogCreateProfile
        open={dialogCreateProfileIsOpen}
        onClose={() => setDialogCreateProfileIsOpen(false)}
      />
    </>
  );
};

export default ProfileRegistrations;
