import { FC, useState } from "react";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import { TemplateFinancial } from "types/Company";
import TemplateFinancialTableItem from "./TemplateFinancialTableItem";
import NewOrEditTemplate from "../components/NewOrEditTemplate";

interface TemplateFinancialTableProps {
  templatesFinancial: TemplateFinancial[];
}

const TemplateFinancialTable: FC<TemplateFinancialTableProps> = ({ templatesFinancial }) => {
  const [edittedTemplate, setEdittedTemplate] = useState<TemplateFinancial | null>(null);

  const closeEditTemplate = () => setEdittedTemplate(null);

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {templatesFinancial.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>Nenhum template cadastrado</TableCell>
              </TableRow>
            )}

            {templatesFinancial.map((template) => (
              <TemplateFinancialTableItem
                key={template.id}
                template={template}
                onEditTemplate={() => setEdittedTemplate(template)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {edittedTemplate && (
        <NewOrEditTemplate
          keepMounted={false}
          open={!!edittedTemplate}
          onClose={closeEditTemplate}
          template={edittedTemplate || undefined}
        />
      )}
    </>
  );
};

export default TemplateFinancialTable;
