import { useQueryClient } from "react-query";
import queryKeys from "react-query/constants";

const useInvalidateDashboardQueries = () => {
  const queryClient = useQueryClient();

  const invalidate = () => {
    queryClient.invalidateQueries(queryKeys.summary, { exact: false });
    queryClient.invalidateQueries(queryKeys.pieChartData, { exact: false });
    queryClient.invalidateQueries(queryKeys.revenuesVsExpenses, { exact: false });
    queryClient.invalidateQueries(queryKeys.latestTransactions, { exact: false });
  };

  return invalidate;
};

export default useInvalidateDashboardQueries;
