import { Descendant } from "slate";
import { TextAlign, TextFormat } from "../slate";
import { createParagraphNode } from "./paragraph";

export const HOTKEYS: { [x: string]: TextFormat } = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+d": "strikethrough",
};

export const TEXT_ALIGN_TYPES: TextAlign[] = ["left", "center", "right", "justify"];
export const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const EMPTY: Descendant[] = [createParagraphNode()];
