import { useMutation, useQueryClient } from "react-query";
import { deleteParticipant } from "services/Requests/companies";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";

const useMutationDeleteParticipant = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteParticipant, {
    onSuccess() {
      toast.success("Cliente excluído com sucesso.");
      queryClient.invalidateQueries(queryKeys.companyParticipants, { exact: false });
    },
  });
};

export default useMutationDeleteParticipant;
