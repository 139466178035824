import { useCallback, useState } from "react";
import useMutationUpdateUser from "../UsersContent/hooks/useMutationUpdateUser";
import useQueryCompanyProfiles from "pages/Home/hooks/useQueryCompanyProfiles";
import useMutationAddUser, { DataForAddUser } from "../UsersContent/hooks/useMutationAddUser";

import ControllerInput from "components/ControllerInput";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { UserTypeContent, userTypeTranslate } from "../UsersContent";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema, validationSchemaForUserGuest } from "./validationSchema";
import { TypesOfUsers } from "types/UserTypes";
import { UserStatus } from "types/User";

type ProfileSelector = "admin" | number;

interface UserRequired {
  name?: string;
  login?: string;
  email?: string;
  profileId?: number | null;
  status: UserStatus;
  id: number;
  userType?: "Admin" | "Accountant";
}

const toUserTypes: Record<UserTypeContent, TypesOfUsers> = {
  accountant: "Accountant",
  admin: "Admin",
  Guest: "Guest",
};

interface DialogCreateOrUpdateAdminUserProps<T extends UserRequired> extends DialogProps {
  companyToken: string;
  userType: UserTypeContent;
  user?: T;
}

function DialogCreateOrUpdateAdminUser<T extends UserRequired>({
  companyToken,
  userType,
  user,
  onClose,
  ...props
}: DialogCreateOrUpdateAdminUserProps<T>) {
  const {
    data = [],
    isLoading,
    isError,
  } = useQueryCompanyProfiles("", userType === "Guest" ? companyToken : undefined);
  const [profileSelected, setProfileSelected] = useState<ProfileSelector>(
    user?.profileId || "admin",
  );

  const { mutateAsync: mutateAdd, isLoading: addUserIsLoading } = useMutationAddUser();
  const { mutateAsync: mutateUpdate, isLoading: updateIsLoading } = useMutationUpdateUser();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(userType === "Guest" ? validationSchemaForUserGuest : validationSchema),
    defaultValues: {
      name: user?.name || "",
      login: user?.login || "",
      email: user?.email || "",
    },
  });

  const resetFormFields = useCallback(() => {
    reset({
      email: user?.email || "",
      login: user?.login || "",
      name: user?.name || "",
    });
    setProfileSelected("admin");
  }, [user]);

  const handleClose = () => {
    if (!addUserIsLoading && !updateIsLoading && onClose) onClose({}, "backdropClick");
  };

  const onSubmit = handleSubmit(async (data) => {
    let dataForAddUser: DataForAddUser = {
      email: data.email,
      fullControl: userType !== "Guest" ? 0 : undefined,
      login: userType !== "Guest" ? data.login : data.email,
      name: data.name,
      userTypes: toUserTypes[userType],
    };

    if (profileSelected !== "admin") {
      dataForAddUser = {
        ...dataForAddUser,
        profileId: profileSelected,
      };
    }

    const runMutate = async () => {
      if (!user) {
        await mutateAdd(dataForAddUser);
      } else {
        await mutateUpdate({
          ...dataForAddUser,
          status: user.status,
          id: user.id,
          userType: user.userType || "Guest",
          profileId: profileSelected === "admin" ? null : profileSelected,
          companyToken,
        });
      }
    };

    await runMutate();
    resetFormFields();
    handleClose();
  });

  return (
    <Dialog maxWidth="sm" fullWidth {...props} onClose={handleClose}>
      <DialogTitle>
        {!user ? "Cadastrar" : "Editar"} {userTypeTranslate[userType]}
      </DialogTitle>

      <form onSubmit={onSubmit}>
        <DialogContent dividers>
          {isLoading ? (
            <Stack alignItems="center" justifyContent="center" height={200}>
              <CircularProgress />
            </Stack>
          ) : (
            <Stack gap={2}>
              <ControllerInput
                control={control}
                name="name"
                inputProps={{
                  label: "Nome",
                  size: "small",
                }}
              />
              {userType !== "Guest" && (
                <ControllerInput
                  control={control}
                  name="login"
                  inputProps={{
                    label: "Login",
                    size: "small",
                  }}
                />
              )}
              <ControllerInput
                control={control}
                name="email"
                inputProps={{
                  label: "Email",
                  size: "small",
                }}
              />

              <FormControl fullWidth>
                <InputLabel>Perfil</InputLabel>
                <Select
                  value={profileSelected}
                  onChange={(e) => {
                    setProfileSelected(e.target.value as ProfileSelector);
                  }}
                  label="Perfil"
                  size="small"
                >
                  {isError && (
                    <MenuItem value="" disabled>
                      Outros perfis não foram encontrados
                    </MenuItem>
                  )}
                  <MenuItem value="admin">Administrador</MenuItem>

                  {data.map((profile) => (
                    <MenuItem value={profile.id} key={profile.id}>
                      {profile.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton
            loading={addUserIsLoading || updateIsLoading}
            variant="contained"
            type="submit"
          >
            Salvar
          </LoadingButton>
          <Button variant="outlined" type="button" onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DialogCreateOrUpdateAdminUser;
