import { FC, useState } from "react";
import { useClientInformation } from "../../../../context/ClientInformation";
import useMutationToggleStatusSubscription from "../../../../hooks/useMutationToggleStatusSubscription";
import useMutationDeleteSubscription from "../../../../hooks/useMutationDeleteSubscription";

import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Add from "@mui/icons-material/Add";
import PlayArrow from "@mui/icons-material/PlayArrow";
import Delete from "@mui/icons-material/Delete";
import Pause from "@mui/icons-material/Pause";
import RemoveIcon from "@mui/icons-material/Remove";

import { DateTime } from "luxon";
import { Subscription } from "types/Subscription";
import SubItems from "./SubItems";

interface SubscriptionTableItemProps {
  subscription: Subscription;
}

const SubscriptionTableItem: FC<SubscriptionTableItemProps> = ({ subscription }) => {
  const { payerCompanyId, companyToken } = useClientInformation();

  const [collapseTable, setCollapseTable] = useState(false);
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [showConfirmationChangeStatus, setConfirmationChangeStatus] = useState(false);

  const { isLoading, mutate } = useMutationToggleStatusSubscription();
  const deleteMutation = useMutationDeleteSubscription();

  const getDateFormat = (date: string) => {
    let dateTime = DateTime.fromFormat(date, "yyyy-MM-dd");
    if (dateTime.isValid) {
      return dateTime.toFormat("dd/MM/yyyy");
    }

    dateTime = DateTime.fromISO(date);
    if (dateTime.isValid) {
      return dateTime.toFormat("dd/MM/yyyy");
    }

    return "";
  };

  return (
    <>
      <TableRow
        sx={{
          opacity: isLoading || deleteMutation.isLoading ? 0.5 : 1,
          transition: "opacity 300ms ease",
          "*": {
            pointerEvents: isLoading || deleteMutation.isLoading ? "none" : "all",
          },
        }}
      >
        <TableCell padding="checkbox">
          <Tooltip title="Subitens e edição" placement="top">
            <IconButton size="small" onClick={() => setCollapseTable((prev) => !prev)}>
              {collapseTable ? <RemoveIcon fontSize="small" /> : <Add fontSize="small" />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>{subscription.plan_name}</TableCell>
        <TableCell>{DateTime.fromISO(subscription.created_at).toLocaleString()}</TableCell>
        <TableCell>
          <TextField
            size="small"
            disabled
            value={getDateFormat(subscription.expires_at)}
            InputProps={{
              readOnly: true,
            }}
          />
        </TableCell>
        <TableCell>{subscription.suspended ? "Inativo" : "Ativo"}</TableCell>
        <TableCell>
          <Stack direction="row">
            <Tooltip
              title={subscription.suspended ? "Ativar assinatura" : "Cancelar assinatura"}
              placement="top"
            >
              <IconButton size="small" onClick={() => setConfirmationChangeStatus(true)}>
                {subscription.suspended ? (
                  <PlayArrow fontSize="small" />
                ) : (
                  <Pause fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title="Deletar assinatura" placement="top">
              <IconButton size="small" onClick={() => setShowConfirmationDelete(true)}>
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>

      <TableRow
        sx={{
          bgcolor: grey[100],
          opacity: isLoading || deleteMutation.isLoading ? 0.5 : 1,
          transition: "opacity 300ms ease",
          "*": {
            pointerEvents: isLoading || deleteMutation.isLoading ? "none" : undefined,
          },
        }}
      >
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={collapseTable} unmountOnExit>
            <SubItems subscription={subscription} />
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
        open={showConfirmationChangeStatus}
        onClose={() => setConfirmationChangeStatus(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {subscription.suspended ? "Ativar assinatura" : "Cancelar assinatura"}
        </DialogTitle>

        <DialogContent dividers>
          Deseja {subscription.suspended ? "ativar" : "cancelar"} a assinatura{" "}
          {subscription.plan_name}?
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirmationChangeStatus(false)}>
            Não
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              mutate({
                action: subscription.suspended ? "activate" : "deactivate",
                id: subscription.id,
                companyToken,
                payerCompanyId,
              });

              setConfirmationChangeStatus(false);
            }}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmationDelete}
        onClose={() => setShowConfirmationDelete(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Deletar assinatura</DialogTitle>

        <DialogContent dividers>
          Tem certeza que deseja excluir a assinatura? Faturas em aberto associadas deverão ser
          canceladas manualmente?
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => setShowConfirmationDelete(false)}>
            Não
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              deleteMutation.mutate({
                companyToken,
                id: subscription.id,
                payerCompanyId,
              });

              setShowConfirmationDelete(false);
            }}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubscriptionTableItem;
