import { useQueries } from "react-query";
import queryKeys from "react-query/constants";
import {
  getCompanyStyles,
  getPathImageByType,
  getTypeSubdomain,
} from "services/Requests/marketplace";
import getSubdomain from "utils/getSubdomain";

const getAllPathImages = async (subdomain: string) => {
  const promisePathUrlLogin = getPathImageByType(subdomain, "urlLogin");
  const promisePathUrlTop = getPathImageByType(subdomain, "urlTop");

  const [pathUrlLogin, pathUrlTop] = await Promise.all([promisePathUrlLogin, promisePathUrlTop]);

  return {
    pathImage: pathUrlLogin,
    imageTop: pathUrlTop,
  };
};

export const useCompanyQueries = () => {
  const subdomain = getSubdomain();

  const [pathImage, companyStyles, typeSubdomain] = useQueries([
    {
      queryKey: [queryKeys.pathImage, subdomain],
      queryFn: () => getAllPathImages(subdomain),
      cacheTime: Infinity,
      staleTime: Infinity,
    },
    {
      queryKey: [queryKeys.styles, subdomain],
      queryFn: () => getCompanyStyles(subdomain),
      cacheTime: Infinity,
      staleTime: Infinity,
    },
    {
      queryKey: [queryKeys.typeSubdomain, subdomain],
      queryFn: () => getTypeSubdomain(subdomain),
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  ]);

  return {
    pathImage,
    companyStyles,
    typeSubdomain,
  };
};
