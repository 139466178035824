import * as yup from "yup";

const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required("O nome é obrigatório")
    .min(3, "O nome deve ter no mínimo 3 caracteres")
    .max(50, "O nome deve ter no máximo 50 caracteres")
    .matches(/^[^\d]+$/, "Não deve conter números"),
  email: yup.string().email("Digite um email correto").required("O email é obrigatório"),
  cellPhone: yup
    .string()
    .required("O celular é obrigatório")
    .test("cellPhone", "Digite um celular válido", (value) => {
      if (!value) return true;
      const cellPhoneOnlyNumber = value.replace(/\D/g, "");
      return cellPhoneOnlyNumber.length === 10 || cellPhoneOnlyNumber.length === 11;
    }),
  telephone: yup.string().test("cellPhone", "Digite um telefone válido", (value) => {
    return !value || value.replace(/\D/g, "").length === 8;
  }),
  password: yup
    .string()
    .required("A senha é obrigatória")
    .min(8, "A senha deve ter no mínimo 6 caracteres")
    .max(20, "A senha deve ter no máximo 20 caracteres"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas não conferem")
    .required("A confirmação de senha é obrigatória"),
  terms: yup
    .boolean()
    .typeError("Você tem que aceitar os termos")
    .test("terms", "Você tem que aceitar os termos", (val) => {
      return !!val;
    }),
});

export default validationSchema;
