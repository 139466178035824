import { FC, useMemo, useState } from "react";
import useMutationUpdatePaymentMethod from "../../hooks/useMutationUpdatePaymentMethod";
import { useForm, FormProvider, DefaultValues } from "react-hook-form";

import { Dialog, DialogContent, Theme, useMediaQuery } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import DialogMessageAfterAdd from "../../components/DialogMessageAfterAdd";
import DialogConfirmClose from "../../components/DialogConfirmClose";

import { ServicePlanDialogProps } from "..";
import TabsController from "./TabsController";

export interface FormFields {
  iAgree: boolean;
}

const DialogPFOpening: FC<ServicePlanDialogProps> = ({
  onClose,
  companyToken,
  service,
  ...props
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const { mutateAsync } = useMutationUpdatePaymentMethod();
  const [showMessageAfterAdd, setShowMessageAfterAdd] = useState(false);
  const [confirmExit, setConfirmExit] = useState(false);

  const defaultValues = useMemo<DefaultValues<FormFields>>(
    () => ({
      iAgree: false,
    }),
    [],
  );
  const formMethods = useForm<FormFields>({
    defaultValues,
  });

  const handleClose = () => {
    if (formMethods.formState.isSubmitting) {
      return;
    }

    setShowMessageAfterAdd(false);
    onClose();
  };

  const handleSubmit = formMethods.handleSubmit(async () => {
    await mutateAsync({
      companyToken,
      tributationType: 1,
    });
    setShowMessageAfterAdd(true);
  });

  return (
    <FormProvider {...formMethods}>
      <Dialog
        {...props}
        open={props.open && !showMessageAfterAdd}
        onClose={() => setConfirmExit(true)}
        maxWidth="lg"
        fullWidth
        fullScreen={!isDesktop}
      >
        <DialogTitleWithCloseButton onClose={() => setConfirmExit(true)}>
          Preencha seus dados
        </DialogTitleWithCloseButton>

        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(e);
          }}
        >
          <DialogContent>
            <TabsController companyToken={companyToken} service={service} />
          </DialogContent>
        </form>
      </Dialog>

      <DialogMessageAfterAdd
        title="Processo Finalizado"
        message={service.endText ?? ""}
        open={showMessageAfterAdd}
        onClose={handleClose}
      />

      <DialogConfirmClose
        open={confirmExit}
        onClose={() => setConfirmExit(false)}
        onConfirm={handleClose}
      />
    </FormProvider>
  );
};

export default DialogPFOpening;
