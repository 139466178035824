import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { markAsPresented } from "services/Requests/notifications";

const useMutationMarkAsPresented = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(markAsPresented, {
    onSuccess() {
      queryClient.invalidateQueries(queryKeys.unpresented);
    },
  });
  return mutation;
};

export default useMutationMarkAsPresented;
