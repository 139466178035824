import getSubdomain from "./getSubdomain";

export const BUCKET_URL = "https://s3-us-west-2.amazonaws.com/osayk-west";
export const BASE_URL_API = "https://api.osayk.com.br/api";
// export const BASE_URL_API = "http://api.osayk.dev.com.br/api";

export const HEADER_HEIGHT = 60;
export const SIDEMENU_WIDTH = 230;
export const DRAWER_WIDTH = 240;

export const COOKIE_TOKEN_NAME = `${getSubdomain()}-osayk-token`;

export const CLIENT_IMPORT_MODEL =
  "https://osaykprod.s3-us-west-2.amazonaws.com/files/modelo_importacao_clientes.xlsx";

export const ACCOUNT_IMPORT_MODEL =
  "https://s3-us-west-2.amazonaws.com/osaykprod/files/modelo_plano_de_contas.xlsx";

// export const BASE_URL_DOWNLOAD_DOCUMENT =
//   "https://api.osayk.com.br/api/companies/DownloadDocumentDrive";
// export const BASE_URL_DOWNLOAD_DOCUMENT_DRIVE =
//   "https://api.osayk.com.br/api/Companies/DownloadDocumentDrive";

export const BASE_URL_DOWNLOAD_DOCUMENT =
  "http://api.osayk.dev.com.br/api/companies/DownloadDocumentDrive";
export const BASE_URL_DOWNLOAD_DOCUMENT_DRIVE =
  "http://api.osayk.dev.com.br/api/Companies/DownloadDocumentDrive";

export const URL_CONTRACT_PROVISION_OF_ACCOUNTING_SERVICES =
  "https://app.osayk.com.br/assets/docs/legal/Contrato_Prestacao_Servicos_Contabeis.pdf";

export const INVOICE_BASE_URL = "https://faturas.iugu.com/";

export const MESSAGE_NO_COMPANY = "Empresa não localizada";

export const BACKOFFICE = [
  {
    slug: "ALTERDATATXT",
    label: "Alterdata (.txt) ",
  },
  {
    slug: "ALTERDATA",
    label: "Alterdata (.xls) ",
  },
  {
    slug: "CALIMA",
    label: "Calima (.csv) ",
  },
  {
    slug: "CONTABMILENIUM",
    label: "Contabmilenium (.xls) ",
  },
  {
    slug: "CONTIMATIC",
    label: "Contmatic (.txt) ",
  },
  {
    slug: "CONTIMATIXLSC",
    label: "Contmatic L1 (.xls) ",
  },
  {
    slug: "CONTIMATIXLSC2",
    label: "Contmatic L2 (.xls) ",
  },
  {
    slug: "DATAC",
    label: "Data C (.txt) ",
  },
  {
    slug: "DEXION",
    label: "Dexion (.txt) ",
  },
  {
    slug: "DOMINIO",
    label: "Domínio (.txt padrão) ",
  },
  {
    slug: "DOMINIO2",
    label: "Domínio 2 (.txt) ",
  },
  {
    slug: "DOMINIO3",
    label: "Domínio 3 (.txt) ",
  },
  {
    slug: "FORTESCT",
    label: "Fortes (.ct) ",
  },
  {
    slug: "FORTES",
    label: "Fortes (.csv) ",
  },
  {
    slug: "FORTES2",
    label: "Fortes 2.0 (.csv) ",
  },
  {
    slug: "FORTES3",
    label: "Fortes 2.1 (.csv) ",
  },
  {
    slug: "INTSYS",
    label: "IntSys (.xlsx) ",
  },
  {
    slug: "MAKROSYSTEM",
    label: "Makrosystem (.xls) ",
  },
  {
    slug: "MASTERMAQ",
    label: "MasterMaq (.csv) ",
  },
  {
    slug: "MEGAPROG",
    label: "Megaprog (.txt) ",
  },
  {
    slug: "NETSPEED",
    label: "Netspeed (.csv) ",
  },
  {
    slug: "QUESTOR",
    label: "Questor (.txt) ",
  },
  {
    slug: "QUESTORCSV",
    label: "Questor (.csv) ",
  },
  {
    slug: "RTA",
    label: "RTA (.csv) ",
  },
  {
    slug: "SAGE",
    label: "Sage (.txt) ",
  },
  {
    slug: "SCI",
    label: "SCI (.txt) ",
  },
  {
    slug: "SUPERSOFT",
    label: "Supersoft (.txt) ",
  },
  {
    slug: "WKSISTEMAS",
    label: "WK Sistemas (.txt) ",
  },
  {
    slug: "Outro",
    label: "Outro ",
  },
] as const;

export type SlugBanks =
  | "acesso-solucoes-de-pagamento"
  | "banco-do-brasil"
  | "bradesco"
  | "caixa-economica"
  | "itau"
  | "santander"
  | "agibank"
  | "banco-banese"
  | "banco-btg-pactual"
  | "banco-c6"
  | "banco-da-amazonia"
  | "banco-daycoval"
  | "banco-do-nordeste"
  | "banco-omni"
  | "banco-original"
  | "banco-topazio"
  | "banestes"
  | "banpara"
  | "banrisul"
  | "bnp-paribas-brasil"
  | "brb"
  | "brl-trust-dtvm"
  | "bs2"
  | "citibank"
  | "cooperativa-central-de-credito-noroeste-brasileiro"
  | "cora"
  | "cresol"
  | "gerencianet-pagamentos-do-brasil"
  | "global-scm"
  | "inter"
  | "jp-morgan"
  | "juno"
  | "mercado-pago"
  | "mercantil-do-brasil"
  | "modal"
  | "money-plus"
  | "neon"
  | "next"
  | "nubank"
  | "pagseguro"
  | "pjbank"
  | "rendimento"
  | "safra"
  | "sicoob"
  | "sicredi"
  | "stone"
  | "unicred"
  | "uniprime"
  | "uniprime-norte-do-parana"
  | "via-credi";

export const AVALIABLE_BANKS: {
  name: string;
  slug: SlugBanks;
}[] = [
  {
    name: "Acesso Soluções de Pagamento",
    slug: "acesso-solucoes-de-pagamento",
  },
  {
    name: "Banco do Brasil",
    slug: "banco-do-brasil",
  },
  {
    name: "Bradesco",
    slug: "bradesco",
  },
  {
    name: "Caixa Econômica",
    slug: "caixa-economica",
  },
  {
    name: "Itaú",
    slug: "itau",
  },
  {
    name: "Santander",
    slug: "santander",
  },
  {
    name: "Agibank",
    slug: "agibank",
  },
  {
    name: "Banco Banese",
    slug: "banco-banese",
  },
  {
    name: "Banco BTG Pactual",
    slug: "banco-btg-pactual",
  },
  {
    name: "Banco C6",
    slug: "banco-c6",
  },
  {
    name: "Banco da Amazonia",
    slug: "banco-da-amazonia",
  },
  {
    name: "Banco Daycoval",
    slug: "banco-daycoval",
  },
  {
    name: "Banco do Nordeste",
    slug: "banco-do-nordeste",
  },
  {
    name: "Banco Omni",
    slug: "banco-omni",
  },
  {
    name: "Banco Original",
    slug: "banco-original",
  },
  {
    name: "Banco Topazio",
    slug: "banco-topazio",
  },
  {
    name: "Banestes",
    slug: "banestes",
  },
  {
    name: "Banpará",
    slug: "banpara",
  },
  {
    name: "Banrisul",
    slug: "banrisul",
  },
  {
    name: "BNP Paribas Brasil",
    slug: "bnp-paribas-brasil",
  },
  {
    name: "BRB",
    slug: "brb",
  },
  {
    name: "BRL Trust DTVM",
    slug: "brl-trust-dtvm",
  },
  {
    name: "BS2",
    slug: "bs2",
  },
  {
    name: "Citibank",
    slug: "citibank",
  },
  {
    name: "Cooperativa Central de Credito Noroeste Brasileiro",
    slug: "cooperativa-central-de-credito-noroeste-brasileiro",
  },
  {
    name: "Cora",
    slug: "cora",
  },
  {
    name: "Cresol",
    slug: "cresol",
  },
  {
    name: "Gerencianet Pagamentos do Brasil",
    slug: "gerencianet-pagamentos-do-brasil",
  },
  {
    name: "Global SCM",
    slug: "global-scm",
  },
  {
    name: "Inter",
    slug: "inter",
  },
  {
    name: "JP Morgan",
    slug: "jp-morgan",
  },
  {
    name: "Juno",
    slug: "juno",
  },
  {
    name: "Mercado Pago",
    slug: "mercado-pago",
  },
  {
    name: "Mercantil do Brasil",
    slug: "mercantil-do-brasil",
  },
  {
    name: "Modal",
    slug: "modal",
  },
  {
    name: "Money Plus",
    slug: "money-plus",
  },
  {
    name: "Neon",
    slug: "neon",
  },
  {
    name: "Next",
    slug: "next",
  },
  {
    name: "Nubank",
    slug: "nubank",
  },
  {
    name: "Pagseguro",
    slug: "pagseguro",
  },
  {
    name: "PJBank",
    slug: "pjbank",
  },
  {
    name: "Rendimento",
    slug: "rendimento",
  },
  {
    name: "Safra",
    slug: "safra",
  },
  {
    name: "Sicoob",
    slug: "sicoob",
  },
  {
    name: "Sicredi",
    slug: "sicredi",
  },
  {
    name: "Stone",
    slug: "stone",
  },
  {
    name: "Unicred",
    slug: "unicred",
  },
  {
    name: "Uniprime",
    slug: "uniprime",
  },
  {
    name: "Uniprime Norte do Paraná",
    slug: "uniprime-norte-do-parana",
  },
  {
    name: "Via Credi",
    slug: "via-credi",
  },
];

export const UNIT_OF_MEASUREMENT = [
  {
    unit: "AMPOLA",
    label: "AMPOLA",
  },
  {
    unit: "BALDE",
    label: "BALDE",
  },
  {
    unit: "BANDEJ",
    label: "BANDEJA",
  },
  {
    unit: "BARRA",
    label: "BARRA",
  },
  {
    unit: "BISNAG",
    label: "BISNAGA",
  },
  {
    unit: "BLOCO",
    label: "BLOCO",
  },
  {
    unit: "BOBINA",
    label: "BOBINA",
  },
  {
    unit: "BOMB",
    label: "BOMBONA",
  },
  {
    unit: "CAPS",
    label: "CAPSULA",
  },
  {
    unit: "CART",
    label: "CARTELA",
  },
  {
    unit: "CENTO",
    label: "CENTO",
  },
  {
    unit: "CJ",
    label: "CONJUNTO",
  },
  {
    unit: "CM",
    label: "CENTIMETRO",
  },
  {
    unit: "CM2",
    label: "CENTIMETRO QUADRADO",
  },
  {
    unit: "CX",
    label: "CAIXA",
  },
  {
    unit: "CX2",
    label: "CAIXA COM 2 UNIDADES",
  },
  {
    unit: "CX3",
    label: "CAIXA COM 3 UNIDADES",
  },
  {
    unit: "CX5",
    label: "CAIXA COM 5 UNIDADES",
  },
  {
    unit: "CX10",
    label: "CAIXA COM 10 UNIDADES",
  },
  {
    unit: "CX15",
    label: "CAIXA COM 15 UNIDADES",
  },
  {
    unit: "CX20",
    label: "CAIXA COM 20 UNIDADES",
  },
  {
    unit: "CX25",
    label: "CAIXA COM 25 UNIDADES",
  },
  {
    unit: "CX50",
    label: "CAIXA COM 50 UNIDADES",
  },
  {
    unit: "CX100",
    label: "CAIXA COM 100 UNIDADES",
  },
  {
    unit: "DISP",
    label: "DISPLAY",
  },
  {
    unit: "DUZIA",
    label: "DUZIA",
  },
  {
    unit: "EMBAL",
    label: "EMBALAGEM",
  },
  {
    unit: "FARDO",
    label: "FARDO",
  },
  {
    unit: "FOLHA",
    label: "FOLHA",
  },
  {
    unit: "FRASCO",
    label: "FRASCO",
  },
  {
    unit: "GALAO",
    label: "GALÃO",
  },
  {
    unit: "GF",
    label: "GARRAFA",
  },
  {
    unit: "GRAMAS",
    label: "GRAMAS",
  },
  {
    unit: "JOGO",
    label: "JOGO",
  },
  {
    unit: "KG",
    label: "QUILOGRAMA",
  },
  {
    unit: "KIT",
    label: "KIT",
  },
  {
    unit: "LATA",
    label: "LATA",
  },
  {
    unit: "LITRO",
    label: "LITRO",
  },
  {
    unit: "M",
    label: "METRO",
  },
  {
    unit: "M2",
    label: "METRO QUADRADO",
  },
  {
    unit: "M3",
    label: "METRO CÚBICO",
  },
  {
    unit: "MILHEI",
    label: "MILHEIRO",
  },
  {
    unit: "ML",
    label: "MILILITRO",
  },
  {
    unit: "MWH",
    label: "MEGAWATT HORA",
  },
  {
    unit: "PACOTE",
    label: "PACOTE",
  },
  {
    unit: "PALETE",
    label: "PALETE",
  },
  {
    unit: "PARES",
    label: "PARES",
  },
  {
    unit: "PC",
    label: "PEÇA",
  },
  {
    unit: "POTE",
    label: "POTE",
  },
  {
    unit: "K",
    label: "QUILATE",
  },
  {
    unit: "RESMA",
    label: "RESMA",
  },
  {
    unit: "ROLO",
    label: "ROLO",
  },
  {
    unit: "SACO",
    label: "SACO",
  },
  {
    unit: "SACOLA",
    label: "SACOLA",
  },
  {
    unit: "TAMBOR",
    label: "TAMBOR",
  },
  {
    unit: "TANQUE",
    label: "TANQUE",
  },
  {
    unit: "TON",
    label: "TONELADA",
  },
  {
    unit: "TUBO",
    label: "TUBO",
  },
  {
    unit: "UNID",
    label: "UNIDADE",
  },
  {
    unit: "VASIL",
    label: "VASILHAME",
  },
  {
    unit: "VIDRO",
    label: "VIDRO",
  },
] as const;

export type UNIT = typeof UNIT_OF_MEASUREMENT[number]["unit"];

export const PIS_CST = [
  {
    code: "1",
    label: "Operação Tributável com Alíquota Básica",
  },
  {
    code: "2",
    label: "Operação Tributável com Alíquota Diferenciada",
  },
  {
    code: "3",
    label: "Operação Tributável com Alíquota por Unidade de Medida de Produto",
  },
  {
    code: "4",
    label: "Operação Tributável Monofásica - Revenda a Alíquota Zero",
  },
  {
    code: "5",
    label: "Operação Tributável por Substituição Tributária",
  },
  {
    code: "6",
    label: "Operação Tributável a Alíquota Zero",
  },
  {
    code: "7",
    label: "Operação Isenta da Contribuição",
  },
  {
    code: "8",
    label: "Operação sem Incidência da Contribuição",
  },
  {
    code: "9",
    label: "Operação com Suspensão da Contribuição",
  },
  {
    code: "49",
    label: "Outras Operações de Saída",
  },
  {
    code: "50",
    label:
      "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno",
  },
  {
    code: "51",
    label:
      "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno",
  },
  {
    code: "52",
    label: "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação",
  },
  {
    code: "53",
    label:
      "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
  },
  {
    code: "54",
    label:
      "Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação",
  },
  {
    code: "55",
    label:
      "Operação com Direito a Crédito - Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação",
  },
  {
    code: "56",
    label:
      "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação",
  },
  {
    code: "60",
    label:
      "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno",
  },
  {
    code: "61",
    label:
      "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno",
  },
  {
    code: "62",
    label:
      "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação",
  },
  {
    code: "63",
    label:
      "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
  },
  {
    code: "64",
    label:
      "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação",
  },
  {
    code: "65",
    label:
      "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação",
  },
  {
    code: "66",
    label:
      "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação",
  },
  {
    code: "67",
    label: "Crédito Presumido - Outras Operações",
  },
  {
    code: "70",
    label: "Operação de Aquisição sem Direito a Crédito",
  },
  {
    code: "71",
    label: "Operação de Aquisição com Isenção",
  },
  {
    code: "72",
    label: "Operação de Aquisição com Suspensão",
  },
  {
    code: "73",
    label: "Operação de Aquisição a Alíquota Zero",
  },
  {
    code: "74",
    label: "Operação de Aquisição sem Incidência da Contribuição",
  },
  {
    code: "75",
    label: "Operação de Aquisição por Substituição Tributária",
  },
  {
    code: "98",
    label: "Outras Operações de Entrada",
  },
  {
    code: "99",
    label: "Outras Operações",
  },
] as const;

export type PisCstCode = typeof PIS_CST[number]["code"];

export const IPI_CST = [
  {
    code: "0",
    label: "Entrada com Recuperação de Crédito",
  },
  {
    code: "1",
    label: "Entrada Tributável com Alíquota Zero",
  },
  {
    code: "2",
    label: "Entrada Isenta",
  },
  {
    code: "3",
    label: "Entrada Não-Tributada",
  },
  {
    code: "4",
    label: "Entrada Imune",
  },
  {
    code: "5",
    label: "Entrada com Suspensão",
  },
  {
    code: "49",
    label: "Outras Entradas",
  },
  {
    code: "50",
    label: "Saída Tributada",
  },
  {
    code: "51",
    label: "Saída Tributável com Alíquota Zero",
  },
  {
    code: "52",
    label: "Saída Isenta",
  },
  {
    code: "53",
    label: "Saída Não-Tributada",
  },
  {
    code: "54",
    label: "Saída Imune",
  },
  {
    code: "55",
    label: "Saída com Suspensão",
  },
  {
    code: "99",
    label: "Outras Saídas",
  },
];

export type IpiCst = typeof IPI_CST[number]["code"];

export const CONTRACT_TERM = `TERMO DE CONTRATAÇÃO E UTILIZAÇÃO DE
SERVIÇOS PROFISSIONAIS CONTÁBEIS ATRAVÉS DO
USO DE APLICATIVO DA INTERNET

Pelo presente instrumento, o Usuário (conforme o termo é definido nos Termos de Uso do site hospedado nos domínios hospedado nos domínios www.osayk.com, www.osayk.biz , www.osayk.net , www.osayk.org , www.osayk.us e www.osayk.com.br , doravante denominado “SITE”) adere ao Contrato Padrão de Prestação de Serviços Contábeis Através do Uso de Aplicativo da Internet com a OSAYK CONTABILIDADE LTDA. sociedade inscrita no CNPJ/MF sob o n.° 22.642.547/0001- 23, inscrita no CRC/RJ sob o n.° 006612/O-0, com sede na R. Paulo Assis Ribeiro, 103, Barra da Tijuca, Rio de Janeiro, RJ, CEP 22621-210 (a “OSAYK CONTABILIDADE”) ao USUÁRIO, conforme termos e condições a seguir.

1. O objeto do presente consiste na prestação pela CONTRATADA à CONTRATANTE de serviços profissionais contábeis (os “Serviços”), incluindo as seguintes tarefas:

(a) Escrituração Contábil de acordo com as normas e princípios contábeis vigentes;
(b) Apuração (cálculo dos impostos mensais / trimestrais)
(c) Balancete, Balanço, DRE e Livros contábeis
(d) Orientação e controle da aplicação da legislação fiscal vigente e aplicável ao regime do Simples Nacional, sejam federais, estaduais ou municipais;
(e) DEFIS, DIRF, CAGED e RAIS (para empresas do Simples Nacional)
(f) ECD, ECF, DCTF, DIRF, CAGED e RAIS (para empresas do Lucro Presumido)
(g) Folha de pagamento funcionários e sócios (pro labore), com geração dos contracheques (disponível apenas para algumas modalidades de
assinatura);
(h) GPS (Guia da Previdência Social), GRF (Guia de Recolhimento do FGTS) e DARF referente ao imposto de renda retido na fonte, referentes à folha de pagamento e pro labore.

*Serviços que não estão descritos na lista acima serão cobrados à parte quando solicitados, conforme cláusula 6 abaixo.

2. Os Serviços serão prestados exclusivamente nas dependências da OSAYK CONTABILIDADE, em obediência às seguintes condições:

(a) As informações e documentação indispensáveis para o desempenho dos serviços arrolados no item 1 acima serão fornecidas pelo USUÁRIO através
do SITE, e consistirá, basicamente das seguintes informações e documentos:

(i) informações relativas a cada despesa, investimento ou outro movimento passível de contabilização relativo ao USUÁRIO, ou pessoa jurídica informada pelo mesmo e com relação à qual sejam prestados os Serviços (a “PESSOA JURÍDICA”), a cada mês, utilizando os formulários eletrônicos disponibilizados e ferramentas de transferência eletrônica de documentos ( upload) no SITE;
(ii) emitir notas fiscais eletrônicas preferencialmente através do SITE e, caso o faça através de acesso direto ao site da Secretaria Municipal de Fazenda do município relevante, informar todos os dados de tais notas fiscais no formulário próprio disponibilizado no SITE;
(iii) enviar, mensalmente, até o dia 05 (cinco) do mês seguinte ao mês de referência, cópia, através de upload de arquivo em formato PDF, de
todos os documentos comprobatórios das operações descritas nos itens (i) e (ii) acima, além dos extratos de todas as contas bancárias (incluindo aplicações financeiras) de titularidade do USUÁRIO ou da PESSOA JURÍDICA, conforme o caso, através de sistema de mensagens ou ferramenta de upload a serem disponibilizadas no SITE;

(b) o USUÁRIO é o único e exclusivo responsável pelas informações que enviar à OSAYK CONTABILIDADE na forma dos itens (a) (i) e (ii) acima,
eximindo a OSAYK CONTABILIDADE de qualquer responsabilidade por erro ou omissão no cumprimento das obrigações previstas no item 1 acima que decorram total ou parcialmente de informações incorretas ou parciais prestadas pelo USUÁRIO;
(c) a OSAYK CONTABILIADE compromete-se a cumprir todos os prazos estabelecidos na legislação aplicável quanto ao envio de declarações, apuração de tributos e envio de guias de pagamento dos mesmos ao USUÁRIO, que por sua vez se obriga a fornecer as informações e documentos no prazo previsto no item (a)(iii) acima, eximindo a OSAYK CONTABILIDADE de qualquer responsabilidade quanto a tais prazos mencionados neste item (c) quando o USUÁRIO não tiver observado os prazos a ele impostos sob o presente Contrato;
(d) a OSAYK CONTABILIDADE desempenhará os Serviços com todo zelo, diligência e honestidade, observada a legislação vigente, resguardando os interesses do USUÁRIO, sem prejuízo da dignidade e independência profissionais, sujeitando-se, ainda, às normas do Código de Ética Profissional do Contabilista;
(e) a OSAYK CONTABILIDADE será responsável por todos os seus prepostos que atuarem na prestação dos Serviços ao USUÁRIO, indenizando o USUÁRIO em caso de culpa ou dolo;
(f) a OSAYK CONTABILIDADE assume integral responsabilidade por eventuais multas fiscais decorrentes de imperfeições ou atrasos nos serviços ora contratados, observadas contudo as hipóteses de isenção de responsabilidade previstas neste contrato, e excetuando-se ainda os ocasionados por força maior ou caso fortuito, assim definidos em lei, depois de esgotados os procedimentos de defesa administrativa;
(h) a OSAYK CONTABILIDADE tratará todos os documentos e informações a ela fornecidos pelo USUÁRIO como confidenciais, respondendo pelo seu
mau uso, mesmo se tal ocorrer por ação ou omissão de seus prepostos ou quaisquer pessoas que a eles tenham acesso.

3. Pela prestação dos Serviços, o USUÁRIO pagará à OSAYK CONTABILIDADE o valor mensal indicado na tela apropriada do SITE, que será cobrado do USUÁRIO conforme método de pagamento escolhido pelo mesmo no ato da contratação através do SITE no dia 05 (cinco) do mês de referência dos Serviços.

3.1 O USUÁRIO reconhece que as formas de pagamento aceitas serão aquelas indicadas no SITE no momento da conclusão do CADASTRO COMPLEMENTAR (conforme tal termo é definido pelos Termos de Uso do SITE), e autoriza a cobrança de quaisquer valores sob o presente Contrato através do SITE, mediante o débito em seu cartão de crédito ou boleto bancário.

4. Caso o USUÁRIO deixe de efetuar qualquer pagamento devido sob o presente Contrato, na data de vencimento, será cobrada uma multa no valor de R$ 10,00 no dia primeiro dia útil após o atraso do pagamento, somada a juros de 0,16% ao dia. Adicionalmente, a OSAYK CONTABILIDADE poderá, a seu único e exclusivo critério, suspender imediatamente a prestação dos Serviços até que os pagamentos tenham sido integralmente restabelecidos e/ou rescindir imediata e motivadamente o presente Contrato.

4.1 Constituirão igualmente motivos para a rescisão imediata do presente Contrato pela OSAYK CONTABILIDADE a falência ou recuperação judicial do USUÁRIO ou da PESSOA JURÍDICA, conforme o caso, sendo que em tal hipótese a OSAYK CONTABILIDADE fica expressamente desobrigada pela elaboração de quaisquer peças e documentos adicionais àqueles mencionados no presente Contrato, mesmo, e principalmente, aqueles exigidos do USUÁRIO ou da PESSOA JURÍDICA sob a legislação falimentar.

5. A OSAYK CONTABILIDADE se reserva o direito de alterar periodicamente sua política de preços e, ainda, os termos e condições do presente Contrato, hipótese em que tais alterações deverão ser comunicadas ao USUÁRIO, que terá o direito de rescindir imediatamente o presente Contrato mediante comunicação por escrito enviada para cancelamento@osayk.com.br.

6. A OSAYK CONTABILIDADE poderá divulgar no SITE outros serviços e competências que não estejam incluídos no escopo dos Serviços, que deverão ser objeto de proposta ou contrato próprio, sejam eles passíveis de contratação através do SITE ou por qualquer outro procedimento.

6.1 São considerados serviços extraordinários ao escopo do presente contrato, entre outros, exemplificativamente, e que podem ou não ser oferecidos pela OSAYK CONTABILIDADE:

(a) assessoria na elaboração e arquivamento de alterações contratuais;
(b) constituição de pessoas jurídicas;
(c) obtenção de certidões negativas, incluindo, sem limitação, referentes a tributos e contribuições federais, contribuições previdenciárias, dívida ativa da União e Certificado de Regularidade do FGTS;
(d) autenticação ou registro de livros;
(e) encadernação de livros;
(f) emissão de declarações de faturamento; e
(g) preenchimento de fichas cadastrais, incluindo, sem limitação, aquelas solicitadas pelo IBGE.

7. O presente contrato vigorará a partir da data do aceite de seus termos e condições pelo USUÁRIO no SITE, por prazo indeterminado, podendo, a
qualquer tempo, ser rescindido pelo USUÁRIO ou pela OSAYK CONTABILIDADE, mediante comunicação à outra parte por e-mail enviado para cancelamento@osayk.com , c aso o cancelamento seja solicitado pelo USUÁRIO, ou para o e-mail informado pelo USUÁRIO no seu cadastro no
SITE, caso o cancelamento seja solicitado pela OSAYK CONTABILIDADE.

7.1 Uma vez comunicada por uma parte à outra sua intenção de rescindir o presente Contrato, o mesmo permanecerá em vigor até o último dia do
mês em que o cancelamento seja solicitado, para fins de não haver prejuízo à contabilidade do USUÁRIO ou da PESSOA JURÍDICA, e para que seja inequívoco o período no qual a contabilidade do USUÁRIO ou da PESSOA JURÍDICA será de responsabilidade da OSAYK CONTABILIDADE.

8. Ocorrendo a transferência dos Serviços para outra organização contábil ou contabilista, o USUÁRIO deverá informar à OSAYK CONTABILIDADE, por
escrito através do e-mail cancelamento@oysayk.com , o nome do responsável e número da inscrição no Conselho Regional de Contabilidade, sem o que não será possível à OSAYK CONTABILIDADE cumprir as formalidades necessárias à continuidade dos Serviços, em relação às quais, diante da eventual inércia do USUÁRIO, estará desobrigada de cumprimento.

9. Fica eleito o Foro Regional da Barra da Tijuca, da Comarca da Capital, do Estado do Rio de Janeiro, com expressa renúncia a qualquer outro, por mais privilegiado que seja, para dirimir as questões oriundas da interpretação e execução do presente contrato.

Atualizado em 8 de março de 2017`;
