import { FC, useState } from "react";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { red, green, yellow, grey } from "@mui/material/colors";
import Search from "@mui/icons-material/Search";

import useDebounce from "hooks/useDebounce";
import { useQueryClients, FilterTypeClient } from "./hooks/useQueryClients";
import TableClients from "./TableClients";
import { GetClientsPayload } from "services/Requests/marketplace";
import usePrefetchAllSubAccounts from "../Fees/hooks/usePrefetchAllSubAccounts";

const Clients: FC = () => {
  usePrefetchAllSubAccounts();
  const [filterTypeClient, setFilterTypeClient] = useState<FilterTypeClient>("accountants");
  const [statusClient, setStatusClient] =
    useState<GetClientsPayload["verificationStatus"]>("approved");
  const [searchClient, setSearchClient] = useState("");
  const searchValue = useDebounce(searchClient, 500);

  const isTablet = useMediaQuery("(max-width:880px)");
  const clients = useQueryClients(filterTypeClient, statusClient, searchValue);

  return (
    <>
      <Box
        gridTemplateColumns={isTablet ? "1fr" : "4fr 3fr"}
        gap={2}
        display="grid"
        alignItems="center"
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          pb: 1,
        }}
      >
        <FormControl disabled={clients.isLoading}>
          <FormLabel>Tipo</FormLabel>
          <RadioGroup
            row
            value={filterTypeClient || "accountants"}
            defaultValue={"accountants"}
            onChange={(e) => setFilterTypeClient(e.target.value as FilterTypeClient)}
          >
            <FormControlLabel value="accountants" control={<Radio />} label="Contadores" />
            <FormControlLabel value="premium" control={<Radio />} label="Premium" />
            <FormControlLabel value="freemium" control={<Radio />} label="Freemium" />
            <FormControlLabel value="lead" control={<Radio />} label="Lead" />
            <FormControlLabel value="suspended" control={<Radio />} label="Suspensos" />
          </RadioGroup>
        </FormControl>

        <TextField
          size="small"
          fullWidth
          disabled={clients.isLoading}
          sx={{ order: isTablet ? -1 : "initial" }}
          value={searchClient}
          onChange={(e) => setSearchClient(e.target.value)}
          InputProps={{
            placeholder: "Buscar clientes",
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="small" />
              </InputAdornment>
            ),
          }}
        />

        <FormControl disabled={clients.isLoading}>
          <FormLabel>Status</FormLabel>
          <RadioGroup
            row
            value={statusClient || "approved"}
            defaultValue={"approved"}
            onChange={(e) =>
              setStatusClient(e.target.value as GetClientsPayload["verificationStatus"])
            }
          >
            <FormControlLabel
              value="approved"
              control={
                <Radio
                  sx={{
                    color: green[400],
                    "&.Mui-checked": {
                      color: green[700],
                    },
                  }}
                />
              }
              label="Aprovada"
            />
            <FormControlLabel
              value="on-approval"
              control={
                <Radio
                  sx={{
                    color: yellow[400],
                    "&.Mui-checked": {
                      color: yellow[700],
                    },
                  }}
                />
              }
              label="Em aprovação"
            />

            <FormControlLabel
              value="unverified"
              control={
                <Radio
                  sx={{
                    color: red[300],
                    "&.Mui-checked": {
                      color: red[600],
                    },
                  }}
                />
              }
              label="Não verificado"
            />

            <FormControlLabel
              value="all"
              control={
                <Radio
                  sx={{
                    color: grey[500],
                    "&.Mui-checked": {
                      color: grey[600],
                    },
                  }}
                />
              }
              label="Todos"
            />
          </RadioGroup>
        </FormControl>

        <Stack alignItems="flex-end" justifyItems="flex-end">
          <Typography variant="body1">
            Total: <strong>{clients.data?.length || 0}</strong>
          </Typography>
        </Stack>
      </Box>

      <TableClients clientsQuery={clients} />
    </>
  );
};

export default Clients;
