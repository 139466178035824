import {
  FinancialCategoryByGroupWithChildren,
  GetGlobalCategoriesByGroupResponse,
} from "types/Company";
import groupBy from "./groupBy";

export type NormalizeFinancialCategories = (
  data: GetGlobalCategoriesByGroupResponse,
) => FinancialCategoryByGroupWithChildren[];

const normalizeFinancialCategories: NormalizeFinancialCategories = (data) => {
  const financialCategory = data.financialCategory;

  const grouped = groupBy(financialCategory, (f) => f.parentCategoryId);
  const fatherCategories = grouped.get(null);

  if (fatherCategories === undefined || fatherCategories?.length === 0) return [];

  return fatherCategories
    .map((fatherCategory) => {
      const children = grouped.get(fatherCategory.id) || [];
      return {
        ...fatherCategory,
        children: children.sort((a, b) => a.order - b.order),
      };
    })
    .sort((a, b) => a.order - b.order);
};

export default normalizeFinancialCategories;
