import { forwardRef, Ref } from "react";

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { PIS_CST } from "utils/constants";

interface PisCstSelectInnerProps extends SelectProps<string | null> {
  helperText?: string;
  FormControlProps?: FormControlProps;
}

function PisCstSelectInner(
  { helperText, FormControlProps, ...props }: PisCstSelectInnerProps,
  ref?: Ref<unknown>,
) {
  return (
    <FormControl error={props.error} size={props.size} {...FormControlProps}>
      <InputLabel>{props.label}</InputLabel>
      <Select ref={ref} {...props} value={props.value || ""}>
        <MenuItem value="">Selecione: </MenuItem>

        {PIS_CST.map((pis) => (
          <MenuItem key={pis.code} value={pis.code}>
            ({pis.code.padStart(2, "0")}) {pis.label}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const PisCstSelect = forwardRef(PisCstSelectInner);
export default PisCstSelect;
