import { toast } from "react-hot-toast";

const messageError = (message: string, messageOnError: string, result?: string | null) => {
  if (result === message) {
    toast.error(messageOnError);
  } else {
    toast.error("Ocorreu um error inesperado");
  }
};

export default messageError;
