import { FC, useState } from "react";

import Email from "@mui/icons-material/Email";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, Stack } from "@mui/material";

import ControllerInput from "components/ControllerInput";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import useMutationSendEmailTest from "../hooks/useMutationSendEmailTest";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object().shape({
  email: yup.string().email("Digite um email válido").required("Digite um email"),
});

interface SendTestEmailProps {
  companyToken: string;
  type: number;
}

const SendTestEmail: FC<SendTestEmailProps> = ({ companyToken, type }) => {
  const [confirmationSend, setConfirmationSend] = useState<string | null>(null);
  const handleCloseConfirmation = () => setConfirmationSend(null);

  const { mutate, isLoading } = useMutationSendEmailTest();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    setConfirmationSend(data.email);
  });

  return (
    <>
      <Box component="form" onSubmit={onSubmit}>
        <ControllerInput
          control={control}
          name="email"
          inputProps={{
            label: "Enviar mensagem de teste para:",
            type: "email",
            fullWidth: true,
          }}
        />

        <Stack alignItems="end" justifyContent="end" mt={1}>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            startIcon={<Email />}
          >
            Enviar teste
          </LoadingButton>
        </Stack>
      </Box>

      <Dialog open={!!confirmationSend} onClose={handleCloseConfirmation}>
        <DialogTitleWithCloseButton onClose={handleCloseConfirmation}>
          Mensagem de teste
        </DialogTitleWithCloseButton>

        <DialogContent>
          Deseja enviar uma mensagem de teste para o e-mail varsovei12@gmail.com?
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleCloseConfirmation}>
            Não
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (!confirmationSend) return;
              mutate({
                type,
                companyToken,
                email: confirmationSend,
              });
              handleCloseConfirmation();
            }}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendTestEmail;
