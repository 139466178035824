import { useAuth } from "context/Auth";
import { useSideMenu } from "context/SideMenuContext";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { UserWithCompany } from "types/User";

import getSideMenuItems, { SideMenuItem } from "utils/getSideMenuItems";
import userTypeComplies from "utils/userTypeComplies";

export const getPrimaryPath = (user: UserWithCompany, sideMenuItems: SideMenuItem[]) => {
  let wasRedirected = false;
  let path = "";

  if (
    user.company?.registrationStatus === "Registered" &&
    user.userInfo.userType === "Accountant"
  ) {
    return "/contabilidade-online";
  }

  if (
    userTypeComplies(["Admin", "Accountant"], user.userInfo.userType) &&
    sideMenuItems.find((s) => s.link === "/marketplace")
  ) {
    return "/marketplace";
  }

  sideMenuItems.forEach((item) => {
    if (wasRedirected) return;

    if (item?.link) {
      path = item.link;
      wasRedirected = true;
      return;
    }

    if (item?.children && item.children.length > 0) {
      path = item.children[0].link;
      wasRedirected = true;
      return;
    }
  });

  return path;
};

const useNavigateHomePage = () => {
  const { user } = useAuth();
  const { isCompany } = useSideMenu();
  const navigate = useNavigate();

  const sideMenuItems = useMemo(() => getSideMenuItems(user, !isCompany), [isCompany, user]);

  const navigatePrimaryPath = () => {
    navigate(getPrimaryPath(user, sideMenuItems));
  };

  return navigatePrimaryPath;
};

export default useNavigateHomePage;
