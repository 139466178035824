import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { updateMessage } from "services/Requests/communications";

const useQueryUpdateMessage = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateMessage, {
    onSuccess() {
      toast.success("Modelo de email foi atualizado com sucesso");
      queryClient.invalidateQueries(queryKeys.emailMessage);
    },
  });
  return mutation;
};

export default useQueryUpdateMessage;
