import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getMovement } from "services/Requests/stockControl";

const useQueryMovement = (companyToken: string, id = 0) => {
  const query = useQuery(
    [queryKeys.stockControlMovement, id],
    () => getMovement({ companyToken, id }),
    {
      enabled: !!id,
    },
  );
  return query;
};

export default useQueryMovement;
