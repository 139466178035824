import { ReleaseCreditForm } from "../Form";

import {
  CreateTransactionPayload,
  InvoiceItemCreateTransaction,
  UpdateTransactionPayload,
} from "services/Requests/companies";

import { InvoiceConfig } from "types/Company";
import { formattedInput } from "components/CurrencyInput";

const getPayloadForCreateTransaction = (
  isEdit: boolean,
  data: ReleaseCreditForm,
  companyToken: string,
  invoiceConfig?: InvoiceConfig,
  id?: number,
): CreateTransactionPayload | UpdateTransactionPayload => {
  let selectedService = undefined;
  const isPartner = data.paymentOrigin === "partner";

  const serviceCodeId = data.invoice.serviceCodeId;
  if (invoiceConfig && data.invoice.serviceCodeId) {
    selectedService = invoiceConfig.services.find((s) => s.id === serviceCodeId);
  }

  const payload: CreateTransactionPayload = {
    isPartner,
    repeat: "noParcel",
    dueDate: data.dueDate ? data.dueDate.toDateString() : undefined,
    referenceDate: data.referenceDate ? data.referenceDate.toDateString() : undefined,
    partnerParticipantId: isPartner && data.partner ? data.partner : undefined,
    companyParticipantId:
      !isPartner && data.companyParticipant
        ? typeof data.companyParticipant === "number"
          ? data.companyParticipant
          : data.companyParticipant.id
        : undefined,
    companyBankAccountId: data.companyBankAccount || "",
    description: data.description || "",
    value: formattedInput(data.value),
    retainedValue: data.retainedValue ? formattedInput(data.retainedValue) : undefined,
    globalCategoryId: data.globalCategoryId || null,
    categoryId: data.categoryId || "",
    periodicity: data.periodicity || 0,
    parcel: data.parcel?.toString() || undefined,
    createBilling: Number(data.createBilling),
    sendInvoiceLater: "0",
    issueDate: data.isInvoice ? data.invoice.issueDate?.toDateString() : undefined,
    daysBefore: data.createBilling !== "1" ? undefined : data.daysBefore,
    transactionType: "Credit",
    isInvoice: data.isInvoice,
    paymentDate:
      data.paymentType === "received" && data.paymentDate
        ? data.paymentDate.toDateString()
        : undefined,
    companyToken,
  };

  if (data.isInvoice && selectedService) {
    const invoice: InvoiceItemCreateTransaction = {
      aliquota: selectedService.aliquota || 0,
      cnae: selectedService.cnae,
      cofins: data.invoice.cofins,
      csll: data.invoice.csll,
      describeInvoice: data.invoice.descriptionNfe,
      irop: data.invoice.irop,
      irpj: data.invoice.irpj,
      isManual: selectedService.isManual ? 1 : 0,
      iss: data.invoice.iss,
      lc116: selectedService.lc116,
      notes: data.invoice.notes,
      outros: selectedService.outros,
      pis: data.invoice.pis,
      quantity: 1,
      issWithHeld: Number(data.invoice.issWithHeld) || 0,
      sendInvoiceLater: Number(data.invoice.when) || 0,
      serviceCodeId: null,
      serviceInvoiceCode: selectedService.codigo,
      serviceInvoiceId: selectedService.id,
      typeTaxation: "",
      unitValue: formattedInput(data.value),
    };
    payload.invoiceItems = [invoice];
  }

  return {
    ...payload,
    id: isEdit && id ? id : undefined,
    UpdateType: data.updateType,
  };
};

export default getPayloadForCreateTransaction;
