import { TableCell, TableRow } from "@mui/material";
import { DateTime } from "luxon";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";
import { FC } from "react";
import { TransactionsItem } from "types/Company";
import { ISODate } from "types/SemanticTypes";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { getColorFromValue } from "./CashFlowTable";

interface CashFlowTableItemProps {
  transactionItem: TransactionsItem;
  index: number;
  companyToken: string;
}

const CashFlowTableItem: FC<CashFlowTableItemProps> = ({
  transactionItem,
  index,
  companyToken,
}) => {
  const { data: bankAccounts } = useQueryGetCompanyBankAccounts(companyToken);

  const formatDate = (ISODate: ISODate) => {
    return DateTime.fromISO(ISODate).toFormat("dd/MM/yyyy");
  };

  const getBankName = (bankID: number) => {
    if (!bankID || !bankAccounts) return "-";

    return bankAccounts.find((bank) => bank.id === bankID)?.alias || "-";
  };

  return (
    <TableRow hover>
      <TableCell>
        {transactionItem?.paymentDate ? formatDate(transactionItem?.paymentDate) : "-"}
      </TableCell>
      <TableCell>{transactionItem?.dueDate ? formatDate(transactionItem?.dueDate) : "-"}</TableCell>
      <TableCell>
        {transactionItem?.referenceDate ? formatDate(transactionItem?.referenceDate) : "-"}
      </TableCell>
      <TableCell>{getBankName(transactionItem?.companyBankAccountId)}</TableCell>
      <TableCell>{transactionItem?.description || "-"}</TableCell>
      <TableCell sx={{ fontWeight: 600, color: "success.main" }} align="right">
        {transactionItem.transactionType === "Credit"
          ? formatCurrencyBRL(transactionItem.value)
          : "-"}
      </TableCell>
      <TableCell sx={{ fontWeight: 600, color: "error.main" }} align="right">
        {transactionItem.transactionType === "Debt"
          ? formatCurrencyBRL(transactionItem.value)
          : "-"}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          color:
            index === 0 || index % 2 === 0
              ? getColorFromValue(transactionItem.balanceOfDay)
              : undefined,
        }}
      >
        {index === 0 || index % 2 === 0 ? formatCurrencyBRL(transactionItem.balanceOfDay) : "-"}
      </TableCell>
    </TableRow>
  );
};

export default CashFlowTableItem;
