import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { createCategory } from "services/Requests/companies";

const useMutationCreateCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createCategory, {
    onSuccess() {
      toast.success("Categoria criada com sucesso");
      queryClient.invalidateQueries(queryKeys.financialCategories);
    },
  });
  return mutation;
};

export default useMutationCreateCategory;
