import { FC } from "react";
import LinkOff from "@mui/icons-material/LinkOff";
import { Box, Divider, Stack, Link as LinkMui, IconButton, Grow } from "@mui/material";
import { RenderElementProps, useFocused, useSelected, useSlateStatic } from "slate-react";
import { removeLink } from "../../utils/link";

const Link: FC<RenderElementProps> = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();

  return (
    <Box position="relative" display="inline">
      <a {...attributes} href={element.href || ""} style={{ textDecoration: "underline" }}>
        {children}
      </a>

      <Grow in={selected && focused} suppressContentEditableWarning={true} contentEditable={false}>
        <Stack
          suppressContentEditableWarning={true}
          contentEditable={false}
          direction="row"
          gap={1}
          alignItems="center"
          bgcolor="background.paper"
          zIndex={99}
          boxShadow={1}
          sx={{ position: "absolute", left: 0, border: 1, borderColor: "divider", pr: 1 }}
        >
          <LinkMui sx={{ p: 1, pr: 0 }} href={element.href} rel="noreferrer" target="_blank">
            {element.href}
          </LinkMui>

          <Divider orientation="vertical" flexItem />

          <IconButton onClick={() => removeLink(editor)}>
            <LinkOff />
          </IconButton>
        </Stack>
      </Grow>
    </Box>
  );
};

export { ButtonLink } from "./ButtonLink";
export default Link;
