import { useCallback } from "react";
import { useQuery } from "react-query";

import searchString from "utils/searchString";
import queryKeys from "react-query/constants";

import { getInvoices, GetInvoicesParams } from "services/Requests/stockControl";
import { InvoiceStatus } from "types/Company";
import { InvoiceStockControl } from "types/StockControl";

const useQueryInvoicesStockControl = (
  payload: GetInvoicesParams,
  search = "",
  status?: InvoiceStatus | null,
) => {
  const selectFn = useCallback(
    (data: InvoiceStockControl[]) => {
      const filteredStatus = (item: InvoiceStockControl) => {
        if (status === undefined || status === null) return true;
        return item.invoiceStatus === status;
      };

      const searchInvoice = (item: InvoiceStockControl) => {
        if (search.trim() === "") return true;
        return (
          searchString(item.invoiceNumber || "", search) ||
          searchString(item.participantName || "", search) ||
          searchString(item.description || "", search)
        );
      };

      const items = data.filter((item) => filteredStatus(item) && searchInvoice(item));
      return items;
    },
    [search, status],
  );

  const query = useQuery(
    [queryKeys.invoiceConfigStockControl, payload.companyToken, payload.startDate, payload.endDate],
    () => getInvoices(payload),
    {
      select: selectFn,
    },
  );
  return query;
};

export default useQueryInvoicesStockControl;
