import { useMutation, useQueryClient } from "react-query";
import { addOrUpdateSubAccount  } from "services/Requests/dre";

import toast from "react-hot-toast";
import errorHandlerCompany from "utils/errorHandlerCompany";
import queryKeys from "react-query/constants";

const useMutationUpdateSyntheticAccounts = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(addOrUpdateSubAccount,
    {
      onSuccess() {
          toast.success("Dados atualizados com sucesso!");
          queryClient.invalidateQueries(queryKeys.dreList);
      },
      onError: errorHandlerCompany,
    },
  );
  return mutation;
};

export default useMutationUpdateSyntheticAccounts;
