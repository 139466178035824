import { FC } from "react";
import useQueryTicket from "pages/Home/hooks/useQueryTicket";

import { CircularProgress, Dialog, DialogProps, Stack } from "@mui/material";
import Content from "./Content";

interface DialogTicketProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  ticketId: number;
  onClose: () => void;
}

const DialogTicket: FC<DialogTicketProps> = ({ companyToken, ticketId, onClose, ...props }) => {
  const { data: ticket, isLoading } = useQueryTicket(ticketId, companyToken);

  const handleClose = () => {
    if (!ticket && isLoading) return;
    onClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="md" fullWidth>
      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {!isLoading && ticket && (
        <Content onClose={handleClose} ticket={ticket} companyToken={companyToken} />
      )}
    </Dialog>
  );
};

export default DialogTicket;
