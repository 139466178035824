import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import toast from "react-hot-toast";
import { deleteUser } from "services/Requests/admin";

const useMutationDeleteUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteUser, {
    onSuccess() {
      toast.success("Usuário deletado com sucesso!");
      queryClient.invalidateQueries(queryKeys.userAdminList);
      queryClient.invalidateQueries(queryKeys.usersCompanies);
    },
  });
  return mutation;
};

export default useMutationDeleteUser;
