import { FC } from "react";

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

interface DialogConfirmCloseProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DialogConfirmClose: FC<DialogConfirmCloseProps> = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={onClose}>Cadastro</DialogTitleWithCloseButton>
      <DialogContent>Tem certeza que deseja interromper o cadastro de sua empresa?</DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} autoFocus variant="contained">
          Sim
        </Button>
        <Button onClick={onClose} variant="outlined">
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmClose;
