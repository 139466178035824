import { FC } from "react";
import { Control, Controller, useWatch } from "react-hook-form";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { InvoiceForm, InvoiceType } from ".";
import ConfigRPSNFCe from "./ConfigRPSNFCe";

interface ConfigRPSProps {
  control: Control<InvoiceForm>;
  isClient: boolean;
  invoiceType: InvoiceType;
}

const ConfigRPS: FC<ConfigRPSProps> = ({ control, isClient, invoiceType }) => {
  const sendInvoice = useWatch({
    control,
    name: "sendInvoice",
  });

  return (
    <>
      <Box>
        <Typography
          variant="h6"
          fontWeight={600}
          borderBottom={1}
          borderColor="divider"
          sx={{ mb: 2 }}
        >
          Configuração da RPS {invoiceType === "NFC-e" && " para NF-e"}
        </Typography>

        <Box display="grid" gap={2}>
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
            <Controller
              control={control}
              name={invoiceType === "NFS-e" ? "serie" : "nfeSerie"}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Série"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              )}
            />

            {invoiceType === "NFS-e" ? (
              <>
                <Controller
                  control={control}
                  name="nextRPS"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Número do RPS atual"
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                      type="number"
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="nextLot"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Número do lote atual"
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                      type="number"
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  )}
                />
              </>
            ) : (
              <Controller
                control={control}
                name="sequentialNFe"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Número da próxima NF-e"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    type="number"
                    sx={{ gridColumn: "2/-1" }}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                )}
              />
            )}
          </Box>

          <Controller
            control={control}
            name="description"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={invoiceType === "NFS-e" ? "Discriminação" : "Descrição"}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                inputProps={{
                  maxLength: 2000,
                }}
                multiline
              />
            )}
          />

          {invoiceType === "NFS-e" && (
            <>
              <Controller
                control={control}
                name="notes"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Informações complementares"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    inputProps={{
                      maxLength: 2000,
                    }}
                    multiline
                  />
                )}
              />

              {!isClient && (
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                  <Controller
                    control={control}
                    name="sendInvoice"
                    render={({ field, fieldState }) => (
                      <FormControl error={!!fieldState.error?.message}>
                        <InputLabel>Momento para emissão da nota</InputLabel>
                        <Select {...field} label="Momento para emissão da nota">
                          <MenuItem value="">Selecione</MenuItem>
                          <MenuItem value="2">Junto com o faturamento</MenuItem>
                          <MenuItem value="3">Após o pagamento</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />

                  {(sendInvoice === "1" || sendInvoice === "3") && (
                    <Controller
                      control={control}
                      name="sendInvoiceInterval"
                      render={({ field, fieldState }) => (
                        <FormControl error={!!fieldState.error?.message}>
                          <InputLabel>Quantidade de dias após</InputLabel>
                          <Select {...field} label="Quantidade de dias após">
                            <MenuItem value="0">Selecione</MenuItem>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  )}
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      {invoiceType === "NFC-e" && <ConfigRPSNFCe control={control} />}
    </>
  );
};

export default ConfigRPS;
