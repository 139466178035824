import { QueryClient } from "react-query";
import { toast } from "react-hot-toast";

const onErrorHandler = () => {
  toast.error("Ocorreu um error inesperado");
};

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: onErrorHandler,
    },
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 15, // 15 minutes
    },
  },
});

export default queryClient;
