import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { exportTo } from "services/Requests/template";

const useMutationExportTo = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(exportTo, {
    onSuccess(_, { companyToken }) {
      toast.success("Exportado com sucesso");
      queryClient.invalidateQueries([queryKeys.listFilesIntegration, companyToken], {
        exact: false,
      });
    },
  });
  return mutation;
};

export default useMutationExportTo;
