import { useAuth } from "context/Auth";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";

interface WithLinkProps {
  children: React.ReactNode;
  link?: string;
}

const WithLink: FC<WithLinkProps> = ({ children, link }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const isNewAccountant = useMemo(
    () =>
      user.company?.registrationStatus === "Registered" && user.userInfo.userType === "Accountant",
    [user],
  );

  if (!link || isNewAccountant) {
    return <>{children}</>;
  }

  return (
    <a
      href={link}
      onClick={(e) => {
        e.preventDefault();
        navigate(link);
      }}
    >
      {children}
    </a>
  );
};

export default WithLink;
