import { FC, useMemo, useState } from "react";

import { Box, Button, Card, CardActions, CardContent, Tooltip, Typography } from "@mui/material";

import { CustomService, Service, ServicePlan } from "types/ServiceAndPlan";
import DOMPurify from "isomorphic-dompurify";
import DialogBuyService from "./components/DialogBuyService";
import ServiceController from "./ServiceController";

const isService = (obj: Service | ServicePlan | CustomService): obj is Service => {
  return "premiumPrices" in obj;
};
const isCustomService = (obj: Service | ServicePlan | CustomService): obj is CustomService => {
  return "daysAfter" in obj;
};

interface ServiceCardProps {
  service: Service | ServicePlan | CustomService;
  companyToken: string;
}

const ServiceCard: FC<ServiceCardProps> = ({ service, companyToken }) => {
  const serviceTitle = isService(service) ? service.groupName ?? service.name : service.name;
  const [isShowBuyService, setIsShowBuyService] = useState(false);

  const startTextSanitizer = useMemo<string>(
    () =>
      DOMPurify.sanitize(
        isService(service) ? service.serviceTexts.startText : service.description ?? "",
      ).toString(),
    [service],
  );

  return (
    <>
      <Card sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: 1 }}>
          <Box pb={2} p={3} borderBottom={1} borderColor="divider">
            <Tooltip
              title={serviceTitle}
              placement="bottom"
              sx={{
                top: "100%",
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  lineHeight: 1,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  fontSize: "1.25rem",
                }}
                align="center"
              >
                {serviceTitle}
              </Typography>
            </Tooltip>
          </Box>

          <Box sx={{ p: 3 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              component="div"
              dangerouslySetInnerHTML={{
                __html: startTextSanitizer,
              }}
              sx={{
                height: "100%",
                maxHeight: 60,
                overflow: "hidden",
                lineClamp: 3,
              }}
            />
          </Box>
        </CardContent>

        <CardActions sx={{ p: 4 }}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setIsShowBuyService(true);
            }}
          >
            {isCustomService(service) ? "Solicitar" : "Iniciar"}
          </Button>
        </CardActions>
      </Card>

      {isShowBuyService && isCustomService(service) && (
        <DialogBuyService
          open={isShowBuyService}
          onClose={() => setIsShowBuyService(false)}
          service={service}
          companyToken={companyToken}
        />
      )}

      {isShowBuyService && !isCustomService(service) && (
        <ServiceController
          open={isShowBuyService}
          onClose={() => setIsShowBuyService(false)}
          service={service}
          companyToken={companyToken}
        />
      )}
    </>
  );
};

export default ServiceCard;
