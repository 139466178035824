import { forwardRef, Ref } from "react";
import useQueryTemplatesToFrom from "pages/Home/hooks/useQueryTemplatesToFrom";

import {
  CircularProgress,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface TemplatesToFromSelectInnerProps extends SelectProps<number | string | null> {
  helperText?: string;
  FormControlProps?: FormControlProps;
}

function TemplatesToFromSelectInner(
  { helperText, FormControlProps, ...props }: TemplatesToFromSelectInnerProps,
  ref?: Ref<unknown>,
) {
  const { isLoading, data: templates, isError } = useQueryTemplatesToFrom();

  return (
    <FormControl error={props.error} size={props.size} {...FormControlProps}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        ref={ref}
        {...props}
        value={props.value ?? ""}
        endAdornment={
          isLoading ? (
            <CircularProgress sx={{ position: "relative", left: "-20px" }} size={20} />
          ) : undefined
        }
      >
        {!!templates && !isLoading && (
          <MenuItem value="" disabled>
            Selecione:{" "}
          </MenuItem>
        )}

        {isError && (
          <MenuItem disabled>
            Ocorreu um erro ao buscar os templates, tente outra vez mais tarde
          </MenuItem>
        )}

        {!!templates &&
          templates.map(({ id, name }) => (
            <MenuItem value={id} key={id}>
              {name}
            </MenuItem>
          ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const TemplatesToFromSelect = forwardRef(TemplatesToFromSelectInner);
export default TemplatesToFromSelect;
