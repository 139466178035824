import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { updateCompanyBankAccount } from "services/Requests/companies";

const useMutationUpdateCompanyBankAccount = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCompanyBankAccount, {
    onSuccess(_, { companyToken }) {
      toast.success("Conta editada com sucesso");
      queryClient.invalidateQueries([queryKeys.companyBankAccounts, companyToken]);
    },
  });
  return mutation;
};

export default useMutationUpdateCompanyBankAccount;
