import { useCallback } from "react";
import { useQuery } from "react-query";

import queryKeys from "react-query/constants";
import searchString from "../../../utils/searchString";

import { getInvoices, GetInvoicesPayload } from "services/Requests/companies";
import { InvoiceStatus, Transactions, TransactionsItem } from "types/Company";

const useQueryInvoices = (
  payload: GetInvoicesPayload,
  search = "",
  status?: InvoiceStatus | null,
) => {
  const selectFn = useCallback(
    (data: Transactions) => {
      const filteredStatus = (item: TransactionsItem) => {
        if (status === undefined || status === null) return true;
        return item.invoiceStatus === status;
      };

      const searchInvoice = (item: TransactionsItem) => {
        if (search.trim() === "") return true;
        return (
          searchString(item.invoiceNumber || "", search) ||
          searchString(item.participantName || "", search) ||
          searchString(item.description || "", search)
        );
      };

      const items = data.items.filter((item) => filteredStatus(item) && searchInvoice(item));
      return {
        ...data,
        items,
      };
    },
    [search, status],
  );

  const query = useQuery(
    [queryKeys.invoices, payload.companyToken, payload.startDate, payload.endDate],
    () => getInvoices(payload),
    {
      select: selectFn,
    },
  );
  return query;
};

export default useQueryInvoices;
