import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getFinancial } from "services/Requests/billing";
import getSubdomain from "utils/getSubdomain";

const useQueryFinancial = (companyToken: string, year: string, month: string) => {
  const query = useQuery([queryKeys.financial, year, month], () =>
    getFinancial({
      companyToken,
      maxResults: null,
      month,
      year,
      subdomain: getSubdomain(),
    }),
  );
  return query;
};

export default useQueryFinancial;
