import { useMutation, useQueryClient } from "react-query";
import { savePlan } from "services/Requests/accountantPlan";
import queryKeys from "react-query/constants";
import toast from "react-hot-toast";

const useMutationSavePlan = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(savePlan, {
    onSuccess(_, { planId }) {
      const text = planId === 0 ? "Plano criado com sucesso" : "Plano atualizado com sucesso";
      toast.success(text);
      queryClient.invalidateQueries(queryKeys.serviceAndPlans);
    },
  });
  return mutation;
};

export default useMutationSavePlan;
