import { forwardRef, Ref } from "react";
import useQueryCounties from "pages/Home/hooks/useQueryCounties";

import {
  CircularProgress,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface CitySelectInnerProps extends SelectProps<string | null> {
  helperText?: string;
  FormControlProps?: FormControlProps;
  stateId?: string;
  byCityName?: boolean;
}

function CitySelectInner(
  { helperText, FormControlProps, stateId, byCityName = false, ...props }: CitySelectInnerProps,
  ref?: Ref<unknown>,
) {
  const { isLoading, data: counties, isError } = useQueryCounties(stateId);

  return (
    <FormControl error={props.error} size={props.size} {...FormControlProps}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        ref={ref}
        {...props}
        value={props.value || ""}
        endAdornment={
          isLoading ? (
            <CircularProgress sx={{ position: "relative", left: "-20px" }} size={20} />
          ) : undefined
        }
      >
        {!!counties && !isLoading && (
          <MenuItem value="" disabled>
            Selecione:{" "}
          </MenuItem>
        )}

        {!stateId && <MenuItem disabled>Selecione um estado</MenuItem>}

        {isError && (
          <MenuItem disabled>
            Ocorreu um erro ao buscar as cidades, tente outra vez mais tarde
          </MenuItem>
        )}

        {!!counties &&
          counties.map(({ id, name }) => (
            <MenuItem value={byCityName ? name : id} key={id}>
              {name}
            </MenuItem>
          ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const CitySelect = forwardRef(CitySelectInner);
export default CitySelect;
