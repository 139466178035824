import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getConfiguration } from "services/Requests/communications";
import getSubdomain from "utils/getSubdomain";

const useQueryGetConfiguration = (companyToken: string) => {
  const subdomain = getSubdomain();

  const query = useQuery(queryKeys.getConfiguration, () =>
    getConfiguration({ companyToken, subdomain }),
  );
  return query;
};

export default useQueryGetConfiguration;
