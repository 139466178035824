import { FC, useEffect, useState } from "react";
import useMutationUpdateCompanyData from "../hooks/useMutationUpdateCompanyData";
import useQueryGetCNAEs from "pages/Home/hooks/useQueryGetCNAEs";

import MaskedInput from "react-text-mask";
import ControllerInput from "components/ControllerInput";

import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

import { CompanyInformation } from "types/Company";
import { CNAEs, Counties } from "types/CommonData";
import { cnpjMask } from "utils/masks";
import AddressForm from "../components/AddressForm";

interface FieldsUpdateMyCompany {
  cnpj: string;
  companyName: string;
  mainActivity: CNAEs | null;
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  streetNumber: string;
  complement: string;
  zipCode: string;
}

interface MyCompanyProps {
  companyInformation: CompanyInformation;
  onChangePage?: () => void;
  onBack?: () => void;
  isPF?: boolean;
  disabledForm?: boolean;
}

const MyCompany: FC<MyCompanyProps> = ({
  onBack,
  companyInformation,
  onChangePage,
  isPF = false,
  disabledForm = false,
}) => {
  const [county, setCounty] = useState<Counties | undefined>(undefined);
  const { mutateAsync, isLoading } = useMutationUpdateCompanyData();
  const { data: listCNAEs, isLoading: listCNAEsIsLoading } = useQueryGetCNAEs();

  const { control, setValue, watch, handleSubmit } = useForm<FieldsUpdateMyCompany>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cnpj: companyInformation.cnpj,
      companyName: companyInformation.companyName,
      mainActivity: null,
      street: companyInformation.address.street,
      neighborhood: companyInformation.address.neighborhood,
      zipCode: companyInformation.address.zipCode,
      city: companyInformation.countyId,
      complement: companyInformation.address.complement,
      state: companyInformation.address.state,
      streetNumber: companyInformation.address.streetNumber,
    },
  });

  useEffect(() => {
    if (!listCNAEs) return;

    const cnaes = listCNAEs.find((c) => c.code === companyInformation.activity);
    if (!cnaes) return;

    setValue("mainActivity", cnaes);
  }, [listCNAEs]);

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (name !== "state" || type !== "change") return;
      setValue("city", "");
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = handleSubmit(async (data) => {
    if (disabledForm) return;
    if (!data.mainActivity || !county) return;

    const zipCode = data.zipCode.replace(/\D/g, "");
    const countyId = data.city;
    const city = county.name;

    if (!city) return;

    try {
      await mutateAsync({
        ...companyInformation,
        address: {
          ...companyInformation.address,
          city,
          complement: data.complement,
          neighborhood: data.neighborhood,
          state: data.state,
          street: data.street,
          streetNumber: data.streetNumber,
          countyId,
          zipCode,
        },
        activity: data.mainActivity.code,
        city,
        cnpj: data.cnpj,
        companyName: data.companyName,
        countyId,
        neighborhood: data.neighborhood,
        state: data.state,
        street: data.street,
        streetNumber: data.streetNumber,
        zipCode,
      });
      onChangePage?.();
    } catch (e) {
      console.log(e);
    }
  });

  return (
    <form>
      <Typography variant="subtitle1" fontWeight={600} gutterBottom>
        {isPF ? "Dados Pessoais" : `Dados da ${companyInformation.companyName || "sua empresa"}`}
      </Typography>
      <Divider sx={{ mb: 3 }} />

      <Stack gap={2} mb={3}>
        <Stack direction="row" flexWrap="wrap" gap={2}>
          <Controller
            name="cnpj"
            control={control}
            render={({ fieldState, field }) => (
              <MaskedInput
                mask={cnpjMask}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="CNPJ"
                    variant="outlined"
                    inputRef={ref}
                    {...props}
                    sx={{ flex: 1 }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    disabled={disabledForm}
                  />
                )}
              ></MaskedInput>
            )}
          />

          <ControllerInput
            control={control}
            name="companyName"
            inputProps={{
              label: isPF ? "Nome Completo" : "Razão Social",
              sx: { flex: 1 },
              disabled: disabledForm,
            }}
          />
        </Stack>

        {!isPF && (
          <Controller
            control={control}
            name="mainActivity"
            render={({ field, fieldState }) => (
              <Autocomplete
                clearOnEscape
                options={listCNAEs || []}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                getOptionLabel={(option) => `${option.code} - ${option.name}`}
                loading={listCNAEsIsLoading}
                noOptionsText="Sem opção"
                value={field.value}
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={(_, value) => field.onChange(value)}
                disabled={disabledForm}
                renderInput={(params) => (
                  <TextField
                    name={field.name}
                    {...params}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    label="Atividade principal (opcional)"
                    disabled={disabledForm}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {listCNAEsIsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
          />
        )}
      </Stack>

      <AddressForm
        control={control}
        onChangeCounty={(county) => setCounty(county)}
        disabled={disabledForm}
      />

      {!disabledForm && (
        <Stack direction="row" justifyContent={onBack ? "space-between" : "end"} sx={{ mt: 4 }}>
          {!!onBack && (
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={onBack}
              startIcon={<NavigateBeforeIcon />}
            >
              Anterior
            </Button>
          )}

          <LoadingButton
            loading={isLoading}
            variant="contained"
            endIcon={<NavigateNextIcon />}
            onClick={() => onSubmit()}
          >
            Salvar cadastro e seguir
          </LoadingButton>
        </Stack>
      )}
    </form>
  );
};

export default MyCompany;
