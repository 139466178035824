import { FC } from "react";
import useMutationUpdatePartners from "../../Profile/components/CompanyData/hooks/useMutationUpdatePartners";

import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { CompanyPartner } from "types/Company";

interface DialogDeletePartnerProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  onClose: () => void;
  partner: CompanyPartner;
}

const DialogDeletePartner: FC<DialogDeletePartnerProps> = ({
  companyToken,
  onClose,
  partner,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationUpdatePartners();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDeletePartner = async () => {
    await mutateAsync({
      companyToken,
      partner: {
        ...partner,
        updateAction: "Delete",
      },
    });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Excluir {partner.name}
      </DialogTitleWithCloseButton>

      <DialogContent>Tem certeza que deseja excluir teste?</DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose} disabled={isLoading}>
          Não
        </Button>
        <LoadingButton onClick={handleDeletePartner} loading={isLoading} variant="contained">
          Sim
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeletePartner;
