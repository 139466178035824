import endpoints from "../endpoints";
import api from "../api";
import { DRE, DREAnalytical } from "types/DRE";

export const getDre = async (
  companyToken?: string
) => {
  const { data } = await api.post<DRE[]>(endpoints.dre.getDRE, {
    companyToken: companyToken
  });
  return data;
};

export const getSyntheticAccounts = async (
  companyToken: string,
) => {
  const { data } = await api.post<DRE[]>(endpoints.dre.getSyntheticAccounts, {
    companyToken: companyToken
  });
  return data;
};

export interface AddSubAccountPayload {
  title: string;
  parentId: number;
  companyToken: string;
  id?: number;
}

export const addOrUpdateSubAccount = async (
  payload: AddSubAccountPayload,
) => {
  const { data } = await api.post<DRE[]>(endpoints.dre.addOrUpdateSubAccount,
    payload);
  return data;
};

export interface RemoveSubAccountPayload {
  parentId?: number;
  companyToken: string;
  id?: number;
}

export const removeSubAccount = async (
  payload: RemoveSubAccountPayload,
) => {
  const { data } = await api.post<DRE[]>(endpoints.dre.removeSubAccount,
    payload);
  return data;
};

export const getAnalitycalAccounts = async (
  companyToken?: string,
) => {
  const { data } = await api.post<DREAnalytical[]>(endpoints.dre.getAnalitycalAccounts, {
    companyToken: companyToken
  });
  return data;
};