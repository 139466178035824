import * as yup from "yup";

const validationSchema = yup.object().shape({
  otherSubject: yup.string().required("O assunto é obrigatório"),
  group: yup
    .number()
    .typeError("O grupo da solicitação é obrigatório")
    .required("O grupo da solicitação é obrigatório"),
  body: yup.string().required("A mensagem é obrigatório"),
});

export default validationSchema;
