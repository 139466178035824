import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";

import {
  updatePenaltiesAndDiscounts,
  UpdatePenaltiesAndDiscountsPayload,
} from "services/Requests/billing";

import toast from "react-hot-toast";
import getSubdomain from "utils/getSubdomain";

const useMutationUpdatePenaltiesAndDiscounts = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<UpdatePenaltiesAndDiscountsPayload, "subdomain">) =>
      updatePenaltiesAndDiscounts({ ...payload, subdomain }),
    {
      onSuccess() {
        toast.success("Multas e descontos atualizados com sucesso");
        queryClient.invalidateQueries(queryKeys.penaltiesAndDiscounts);
        queryClient.invalidateQueries(queryKeys.infoAccount, { exact: false });
      },
    },
  );
  return mutation;
};

export default useMutationUpdatePenaltiesAndDiscounts;
