import { FC, useMemo, useState } from "react";
import { useAuth } from "context/Auth";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import Add from "@mui/icons-material/Add";

import AccountantTable from "./AccountantTable";
import CardService from "./CardService";
import DialogCreatePlan from "./DialogCreatePlan";

import { Service, ServiceAndPlan } from "types/ServiceAndPlan";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import toast from "react-hot-toast";

type FilterPlans = "client" | "accountant" | "global";

interface AdminStandardizedPlansProps {
  serviceAndPlan: ServiceAndPlan;
  isIuguActivate: boolean;
  onEditService: (service: Service) => void;
}

const AdminStandardizedPlans: FC<AdminStandardizedPlansProps> = ({
  serviceAndPlan,
  isIuguActivate,
  onEditService,
}) => {
  const {
    user: { company },
  } = useAuth();

  const [filterPlans, setFilterPlans] = useState<FilterPlans>("client");
  const [showDialogCreatePlan, setShowDialogCreatePlan] = useState(false);

  const handleShowDialogCreatePlan = () => {
    if (!company) {
      toast.error(MESSAGE_NO_COMPANY);
      return;
    }

    setShowDialogCreatePlan(true);
  };

  const filteredServices = useMemo(() => {
    const services = serviceAndPlan.defaultServices;
    if (serviceAndPlan.premiumService) {
      services.push(serviceAndPlan.premiumService);
    }

    if (filterPlans === "client") {
      return services.filter(
        (service) =>
          service.userType !== "Accountant" && !service.global && service.serviceType !== "Others",
      );
    }

    if (filterPlans === "global") {
      return services.filter((service) => service.global && service.serviceType !== "Others");
    }
  }, [filterPlans, serviceAndPlan]);

  const filteredPlans = useMemo(() => {
    if (filterPlans !== "accountant") return;

    return serviceAndPlan.plans.filter((plan) => plan.userType === "Accountant" && !plan.global);
  }, [filterPlans, serviceAndPlan]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <FormControl>
          <RadioGroup
            row
            value={filterPlans}
            onChange={(e) => setFilterPlans(e.target.value as FilterPlans)}
          >
            <FormControlLabel value="client" control={<Radio />} label="Clientes" />
            <FormControlLabel value="accountant" control={<Radio />} label="Contadores" />
            <FormControlLabel value="global" control={<Radio />} label="Global" />
          </RadioGroup>
        </FormControl>

        {filterPlans === "accountant" && (
          <Button
            variant="contained"
            size="small"
            startIcon={<Add fontSize="small" />}
            onClick={handleShowDialogCreatePlan}
          >
            Novo plano
          </Button>
        )}
      </Stack>

      <Box mt={2}>
        {filteredServices && (
          <Box gap={2} display="grid" gridTemplateColumns="repeat( auto-fit, minmax(250px, 1fr) )">
            {filteredServices.map((service) => (
              <CardService
                key={service.id}
                service={service}
                isIuguActivate={isIuguActivate}
                onClick={() => onEditService(service)}
              />
            ))}
          </Box>
        )}

        {filteredPlans && (
          <AccountantTable isIuguActivate={isIuguActivate} servicePlan={filteredPlans} />
        )}
      </Box>

      {company && (
        <DialogCreatePlan
          keepMounted={false}
          companyToken={company.token}
          open={showDialogCreatePlan}
          onClose={() => setShowDialogCreatePlan(false)}
        />
      )}
    </>
  );
};

export default AdminStandardizedPlans;
