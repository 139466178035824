import { TextField, Typography } from "@mui/material";
import { FC } from "react";
import { ProductForm } from ".";
import { Control, Controller } from "react-hook-form";

interface NFCFieldsProps {
  control: Control<ProductForm>;
}

const NFCFields: FC<NFCFieldsProps> = ({ control }) => {
  return (
    <>
      <Typography
        variant="h6"
        fontWeight="400"
        sx={{ gridColumn: "1/-1", borderBottom: 1, borderBottomColor: "divider" }}
      >
        Para a NFC-e
      </Typography>

      <Controller
        control={control}
        name="municipalTax"
        render={({ field, fieldState }) => (
          <TextField
            label="Tributo Municipal (%)"
            {...field}
            type="number"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="stateTax"
        render={({ field, fieldState }) => (
          <TextField
            label="Tributo Estadual (%)"
            {...field}
            type="number"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="federalTax"
        render={({ field, fieldState }) => (
          <TextField
            label="Tributo Federal (%)"
            {...field}
            type="number"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="taxSource"
        render={({ field }) => (
          <TextField
            label="Fonte"
            inputProps={{
              maxLength: 10,
            }}
            {...field}
          />
        )}
      />
    </>
  );
};

export default NFCFields;
