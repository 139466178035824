import { FC, useState } from "react";
import { useFilterPeriod } from "../contexts/FilterPeriod";
import useQueryGetTransactions from "../hooks/useQueryGetTransactions";

import { Box, CircularProgress, Collapse, Divider, LinearProgress, Stack } from "@mui/material";

import BillsToReceiveTable from "./BillsToReceiveTable";
import FilterPeriod from "components/FilterPeriod";
import CategoryFilter from "../components/CategoryFilter";
import CompanyParticipantFilter from "../components/CompanyParticipantFilter";
import SearchInput from "components/SearchInput";
import DateTypesFilter from "../components/DateTypesFilter";

import { DateTypeFilter, FinancialCategory } from "types/Company";

interface BillsToReceiveProps {
  companyToken: string;
}

const BillsToReceiveFilters: FC<BillsToReceiveProps> = ({ companyToken }) => {
  const [categoryFilter, setCategoryFilter] = useState<FinancialCategory | null>(null);
  const [companyParticipant, setCompanyParticipant] = useState<null | number>(null);
  const [dateTypeFilter, setDateTypeFilter] = useState<null | DateTypeFilter>("due");
  const [search, setSearch] = useState("");

  const {
    date: { start, end },
    setDate,
  } = useFilterPeriod();
  const {
    data: transactions,
    isLoading,
    isFetching,
  } = useQueryGetTransactions(
    companyToken,
    start,
    end,
    "AR",
    null,
    search,
    categoryFilter,
    companyParticipant,
    dateTypeFilter,
  );

  const totalBills = transactions?.items.reduce((acc, item) => acc + item.value, 0);

  return (
    <Box>
      <Collapse in={!isLoading && isFetching}>
        <LinearProgress />
      </Collapse>

      <Stack gap={2} direction="row" mt={0.5} flexWrap="wrap">
        <FilterPeriod
          endDate={end}
          startDate={start}
          onChangeDates={(dates) => setDate(dates)}
          label="Período"
          size="medium"
        />

        <DateTypesFilter value={dateTypeFilter} setValue={setDateTypeFilter} type={2} />

        <CategoryFilter
          companyToken={companyToken}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
        />

        <CompanyParticipantFilter
          companyToken={companyToken}
          value={companyParticipant}
          setValue={setCompanyParticipant}
        />

        <SearchInput label="Buscar contas a receber" onChange={(value) => setSearch(value)} />
      </Stack>

      <Divider sx={{ mt: 2, mb: 4 }} />
      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
      {transactions && <BillsToReceiveTable transactions={transactions} totalBills={totalBills} />}
    </Box>
  );
};

export default BillsToReceiveFilters;
