import {
  forwardRef,
  HTMLAttributes,
  useEffect,
  useRef,
  Ref,
  ReactNode,
  createContext,
  useContext,
  useCallback,
} from "react";
import useQueryMunicipalService from "pages/Home/hooks/useQueryMunicipalService";

import { VariableSizeList, ListChildComponentProps } from "react-window";

import {
  Autocomplete,
  autocompleteClasses,
  AutocompleteProps,
  CircularProgress,
  Popper,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import ListSubheader from "@mui/material/ListSubheader";
import { MunicipalService } from "types/Invoice";

const LISTBOX_PADDING = 8; // px

function useResetCache(data: unknown) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current !== null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: ReactNode[] = [];
    (children as ReactNode[]).forEach((item: ReactNode) => {
      itemData.push(item);
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: ReactNode) => {
      if (child && Object.hasOwn(child as object, "group")) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (Object.hasOwn(dataSet, "group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography
      variant="body2"
      component="li"
      {...dataSet[0]}
      noWrap
      style={{ ...inlineStyle, textOverflow: "ellipsis", overflow: "hidden", display: "block" }}
    >
      {dataSet[1]}
    </Typography>
  );
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

type MyAutocompleteProps = Partial<AutocompleteProps<MunicipalService, false, false, false, "div">>;
type Value = MunicipalService | string | null | undefined;

export interface MunicipalServiceAutocompleteProps extends Omit<MyAutocompleteProps, "value"> {
  textFieldProps?: TextFieldProps;
  value: Value;
  companyToken: string;
}

function MunicipalServiceAutocompleteInner(
  { textFieldProps, companyToken, ...props }: MunicipalServiceAutocompleteProps,
  ref?: Ref<unknown>,
) {
  const { data: services, isLoading } = useQueryMunicipalService(companyToken);

  const getServiceValue = useCallback(
    (value: Value) => {
      if (value === null || value === undefined) return undefined;
      if (typeof value !== "string") {
        return value;
      }

      if (!services) return undefined;
      return services.find((service) => service.descricao === value);
    },
    [services],
  );

  return (
    <Autocomplete
      {...props}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      value={getServiceValue(props.value)}
      options={services ?? []}
      autoHighlight
      getOptionLabel={(option) => option?.descricao ?? ""}
      isOptionEqualToValue={(option, value) => option?.descricao === value?.descricao}
      noOptionsText="Sem opção"
      disabled={props.disabled || isLoading}
      ref={ref}
      renderOption={(props, option) => [props, option.descricao] as ReactNode}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
            ...textFieldProps?.inputProps,
          }}
          InputProps={{
            ...textFieldProps?.InputProps,
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

const MunicipalServiceAutocomplete = forwardRef(MunicipalServiceAutocompleteInner);
export default MunicipalServiceAutocomplete;
