import { FC } from "react";

import CurrencyInput, { formattedInput } from "components/CurrencyInput";
import ControllerInput from "components/ControllerInput";
import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Spreads } from "types/Billing";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

import useMutationUpdateSpreads from "pages/Home/pages/Marketplace/hooks/useMutationUpdateSpreads";
import { useClientInformation } from "../../../context/ClientInformation";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

interface FeesFormProps extends Omit<Spreads, "creditCardSpread" | "invoiceSpread"> {
  bankSlipCents: number;
  creditCardPercent: number;
}

const FeesForm: FC<FeesFormProps> = ({
  bankSlipCents,
  ccAdvanceSpread,
  creditCardPercent,
  withdrawSpread,
}) => {
  const { token, payerCompanyId } = useClientInformation();
  const { mutate, isLoading } = useMutationUpdateSpreads(token);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onSubmit",
    mode: "onSubmit",
    defaultValues: {
      invoiceBilledCost: formatCurrencyBRL(bankSlipCents / 100),
      withdrawalCost: formatCurrencyBRL(withdrawSpread / 100),
      cardPaymentPercentage: creditCardPercent,
      anticipationPercentage: ccAdvanceSpread,
    },
  });

  const onSubmit = handleSubmit((data) => {
    mutate({
      ccAdvanceSpread: data.anticipationPercentage,
      companyId: payerCompanyId,
      creditCardSpread: data.cardPaymentPercentage.toString(),
      invoiceSpread: (formattedInput(data.invoiceBilledCost) * 100).toString(),
      withdrawSpread: (formattedInput(data.withdrawalCost) * 100).toString(),
    });
  });

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Stack gap={2}>
        <Controller
          name="invoiceBilledCost"
          control={control}
          render={({ field, fieldState }) => (
            <CurrencyInput
              {...field}
              inputProps={{
                label: "Custo por boleto faturado",
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message || "sem o custo da IUGU",
              }}
            />
          )}
        />

        <Controller
          name="withdrawalCost"
          control={control}
          render={({ field, fieldState }) => (
            <CurrencyInput
              {...field}
              inputProps={{
                label: "Custo por saque da conta",
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message,
              }}
            />
          )}
        />

        <ControllerInput
          name="cardPaymentPercentage"
          control={control}
          inputProps={{
            type: "number",
            label: "Percentual cobrado para aquisição de pagamento por cartão de crédito",
            helperText: "sem o custo da IUGU",
          }}
        />

        <ControllerInput
          name="anticipationPercentage"
          control={control}
          inputProps={{
            type: "number",
            label: "Percentual cobrado para antecipação de recebíveis do cartão de crédito",
          }}
        />
      </Stack>

      <Stack alignItems="flex-end" mt={1}>
        <LoadingButton loading={isLoading} variant="contained" type="submit">
          Salvar
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default FeesForm;
