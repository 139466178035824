import publicApi from "../publicApi";
import api from "services/api";

import getSubdomain from "../../utils/getSubdomain";
import endpoints from "../endpoints";
import { Profile, UserResponse } from "../../types/User";
import { TypesOfUsers } from "types/UserTypes";

// forgot
interface ForgotPasswordPayload {
  email: string;
}

export const forgotPassword = async ({ email }: ForgotPasswordPayload) => {
  const { data } = await publicApi.post(endpoints.registration.forgotPassword, {
    email,
    subdomain: getSubdomain(),
  });

  return data;
};

// sign in
export interface SignInPayload {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const signInUser = async (user: SignInPayload): Promise<UserResponse> => {
  const { data } = await publicApi.post(endpoints.registration.signIn, {
    username: user.email,
    password: user.password,
    remember: user.rememberMe,
    subdomain: getSubdomain(),
  });

  return data;
};

// sign up
interface SignUpUserPayload {
  email: string;
  fullName: string;
  telephone?: string;
  password: string;
  cellPhone: string;
  type: "accountant" | "user";
}

export const signUpUser = async (user: SignUpUserPayload): Promise<UserResponse> => {
  const { data } = await publicApi.post(endpoints.registration.simpleRegistration, {
    acceptTerms: true,
    email: user.email,
    name: user.fullName,
    optionalPhone: user.telephone || "",
    password: user.password,
    passwordConfirm: user.password,
    phone: user.cellPhone,
    subdomain: getSubdomain(),
    type: user.type,
  });

  return data;
};

// check availability
export const checkAvailabilityEmail = async (email: string) => {
  const { data } = await publicApi.post(endpoints.registration.checkAvailability, {
    email: email,
  });

  return data;
};

export interface GetProfilePostPayload {
  companyToken: string;
}

export const getProfilePost = async (payload: GetProfilePostPayload): Promise<Profile> => {
  const { data } = await api.post<Profile>(endpoints.registration.profilePost, payload);
  return data;
};

export interface ChangeSimplePasswordPayload {
  companyToken: string;
  newPassword: string;
  passwordConfirm: string;
}

export const changeSimplePassword = async (payload: ChangeSimplePasswordPayload) => {
  const { data } = await api.post(endpoints.registration.changeSimplePassword, payload);
  return data;
};

export interface UpdateProfileTokenPayload {
  companyToken: string;
  subDomain: string;
  profileId: number;
  userType: TypesOfUsers;
  cpf?: null | string;
  crc?: null | string;
  email?: string;
  erp?: null | string;
  erpUser?: null | string;
  friendEmail?: null | string;
  friendName?: null | string;
  name?: null | string;
  optionalPhone?: null | string;
  password?: null | string;
  phone?: null | string;
}

export const updateProfileToken = async (payload: UpdateProfileTokenPayload) => {
  await api.post(endpoints.registration.updateProfileToken, payload);
};

export interface GetUserInfoPayload {
  companyToken: string;
}

export const getUserInfo = async (payload: GetUserInfoPayload): Promise<UserResponse> => {
  const { data } = await api.post<UserResponse>(endpoints.registration.getUserInfo, payload);
  return data;
};

export const getProfile = async (): Promise<Profile> => {
  const { data } = await api.get<Profile>(endpoints.registration.getProfile);
  return data;
};

export const updateProfile = async (payload: Profile) => {
  await api.post(endpoints.registration.updateProfile, payload);
};
