import { FC } from "react";
import { useAuth } from "context/Auth";

import { Typography } from "@mui/material";
import { MESSAGE_NO_COMPANY } from "utils/constants";

function WithCompany<T extends { companyToken: string }>(Component: FC<T>) {
  const Wrapper: FC<Omit<T, "companyToken">> = (props) => {
    const {
      user: { company },
    } = useAuth();

    if (company) {
      return <Component {...(props as T)} companyToken={company.token} />;
    }

    return <Typography>{MESSAGE_NO_COMPANY}</Typography>;
  };

  return Wrapper;
}

export default WithCompany;
