import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCounties } from "services/Requests/commonData";

const useQueryCounties = (id?: string) => {
  const query = useQuery([queryKeys.counties, id], () => getCounties({ id: id || "AC" }), {
    enabled: !!id,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
  return query;
};

export default useQueryCounties;
