import * as yup from "yup";
import { formattedInput } from "components/CurrencyInput";

const numberValidation = yup
  .number()
  .nullable()
  .typeError("Digite apenas números")
  .test("verifyPositive", "Digite apenas valores positivos", (v) => {
    if (v === null || v === undefined) return true;
    return v >= 0;
  });

const companyParticipantValidation = yup
  .mixed()
  .nullable(false)
  .typeError("É necessário selecionar o cliente.")
  .required("É necessário selecionar o cliente.");

const validationSchema = yup.object().shape({
  value: yup
    .string()
    .required("Por favor, informe o valor.")
    .test("valueIsValid", "Por favor, informe o valor.", (v) => {
      if (!v) return false;
      const format = formattedInput(v);
      return format > 0;
    }),

  globalCategoryId: yup.number().notRequired().nullable(),

  parcel: yup
    .number()
    .nullable()
    .transform((value) => {
      if (value === undefined || value === null || isNaN(value)) return null;
      return typeof value === "string" ? Number(value) : value;
    })
    .test("validatePositive", "Apenas números positivos", (v) => {
      if (v === null || v === undefined) return true;
      return v >= 0;
    }),

  dueDate: yup
    .date()
    .required("A data de vencimento é obrigatório")
    .typeError("Digite uma data válida"),
  referenceDate: yup
    .date()
    .required("A data de compotência é obrigatório")
    .typeError("Digite uma data válida"),
  paymentDate: yup.date().notRequired().nullable(true).typeError("Digite uma data válida"),

  isInvoice: yup.boolean(),
  createBilling: yup.string(),

  companyParticipant: yup
    .mixed()
    .nullable(true)
    .notRequired()
    .when("isInvoice", {
      is: true,
      then: companyParticipantValidation,
    })
    .when("createBilling", {
      is: "1",
      then: companyParticipantValidation,
    }),

  description: yup
    .string()
    .notRequired()
    .when("createBilling", {
      is: "1",
      then: yup.string().required("A descrição é obrigatória ao gerar cobrança"),
    }),

  companyBankAccount: yup
    .number()
    .notRequired()
    .nullable(true)
    .when("createBilling", {
      is: "1",
      then: yup
        .number()
        .nullable(false)
        .required("O banco é obrigatório ao gerar cobrança")
        .typeError("O banco é obrigatório ao gerar cobrança"),
    }),

  invoice: yup
    .object()
    .shape({})
    .when("isInvoice", {
      is: true,
      then: yup.object().shape({
        issueDate: yup
          .date()
          .nullable(false)
          .required("A data de emissão é obrigatório")
          .typeError("Digite uma data válida"),
        serviceCodeId: yup
          .number()
          .required("O serviço prestado é obrigatório")
          .typeError("O serviço prestado é obrigatório"),

        iss: numberValidation,
        irpj: numberValidation,
        irop: numberValidation,
        pis: numberValidation,
        cofins: numberValidation,
        csll: numberValidation,
        descriptionNfe: yup.string().required("A discriminação da nota fiscal é obrigatório"),
      }),
    }),
});

export default validationSchema;
