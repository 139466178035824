import { FC, useState } from "react";
import useQueryCompanyPartners from "../../../hooks/useQueryCompanyPartners";

import { CircularProgress, Stack, useMediaQuery } from "@mui/material";
import PartnersCardList from "./PartnersCardList";
import PartnersTable from "./PartnersTable";
import { CompanyPartner } from "types/Company";
import DialogUpdatePartner from "../DialogCreateUpdatePartner";
import DialogDeletePartner from "../DialogDeletePartner";
import DialogConsultPartner from "../DialogConsultPartner";

interface PartnersListProps {
  companyToken: string;
}

const PartnersList: FC<PartnersListProps> = ({ companyToken }) => {
  const isTablet = useMediaQuery("(max-width:728px)");
  const [edittingPartner, setEdittingPartiner] = useState<CompanyPartner | null>(null);
  const [delettingPartner, setDelettingPartner] = useState<CompanyPartner | null>(null);
  const [consultPartner, setConsultPartner] = useState<CompanyPartner | null>(null);

  const { data: companyPartners = [], isLoading, isError } = useQueryCompanyPartners(companyToken);

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" height={400}>
        <CircularProgress />
      </Stack>
    );
  }

  const propsList = {
    companyPartners: companyPartners,
    isQueryError: isError,
    onEdit: (partner: CompanyPartner) => setEdittingPartiner(partner),
    onDelete: (partner: CompanyPartner) => setDelettingPartner(partner),
    onConsult: (partner: CompanyPartner) => setConsultPartner(partner),
  };

  return (
    <>
      {isTablet ? <PartnersCardList {...propsList} /> : <PartnersTable {...propsList} />}

      {delettingPartner && (
        <DialogDeletePartner
          partner={delettingPartner}
          keepMounted={false}
          open
          onClose={() => setDelettingPartner(null)}
          companyToken={companyToken}
        />
      )}

      {consultPartner && (
        <DialogConsultPartner
          partner={consultPartner}
          keepMounted={false}
          open
          onClose={() => setConsultPartner(null)}
        />
      )}

      {edittingPartner && (
        <DialogUpdatePartner
          partner={edittingPartner}
          keepMounted={false}
          open
          onClose={() => setEdittingPartiner(null)}
          companyToken={companyToken}
        />
      )}
    </>
  );
};

export default PartnersList;
