import * as yup from "yup";

const validationSchema = yup.object().shape({
  invoiceBilledCost: yup.string(),
  withdrawalCost: yup.string(),
  cardPaymentPercentage: yup
    .number()
    .nullable()
    .typeError("Digite um número válido")
    .notRequired()
    .max(100, "A porcentagem deve ser menor que 100%")
    .transform((_, value) => {
      return value === "" ? 0 : typeof value === "number" ? value : Number(value.replace(/,/g, ""));
    }),
  anticipationPercentage: yup
    .number()
    .nullable()
    .typeError("Digite um número válido")
    .notRequired()
    .max(100, "A porcentagem deve ser menor que 100%")
    .transform((_, value) => {
      return value === "" ? 0 : typeof value === "number" ? value : Number(value.replace(/,/g, ""));
    }),
});

export default validationSchema;
