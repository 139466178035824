import { useMutation, useQueryClient } from "react-query";
import { updateCompanyPartners } from "services/Requests/companies";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";

import axios from "axios";

interface Error {
  entries: {
    description: null | string;
    entityType: string;
    problems: { [x: string]: string };
  }[];
  success: boolean;
}

const useMutationUpdatePartners = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCompanyPartners, {
    onSuccess(_, { companyToken, partner, partners }) {
      queryClient.invalidateQueries([queryKeys.companyPartners, companyToken]);

      if (partner) {
        queryClient.invalidateQueries([queryKeys.companyPartnerDetail, partner.id]);
      }

      if (partners) {
        partners.forEach((partner) => {
          queryClient.invalidateQueries([queryKeys.companyPartnerDetail, partner.id]);
        });
      }

      toast.success("Sócios alterados com sucesso!");
    },
    onError(err) {
      const genericErrorMessage = "Ocorreu um error inesperado";

      if (!axios.isAxiosError(err)) {
        toast.error(genericErrorMessage);
        return;
      }

      const errorData = err.response?.data as Error;

      if (!errorData) {
        toast.error(genericErrorMessage);
        return;
      }

      const messages = errorData.entries.map((entry) => {
        const messages = Object.values(entry.problems);
        return messages.join("\n");
      });

      toast.error(messages.join("\n"));
    },
  });

  return mutation;
};

export default useMutationUpdatePartners;
