import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import {
  updateCompanyOrderGlobalCategories,
  updateOrderGlobalCategories,
} from "services/Requests/accountant";

const useMutationOrderGlobalCategories = (isCompany = false) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    isCompany ? updateCompanyOrderGlobalCategories : updateOrderGlobalCategories,
    {
      onSuccess() {
        toast.success("Ordem mudada com sucesso");
        queryClient.invalidateQueries(queryKeys.globalCategoriesByGroup, { exact: false });
        queryClient.invalidateQueries(queryKeys.clientChartOfAccounts, { exact: false });
      },
    },
  );

  return mutation;
};

export default useMutationOrderGlobalCategories;
