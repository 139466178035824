import { FC } from "react";
import { useAuth } from "context/Auth";
import { Stack, Typography } from "@mui/material";

import Configuration from "./Configuration";
import Model from "./Model";

const Emails: FC = () => {
  const {
    user: { company },
  } = useAuth();

  if (!company) {
    return <Typography>Sua empresa não foi localizada</Typography>;
  }

  return (
    <Stack gap={2}>
      <Configuration companyToken={company.token} />
      <Model companyToken={company.token} />
    </Stack>
  );
};

export default Emails;
