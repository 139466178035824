import { useCallback } from "react";
import { useQuery, UseQueryResult } from "react-query";

import queryKeys from "react-query/constants";
import { getClientChartOfAccounts } from "services/Requests/accountant";
import {
  FinancialCategoryByGroupWithChildren,
  GetGlobalCategoriesByGroupResponse,
} from "types/Company";
import normalizeFinancialCategories from "utils/normalizeFinancialCategories";

type QueryType<T> = UseQueryResult<
  T extends true ? FinancialCategoryByGroupWithChildren[] : GetGlobalCategoriesByGroupResponse,
  unknown
>;

function useQueryClientChartOfAccounts<T extends boolean>(
  companyToken: string,
  normalize: T,
): QueryType<T> {
  const select = useCallback(normalizeFinancialCategories, []);

  const query = useQuery(
    [queryKeys.clientChartOfAccounts, companyToken],
    () => getClientChartOfAccounts({ companyToken }),
    {
      select: normalize ? select : undefined,
    },
  );
  return query as QueryType<T>;
}

export default useQueryClientChartOfAccounts;
