import * as yup from "yup";

const validationSchema = yup.object().shape({
  insc: yup
    .string()
    .required("Inscrição obrigatória")
    .typeError("Campo obrigatório")
    .min(4, "A Inscrição estadual da empresa deve conter no mínimo 4 dígitos (somente números)"),
  optingSimple: yup
    .string()
    .required("Simples nacional é obrigatório")
    .typeError("Campo obrigatório"),
  cultural: yup
    .string()
    .required("O incentivo cultural é obrigatório")
    .typeError("Campo obrigatório"),
});

export default validationSchema;
