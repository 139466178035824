import { FC } from "react";
import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { PlanForm } from ".";
import CurrencyInput from "components/CurrencyInput";

interface LeafFormProps {
  control: Control<PlanForm>;
}

const LeafForm: FC<LeafFormProps> = ({ control }) => {
  return (
    <Stack gap={4}>
      <Box>
        <Typography
          variant="h5"
          sx={{ borderBottom: 1, borderBottomColor: "divider", fontWeight: 600, mb: 2 }}
        >
          Folha de pagamentos
        </Typography>

        <Stack direction="row" gap={1} justifyContent="space-between">
          <Controller
            control={control}
            name={"usePayroll"}
            render={({ field }) => (
              <FormControlLabel
                sx={{ m: 0, height: "auto" }}
                control={
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={!!field.value}
                    value={!!field.value}
                    inputRef={field.ref}
                  />
                }
                label="Oferecer também folha de pagamentos"
              />
            )}
          />

          <Controller
            name={"payrollValue"}
            control={control}
            render={({ field, fieldState }) => (
              <CurrencyInput
                {...field}
                inputProps={{
                  label: "Valor por funcionário",
                  error: !!fieldState.error?.message,
                  helperText: fieldState.error?.message,
                }}
              />
            )}
          />
        </Stack>
      </Box>

      <Box>
        <Typography
          variant="h5"
          sx={{ borderBottom: 1, borderBottomColor: "divider", fontWeight: 600, mb: 2 }}
        >
          Pró-labore
        </Typography>

        <Stack direction="row" gap={1} justifyContent="space-between">
          <Controller
            control={control}
            name={"useProlabore"}
            render={({ field }) => (
              <FormControlLabel
                sx={{ m: 0, height: "auto" }}
                control={
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={!!field.value}
                    value={!!field.value}
                    inputRef={field.ref}
                  />
                }
                label="Oferecer também pró-labore"
              />
            )}
          />

          <Controller
            name={"prolaboreValue"}
            control={control}
            render={({ field, fieldState }) => (
              <CurrencyInput
                {...field}
                inputProps={{
                  label: "Valor por sócio",
                  error: !!fieldState.error?.message,
                  helperText: fieldState.error?.message,
                }}
              />
            )}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default LeafForm;
