import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { deleteCompanyBankAccount } from "services/Requests/companies";

const useMutationDeleteCompanyBankAccount = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteCompanyBankAccount, {
    onSuccess() {
      toast.success("Conta deletada com sucesso");
      queryClient.invalidateQueries(queryKeys.companyBankAccounts, { exact: false });
    },
  });
  return mutation;
};

export default useMutationDeleteCompanyBankAccount;
