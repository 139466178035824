import { FC } from "react";
import useQueryInvoices from "pages/Home/hooks/useQueryInvoices";
import useQueryInvoicesStockControl from "./hooks/useQueryInvoicesStockControl";

import {
  Box,
  CircularProgress,
  Collapse,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import InvoiceCard from "./InvoiceCard";
import InvoiceTable from "./InvoiceTable";
import InvoiceTableStockControl from "./InvoiceTableStockControl";

import { InvoiceStatus } from "types/Company";
import { ISODate } from "types/SemanticTypes";

interface InvoiceControllerProps {
  companyToken: string;
  isStockControl: boolean;
  endDate: ISODate;
  startDate: ISODate;
  search: string;
  status: InvoiceStatus | null;
}

const QueryState: FC<{ isLoading: boolean; isError: boolean; isFetching: boolean }> = ({
  isError,
  isLoading,
  isFetching,
}) => (
  <>
    <Collapse in={!isLoading && isFetching}>
      <LinearProgress sx={{ mb: 0.5 }} />
    </Collapse>

    {isLoading && (
      <Stack alignItems="center" justifyContent="center" height={400}>
        <CircularProgress />
      </Stack>
    )}
    {isError && <Typography>Ocorreu um erro ao buscar os NFS-e.</Typography>}
  </>
);

const LoadingInvoiceStockControl: FC<Omit<InvoiceControllerProps, "isStockControl">> = ({
  companyToken,
  endDate,
  search,
  startDate,
  status,
}) => {
  const {
    data: invoice,
    isLoading,
    isError,
    isFetching,
  } = useQueryInvoicesStockControl(
    {
      companyToken,
      endDate,
      startDate,
    },
    search,
    status,
  );

  return (
    <>
      <QueryState isError={isError} isLoading={isLoading} isFetching={isFetching} />

      {!isLoading && !isError && invoice && (
        <Box>
          <InvoiceTableStockControl invoice={invoice} companyToken={companyToken} />
        </Box>
      )}
    </>
  );
};

const LoadingInvoiceTransactions: FC<Omit<InvoiceControllerProps, "isStockControl">> = ({
  companyToken,
  endDate,
  search,
  startDate,
  status,
}) => {
  const isTablet = useMediaQuery("(max-width:900px)");

  const {
    data: invoice,
    isLoading,
    isError,
    isFetching,
  } = useQueryInvoices(
    {
      companyToken,
      endDate,
      startDate,
    },
    search,
    status,
  );

  return (
    <>
      <QueryState isError={isError} isLoading={isLoading} isFetching={isFetching} />

      {!isLoading && !isError && invoice && (
        <Box>
          {isTablet ? (
            <Stack>
              {invoice.items.length === 0 && <Typography>Nenhum NFS-e encontrado</Typography>}
              {invoice.items.map((item) => (
                <InvoiceCard key={item.id} invoice={item} companyToken={companyToken} />
              ))}
            </Stack>
          ) : (
            <InvoiceTable invoice={invoice} companyToken={companyToken} />
          )}
        </Box>
      )}
    </>
  );
};

const InvoiceController: FC<InvoiceControllerProps> = ({ isStockControl, ...props }) => (
  <>
    {isStockControl ? (
      <LoadingInvoiceStockControl {...props} />
    ) : (
      <LoadingInvoiceTransactions {...props} />
    )}
  </>
);

export default InvoiceController;
