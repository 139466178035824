import { FC } from "react";
import { ClientInformationContext } from ".";
import { NormalizedClient } from "types/Client";

interface ClientInformationProviderProps {
  children: React.ReactNode;
  client: NormalizedClient;
  companyToken: string;
}

export const ClientInformationProvider: FC<ClientInformationProviderProps> = ({
  children,
  client,
  companyToken,
}) => {
  return (
    <ClientInformationContext.Provider value={{ ...client, companyToken }}>
      {children}
    </ClientInformationContext.Provider>
  );
};
