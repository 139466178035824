import { FC, useState } from "react";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";

import DialogUpdateCompanyData from "./DialogUpdateCompanyData";
import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import { CardAction, CardContent } from "../CardWrapper";

interface CompanyDataProps {
  companyToken: string;
}

const CompanyData: FC<CompanyDataProps> = ({ companyToken }) => {
  const { data, isLoading } = useQueryGetCompany(companyToken);
  const [dialogUpdateCompanyIsOpen, setDialogUpdateCompanyIsOpen] = useState(false);

  return (
    <>
      <CardContent>
        {isLoading && (
          <Stack height={200} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {data && (
          <Stack gap={2}>
            <TextField
              label="Razão Social"
              fullWidth
              value={data.companyName || "-"}
              InputProps={{ readOnly: true }}
            />
            <TextField label="CNPJ" fullWidth value={data.cnpj} InputProps={{ readOnly: true }} />
          </Stack>
        )}
      </CardContent>

      <CardAction>
        <Button variant="contained" onClick={() => setDialogUpdateCompanyIsOpen(true)}>
          Atualizar dados da empresa
        </Button>
      </CardAction>

      {data && dialogUpdateCompanyIsOpen && (
        <DialogUpdateCompanyData
          companyInformation={data}
          open={dialogUpdateCompanyIsOpen}
          onClose={() => setDialogUpdateCompanyIsOpen(false)}
        />
      )}
    </>
  );
};

export default CompanyData;
