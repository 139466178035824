import { useState } from "react";
import { Control, Controller, Path } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import useQueryGetContracts from "pages/Home/hooks/useQueryGetContracts";
import { useNavigate } from "react-router-dom";

interface RequiredFields {
  agreement: string;
}

interface ContractProps<T extends RequiredFields = RequiredFields> {
  control: Control<T>;
  companyToken: string;
}

export function Contract<T extends RequiredFields = RequiredFields>({
  control,
  companyToken,
}: ContractProps<T>) {
  const { data, isLoading, isError } = useQueryGetContracts(companyToken);
  const [confirmationExit, setConfirmationExit] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Box>
        <FormControl fullWidth sx={{ mb: 1 }}>
          <InputLabel>Vincular contrato</InputLabel>
          <Controller
            name={"agreement" as Path<T>}
            control={control}
            render={({ field, fieldState }) => (
              <>
                <Select
                  {...field}
                  value={field.value || ""}
                  label="Vincular contrato"
                  fullWidth
                  MenuProps={{ sx: { maxHeight: "50vh" } }}
                  error={!!fieldState.error?.message}
                >
                  <MenuItem value="" disabled>
                    {isLoading && "Carregando..."}
                    {isError && "Ocorreu um error ao carregar os contratos"}
                    {data && data.length === 0 && "Nenhum contrato encontrado"}
                    {!isLoading && !isLoading && data && data.length !== 0 && "Selecione: "}
                  </MenuItem>

                  {data &&
                    data.map((contract) => (
                      <MenuItem key={contract.downloadToken} value={contract.filename}>
                        {contract.filename}
                      </MenuItem>
                    ))}
                </Select>
                {!!fieldState.error?.message && (
                  <Typography variant="body1">{fieldState.error?.message}</Typography>
                )}
              </>
            )}
          />
        </FormControl>

        <Typography variant="body2">É recomendável cadastrar previamente os contratos</Typography>
        <Link
          href="configuracoes?tab=contratos"
          sx={{ fontSize: "14px" }}
          onClick={(e) => {
            e.preventDefault();
            setConfirmationExit(true);
          }}
        >
          Ver contratos cadastrados
        </Link>
      </Box>

      <Dialog open={confirmationExit} onClose={() => setConfirmationExit(false)}>
        <DialogTitle>Deseja sair da edição/criação?</DialogTitle>

        <DialogContent>
          Você irá sair dessa tela para ir aos contratos, seu progresso será excluído
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirmationExit(false)}>
            Não
          </Button>
          <Button variant="contained" onClick={() => navigate("/configuracoes?tab=contratos")}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
