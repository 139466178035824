/* eslint-disable camelcase */

import { ResponseCallback } from "types/Iugu";

export interface CreatePaymentToken {
  iuguAccountId: string;
  year: string;
  month: string;
  fullName: string;
  cardNumber: string;
  securityCode: number;
}

export const createPaymentToken = async (
  { iuguAccountId, fullName, month, year, cardNumber, securityCode }: CreatePaymentToken,
  responseCallback: (response: ResponseCallback) => void,
) => {
  await Iugu.setAccountID(iuguAccountId);
  await Iugu.setTestMode(false);
  await Iugu.setup();

  const splittedName = fullName.split(/[\s ]+/);

  const firstName = splittedName[0];
  splittedName.shift();
  const lastName = splittedName.join(" ");
  cardNumber = cardNumber.replace(/ /g, "");

  await Iugu.createPaymentToken(
    {
      month,
      year,
      first_name: firstName,
      last_name: lastName,
      number: cardNumber,
      verification_value: securityCode,
    },
    responseCallback,
  );
};
