import { FC } from "react";
import FeesTable from "./FeesTable";
import { CircularProgress, Stack, Typography } from "@mui/material";
import useQueryAllSubAccounts from "./hooks/useQueryAllSubAccounts";

const Fees: FC = () => {
  const { data, isLoading, isError } = useQueryAllSubAccounts();

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isError && !isLoading) {
    return (
      <>
        <Typography variant="body1">Ocorreu um error em carregar as taxas dos clientes!</Typography>
      </>
    );
  }

  return (
    <>
      <FeesTable subsAccounts={data || []} />
    </>
  );
};

export default Fees;
