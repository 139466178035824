import { FC, memo, useState } from "react";
import { Input, Stack, Typography } from "@mui/material";
import useDebounce from "hooks/useDebounce";

interface ColorFieldProps {
  initialValue: string;
  onChange: (newValue: string) => void;
  label: string;
}

const ColorField: FC<ColorFieldProps> = ({ label, onChange, initialValue }) => {
  const [value, setValue] = useState(initialValue);
  useDebounce(value, 300, (value) => {
    onChange(value);
  });

  return (
    <>
      <Typography variant="body1" sx={{ fontSize: "14px" }}>
        {label}
      </Typography>
      <Stack alignItems="end" justifyContent="end">
        <Input
          fullWidth
          type="color"
          value={value}
          onChange={(e) => {
            setValue(e?.target?.value);
          }}
          sx={{ width: 50 }}
        />
      </Stack>
    </>
  );
};

export default memo(ColorField);
