import { Client, NormalizedClient } from "types/Client";

const normalizeClient = (client: Client): NormalizedClient => {
  return {
    ...client,
    balance: Number(client.balance).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    }),
  };
};

export default normalizeClient;
