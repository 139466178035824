import { useAuth } from "context/Auth";
import { useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { getAllSubAccounts } from "services/Requests/marketplace";

const usePrefetchAllSubAccounts = () => {
  const {
    user: { userInfo },
  } = useAuth();
  const queryClient = useQueryClient();

  if (userInfo.userType !== "Admin") return;

  queryClient.prefetchQuery(queryKeys.allSubAccounts, getAllSubAccounts);
};

export default usePrefetchAllSubAccounts;
