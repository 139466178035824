import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { updateLayout } from "services/Requests/template";

const useMutationUpdateLayout = () => {
  const queryClient = useQueryClient();

  return useMutation(updateLayout, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries([queryKeys.templates, id]);
    },
  });
};

export default useMutationUpdateLayout;
