import { FC, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import Close from "@mui/icons-material/Close";

import MyCompany from "./MyCompany";
import TabPanel from "components/TabPanel";
import { CompanyInformation } from "types/Company";
import PartnerForm from "pages/Home/pages/PartnerForm";
import useQueryCompanyPartners from "pages/Home/hooks/useQueryCompanyPartners";

interface DialogUpdateCompanyDataProps extends DialogProps {
  companyInformation: CompanyInformation;
}

const DialogUpdateCompanyData: FC<DialogUpdateCompanyDataProps> = ({
  companyInformation,
  onClose,
  ...props
}) => {
  const [value, setValue] = useState(0);
  const [confirmationExit, setConfirmationExit] = useState(false);
  const [completeFirstForm, setCompleteFirstForm] = useState(false);
  const { data: partners, isLoading } = useQueryCompanyPartners(companyInformation.companyToken);

  return (
    <>
      <Dialog maxWidth="lg" fullWidth onClose={() => setConfirmationExit(true)} {...props}>
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            Atualização Cadastral
            <Tooltip title="Fechar">
              <IconButton size="small" onClick={() => setConfirmationExit(true)}>
                <Close fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              onChange={(_, newValue) => setValue(newValue)}
              value={value}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Minha Empresa" />
              <Tab label="Sócios" disabled={!completeFirstForm} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <MyCompany
              companyInformation={companyInformation}
              onChangePage={() => {
                setValue(1);
                setCompleteFirstForm(true);
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {isLoading && (
              <Stack height={300} alignItems="center" justifyContent="center">
                <CircularProgress />
              </Stack>
            )}

            {partners && !isLoading && (
              <PartnerForm
                companyInformation={companyInformation}
                partners={partners}
                onChangePage={() => {
                  setValue(0);
                }}
                onClose={() => onClose?.({}, "escapeKeyDown")}
              />
            )}
          </TabPanel>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="sm"
        open={confirmationExit}
        fullWidth
        onClose={() => setConfirmationExit(false)}
      >
        <DialogTitle>Cadastro</DialogTitle>
        <DialogContent dividers>
          Tem certeza que deseja interromper a atualização cadastral?
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirmationExit(false)}>
            Não
          </Button>
          <Button variant="contained" onClick={() => onClose?.({}, "escapeKeyDown")}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogUpdateCompanyData;
