import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { MovementForm } from "../utils/InterfacesForm";
import { Box, Button } from "@mui/material";
import PaymentModesSelect from "pages/Home/components/PaymentModesSelect";
import CurrencyInput from "components/CurrencyInput";
import DeleteIcon from "@mui/icons-material/Delete";

interface PaymentItemProps {
  control: Control<MovementForm>;
  index: number;
  isConsultation: boolean;
  onRemove: () => void;
}

const PaymentItem: FC<PaymentItemProps> = ({ control, index, isConsultation, onRemove }) => {
  return (
    <Box
      display="grid"
      gap={2}
      gridTemplateColumns="2fr 2fr 1fr"
      pb={2}
      borderBottom={1}
      borderColor="divider"
    >
      <Controller
        control={control}
        name={`payment.${index}.paymentMode`}
        render={({ field, fieldState }) => (
          <PaymentModesSelect
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            label="Forma"
            disabled={isConsultation}
          />
        )}
      />

      <Controller
        control={control}
        name={`payment.${index}.value`}
        render={({ field, fieldState }) => (
          <CurrencyInput
            {...field}
            inputProps={{
              label: "Valor (R$)",
              helperText: fieldState.error?.message,
              error: !!fieldState.error?.message,
            }}
            disabled={isConsultation}
          />
        )}
      />

      {index !== 0 && !isConsultation && (
        <Button startIcon={<DeleteIcon />} onClick={onRemove}>
          Excluir
        </Button>
      )}
    </Box>
  );
};

export default PaymentItem;
