import { FC } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DateTypeFilter } from "types/Company";

interface DateTypesFilterProps {
  value: DateTypeFilter | null;
  setValue: (value: DateTypeFilter | null) => void;
  type?: 1 | 2 | 3 | 4;
}

const DateTypesFilter: FC<DateTypesFilterProps> = ({ value, setValue, type = 1 }) => {
  return (
    <FormControl>
      <InputLabel>Tipos de datas</InputLabel>
      <Select
        value={value || 0}
        label="Tipos de datas"
        onChange={(e) => {
          let value = e.target.value;
          if (typeof value === "number") value = value.toString();

          setValue(value === "0" ? null : (value as DateTypeFilter));
        }}
      >
        {type === 2 && [
          <MenuItem value={"due"} key={"due"}>
            Vencimento
          </MenuItem>,
          <MenuItem value="reference" key={"reference"}>
            Competência
          </MenuItem>,
        ]}

        {type === 1 && [
          <MenuItem value={0} key={0}>
            Recebimento
          </MenuItem>,
          <MenuItem value="reference" key={"reference"}>
            Competência
          </MenuItem>,
        ]}

        {type === 3 && [
          <MenuItem value={0} key={0}>
            Pagamento
          </MenuItem>,
          <MenuItem value="reference" key={"reference"}>
            Competência
          </MenuItem>,
        ]}

        {type === 4 && [
          <MenuItem value={0} key={0}>
            Pgto / Vencimento
          </MenuItem>,
          <MenuItem value="reference" key={"reference"}>
            Competência
          </MenuItem>,
        ]}
      </Select>
    </FormControl>
  );
};

export default DateTypesFilter;
