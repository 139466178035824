import { FC, useState } from "react";
import useMutationCreateBill from "pages/Home/hooks/useMutationCreateBill";

import { Button, Dialog, DialogActions, DialogContent, DialogProps, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Info from "@mui/icons-material/Info";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { CustomService } from "types/ServiceAndPlan";

/* eslint-disable camelcase */

interface DialogBuyServiceProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  service: CustomService;
  companyToken: string;
}

const DialogBuyService: FC<DialogBuyServiceProps> = ({
  onClose,
  service,
  companyToken,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationCreateBill(false);
  const [showEndDialog, setShowEndDialog] = useState(false);

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleBuyService = async () => {
    const value = service.value * 100;

    const today = new Date();
    const payDate = new Date();
    payDate.setDate(today.getDate() + service.daysAfter);

    await mutateAsync({
      companyToken,
      items: [
        {
          quantity: 1,
          description: service.name,
          price_cents: value,
        },
      ],
      customVariables: [
        {
          name: "type",
          value: "service",
        },
      ],
      dueDate: payDate.toDateString(),
    });
    setShowEndDialog(true);
  };

  return (
    <>
      <Dialog {...props} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitleWithCloseButton onClose={handleClose} sx={{ lineHeight: 1.1 }}>
          Compra do serviço {service.name}
        </DialogTitleWithCloseButton>

        <DialogContent>Deseja confirmar a comprar?</DialogContent>

        <DialogActions>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            autoFocus
            onClick={handleBuyService}
          >
            Sim
          </LoadingButton>
          <Button disabled={isLoading} onClick={handleClose} variant="outlined">
            Não
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showEndDialog && props.open}
        onClose={() => {
          setShowEndDialog(false);
          handleClose();
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitleWithCloseButton
          onClose={() => {
            setShowEndDialog(false);
            handleClose();
          }}
        >
          <Stack direction="row" gap={0.5} alignItems="center">
            <Info color="info" />
            Compra de serviço
          </Stack>
        </DialogTitleWithCloseButton>

        <DialogContent>Compra realizada com sucesso. Aguarde o contato do contador!</DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setShowEndDialog(false);
              handleClose();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogBuyService;
