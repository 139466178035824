import { FC } from "react";
import useMutationGuessCounty from "pages/Home/hooks/useMutationGuessCounty";
import useMutationValidateParticipantCPFCNPJ from "./hooks/useMutationValidateParticipantCPFCNPJ";
import jsonp from "jsonp";

import MaskedInput from "react-text-mask";
import { Control, Controller, useWatch, UseFormSetValue } from "react-hook-form";
import { cnpjMask, cpfMask, telephoneMask, telephoneMaskWithFiveDigit } from "utils/masks";

import { Box, Stack, TextField, Typography } from "@mui/material";
import ControllerInput from "components/ControllerInput";
import { CreateClientForm } from ".";

import { CNPJCPFInfo } from "types/External";
import endpoints from "services/endpoints";
import axios, { AxiosError } from "axios";
import { CompanyPartner } from "types/Company";

interface BasicDataFieldsProps {
  control: Control<CreateClientForm>;
  companyToken: string;
  setValue: UseFormSetValue<CreateClientForm>;
  onAlert: (message: string | null) => void;
}

const BasicDataFields: FC<BasicDataFieldsProps> = ({
  control,
  companyToken,
  onAlert,
  setValue,
}) => {
  const cpfcnpj = useWatch({
    control,
    name: "cpfcnpj",
  });
  const { mutateAsync } = useMutationValidateParticipantCPFCNPJ(false);
  const { mutateAsync: mutateGuessCounty } = useMutationGuessCounty();

  const maskBuilderCPFCNPJ = (v: string) => {
    return v.length <= 14 ? [...cpfMask, /\d/g] : cnpjMask;
  };

  const maskBuilderTelephone = (v: string) => {
    return v.length <= 14 ? [...telephoneMask, /\d/g] : telephoneMaskWithFiveDigit;
  };

  const handleBlurClientCPFCNPJ = async () => {
    if (!cpfcnpj) return;
    const cleanCPFCNPJ = cpfcnpj.replace(/\D/g, "");

    const runCPFCNPJValidation = async () => {
      try {
        await mutateAsync({
          cpfcnpj: cleanCPFCNPJ,
          companyToken,
        });
        return true;
      } catch (err) {
        if (err && axios.isAxiosError(err)) {
          const error = err as AxiosError<CompanyPartner, unknown>;
          if (error.response?.data.cpfcnpj) {
            onAlert(
              `O CPF/CNPJ ${error.response?.data.cpfcnpj} já está cadastrado por ${error.response?.data.name}`,
            );
          } else {
            onAlert("Digite um CPF/CNPJ válido");
          }
        } else {
          onAlert("Ocorreu um erro na requisição");
        }
        return false;
      }
    };

    try {
      const validCPFCNPJ = await runCPFCNPJValidation();

      if (cleanCPFCNPJ.length !== 14 || !validCPFCNPJ) {
        if (validCPFCNPJ) {
          onAlert(null);
        }
        return;
      }

      jsonp(
        `${endpoints.external.receitaws}/${cleanCPFCNPJ}`,
        undefined,
        async (_, data: CNPJCPFInfo) => {
          const company = data;
          if (!data || data.status != "OK") {
            onAlert("CNPJ não encontrado");
            return;
          }

          setValue("complement", company.complemento);
          setValue("neighborhood", company.bairro);
          setValue("streetNumber", Number(company.numero));
          setValue("street", company.logradouro);

          setValue("name", company.nome);
          setValue("state", company.uf);
          setValue("zipCode", company.cep?.replace(/\D/g, ""));

          const county = await mutateGuessCounty({ name: company.municipio, state: company.uf });
          if (data && county?.id) {
            setValue("city", county.id);
          }

          onAlert(null);
        },
      );
    } catch (err) {
      onAlert("CNPJ não encontrado");
    }
  };

  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={400}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 2 }}
      >
        Dados Básicos
      </Typography>

      <Stack gap={2}>
        <Controller
          name={"cpfcnpj"}
          control={control}
          render={({ fieldState, field }) => (
            <MaskedInput
              mask={maskBuilderCPFCNPJ(field.value)}
              guide={false}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              render={(ref, props) => (
                <TextField
                  fullWidth
                  label="CPF / CNPJ"
                  variant="outlined"
                  inputRef={ref}
                  {...props}
                  sx={{ flex: 1 }}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  onBlur={handleBlurClientCPFCNPJ}
                />
              )}
            ></MaskedInput>
          )}
        />

        <ControllerInput
          control={control}
          name="name"
          inputProps={{
            label: "Nome",
            required: true,
            sx: { flex: 1 },
          }}
        />

        <Stack direction="row" gap={2}>
          <ControllerInput
            control={control}
            name="municipalEnrollment"
            inputProps={{
              label: "Inscrição Municipal",
              sx: { flex: 1 },
            }}
          />
          <ControllerInput
            control={control}
            name="stateRegistration"
            inputProps={{
              label: "Inscrição Estadual",
              sx: { flex: 1 },
            }}
          />
        </Stack>

        <Stack direction="row" gap={2}>
          <Controller
            name="telNumber"
            control={control}
            render={({ fieldState, field }) => (
              <MaskedInput
                mask={maskBuilderTelephone(field.value)}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                onPaste={(e) => maskBuilderTelephone(e.clipboardData.getData("text/plain"))}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="DDD + Telefone"
                    variant="outlined"
                    inputRef={ref}
                    placeholder="(00) 00000-0000"
                    {...props}
                    sx={{ flex: 1 }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              ></MaskedInput>
            )}
          />
          <ControllerInput
            control={control}
            name="email"
            inputProps={{
              label: "Email",
              type: "email",
              sx: { flex: 1 },
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default BasicDataFields;
