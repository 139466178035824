import { ChangeEvent, FC, useState } from "react";

import { Checkbox, IconButton, Menu, MenuItem, TableCell, TableRow } from "@mui/material";
import {
  ChartOfAccountsFromToGroup,
  ChartOfAccountFromToGroupClass,
  ChartOfAccountFromToGroupNature,
} from "types/Accountant";
import MenuIcon from "@mui/icons-material/Menu";
import CreateOrEditAccount from "./components/CreateOrEditAccount";
import DialogDeleteAccount from "./components/DialogDeleteAccount";

export const classRepresentation: { [x in ChartOfAccountFromToGroupClass | "default"]: string } = {
  A: "Ativo",
  P: "Passivo",
  PL: "Patrimônio Líquido",
  R: "Resultado",
  default: "-",
};
export const natureRepresentation: { [x in ChartOfAccountFromToGroupNature | "default"]: string } =
  {
    C: "Crédito",
    D: "Débito",
    default: "-",
  };

interface ChartOfAccountTableItemProps {
  chartOfAccount: ChartOfAccountsFromToGroup;
  selected: boolean;
  onToggleSelected: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const ChartOfAccountTableItem: FC<ChartOfAccountTableItemProps> = ({
  chartOfAccount,
  selected,
  onToggleSelected,
}) => {
  const [editAccount, setEditAccount] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);

  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox size="small" checked={selected} onChange={onToggleSelected} />
        </TableCell>
        <TableCell>{chartOfAccount.account}</TableCell>
        <TableCell>{chartOfAccount.fromDominio}</TableCell>
        <TableCell>{chartOfAccount.description}</TableCell>
        <TableCell>{classRepresentation[chartOfAccount.class || "default"]}</TableCell>
        <TableCell>{natureRepresentation[chartOfAccount.nature || "default"]}</TableCell>
        <TableCell align="right">
          <IconButton size="small" onClick={(e) => setAnchorElMenu(e.currentTarget)}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      <Menu
        anchorEl={anchorElMenu}
        open={menuIsOpen}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => setEditAccount(true)}>Editar</MenuItem>
        <MenuItem onClick={() => setDeleteAccount(true)}>Excluir</MenuItem>
      </Menu>

      <CreateOrEditAccount
        open={editAccount}
        onClose={() => setEditAccount(false)}
        groupChartOfAccountsToFromId={chartOfAccount.groupChartOfAccountsToFromId}
        chartOfAccount={chartOfAccount}
      />

      <DialogDeleteAccount
        open={deleteAccount}
        onClose={() => setDeleteAccount(false)}
        chartOfAccount={chartOfAccount}
      />
    </>
  );
};

export default ChartOfAccountTableItem;
