import { FC, useMemo } from "react";

import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import { Transactions } from "types/Company";
import { TransactionAction } from "services/Requests/companies";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface AccomplishedTableProps {
  transaction: Transactions;
  actionFilter: TransactionAction | null;
}

const AccomplishedTable: FC<AccomplishedTableProps> = ({ transaction, actionFilter }) => {
  const accomplishedData = useMemo(() => {
    const paidOut = transaction?.pago || 0;
    const received = transaction?.recebido || 0;
    const finalFlowPeriod = paidOut + received;

    if (actionFilter === null || actionFilter === undefined) {
      return {
        paidOut,
        received,
        finalFlowPeriod,
        balanceToday: transaction?.saldoHoje || 0,
      };
    }

    return {
      paidOut:
        actionFilter === "PA" || actionFilter === "PR" || actionFilter === "TM" ? paidOut : 0,
      received:
        actionFilter === "RE" || actionFilter === "PR" || actionFilter === "TM" ? received : 0,
      finalFlowPeriod: actionFilter === "PR" || actionFilter === "TM" ? finalFlowPeriod : 0,
      balanceToday: 0,
    };
  }, [transaction]);

  const getColor = (value: number) => {
    if (value >= 0) return "success.main";
    return "error.main";
  };

  return (
    <Card sx={{ "@media print": { boxShadow: "none" } }}>
      <CardContent>
        <Typography variant="h6">Realizado</Typography>

        <TableContainer sx={{ mt: 1 }}>
          <Table size="small">
            <TableBody>
              <TableRow hover>
                <TableCell>Contas Pagas</TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, color: getColor(accomplishedData.paidOut) }}
                >
                  {formatCurrencyBRL(accomplishedData.paidOut)}
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Contas Recebidas</TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, color: getColor(accomplishedData.received) }}
                >
                  {formatCurrencyBRL(accomplishedData.received)}
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Total de contas pagas e recebidas</TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, color: getColor(accomplishedData.finalFlowPeriod) }}
                >
                  {formatCurrencyBRL(accomplishedData.finalFlowPeriod)}
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Saldo disponível hoje</TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, color: getColor(accomplishedData.balanceToday) }}
                >
                  {formatCurrencyBRL(accomplishedData.balanceToday)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default AccomplishedTable;
