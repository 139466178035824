import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { updateCompanyChartOfAccounts } from "services/Requests/accountant";

const useMutationUpdateCompanyChartOfAccounts = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCompanyChartOfAccounts, {
    onSuccess() {
      toast.success("Categoria atualizada com sucesso");
      queryClient.invalidateQueries(queryKeys.globalCategoriesByGroup, { exact: false });
      queryClient.invalidateQueries(queryKeys.clientChartOfAccounts, { exact: false });
    },
  });
  return mutation;
};

export default useMutationUpdateCompanyChartOfAccounts;
