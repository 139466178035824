import { forwardRef, Ref } from "react";
import { Menu, MenuProps } from "@mui/material";

function DropdownMenuInner(
  props: Omit<MenuProps, "transformOrigin" | "anchorOrigin">,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <Menu
      {...props}
      ref={ref}
      PaperProps={{
        ...props.PaperProps,
        elevation: 0,
        sx: {
          ...props?.PaperProps?.sx,
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,

          "&:before": {
            content: "''",
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {props.children}
    </Menu>
  );
}

const DropdownMenu = forwardRef(DropdownMenuInner);
export default DropdownMenu;
