import { FC, useMemo } from "react";

import { Box, Button, Divider, Paper, Typography } from "@mui/material";

import { Periodicity, periodicityLabels } from "./Plans";
import { ProductByPlan } from "types/Plan";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface PlanProps {
  plan: ProductByPlan;
  periodicity: Periodicity;
  onSelectPlan: (plan: ProductByPlan) => void;
}

const Plan: FC<PlanProps> = ({ plan, periodicity, onSelectPlan }) => {
  const fieldsByPeriodicity = useMemo(() => {
    const fields = {
      price: plan.price,
      description: plan.priceDescription,
      total: plan.price,
      totalUnitPrice: plan.price / plan.useLicenses,
    };

    if (periodicity === 1) {
      return fields;
    }

    const findedPeriodicity = plan.paymentRecurrences.find(
      (recurrence) => recurrence.periodicity === periodicity,
    );

    if (!findedPeriodicity) {
      return fields;
    }

    const discountPrice = fields.price * (findedPeriodicity.percentDiscount / 100);

    fields.price = fields.price - discountPrice;
    fields.description = findedPeriodicity.priceDescription;
    fields.total = fields.price * periodicity;
    fields.totalUnitPrice = fields.price / plan.useLicenses;

    return fields;
  }, [plan, periodicity]);

  return (
    <Paper elevation={1} sx={{ p: 1 }}>
      <Typography variant="body1" align="center">
        {plan.name}
      </Typography>

      <Box p={2}>
        <Typography variant="h4" align="center" fontWeight={600}>
          {formatCurrencyBRL(fieldsByPeriodicity.price)}
          <Typography variant="body1" color="text.secondary" component="span">
            /mês
          </Typography>
        </Typography>
        <Typography align="center" variant="body2" sx={{ mt: 0 }}>
          {fieldsByPeriodicity.description}
        </Typography>

        <Button
          variant="contained"
          sx={{ display: "block", width: "90%", marginX: "auto", mt: 1.5 }}
          onClick={() => {
            onSelectPlan({ ...plan, price: fieldsByPeriodicity.total });
          }}
        >
          Assinar
        </Button>
        <Typography
          variant="body2"
          align="center"
          sx={{ fontSize: "0.8rem", "*": { fontSize: "0.8rem" }, mt: 0.5 }}
        >
          Total{" "}
          <Typography component="strong" fontWeight={600}>
            {formatCurrencyBRL(fieldsByPeriodicity.total)}
          </Typography>
          <Typography component="small" color="text.secondary" sx={{ fontSize: "0.6rem" }}>
            /{periodicityLabels[periodicity].toLowerCase()}
          </Typography>
        </Typography>

        <Box component="ul" sx={{ listStyle: "disc", pl: 2, mt: 2 }}>
          <Typography component="li" variant="body2">
            Licenciado para{" "}
            <Typography component="strong" fontWeight={600}>
              {plan.useLicenses} clientes
            </Typography>
          </Typography>
          <Typography component="li" variant="body2">
            Licença adicional{" "}
            <Typography component="strong" fontWeight={600} variant="inherit">
              {formatCurrencyBRL(fieldsByPeriodicity.totalUnitPrice)}
            </Typography>
            <Typography
              variant="inherit"
              component="small"
              color="text.secondary"
              sx={{ fontSize: "0.8rem" }}
            >
              /cada
            </Typography>
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" align="center">
          Detalhes
        </Typography>
        <Box
          mt={1}
          dangerouslySetInnerHTML={{
            __html: plan.description,
          }}
          sx={{
            ul: {
              listStyle: "disc",
              pl: 2,
            },
          }}
        />
      </Box>
    </Paper>
  );
};

export default Plan;
