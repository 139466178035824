import { FC, useState } from "react";
import useQueryOrdersByAssignments from "../../hooks/useQueryOrdersByAssignments";

import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

import OrdersTable from "./OrdersTable";
import { OrderState } from "types/OpenCompany";
import SearchInput from "components/SearchInput";

const Legalization: FC = () => {
  const [orderStateFilter, setOrderStateFilter] = useState<OrderState>(0);
  const [search, setSearch] = useState("");
  const {
    data: orders,
    isLoading,
    isFetching,
    isError,
  } = useQueryOrdersByAssignments(orderStateFilter, search);

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <RadioGroup
          row
          value={orderStateFilter}
          onChange={(_, value) => setOrderStateFilter(Number(value) as OrderState)}
        >
          <FormControlLabel value={0} control={<Radio />} label={"Em Andamento"} />
          <FormControlLabel value={11} control={<Radio />} label={"Finalizados"} />
          <FormControlLabel value={12} control={<Radio />} label={"Pendentes"} />
        </RadioGroup>

        <SearchInput value={search} onChange={(s) => setSearch(s)} />
      </Stack>

      <Collapse in={isFetching && !isLoading}>
        <LinearProgress />
      </Collapse>
      <Divider sx={{ mb: 1 }} />
      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
      {isError && (
        <Typography>
          Ocorreu um erro ao tantar pegar seus pedido, tente novamente mais tarde
        </Typography>
      )}
      {!isLoading && !isError && orders && <OrdersTable orders={orders} />}
    </Box>
  );
};

export default Legalization;
