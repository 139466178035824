import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    overflow: hidden;
  }

  body, #root {
    font-family: 'Roboto', sans-serif;
  }

  #root {
    height: 100vh;
    overflow: auto;
  }

  :root {
    scroll-behavior: smooth;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ol,
  ul {
    list-style: none;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .only-print {
    display: none !important;
  }

  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }

    .no-print {
      display: none !important;
    }

    .only-print {
      display: initial !important;
    }
  }

  @page {
    size: auto;
    margin: 14mm;
  }

  div[role="cell"]:has(button.Mui-disabled) {
    cursor: not-allowed !important;
  }
`;
