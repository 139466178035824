import { useMutation, useQueryClient } from "react-query";
import useInvalidateDashboardQueries from "../pages/Dashboard/hooks/useInvalidateDashboardQueries";
import toast from "react-hot-toast";

import queryKeys from "react-query/constants";
import { createTransaction, CreateTransactionPayload } from "services/Requests/companies";
import { Transaction } from "types/Company";
import { AxiosError } from "axios";

interface IError {
  message?: string;
}

const useMutationCreateTransaction = () => {
  const queryClient = useQueryClient();
  const invalidate = useInvalidateDashboardQueries();

  const mutation = useMutation<Transaction, AxiosError<IError>, CreateTransactionPayload>(
    createTransaction,
    {
      onSuccess() {
        toast.success("Lançamento criado com sucesso!");
        queryClient.invalidateQueries(queryKeys.transactions, { exact: false });
        invalidate();
      },
    },
  );
  return mutation;
};

export default useMutationCreateTransaction;
