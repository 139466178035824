import endpoints from "../endpoints";
import api from "../api";
import { Configurations, MessageEmail, MessageType } from "types/Communications";
import { HTMLString } from "types/SemanticTypes";

export interface GetConfigurationPayload {
  companyToken: string;
  subdomain: string;
}

export const getConfiguration = async (
  payload: GetConfigurationPayload,
): Promise<Configurations> => {
  const { data } = await api.post<Configurations>(endpoints.communications.configuration, payload);
  return data;
};

export interface SetConfigurationPayload extends Omit<Configurations, "companyToken"> {
  companyToken: string;
}

export const setConfiguration = async (payload: SetConfigurationPayload) => {
  const { data } = await api.post(endpoints.communications.setConfiguration, payload);
  return data;
};

export interface GetMessageTypesPayload {
  companyToken: string;
}

export const getMessageTypes = async (payload: GetMessageTypesPayload): Promise<MessageType[]> => {
  const { data } = await api.get(endpoints.communications.messageTypes, {
    params: {
      companyToken: payload.companyToken,
    },
  });
  return data;
};

export interface GetMessageParams {
  companyToken: string;
  messageType: number;
}

export const getMessage = async (params: GetMessageParams): Promise<MessageEmail> => {
  const { data } = await api.get(endpoints.communications.message, { params });
  return data;
};

export interface UpdateMessagePayload {
  body: HTMLString;
  footer: null | string;
  title: string;
  type: number;
  companyToken: string;
}

export const updateMessage = async (payload: UpdateMessagePayload) => {
  const { data } = await api.post(endpoints.communications.message, payload);
  return data;
};

export interface SendEmailTestPayload {
  companyToken: string;
  email: string;
  type: number;
}

export const sendEmailTest = async (payload: SendEmailTestPayload) => {
  await api.post(endpoints.communications.sendEmailTest, {
    companyToken: payload.companyToken,
    title: payload.email,
    type: payload.type,
  });
};
