import { FC } from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import useQueryInfoAccount from "pages/Home/hooks/useQueryInfoAccount";

import {
  Alert,
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { ReleaseCreditForm } from "../Form";
import PeriodicityForm from "./PeriodicityForm";

interface ChargeFormProps {
  control: Control<ReleaseCreditForm>;
  companyToken: string;
  editRecurrence?: boolean;
}

const ChargeForm: FC<ChargeFormProps> = ({ control, companyToken, editRecurrence }) => {
  const { data: infoAccount } = useQueryInfoAccount({ companyToken });
  const isVerified = infoAccount?.verification_status === "2";

  const parcel = useWatch({
    control,
    name: "parcel",
  });
  const createBilling = useWatch({
    control,
    name: "createBilling",
  });

  const multipleTimes = !!parcel && parcel > 1;

  return (
    <>
      <PeriodicityForm control={control} editRecurrence={editRecurrence} />

      {!editRecurrence && (
        <>
          <Controller
            name="createBilling"
            control={control}
            render={({ field }) => (
              <FormControl sx={{ gridColumn: "-1/1" }} disabled={!isVerified}>
                <FormLabel>Emitir Cobrança?</FormLabel>
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label={multipleTimes ? "Nenhuma" : "Não"}
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label={multipleTimes ? "Somente a primeira" : "Sim"}
                  />
                  {multipleTimes && (
                    <FormControlLabel value={2} control={<Radio />} label="Todas as ocorrências" />
                  )}
                </RadioGroup>
              </FormControl>
            )}
          />

          <Box gridColumn="-1/1">
            <Collapse in={createBilling === "1"}>
              <Box>
                <Controller
                  name="daysBefore"
                  control={control}
                  render={({ field }) => (
                    <FormControl disabled={!isVerified}>
                      <FormLabel>Quando emitir a cobrança?</FormLabel>
                      <RadioGroup {...field} row>
                        <FormControlLabel value={0} control={<Radio />} label="Imediatemente" />
                        <FormControlLabel
                          value={5}
                          control={<Radio />}
                          label="5 dias antes do vencimento"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Box>
            </Collapse>
          </Box>

          {infoAccount && !isVerified && (
            <Alert sx={{ gridColumn: "-1/1" }} severity="warning">
              Para emitir cobranças é necessário fazer a verificação da conta.
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default ChargeForm;
