import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { createCompanyBankAccount } from "services/Requests/companies";

const useMutationCreateCompanyBankAccount = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createCompanyBankAccount, {
    onSuccess(_, { companyToken }) {
      toast.success("Conta criada com sucesso");
      queryClient.invalidateQueries([queryKeys.companyBankAccounts, companyToken]);
    },
  });
  return mutation;
};

export default useMutationCreateCompanyBankAccount;
