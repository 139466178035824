import { FC, useState } from "react";
import useQueryGetAllSubs from "../hooks/useQueryGetAllSubs";
import { useQueryClient } from "react-query";
import useDebounce from "hooks/useDebounce";

import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";

import SignatureTable from "./SignatureTable";

import { GetAllSubsPayload } from "services/Requests/marketplace";
import { DateTime } from "luxon";
import queryKeys from "react-query/constants";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

import { keyframes } from "@mui/system";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

type FilterStatus = GetAllSubsPayload["status"];

const Signatures: FC = () => {
  const queryClient = useQueryClient();

  const [searchSignature, setSearchSignature] = useState("");
  const search = useDebounce(searchSignature, 200);

  const [filterStatus, setFilterStatus] = useState<FilterStatus>("active");

  const {
    data: signatures,
    isLoading,
    isFetching,
  } = useQueryGetAllSubs(filterStatus, search || null);

  const handleInvalidateQueries = () => {
    queryClient.invalidateQueries(queryKeys.allSubs, {
      exact: false,
    });
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack alignItems="center" direction="row" gap={1}>
          <RadioGroup
            row
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value as FilterStatus)}
          >
            <FormControlLabel
              value="active"
              control={<Radio />}
              label={`Ativas${signatures ? " (" + signatures.facets.active.count + ")" : ""}`}
            />
            <FormControlLabel
              value="due"
              control={<Radio />}
              label={`Vencidas${signatures ? " (" + signatures.facets.due.count + ")" : ""}`}
            />
            <FormControlLabel
              value="suspended"
              control={<Radio />}
              label={`Inativas${signatures ? " (" + signatures.facets.suspended.count + ")" : ""}`}
            />
          </RadioGroup>

          <Tooltip title="Recarregar assinaturas">
            <IconButton onClick={handleInvalidateQueries} disabled={isFetching}>
              <RefreshIcon
                sx={{
                  animation: isFetching ? `${spin} 1s infinite ease` : "none",
                  transition: "all 300ms ease",
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>

        <TextField
          size="small"
          value={searchSignature}
          onChange={(e) => setSearchSignature(e.target.value)}
          InputProps={{
            placeholder: "Buscar",
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Divider sx={{ mt: 2, mb: 3 }} />

      {isLoading && (
        <Stack height={300} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {signatures && (
        <Box>
          <Collapse in={!isLoading && isFetching}>
            <LinearProgress />
          </Collapse>

          <Box mt={1}>
            <SignatureTable
              signature={signatures.items.map((item) => ({
                ...item,
                expiresDate: DateTime.fromISO(item.expires_at).toFormat("dd/MM/yyyy"),
                total: formatCurrencyBRL(
                  item.subitems.reduce((acc, subitem) => subitem.price_cents / 100 + acc, 0),
                ),
              }))}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Signatures;
