import { useMutation, useQueryClient } from "react-query";
import { updateDunning, UpdateDunningPayload } from "services/Requests/billing";

import getSubdomain from "utils/getSubdomain";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";

const useMutationUpdateDunning = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<UpdateDunningPayload, "subdomain">) => updateDunning({ ...payload, subdomain }),
    {
      onSuccess() {
        toast.success("Atualização realizada com sucesso");
        queryClient.invalidateQueries(queryKeys.dunning, { exact: false });
        queryClient.invalidateQueries(queryKeys.infoAccount, { exact: false });
      },
    },
  );
  return mutation;
};

export default useMutationUpdateDunning;
