import { FC } from "react";

import { Box, Typography } from "@mui/material";

import { ServicePlan } from "types/ServiceAndPlan";
import ContractTerms from "../../../components/ContractTerms";
import CalculatorPlan from "../../../components/CalculatorPlan";

interface ContractProps {
  downloadToken: string;
  onBack: () => void;
  onNext: () => void;
  plans: ServicePlan[];
}

const Contract: FC<ContractProps> = ({ onBack, onNext, plans, downloadToken }) => {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}
      >
        Plano de contabilidade online
      </Typography>

      <Typography variant="body1" sx={{ mb: 2 }}>
        Escolha o plano que irá melhor se encaixar com a sua empresa.
        <br /> Caso não tenha certeza, não se preocupe, podemos ajustar o plano mais tarde.
      </Typography>

      <Box mb={2}>
        <CalculatorPlan plans={plans} />
      </Box>

      <ContractTerms downloadToken={downloadToken} onBack={onBack} onNext={onNext} />
    </Box>
  );
};

export default Contract;
