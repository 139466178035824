import { FC } from "react";
import { useCompanyQueries } from "./hooks/useCompanyQueries";

import Transition from "components/Transition";
import getSubdomain from "../../utils/getSubdomain";
import { BUCKET_URL } from "../../utils/constants";
import { CompanyInfoContext } from ".";

import { createTheme, Stack, ThemeProvider, Typography } from "@mui/material";
import { Toaster } from "react-hot-toast";
import ErrorIcon from "@mui/icons-material/Error";
import AnimationLoading from "../../assets/AnimationLoading";
import { grey } from "@mui/material/colors";

interface CompanyInfoProviderProps {
  children: React.ReactNode;
}

export const CompanyInfoProvider: FC<CompanyInfoProviderProps> = ({ children }) => {
  const { companyStyles, pathImage, typeSubdomain } = useCompanyQueries();

  if (companyStyles.isLoading || pathImage.isLoading || typeSubdomain.isLoading) {
    return (
      <Stack
        alignItems="center" 
        justifyContent="center"
        sx={{ position: "fixed", top: 0, left: 0, zIndex: 999, height: "100vh", width: "100vw" }}
      >
        <AnimationLoading />
        <Typography variant="body1" align="center" sx={{ mt: -4 }}>
          Carregando...
        </Typography>
      </Stack>
    );
  }

  if (!companyStyles.data || !pathImage.data || !typeSubdomain.data) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ position: "fixed", top: 0, left: 0, zIndex: 999, height: "100vh", width: "100vw" }}
      >
        <ErrorIcon fontSize="large" sx={{ color: "#009221" }} />
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          Não conseguimos pegar os dados.
        </Typography>
      </Stack>
    );
  }

  const getTheme = () => {
    return createTheme({
      typography: {
        fontSize: 12,
      },
      palette: {
        primary: {
          main: companyStyles.data.buttonPrimaryBg,
        },
      },
      components: {
        MuiTypography: {
          styleOverrides: {
            body1: {
              color: companyStyles.data.bodyColor,
            },
            body2: {
              color: companyStyles.data.bodyColor,
            },
            h1: { color: companyStyles.data.h1Color },
            h2: { color: companyStyles.data.h1Color },
            h3: { color: companyStyles.data.h1Color },
            h4: { color: companyStyles.data.h4Color },
            h5: { color: companyStyles.data.h4Color },
            h6: { color: companyStyles.data.h4Color },
            subtitle1: { color: companyStyles.data.h4Color },
            subtitle2: { color: companyStyles.data.h4Color },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              color: companyStyles.data.linksColor,
              textDecorationColor: companyStyles.data.linksColor,
              "&:hover": {
                color: companyStyles.data.linksHoverColor,
                textDecorationColor: companyStyles.data.linksHoverColor,
              },
            },
          },
        },
        MuiDialog: {
          defaultProps: {
            TransitionComponent: Transition,
            scroll: "body",
          },
        },
        MuiDialogContent: {
          defaultProps: {
            dividers: true,
          },
        },
        MuiButton: {
          defaultProps: {
            disableElevation: true,
          },
          styleOverrides: {
            containedSecondary: {
              backgroundColor: companyStyles.data.buttonDefaultBg,
              color: companyStyles.data.buttonDefaultColor,
              border: "1px solid transparent",
              borderColor: companyStyles.data.buttonDefaultBorderColor,
              "&:disabled": {
                borderColor: "transparent",
              },
            },
            containedPrimary: {
              backgroundColor: companyStyles.data.buttonPrimaryBg,
              color: companyStyles.data.buttonPrimaryColor,
              border: "1px solid transparent",
              borderColor: companyStyles.data.buttonPrimaryBorderColor,
              "&:disabled": {
                borderColor: "transparent",
              },
            },
            outlinedPrimary: {
              border: "1px solid transparent",
              borderColor: companyStyles.data.buttonPrimaryBorderColor,
              "&:disabled": {
                borderColor: "transparent",
              },
            },
          },
        },
        MuiTooltip: {
          defaultProps: {
            placement: "top",
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              "@media print": {
                backgroundColor: "initial !important",
                color: grey[900],
                "*": {
                  padding: "0 !important",
                },
              },
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              "input.Mui-disabled": {
                background: grey[200],
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: companyStyles.data.formInputFocusBorderColor,
                },
              },
            },
          },
        },
      },
    });
  };

  return (
    <ThemeProvider theme={getTheme()}>
      <CompanyInfoContext.Provider
        value={{
          pathImage: pathImage.data.pathImage.value
            ? `${BUCKET_URL}/${pathImage.data.pathImage.value}`
            : null,
          imageTopDashboard: pathImage.data.imageTop.value
            ? `${BUCKET_URL}/${pathImage.data.imageTop.value}`
            : null,
          subdomain: getSubdomain(),
          companyStyles: companyStyles.data,
          typeSubdomain: typeSubdomain.data,
        }}
      >
        <Toaster
          position="top-center"
          reverseOrder={true}
          toastOptions={{
            style: {
              border: "1px solid " + companyStyles.data.buttonPrimaryBg,
              fontFamily: "Roboto, sans-serif",
            },
            duration: 4000,
          }}
        />
        {children}
      </CompanyInfoContext.Provider>
    </ThemeProvider>
  );
};
