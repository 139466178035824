import { FC, useMemo, PropsWithChildren } from "react";
import useQueryGetCompanyPartnerDetail from "../hooks/useQueryGetCompanyPartnerDetail";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import AddressConsult from "../../../components/AddressConsult";
import BankConsult from "../../../components/BankConsult";

import { CompanyPartner } from "types/Company";

const ListComponent: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <Stack
    component="li"
    sx={{ border: 1, borderColor: "divider" }}
    p={2}
    alignItems="center"
    justifyContent="space-between"
    direction="row"
  >
    {children}
  </Stack>
);

interface DialogConsultPartnerProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  partner: CompanyPartner;
}

const DialogConsultPartner: FC<DialogConsultPartnerProps> = ({ onClose, partner, ...props }) => {
  const { data: partnerDetail, isLoading } = useQueryGetCompanyPartnerDetail(partner);

  const basicData = useMemo(() => {
    const textForNoData = "N/D";
    if (!partnerDetail) return [];

    return [
      {
        title: "Nome",
        content: partnerDetail.name,
      },
      {
        title: "CPF / CNPJ",
        content: partnerDetail.cpfcnpj === "--" ? textForNoData : partnerDetail.cpfcnpj,
      },
      {
        title: "Telefone",
        content: partnerDetail.phoneAsString || textForNoData,
      },
      {
        title: "Email",
        content: partnerDetail.email || textForNoData,
      },
    ];
  }, [partnerDetail]);

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" height={300}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Dialog {...props} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={onClose}>Sócio</DialogTitleWithCloseButton>

      <DialogContent>
        <Typography variant="h6" fontWeight={400}>
          Dados Básicos
        </Typography>

        <Box component="ul" mt={1}>
          {basicData.map((data, index) => (
            <ListComponent key={index}>
              <>
                <Typography variant="body1" fontWeight={600}>
                  {data.title}
                </Typography>
                <Typography variant="body1">{data.content}</Typography>
              </>
            </ListComponent>
          ))}
        </Box>

        {partnerDetail && (
          <>
            <AddressConsult
              address={{ ...partnerDetail.address, countyAsString: partnerDetail.countyAsString }}
            />
            <BankConsult
              bank={{
                ...partnerDetail.bankAccount,
                bankNameAsString: partnerDetail.bankNameAsString,
              }}
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onClose} startIcon={<Close />}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConsultPartner;
