import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import {
  validateParticipantCPFCNPJ,
  ValidateParticipantCPFCNPJPayload,
} from "services/Requests/companies";
import { CompanyPartner } from "types/Company";

const useMutationValidateParticipantCPFCNPJ = (showToast = true) => {
  const mutation = useMutation<void, AxiosError<CompanyPartner>, ValidateParticipantCPFCNPJPayload>(
    validateParticipantCPFCNPJ,
    {
      onError() {
        if (showToast) toast.error("Este CNPJ ou CPF já está sendo utilizado");
      },
    },
  );
  return mutation;
};

export default useMutationValidateParticipantCPFCNPJ;
