import { FC, useState } from "react";
import useMutationToggleStatusSubscription from "../../Marketplace/components/Clients/hooks/useMutationToggleStatusSubscription";

import {
  IconButton,
  TableCell,
  TableRow,
  Menu as DropdownMenu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NormalizeSignature } from "types/Marketplace";
import { useAuth } from "context/Auth";
import { LoadingButton } from "@mui/lab";

interface SignatureTableItemProps {
  signature: NormalizeSignature;
}

const SignatureTableItem: FC<SignatureTableItemProps> = ({ signature }) => {
  const {
    user: { company },
  } = useAuth();
  const { mutateAsync, isLoading } = useMutationToggleStatusSubscription();

  const [inactivateConfirmation, setInactivateConfirmation] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  const inactivateSignature = async () => {
    if (!company) return;

    await mutateAsync({
      action: "deactivate",
      companyToken: company.token,
      id: signature.id,
      payerCompanyId: 1,
    });
    setInactivateConfirmation(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>{signature.customer_name}</TableCell>
        <TableCell>{signature.plan_name}</TableCell>
        <TableCell>{signature.expiresDate}</TableCell>
        <TableCell>{signature.total}</TableCell>
        <TableCell align="right">
          {!signature.suspended && (
            <IconButton size="small" onClick={(e) => setAnchorElMenu(e.currentTarget)}>
              <MenuIcon fontSize="small" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      <DropdownMenu
        anchorEl={anchorElMenu}
        open={menuIsOpen}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => setInactivateConfirmation(true)} disabled={!company}>
          Inativar assinatura
        </MenuItem>
      </DropdownMenu>

      <Dialog
        open={inactivateConfirmation}
        onClose={() => setInactivateConfirmation(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Suspender assinatura</DialogTitle>

        <DialogContent>
          Deseja suspender a assinatura de &quot;{signature.customer_name}&quot;?
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" disabled={isLoading}>
            Não
          </Button>
          <LoadingButton loading={isLoading} variant="contained" onClick={inactivateSignature}>
            Sim
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SignatureTableItem;
