import { FC } from "react";
import { useAuth } from "context/Auth";

import { Box, Typography } from "@mui/material";

import Head from "components/Head";
import InvoicesIssued from "pages/Home/components/InvoicesIssued";

import { MESSAGE_NO_COMPANY } from "utils/constants";
import getSubdomain from "utils/getSubdomain";

const NFSe: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <>
      <Head title={`Notas Fiscais | ${getSubdomain()}`} />

      <Typography variant="h4" fontWeight="bold" sx={{ mr: 1 }}>
        Notas Fiscais
      </Typography>

      <Box boxShadow={1} p={2} mt={2}>
        {company ? (
          <InvoicesIssued companyToken={company.token} issueInvoice={false} />
        ) : (
          <Typography variant="body2">{MESSAGE_NO_COMPANY}</Typography>
        )}
      </Box>
    </>
  );
};

export default NFSe;
