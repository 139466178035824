import { useCallback, useMemo } from "react";
import { ServicePlan } from "types/ServiceAndPlan";

interface Regime {
  id: number;
  name: string;
  active: boolean;
  tabName: string;
}

interface CalculatorPlanParam {
  plans: ServicePlan[] | ServicePlan;
  accountabilityType: string;
  regimeType: "1" | "2" | "3";
  selectedInvoicing: string;
  employees: number;
  prolabore: number;
}

const useCalculatorPlan = ({
  plans,
  accountabilityType = "",
  regimeType,
  employees = 0,
  prolabore = 0,
  selectedInvoicing = "",
}: CalculatorPlanParam) => {
  const defaultPlan = useMemo(() => {
    if (!Array.isArray(plans)) return plans;

    return plans.find((plan) => {
      if (accountabilityType === "service" && plan.typeIdentifier === "accountability-default") {
        return true;
      }

      if (accountabilityType === "business" && plan.typeIdentifier === "commerce-default") {
        return true;
      }

      return false;
    });
  }, [accountabilityType, plans]);

  const activesRegimes = useMemo<Regime[]>(() => {
    if (!defaultPlan) {
      return [];
    }

    const regimes: Regime[] = [
      {
        id: 1,
        name: "NationalSimple",
        active: false,
        tabName: "Simples Nacional",
      },
      {
        id: 2,
        name: "PresumedProfit",
        active: false,
        tabName: "Lucro Presumido",
      },
      {
        id: 3,
        name: "MEI",
        active: false,
        tabName: "MEI",
      },
    ];

    if (defaultPlan) {
      const { features } = defaultPlan;
      for (const feature of features) {
        const regime = regimes.find((regime) => regime.name === feature.name);
        if (regime && feature.active && !regime.active) {
          regime.active = true;
        }
      }
    }

    return regimes.filter((regime) => regime.active);
  }, [defaultPlan]);

  const formatFeatureValue = useCallback((value: string) => {
    const splittedValue = value.split(":");

    const formatValue = (value: string) =>
      (Number(value) / 100).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });

    if (splittedValue[1] === "0" || splittedValue[1] === "000") {
      return `acima de ${formatValue(splittedValue[0])}/mês`;
    }

    return `de ${formatValue(splittedValue[0])} a ${formatValue(splittedValue[1])}/mês`;
  }, []);

  const invoicingByRegime = useMemo(() => {
    const features = defaultPlan?.features;

    if (!features || !regimeType) return [];

    const featuresByRegime = features.filter((feature) => {
      if (regimeType === "1" && feature.name === "NationalSimple") return true;
      if (regimeType === "2" && feature.name === "PresumedProfit") return true;
      if (regimeType === "3" && feature.name === "MEI") return true;
      return false;
    });

    return featuresByRegime.reduce(
      (acc, feature) => ({
        ...acc,
        [feature.value]: formatFeatureValue(feature.value),
      }),
      {} as Record<string, string>,
    );
  }, [regimeType, defaultPlan, formatFeatureValue]);

  const monthlyPayment = useMemo(() => {
    const additionalEmployees =
      Number(defaultPlan?.features.find((feature) => feature.name === "Payroll")?.value ?? 100) /
      100;
    const additionalProlabore =
      Number(defaultPlan?.features.find((feature) => feature.name === "Prolabore")?.value ?? 100) /
      100;

    const monthly = Number(selectedInvoicing.split(":")?.[2] ?? 0) / 100;

    const totalEmployees = employees >= 0 ? employees * additionalEmployees : 0;
    const totalProlabore = prolabore >= 0 ? prolabore * additionalProlabore : 0;

    return monthly + totalEmployees + totalProlabore;
  }, [defaultPlan, selectedInvoicing, employees, prolabore]);

  return { invoicingByRegime, monthlyPayment, defaultPlan, activesRegimes };
};

export default useCalculatorPlan;
