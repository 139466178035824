import { FinancialCategory, TransactionsItem } from "types/Company";

const filterByCategory = (
  transactionsItems: TransactionsItem[],
  categoryFilter: FinancialCategory,
) => {
  const getAllCategoriesIds = (categories: FinancialCategory[]): number[] => {
    return categories
      .map((c) => [c.id, ...getAllCategoriesIds(c.children)])
      .flat(Infinity) as number[];
  };

  const categoriesIds = getAllCategoriesIds([categoryFilter]);

  const newTransactionsItems = transactionsItems.filter((transaction) =>
    categoriesIds.includes(transaction.globalCategoryId),
  );

  return newTransactionsItems;
};

export default filterByCategory;
