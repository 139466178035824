import { FC } from "react";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";

import { CircularProgress, Stack, Typography } from "@mui/material";
import CompanyForm from "./CompanyForm";

interface CompanyProps {
  companyToken: string;
  onNext: () => void;
  onPrev: () => void;
}

const Company: FC<CompanyProps> = ({ companyToken, onNext, onPrev }) => {
  const { data: company, isLoading, isError } = useQueryGetCompany(companyToken);

  return (
    <>
      {isLoading && (
        <Stack height={500} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}

      {isError && !isLoading && (
        <Typography>Ocorreu um erro ao carregar os dados, tente novamente mais tarde</Typography>
      )}

      {!isLoading && !isError && company && (
        <CompanyForm company={company} onNext={onNext} onPrev={onPrev} />
      )}
    </>
  );
};

export default Company;
