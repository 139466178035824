import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import ListComponent from "components/ListComponent";
import { DateTime } from "luxon";
import { FC } from "react";
import { BASE_URL_DOWNLOAD_DOCUMENT } from "utils/constants";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import useQueryGetTransaction from "../../MonthClosure/hooks/useQueryGetTransaction";

interface DialogConsultTransactionProps extends Omit<DialogProps, "onClose" | "id"> {
  id: number;
  companyToken: string;
  onClose: () => void;
}

const DialogConsultTransaction: FC<DialogConsultTransactionProps> = ({
  id,
  companyToken,
  onClose,
  ...props
}) => {
  const { data: transaction, isLoading } = useQueryGetTransaction(companyToken, id);

  return (
    <Dialog {...props} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {transaction?.description ? transaction.description : "Detalhes da nota"}
      </DialogTitle>

      <DialogContent>
        {isLoading && (
          <Stack height={300} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {transaction && (
          <Box component="ul">
            <ListComponent>
              <Typography fontWeight={600}>Tipo de lançamento</Typography>
              <Typography>
                {transaction.transactionType === "Credit" ? "Crédito" : "Débito"}
              </Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Valor</Typography>
              <Typography>{formatCurrencyBRL(transaction.value)}</Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Emitida em</Typography>
              <Typography>
                {transaction.dateEmission
                  ? DateTime.fromISO(transaction.dateEmission).toFormat("dd/MM/yyyy")
                  : "N/D"}
              </Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Emitida em</Typography>
              <Typography>
                {transaction.dateEmission
                  ? DateTime.fromISO(transaction.dateEmission).toFormat("dd/MM/yyyy")
                  : "N/D"}
              </Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Vence em</Typography>
              <Typography>
                {transaction.dueDate
                  ? DateTime.fromISO(transaction.dueDate).toFormat("dd/MM/yyyy")
                  : "N/D"}
              </Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Data de competência</Typography>
              <Typography>
                {transaction.referenceDate
                  ? DateTime.fromISO(transaction.referenceDate).toFormat("dd/MM/yyyy")
                  : "N/D"}
              </Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Pago em</Typography>
              <Typography>
                {transaction.paymentDate
                  ? DateTime.fromISO(transaction.paymentDate).toFormat("dd/MM/yyyy")
                  : "N/D"}
              </Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>Categoria</Typography>
              <Typography>{transaction.categoryName || "N/D"}</Typography>
            </ListComponent>
            <ListComponent>
              <Typography fontWeight={600}>
                {transaction.transactionType == "Credit" ? "Cliente" : "Fornecedor"}
              </Typography>
              <Typography>{transaction.participantName || "N/D"}</Typography>
            </ListComponent>

            {transaction.documents.length > 0 && (
              <ListComponent>
                <Typography fontWeight={600}>Comprovante(s)</Typography>
                <Stack alignItems="center" gap={0.5}>
                  {transaction.documents.map((document) => (
                    <Link
                      key={document.downloadToken}
                      href={`${BASE_URL_DOWNLOAD_DOCUMENT}/?id=${document.downloadToken}&fullPath=/COMPROVANTES+DE+PAGAMENTO&preview=true`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {document.name}
                    </Link>
                  ))}
                </Stack>
              </ListComponent>
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" startIcon={<CloseIcon />}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConsultTransaction;
