import { FC, useState } from "react";
import { useAuth } from "context/Auth";

import Add from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";

import Head from "components/Head";
import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import DialogCreateUpdateCompanyParticipant from "pages/Home/components/DialogCreateUpdateCompanyParticipant";
import RegistryClientsList from "pages/Home/components/RegistryClientsList";

const RegistrationClients: FC = () => {
  const {
    user: { company },
  } = useAuth();

  const [createIsOpen, setCreateIsOpen] = useState(false);

  return (
    <>
      <Head title={`Clientes | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Clientes
        </Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<Add fontSize="small" />}
          onClick={() => setCreateIsOpen(true)}
          disabled={!company}
        >
          Novo cliente
        </Button>
      </Stack>

      <Box boxShadow={1} p={2} mt={2}>
        {company ? (
          <RegistryClientsList companyToken={company.token} role="client" />
        ) : (
          <Typography variant="body2">{MESSAGE_NO_COMPANY}</Typography>
        )}
      </Box>

      {company && (
        <DialogCreateUpdateCompanyParticipant
          keepMounted={false}
          open={createIsOpen}
          onClose={() => setCreateIsOpen(false)}
          companyToken={company.token}
          role="client"
        />
      )}
    </>
  );
};

export default RegistrationClients;
