import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getUsersAdm } from "services/Requests/admin";

const useQueryGetUsersAdm = () => {
  const query = useQuery(queryKeys.usersAdm, getUsersAdm);
  return query;
};

export default useQueryGetUsersAdm;
