import { FC, memo, useState } from "react";
import { useClientDetails } from "../../../hooks/useClientDetails";
import { useClientInformation } from "../../../context/ClientInformation";

import { grey } from "@mui/material/colors";
import {
  Skeleton,
  Stack,
  Typography,
  Button,
  Collapse,
  Link,
  Box,
  CircularProgress,
} from "@mui/material";
import SignatureForm from "./SignatureForm";
import SubscriptionsTable from "./SubscriptionsTable";

interface SubscriptionsProps {
  withPlan?: boolean;
}

const Subscriptions: FC<SubscriptionsProps> = ({ withPlan = true }) => {
  const clientInformation = useClientInformation();
  const [showFormSignature, setShowFormSignature] = useState(false);

  const { data, isLoading, isError, isFetching } = useClientDetails(
    clientInformation.payerCompanyId,
    clientInformation.ownerId,
  );

  return (
    <Box position="relative">
      {isFetching && !isLoading && (
        <Box position="absolute" top={0} right={0} zIndex={5}>
          <CircularProgress size={15} />
        </Box>
      )}

      <Stack width="100%" gap={0.5} p={2} bgcolor={grey[200]}>
        {isLoading && (
          <>
            <Skeleton variant="text" height={25} width="90%" />
            <Skeleton variant="text" height={25} width="60%" />
            <Skeleton variant="text" height={25} width="80%" />
            <Skeleton variant="text" height={25} width="40%" />
          </>
        )}

        {data && (
          <>
            <Typography variant="body1">
              <strong>Responsável: </strong>
              {clientInformation.owner}
            </Typography>
            <Typography variant="body1">
              <strong>Telefone: </strong>
              <Link href={"tel:" + clientInformation.phone} target="_blank" color={grey[900]}>
                {clientInformation.phone}
              </Link>
            </Typography>
            <Typography variant="body1">
              <strong>Email: </strong>
              <Link
                href={"mailto:" + clientInformation.ownerEmail}
                target="_blank"
                color={grey[900]}
              >
                {clientInformation.ownerEmail}
              </Link>
            </Typography>
            <Typography variant="body1">
              <strong>Forma de pagamento escolhida: </strong>
              {data.paymentMethod.paymentMethod}
            </Typography>
          </>
        )}

        {isError && (
          <Typography variant="h6" align="center">
            Ocorreu um erro ao tentar acessar os detalhes do cliente, tente novamente mais tarde!
          </Typography>
        )}
      </Stack>

      {data && data.subscriptionList?.items && (
        <SubscriptionsTable subscriptionList={data.subscriptionList.items} />
      )}

      <Collapse in={showFormSignature} mountOnEnter unmountOnExit>
        <SignatureForm withPlan={withPlan} />
      </Collapse>

      {!isError && data && (
        <Stack alignItems="flex-end" justifyContent="flex-end" mt={1}>
          <Button
            variant="contained"
            disableElevation
            size="small"
            onClick={() => setShowFormSignature((prev) => !prev)}
          >
            {showFormSignature ? "Cancelar" : "Adicionar Assinatura"}
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default memo(Subscriptions);
