import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { moveReleasesFinancial } from "services/Requests/accountant";

const useMutationMoveReleasesFinancial = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(moveReleasesFinancial, {
    onSuccess(_, { companyToken }) {
      toast.success("Lançamentos transferidos com sucesso!");
      queryClient.invalidateQueries([queryKeys.clientChartOfAccounts, companyToken]);
      queryClient.invalidateQueries([queryKeys.categoriesWithReleases, companyToken]);
    },
  });
  return mutation;
};

export default useMutationMoveReleasesFinancial;
