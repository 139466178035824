import { FC } from "react";

import { Chip, TableCell, TableRow } from "@mui/material";

import { DateTime } from "luxon";
import { TransactionsItem } from "types/Company";
import { ISODate } from "types/SemanticTypes";

import formatCurrencyBRL from "utils/formatCurrencyBRL";
import getLabelStatus from "pages/Home/utils/getLabelStatus";
import getStatusColor from "pages/Home/utils/getStatusColor";

interface BillsToPayTableItemProps {
  transactionItem: TransactionsItem;
}

const BillsToPayTableItem: FC<BillsToPayTableItemProps> = ({ transactionItem }) => {
  const formatDate = (ISODate: ISODate) => {
    return DateTime.fromISO(ISODate).toFormat("dd/MM/yyyy");
  };

  return (
    <TableRow hover>
      <TableCell>{transactionItem.dueDate ? formatDate(transactionItem.dueDate) : "-"}</TableCell>
      <TableCell>
        {transactionItem.referenceDate ? formatDate(transactionItem.referenceDate) : "-"}
      </TableCell>
      <TableCell>{transactionItem.participantName || "-"}</TableCell>
      <TableCell>{transactionItem.description || "-"}</TableCell>
      <TableCell>{transactionItem.categoryName || "-"}</TableCell>
      <TableCell>
        <Chip
          size="small"
          label={getLabelStatus(transactionItem)}
          color={getStatusColor(transactionItem)}
        />
      </TableCell>
      <TableCell align="right" data-a-h="right">
        {formatCurrencyBRL(transactionItem.value)}
      </TableCell>
    </TableRow>
  );
};

export default BillsToPayTableItem;
