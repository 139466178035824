import { FC, useState } from "react";
import useQueryCompanyChartOfAccountsFromTo from "pages/Home/hooks/useQueryGetChartOfAccountsFromToGroup";

import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import SearchInput from "components/SearchInput";
import AccountingItem from "./AccountingItem";
import CreateOrEditAccount from "pages/Home/pages/AccountingItem/components/CreateOrEditAccount";

interface AccountingProps {
  companyToken: string;
}

const Accounting: FC<AccountingProps> = ({ companyToken }) => {
  const [search, setSearch] = useState("");
  const {
    data: accounts,
    isLoading,
    isError,
    isFetching,
  } = useQueryCompanyChartOfAccountsFromTo(companyToken, search);

  const [createAccountIsOpen, setCreateAccountIsOpen] = useState(false);

  return (
    <>
      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {isError && <Typography>Ocorreu um erro ao tentar obter os dados.</Typography>}

      {!isLoading && !isError && accounts && (
        <Box>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Button
              onClick={() => setCreateAccountIsOpen(true)}
              variant="contained"
              startIcon={<AddIcon fontSize="small" />}
              size="small"
            >
              Adicionar nova conta
            </Button>

            {isFetching && <CircularProgress size={18} />}
          </Stack>

          <SearchInput
            fullWidth
            size="small"
            value={search}
            onChange={(search) => setSearch(search)}
          />

          <TableContainer sx={{ mt: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Tipo da Conta</TableCell>
                  <TableCell>Conta Contábil</TableCell>
                  <TableCell>Conta Reduzida</TableCell>
                  <TableCell>Descrição da Conta</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>

              <TableBody>
                {accounts.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>Nenhuma conta encontrada.</TableCell>
                  </TableRow>
                )}

                {accounts.map((account) => (
                  <AccountingItem key={account.id} account={account} companyToken={companyToken} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {createAccountIsOpen && (
        <CreateOrEditAccount
          onClose={() => setCreateAccountIsOpen(false)}
          open={createAccountIsOpen}
          isCompany
          companyToken={companyToken}
        />
      )}
    </>
  );
};

export default Accounting;
