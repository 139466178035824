import { useContext } from "react";
import { FinancialCategoryIdContext } from ".";

export const useFinancialCategoryId = () => {
  const ctx = useContext(FinancialCategoryIdContext);

  if (!ctx) {
    throw new Error("useFinancialCategoryId must be used within a FinancialCategoryIdProvider");
  }

  return ctx;
};
