import { FC } from "react";
import { Box, BoxProps } from "@mui/material";

export interface TabPanelProps extends BoxProps {
  index: number | string;
  value: number | string;
  keepMounted?: boolean;
  children?: React.ReactNode;
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, keepMounted = false, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      <Box
        {...other}
        sx={{ p: 3, ...other?.sx }}
        display={value === index ? props?.display || "block" : "none"}
      >
        {value === index || keepMounted ? children : null}
      </Box>
    </div>
  );
};

export default TabPanel;
