import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCompanyGroups } from "services/Requests/mailbox";
import getSubdomain from "utils/getSubdomain";

const useQueryCompanyGroups = () => {
  const subdomain = getSubdomain();

  const query = useQuery(queryKeys.companyGroups, () => getCompanyGroups({ subdomain }));
  return query;
};

export default useQueryCompanyGroups;
