import endpoints from "../endpoints";
import api from "../api";
import { ClientStatus } from "types/Client";
import { UserAdmin } from "types/User";
import { TypesOfUsers } from "types/UserTypes";
import { CompanyAssignmentsResponse, UserCompany, UsersCompanies } from "types/Admin";

/* eslint-disable camelcase */

export interface ChangeDocumentStatusPayload {
  companyToken: string;
  payerCompanyId: number;
  status: "Em aprovação" | "Não anexado" | "Aprovado" | "Reenviar";
  type: "CS" | "IS" | "EF";
}

export const changeDocumentStatus = async (payload: ChangeDocumentStatusPayload) => {
  await api.post(endpoints.admin.changeDocumentStatus, payload);
};

interface UpdateCompanyRegistrationStatusPayload {
  companyId: number;
  status: ClientStatus;
}

export const updateCompanyRegistrationStatus = async (
  payload: UpdateCompanyRegistrationStatusPayload,
) => {
  const statusResolver: { [x in ClientStatus]: number } = {
    Approved: 1,
    Registered: 1,
    Prospect: 1,
    Suspended: 0,
  };

  const registrationStatusResolver: { [x in ClientStatus]: number } = {
    Approved: 3,
    Registered: 3,
    Prospect: 3,
    Suspended: 2,
  };

  await api.post(endpoints.admin.updateCompanyRegistrationStatus, {
    ...payload,
    status: statusResolver[payload.status],
    registrationStatus: registrationStatusResolver[payload.status],
  });
};

export interface GetUsersAdmListPayload {
  companyToken: string;
}

export const getUsersAdmList = async (payload: GetUsersAdmListPayload): Promise<UserAdmin[]> => {
  const { data } = await api.post<UserAdmin[]>(endpoints.admin.usersAdmList, payload);
  return data;
};

export const getUsersAdm = async (): Promise<UserAdmin[]> => {
  const { data } = await api.post<UserAdmin[]>(endpoints.admin.usersAdm);
  return data;
};

export interface AddUserPayload {
  companyToken: string;
  email: string;
  fullControl?: 0 | 1;
  login: string;
  name: string;
  profileId?: number;
  subdomain: string;
  userTypes: TypesOfUsers;
}

export const addUser = async (payload: AddUserPayload): Promise<void> => {
  await api.post(endpoints.admin.addUser, payload);
};

export interface UpdateUserPayload extends Omit<UserAdmin, "profileId" | "userType"> {
  companyToken: string;
  fullControl?: 0 | 1;
  subdomain: string;
  profileId: number | null;
  userType: "Admin" | "Accountant" | "Guest";
}

export const updateUser = async (payload: UpdateUserPayload) => {
  await api.post(endpoints.admin.updateUser, payload);
};

type DeleteUserPayload = Partial<UserAdmin> | Partial<UserCompany>;

export const deleteUser = async (payload: DeleteUserPayload) => {
  await api.post(endpoints.admin.deleteUser, payload);
};

export type BlockUserPayload = Partial<UserAdmin> | Partial<UserCompany>;

export const blockUser = async (payload: BlockUserPayload) => {
  await api.post(endpoints.admin.blockUser, payload);
};

export type UnBlockUserPayload = Partial<UserAdmin> | Partial<UserCompany>;

export const unBlockUser = async (payload: UnBlockUserPayload) => {
  await api.post(endpoints.admin.unBlockUser, payload);
};

export interface SendPasswordPayload extends Partial<UserCompany>, Partial<UserAdmin> {
  companyToken: string;
}

export const sendPasswordUser = async (payload: SendPasswordPayload) => {
  await api.post(endpoints.admin.sendPasswordUser, payload);
};

export interface GetCompanyAssignmentsPayload {
  registrationStatus: number;
  status: number;
}

export const getCompanyAssignments = async (
  payload: GetCompanyAssignmentsPayload,
): Promise<CompanyAssignmentsResponse> => {
  const { data } = await api.post<CompanyAssignmentsResponse>(
    endpoints.admin.getCompanyAssignments,
    payload,
  );
  return data;
};

export interface GetUsersCompaniesPayload {
  companyToken: string;
}

export const getUsersCompanies = async (
  paylaod: GetUsersCompaniesPayload,
): Promise<UsersCompanies> => {
  const { data } = await api.post<UsersCompanies>(endpoints.admin.getUsersCompanies, paylaod);
  return data;
};

export interface AssignAccountantPayload {
  accountantId: number;
  companyId: number;
}

export const assignAccountant = async (payload: AssignAccountantPayload) => {
  await api.post(endpoints.admin.assignAccountant, payload);
};

export interface UpdateCompanyActivatePayload {
  companyId: number;
  status: number;
}

export const updateCompanyActivate = async (payload: UpdateCompanyActivatePayload) => {
  await api.post(endpoints.admin.updateCompanyActivate, payload);
};

export interface DeleteCompanyPayload {
  companyToken: string;
}

export const deleteCompany = async (payload: DeleteCompanyPayload) => {
  await api.post(endpoints.admin.deleteCompany, payload);
};

export interface GeneratePassDefaultPayload {
  companyId: number;
}

export const generatePassDefault = async (payload: GeneratePassDefaultPayload) => {
  await api.post(endpoints.admin.generatePassDefault, payload);
};

export interface UpdateCompanyStatusPayload {
  companyId: number;
  status: -1 | 0;
}

export const updateCompanyStatus = async (payload: UpdateCompanyStatusPayload) => {
  await api.post(endpoints.admin.updateCompanyStatus, payload);
};
