import { useCallback } from "react";
import { useQuery } from "react-query";

import queryKeys from "react-query/constants";
import { getAllTransactions } from "services/Requests/billing";
import { AnticipationResponse } from "types/Billing";

import getSubdomain from "utils/getSubdomain";
import searchString from "utils/searchString";

const useQueryAllTransaction = (companyToken: string, search = "", maxResults = 100) => {
  const subdomain = getSubdomain();

  const select = useCallback(
    (data: AnticipationResponse) => {
      if (!search.trim()) return data;

      const items = data.items.filter((item) => {
        return searchString(item.description, search) || searchString(item.customerRef, search);
      });
      return {
        ...data,
        items,
      };
    },
    [search],
  );

  const query = useQuery(
    [queryKeys.allTransactions, maxResults],
    () =>
      getAllTransactions({
        companyToken,
        subdomain,
        maxResults,
      }),
    {
      select,
    },
  );
  return query;
};

export default useQueryAllTransaction;
