import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { prefectureByCompany } from "services/Requests/invoice";

const useQueryPrefectureByCompany = (companyToken: string) => {
  const query = useQuery(
    queryKeys.prefectureByCompany,
    () => prefectureByCompany({ companyToken }),
    {
      staleTime: 1000 * 60 * 20, // 20 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      retry: 0,
      refetchOnWindowFocus: false,
    },
  );
  return query;
};

export default useQueryPrefectureByCompany;
