import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { createTransactionAccountant } from "services/Requests/companies";

const useMutationCreateTransactionAccountant = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createTransactionAccountant, {
    onSuccess: () => {
      toast.success("Nota fiscal criada com sucesso!");
      queryClient.invalidateQueries(queryKeys.invoiceConfigStockControl, { exact: false });
      queryClient.invalidateQueries(queryKeys.invoices, { exact: false });
    },
  });
  return mutation;
};

export default useMutationCreateTransactionAccountant;
