import { FC } from "react";

import { DialogProps } from "@mui/material";
import { Service, ServicePlan, ServiceTypes } from "types/ServiceAndPlan";

import DialogPremiumOpening from "./DialogPremiumOpening";
import DialogFreeOpening from "./DialogFreeOpening";
import DialogPlanOpening from "./DialogPlanOpening";
import DialogPFOpening from "./DialogPFOpening";

const isService = (obj: Service | ServicePlan): obj is Service => {
  return "premiumPrices" in obj;
};

export interface ServiceDialogProps extends Omit<DialogProps, "onClose"> {
  service: Service;
  onClose: () => void;
  companyToken: string;
}
export interface ServicePlanDialogProps extends Omit<DialogProps, "onClose"> {
  service: ServicePlan;
  onClose: () => void;
  companyToken: string;
}
interface ServiceControllerProps extends Omit<DialogProps, "onClose"> {
  service: ServicePlan | Service;
  onClose: () => void;
  companyToken: string;
}

const serviceDialogs: Record<ServiceTypes, FC<ServiceDialogProps>> = {
  Premium: DialogPremiumOpening,
  OpeningPremium: DialogPremiumOpening,
  FindAccountant: DialogFreeOpening,
  Freemium: DialogFreeOpening,
  OpeningFree: DialogFreeOpening,
  Others: DialogFreeOpening,
};

const ServiceController: FC<ServiceControllerProps> = ({ service, ...props }) => {
  if (isService(service)) {
    const Component = serviceDialogs[service.serviceType];
    return <Component service={service} {...props} />;
  }

  if (service.typeIdentifier === "pf-default") {
    return <DialogPFOpening service={service} {...props} />;
  }

  return <DialogPlanOpening service={service} {...props} />;
};

export default ServiceController;
