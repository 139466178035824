import { useMutation, useQueryClient } from "react-query";
import useInvalidateDashboardQueries from "pages/Home/pages/Dashboard/hooks/useInvalidateDashboardQueries";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { cancelBillByAccountant } from "services/Requests/billing";

const useMutationCancelBillByAccountant = () => {
  const queryClient = useQueryClient();
  const invalidate = useInvalidateDashboardQueries();

  const mutation = useMutation(cancelBillByAccountant, {
    onSuccess(_, { id }) {
      toast.success(
        "Solicitação para alteração da fatura registrada com sucesso! Por favor aguarde um pouco para a solicitação ser processada.",
      );
      invalidate();
      queryClient.invalidateQueries(queryKeys.usersBills, {
        exact: false,
      });
      queryClient.invalidateQueries(queryKeys.transactions, {
        exact: false,
      });
      queryClient.invalidateQueries([queryKeys.transaction, Number(id)]);
    },
  });
  return mutation;
};

export default useMutationCancelBillByAccountant;
