import { useMutation, useQueryClient } from "react-query";
import useInvalidateDashboardQueries from "../pages/Dashboard/hooks/useInvalidateDashboardQueries";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { mergeDeleteTransaction } from "services/Requests/companies";

const useMutationDeleteTransaction = () => {
  const queryClient = useQueryClient();
  const invalidate = useInvalidateDashboardQueries();

  const mutation = useMutation(mergeDeleteTransaction, {
    onSuccess(_, { id, ids }) {
      toast.success("Lançamento excluído com sucessso");
      invalidate();
      queryClient.invalidateQueries(queryKeys.transactions, { exact: false });
      queryClient.invalidateQueries(queryKeys.invoices, { exact: false });
      queryClient.invalidateQueries(queryKeys.invoiceConfigStockControl, { exact: false });

      if (id) queryClient.invalidateQueries([queryKeys.transaction, id], { exact: false });
      if (ids) {
        ids.forEach((id) => {
          queryClient.invalidateQueries([queryKeys.transaction, id], { exact: false });
        });
      }
    },
  });
  return mutation;
};

export default useMutationDeleteTransaction;
