import endpoints from "../endpoints";
import api from "../api";
import { AllNotification, Unpresented } from "types/Notifications";

export interface GetAllNotificationsPayload {
  companyToken: string;
}

export const getAllNotifications = async (
  payload: GetAllNotificationsPayload,
): Promise<AllNotification[]> => {
  const { data } = await api.post<AllNotification[]>(
    endpoints.notifications.getAllNotifications,
    payload,
  );
  return data;
};

export const getUnpresented = async (): Promise<Unpresented[]> => {
  const { data } = await api.get<Unpresented[]>(endpoints.notifications.getUnpresented);
  return data;
};

export const markAsPresented = async () => {
  await api.post(endpoints.notifications.markAsPresented);
};

export interface GetAllNotificationsToAccountantPayload {
  companyToken: string;
}

export const getAllNotificationsToAccountant = async (
  payload: GetAllNotificationsToAccountantPayload,
): Promise<AllNotification[]> => {
  const { data } = await api.post<AllNotification[]>(
    endpoints.notifications.getAllNotificationsToAccountant,
    payload,
  );
  return data;
};

interface MarkAsPresentedToAccountantPayload {
  companyToken: string;
}

export const markAsPresentedToAccountant = async (payload: MarkAsPresentedToAccountantPayload) => {
  await api.post(endpoints.notifications.markAsPresentedToAccountant, payload);
};
