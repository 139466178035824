import { FC, useState } from "react";
import useMutationAssignAccountant from "../../hooks/useMutationAssignAccountant";
import useQueryLegals from "../../hooks/useQueryLegals";

import { CircularProgress, IconButton, MenuItem, Select, TableCell, TableRow } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DropdownMenu from "components/DropdownMenu";

import { DateTime } from "luxon";
import { Order, OrderState, TypeServiceOrder } from "types/OpenCompany";
import DialogDeleteOrder from "./components/DialogDeleteOrder";
import DialogChangeStateOrder from "./components/DialogChangeStateOrder";
import DialogOpenCompany from "pages/Home/components/DialogOpenCompany";

export const getCompanyType = (companyType: string | null) =>
  companyType === "IndividualEntrepreneur" ? "Empresário Individual" : companyType || "";

export const translateTypeService: Record<TypeServiceOrder, string> = {
  0: "Abertura de Empresas - Premium",
  1: "Abertura de Empresas - Faça você mesmo",
  2: "Contabilidade",
};

export const translateOrderState: { [x in OrderState]: string } = {
  0: "Em análise",
  1: "Aguard. Pagamento",
  2: "Aguard. Documentação",
  3: "Aprov. Contrato Social",
  4: "Entrega da Documentação",
  5: "Documentação para Assinatura",
  6: "Documentação Recebida",
  7: "Cadastro em Órgãos Públicos",
  8: "CNPJ Aprovado",
  9: "Alvará e Inscrição Municipal",
  10: "Processo Concluido",
  11: "Finalizado",
  12: "Pendente",
};

interface OrdersTableItemProps {
  order: Order;
}

const OrdersTableItem: FC<OrdersTableItemProps> = ({ order }) => {
  const { mutate, isLoading: mutateLoading } = useMutationAssignAccountant();

  const { data: legals, isLoading } = useQueryLegals();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [accountantId, setAccountantId] = useState(order.accountant.id);
  const [isDelete, setIsDelete] = useState(false);
  const [isChangeState, setIsChangeState] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const handleAssignAccountant = () => {
    mutate({
      accountantId,
      companyId: order.companyId,
    });
  };

  return (
    <>
      <TableRow
        hover
        selected={!!anchorEl}
        sx={{
          opacity: mutateLoading ? 0.5 : 1,
          pointerEvents: mutateLoading ? "none" : "all",
        }}
      >
        <TableCell>{DateTime.fromISO(order.createdOn).toFormat("dd/MM/yyyy")}</TableCell>
        <TableCell>{translateTypeService[order.typeService] || ""}</TableCell>
        <TableCell sx={{ cursor: "pointer" }} onClick={() => setShowDetails(true)}>
          {order.companyName}
        </TableCell>
        <TableCell>{getCompanyType(order.companyType)}</TableCell>
        <TableCell>{translateOrderState[order.orderState]}</TableCell>
        <TableCell>
          <Select
            value={legals ? accountantId || 0 : 0}
            onChange={(e) => setAccountantId(Number(e.target.value))}
            size="small"
            sx={{ maxWidth: 150 }}
            color={accountantId !== order.accountant.id ? "warning" : undefined}
            endAdornment={
              isLoading ? (
                <CircularProgress sx={{ position: "relative", left: "-20px" }} size={20} />
              ) : undefined
            }
          >
            <MenuItem value={0}></MenuItem>
            {legals &&
              legals.map((accountant) => (
                <MenuItem key={accountant.id} value={accountant.id}>
                  {accountant.name}
                </MenuItem>
              ))}
          </Select>
        </TableCell>

        <TableCell align="right">
          <IconButton size="small" onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        {order.orderState !== 11 && (
          <MenuItem onClick={() => setIsChangeState(true)}>Migrar para contabilidade</MenuItem>
        )}
        <MenuItem onClick={handleAssignAccountant}>Associar contador</MenuItem>
        <MenuItem onClick={() => setShowDetails(true)}>Detalhes</MenuItem>
        <MenuItem onClick={() => setIsDelete(true)}>Excluir</MenuItem>
      </DropdownMenu>

      {isDelete && (
        <DialogDeleteOrder order={order} onClose={() => setIsDelete(false)} open={isDelete} />
      )}

      {isChangeState && (
        <DialogChangeStateOrder
          order={order}
          onClose={() => setIsChangeState(false)}
          open={isChangeState}
        />
      )}

      {showDetails && (
        <DialogOpenCompany order={order} open={showDetails} onClose={() => setShowDetails(false)} />
      )}
    </>
  );
};

export default OrdersTableItem;
