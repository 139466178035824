import { FC } from "react";
import useMutationSendPasswordUser from "../UsersContent/hooks/useMutationSendPasswordUser";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { UserAdmin } from "types/User";
import { UserTypeContent, userTypeTranslate } from "../UsersContent";
import { UserCompany } from "types/Admin";

interface DialogSendPassowordUserProps extends DialogProps {
  user: UserAdmin | UserCompany;
  userType?: UserTypeContent;
  companyToken: string;
}

const DialogSendPassowordUser: FC<DialogSendPassowordUserProps> = ({
  user,
  userType,
  companyToken,
  onClose,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationSendPasswordUser();

  const handleClose = () => {
    if (!onClose || isLoading) return;
    onClose({}, "escapeKeyDown");
  };

  const handleSendPassword = async () => {
    try {
      await mutateAsync({
        ...user,
        companyToken,
      });
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth {...props} onClose={handleClose}>
      <DialogTitle>Reenvio de Senha</DialogTitle>

      <DialogContent dividers>
        Deseja enviar uma nova senha para o{" "}
        {userType ? userTypeTranslate[userType] : `email ${user.login}`}?
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} variant="outlined" onClick={handleClose}>
          Não
        </Button>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleSendPassword}>
          Sim
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSendPassowordUser;
