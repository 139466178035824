import { useQuery } from "react-query";
import { useCallback } from "react";
import queryKeys from "react-query/constants";
import { getCompanyParticipants } from "services/Requests/companies";
import { CompanyParticipants, RoleCompanyParticipant } from "types/Company";

const useQueryGetCompanyParticipants = (
  companyToken: string,
  searchClients?: string,
  role: RoleCompanyParticipant | "" = 1,
) => {
  const selectSearch = useCallback(
    (data: CompanyParticipants[]) => {
      if (!searchClients) return data;

      const searchClient = (name: string) => {
        if (!searchClients.trim()) return true;

        return name.toLowerCase().search(searchClients.toLowerCase()) !== -1;
      };

      return data.filter((contract) => searchClient(contract.name));
    },
    [searchClients],
  );

  const queryKey: (string | number)[] = [queryKeys.companyParticipants];
  if (role) queryKey.push(role);

  const query = useQuery(queryKey, () => getCompanyParticipants({ companyToken, role }), {
    select: selectSearch,
  });
  return query;
};

export default useQueryGetCompanyParticipants;
