import { useQuery } from "react-query";

import getSubdomain from "utils/getSubdomain";
import { getBills } from "services/Requests/billing";
import queryKeys from "react-query/constants";

const useQueryBills = (payerCompanyId: number, companyToken: string) => {
  const subdomain = getSubdomain();

  const query = useQuery([queryKeys.clientBills, payerCompanyId], () =>
    getBills({ subdomain, maxResults: 100, payerCompanyId, companyToken }),
  );
  return query;
};

export default useQueryBills;
