import { FC } from "react";
import { Control, Controller } from "react-hook-form";

import { Box, TextField, Typography } from "@mui/material";
import { InvoiceForm } from ".";

interface AedfProps {
  control: Control<InvoiceForm>;
}

const Aedf: FC<AedfProps> = ({ control }) => {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={600}
        borderBottom={1}
        borderColor="divider"
        sx={{ mb: 2 }}
      >
        Autorização para Emissão de Documentos Fiscais Eletrônicos (AEDF)
      </Typography>

      <Controller
        control={control}
        name="aedf"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="AEDF"
            error={!!fieldState.error?.message}
            helperText={
              fieldState.error?.message ??
              "Observação: essa informação não será armazenada em nosso servidor."
            }
            fullWidth
            inputProps={{
              maxLength: 50,
            }}
          />
        )}
      />
    </Box>
  );
};

export default Aedf;
