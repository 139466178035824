import { FC } from "react";
import useQueryCompanyPartners from "pages/Home/hooks/useQueryCompanyPartners";

import { CircularProgress, Stack, Typography } from "@mui/material";
import PartnerForm from "./PartnerForm";

interface Props {
  companyToken: string;
  onNext: () => void;
  onPrev: () => void;
}

const Partner: FC<Props> = ({ companyToken, onNext, onPrev }) => {
  const { data: partners, isLoading, isError } = useQueryCompanyPartners(companyToken);

  return (
    <>
      {isLoading && (
        <Stack height={500} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}

      {isError && !isLoading && (
        <Typography>Ocorreu um erro ao carregar os dados, tente novamente mais tarde</Typography>
      )}

      {!isLoading && !isError && !!partners && (
        <PartnerForm
          onNext={onNext}
          onPrev={onPrev}
          partners={partners}
          companyToken={companyToken}
        />
      )}
    </>
  );
};

export default Partner;
