import { FC } from "react";
import useMutationDeleteCompany from "../hooks/useMutationDeleteCompany";

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

interface DialogDeleteCompanyProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  companyName: string;
}

const DialogDeleteCompany: FC<DialogDeleteCompanyProps> = ({
  companyName,
  companyToken,
  onClose,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationDeleteCompany();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync({
      companyToken,
    });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Excluir {`"${companyName}"`}
      </DialogTitleWithCloseButton>

      <DialogContent>Tem certeza que deseja excluir a empresa {companyName}?</DialogContent>

      <DialogActions>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleDelete}>
          Sim
        </LoadingButton>
        <Button variant="outlined" disabled={isLoading} onClick={handleClose}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteCompany;
