import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import {
  getCompanyChartOfAccountsFromTo,
  getCompanyChartOfAccountsFromToByTerm,
} from "services/Requests/accountant";

const useQueryCompanyChartOfAccountsFromTo = (companyToken: string, term?: string) => {
  const queryKey = [queryKeys.chartOfAccountsFromToGroup, companyToken];
  if (term?.trim()) queryKey.push(term);

  const query = useQuery(
    queryKey,
    () =>
      term?.trim()
        ? getCompanyChartOfAccountsFromToByTerm({ companyToken, term })
        : getCompanyChartOfAccountsFromTo({ companyToken }),
    {
      keepPreviousData: true,
    },
  );
  return query;
};

export default useQueryCompanyChartOfAccountsFromTo;
