import { FC, useRef } from "react";
import useQueryGetFinancialSummary from "../hooks/useQueryGetFinancialSummary";

import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import FinancialSummaryTableItem from "./FinancialSummaryTableItem";
import TableActions from "pages/Home/components/TableActions";
import InfoToPrint from "pages/Home/components/InfoToPrint";
import PrintLandscape from "components/PrintLandscape";

const tableHeadCells = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
  "Ano",
];

interface FinancialSummaryTableProps {
  companyToken: string;
  year: number;
}

const FinancialSummaryTable: FC<FinancialSummaryTableProps> = ({ companyToken, year }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);

  const { data: financialSummary, isLoading } = useQueryGetFinancialSummary(companyToken, year);

  return (
    <Box>
      {isLoading && (
        <Stack height={350} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {financialSummary && (
        <>
          <PrintLandscape>
            <TableContainer
              sx={{ "@media print": { "*": { fontSize: "90%" } } }}
              ref={containerRef}
            >
              <InfoToPrint />

              <Table ref={tableRef} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {tableHeadCells.map((cell) => (
                      <TableCell key={cell} align="center" data-a-h="center">
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {financialSummary.financialSummaryByTypeDTO
                    .sort((a, b) => a.orderFinReport - b.orderFinReport)
                    .map((summary) => (
                      <FinancialSummaryTableItem
                        key={summary.categoriaSintetica}
                        financialSummaryByTypeDTO={summary}
                      />
                    ))}

                  {financialSummary.operationalResult && (
                    <TableRow sx={{ bgcolor: grey[200] }}>
                      <TableCell sx={{ fontWeight: 600 }}>Resultado Operacional</TableCell>
                      {financialSummary.operationalResult.months.map(({ totalMonth }, index) => (
                        <TableCell
                          key={index}
                          align="center"
                          data-a-h="center"
                          sx={{ fontWeight: 600 }}
                          data-f-bold="true"
                        >
                          {totalMonth ? totalMonth.toLocaleString("pt-br") : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </PrintLandscape>

          {containerRef.current && tableRef.current && (
            <TableActions
              mt={2}
              p={2}
              containerTableRef={containerRef.current}
              tableRef={tableRef.current}
              nameExcel="resumo-financeiro"
              nameTable="relatório"
            />
          )}
        </>
      )}
    </Box>
  );
};

export default FinancialSummaryTable;
