import { FC, useState } from "react";
import useQueryCompanyAssignments from "./hooks/useQueryCompanyAssignments";

import {
  CircularProgress,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import CompanyAssignmentsTable from "./CompanyAssignmentsTable";
import SearchInput from "components/SearchInput";

const Accounting: FC = () => {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    registrationStatus: 2,
    status: 0,
  });
  const { isLoading, data: companyAssignments } = useQueryCompanyAssignments({
    registrationStatus: filter.registrationStatus,
    status: filter.status,
    search,
  });

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <RadioGroup
          row
          value={`${filter.status},${filter.registrationStatus}`}
          onChange={(_, value) => {
            const [status, registrationStatus] = value.split(",");
            setFilter({
              status: Number(status),
              registrationStatus: Number(registrationStatus),
            });
          }}
        >
          <FormControlLabel value="0,2" control={<Radio size="small" />} label="Ativos" />
          <FormControlLabel value="0,0" control={<Radio size="small" />} label="Prospect" />
          <FormControlLabel value="0,1" control={<Radio size="small" />} label="Lead" />
          <FormControlLabel value="-1,-1" control={<Radio size="small" />} label="Inativos" />
        </RadioGroup>

        <SearchInput size="small" value={search} onChange={(newValue) => setSearch(newValue)} />
      </Stack>

      <Divider sx={{ my: 3 }} />

      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={350}>
          <CircularProgress />
        </Stack>
      )}

      {companyAssignments && !isLoading && (
        <CompanyAssignmentsTable
          companyAssignments={companyAssignments}
          registrationStatus={filter.registrationStatus}
        />
      )}
    </>
  );
};

export default Accounting;
