import { forwardRef, Ref } from "react";

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import useQueryPaymentTypes from "pages/Home/hooks/useQueryPaymentTypes";

interface PaymentTypesSelectInnerProps extends SelectProps {
  FormControlProps?: FormControlProps;
  helperText?: string;
}

function PaymentTypesSelectInner(
  { helperText, FormControlProps, ...props }: PaymentTypesSelectInnerProps,
  ref: Ref<unknown>,
) {
  const { data: paymentTypes = [], isLoading, isError } = useQueryPaymentTypes();

  return (
    <FormControl
      disabled={props.disabled}
      error={props.error}
      size={props.size}
      {...FormControlProps}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select {...props} ref={ref} value={props.value || ""}>
        <MenuItem disabled value="">
          Selecione:{" "}
        </MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um erro ao carregar os tipos de pagamento</MenuItem>}

        {paymentTypes.map((payment) => (
          <MenuItem key={payment.id} value={payment.id}>
            {payment.description}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const PaymentTypesSelect = forwardRef(PaymentTypesSelectInner);
export default PaymentTypesSelect;
