import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { createMovement, CreateMovementPayload } from "services/Requests/stockControl";
import queryKeys from "react-query/constants";
import { AxiosError } from "axios";

type Error = { message?: string };
type MutationResult = void;

const useMutationCreateMovement = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<MutationResult, Error, CreateMovementPayload>(createMovement, {
    onSuccess(_, { id }) {
      toast.success(`Movimentação ${id ? "atualizada" : "criada"} com sucesso`);
      queryClient.invalidateQueries(queryKeys.stockControlMovements, { exact: false });
      queryClient.invalidateQueries(queryKeys.stockControlMovement, { exact: false });
    },
    onError(error) {
      const message = (error as AxiosError<{ message: string }>)?.response?.data?.message;

      if (message) {
        toast.error(message);
      }
    },
  });
  return mutation;
};

export default useMutationCreateMovement;
