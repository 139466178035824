import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { setConfiguration } from "services/Requests/communications";

const useMutationSetConfiguration = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(setConfiguration, {
    onSuccess() {
      queryClient.invalidateQueries(queryKeys.getConfiguration);
      toast.success("Configuração de email foi atualizado com sucesso");
    },
  });
  return mutation;
};

export default useMutationSetConfiguration;
