import { FC, useState, useMemo, useEffect, useRef, CSSProperties } from "react";
import { FixedSizeList as List } from "react-window";

import { Box, Divider, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { ImportOFXPeriod, ImportOFXResponse, ImportOFXTransaction } from "types/Company";
import ExtractItem from "./ExtractItem";
import MassAction from "./MassAction";
import { ExtractProvider } from "../context/Extract";

interface ExtractsProps {
  companyToken: string;
  importResult: ImportOFXResponse;
  reloadList?: () => void;
}

const Extracts: FC<ExtractsProps> = ({ companyToken, importResult, reloadList }) => {
  const listRef = useRef<List | null>(null);

  const isDesktop = useMediaQuery("(min-width:1200px)");
  const firstRender = useRef(true);

  const extractsRef = useRef<ImportOFXTransaction[]>(importResult.transactions);
  const [extractsSelected, setExtractsSelected] = useState<string[]>([]);
  const [filterExtracts, setFilterExtracts] = useState<ImportOFXTransaction[]>([]);
  const [currentIndexPeriod, setCurrentIndexPeriod] = useState(0);

  const currentPeriod = useMemo(
    (): ImportOFXPeriod | undefined => importResult.periods?.[currentIndexPeriod],
    [currentIndexPeriod],
  );

  const availablesExtracts = useMemo(
    () => filterExtracts.filter((e) => !e.check),
    [filterExtracts],
  );

  useEffect(() => {
    setFilterExtracts(
      extractsRef.current.filter((extract) => extract.monthYear === currentPeriod?.monthYear),
    );

    return () => {
      setFilterExtracts([]);
      setExtractsSelected([]);
    };
  }, [currentPeriod, extractsRef.current, importResult]);

  useEffect(() => {
    setCurrentIndexPeriod(0);
  }, [importResult]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    extractsRef.current = importResult.transactions;
  }, [importResult]);

  const handleSave = (extract: ImportOFXTransaction, index: number) => {
    extractsRef.current = extractsRef.current.map((e, i) => (i === index ? extract : e));

    setFilterExtracts(extractsRef.current.map((e, i) => (i === index ? extract : e)));
  };

  const handleSelected = (selected: boolean, id: string) => {
    const hasSelected = extractsSelected.includes(id);

    if (hasSelected && selected) return;

    if (selected) {
      setExtractsSelected((prev) => [...prev, id]);
    } else {
      setExtractsSelected((prev) => prev.filter((p) => p !== id));
    }
  };

  const clearCheckboxsAfterAction = () => {
    setFilterExtracts(
      extractsRef.current.map((ex) => ({
        ...ex,
        check: extractsSelected.includes(ex.ofxId) ? true : ex.check,
      })),
    );
  };

  return (
    <ExtractProvider extracts={filterExtracts} updateExtracts={handleSave}>
      <Box p={2}>
        <MassAction
          selectIds={extractsSelected}
          itemsLength={availablesExtracts.length}
          onMarkOffAll={() => setExtractsSelected([])}
          onSelectAll={() => setExtractsSelected(availablesExtracts.map((e) => e.ofxId))}
          extracts={filterExtracts}
          reloadList={clearCheckboxsAfterAction}
        />

        <Divider sx={{ mb: 1, mt: 2 }} />

        <Stack direction="row" alignItems="center" justifyContent="center" gap={4}>
          <IconButton
            size="large"
            disabled={currentIndexPeriod === 0}
            onClick={() => {
              setCurrentIndexPeriod((prev) => prev - 1);
            }}
          >
            <ArrowLeftIcon fontSize="large" />
          </IconButton>
          <Typography
            component="div"
            align="center"
            fontWeight={600}
            variant="h5"
            width="40%"
            textTransform="capitalize"
          >
            {currentPeriod?.monthDes || "Sem período"}
            <Typography align="center" variant="body1" color="text.secondary">
              {currentPeriod?.year || "Ano"}
            </Typography>
          </Typography>
          <IconButton
            size="large"
            disabled={
              !importResult?.periods || importResult?.periods.length - 1 === currentIndexPeriod
            }
            onClick={() => {
              setCurrentIndexPeriod((prev) => prev + 1);
            }}
          >
            <ArrowRightIcon fontSize="large" />
          </IconButton>
        </Stack>

        {isDesktop && (
          <>
            <Divider sx={{ my: 1 }} />
            <Box display="grid" gridTemplateColumns="1fr 1fr">
              <Typography align="center" variant="body1" fontWeight={500}>
                Lançamentos importados do seu extrato
              </Typography>
              <Typography align="center" variant="body1" fontWeight={500}>
                Lançamentos cadastrados na plataforma
              </Typography>
            </Box>
          </>
        )}

        <Box mt={1} border={1} borderColor="divider">
          {/* <List
          ref={listRef}
          height={filterExtracts.length * 600}
          width="100%"
          itemCount={filterExtracts.length}
          itemSize={isDesktop ? 600 : 900}
          itemData={filterExtracts}
        >
          {({
            data,
            index,
            style,
          }: {
            data: ImportOFXTransaction[];
            index: number;
            style: CSSProperties;
          }) => (
            <ExtractProvider
              extract={data[index]}
              onSave={(e) => handleSave(e, index)}
              selected={extractsSelected.includes(data[index].ofxId)}
              onSelected={handleSelected}
            >
              <ExtractItem companyToken={companyToken} style={style} />
            </ExtractProvider>
          )}
        </List> */}

          {filterExtracts.map((extract, index) => (
            <ExtractItem
              key={extract.ofxId}
              onSelected={handleSelected}
              selected={extractsSelected.includes(filterExtracts[index].ofxId)}
              companyToken={companyToken}
              extract={extract}
            />
          ))}
        </Box>
      </Box>
    </ExtractProvider>
  );
};

export default Extracts;
