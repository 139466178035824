import { FC } from "react";

import { Box, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import BankAccountSelect from "pages/Home/components/BankAccountSelect";
import { TransferAccountsForm } from ".";

interface OriginFormProps {
  companyToken: string;
  control: Control<TransferAccountsForm>;
}

const OriginForm: FC<OriginFormProps> = ({ control, companyToken }) => {
  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
      <Controller
        control={control}
        name="accountSource"
        render={({ fieldState, field }) => (
          <BankAccountSelect
            sx={{ minWidth: 250 }}
            label="Conta Origem"
            companyToken={companyToken}
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="balance"
        render={({ field, fieldState }) => (
          <TextField
            label="Saldo R$"
            {...field}
            value={
              field.value !== undefined && field.value !== null
                ? formatCurrencyBRL(field.value)
                : ""
            }
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            disabled
          />
        )}
      />
    </Box>
  );
};

export default OriginForm;
