import { FC } from "react";
import { Control, Controller, useWatch } from "react-hook-form";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import CurrencyInput from "components/CurrencyInput";
import ModalitiesSelect from "pages/Home/components/ModalitiesSelect";
import UnityOfMeasureSelect from "pages/Home/components/UnityOfMeasureSelect";
import Recipient from "./Recipient";
import Carrier from "./Carrier";

import { MovementForm } from "../utils/InterfacesForm";

interface ShippingProps {
  control: Control<MovementForm>;
  isConsultation: boolean;
}

const Shipping: FC<ShippingProps> = ({ control, isConsultation }) => {
  const deliveryAddress = useWatch({
    control,
    name: "shipping.deliveryAddress",
  });
  const carrierUsesIssuerData = useWatch({
    control,
    name: "shipping.carrierUsesIssuerData",
  });

  return (
    <>
      <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" alignItems="center" gap={2} mb={3}>
        <Controller
          control={control}
          name={"shipping.modality"}
          render={({ field, fieldState }) => (
            <ModalitiesSelect
              {...field}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              label="Modalidade"
              disabled={isConsultation}
            />
          )}
        />

        <Controller
          control={control}
          name="shipping.value"
          render={({ field, fieldState }) => (
            <CurrencyInput
              {...field}
              inputProps={{
                label: "Valor (R$)",
                helperText: fieldState.error?.message,
                error: !!fieldState.error?.message,
              }}
              disabled={isConsultation}
            />
          )}
        />

        <Controller
          control={control}
          name="shipping.quantity"
          render={({ field, fieldState }) => (
            <TextField
              label="Quantidade"
              {...field}
              inputProps={{
                min: "0",
              }}
              type="number"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              disabled={isConsultation}
            />
          )}
        />

        <Controller
          control={control}
          name="shipping.unityOfMeasure"
          render={({ field, fieldState }) => (
            <UnityOfMeasureSelect
              {...field}
              label="Espécie"
              helperText={fieldState.error?.message}
              error={!!fieldState.error?.message}
              disabled={isConsultation}
            />
          )}
        />

        <Controller
          control={control}
          name="shipping.grossWeight"
          render={({ field, fieldState }) => (
            <TextField
              label="Peso Bruto (Kg)"
              {...field}
              inputProps={{
                min: "0",
              }}
              type="number"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              disabled={isConsultation}
            />
          )}
        />

        <Controller
          control={control}
          name="shipping.netWeight"
          render={({ field, fieldState }) => (
            <TextField
              label="Peso Líquido (Kg)"
              {...field}
              inputProps={{
                min: "0",
              }}
              type="number"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              disabled={isConsultation}
            />
          )}
        />

        <Controller
          control={control}
          name="shipping.mark"
          render={({ field, fieldState }) => (
            <TextField
              label="Marca"
              {...field}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              disabled={isConsultation}
            />
          )}
        />

        <Controller
          control={control}
          name="shipping.numeration"
          render={({ field, fieldState }) => (
            <TextField
              label="Numeração"
              {...field}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              disabled={isConsultation}
            />
          )}
        />
      </Box>

      <Typography variant="h6" sx={{ borderBottom: 1, borderColor: "divider" }}>
        Local de entrega
      </Typography>

      <Controller
        name="shipping.deliveryAddress"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ mt: 2 }} disabled={isConsultation}>
            <FormLabel>Endereço</FormLabel>
            <RadioGroup {...field} row>
              <FormControlLabel value="2" control={<Radio />} label="Não informar" />
              <FormControlLabel value="1" control={<Radio />} label="Usar dados do cliente" />
              <FormControlLabel value="0" control={<Radio />} label="Outro" />
            </RadioGroup>
          </FormControl>
        )}
      />

      {deliveryAddress === "0" && <Recipient control={control} isConsultation={isConsultation} />}

      <Typography variant="h6" sx={{ borderBottom: 1, borderColor: "divider", mt: 3 }}>
        Transportadora
      </Typography>

      <Controller
        name="shipping.carrierUsesIssuerData"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ mt: 2 }} disabled={isConsultation}>
            <FormLabel>Endereço</FormLabel>
            <RadioGroup {...field} row>
              <FormControlLabel value="2" control={<Radio />} label="Não informar" />
              <FormControlLabel value="1" control={<Radio />} label="Usar dados do emitente" />
              <FormControlLabel value="0" control={<Radio />} label="Outro" />
            </RadioGroup>
          </FormControl>
        )}
      />

      {carrierUsesIssuerData === "0" && (
        <Carrier control={control} isConsultation={isConsultation} />
      )}
    </>
  );
};

export default Shipping;
