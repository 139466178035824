import { FC, useState } from "react";
import useQueryGetUsersBills from "./hooks/useQueryGetUsersBills";
import useDebounce from "hooks/useDebounce";

import {
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import BillTable from "./BillTable";
import FilterPeriod from "components/FilterPeriod";
import { DateTime } from "luxon";
import { GetUsersBillsPayload } from "services/Requests/billing";

type StatusFilter = GetUsersBillsPayload["statusFilter"] | "all";

const statusFilterOptions = {
  Todos: "all",
  Pagas: "paid",
  "Em aberto": "pending",
  Canceladas: "canceled",
  Vencidas: "expired",
};

interface BillFiltersProps {
  companyToken: string;
}

const BillFilters: FC<BillFiltersProps> = ({ companyToken }) => {
  const isTablet = useMediaQuery("(max-width:728px)");

  const [statusFilter, setStatusFilter] = useState<StatusFilter>("all");
  const [date, setDate] = useState({
    start: DateTime.local().startOf("month").toISO(),
    end: DateTime.local().endOf("month").toISO(),
  });

  const [searchValue, setSearchValue] = useState("");
  const searchBill = useDebounce(searchValue, 200);

  const {
    data: usersBills,
    isLoading,
    isFetching,
  } = useQueryGetUsersBills(
    companyToken,
    date.start,
    date.end,
    statusFilter === "all" ? null : statusFilter,
    searchBill,
  );

  return (
    <>
      <Stack
        direction={isTablet ? "column" : "row"}
        gap={1.5}
        alignItems={isTablet ? "flex-start" : "center"}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack
          width={isTablet ? "100%" : "auto"}
          direction={isTablet ? "column" : "row"}
          gap={1.5}
          alignItems={isTablet ? "flex-start" : "center"}
          flexWrap="wrap"
        >
          <FilterPeriod
            fullWidth={isTablet}
            endDate={date.end}
            startDate={date.start}
            onChangeDates={(dates) => setDate(dates)}
            label="Período"
          />

          <FormControl fullWidth={isTablet}>
            <InputLabel>Mostrando</InputLabel>
            <Select
              size="small"
              value={statusFilter}
              label="Mostrando"
              onChange={(e) => {
                setStatusFilter(e.target.value as StatusFilter);
              }}
            >
              {Object.entries(statusFilterOptions).map(([nameFilter, slugFilter]) => (
                <MenuItem value={slugFilter} key={slugFilter}>
                  {nameFilter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <TextField
          fullWidth={isTablet}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          size="small"
          label="Buscar"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Divider sx={{ my: 2 }} />

      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={300}>
          <CircularProgress />
        </Stack>
      )}

      <Collapse in={!isLoading && isFetching}>
        <LinearProgress />
      </Collapse>

      {usersBills && <BillTable usersBills={usersBills} companyToken={companyToken} />}
    </>
  );
};

export default BillFilters;
