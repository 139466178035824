import { FC, useState } from "react";
import useMutationGetAccount from "../hooks/useMutationGetAccount";
import useMutationLinkAccount from "../hooks/useMutationLinkAccount";
import useMutationConnectToken from "../hooks/useMutationConnectToken";
import useMutationUpdateIntegratorAccount from "../hooks/useMutationUpdateIntegratorAccount";
import useMutationDeleteCompanyBankAccount from "../hooks/useMutationDeleteCompanyBankAccount";

import {
  IconButton,
  TableCell,
  TableRow,
  Menu as DropdownMenu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Chip,
} from "@mui/material";
import Menu from "@mui/icons-material/Menu";
import { LoadingButton } from "@mui/lab";

import { DateTime } from "luxon";
import { Account } from "types/BankingIntegrator";
import { CompanyBankAccounts } from "types/Company";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";

import { PluggyConnect, PluggyConnectProps } from "react-pluggy-connect";
import toast from "react-hot-toast";

interface CompanyAccountTableItemProps {
  bankAccount: CompanyBankAccounts;
  companyToken: string;
  onEdit: () => void;
}

const CompanyAccountTableItem: FC<CompanyAccountTableItemProps> = ({
  bankAccount,
  onEdit,
  companyToken,
}) => {
  const { mutateAsync, isLoading } = useMutationDeleteCompanyBankAccount();
  const { mutateAsync: mutateConnect, isLoading: loadingConnect, data } = useMutationConnectToken();
  const { mutateAsync: mutateGetAccount, isLoading: loadingGetAccount } = useMutationGetAccount();
  const { mutateAsync: mutateLinkAccount, isLoading: loadingLinkAccount } =
    useMutationLinkAccount();
  const { mutateAsync: mutateIntegratorAccount, isLoading: loadingIntegrator } =
    useMutationUpdateIntegratorAccount();

  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [createIntegration, setCreateIntegration] = useState(false);

  const handleDeleteClient = async () => {
    await mutateAsync({
      id: bankAccount.downloadToken,
    });
    setConfirmationDelete(false);
  };

  const handleConnectToken = async () => {
    setCreateIntegration(true);
    await mutateConnect();
  };

  const handleSuccessPluggyConnect: PluggyConnectProps["onSuccess"] = async ({ item }) => {
    const linkAccount = async (account: Account) => {
      await mutateLinkAccount({
        account,
        companyToken,
        item,
        linkedBankAccountId: bankAccount.id,
      });

      await mutateIntegratorAccount({
        companyToken,
        id: bankAccount.id,
        integratorAccountId: account.id,
      });
    };

    try {
      const responseAccount = await mutateGetAccount({
        companyToken,
        item,
        type: null,
      });
      linkAccount(responseAccount);
    } catch (e) {
      console.log(e);
    } finally {
      setCreateIntegration(false);
    }
  };

  const handleErrorPluggyConnect = () => {
    toast.error("Ocorreu um erro ao tentar iniciar a integração. Tente novamente mais tarde.");
    setCreateIntegration(false);
  };

  return (
    <>
      <TableRow hover>
        <TableCell>{bankAccount.alias}</TableCell>
        <TableCell>
          {DateTime.fromISO(bankAccount.dateInitialBalance).toFormat("dd/MM/yyyy")}
        </TableCell>
        <TableCell>{bankAccount.bankFormat}</TableCell>
        <TableCell>{bankAccount.agency}</TableCell>
        <TableCell>{bankAccount.account}</TableCell>
        <TableCell>{bankAccount.description || "-"}</TableCell>
        <TableCell>{bankAccount.initialBalance}</TableCell>
        <TableCell>
          {bankAccount.integratorAccountId ? (
            <Chip label="Integrada" color="success" size="small" />
          ) : (
            <Chip label="Não integrada" color="default" size="small" />
          )}
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={(e) => setAnchorElMenu(e.currentTarget)}>
            <Menu />
          </IconButton>
        </TableCell>
      </TableRow>

      <Dialog
        open={confirmationDelete}
        onClose={() => {
          if (!isLoading) setConfirmationDelete(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Excluir Conta</DialogTitle>
        <DialogContent>
          Tem certeza que deseja excluir a conta?
          <Typography>
            Esta ação também excluirá todos os lançamentos referente a essa conta.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={() => setConfirmationDelete(false)}
          >
            Não
          </Button>
          <LoadingButton variant="contained" loading={isLoading} onClick={handleDeleteClient}>
            Sim
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <DropdownMenu
        anchorEl={anchorElMenu}
        open={menuIsOpen}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {!bankAccount.integratorAccountId && (
          <MenuItem onClick={handleConnectToken}>
            Integrar conta <Chip label="Novo" color="success" size="small" sx={{ ml: 1 }} />
          </MenuItem>
        )}
        <MenuItem onClick={onEdit}>Editar</MenuItem>
        <MenuItem onClick={() => setConfirmationDelete(true)}>Excluir</MenuItem>
      </DropdownMenu>

      {(loadingConnect || loadingGetAccount || loadingLinkAccount || loadingIntegrator) && (
        <FullscreenLoadingIndicator />
      )}

      {createIntegration && !loadingConnect && !!data?.accessToken && (
        <PluggyConnect
          connectToken={data.accessToken}
          onSuccess={handleSuccessPluggyConnect}
          onError={handleErrorPluggyConnect}
          onClose={() => setCreateIntegration(false)}
        />
      )}
    </>
  );
};

export default CompanyAccountTableItem;
