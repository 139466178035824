import { FC, useState } from "react";
import useQueryCompaniesPost from "./hooks/useQueryCompaniesPost";

import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import comparatorSortByProperty from "utils/comparatorSortByProperty";
import CompaniesTableItem from "./CompaniesTableItem";
import SortableTableHead from "components/SortableTableHead";
import SearchInput from "components/SearchInput";
import stableSort from "utils/stableSort";

import { Order } from "types/SemanticTypes";
import { Company } from "types/Company";

type CompanyKeys = keyof Company;

interface HeadCells {
  label: string;
  id: CompanyKeys;
}

const headCells: HeadCells[] = [
  {
    label: "Empresa",
    id: "name",
  },
  {
    label: "CNPJ",
    id: "cnpj",
  },
  {
    label: "Data",
    id: "createdOn",
  },
  {
    label: "Status",
    id: "registrationStatus",
  },
];

interface CompaniesTableProps {
  companyToken: string;
}

const CompaniesTable: FC<CompaniesTableProps> = ({ companyToken }) => {
  const [search, setSearch] = useState("");
  const { isLoading, data: companies, isError } = useQueryCompaniesPost(companyToken, search);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<CompanyKeys>("name");

  const sortHandler = (property: CompanyKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box boxShadow={2} bgcolor="#fff">
      <Box p={2}>
        <SearchInput value={search} onChange={(newValue) => setSearch(newValue)} fullWidth />
      </Box>
      <Divider />
      <Box p={2}>
        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={400}>
            <CircularProgress />
          </Stack>
        )}

        {isError && <Typography>Ocorreu um error, tente novamente mais tarde</Typography>}

        {companies && !isLoading && !isError && (
          <TableContainer>
            <Table size="small">
              <SortableTableHead
                headCells={headCells}
                onRequestSort={(e, property) => sortHandler(property)}
                order={order}
                orderBy={orderBy}
              >
                <TableCell align="right"></TableCell>
              </SortableTableHead>

              <TableBody>
                {companies.length === 0 && !isLoading && (
                  <TableRow sx={{ width: "100%" }}>
                    <TableCell colSpan={headCells.length + 1}>
                      <Typography variant="body1">Nenhum resultado encontrado</Typography>
                    </TableCell>
                  </TableRow>
                )}

                {stableSort(companies, comparatorSortByProperty(order, orderBy)).map((company) => (
                  <CompaniesTableItem key={company.id} company={company} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
};

export default CompaniesTable;
