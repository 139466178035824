import { FC, useState } from "react";

import DialogDeleteAdminUser from "../DialogDeleteAdminUser";
import DialogBlockOrUnBlockUser from "../DialogBlockOrUnBlockUser";
import DialogSendPasswordUser from "../DialogSendPasswordUser";
import { UserAdmin } from "types/User";
import { UserTypeContent } from ".";

import { Chip, IconButton, Link, TableCell, TableRow, Menu, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

interface UserTableItemProps {
  user: UserAdmin;
  companyToken: string;
  userType: UserTypeContent;
  onEditUser: (user: UserAdmin) => void;
}

const UserTableItem: FC<UserTableItemProps> = ({ user, onEditUser, userType, companyToken }) => {
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  const [dialogConfirmationDelete, setDialogConfirmationDelete] = useState(false);
  const [dialogBlockOrUnBlock, setDialogBlockOrUnBlock] = useState(false);
  const [dialogSendPassword, setDialogSendPassword] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>{user.name}</TableCell>
        <TableCell>{user.login}</TableCell>
        <TableCell>
          <Link href={`mailto:${user.email}`} target="_blank" color={grey[900]}>
            {user.email}
          </Link>
        </TableCell>
        <TableCell>
          <Chip
            label={user.status === "Active" ? "ativo" : "bloqueado"}
            color={user.status === "Active" ? "success" : "error"}
          />
        </TableCell>
        <TableCell padding="checkbox" align="right">
          <IconButton onClick={(e) => setAnchorElMenu(e.currentTarget)}>
            <MenuIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <DialogSendPasswordUser
        user={user}
        companyToken={companyToken}
        open={dialogSendPassword}
        onClose={() => setDialogSendPassword(false)}
        userType={userType}
      />

      <DialogBlockOrUnBlockUser
        user={user}
        open={dialogBlockOrUnBlock}
        onClose={() => setDialogBlockOrUnBlock(false)}
        userType={userType}
      />

      <DialogDeleteAdminUser
        user={user}
        open={dialogConfirmationDelete}
        onClose={() => setDialogConfirmationDelete(false)}
        userType={userType}
      />

      <Menu
        anchorEl={anchorElMenu}
        open={menuIsOpen}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => onEditUser(user)}>Editar</MenuItem>
        <MenuItem onClick={() => setDialogSendPassword(true)}>Reenviar Senha</MenuItem>
        <MenuItem onClick={() => setDialogBlockOrUnBlock(true)}>
          {user.status === "Active" ? "Bloquear" : "Desbloquear"}
        </MenuItem>
        <MenuItem onClick={() => setDialogConfirmationDelete(true)}>Excluir</MenuItem>
      </Menu>
    </>
  );
};

export default UserTableItem;
