import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";

import { updateSpreads, UpdateSpreadsPayload } from "services/Requests/billing";
import getSubdomain from "utils/getSubdomain";
import toast from "react-hot-toast";

const useMutationUpdateSpreads = (companyToken?: string) => {
  const subdomain = getSubdomain();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload: Omit<UpdateSpreadsPayload, "subdomain">) => updateSpreads({ ...payload, subdomain }),
    {
      onSuccess(_, { companyId }) {
        toast.success("Spreads atualizadas com sucesso!");

        if (companyToken) {
          queryClient.invalidateQueries([queryKeys.infoAccount, companyToken]);
        } else {
          queryClient.invalidateQueries(queryKeys.infoAccount);
        }
        queryClient.invalidateQueries([queryKeys.clientSpreads, companyId]);
        queryClient.invalidateQueries(queryKeys.allSubAccounts);
      },
    },
  );
  return mutation;
};

export default useMutationUpdateSpreads;
