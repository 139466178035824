import { FC } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useMutationDeleteCustomService from "../../hooks/useMutationDeleteCustomService";
import toast from "react-hot-toast";

interface DialogDeleteServiceProps extends DialogProps {
  companyToken: string;
  serviceId: number;
}

const DialogService: FC<DialogDeleteServiceProps> = ({
  onClose,
  companyToken,
  serviceId,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationDeleteCustomService();

  const handleClose = () => {
    if (isLoading) return;

    onClose?.({}, "backdropClick");
  };

  const handleDeleteService = async () => {
    try {
      await mutateAsync({
        companyToken,
        serviceId,
      });
      handleClose();
    } catch (e) {
      toast.error("Ocorreu um erro ao tentar deletar serviço");
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} {...props}>
      <DialogTitle>Excluir serviço</DialogTitle>

      <DialogContent>Deseja excluir este serviço?</DialogContent>

      <DialogActions>
        <Button variant="outlined">Não</Button>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleDeleteService}>
          Sim
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogService;
