import { FC, useState } from "react";

import { Dialog, DialogContent, DialogProps, Tab, Tabs } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import TabPanel from "components/TabPanel";
import Company from "./Company";
import Order from "./Order";
import Partner from "./Partner";
import Documentation from "./Documentation";
import Comunication from "./Comunication";
import Signature from "./Signature";
import Invoice from "./Invoice";

import { Order as IOrder } from "types/OpenCompany";
import { getCompanyType } from "pages/Home/pages/Marketplace/components/Legalization/OrdersTableItem";

interface DialogOpenCompanyProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  order: IOrder;
}

interface Tabs {
  label: string;
  Component: FC<{ order: IOrder }>;
}

const tabs: Tabs[] = [
  {
    label: "Pedido",
    Component: Order,
  },
  {
    label: "Empresa",
    Component: Company,
  },
  {
    label: "Sócios",
    Component: Partner,
  },
  {
    label: "Documentação",
    Component: Documentation,
  },
  {
    label: "Solicitações",
    Component: Comunication,
  },
  {
    label: "Assinatura",
    Component: Signature,
  },
  {
    label: "Faturas",
    Component: Invoice,
  },
];

const DialogOpenCompany: FC<DialogOpenCompanyProps> = ({ order, ...props }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Dialog {...props} maxWidth="lg" fullWidth>
      <DialogTitleWithCloseButton onClose={props.onClose}>
        Empresa: {order.companyName + " " + getCompanyType(order.companyType)}
      </DialogTitleWithCloseButton>

      <DialogContent>
        <Tabs
          color="primary"
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          sx={{
            borderBottom: 1,
            borderBottomColor: "divider",
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>

        {tabs.map(({ Component }, index) => (
          <TabPanel key={index} value={activeTab} index={index} keepMounted>
            <Component order={order} />
          </TabPanel>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default DialogOpenCompany;
