import endpoints from "../endpoints";
import api from "../api";
import {
  Balances,
  CorrectionResponse,
  FullMovement,
  InvoiceStockControl,
  Modality,
  Movements,
  PaymentMode,
  PaymentType,
  PresenceIndicator,
  Products,
  TypeInvoice,
} from "types/StockControl";
import { ISODate } from "types/SemanticTypes";
import { InvoiceConfig } from "types/Company";

export interface GetProductsParams {
  companyToken: string;
}

export const getProducts = async (params: GetProductsParams): Promise<Products> => {
  const { data } = await api.get<Products>(endpoints.stockControl.products, {
    params,
  });
  return data;
};

export interface PostProductsPayload {
  companyToken: string;
  id?: number;
  code: string;
  name: string;
  description: string;
  initialQuantity: string;
  minimumQuantity: string;
  averageUnitValue: string | number;
  notes: string;
  ncm: string;
  cest: string;
  ean: string;
  unityOfMeasure: string;
  icmsCst: string;
  icms: string;
  pisCst: string;
  pis: string;
  cofinsCst: string;
  cofins: string;
  ipiCst: string;
  ipi: string;
  lc116?: string;
  iss?: string;
  cfop?: string;
  taxBenefit: string | null;
  taxSource: string | null;
  municipalTax: number | null;
  stateTax: number | null;
  federalTax: number | null;
}

export const postProducts = async (payload: PostProductsPayload) => {
  await api.post(endpoints.stockControl.product, { ...payload, id: payload?.id ?? 0 });
};

export interface DeleteProductPayload {
  companyToken: string;
  id: number;
}

export const deleteProduct = async (payload: DeleteProductPayload) => {
  await api.post(endpoints.stockControl.deleteProduct, payload);
};

export interface GetBalanceParams {
  companyToken: string;
}

export const getBalance = async (params: GetBalanceParams): Promise<Balances> => {
  const { data } = await api.get<Balances>(endpoints.stockControl.balance, { params });
  return data;
};

export interface GetMovementsParams {
  companyToken: string;
  endDate: ISODate;
  startDate: ISODate;
}

export const getMovements = async (params: GetMovementsParams): Promise<Movements> => {
  const { data } = await api.get<Movements>(endpoints.stockControl.movements, {
    params: {
      ...params,
      type: "",
    },
  });
  return data;
};

export const getModalities = async (): Promise<Modality[]> => {
  const { data } = await api.get<Modality[]>(endpoints.stockControl.modalities);
  return data;
};

export interface GetInvoiceConfig {
  companyToken: string;
}

export const getInvoiceConfig = async (params: GetInvoiceConfig): Promise<InvoiceConfig> => {
  const { data } = await api.get<InvoiceConfig>(endpoints.stockControl.invoiceConfig, { params });
  return data;
};

export interface CreateMovementPayment {
  id: number;
  paymentMode: string;
  paymentType: string;
  updateAction: "Insert" | "Update";
  value: number;
}

export interface CreateMovementItem {
  id: number;
  productId: number;
  unitValue: number;
  quantity: number;
  discount: number;
  otherExpenses: number;
  cfop: null | string;
  icmsCst: string;
  icms: number;
  modalityBCST: number;
  aliquotST: number;
  aliquotMvaST: number;
  aliquotReductionBCST: number;
  pisCst: string;
  pis: number;
  cofinsCst: string;
  cofins: number;
  ipiCst: string;
  ipi: number;
  ncm?: string;
  lc116: string;
  iss: number;
  anp: string;
  ufConsumption: string;
  updateAction: "Insert" | "Update";
  notes: string;
  totalValue: number;
  taxBenefit: string | null;
  taxSource: string | null;
  municipalTax: number | null;
  stateTax: number | null;
  federalTax: number | null;
}

export interface CreateMovementShipping {
  modality: string;
  value: number;
  quantity: number;
  unityOfMeasure: string;
  grossWeight: number;
  netWeight: number;
  mark: string;
  numeration: string;
  deliveryAddress: number;
  carrierUsesIssuerData: number;
  recipientCpfCnpj: string;
  carrierCpfCnpj: string;
  recipientStreet: string;
  recipientNumber: string | number;
  recipientComplement: string;
  recipientNeighborhood: string;
  recipientZipCode: string;
  recipientState: string;
  recipientCity: string;
  recipientCountyId?: string;
  carrierName: string;
  carrierStateRegistration?: string;
  carrierAddress: string;
  carrierState: string;
  carrierCity: string;
  carrierCountyId?: string;
}

export interface CreateMovementPayload {
  id: number;
  operationType: number;
  participantId?: number;
  presenceIndicator: number;
  finalConsumer: string;
  showFinalConsumer: boolean;
  nature: string;
  notes: string;
  generateTransaction: boolean;
  items: CreateMovementItem[];
  totalValue: number;
  companyToken: string;
  movementDate: ISODate;
  dueDate: ISODate;
  shipping?: CreateMovementShipping;
  nFType: string;
  nfType: string;
  sendInvoice: boolean;
  sendNFe: boolean;
  sendNFCe: boolean;
  paymentType: string | null;
  payments: CreateMovementPayment[];
}

export const createMovement = async (payload: CreateMovementPayload) => {
  await api.post(endpoints.stockControl.movement, payload);
};

export interface GetMovementParams {
  companyToken: string;
  id: number;
}

export const getMovement = async (params: GetMovementParams): Promise<FullMovement> => {
  const { data } = await api.get<FullMovement>(endpoints.stockControl.movement, { params });
  return data;
};

export interface DeleteMovementPayload {
  companyToken: string;
  id: number;
  itemId: number;
}

export const deleteMovement = async (payload: DeleteMovementPayload) => {
  await api.post(endpoints.stockControl.deleteMovement, payload);
};

export interface GetInvoicesParams {
  companyToken: string;
  endDate: ISODate;
  startDate: ISODate;
}

export const getInvoices = async (params: GetInvoicesParams): Promise<InvoiceStockControl[]> => {
  const { data } = await api.get<InvoiceStockControl[]>(endpoints.stockControl.invoices, {
    params: { ...params, type: "" },
  });
  return data;
};

export interface CorrectionsParams {
  companyToken: string;
  movementId: number;
}

export const corrections = async (params: CorrectionsParams): Promise<CorrectionResponse> => {
  const { data } = await api.get<CorrectionResponse>(endpoints.stockControl.corrections, {
    params,
  });
  return data;
};

export interface CreateCorrectionPayload {
  accessKey: string;
  companyToken: string;
  description: string;
  id: number;
  movementId: number;
  number: number;
}

export const createCorrection = async (payload: CreateCorrectionPayload) => {
  await api.post(endpoints.stockControl.correction, payload);
};

export interface DeleteCorrectionPayload {
  companyToken: string;
  movementId: number;
  id: number;
}

export const deleteCorrection = async (payload: DeleteCorrectionPayload) => {
  await api.post(endpoints.stockControl.deleteCorrection, payload);
};

export interface DeleteInvoicePayload {
  companyToken: string;
  id: number;
}

export const deleteInvoice = async (payload: DeleteInvoicePayload) => {
  await api.post(endpoints.stockControl.deleteInvoice, payload);
};

export const getPresenceIndicator = async (type: TypeInvoice) => {
  const endpoint =
    type === "NFe"
      ? endpoints.stockControl.presenceIndicatorNFe
      : endpoints.stockControl.presenceIndicatorNFCe;

  const { data } = await api.get<PresenceIndicator[]>(endpoint);
  return data;
};

export const getPaymentTypes = async () => {
  const { data } = await api.get<PaymentType[]>(endpoints.stockControl.paymentTypes);
  return data;
};

export const getPaymentModes = async () => {
  const { data } = await api.get<PaymentMode[]>(endpoints.stockControl.paymentModes);
  return data;
};
