import { FC, MouseEventHandler, useState } from "react";
import { useAuth } from "context/Auth";
import { useSideMenu } from "context/SideMenuContext/useSideMenu";
import { useCompanyInfo } from "context/CompanyInfo";

import {
  Box,
  Collapse,
  Fade,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HomeIcon from "@mui/icons-material/Home";

import checkUserPermission from "utils/checkUserPermission";

interface MyCompanyMenuItemProps {
  onChangeCompany: () => void;
  onShowConfiguration: () => void;
}

const MyCompanyMenuItem: FC<MyCompanyMenuItemProps> = ({
  onChangeCompany,
  onShowConfiguration,
}) => {
  const { user } = useAuth();
  const { companyStyles } = useCompanyInfo();
  const { isOpen } = useSideMenu();

  const [collapsed, setCollapsed] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dropdownOpen = Boolean(anchorEl);

  if (user.userInfo.userType === "User") {
    return null;
  }

  if (!checkUserPermission(user, "32") || !checkUserPermission(user, ["33", "34"])) {
    return null;
  }

  const handleMouseEnter: MouseEventHandler<HTMLLIElement> | undefined = (event) => {
    if (isOpen) return;
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      component="li"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setAnchorEl(null)}
      position="relative"
    >
      <ListItemButton
        onClick={() => setCollapsed(!collapsed)}
        sx={{
          py: 2,
          gap: 1.5,
          borderLeft: "5px solid transparent",
          transition: "0.2s",
          background: collapsed ? companyStyles.sidebarLinkHoverBg : undefined,
          "&:hover, &:focus": {
            background: `${companyStyles.sidebarLinkHoverBg} !important`,
            ".arrow-down": { opacity: collapsed ? 1 : 0 },
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: "fit-content", m: 0 }}>
          <HomeIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary="Minha Empresa"
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "light",
            lineHeight: "20px",
          }}
          sx={{ opacity: isOpen ? undefined : 0 }}
        />

        <KeyboardArrowDownIcon
          sx={{
            mr: -1,
            opacity: 0,
            transform: collapsed ? "rotate(-180deg)" : "rotate(0)",
            transition: "0.2s",
          }}
          className="arrow-down"
        />
      </ListItemButton>

      <Collapse in={collapsed && isOpen} timeout={isOpen ? undefined : 0}>
        {checkUserPermission(user, "33") && (
          <ListItemButton
            sx={{ py: 2, px: 3, gap: 1.5, background: companyStyles.sidebarSubmenuBg }}
            onClick={() => {
              onChangeCompany();
              setCollapsed(false);
            }}
          >
            <ListItemIcon sx={{ minWidth: "fit-content" }}>•</ListItemIcon>
            <ListItemText
              color="text.primary"
              primary={"Acessar"}
              primaryTypographyProps={{
                fontSize: 15,
                fontWeight: "400",
                lineHeight: "20px",
              }}
            />
          </ListItemButton>
        )}

        {checkUserPermission(user, "34") && (
          <ListItemButton
            sx={{ py: 2, px: 3, gap: 1.5, background: companyStyles.sidebarSubmenuBg }}
            onClick={() => onShowConfiguration()}
          >
            <ListItemIcon sx={{ minWidth: "fit-content" }}>•</ListItemIcon>
            <ListItemText
              color="text.primary"
              primary={"Configurações"}
              primaryTypographyProps={{
                fontSize: 15,
                fontWeight: "400",
                lineHeight: "20px",
              }}
            />
          </ListItemButton>
        )}
      </Collapse>

      <Popper open={dropdownOpen} anchorEl={anchorEl} placement="right-start" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                background: `${companyStyles.sidebarBg} !important`,
                color: companyStyles.sidebarLinkColor,
              }}
            >
              <Typography sx={{ px: 2, py: 1 }} color="inherit">
                Minha Empresa
              </Typography>

              <Box bgcolor={companyStyles.sidebarSubmenuBg} px={2} py={1}>
                <Typography
                  component="span"
                  color="inherit"
                  display="block"
                  py={0.5}
                  sx={{ cursor: "pointer" }}
                  role="button"
                  onClick={() => {
                    onChangeCompany();
                    setCollapsed(false);
                  }}
                >
                  •{" "}
                  <Typography component="span" ml={0.5} color="inherit">
                    Acessar
                  </Typography>
                </Typography>

                <Typography
                  onClick={() => onShowConfiguration()}
                  component="span"
                  color="inherit"
                  display="block"
                  py={0.5}
                  sx={{ cursor: "pointer" }}
                  role="button"
                >
                  •{" "}
                  <Typography component="span" ml={0.5} color="inherit">
                    Configurações
                  </Typography>
                </Typography>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default MyCompanyMenuItem;
