import { FC, useState } from "react";

import { IconButton, MenuItem, TableCell, TableRow } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";

import { Movement } from "types/StockControl";
import { DateTime } from "luxon";
import DropdownMenu from "components/DropdownMenu";
import DialogUpdateMovement from "../components/DialogUpdateOrCreateMovement";
import DialogDeleteMovement from "../components/DialogDeleteMovement";

interface MovementTableItemProps {
  movement: Movement;
  companyToken: string;
}

const MovementTableItem: FC<MovementTableItemProps> = ({ movement, companyToken }) => {
  const Icon = movement.operationType === "Entrada" ? AddIcon : RemoveIcon;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isConsultation, setIsConsultation] = useState(false);
  const modifiable = movement.invoiceStatus == "" || movement.invoiceStatus == "Negada";

  return (
    <>
      <TableRow hover selected={isOpen}>
        <TableCell>
          <Icon fontSize="small" />
        </TableCell>
        <TableCell>{DateTime.fromISO(movement.movementDate).toFormat("dd/MM/yyyy")}</TableCell>
        <TableCell>{movement.id}</TableCell>
        <TableCell>{movement.productCode}</TableCell>
        <TableCell>{movement.productName}</TableCell>
        <TableCell>{movement.participantName}</TableCell>
        <TableCell align="right">{movement.quantity}</TableCell>
        <TableCell align="right">
          {movement.unitValue.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </TableCell>
        <TableCell align="right">
          {movement.totalValue.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </TableCell>
        <TableCell>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setIsConsultation(true)}>Consultar</MenuItem>
        {modifiable && <MenuItem onClick={() => setIsEdit(true)}>Editar</MenuItem>}
        {modifiable && <MenuItem onClick={() => setIsDelete(true)}>Excluir</MenuItem>}
      </DropdownMenu>

      {(isEdit || isConsultation) && (
        <DialogUpdateMovement
          open={isEdit || isConsultation}
          onClose={() => {
            setIsEdit(false);
            setIsConsultation(false);
          }}
          companyToken={companyToken}
          movementId={movement.movementId}
          isConsultation={isConsultation}
        />
      )}

      {isDelete && (
        <DialogDeleteMovement
          companyToken={companyToken}
          itemId={movement.id}
          movementId={movement.movementId}
          open={isDelete}
          onClose={() => setIsDelete(false)}
        />
      )}
    </>
  );
};

export default MovementTableItem;
