import { FC, memo, CSSProperties, useEffect, useCallback } from "react";
import { useExtract } from "../context/Extract";
import useMutationCreateOrUpdateTransaction from "../hooks/useMutationCreateOrUpdateTransaction";

import { Box, Checkbox, Stack, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { grey } from "@mui/material/colors";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";

import TabPanel from "components/TabPanel";
import ExtractTabNavigation from "./ExtractTabNavigation";

import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { DateTime } from "luxon";
import useMutationUploadReceipt from "pages/Home/hooks/useMutationUploadReceipt";
import { ImportOFXTransaction } from "types/Company";

interface ExtractItemProps {
  companyToken: string;
  style?: CSSProperties;
  extract: ImportOFXTransaction;
  onSelected: (selected: boolean, id: string) => void;
  selected: boolean;
}

const ExtractItem: FC<ExtractItemProps> = ({
  companyToken,
  extract,
  onSelected,
  selected,
  style,
}) => {
  const { isLoading: isLoadingCreateOrUpdate, mutateAsync } =
    useMutationCreateOrUpdateTransaction();
  const { isLoading: isLoadingUpload, mutateAsync: mutateUpload } = useMutationUploadReceipt();
  const isLoading = isLoadingUpload || isLoadingCreateOrUpdate;

  const { onChangeExtract, fileList } = useExtract();
  const isDesktop = useMediaQuery("(min-width:1200px)");

  const handleReconcile = async (ofxIgnore: boolean) => {
    const globalCategoryId = extract.globalCategoryId;

    const { id } = await mutateAsync({
      companyToken,
      ofxDescription: extract.description2,
      oFXDescription: extract.description2,
      ofxId: extract.ofxId,
      ofxIgnore,
      paymentDate: extract.date,
      transactionId: extract.transactionId,
      transactionType: extract.type.toLowerCase() === "credit" ? "Credit" : "Debt",
      value: extract.transactionValue,
      categoryId: extract.categoryId,
      companyBankAccountId: extract.companyBankAccountId,
      companyParticipantId: extract.companyParticipantId,
      companyTransferBankAccountId: extract.companyTransferBankAccount
        ? extract.companyBankAccountId
        : undefined,
      description: extract.description2,
      descriptionOFX: extract.description2,
      dueDate: extract.date,
      dueDate2: extract.date,
      globalCategoryId,
      isInvoice: false,
      issueDate: extract.date,
      partnerParticipantId: extract.partnerParticipantId,
      id: extract.id,
    });

    const uploadFiles = async () => {
      try {
        if (ofxIgnore || fileList.length === 0) return;
        mutateUpload({
          companyToken,
          files: fileList,
          transactionId: id,
        });
      } catch (e) {
        console.log(e);
      }
    };

    await uploadFiles();
    onChangeExtract(extract, "check", true);
  };

  const handleSelected = (slcted: boolean) => onSelected(slcted, extract.ofxId);

  const CheckboxItem = useCallback(
    () => (
      <Checkbox
        sx={{ height: "fit-content" }}
        size="small"
        checked={selected}
        onChange={(_, checked) => handleSelected(checked)}
      />
    ),
    [selected],
  );

  return (
    <Box
      style={{ position: "relative", left: "0px", top: "0px", height: "600px", width: "100%" }}
      sx={{
        "&:hover": { bgcolor: grey[50] },
        "&:not(:first-of-type)": {
          borderTop: 1,
          borderColor: !isDesktop ? grey[400] : "transparent",
        },
      }}
    >
      <Box
        display="grid"
        gridTemplateColumns={isDesktop ? "4fr auto 4fr" : "1f"}
        alignItems="start"
        gap={3}
        p={isDesktop ? 2 : 4}
      >
        {!isDesktop && (
          <Typography sx={{ mb: -2, ml: 1 }} variant="body1" fontWeight={500}>
            Lançamentos importados do seu extrato
          </Typography>
        )}
        <Stack direction="row" gap={1}>
          {!extract.check && <CheckboxItem />}

          <Box sx={{ width: "100%", paddingLeft: extract.check && isDesktop ? "46px" : 0 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs color="primary" value={0}>
                <Tab label="Extrato" />
              </Tabs>
            </Box>

            <TabPanel
              value={0}
              index={0}
              bgcolor={grey[200]}
              borderRadius="0 0 4px 4px"
              border={1}
              borderColor={grey[300]}
            >
              <Stack direction="row" gap={1} justifyContent="space-between" mb={1}>
                <Typography variant="body1">
                  {DateTime.fromISO(extract.date).toFormat("dd/MM/yyyy")}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  color={extract.transactionValue < 0 ? "error.main" : "success.main"}
                >
                  {formatCurrencyBRL(extract.transactionValue)}
                </Typography>
              </Stack>
              <Typography variant="body2">{extract.description}</Typography>
              {!!extract.descriptionRaw && (
                <Typography variant="body2" color="text.secondary">
                  {extract.descriptionRaw}
                </Typography>
              )}
              {!!extract.counterpaty && (
                <Typography variant="body2" color="text.secondary">
                  Contraparte: {extract.counterpaty}
                </Typography>
              )}
              <Stack mt={6} alignItems="end">
                <LoadingButton
                  loading={isLoading}
                  onClick={() => handleReconcile(true)}
                  size="small"
                  variant="contained"
                  disabled={extract.check}
                  startIcon={<DeleteIcon fontSize="small" />}
                >
                  Ignorar
                </LoadingButton>
              </Stack>
            </TabPanel>
          </Box>
        </Stack>

        <LoadingButton
          onClick={() => handleReconcile(false)}
          loading={isLoading}
          disabled={extract.check}
          size="small"
          variant="contained"
          sx={{
            alignSelf: "center",
            order: !isDesktop ? 1 : undefined,
            width: !isDesktop ? "fit-content" : undefined,
          }}
        >
          Conciliar
        </LoadingButton>

        {!isDesktop && (
          <Typography sx={{ mb: -2, ml: 1 }} variant="body1" fontWeight={500}>
            Lançamentos cadastrados na plataforma
          </Typography>
        )}

        <ExtractTabNavigation extract={extract} companyToken={companyToken} />
      </Box>
    </Box>
  );
};

export default memo(ExtractItem);
