import { FC, MouseEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton, Link, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import UploadIcon from "@mui/icons-material/Upload";

import { DateTime } from "luxon";
import { Order } from "types/OpenCompany";
import {
  getCompanyType,
  translateOrderState,
  translateTypeService,
} from "../Marketplace/components/Legalization/OrdersTableItem";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import DialogViewDetails from "./DialogViewDetails";

interface SolicitationTableItemProps {
  order: Order;
  companyToken: string;
}

const SolicitationTableItem: FC<SolicitationTableItemProps> = ({ order, companyToken }) => {
  const [showDetails, setShowDetails] = useState(false);
  const navigate = useNavigate();

  const handleNavigate: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    navigate(e.currentTarget.pathname);
  };

  return (
    <>
      <TableRow hover>
        <TableCell>{DateTime.fromISO(order.createdOn).toFormat("dd/MM/yyyy")}</TableCell>
        <TableCell>{getCompanyType(order.companyType) ?? "-"}</TableCell>
        <TableCell>{translateTypeService[order.typeService] ?? "-"}</TableCell>
        <TableCell>{formatCurrencyBRL(order.value)}</TableCell>
        {order.orderState == 1 ? (
          <TableCell>
            <Link href="/meu-perfil" onClick={handleNavigate}>
              Aguardando Pagamento
            </Link>
          </TableCell>
        ) : (
          <TableCell>{translateOrderState[order.orderState]}</TableCell>
        )}

        <TableCell align="right">
          <Stack direction="row" gap={0.5} alignItems="center" justifyContent="end">
            <Tooltip title="Ver detalhes">
              <IconButton color="primary" onClick={() => setShowDetails(true)}>
                <SearchIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {[2, 3].includes(order.orderState) && (
              <Tooltip title={order.orderState == 2 ? "Drive" : "Aprovar Contrato Social"}>
                <IconButton color="primary" href="/drive" component="a" onClick={handleNavigate}>
                  <UploadIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </TableCell>
      </TableRow>

      {showDetails && (
        <DialogViewDetails
          open={showDetails}
          onClose={() => setShowDetails(false)}
          typeService={order.typeService === 0 ? "premium" : "free"}
          companyToken={companyToken}
        />
      )}
    </>
  );
};

export default SolicitationTableItem;
