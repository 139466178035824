import { FC, MouseEventHandler, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useQueryTemplate from "./hooks/useQueryTemplate";

import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import Fields from "./Fields";
import { defaultLayoutItems } from "./utils";
import { Layout } from "types/Template";
import Columns from "./Columns";

interface PageProps {
  id: number;
  companyToken: string;
}

const Page: FC<PageProps> = ({ companyToken, id }) => {
  const { data: template, isLoading } = useQueryTemplate(companyToken, id);
  const navigate = useNavigate();

  const handleNavigateLayouts: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    navigate(e.currentTarget.pathname);
  };

  const layouts = useMemo<Layout[]>(() => {
    if (!template) return defaultLayoutItems;

    if (!template.layout) return defaultLayoutItems;

    const l = JSON.parse(template.layout) as Layout[];
    return l?.length > 0 ? l : defaultLayoutItems;
  }, [template]);

  return (
    <>
      {isLoading && (
        <Stack height={500} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {!isLoading && !!template && (
        <>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="h4" fontWeight="bold">
              {template.name}
            </Typography>
            <Typography variant="h6" fontWeight={100} color="text.secondary">
              Layout
            </Typography>

            <Button
              variant="contained"
              size="small"
              component="a"
              href="/layout-exportacao"
              onClick={handleNavigateLayouts}
              sx={{ ml: 1 }}
            >
              Lista de Layouts
            </Button>
          </Stack>

          <Box mt={2} p={2} boxShadow={2} borderRadius={1} bgcolor="#fff" display="grid" gap={2}>
            <Fields layouts={layouts} companyToken={companyToken} id={id} />
            <Columns layouts={layouts} companyToken={companyToken} id={id} />
          </Box>
        </>
      )}
    </>
  );
};

export default Page;
