import * as yup from "yup";
import { formattedInput } from "components/CurrencyInput";

const validationSchema = yup.object().shape({
  category: yup
    .number()
    .required("A categoria é obrigatório")
    .typeError("A categoria é obrigatório"),
  description: yup.string().required("A descrição é obrigatório"),
  bank: yup.mixed().required("A conta é obrigatório"),
  paymentDate: yup.date().required("Informe a data").typeError("Digite uma data válida"),
  companyParticipant: yup.mixed().required("É necessário selecionar o cliente."),
  value: yup
    .string()
    .required("Informe o valor")
    .test("validPrice", "Digite um valor válido", (value) => {
      if (!value) return false;
      const price = formattedInput(value);
      return price >= 0;
    }),
});

export default validationSchema;
