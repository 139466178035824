const tranformToMaskType = (mask: (string | RegExp)[], itemToTransform: string | string[]) => {
  itemToTransform = Array.isArray(itemToTransform) ? itemToTransform : itemToTransform.split("");
  let scape = 0;

  const itemWithMask = mask
    .map((item, index) => {
      if (typeof item === "string") {
        scape = scape + 1;
        return item;
      }

      return itemToTransform[index - scape];
    })
    .filter((maskItem) => maskItem !== undefined);

  return itemWithMask.join("");
};

export default tranformToMaskType;
