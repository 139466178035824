import { forwardRef, Ref, useCallback } from "react";
import useQueryGetCNAEs from "pages/Home/hooks/useQueryGetCNAEs";

import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { CNAEs } from "types/CommonData";

type MyAutocompleteProps = Partial<AutocompleteProps<CNAEs, false, false, false, "div">>;
type Value = CNAEs | string | null | undefined;

export interface CNAEAutocompleteProps extends Omit<MyAutocompleteProps, "value"> {
  textFieldProps?: TextFieldProps;
  value: Value;
}

function CNAEAutocompleteInner(
  { textFieldProps, ...props }: CNAEAutocompleteProps,
  ref?: Ref<unknown>,
) {
  const { data: listCNAEs, isLoading } = useQueryGetCNAEs();

  const getValue = useCallback(
    (value: Value) => {
      if (value === null || value === undefined) return undefined;
      if (typeof value !== "string") {
        return value;
      }

      if (!listCNAEs) return undefined;
      return listCNAEs.find((cnae) => cnae.code === value);
    },
    [listCNAEs],
  );

  return (
    <>
      <Autocomplete
        {...props}
        disableListWrap
        value={getValue(props.value)}
        options={listCNAEs ?? []}
        autoHighlight
        isOptionEqualToValue={(option, value) => option?.code === value?.code}
        getOptionLabel={(option) => `${option?.code} - ${option?.name}`}
        noOptionsText="Sem opção"
        disabled={props.disabled || isLoading}
        ref={ref}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
              ...textFieldProps?.inputProps,
            }}
            InputProps={{
              ...textFieldProps?.InputProps,
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}

const CNAEAutocomplete = forwardRef(CNAEAutocompleteInner);
export default CNAEAutocomplete;
