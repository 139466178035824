import { FC } from "react";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";

import { CircularProgress, Stack, Typography } from "@mui/material";
import FormCompany from "../FormCompany";
import MyCompany from "pages/Home/pages/Profile/components/CompanyData/MyCompany";

interface CompanyTabProps {
  companyToken: string;
  onNext: () => void;
  onBack?: () => void;
  otherForm?: boolean;
  isPF?: boolean;
}

const CompanyTab: FC<CompanyTabProps> = ({
  companyToken,
  onBack,
  onNext,
  otherForm = false,
  isPF = false,
}) => {
  const { data: company, isLoading, isError } = useQueryGetCompany(companyToken);

  return (
    <>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}
      {!isLoading &&
        company &&
        (otherForm ? (
          <MyCompany
            companyInformation={company}
            onChangePage={onNext}
            onBack={onBack}
            isPF={isPF}
          />
        ) : (
          <FormCompany company={company} onNext={onNext} onBack={onBack} />
        ))}
      {isError && (
        <Typography>Não foi possível carregar a empresa. Tente novamente mais tarde</Typography>
      )}
    </>
  );
};

export default CompanyTab;
