import { BillingInfo, BillingInfoResponse } from "types/Billing";

const normalizeBillingInfo = (info: BillingInfoResponse): BillingInfo => {
  if (!info.informations) return info as BillingInfo;

  const newInformation: BillingInfo["informations"] = {};

  info.informations.forEach((info) => {
    newInformation[info.key] = info.value;
  });

  return {
    ...info,
    informations: newInformation,
  };
};

export default normalizeBillingInfo;
