import { FC } from "react";

import Delete from "@mui/icons-material/Delete";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { Control, Controller, FieldArrayWithId, useWatch } from "react-hook-form";
import { CreatePlanForm, periodicity } from ".";
import { formattedInput } from "components/CurrencyInput";
import ControllerInput from "components/ControllerInput";

import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface PaymentRecurrenceField {
  control: Control<CreatePlanForm>;
  field: FieldArrayWithId<CreatePlanForm, "paymentRecurrence", "id">;
  index: number;
  onRemove: (index: number) => void;
}

const PaymentRecurrenceField: FC<PaymentRecurrenceField> = ({
  control,
  field,
  index,
  onRemove,
}) => {
  const isTablet = useMediaQuery("(max-width:650px)");

  const pricePlan = useWatch({
    control,
    name: "price",
  });
  const unitPrice = useWatch({
    control,
    name: "unitPrice",
  });
  const percentDiscount = useWatch({
    control,
    name: `paymentRecurrence.${index}.percentDiscount`,
  });

  const formattedPricePlan = formattedInput(pricePlan);
  const formattedUnitPrice = formattedInput(unitPrice);

  const calcDiscount = (price: number, discount: number) => price - (price * discount) / 100;

  const planWithDiscount = formattedPricePlan
    ? calcDiscount(formattedPricePlan, percentDiscount)
    : 0.0;

  const unitPriceWithDiscount = formattedUnitPrice
    ? calcDiscount(formattedUnitPrice, percentDiscount)
    : 0.0;

  return (
    <Box>
      <Stack direction={isTablet ? "column" : "row"} gap={1}>
        <FormControl fullWidth sx={{ flex: 1 }}>
          <InputLabel>Recorrência</InputLabel>
          <Controller
            name={`paymentRecurrence.${index}.periodicity`}
            control={control}
            render={({ field, fieldState }) => (
              <>
                <Select
                  {...field}
                  value={field.value || ""}
                  label="Recorrência"
                  fullWidth
                  MenuProps={{ sx: { maxHeight: "50vh" } }}
                  error={!!fieldState.error?.message}
                  endAdornment={
                    <InputAdornment position="end" sx={{ mr: 2 }}>
                      <Tooltip title="Remover recorrência">
                        <IconButton size="small" onClick={() => onRemove(index)}>
                          <Delete fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  <MenuItem value="" disabled>
                    Selecione:
                  </MenuItem>

                  {Object.entries(periodicity).map((p) => (
                    <MenuItem key={p[1].toString()} value={p[1]}>
                      {p[0]}
                    </MenuItem>
                  ))}
                </Select>
                {!!fieldState.error?.message && (
                  <Typography variant="body1">{fieldState.error?.message}</Typography>
                )}
              </>
            )}
          />
        </FormControl>

        <ControllerInput
          control={control}
          name={`paymentRecurrence.${index}.percentDiscount`}
          inputProps={{
            label: "Desconto",
            type: "number",
            sx: { flex: 1 },
            InputProps: {
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            },
          }}
        />

        <TextField
          sx={{ flex: 1 }}
          disabled
          label="Plano"
          value={formatCurrencyBRL(planWithDiscount)}
        />
        <TextField
          sx={{ flex: 1 }}
          disabled
          label="Cada Licença"
          value={formatCurrencyBRL(unitPriceWithDiscount)}
        />
      </Stack>

      <ControllerInput
        control={control}
        name={`paymentRecurrence.${index}.recurrencePriceDescription`}
        inputProps={{
          fullWidth: true,
          sx: { mt: 1 },
          label: "Descrição de preço",
        }}
      />
    </Box>
  );
};

export default PaymentRecurrenceField;
