import * as yup from "yup";
import { formattedInput } from "components/CurrencyInput";

const numberValidation = yup
  .number()
  .nullable()
  .typeError("Digite apenas números")
  .moreThan(-1, "Digite apenas valores positivos");

const validationSchema = yup.object().shape({
  value: yup
    .string()
    .required("Por favor, informe o valor.")
    .test("valueIsValid", "Por favor, informe o valor.", (v) => {
      if (!v) return false;
      const format = formattedInput(v);
      return format > 0;
    }),

  serviceCodeId: yup
    .number()
    .required("O serviço prestado é obrigatório")
    .typeError("O serviço prestado é obrigatório"),

  iss: numberValidation,
  irpj: numberValidation,
  irop: numberValidation,
  pis: numberValidation,
  cofins: numberValidation,
  csll: numberValidation,
  description: yup.string().required("A discriminação da nota fiscal é obrigatório"),
});

export default validationSchema;
