import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("O nome da categoria analítica é obrigatória"),
  parentCategoryId: yup
    .number()
    .typeError("A categoria sintética é obrigatória")
    .required("A categoria sintética é obrigatória"),
  dreId: yup.string(),
});

export default validationSchema;
