import { useCallback } from "react";
import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import {
  getCompanyDocumentsDrive,
  GetCompanyDocumentsDrivePayload,
} from "services/Requests/companies";
import { CompanyDocumentsDrive } from "types/Company";
import addIfValueIsTruthy from "utils/addIfValueIsTruthy";

const useQueryCompanyDocumentsDrive = ({
  companyToken,
  contentType,
  endDate,
  fileName,
  parentId,
  startDate,
  onlyDirectory = false,
}: GetCompanyDocumentsDrivePayload & { onlyDirectory?: boolean }) => {
  let queryKey: (string | number)[] = [queryKeys.companyDocumentsDrive, companyToken];
  queryKey = addIfValueIsTruthy(queryKey, parentId, contentType, startDate, endDate, fileName);

  const selectorOnlyDirectiory = useCallback((documentsDrive: CompanyDocumentsDrive) => {
    return documentsDrive.filter((d) => d.type === "dir");
  }, []);

  const query = useQuery(
    queryKey,
    () =>
      getCompanyDocumentsDrive({
        companyToken,
        contentType,
        endDate,
        fileName,
        parentId,
        startDate,
      }),
    { keepPreviousData: true, select: onlyDirectory ? selectorOnlyDirectiory : undefined },
  );
  return query;
};

export default useQueryCompanyDocumentsDrive;
