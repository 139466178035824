import { FC } from "react";
import useQueryMonthClosure from "../hooks/useQueryMonthClosure";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Collapse,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MonthClosureDetails from "./MonthClosureDetails";
import getRandomInteger from "utils/getRandomInteger";
import { DateTime } from "luxon";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

const FIXED_DAY = "10";

interface MonthClosureItemProps {
  companyToken: string;
  month: string;
}

const MonthClosureItem: FC<MonthClosureItemProps> = ({ companyToken, month }) => {
  const {
    data: monthClosure,
    isLoading,
    isFetching,
  } = useQueryMonthClosure(companyToken, month + "/" + FIXED_DAY);

  return (
    <>
      {isLoading && (
        <Box
          alignItems="center"
          display="grid"
          gridTemplateColumns="1fr 2fr"
          bgcolor="background.paper"
          p={2}
          gap={6}
        >
          <Skeleton
            animation="wave"
            height={25}
            width={`${getRandomInteger(40, 90)}%`}
            variant="text"
          />

          <Stack alignItems="center" direction="row" gap={2}>
            <Skeleton animation="wave" height={10} width={30} variant="rectangular" />

            <Box width="100%">
              <Skeleton animation="wave" height={15} width="45%" variant="text" />
              <Skeleton animation="wave" height={15} width="70%" variant="text" />
            </Box>
          </Stack>
        </Box>
      )}

      {monthClosure && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Typography variant="h6" sx={{ width: "33%", flexShrink: 0 }}>
              Mês de {DateTime.fromISO(monthClosure.currentMonth).toFormat("LLLL / yyyy")}
            </Typography>

            <Stack ml={3} alignItems="center" justifyContent="center">
              <Chip
                label={monthClosure.isClosed ? "Fechado" : "Pendente"}
                color={monthClosure.isClosed ? "success" : "info"}
              />
            </Stack>
            <Box ml={2}>
              <Typography variant="body2">
                Receita total até o momento{" "}
                <Typography
                  component="strong"
                  variant="body2"
                  color="success.main"
                  fontWeight={600}
                >
                  {formatCurrencyBRL(monthClosure.invoicesTotal)}
                </Typography>
              </Typography>
              <Typography variant="body2">
                Despesas e pagamentos total até o momento{" "}
                <Typography component="strong" variant="body2" color="error.main" fontWeight={600}>
                  {formatCurrencyBRL(monthClosure.debitsTotal)}
                </Typography>
              </Typography>
            </Box>
          </AccordionSummary>

          <Collapse in={isFetching && !isLoading}>
            <LinearProgress />
          </Collapse>

          <AccordionDetails
            sx={{
              pt: 2,
              "*": {
                pointerEvents: isFetching && !isLoading ? "none" : "all",
                opacity: isFetching && !isLoading ? 0.8 : 1,
                userSelect: isFetching && !isLoading ? "none" : "auto",
                transition: "opacity 400ms ease",
              },
            }}
          >
            <MonthClosureDetails monthClosure={monthClosure} companyToken={companyToken} />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default MonthClosureItem;
