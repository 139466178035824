import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { createCorrection } from "services/Requests/stockControl";

const useMutationCreateCorrection = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createCorrection, {
    onSuccess(_, { companyToken, movementId }) {
      toast.success("Carta emitida. Acompanhe o status.");
      queryClient.invalidateQueries([queryKeys.invoiceCorrections, companyToken, movementId]);
    },
    onError(e: AxiosError<{ message: string }>) {
      const errorMessage =
        e?.response?.data?.message ??
        "Não foi possível emitir a carta. Tente novamente mais tarde.";
      toast.error(errorMessage);
    },
  });

  return mutation;
};

export default useMutationCreateCorrection;
