import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

import { deleteSubscription, DeleteSubscriptionPayload } from "services/Requests/billing";
import getSubdomain from "utils/getSubdomain";
import queryKeys from "react-query/constants";
import axios from "axios";
import { ClientDetailsWithPaymentMethod } from "types/Client";

const useMutationDeleteSubscription = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<DeleteSubscriptionPayload, "subdomain">) =>
      deleteSubscription({
        ...payload,
        subdomain,
      }),
    {
      onSuccess(_, { payerCompanyId, id }) {
        queryClient.invalidateQueries([queryKeys.clientBills, payerCompanyId]);
        toast.success(
          "Solicitação para exclusão de assinatura registrada com sucesso! Por favor aguarde um pouco para a solicitação ser processada.",
        );

        const clientDetailsKey = [queryKeys.clientDetails, payerCompanyId];
        const dataSubscriptions =
          queryClient.getQueryData<ClientDetailsWithPaymentMethod>(clientDetailsKey);

        if (!dataSubscriptions || !dataSubscriptions.subscriptionList.items) {
          queryClient.invalidateQueries(clientDetailsKey);
        } else {
          const newSubscriptionList = dataSubscriptions.subscriptionList.items.filter(
            (item) => item.id !== id,
          );
          queryClient.setQueryData(clientDetailsKey, {
            ...dataSubscriptions,
            subscriptionList: {
              items: newSubscriptionList,
            },
          });
        }
      },
      onError(err) {
        if (axios.isAxiosError(err) && err?.response?.status === 500) {
          toast.error(
            "Esta assinatura já foi deletada! Por favor aguarde um pouco para a solicitação ser processada",
          );
        } else {
          toast.error("Ocorreu um erro desconhecido");
        }
      },
    },
  );
  return mutation;
};

export default useMutationDeleteSubscription;
