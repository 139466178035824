import React, { FC, memo } from "react";
import { Control, useFieldArray, DefaultValues, UseFormSetValue } from "react-hook-form";
import useQueryProducts from "../../../hooks/useQueryProducts";

import { Button, Divider, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import MovementItem from "./MovementItem";
import { MovementForm, MovementFormItem } from "../utils/InterfacesForm";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

export const defaultMovementItem: DefaultValues<MovementFormItem> = {
  productId: undefined,
  unitValue: formatCurrencyBRL(0),
  quantity: 0,
  discount: formatCurrencyBRL(0),
  otherExpenses: formatCurrencyBRL(0),
  cfop: "",
  pisCst: null,
  pis: 0,
  icmsCst: "",
  icms: 0,
  cofinsCst: null,
  cofins: 0,
  ipiCst: null,
  ipi: 0,
};

interface ItemsControllerProps {
  control: Control<MovementForm>;
  companyToken: string;
  setValue: UseFormSetValue<MovementForm>;
  isConsultation: boolean;
}

const ItemsController: FC<ItemsControllerProps> = ({
  control,
  companyToken,
  setValue,
  isConsultation,
}) => {
  const { data: products = [] } = useQueryProducts(companyToken);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const handleAddNewItem = () => {
    append(defaultMovementItem);
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  const handleUpdateItem = (index: number, productId: number) => {
    const product = products.find((p) => p.id === productId);
    if (!product) return;

    const valuesToUpdate: Partial<MovementFormItem> = {
      unitValue: formatCurrencyBRL(product.averageUnitValue),
      cfop: product.cfop || "",
      pisCst: product.pisCst,
      pis: product.pis,
      icmsCst: product.icmsCst,
      icms: product.icms,
      cofinsCst: product.cofinsCst,
      cofins: product.cofins,
      ipiCst: product.ipiCst,
      ipi: product.ipi,
      notes: product.notes,
      ncm: product.ncm,
      taxBenefit: product.taxBenefit ?? "",
      taxSource: product?.taxSource || "",
      municipalTax: product?.municipalTax ?? null,
      federalTax: product?.federalTax ?? null,
      stateTax: product?.stateTax ?? null,
    } as const;

    Object.entries(valuesToUpdate).forEach(([key, value]) => {
      setValue(`items.${index}.${key as keyof MovementFormItem}`, value as any);
    });
  };

  return (
    <>
      {fields.map((field, index, arr) => (
        <React.Fragment key={field.id}>
          <MovementItem
            control={control}
            index={index}
            companyToken={companyToken}
            requestUpdate={handleUpdateItem}
            isConsultation={isConsultation}
          />

          {arr.length - 1 !== 0 && !isConsultation && (
            <Stack alignItems="end">
              <Button
                onClick={() => handleRemoveItem(index)}
                size="small"
                startIcon={<DeleteIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                Excluir
              </Button>
            </Stack>
          )}

          {index !== arr.length - 1 && <Divider sx={{ mt: 2, mb: 3 }} />}
        </React.Fragment>
      ))}

      {!isConsultation && (
        <>
          <Divider sx={{ my: 2 }} />

          <Button size="small" startIcon={<AddIcon fontSize="small" />} onClick={handleAddNewItem}>
            Adicionar item
          </Button>
        </>
      )}
    </>
  );
};

export default memo(ItemsController);
