import { FC, useState } from "react";
import useMutationDeleteTransaction from "pages/Home/hooks/useMutationDeleteTransaction";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { LoadingButton } from "@mui/lab";

interface DialogReleaseDeleteProps extends Omit<DialogProps, "id" | "onClose"> {
  onClose: () => void;
  id: number | number[];
  companyToken: string;
  hasParcel: boolean;
}

const DialogReleaseDelete: FC<DialogReleaseDeleteProps> = ({
  companyToken,
  onClose,
  id,
  hasParcel,
  ...props
}) => {
  const isArrayOfId = Array.isArray(id);

  const { isLoading, mutateAsync } = useMutationDeleteTransaction();
  const [allRecurrents, setAllRecurrents] = useState<1 | 0>(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllRecurrents(Number((event.target as HTMLInputElement).value) as 1 | 0);
  };

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync({
      companyToken,
      id: isArrayOfId ? undefined : id,
      ids: isArrayOfId ? id : undefined,
      allRecurrents: hasParcel ? allRecurrents : undefined,
    });
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} {...props} fullWidth maxWidth="sm">
      <DialogTitleWithCloseButton onClose={handleClose}>
        {hasParcel && "Excluir lançamento recorrente"}
        {!hasParcel && !isArrayOfId && "Excluir lançamento"}
        {!hasParcel && isArrayOfId && "Excluir lançamentos selecionados"}
      </DialogTitleWithCloseButton>

      <DialogContent>
        {!hasParcel ? (
          !isArrayOfId ? (
            "Deseja excluir este lançamento?"
          ) : (
            "Deseja excluir os lançamentos selecionados?"
          )
        ) : (
          <>
            <Typography gutterBottom>
              Este lançamento faz parte de uma série recorrente. Selecione por favor como excluir.
            </Typography>

            <FormControl>
              <RadioGroup value={allRecurrents} onChange={handleChange}>
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Excluir somente esse lançamento"
                />
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Excluir esse e todos os próximos lançamentos da série"
                />
              </RadioGroup>
            </FormControl>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton onClick={handleDelete} loading={isLoading} variant="contained">
          Excluir
        </LoadingButton>
        <Button disabled={isLoading} onClick={handleClose} variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogReleaseDelete;
