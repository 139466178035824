import endpoints from "../endpoints";
import api from "../api";
import { LatestTransactions } from "types/Dashboard";
import { Layout, Template } from "types/Template";

export type TypeExport = "ALTERDATATXT" | "DOMINIO" | "QUESTOR" | "QUESTORCSV" | "SAGE";

export interface ExportToPayload {
  templateId: TypeExport;
  companyToken: string;
  endDate: string;
  startDate: string;
  releases: LatestTransactions[];
}

export const exportTo = async (payload: ExportToPayload) => {
  const endpoistToExport: Record<TypeExport, string> = {
    ALTERDATATXT: endpoints.template.exportToAlterdataTXT,
    DOMINIO: endpoints.template.exportToDominio,
    QUESTOR: endpoints.template.exportToQuestorTxt,
    QUESTORCSV: endpoints.template.exportToQuestorCsv,
    SAGE: endpoints.template.exportToSAGE,
  };

  const { data } = await api.post<{ fileToken: string }>(
    endpoistToExport[payload.templateId],
    payload,
  );
  return data.fileToken;
};

export interface GetTemplatesPayload {
  subdomain: string;
}

export const getTemplates = async (payload: GetTemplatesPayload) => {
  const { data } = await api.post<Template[]>(endpoints.template.getTemplates, payload);
  return data;
};

export interface GetTemplatePayload {
  id: number;
  companyToken: string;
}

export const getTemplate = async (payload: GetTemplatePayload) => {
  const { data } = await api.post<Template>(endpoints.template.getTemplate, payload);
  return data;
};

export interface CreateTemplatePayload {
  companyToken: string | null;
  name: string;
  separate: string;
  delimiter?: string;
  extension?: string;
  type?: string;
}

export const createTemplate = async (payload: CreateTemplatePayload) => {
  const { data } = await api.post(endpoints.template.createTemplate, payload);
  return data;
};

export interface UpdateTemplatePayload extends CreateTemplatePayload {
  id: number;
}

export const updateTemplate = async (payload: UpdateTemplatePayload) => {
  const { data } = await api.post(endpoints.template.updateTemplate, payload);
  return data;
};

export interface DeleteTemplatePayload {
  id: number;
  companyToken: string;
}

export const deleteTemplate = async (payload: DeleteTemplatePayload) => {
  const { data } = await api.post(endpoints.template.deleteTemplate, payload);
  return data;
};

export interface UpdateLayoutPayload {
  id: number;
  companyToken: string;
  items: Layout[];
}

export const updateLayout = async (payload: UpdateLayoutPayload) => {
  const { data } = await api.post(endpoints.template.updateLayout, payload);
  return data;
};
