import { User } from "types/User";

const checkUserPermission = (user: User, permission: string | string[]) => {
  permission = Array.isArray(permission) ? permission : [permission];

  if (user.userInfo.features[0] == "00") return true;
  if (permission.every((p) => user.userInfo.features.includes(p))) return true;

  return false;
};

export default checkUserPermission;
