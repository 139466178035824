import { FC } from "react";
import { IconButton } from "@mui/material";
import { useSlate } from "slate-react";
import { IconType, TextAlign, TypeCustomElement } from "../../slate";
import { isTextAlign, isBlockActive, toggleBlock } from "../../utils/functions";

interface ButtonProps {
  format: TypeCustomElement | TextAlign;
  Icon: IconType;
}

const BlockButton: FC<ButtonProps> = ({ format, Icon }) => {
  const editor = useSlate();
  const isActive = isBlockActive(editor, format, isTextAlign(format) ? "align" : "type");

  return (
    <IconButton
      size="small"
      color={isActive ? "primary" : "default"}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon
        fontSize="small"
        sx={{
          transition: "all 500ms ease",
          transform: `scale(${isActive ? "1.1" : "1"})`,
        }}
      />
    </IconButton>
  );
};

export default BlockButton;
