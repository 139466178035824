import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, Stack } from "@mui/material";
import ControllerInput from "components/ControllerInput";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { TemplateAccountanting } from "types/Company";
import useMutationCreateTemplateAccountanting from "../../hooks/useMutationCreateTemplateAccountanting";
import validationSchema from "./validationSchema";

interface NewOrEditTemplateProps extends DialogProps {
  template?: TemplateAccountanting;
}

const NewOrEditTemplate: FC<NewOrEditTemplateProps> = ({ template, onClose, ...props }) => {
  const { mutateAsync, isLoading } = useMutationCreateTemplateAccountanting();

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: template?.name || "",
      mask: template?.mask || "",
    },
  });

  const handleClose = () => {
    if (isLoading) return;

    reset();
    onClose?.({}, "backdropClick");
  };

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      mask: data.mask,
      name: data.name,
      id: template?.id || 0,
    });
    handleClose();
  });

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {template ? "Editar" : "Novo"} template
      </DialogTitleWithCloseButton>

      <form onSubmit={onSubmit}>
        <DialogContent>
          <Stack gap={2}>
            <ControllerInput
              control={control}
              name="name"
              inputProps={{ label: "Nome do Template" }}
            />
            <ControllerInput
              control={control}
              name="mask"
              inputProps={{ label: "Máscara Contábil" }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton loading={isLoading} variant="contained" type="submit">
            Salvar
          </LoadingButton>
          <Button variant="outlined" disabled={isLoading} onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewOrEditTemplate;
