import { Button } from "@mui/material";
import { FC, useState } from "react";
import GroupIcon from "@mui/icons-material/Group";
import DialogGroups from "./DialogGroups";

const Groups: FC = () => {
  const [dialogConfigGroupIsOpen, setDialogConfigGroupIsOpen] = useState(false);
  const handleOpenDialog = () => setDialogConfigGroupIsOpen(true);
  const handleCloseDialog = () => setDialogConfigGroupIsOpen(false);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        startIcon={<GroupIcon fontSize="small" />}
        onClick={handleOpenDialog}
      >
        Configurar grupos
      </Button>
      {dialogConfigGroupIsOpen && (
        <DialogGroups open={dialogConfigGroupIsOpen} onClose={handleCloseDialog} />
      )}
    </>
  );
};

export default Groups;
