import { FC } from "react";
import useMutationDeleteDocumentDrive from "../../hooks/useMutationDeleteDocumentDrive";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { CompanyDocumentDrive } from "types/Company";

interface DialogDeleteFolderProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyDocumentDrive: CompanyDocumentDrive;
  fullPath: string;
}

const DialogDeleteFolder: FC<DialogDeleteFolderProps> = ({
  companyDocumentDrive,
  onClose,
  fullPath,
  ...props
}) => {
  const nameTypeOfDocument = companyDocumentDrive.type === "dir" ? "diretório" : "arquivo";
  const { isLoading, mutateAsync } = useMutationDeleteDocumentDrive();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync({
      documentToken: companyDocumentDrive.downloadToken,
      fullPath,
    });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Deletar {nameTypeOfDocument}
      </DialogTitleWithCloseButton>

      <DialogContent>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="center"
          mx="auto"
          width="70%"
        >
          <DeleteIcon color="error" fontSize="large" />
          <Typography variant="body1" lineHeight={1.1}>
            Você tem certeza que deseja deletar o {nameTypeOfDocument}{" "}
            <Typography fontWeight={600} component="strong" variant="inherit">
              {companyDocumentDrive.name}
            </Typography>
            ?
          </Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <LoadingButton onClick={handleDelete} variant="contained" loading={isLoading}>
          Deletar
        </LoadingButton>
        <Button variant="outlined" onClick={handleClose} disabled={isLoading}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteFolder;
