import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { TransactionAction } from "services/Requests/companies";

const transactionsType: { [x in Exclude<TransactionAction, "FC"> | "default"]: string } = {
  default: "Todos",
  AP: "A Pagar",
  PA: "Pago",
  AR: "A Receber",
  RE: "Recebido",
  PR: "Pagos e Recebidos",
  APR: "A Pagar e Receber",
  TM: "Todos menos saldo anterior",
};

interface TransactionTypeFilterProps {
  value: TransactionAction | null;
  setValue: (actions: TransactionAction | null) => void;
}

const TransactionTypeFilter: FC<TransactionTypeFilterProps> = ({ setValue, value }) => {
  return (
    <FormControl>
      <InputLabel>Mostrando</InputLabel>
      <Select
        value={value || "default"}
        label="Mostrando"
        onChange={(e) =>
          setValue(e.target.value === "default" ? null : (e.target.value as TransactionAction))
        }
      >
        {Object.entries(transactionsType).map(([type, label]) => (
          <MenuItem value={type} key={type}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TransactionTypeFilter;
