import { FC, useMemo } from "react";

import { Box, Typography } from "@mui/material";
import { BankAccountCompanyParticipants } from "types/Company";

interface Bank extends BankAccountCompanyParticipants {
  bankNameAsString: string;
}

interface BankConsultProps {
  bank: Bank;
}

const BankConsult: FC<BankConsultProps> = ({ bank }) => {
  const bankPresent = useMemo(() => {
    return bank.bankNumber && bank.bankNameAsString && bank.agency && bank.account;
  }, [bank]);

  return (
    <>
      <Typography variant="h6" fontWeight={400} sx={{ mt: 2 }}>
        Informações Bancárias
      </Typography>
      <Box mt={1} border={1} borderColor="divider" p={2}>
        <Typography variant="body1">
          {bankPresent ? (
            <>
              {bank.bankNumber} {bank.bankNameAsString}
              <br />
              Agência: {bank.agency}, Conta: {bank.account}
            </>
          ) : (
            "Dados bancários não informados"
          )}
        </Typography>
      </Box>
    </>
  );
};

export default BankConsult;
