import { useMutation, useQueryClient } from "react-query";
import { UpdateCompanyData, updateCompanyData } from "services/Requests/companies";

import toast from "react-hot-toast";
import getSubdomain from "utils/getSubdomain";
import queryKeys from "react-query/constants";
import errorHandlerCompany from "utils/errorHandlerCompany";

const useMutationUpdateCompanyData = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<UpdateCompanyData, "subdomain">) =>
      updateCompanyData({
        ...payload,
        subdomain,
      }),
    {
      onSuccess(data, { companyToken }) {
        if (data) {
          toast.success("Dados atualizados com sucesso!");
          queryClient.invalidateQueries([queryKeys.company, companyToken]);
          queryClient.invalidateQueries(queryKeys.ordersByAssignments, { exact: false });
        }
      },
      onError: errorHandlerCompany,
    },
  );
  return mutation;
};

export default useMutationUpdateCompanyData;
