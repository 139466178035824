import { FC } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useClientInformation } from "../../../../context/ClientInformation";
import useMutationUpdateSubscription from "../../../../hooks/useMutationUpdateSubscription";

import { Box, Button, Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CurrencyInput, { formattedInput } from "components/CurrencyInput";

import { Subscription } from "types/Subscription";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

interface FormSubItems {
  subItems: {
    description: string;
    value: string;
    _destroy: boolean;
    recurrent: boolean;
    itemId?: string;
  }[];
}

interface FormEditSubItemsProps {
  subscription: Subscription;
  onAfterUpdate: () => void;
}

const FormEditSubItems: FC<FormEditSubItemsProps> = ({ subscription, onAfterUpdate }) => {
  const { mutateAsync } = useMutationUpdateSubscription();
  const { companyToken, ownerId, payerCompanyId } = useClientInformation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormSubItems>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      subItems: subscription.subitems.map((item) => ({
        _destroy: false,
        description: item.description,
        itemId: item.id,
        recurrent: item.recurrent,
        value: item.price,
      })),
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: "subItems",
  });

  const handleAddSubItem = () => {
    append({
      _destroy: false,
      description: "",
      itemId: undefined,
      recurrent: false,
      value: "",
    });
  };

  const onSubmit = handleSubmit(async ({ subItems }) => {
    await mutateAsync({
      id: subscription.id,
      companyToken,
      ownerId,
      payerCompanyId,
      dueDate: subscription.expires_at,
      items: subItems.map((item) => {
        if (item.itemId === undefined) {
          return {
            _destroy: item._destroy,
            description: item.description,
            recurrent: item.recurrent,
            value: formattedInput(item.value),
          };
        }

        const oldItem = subscription.subitems.find((subItem) => subItem.id === item.itemId);
        return {
          ...oldItem,
          _destroy: item._destroy,
          description: item.description,
          recurrent: item.recurrent,
          value: formattedInput(item.value),
        };
      }),
    });
    onAfterUpdate();
  });

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit(e);
      }}
    >
      <Stack gap={1}>
        {fields.map((field, index) => (
          <Box
            key={field.id}
            width="100%"
            display="grid"
            gap={1}
            gridTemplateColumns="4fr 2fr 1fr 1fr"
          >
            <Controller
              control={control}
              name={`subItems.${index}.description`}
              render={({ field, fieldState }) => (
                <TextField
                  size="small"
                  {...field}
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={`subItems.${index}.value`}
              render={({ field, fieldState }) => (
                <CurrencyInput
                  {...field}
                  inputProps={{
                    helperText: fieldState.error?.message,
                    error: !!fieldState.error?.message,
                    size: "small",
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name={`subItems.${index}._destroy`}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ m: 0, height: "auto" }}
                  control={
                    <Checkbox
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      checked={field.value}
                      value={field.value}
                      inputRef={field.ref}
                      size="small"
                    />
                  }
                  label="Excluir?"
                />
              )}
            />
            <Controller
              control={control}
              name={`subItems.${index}.recurrent`}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ m: 0, height: "auto" }}
                  control={
                    <Checkbox
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      checked={field.value}
                      value={field.value}
                      inputRef={field.ref}
                      size="small"
                    />
                  }
                  label="Recorrente?"
                />
              )}
            />
          </Box>
        ))}

        <Stack direction="row" gap={1}>
          <LoadingButton variant="contained" loading={isSubmitting} type="submit" size="small">
            Salvar Edição
          </LoadingButton>
          <Button disabled={isSubmitting} size="small" onClick={handleAddSubItem}>
            Novo subitem
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default FormEditSubItems;
