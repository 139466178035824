import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import toast from "react-hot-toast";
import { settleTransactions } from "services/Requests/companies";

const useMutationSettleTransactions = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(settleTransactions, {
    onSuccess(_, { transactionIds }) {
      toast.success("Conciliação realizada com sucesso!");

      transactionIds.forEach((id) => {
        queryClient.invalidateQueries([queryKeys.transaction, id], { exact: false });
      });
      queryClient.invalidateQueries(queryKeys.transactions, { exact: false });
    },
  });
  return mutation;
};

export default useMutationSettleTransactions;
