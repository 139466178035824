import { Dispatch, SetStateAction } from "react";
import { Box, Checkbox, FormControlLabel, InputLabel, Stack } from "@mui/material";

import { Control, Controller, FieldArrayWithId, Path } from "react-hook-form";
import { Descendant } from "slate";
import { ActiveAndContract, Contract } from "../../Fields";

import ControllerInput from "components/ControllerInput";
import CurrencyInput from "components/CurrencyInput";
import TextEditor from "components/TextEditor";
import { ServicePlan, Service, AccountabilityPlan } from "types/ServiceAndPlan";

const isService = (obj: Service | ServicePlan | AccountabilityPlan): obj is Service => {
  return "serviceType" in obj;
};

interface RequiredFields {
  name: string;
  agreement: string;
  isActive: boolean;
  services?: {
    name: string;
    serviceId: number;
    price: string;
    isActive: boolean;
  }[];
}

interface GeneralFormProps<T extends RequiredFields = RequiredFields> {
  control: Control<T>;
  companyToken: string;
  service: ServicePlan | Service;
  startText: Descendant[];
  setStartText: Dispatch<SetStateAction<Descendant[]>>;
  successPlan: Descendant[];
  setSuccessPlan: Dispatch<SetStateAction<Descendant[]>>;
  onlyContract: boolean;
  fields?: FieldArrayWithId<RequiredFields, "services", "id">[];
}

function GeneralForm<T extends RequiredFields = RequiredFields>({
  control,
  companyToken,
  service,
  startText,
  setStartText,
  successPlan,
  setSuccessPlan,
  fields,
  onlyContract,
}: GeneralFormProps<T>) {
  return (
    <>
      <Stack gap={2}>
        <ControllerInput name="name" control={control} inputProps={{ label: "Nome do Plano" }} />

        {onlyContract ? (
          <Contract companyToken={companyToken} control={control} />
        ) : (
          <ActiveAndContract companyToken={companyToken} control={control} />
        )}

        <Box>
          <InputLabel sx={{ mb: 0.5, pl: 2 }}>Texto de introdução</InputLabel>
          <TextEditor value={startText} onChange={(newValue) => setStartText(newValue)} />
        </Box>

        <Box>
          <InputLabel sx={{ mb: 0.5, pl: 2 }}>Texto de conclusão</InputLabel>
          <TextEditor value={successPlan} onChange={(newValue) => setSuccessPlan(newValue)} />
        </Box>

        {fields && isService(service) && service.serviceType !== "OpeningFree" && (
          <Stack gap={2} mt={1}>
            {fields.map((field, index) => (
              <Stack direction="row" alignItems="center" justifyContent="end" key={field.serviceId}>
                <Controller
                  name={`services.${index}.price` as Path<T>}
                  control={control}
                  render={({ field: props, fieldState }) => (
                    <CurrencyInput
                      name={props.name}
                      value={props.value as string}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                      ref={props.ref}
                      inputProps={{
                        sx: { flex: 1 },
                        label: field.name,
                        error: !!fieldState.error?.message,
                        helperText: fieldState.error?.message,
                        size: "small",
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`services.${index}.isActive` as Path<T>}
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ m: 0, height: "auto" }}
                      control={
                        <Checkbox
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          checked={!!field.value}
                          value={field.value}
                          inputRef={field.ref}
                        />
                      }
                      label="Oferecer este serviço"
                    />
                  )}
                />
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </>
  );
}

export default GeneralForm;
