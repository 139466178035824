import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { deleteCustomService } from "services/Requests/marketplace";

const useMutationDeleteCustomService = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteCustomService, {
    onSuccess() {
      queryClient.invalidateQueries(queryKeys.serviceAndPlans);
      toast.success("Serviço deletado com sucesso!");
    },
  });
  return mutation;
};

export default useMutationDeleteCustomService;
