import { FC, useState } from "react";
import { useCompanyInfo } from "context/CompanyInfo";
import useMutationUploadImage from "../hooks/useMutationUploadImage";

import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import UploadImage from "../components/UploadImage";
import { grey } from "@mui/material/colors";
import toast from "react-hot-toast";

interface LoginImageProps {
  companyToken: string;
}

const LoginImage: FC<LoginImageProps> = ({ companyToken }) => {
  const { mutate, isLoading } = useMutationUploadImage();

  const [file, setFile] = useState<File | null>(null);
  const { pathImage } = useCompanyInfo();

  const handleUploadImage = () => {
    if (!file) {
      toast.error("Selecione alguma imagem");
      return;
    }

    mutate({
      file,
      companyToken,
      type: "login",
    });
  };

  return (
    <Stack gap={3} flex={1} flexBasis={350}>
      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ borderBottom: 1, borderBottomColor: "divider" }}
      >
        Imagem do Login
      </Typography>

      <UploadImage
        file={file}
        onChange={(file) => setFile(file)}
        label="Upload da imagem do Login"
        initialImage={pathImage}
      />

      <Stack alignItems="end" justifyItems="end" bgcolor={grey[200]} p={2}>
        <LoadingButton
          disableElevation={false}
          variant="contained"
          loading={isLoading}
          onClick={handleUploadImage}
        >
          Salvar imagem
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default LoginImage;
