import { FC } from "react";
import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import ControllerInput from "components/ControllerInput";
import { Control, Controller } from "react-hook-form";
import { PlanForm } from ".";
import { ActiveAndContract, PaymentMethodFields, RecurrenceFields } from "../../../Fields";

interface GeneralPlanFormProps {
  control: Control<PlanForm>;
  companyToken: string;
}

const GeneralPlanForm: FC<GeneralPlanFormProps> = ({ control, companyToken }) => {
  return (
    <Stack gap={2}>
      <ControllerInput name="name" control={control} inputProps={{ label: "Nome do Plano" }} />

      <ActiveAndContract companyToken={companyToken} control={control} />

      <Box>
        <Typography
          variant="h5"
          sx={{ borderBottom: 1, borderBottomColor: "divider", fontWeight: 600, mb: 2 }}
        >
          Cobrança
        </Typography>

        <Stack direction="row" gap={2}>
          <PaymentMethodFields control={control} />
          <RecurrenceFields control={control} activeAfterDays={false} alwaysShowIsSameDayExp />
        </Stack>

        <Box>
          <Controller
            control={control}
            name="isManualBilling"
            render={({ field }) => (
              <FormControlLabel
                sx={{ m: 0, height: "auto" }}
                control={
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={!!field.value}
                    value={!!field.value}
                    inputRef={field.ref}
                  />
                }
                label="Desativar assinatura automática"
              />
            )}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default GeneralPlanForm;
