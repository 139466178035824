import api from "services/api";
import { InvoiceConfiguration, MunicipalServiceResponse, PrefectureInvoice } from "types/Invoice";
import getFormData from "utils/objectToFormData";
import endpoints from "../endpoints";

export interface DownloadBatchPayload {
  items: {
    Number: string;
    URL: string | null;
    Status: string;
  }[];
  type: "xml" | "pdf";
}

export const downloadBatch = async (payload: DownloadBatchPayload) => {
  const { data } = await api.post<ArrayBuffer>(endpoints.invoice.downloadBatch, payload, {
    responseType: "arraybuffer",
  });
  return data;
};

export interface GetConfigurationPayload {
  companyToken: string;
}

export const getConfiguration = async (payload: GetConfigurationPayload) => {
  const { data } = await api.post<InvoiceConfiguration>(
    endpoints.invoice.getConfiguration,
    payload,
  );
  return data;
};

export interface PrefectureByCompanyParams {
  companyToken: string;
}

export const prefectureByCompany = async (params: PrefectureByCompanyParams) => {
  const { data } = await api.get<PrefectureInvoice>(endpoints.invoice.prefectureByCompany, {
    params,
  });
  return data;
};

export interface UploadLogoPayload {
  companyToken: string;
  file: File;
  id: number;
  invoiceType: "NFS-e";
}

export const uploadLogo = async (payload: UploadLogoPayload) => {
  await api.post(endpoints.invoice.uploadLogo, getFormData(payload));
};

export interface MunicipalServiceByCompanyParams {
  companyToken: string;
}

export const municipalServiceByCompany = async (params: MunicipalServiceByCompanyParams) => {
  const { data } = await api.get<MunicipalServiceResponse>(
    endpoints.invoice.municipalServiceByCompany,
    {
      params,
    },
  );
  return data;
};

export interface CreateConfigurationPayload {
  companyToken: string;
  password: string;
  id: number;
  specialRegime: string;
  cultural: number;
  simple: number;
  insc: string | null;
  serie: string;
  nextRPS: number;
  nextLot: number;
  description: string;
  notes: string;
  sendInvoice: string;
  sendInvoiceInterval: number;
  prefectureLogin: string;
  prefecturePass: string;
  prefectureToken: string;
  prefecturePhrase: string;
  aedf: string;
  invoiceType: string;
  nfeSerie: string | null;
  nfeNumber: number;
  nfceSerie?: string;
  nfceNumber?: number;
  cscId?: string;
  cscCode?: string;
  services: {
    id?: number;
    descricao: string;
    isManual: boolean;
    cnae: string;
    codigo: string;
    lc116: string;
    iss: number;
    irpj: number;
    irop: number;
    pis: number;
    cofins: number;
    csll: number;
    updateAction: "Update" | "Insert";
  }[];
  file?: File;
}

export const createConfiguration = async ({ services, ...payload }: CreateConfigurationPayload) => {
  const formData = getFormData(payload);
  formData.append("services", JSON.stringify(services));

  await api.post(endpoints.invoice.createConfiguration, formData);
};
