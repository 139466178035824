import { FC } from "react";
import useQuerySpreads from "pages/Home/hooks/useQuerySpreads";

import { Box, CircularProgress, Grow, Stack, TextField, Typography } from "@mui/material";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface TransactionFeesProps {
  companyToken: string;
}

const TransactionFees: FC<TransactionFeesProps> = ({ companyToken }) => {
  const {
    data: spreads,
    isLoading,
    isFetching,
  } = useQuerySpreads({
    companyToken,
  });

  return (
    <Box position="relative">
      <Grow in={!isLoading && isFetching}>
        <Stack alignItems="center" justifyContent="center" position="absolute" top={0} width="100%">
          <CircularProgress size={20} />
        </Stack>
      </Grow>

      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 2 }}
      >
        Taxas das transações
      </Typography>

      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}

      {spreads && (
        <Stack gap={2}>
          <TextField
            value={formatCurrencyBRL(spreads.invoiceSpread / 100)}
            label="Custo por boleto faturado"
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={formatCurrencyBRL(spreads.withdrawSpread / 100)}
            label="Custo por saque da conta"
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={spreads.creditCardSpread}
            label="Percentual cobrado para aquisição de pagamento por cartão de crédito"
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={spreads.ccAdvanceSpread}
            label="Percentual cobrado para antecipação de recebíveis do cartão de crédito"
            InputProps={{ readOnly: true }}
          />
        </Stack>
      )}
    </Box>
  );
};

export default TransactionFees;
