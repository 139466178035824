import { FC, memo, useState } from "react";
import { ImportOFXTransaction } from "types/Company";
import { ExtractContext } from ".";

interface ExtractProviderProps {
  children: React.ReactNode;
  updateExtracts: (extract: ImportOFXTransaction, index: number) => void;
  extracts: ImportOFXTransaction[];
}

const ExtractProviderInner: FC<ExtractProviderProps> = ({ children, extracts, updateExtracts }) => {
  const [extractsState, setExtractsState] = useState<ImportOFXTransaction[]>(extracts);
  const [fileList, setFileList] = useState<File[]>([]);

  function handleChangeExtract<T extends keyof ImportOFXTransaction>(
    extract: ImportOFXTransaction,
    key: T,
    value: ImportOFXTransaction[T],
  ) {
    const findIndex = extractsState.findIndex((e) => e.ofxId === extract.ofxId);

    extract[key] = value;

    updateExtracts(extract, findIndex);
  }

  const handleEnableEditExtract = (extract: ImportOFXTransaction) => {
    handleChangeExtract(extract, "check", false);
  };

  const handleRequestSave = (extract: ImportOFXTransaction) => {
    const findIndex = extractsState.findIndex((e) => e.ofxId === extract.ofxId);
    updateExtracts(extract, findIndex);
  };

  return (
    <ExtractContext.Provider
      value={{
        extracts: extractsState,
        onChangeExtract: handleChangeExtract,
        enableEditExtract: handleEnableEditExtract,
        fileList,
        onChangeFile(newFileList) {
          setFileList(newFileList);
        },
        requestSave: handleRequestSave,
      }}
    >
      {children}
    </ExtractContext.Provider>
  );
};

export const ExtractProvider = memo(ExtractProviderInner);
