import { FC, memo } from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "../../../context/Auth";

import NotFound from "../../NotFound";
import pathRoutes, { pathRoutesCompany } from "./pathRoutes";
import userTypeComplies from "../../../utils/userTypeComplies";
import getSubdomain from "utils/getSubdomain";

const RoutesThree: FC = () => {
  const {
    user: { userInfo, company },
  } = useAuth();
  const subdomain = getSubdomain();

  const renderMyCompanyRoute = () => {
    const userFeatures = userInfo.features;

    if (userInfo.userType === "User" || company?.registrationStatus === "Registered") {
      return <></>;
    }

    if (
      userFeatures[0] !== "00" &&
      (!userFeatures.includes("32") ||
        (!userFeatures.includes("33") && !userFeatures.includes("34")))
    ) {
      return <></>;
    }

    return (
      <>
        {pathRoutesCompany.map(({ Component, path }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </>
    );
  };

  return (
    <Routes>
      {pathRoutes.map(({ Component, path, index, type, forNewUser, ignoreRootSubdomain }) => {
        if (
          index &&
          userInfo.features[0] !== "00" &&
          !userInfo.features.includes(index.toString())
        ) {
          return null;
        }

        if (type && !userTypeComplies(type, userInfo.userType)) {
          return null;
        }

        const isNewUser =
          !userTypeComplies(["Accountant", "Admin"], userInfo.userType) &&
          (company?.cnpj == null || subdomain == "app");
        const isNewAccountant =
          company?.registrationStatus === "Registered" && userInfo.userType === "Accountant";

        if (!isNewUser && forNewUser === true) {
          return null;
        }

        if (isNewUser && forNewUser === false) {
          return null;
        }

        if (subdomain == "app" && ignoreRootSubdomain === true) {
          return null;
        }

        if (isNewAccountant && path !== "/contabilidade-online") {
          return null;
        }

        if (!isNewAccountant && path === "/contabilidade-online") {
          return null;
        }

        return <Route key={path} path={path} element={<Component />} />;
      })}

      {renderMyCompanyRoute()}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default memo(RoutesThree);
