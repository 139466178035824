import endpoints from "../endpoints";
import api from "../api";
import { HTMLString } from "types/SemanticTypes";

export interface SavePlanPayload {
  companyToken: string;
  name: string;
  planId: number; // 0 for create new plan
  isActive: boolean;
  intervalType: "months";
  interval: number;
  payableWith: null;
  bankSlip: boolean;
  creditCard: boolean;
  isExpirationDateCreationDay: boolean;
  usePayroll: boolean;
  payrollValue: 0;
  useProLabore: boolean;
  proLaboreValue: 0;
  documentToken: string;
  description: HTMLString;
  endText: HTMLString;
  isAutomaticBilling: boolean;
  priceDescription: string;
  price: number;
  unitPrice: number;
  useLicenses: number;
  paymentRecurrences: {
    periodicity: number;
    percentDiscount: number;
    priceDescription: string;
  }[];
}

export const savePlan = async (payload: SavePlanPayload) => {
  await api.post(endpoints.accountantPlan.save, payload);
};
