import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { createFinancialCategory } from "services/Requests/companies";

const useMutationCreateFinancialCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createFinancialCategory, {
    onSuccess(_, { groupId }) {
      toast.success("Categoria criada");
      queryClient.invalidateQueries([queryKeys.globalCategoriesByGroup, groupId]);
    },
  });

  return mutation;
};

export default useMutationCreateFinancialCategory;
