import { FC } from "react";
import useQueryClientChartOfAccounts from "../../hooks/useQueryClientChartOfAccounts";

import { CircularProgress, Stack } from "@mui/material";

import Import from "./Import";
import Transfer from "./Transfer";
import NewCategory from "./NewCategory";
import TemplateProvider from "pages/Home/pages/FinancialItem/context/Template/TemplateProvider";
import FinancialCategories from "pages/Home/pages/FinancialItem/FinancialCategories";

interface FinancialProps {
  companyToken: string;
  companyId: number;
}

const Financial: FC<FinancialProps> = ({ companyToken, companyId }) => {
  const { data, isLoading } = useQueryClientChartOfAccounts(companyToken, true);

  return (
    <>
      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {data && !isLoading && data.length === 0 && (
        <Stack alignItems="center">
          <Import noHaveCategories companyToken={companyToken} />
        </Stack>
      )}

      {data && !isLoading && data.length > 0 && (
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <NewCategory companyToken={companyToken} />
          <Transfer companyToken={companyToken} />
          <Import companyToken={companyToken} />

          <TemplateProvider
            data={data}
            isCompany={true}
            companyToken={companyToken}
            companyId={companyId}
          >
            <FinancialCategories />
          </TemplateProvider>
        </Stack>
      )}
    </>
  );
};

export default Financial;
