import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { uploadImage, UploadImagePayload } from "services/Requests/files";
import getSubdomain from "utils/getSubdomain";

const useMutationUploadImage = () => {
  const subdomain = getSubdomain();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload: Omit<UploadImagePayload, "path">) => uploadImage({ ...payload, path: subdomain }),
    {
      onSuccess(_, { type }) {
        const typeImage = type === "login" ? "login" : "topo";
        toast.success(`Imagem do ${typeImage} atualizada com sucesso`);
        queryClient.invalidateQueries([queryKeys.pathImage, subdomain]);
      },
    },
  );
  return mutation;
};

export default useMutationUploadImage;
