import { FC, useState } from "react";
import useMutationImportToFrom from "../../../hooks/useMutationImportToFrom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import TemplatesToFromSelect from "pages/Home/components/TemplatesToFromSelect";

interface DialogImportProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
}

const DialogImport: FC<DialogImportProps> = ({ onClose, companyToken, ...props }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const [alert, setAlert] = useState(false);

  const { mutateAsync, isLoading } = useMutationImportToFrom();

  const handleClose = () => {
    if (isLoading) return;
    setAlert(false);
    onClose();
  };

  const handleImport = async () => {
    if (!selectedTemplate) return;
    await mutateAsync({
      companyToken,
      id: selectedTemplate,
    });
    handleClose();
  };

  return (
    <>
      <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
        <DialogTitleWithCloseButton onClose={handleClose}>
          Importar Plano Financeiro
        </DialogTitleWithCloseButton>

        <DialogContent>
          <TemplatesToFromSelect
            label="Templates"
            FormControlProps={{ fullWidth: true }}
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(Number(e.target.value))}
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            disabled={!selectedTemplate}
            onClick={() => {
              setAlert(true);
            }}
          >
            Importar
          </LoadingButton>
          <Button variant="outlined" disabled={isLoading} onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={alert} onClose={() => setAlert(false)} maxWidth="xs" fullWidth>
        <DialogTitle>Confirmação</DialogTitle>
        <DialogContent>
          <Typography color="error">
            <Typography component="strong" color="error" fontWeight={600}>
              ATENÇÃO
            </Typography>{" "}
            - Após a escolha no novo template, é necessário ir em &quot;Transferir lançamentos&quot;
            e migrar os lançamentos paras as novas categorias, para que os lançamentos façam parte
            do novo template escolhido, e dos resultados de busca!
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setAlert(false);
              handleImport();
            }}
          >
            Sim
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogImport;
