import {
  CreateMovementItem,
  CreateMovementPayment,
  CreateMovementShipping,
} from "services/Requests/stockControl";
import { MovementFormItem, MovementFormPayment, ShippingForm } from "./InterfacesForm";
import { formattedInput } from "components/CurrencyInput";
import { FullMovementItem, FullMovementPayment } from "types/StockControl";
import calculateTotalItem from "./calculateTotalItem";

const movementShipping = (data: ShippingForm): CreateMovementShipping => ({
  ...data,
  value: formattedInput(data.value),
  carrierCpfCnpj: data.carrierCpfCnpj?.replace(/\D/g, "") ?? "",
  recipientCpfCnpj: data.recipientCpfCnpj?.replace(/\D/g, "") ?? "",
  carrierUsesIssuerData: Number(data.carrierUsesIssuerData),
  deliveryAddress: Number(data.deliveryAddress),
  recipientCity: data.recipientCity || "",
  carrierCity: data.carrierCity || "",
});

const createMovementItem = (item: MovementFormItem): CreateMovementItem => ({
  id: item?.itemId || 0,
  productId: item.productId,
  unitValue: formattedInput(item.unitValue),
  quantity: item.quantity,
  discount: formattedInput(item.discount),
  otherExpenses: formattedInput(item.otherExpenses),
  cfop: item.cfop,
  icmsCst: item.icmsCst || "",
  icms: item.icms || 0,
  modalityBCST: 0,
  aliquotST: 0,
  aliquotMvaST: 0,
  aliquotReductionBCST: 0,
  pisCst: item.pisCst || "",
  pis: item.pis,
  cofinsCst: item.cofinsCst || "",
  cofins: item.cofins || 0,
  ipiCst: item.ipiCst || "",
  ipi: item.ipi,
  lc116: "",
  iss: 0,
  anp: "",
  ufConsumption: "",
  updateAction: item.itemId ? "Update" : "Insert",
  notes: item.notes,
  totalValue: calculateTotalItem(item),
  ncm: item.ncm || "",
  taxBenefit: item.taxBenefit ?? null,
  taxSource: item.taxSource ?? null,
  municipalTax: Number(item.municipalTax) || null,
  stateTax: Number(item.stateTax) || null,
  federalTax: Number(item.federalTax) || null,
});

const movementItems = (
  data: MovementFormItem[],
  oldItems?: FullMovementItem[],
): CreateMovementItem[] => {
  const newItems: CreateMovementItem[] = [];

  if (oldItems) {
    const items: CreateMovementItem[] = oldItems.map((item) => {
      const dataItem = data.find((i) => i.itemId === item.id);

      if (!dataItem) {
        return {
          ...item,
          updateAction: "Delete",
        } as unknown as CreateMovementItem;
      }

      return createMovementItem(dataItem);
    });

    newItems.push(...items);
  }

  data.forEach((item) => {
    if (item.itemId) return;
    newItems.push(createMovementItem(item));
  });

  return newItems;
};

const createPaymentItem = (
  payment: MovementFormPayment,
  paymentType: string,
): CreateMovementPayment => ({
  id: payment.paymentId ?? 0,
  value: formattedInput(payment.value),
  paymentMode: payment.paymentMode,
  updateAction: payment.paymentId ? "Update" : "Insert",
  paymentType,
});

const createPayments = (
  data: MovementFormPayment[],
  paymentType: string,
  oldPayments?: FullMovementPayment[],
): CreateMovementPayment[] => {
  const newItems: CreateMovementPayment[] = [];

  if (oldPayments) {
    const items: CreateMovementPayment[] = oldPayments.map((item) => {
      const dataPayment = data.find((i) => i.paymentId === item.id);

      if (!dataPayment) {
        return {
          ...item,
          updateAction: "Delete",
        } as unknown as CreateMovementPayment;
      }

      return createPaymentItem(dataPayment, paymentType);
    });

    newItems.push(...items);
  }

  data.forEach((item) => {
    if (item.paymentId) return;
    newItems.push(createPaymentItem(item, paymentType));
  });

  return newItems;
};

export { createPayments, createPaymentItem, movementShipping, movementItems };
