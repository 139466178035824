import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getForms } from "services/Requests/marketplace";

const useQueryForms = () => {
  const query = useQuery(queryKeys.forms, getForms);
  return query;
};

export default useQueryForms;
