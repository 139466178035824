import { FC, memo } from "react";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ImportAccountsTableItem from "./ImportAccountsTableItem";

import { ImportAccount } from "types/Accountant";
import stringToSlug from "utils/stringToSlug";

interface ImportAccountsTableProps {
  importAccounts: ImportAccount[];
  onChangeAccount: (account: ImportAccount, index: number) => void;
  onDeleteAccount: (index: number) => void;
}

const ImportAccountsTable: FC<ImportAccountsTableProps> = ({
  importAccounts,
  onChangeAccount,
  onDeleteAccount,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tipo de Conta</TableCell>
            <TableCell>Conta Contábil</TableCell>
            <TableCell>Conta Reduzida</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {importAccounts.length === 0 && (
            <TableRow hover>
              <TableCell colSpan={5} align="center">
                Não há contas para importar.
              </TableCell>
            </TableRow>
          )}
          {importAccounts.map((importAccount, index) => (
            <ImportAccountsTableItem
              key={stringToSlug(importAccount.description) + index.toString()}
              importAccount={importAccount}
              onChangeAccount={(newAccount) => onChangeAccount(newAccount, index)}
              onDeleteAccount={() => onDeleteAccount(index)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(ImportAccountsTable);
