import { formattedInput } from "components/CurrencyInput";
import { CreateTransactionPayload, UpdateTransactionPayload } from "services/Requests/companies";
import { ReleaseDebitForm } from "../Form";

type PayloadType<T extends boolean> = T extends true
  ? UpdateTransactionPayload
  : CreateTransactionPayload;

function getPayload<T extends boolean>(
  isEdit: T,
  data: ReleaseDebitForm,
  companyToken: string,
  id: T extends true ? number : undefined,
): PayloadType<T> {
  const isPartner = data.paymentOrigin === "partner";

  return {
    categoryId: data.categoryId || "",
    globalCategoryId: data.globalCategoryId,
    companyToken,
    daysBefore: "0",
    dueDate: data.dueDate ? data.dueDate.toDateString() : undefined,
    isPartner,
    referenceDate: data.referenceDate ? data.referenceDate.toDateString() : undefined,
    partnerParticipantId: isPartner && data.partner ? data.partner : undefined,
    companyParticipantId:
      !isPartner && data.companyParticipant
        ? typeof data.companyParticipant === "number"
          ? data.companyParticipant
          : data.companyParticipant.id
        : undefined,
    paymentDate:
      data.paymentType === "received" && data.paymentDate
        ? data.paymentDate.toDateString()
        : undefined,
    periodicity: data.paymentType === "receive" ? data.periodicity : 0,
    parcel: data.paymentType === "receive" ? data.parcel?.toString() : undefined,
    repeat: "noParcel",
    sendInvoiceLater: "0",
    transactionType: "Debt",
    value: formattedInput(data.value),
    description: data.description,
    companyBankAccountId: data.companyBankAccountId || undefined,
    docNumber: data.docNumber,
    descDocument: data.descDocument,
    id: isEdit ? id : undefined,
    UpdateType: data.updateType,
  } as PayloadType<T>;
}

export default getPayload;
