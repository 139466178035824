import { FC } from "react";
import { useQueryClient as useQueryClientLib } from "react-query";
import useQueryClient from "pages/Home/hooks/useQueryClient";

import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import Invoices from "../../Invoices";
import queryKeys from "react-query/constants";
import { CompanyAssignments } from "types/Admin";
import { Company } from "types/Company";

const Loading = () => (
  <Stack alignItems="center" justifyContent="center" height={400}>
    <CircularProgress />
  </Stack>
);

interface InvoiceProps {
  company: CompanyAssignments | Company;
}

const Invoice: FC<InvoiceProps> = ({ company }) => {
  const { data: client, isLoading, isSuccess, isError } = useQueryClient(company.token);
  const queryClient = useQueryClientLib();

  return (
    <>
      {!!client && (
        <IconButton
          onClick={() =>
            queryClient.invalidateQueries([queryKeys.clientBills, client.payerCompanyId])
          }
        >
          <RefreshIcon />
        </IconButton>
      )}

      {isLoading && <Loading />}
      {!!client && !isLoading && (
        <Invoices
          companyToken={company.token}
          ownerId={client.ownerId}
          payerCompanyId={client.payerCompanyId}
        />
      )}

      {!client && isSuccess && !isLoading && <Typography>Nenhum resultado encontrado</Typography>}
      {isError && !isLoading && (
        <Typography>Ocorreu um error ao tentar pegar os dados, tente mais tarde</Typography>
      )}
    </>
  );
};

export default Invoice;
