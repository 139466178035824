import { FC, useState, useEffect } from "react";
import useQueryGetTransactionsWithPagination from "../hooks/useQueryGetTransactionsWithPagination";

import { Box, CircularProgress, Collapse, LinearProgress, Stack } from "@mui/material";

import { DateTime } from "luxon";
import { DateTypeFilter, FinancialCategory } from "types/Company";
import { TransactionAction } from "services/Requests/companies";

import CategoryFilter from "../../FinancialAccounting/components/CategoryFilter";
import BankAccountFilter from "../../FinancialAccounting/components/BankAccountFilter";
import FilterPeriod from "components/FilterPeriod";
import TransactionTypeFilter from "../components/TransactionTypeFilter";
import ReleasesTable from "./ReleasesTable";
import SearchInput from "components/SearchInput";
import DateTypesFilter from "../../FinancialAccounting/components/DateTypesFilter";

interface ReleasesListProps {
  companyToken: string;
}

const ReleasesList: FC<ReleasesListProps> = ({ companyToken }) => {
  const [date, setDate] = useState({
    start: DateTime.local().startOf("month").toISO(),
    end: DateTime.local().endOf("month").toISO(),
  });
  const [categoryFilter, setCategoryFilter] = useState<FinancialCategory | null>(null);
  const [bankAccountFilter, setBankAccountFilter] = useState<number | null>(null);
  const [actionFilter, setActionFilter] = useState<TransactionAction | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [dateTypeFilter, setDateTypeFilter] = useState<null | DateTypeFilter>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {
    setCurrentPage(1);
    setSelected([]);
  }, [categoryFilter, bankAccountFilter, actionFilter, searchValue, dateTypeFilter]);

  const {
    data: transactions,
    isLoading,
    isFetching,
  } = useQueryGetTransactionsWithPagination(
    companyToken,
    date.start,
    date.end,
    actionFilter,
    currentPage,
    pageSize,
    searchValue,
    categoryFilter,
    bankAccountFilter,
    dateTypeFilter,
  );

  return (
    <>
      <Box boxShadow={1} bgcolor="#fff" p={2} mb={2}>
        <Stack direction="row" gap={2} flexWrap="wrap">
          <FilterPeriod
            size="medium"
            label="Período"
            endDate={date.end}
            startDate={date.start}
            onChangeDates={(dates) => setDate(dates)}
          />
          <DateTypesFilter value={dateTypeFilter} setValue={setDateTypeFilter} type={4} />
          <TransactionTypeFilter value={actionFilter} setValue={setActionFilter} />
          <CategoryFilter
            companyToken={companyToken}
            categoryFilter={categoryFilter}
            setCategoryFilter={setCategoryFilter}
          />
          <BankAccountFilter
            companyToken={companyToken}
            value={bankAccountFilter}
            setValue={setBankAccountFilter}
          />
          <SearchInput label="Buscar lançamentos" value={searchValue} onChange={setSearchValue} />
        </Stack>
      </Box>

      <Box boxShadow={2} bgcolor="#fff">
        <Collapse in={!isLoading && isFetching}>
          <LinearProgress />
        </Collapse>

        <Box p={2}>
          {isLoading && (
            <Stack alignItems="center" justifyContent="center" height={400}>
              <CircularProgress />
            </Stack>
          )}

          {transactions && (
            <ReleasesTable
              actionFilter={actionFilter}
              date={date}
              transactions={transactions}
              companyToken={companyToken}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              selected={selected}
              onChangeSelected={setSelected}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ReleasesList;
