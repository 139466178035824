import { jsx } from "slate-hyperscript";
import { CustomEditor, CustomText, TextAlign, TextFormat, TypeCustomElement } from "../slate";
import {
  Descendant,
  Editor,
  Element as SlateElement,
  Node as NodeSlate,
  Text,
  Transforms,
} from "slate";

import { HTMLString } from "types/SemanticTypes";
import escapeHtml from "utils/escapeHtml";
import { LIST_TYPES, TEXT_ALIGN_TYPES } from "./constants";
import { createParagraphNode } from "./paragraph";

export const isMarkActive = (editor: CustomEditor, format: keyof Omit<CustomText, "text">) => {
  const marks = Editor.marks(editor);

  if (!marks) return false;
  return marks[format];
};

export const toggleMark = (editor: CustomEditor, format: TextFormat) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isTextAlign = (format: string): format is TextAlign => {
  return TEXT_ALIGN_TYPES.some((align) => align === format);
};

export const isBlockActive = (
  editor: CustomEditor,
  format: string,
  blockType: "type" | "align" = "type",
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    }),
  );

  return !!match;
};

export const toggleBlock = (editor: CustomEditor, format: TypeCustomElement | TextAlign) => {
  const isActive = isBlockActive(editor, format, isTextAlign(format) ? "align" : "type");
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !isTextAlign(format),
    split: true,
  });

  let newProperties: Partial<SlateElement>;

  if (isTextAlign(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList && !isTextAlign(format)) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const serialize = (node: NodeSlate): HTMLString => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);

    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }

    if (node.italic) {
      string = `<em>${string}</em>`;
    }

    if (node.underline) {
      string = `<u>${string}</u>`;
    }

    if (node.strikethrough) {
      string = `<del>${string}</del>`;
    }

    return string;
  }

  if (!SlateElement.isElement(node)) return "";

  const children: string = node.children.map((n) => serialize(n)).join("");

  const style: string = node.align ? ` style="text-align: ${node.align};"` : "";

  switch (node.type) {
    case "block-quote":
      return `<blockquote${style}><p>${children}</p></blockquote>`;
    case "bulleted-list":
      return `<ul${style}>${children}</ul>`;
    case "numbered-list":
      return `<ol${style}>${children}</ol>`;
    case "list-item":
      return `<li${style}>${children}</li>`;
    case "link":
      return `<a href="${node.href}">${children}</a>`;
    default:
      if (!children.trim().length) {
        return "";
      }
      return `<p${style}>${children}</p>`;
  }
};

const ELEMENT_TAGS: { [x: string]: (href?: string) => object } = {
  BLOCKQUOTE: () => ({ type: "block-quote" }),
  H1: () => ({ type: "heading-one" }),
  H2: () => ({ type: "heading-two" }),
  LI: () => ({ type: "list-item" }),
  OL: () => ({ type: "numbered-list" }),
  P: () => ({ type: "paragraph" }),
  PRE: () => ({ type: "code" }),
  UL: () => ({ type: "bulleted-list" }),
  A: (href) => ({ type: "link", href }),
  BR: () => ({ type: "break" }),
};

const TEXT_TAGS: { [x: string]: () => object } = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  STRIKE: () => ({ strikethrough: true }),
  S: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  STRONG: () => ({ bold: true }),
  B: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

export const deserialize = (el: HTMLElement, markAttributes = {}): Descendant | Descendant[] => {
  const align = el?.style?.textAlign || undefined;

  if (el.nodeType === Node.TEXT_NODE && el.textContent?.trim()) {
    return jsx("text", markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return [];
  }

  if (el.nodeName === "BR") return jsx("text", {}, "\n");

  if (el.nodeType === Node.TEXT_NODE && !el.textContent?.trim()) return [];

  const nodeAttributes = { ...markAttributes, ...TEXT_TAGS[el.nodeName]?.() };

  const children: Descendant[] = Array.from(el.childNodes)
    .map((node) => deserialize(node as HTMLElement, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }

  if (ELEMENT_TAGS[el.nodeName]) {
    return jsx(
      "element",
      { ...ELEMENT_TAGS[el.nodeName](el.getAttribute("href") || undefined), align },
      children,
    );
  } else {
    return children;
  }
};

export const HTMLStringToDescendant = (html: HTMLString): Descendant[] => {
  const initialDOMValue = new DOMParser().parseFromString(html, "text/html");
  const parsedToEditor = deserialize(initialDOMValue.body);

  if (!Array.isArray(parsedToEditor) || parsedToEditor.length === 1) {
    const onlyNode = Array.isArray(parsedToEditor) ? parsedToEditor[0] : parsedToEditor;

    if (Text.isText(onlyNode)) {
      return [createParagraphNode([onlyNode])];
    }
  }

  return Array.isArray(parsedToEditor) ? parsedToEditor : [parsedToEditor];
};

export const toHTMLString = (descendant: Descendant[]): HTMLString => {
  const toHTMLString = descendant.map(serialize).join("");
  return toHTMLString;
};

export const toOnlyText = (descendant: Descendant[]): string => {
  const findText = (descendant: Descendant): string => {
    if (Text.isText(descendant)) {
      return descendant.text;
    }

    return descendant.children.map(findText).join(" ");
  };

  return descendant.map(findText).join(" ");
};
