import { FC, useMemo, useState } from "react";
import useQueryAllTransaction from "../hooks/useQueryAllTransaction";

import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import SearchInput from "components/SearchInput";
import SortableTableHead from "components/SortableTableHead";
import WithCompany from "components/WithCompany";
import RequestAnticipations from "./RequestAnticipations";

import { Order } from "types/SemanticTypes";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { AnticipationTransaction } from "types/Billing";
import { DateTime } from "luxon";
import comparatorSortByProperty from "utils/comparatorSortByProperty";
import stableSort from "utils/stableSort";

type AnticipationKeys = keyof AnticipationTransaction;

interface HeadCells {
  label: string;
  id: AnticipationKeys;
  sortable?: boolean;
}

const headCells: HeadCells[] = [
  {
    label: "Pagamento",
    id: "scheduledDate",
    sortable: true,
  },
  {
    label: "Nome",
    id: "customerRef",
    sortable: true,
  },
  {
    label: "Descrição",
    id: "description",
    sortable: true,
  },
  {
    label: "Disponível",
    id: "clientShare",
    sortable: true,
  },
];

interface AnticipationsProps {
  companyToken: string;
}

const Anticipations: FC<AnticipationsProps> = ({ companyToken }) => {
  const [search, setSearch] = useState("");
  const [selectsAnticipations, setSelectsAnticipations] = useState<string[]>([]);
  const { data, isLoading, isError } = useQueryAllTransaction(companyToken, search);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<AnticipationKeys>("scheduledDate");

  const totalSelect = useMemo(() => {
    if (!data?.items) return 0;

    return selectsAnticipations.reduce((acc, curr) => {
      return (data?.items.find((item) => item.id === curr)?.clientShare ?? 0) + acc;
    }, 0);
  }, [selectsAnticipations]);

  const sortHandler = (property: AnticipationKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleToggleSelectAnticipation = (anticipation: string) => {
    setSelectsAnticipations((prev) => {
      const newSelects = [...prev];
      if (newSelects.includes(anticipation)) {
        newSelects.splice(newSelects.indexOf(anticipation), 1);
      } else {
        newSelects.push(anticipation);
      }
      return newSelects;
    });
  };

  const getDateFormat = (date: string) => {
    let dateTime = DateTime.fromFormat(date, "yyyy-MM-dd");
    if (dateTime.isValid) {
      return dateTime.toFormat("dd/MM/yyyy");
    }

    dateTime = DateTime.fromISO(date);
    if (dateTime.isValid) {
      return dateTime.toFormat("dd/MM/yyyy");
    }

    return "";
  };

  return (
    <>
      <Stack
        pb={3}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
      >
        <SearchInput value={search} onChange={(s) => setSearch(s)} />

        <Stack direction="row" alignItems="center" gap={1}>
          <Typography>
            Solicitado para antecipação:{" "}
            <Typography component="strong" fontWeight={600}>
              {formatCurrencyBRL(totalSelect)}
            </Typography>
          </Typography>
          <RequestAnticipations
            selecteds={selectsAnticipations}
            total={totalSelect}
            companyToken={companyToken}
          />
        </Stack>
      </Stack>

      <Divider sx={{ mb: 4 }} />

      <Box>
        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={500}>
            <CircularProgress />
          </Stack>
        )}

        {isError && (
          <Typography>
            Não foi possível obter as antecipações. Tente novamente mais tarde.
          </Typography>
        )}

        {!isLoading && !isError && data?.items && (
          <TableContainer>
            <Table size="small">
              <SortableTableHead
                headCells={headCells}
                onRequestSort={(e, property) => sortHandler(property)}
                order={order}
                orderBy={orderBy}
              >
                <TableCell align="right">Antecipar?</TableCell>
              </SortableTableHead>

              <TableBody>
                {data.items.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={headCells.length + 1}>
                      Não foram encontrados antecipações
                    </TableCell>
                  </TableRow>
                )}

                {stableSort(data.items, comparatorSortByProperty(order, orderBy)).map(
                  (transaction) => (
                    <TableRow key={transaction.id} hover>
                      <TableCell>{getDateFormat(transaction.scheduledDate)}</TableCell>
                      <TableCell>{transaction.customerRef}</TableCell>
                      <TableCell>{transaction.description}</TableCell>
                      <TableCell>{formatCurrencyBRL(transaction.clientShare)}</TableCell>
                      <TableCell padding="checkbox">
                        <Checkbox
                          size="small"
                          color="primary"
                          checked={selectsAnticipations.includes(transaction.id)}
                          onChange={() => handleToggleSelectAnticipation(transaction.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
};

export default WithCompany(Anticipations);
