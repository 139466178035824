import { createContext, Dispatch, SetStateAction } from "react";
import { Company } from "types/Company";
import { UserWithCompany } from "../../types/User";

interface AuthContextProps {
  user: UserWithCompany;
  setUser: Dispatch<SetStateAction<UserWithCompany>>;
  isAuthenticated: boolean;
  login: (user: UserWithCompany) => void;
  logout: () => void;
  loginTheClient: (token: string) => Promise<void>;
  previousUser: UserWithCompany | null;
  changeToPreviousUser: () => void;
  changeCompany: (company: Company) => void;
}

export const AuthContext = createContext({} as AuthContextProps);
