import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getFinancialCategories } from "services/Requests/companies";

const useQueryGetFinancialCategories = (companyToken: string) => {
  const query = useQuery(queryKeys.financialCategories, () =>
    getFinancialCategories({ companyToken }),
  );
  return query;
};

export default useQueryGetFinancialCategories;
