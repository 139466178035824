import { FC } from "react";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ServicePlan } from "types/ServiceAndPlan";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { getColorStatus } from "./CardService";

interface AccountantTableProps {
  isIuguActivate: boolean;
  servicePlan: ServicePlan[];
}

const AccountantTable: FC<AccountantTableProps> = ({ isIuguActivate, servicePlan }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Mensal</TableCell>
            <TableCell>Licenças</TableCell>
            <TableCell>Unitário</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {servicePlan.map((service) => (
            <TableRow key={service.planId} hover sx={{ "&:last-child td": { border: 0 } }}>
              <TableCell>{service.name}</TableCell>
              <TableCell>{formatCurrencyBRL(service.price)}</TableCell>
              <TableCell>{service.useLicenses}</TableCell>
              <TableCell>{formatCurrencyBRL(service.unitPrice)}</TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Box
                    width={10}
                    height={10}
                    borderRadius="50%"
                    bgcolor={getColorStatus(service.iuguPlanId, isIuguActivate)}
                  />
                  {service.isActive ? "Plano ativo" : "Plano inativo"}{" "}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountantTable;
