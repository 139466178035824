import { FC } from "react";

import { Stack } from "@mui/material";

import ChatItem from "./ChatItem";
import { Chat, ChatInformation } from "types/Mailbox";

interface ChatGroupProps {
  ticket: Chat;
  chatInformation: ChatInformation;
}

const ChatGroup: FC<ChatGroupProps> = ({ ticket, chatInformation }) => {
  return (
    <Stack flex={1} gap={1.5} sx={{ overflowY: "auto" }} py={2} px={4} mt={1}>
      {ticket.messages.map((message) => (
        <ChatItem
          key={message.messageId}
          chatInformation={chatInformation}
          message={message}
          ticket={ticket}
        />
      ))}
    </Stack>
  );
};

export default ChatGroup;
