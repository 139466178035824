import { FC, MouseEventHandler } from "react";

import { Card, CardActionArea, CardContent, Typography, Stack, Box } from "@mui/material";
import { green, red, yellow } from "@mui/material/colors";
import { Service, ServicePlan } from "types/ServiceAndPlan";

export const getColorStatus = (iuguPlanId: string | null, isIuguActivate: boolean) => {
  if (!isIuguActivate) return red[400];

  if (isIuguActivate && !iuguPlanId) return yellow[400];

  if (isIuguActivate && iuguPlanId) return green[400];
};

const isService = (obj: Service | ServicePlan): obj is Service => {
  return "serviceType" in obj;
};

interface CardServiceProps {
  service: ServicePlan | Service;
  isIuguActivate: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const CardService: FC<CardServiceProps> = ({ isIuguActivate, service, onClick }) => {
  return (
    <Card>
      <CardActionArea sx={{ height: "100%" }} onClick={onClick}>
        <CardContent>
          <Typography color="text.primary" gutterBottom>
            {isService(service) ? service.groupName || service.name : service.name}
          </Typography>
          <Stack direction="row" alignItems="center" gap={1}>
            <Box
              width={10}
              height={10}
              borderRadius="50%"
              bgcolor={getColorStatus(service.iuguPlanId, isIuguActivate)}
            />
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              {service.isActive ? "Plano ativo" : "Plano inativo"}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardService;
