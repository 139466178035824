const queryKeys = {
  styles: "styles",
  pathImage: "path-image",
  typeSubdomain: "type-subdomain",
  plans: "plans",
  clients: "clients",
  infoBilling: "info-billing",
  infoAccount: "info-account",
  infoAccountAdmin: "info-account-admin",
  clientDetails: "client-details",
  clientBills: "client-bills",
  clientSpreads: "client-spreads",
  allSubAccounts: "all-sub-accounts",
  userAdminList: "users-admin-list",
  companyProfile: "company-profile",
  profile: "profile",
  company: "company",
  CNAEsList: "cnaes-list",
  paymentInfo: "payment-info",
  paymentInfoProfile: "payment-info-profile",
  counties: "counties",
  companyPartners: "company-partners",
  serviceAndPlans: "service-and-plans",
  contracts: "contracts",
  groups: "groups",
  usersAdm: "users-adm",
  monthClosureConfiguration: "month-closure-configuration",
  getConfiguration: "configuration-email",
  messagesTypes: "messages-types",
  emailMessage: "email-message",
  templateFinancial: "template-financial",
  templateAccountanting: "template-accountanting",
  defaultAccountabilityPlan: "default-accountability-plan",
  companyParticipants: "company-participants",
  bank: "bank",
  states: "states",
  individualCompanyParticipant: "individual-company-participant",
  companyBankAccounts: "company-bank-accounts",
  financialCategories: "financial-categories",
  companyPartnerDetail: "company-partner-detail",
  allChats: "all-chats",
  getTicketById: "get-ticket-by-id",
  openMonths: "open-months",
  monthClosure: "month-closure",
  transaction: "transaction",
  summary: "summary",
  latestTransactions: "latest-transactions",
  pieChartData: "pie-chart-data",
  revenuesVsExpenses: "revenues-vs-expenses",
  allSubs: "all-subs",
  usersBills: "users-bills",
  dunning: "dunning",
  penaltiesAndDiscounts: "penalties-and-discounts",
  financialSummary: "financial-summary",
  transactions: "transactions",
  invoiceConfig: "invoice-config",
  balance: "balance",
  transactionsSearch: "transactions-search",
  transactionsMatch: "transactions-match",
  groupChartOfAccounts: "group-chart-of-accounts",
  chartOfAccountsFromToGroup: "chart-of-accounts-from-to-group",
  globalCategoriesByGroup: "global-categories-by-group",
  globalAccountanting: "global-accountanting",
  leads: "leads",
  forms: "forms",
  groupsForm: "groups-form",
  clientsConfigLeads: "clients-config-leads",
  companyDocumentsDrive: "company-documents-drive",
  companyAssignments: "company-assignments",
  chats: "chats",
  companyGroups: "company-groups",
  ticket: "ticket",
  allNotifications: "all-notifications",
  usersCompanies: "users-companies",
  unpresented: "unpresented",
  companiesPost: "companies-post",
  stockControlProducts: "stock-control-products",
  stockControlBalance: "stock-control-balance",
  stockControlMovements: "stock-control-movements",
  stockControlMovement: "stock-control-movement",
  modalities: "modalities",
  invoiceConfigStockControl: "invoice-config-stock-control",
  ordersByAssignments: "orders-by-assignments",
  getLegals: "get-legals",
  openCompanyProfile: "open-company-profile",
  companyActivities: "company-activities",
  CNAEsByActivity: "cnaes-by-activity",
  getClient: "get-client",
  allNotificationsToAccountant: "all-notifications-to-accountant",
  clientChartOfAccounts: "client-chart-of-accounts",
  templatesToFrom: "templates-to-from",
  categoriesWithReleases: "categories-with-releases",
  companyGlobalAccountanting: "company-global-accountanting",
  companyChartOfAccountsFromTo: "company-chart-of-accounts-from-to",
  listFilesIntegration: "list-files-integration",
  fileHistoryForIntegrationCompany: "file-history-for-integration-company",
  financial: "financial",
  invoices: "invoices",
  invoicesStockControl: "invoices-stock-control",
  invoiceConfiguration: "invoice-configuration",
  prefectureByCompany: "prefecture-by-company",
  invoiceCorrections: "invoice-corrections",
  municipalServiceByCompany: "municipal-service-by-company",
  servicesAndPlansClient: "services-and-plans-client",
  allTransactions: "all-transactions",
  templates: "templates",
  ordersByCompany: "orders-by-company",
  accountantApps: "accountant-apps",
  osaykPlanByProduct: "osayk-plan-by-product",
  registrationProfile: "registration-profile",
  bankingIntegratorTransaction: "banking-integrator-transaction",
  configBlockRelease: "config-block-release",
  presenceIndicator: "presence-indicator",
  paymentTypes: "payment-types",
  paymentModes: "payment-modes",
  dreList: "dre-list",
};

export type QueryKeys = typeof queryKeys;
export type KeyofQueryKeys = keyof QueryKeys;

export const permanentQueryKeys: KeyofQueryKeys[] = [
  "pathImage",
  "styles",
  "typeSubdomain",
  "counties",
  "states",
  "modalities",
  "companyActivities",
  "CNAEsByActivity",
];

export default queryKeys;
