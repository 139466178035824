import { CustomEditor } from "../slate";

const withLinks = (editor: CustomEditor) => {
  const { isInline } = editor;

  editor.isInline = (element) => (element.type === "link" ? true : isInline(element));

  return editor;
};

export default withLinks;
