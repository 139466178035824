import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getInfoAccount, GetInfoAccountPayload } from "services/Requests/billing";

const useQueryInfoAccount = (payload: GetInfoAccountPayload, enabled?: boolean) => {
  const query = useQuery(
    [queryKeys.infoAccount, payload.companyToken],
    () => getInfoAccount(payload),
    {
      enabled,
    },
  );
  return query;
};

export default useQueryInfoAccount;
