import { FC, useState } from "react";

import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";

import DialogImport from "./DialogImport";

interface ImportProps {
  noHaveCategories?: boolean;
  companyToken: string;
}

const Import: FC<ImportProps> = ({ companyToken, noHaveCategories = false }) => {
  const [showImportDialog, setShowImportDialog] = useState(false);
  const Icon = noHaveCategories ? DownloadIcon : ChangeCircleIcon;

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Icon fontSize="small" />}
        onClick={() => setShowImportDialog(true)}
        size="small"
      >
        {noHaveCategories ? "Importar De → Para" : "Escolher outro template"}
      </Button>

      <DialogImport
        open={showImportDialog}
        companyToken={companyToken}
        onClose={() => setShowImportDialog(false)}
      />
    </>
  );
};

export default Import;
