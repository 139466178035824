import { FC } from "react";

import { Control, Controller } from "react-hook-form";
import { ReleaseDebitForm } from "./Form";

import ControllerInput from "components/ControllerInput";
import CurrencyInput from "components/CurrencyInput";
import BankAccountSelect from "../BankAccountSelect";

interface DescriptionFormProps {
  control: Control<ReleaseDebitForm>;
  companyToken: string;
}

const DescriptionForm: FC<DescriptionFormProps> = ({ control, companyToken }) => {
  return (
    <>
      <Controller
        control={control}
        name="companyBankAccountId"
        render={({ fieldState, field }) => (
          <BankAccountSelect
            label="Conta"
            companyToken={companyToken}
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <ControllerInput control={control} name="description" inputProps={{ label: "Descrição" }} />

      <ControllerInput
        control={control}
        name="docNumber"
        inputProps={{ label: "Número do Documento" }}
      />

      <ControllerInput
        control={control}
        name="descDocument"
        inputProps={{ label: "Descrição do Documento" }}
      />

      <Controller
        control={control}
        name="value"
        render={({ field, fieldState }) => (
          <CurrencyInput
            {...field}
            inputProps={{
              label: "Valor",
              error: !!fieldState.error?.message,
              helperText: fieldState.error?.message,
            }}
          />
        )}
      />
    </>
  );
};

export default DescriptionForm;
