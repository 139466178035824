import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCategoriesWithReleases } from "services/Requests/companies";

const useQueryCategoriesWithReleases = (companyToken: string) => {
  const query = useQuery([queryKeys.categoriesWithReleases, companyToken], () =>
    getCategoriesWithReleases({ companyToken }),
  );
  return query;
};

export default useQueryCategoriesWithReleases;
