import { FC } from "react";
import useQueryClientsConfigLeads from "../hooks/useQueryClientsConfigLeads";

import { FixedSizeList as List } from "react-window";
import { CircularProgress, Collapse, LinearProgress, Stack, Typography } from "@mui/material";
import ConfigLead from "./ConfigLead";

interface ConfigLeadsListProps {
  form: number;
  name: string;
}

const ConfigLeadsList: FC<ConfigLeadsListProps> = ({ form, name }) => {
  const { isLoading, isFetching, data: configLeads } = useQueryClientsConfigLeads({ form, name });

  return (
    <>
      <Collapse in={!isLoading && isFetching}>
        <LinearProgress />
      </Collapse>

      <Stack gap={1} py={2} px={3}>
        {isLoading && (
          <Stack height={450} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {!isLoading && configLeads && configLeads.length !== 0 && (
          <List
            itemSize={60}
            width="100%"
            itemData={configLeads}
            itemCount={configLeads.length}
            height={450}
          >
            {({ data, index, style }) => (
              <ConfigLead formName={form} configLead={data[index]} style={style} />
            )}
          </List>
        )}

        {!isLoading && configLeads && configLeads.length === 0 && (
          <Typography>Nenhum resultado</Typography>
        )}
      </Stack>
    </>
  );
};

export default ConfigLeadsList;
