import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCompanyPartnerDetail } from "services/Requests/companies";
import { CompanyPartner } from "types/Company";

const useQueryGetCompanyPartnerDetail = (partner: CompanyPartner) => {
  const query = useQuery([queryKeys.companyPartnerDetail, partner.id], () =>
    getCompanyPartnerDetail({
      partner,
    }),
  );
  return query;
};

export default useQueryGetCompanyPartnerDetail;
