import { FC, Fragment, useState } from "react";

import { Box, Stack, Typography } from "@mui/material";
import Head from "components/Head";
import TabNavigation, { TabNavigationProps } from "components/TabNavigation";

import Panel from "./Panel";
import FinancialStatement from "./FinancialStatement";
import Signatures from "./Signatures";
import UsersBills from "./UsersBills";
import Fees from "./Fees";
import Settings from "./Settings";
import PenaltiesAndDiscounts from "./PenaltiesAndDiscounts";

import getSubdomain from "utils/getSubdomain";
import Anticipations from "./Anticipations";

const tabs: TabNavigationProps["tabs"] = [
  {
    Component: Panel,
    name: "Painel",
    slug: "painel",
    perm: 13,
  },
  {
    Component: FinancialStatement,
    name: "Extrato financeiro",
    slug: "extrato-financeiro",
    perm: 14,
  },
  {
    Component: Signatures,
    name: "Assinaturas",
    slug: "assinaturas",
    perm: 15,
  },
  {
    Component: UsersBills,
    name: "Faturas",
    slug: "faturas",
    perm: 16,
  },
  {
    Component: Anticipations,
    name: "Antecipações",
    slug: "antecipacoes",
    perm: 17,
  },
  {
    Component: Fees,
    name: "Taxas",
    slug: "taxas",
    perm: 18,
  },
  {
    Component: Settings,
    name: "Configurações",
    slug: "configuracoes",
    perm: 19,
  },
  {
    Component: PenaltiesAndDiscounts,
    name: "Multas e descontos",
    slug: "multas-e-descontos",
    perm: 20,
  },
];

const CollectionManagement: FC = () => {
  const [tabActive, setTabActive] = useState("1");

  const handleChangeTab = (newValue: string) => {
    setTabActive(newValue);
  };

  return (
    <>
      <Head title={`Gestão de Cobrança | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Gestão de Cobrança
        </Typography>
      </Stack>

      <Box mt={2} boxShadow={2} bgcolor="#fff">
        <TabNavigation tabActive={tabActive} tabs={tabs} handleChangeTab={handleChangeTab} />
      </Box>
    </>
  );
};

export default CollectionManagement;
