import { FC, useState } from "react";
import useQueryServicesAndPlansClient from "../../hooks/useQueryServicesAndPlansClient";

import {
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import TabPanel, { TabPanelProps } from "components/TabPanel";
import PartnerTab from "../../components/PartnerTab";
import Introduction from "./Tabs/Introduction";
import CompanyTab from "../../components/CompanyTab";

import { Service } from "types/ServiceAndPlan";
import Contract from "./Tabs/Contract";
import PaymentMethod from "./Tabs/PaymentMethod";

interface TabsControllerProps {
  companyToken: string;
  service: Service;
}

const TabsController: FC<TabsControllerProps> = ({ companyToken, service }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { data, isLoading, isError } = useQueryServicesAndPlansClient(companyToken);
  const [activeTab, setActiveTab] = useState(0);

  const handleNext = () => setActiveTab(activeTab + 1);
  const handleBack = () => setActiveTab(activeTab - 1);

  const tabPanelProps: TabPanelProps = {
    keepMounted: true,
    value: activeTab,
    sx: {
      p: isMobile ? 2 : 3,
    },
    index: 0,
  };

  return (
    <Box>
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{
          borderBottom: 1,
          borderBottomColor: "divider",
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Introdução" />
        <Tab label="Sua empresa" disabled={activeTab < 1} />
        <Tab label="Sobre os sócios" disabled={activeTab < 2} />
        <Tab label="Contrato" disabled={activeTab < 3} />
        <Tab label="Método de Pagamento" disabled={activeTab < 4} />
      </Tabs>

      <TabPanel {...tabPanelProps} index={0}>
        <Introduction onNext={handleNext} />
      </TabPanel>
      <TabPanel {...tabPanelProps} index={1}>
        <CompanyTab companyToken={companyToken} onBack={handleBack} onNext={handleNext} />
      </TabPanel>
      <TabPanel {...tabPanelProps} index={2}>
        <PartnerTab companyToken={companyToken} onBack={handleBack} onNext={handleNext} />
      </TabPanel>
      <TabPanel {...tabPanelProps} index={3}>
        {isLoading && (
          <Stack height={400} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}
        {isError && (
          <Typography>Não foi possível carregar os dados. Tente novamente mais tarde.</Typography>
        )}
        {data && !isError && !isLoading && (
          <Contract
            onBack={handleBack}
            onNext={handleNext}
            plans={data.plans}
            downloadToken={service.downloadToken}
          />
        )}
      </TabPanel>
      <TabPanel {...tabPanelProps} index={4}>
        <PaymentMethod onBack={handleBack} />
      </TabPanel>
    </Box>
  );
};

export default TabsController;
