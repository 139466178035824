import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { uploadLogo } from "services/Requests/invoice";

const useMutationUploadLogo = () => {
  const mutation = useMutation(uploadLogo, {
    onSuccess: () => {
      toast.success("Logo cadastrada com sucesso!");
    },
  });
  return mutation;
};

export default useMutationUploadLogo;
