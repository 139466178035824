import { useMutation, useQueryClient } from "react-query";
import useInvalidateDashboardQueries from "../pages/Dashboard/hooks/useInvalidateDashboardQueries";
import toast from "react-hot-toast";

import queryKeys from "react-query/constants";
import { updateTransaction, UpdateTransactionPayload } from "services/Requests/companies";
import { Transaction } from "types/Company";
import { AxiosError } from "axios";

interface IError {
  message?: string;
}

const useMutationUpdateTransaction = () => {
  const queryClient = useQueryClient();
  const invalidate = useInvalidateDashboardQueries();

  const mutation = useMutation<Transaction, AxiosError<IError>, UpdateTransactionPayload>(
    updateTransaction,
    {
      onSuccess({ id }) {
        toast.success("Lançamento editado com sucesso!");
        queryClient.invalidateQueries(queryKeys.transactions, { exact: false });
        queryClient.invalidateQueries([queryKeys.transaction, id], { exact: false });
        invalidate();
      },
      onError() {
        return;
      },
    },
  );
  return mutation;
};

export default useMutationUpdateTransaction;
