import { FC, FormEvent, useState } from "react";
import { useAuth } from "context/Auth";
import useMutationDuplicateBill from "pages/Home/hooks/useMutationDuplicateBill";

import {
  TableRow,
  TableCell,
  IconButton,
  Chip,
  ChipProps,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import Search from "@mui/icons-material/Search";

import { Bill } from "types/Billing";
import { DateTime } from "luxon";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import getSubdomain from "utils/getSubdomain";

const colorChip: { [x in Bill["status"]]: ChipProps["color"] } = {
  "Em aberto": "info",
  Cancelada: "default",
  Paga: "success",
  draft: "warning",
};

interface InvoiceTableItemProps {
  bill: Bill;
}

const InvoiceTableItem: FC<InvoiceTableItemProps> = ({ bill }) => {
  const {
    user: { company },
  } = useAuth();

  const dueDate = DateTime.fromISO(bill.dueDate);

  const minDate =
    dueDate > DateTime.now().plus({ days: 1 })
      ? dueDate.plus({ days: 1 })
      : DateTime.now().plus({ days: 1 });
  const maxDate = minDate.plus({ days: 4 });

  const [dialogDuplicateInvoice, setDialogDuplicateInvoice] = useState(false);
  const [newDueDate, setNewDueDate] = useState<undefined | DateTime>(minDate);

  const subdomain = getSubdomain();
  const { isLoading: duplicateIsLoading, mutateAsync } = useMutationDuplicateBill();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!newDueDate) return;

    try {
      await mutateAsync({
        companyToken: company!.token,
        dueDate: newDueDate.toISO(),
        id: bill.id,
        item: {
          value: bill.value,
          description: bill.description,
          quantity: 1,
        },
        payerCompanyId: company!.id,
        subdomain,
      });
      setDialogDuplicateInvoice(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <a href={bill.detailsUrl} target="_blank" rel="noreferrer">
            <IconButton size="small">
              <Search fontSize="small" />
            </IconButton>
          </a>
        </TableCell>

        <TableCell>{DateTime.fromISO(bill.dueDate).toFormat("dd/MM/yyyy")}</TableCell>
        <TableCell>
          {bill.payDate ? DateTime.fromISO(bill.payDate).toFormat("dd/MM/yyyy") : "-"}
        </TableCell>
        <TableCell>{formatCurrencyBRL(bill.value)}</TableCell>
        <TableCell>{bill.description}</TableCell>
        <TableCell>
          <Stack direction="row" flexWrap="wrap" gap={0.5}>
            <Chip
              label={bill.status}
              size="small"
              color={
                bill.status === "Em aberto" && DateTime.now() > DateTime.fromISO(bill.dueDate)
                  ? "error"
                  : colorChip[bill.status]
              }
            />
            {bill.status === "Em aberto" && (
              <Chip
                onClick={() => window.open(bill.detailsUrl, "_blank")}
                label="Pagar"
                color="warning"
                size="small"
                clickable
              />
            )}
            {bill.status === "Em aberto" && bill.value >= 1 && (
              <Chip
                onClick={() => setDialogDuplicateInvoice(true)}
                label="2° via"
                color="warning"
                size="small"
                clickable
              />
            )}
          </Stack>
        </TableCell>
      </TableRow>

      <Dialog
        maxWidth="md"
        fullWidth
        open={dialogDuplicateInvoice}
        onClose={() => {
          if (!duplicateIsLoading) {
            setDialogDuplicateInvoice(false);
          }
        }}
      >
        <DialogTitle>Emissão de segunda via de fatura</DialogTitle>

        <Box component="form" onSubmit={handleSubmit}>
          <DialogContent dividers sx={{ p: 4 }}>
            <Stack direction="row" gap={2} flexWrap="wrap">
              <DesktopDatePicker
                disablePast
                minDate={minDate}
                maxDate={maxDate}
                value={newDueDate}
                label="Vencimento"
                renderInput={(props) => <TextField sx={{ flex: 1 }} {...props} />}
                onChange={(value) => setNewDueDate(!value ? undefined : value)}
              />
              <TextField
                sx={{ flex: 1 }}
                value={formatCurrencyBRL(bill.value)}
                disabled
                label="Valor"
              />
              <TextField sx={{ flex: 1 }} value={bill.description} disabled label="Descrição" />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              disabled={duplicateIsLoading}
              onClick={() => setDialogDuplicateInvoice(false)}
            >
              Cancelar
            </Button>
            <LoadingButton loading={duplicateIsLoading} variant="contained" type="submit">
              Emitir segunda via
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default InvoiceTableItem;
