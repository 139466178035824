import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { deleteMovement } from "services/Requests/stockControl";

const useMutationDeleteMovement = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteMovement, {
    onSuccess() {
      toast.success("Movimentação deletada com sucesso");
      queryClient.invalidateQueries(queryKeys.stockControlMovements, { exact: false });
    },
  });
  return mutation;
};

export default useMutationDeleteMovement;
