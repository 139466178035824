import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import {
  duplicateBillByAccountant,
  DuplicateBillByAccountantPayload,
} from "services/Requests/billing";
import getSubdomain from "utils/getSubdomain";

const useMutationDuplicateBillByAccountant = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<DuplicateBillByAccountantPayload, "subdomain">) =>
      duplicateBillByAccountant({ subdomain, ...payload }),
    {
      onSuccess() {
        toast.success(
          "Solicitação para alteração da fatura registrada com sucesso! Por favor aguarde um pouco para a solicitação ser processada.",
        );
        queryClient.invalidateQueries(queryKeys.usersBills, {
          exact: false,
        });
      },
    },
  );
  return mutation;
};

export default useMutationDuplicateBillByAccountant;
