import { FC, useState } from "react";

import { Box, Tab, Tabs } from "@mui/material";

import PartnerTab from "../../components/PartnerTab";
import Introduction from "./Tab/Introduction";
import TabPanel from "components/TabPanel";
import ContractTerms from "../../components/ContractTerms";

import { Service } from "types/ServiceAndPlan";
import CompanyTab from "../../components/CompanyTab";

interface TabsControllerProps {
  companyToken: string;
  service: Service;
}

const TabsController: FC<TabsControllerProps> = ({ companyToken, service }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleNext = () => setActiveTab(activeTab + 1);
  const handleBack = () => setActiveTab(activeTab - 1);

  return (
    <Box>
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{
          borderBottom: 1,
          borderBottomColor: "divider",
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Introdução" />
        <Tab label="Sua empresa" disabled={activeTab < 1} />
        <Tab label="Sobre os sócios" disabled={activeTab < 2} />
        <Tab label="Contrato" disabled={activeTab < 3} />
      </Tabs>

      <TabPanel value={activeTab} index={0} keepMounted>
        <Introduction service={service} onNext={handleNext} />
      </TabPanel>
      <TabPanel value={activeTab} index={1} keepMounted>
        <CompanyTab companyToken={companyToken} onBack={handleBack} onNext={handleNext} />
      </TabPanel>
      <TabPanel value={activeTab} index={2} keepMounted>
        <PartnerTab companyToken={companyToken} onBack={handleBack} onNext={handleNext} />
      </TabPanel>
      <TabPanel value={activeTab} index={3} keepMounted>
        <ContractTerms downloadToken={service.downloadToken} onBack={handleBack} />
      </TabPanel>
    </Box>
  );
};

export default TabsController;
