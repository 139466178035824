import validateCNPJ from "utils/validateCNPJ";
import validateCPF from "utils/validateCPF";
import * as yup from "yup";

const emptyValidation = yup
  .string()
  .nullable()
  .transform((value) => {
    if (value === undefined || value === null || value === "") return null;
    return value;
  })
  .test("onlyNumber", "Digite apenas números", (value) => {
    if (value === undefined || value === null || value === "") return true;
    return !isNaN(Number(value));
  })
  .test("isPositive", "Digite apenas números positivos", (value) => {
    if (value === undefined || value === null || value === "" || isNaN(Number(value))) return true;

    return Number(value) > 0;
  });

const validationSchema = yup.object().shape({
  cpfcnpj: yup
    .string()
    .nullable()
    .test("cpfOrCnpjIsValid", "Digite um cpf ou cnpj válido", (value) => {
      if (value === undefined || value === null || value === "") return true;

      if (value.length <= 14) {
        const cpfIsValid = validateCPF(value);
        return cpfIsValid;
      }

      const cnpjIsValid = validateCNPJ(value);
      return cnpjIsValid;
    }),

  stateRegistration: emptyValidation,

  telNumber: yup
    .string()
    .nullable()
    .test("telNumberIsValid", "Digite um telefone válido", (value) => {
      if (value === undefined || value === null || value === "") return true;

      const telNumberClean = value.replace(/\D/g, "");
      return telNumberClean.length === 10 || telNumberClean.length === 11;
    }),

  email: yup.string().email("Digite um email válido").nullable(),

  agency: emptyValidation,
  account: emptyValidation,
});

export default validationSchema;
