import { useState, useEffect, Dispatch, SetStateAction } from "react";

function getStorageValue<T = unknown>(key: string, defaultValue: T): T {
  const saved = localStorage.getItem(key);
  if (!saved) return defaultValue;

  const storage = JSON.parse(saved) as T;
  return storage;
}

function useLocalStorage<T = unknown>(
  key: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export default useLocalStorage;
