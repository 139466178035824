import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Chip,
  Stack,
  IconButton,
  Tooltip,
  ChipProps,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { BillingInfo, StatusDoc } from "types/Billing";
import { Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import useMutationUploadDocumentsApproveDrive from "../../hooks/useMutationUploadDocumentsApproveDrive";

const getColorChip: { [key in StatusDoc]: ChipProps["color"] } = {
  "Em aprovação": "warning",
  "Não anexado": "info",
  Aprovado: "success",
  Reenviar: "error",
};

interface DocumentCopyProps {
  billingInfo: BillingInfo;
  companyToken: string;
}

const DocumentCopy: FC<DocumentCopyProps> = ({ billingInfo, companyToken }) => {
  const [csDoc, setCsDoc] = useState<File | null>(null);
  const [isDoc, setIsDoc] = useState<File | null>(null);
  const [efDoc, setEfDoc] = useState<File | null>(null);

  const { mutateAsync, isLoading } = useMutationUploadDocumentsApproveDrive();

  const onChangeDoc = (
    e: ChangeEvent<HTMLInputElement>,
    setState: Dispatch<SetStateAction<File | null>>,
  ) => {
    if (!e.target.files) return;

    const file = e.target?.files[0];

    if (!file) setState(null);
    setState(file);
  };

  const onSubmitDocument = () => {
    if (!csDoc && !isDoc && !efDoc) {
      toast.error("Faça upload de algum documento");
      return;
    }

    const promises = [];

    if (csDoc) {
      promises.push(
        mutateAsync({
          companyToken,
          file: csDoc,
          fullPath: "EMPRESA/CONTRATO SOCIAL",
        }),
      );
    }

    if (isDoc) {
      promises.push(
        mutateAsync({
          companyToken,
          file: isDoc,
          fullPath: "SÓCIOS",
        }),
      );
    }

    if (efDoc) {
      promises.push(
        mutateAsync({
          companyToken,
          file: efDoc,
          fullPath: "EMPRESA/OUTROS DOCUMENTOS",
        }),
      );
    }

    Promise.all(promises).then(() => {
      toast.success("Arquivos enviados com sucesso!");
      setCsDoc(null);
      setIsDoc(null);
      setEfDoc(null);
    });
  };

  return (
    <Box flex={1} flexBasis="320px">
      <Box p={0.5} bgcolor={grey[200]}>
        <Typography variant="body1" align="center" fontWeight={600}>
          Cópia de documento
        </Typography>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        mt={1}
        position="relative"
      >
        <Typography variant="body1" fontWeight={600}>
          Contrato Social
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="end" gap={1}>
          {csDoc && (
            <Typography variant="body1">
              {csDoc.name.length > 10 ? `${csDoc.name.substring(0, 15)}...` : csDoc.name}
            </Typography>
          )}
          <Tooltip title="Anexar">
            <IconButton
              color="default"
              component="label"
              size="small"
              disabled={
                billingInfo.doc_status_ef === "Aprovado" ||
                billingInfo.doc_status_ef === "Em aprovação"
              }
            >
              <input hidden type="file" onChange={(e) => onChangeDoc(e, setCsDoc)} />
              <Upload fontSize="small" />
            </IconButton>
          </Tooltip>

          <Chip
            label={billingInfo.doc_status_cs || "Não anexado"}
            color={getColorChip[billingInfo.doc_status_cs || "Não anexado"]}
          />
        </Stack>

        <Divider sx={{ my: 1, gridColumn: "span 2" }} />

        <Typography variant="body1" fontWeight={600}>
          Identidade dos Sócios
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="end" gap={1}>
          {isDoc && (
            <Typography variant="body1">
              {isDoc.name.length > 10 ? `${isDoc.name.substring(0, 15)}...` : isDoc.name}
            </Typography>
          )}
          <Tooltip title="Anexar">
            <IconButton
              color="default"
              component="label"
              size="small"
              disabled={
                billingInfo.doc_status_ef === "Aprovado" ||
                billingInfo.doc_status_ef === "Em aprovação"
              }
            >
              <input hidden type="file" onChange={(e) => onChangeDoc(e, setIsDoc)} />
              <Upload fontSize="small" />
            </IconButton>
          </Tooltip>

          <Chip
            label={billingInfo.doc_status_is || "Não anexado"}
            color={getColorChip[billingInfo.doc_status_is || "Não anexado"]}
          />
        </Stack>

        <Divider sx={{ my: 1, gridColumn: "span 2" }} />

        <Typography variant="body1" fontWeight={600}>
          Comprovante Domic. Bancário
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="end" gap={1}>
          {efDoc && (
            <Typography variant="body1">
              {efDoc.name.length > 10 ? `${efDoc.name.substring(0, 15)}...` : efDoc.name}
            </Typography>
          )}
          <Tooltip title="Anexar">
            <IconButton
              color="default"
              component="label"
              size="small"
              disabled={
                billingInfo.doc_status_ef === "Aprovado" ||
                billingInfo.doc_status_ef === "Em aprovação"
              }
            >
              <input hidden type="file" onChange={(e) => onChangeDoc(e, setEfDoc)} />
              <Upload fontSize="small" />
            </IconButton>
          </Tooltip>

          <Chip
            label={billingInfo.doc_status_ef || "Não anexado"}
            color={getColorChip[billingInfo.doc_status_ef || "Não anexado"]}
          />
        </Stack>

        <Divider sx={{ my: 1, gridColumn: "span 2" }} />

        <LoadingButton
          loading={isLoading}
          onClick={onSubmitDocument}
          variant="contained"
          sx={{ mt: 1 }}
        >
          Enviar para verificação
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default DocumentCopy;
