import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/Auth";
import toast from "react-hot-toast";
import { checkAvailabilityEmail, signUpUser } from "../../../../services/Requests/registration";
import messageError from "../../../../utils/messageError";
import { getCompanyLastAccess } from "../../../../services/Requests/companies";
import normalizeUser from "utils/normalizeUser";

interface CreateUserArgs {
  email: string;
  password: string;
  fullName: string;
  cellPhone: string;
  telephone?: string;
  type: "accountant" | "user";
}

const createUser = async (user: CreateUserArgs) => {
  const data = await checkAvailabilityEmail(user.email);

  if (data?.result === "NotAvailable") {
    return data;
  }

  const userData = await signUpUser(user);
  const company = await getCompanyLastAccess({ token: userData.token });

  return {
    ...userData,
    company,
  };
};

const useSignUp = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const mutation = useMutation(createUser, {
    async onSuccess(data) {
      if (data?.result === "Success") {
        login(normalizeUser(data));
        navigate("/");
        return;
      }

      messageError("NotAvailable", "Email já está cadastrado!", data.result);
    },

    onError(error: any) {
      let message = "Ocorreu um erro ao tentar se registrar";
      if (error?.response?.data && error?.response?.data?.exceptionMessage) {
        message += ":\n" + error?.response?.data.exceptionMessage;
      }

      toast.error(message);
    },
  });

  return mutation;
};

export default useSignUp;
