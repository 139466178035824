import axios from "axios";
import { BASE_URL_API } from "../utils/constants";

const publicApi = axios.create({
  baseURL: BASE_URL_API,
  headers: {
    "content-type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Headers": "*",
  },
});

export default publicApi;
