import { CSSProperties, FC, useRef } from "react";

import Toolbar, { ToolbarProps } from "./interface/Toolbar";

import { createEditor, Descendant } from "slate";
import { withHistory } from "slate-history";
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from "slate-react";
import withLinks from "./plugins/withLinks";

import { CustomEditor } from "./slate";
import Link from "./interface/Link";

import pipe from "utils/pipe";

import isHotkey from "is-hotkey";
import { HOTKEYS } from "./utils/constants";
import { toggleMark } from "./utils/functions";
import { EditorWrapper } from "./styled";
import { EditableProps } from "slate-react/dist/components/editable";

const renderElement = ({ attributes, children, element }: RenderElementProps) => {
  const style: CSSProperties | undefined = element.align ? { textAlign: element.align } : undefined;

  switch (element.type) {
    case "link":
      return (
        <Link attributes={attributes} element={element}>
          {children}
        </Link>
      );

    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );

    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );

    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );

    default:
      return (
        <p {...attributes} style={style}>
          {children}
        </p>
      );
  }
};

const renderLeaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  return <span {...attributes}>{children}</span>;
};

const createEditorWithPlugins = pipe(withReact, withHistory, withLinks);

interface TextEditorProps {
  value: Descendant[];
  onChange: (value: Descendant[]) => void;
  editableProps?: Partial<EditableProps>;
  preset?: ToolbarProps["preset"];
}

const TextEditor: FC<TextEditorProps> = ({ value, onChange, editableProps, preset = 1 }) => {
  const editorRef = useRef<CustomEditor>();
  if (!editorRef.current) editorRef.current = createEditorWithPlugins(createEditor());
  const editor = editorRef.current;

  editor.children = value;

  return (
    <EditorWrapper>
      <Slate editor={editor} value={value} onChange={onChange}>
        <Toolbar preset={preset} />

        <Editable
          {...editableProps}
          id="text-editor"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          onKeyDown={(event) => {
            Object.entries(HOTKEYS).forEach((hotkey) => {
              if (isHotkey(hotkey[0], event)) {
                event.preventDefault();
                const mark = hotkey[1];
                toggleMark(editor, mark);
              }
            });
          }}
        />
      </Slate>
    </EditorWrapper>
  );
};

export default TextEditor;
