import { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { NormalizedClient } from "types/Client";
import { LoadingButton } from "@mui/lab";
import useMutationUpdateCompanyRegistrationStatus from "../../../hooks/useMutationUpdateCompanyRegistrationStatus";

interface DialogChangeStatus extends DialogProps {
  clientInformation: NormalizedClient;
}

const DialogChangeStatus: FC<DialogChangeStatus> = ({ clientInformation, ...props }) => {
  const { mutateAsync, isLoading } = useMutationUpdateCompanyRegistrationStatus();

  const onChangeStatus = async (e: any) => {
    try {
      await mutateAsync({
        companyId: clientInformation.payerCompanyId,
        status: clientInformation.status,
      });
      props.onClose?.(e, "backdropClick");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog
      {...props}
      onClose={(e) => {
        if (isLoading || !props.onClose) return;
        props.onClose(e, "backdropClick");
      }}
    >
      <DialogTitle>
        {clientInformation.status === "Suspended" ? "Reativar" : "Suspender"} conta{" "}
        {clientInformation.companyName}
      </DialogTitle>

      <DialogContent dividers>
        Confirma a {clientInformation.status === "Suspended" ? "reativação" : "suspensão"} dessa
        conta?
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={(e) => props.onClose?.(e, "backdropClick")}
        >
          Não
        </Button>
        <LoadingButton loading={isLoading} variant="contained" onClick={onChangeStatus}>
          Sim
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogChangeStatus;
