import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/Auth";

import { signInUser, SignInPayload } from "../../../../services/Requests/registration";
import { getCompanyLastAccess } from "../../../../services/Requests/companies";
import normalizeUser from "utils/normalizeUser";
import { getPrimaryPath } from "hooks/useNavigateHomePage";
import { UserWithCompany } from "types/User";
import getSideMenuItems from "utils/getSideMenuItems";

const signIn = async (user: SignInPayload) => {
  const userData = await signInUser(user);

  try {
    const company = await getCompanyLastAccess({ token: userData.token });
    return {
      result: "Success",
      ...userData,
      company,
    };
  } catch (e) {
    return {
      result: "Success",
      ...userData,
    };
  }
};

const useSignIn = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const navigateHomePage = (user: UserWithCompany) => {
    const sideMenuItems = getSideMenuItems(user, false);
    navigate(getPrimaryPath(user, sideMenuItems));
  };

  const mutation = useMutation(signIn, {
    onSuccess: (data) => {
      if (data?.result === "InvalidCredentials") {
        toast.error("Usuário ou senha inválidos");
        return;
      }

      if (data?.result === "AccountantInactive") {
        toast.error("Entre em contato com o administrador da plataforma e verifique seu acesso.");
        return;
      }

      if (data?.result === "Success") {
        const normalizedUser = normalizeUser(data);
        login(normalizedUser);
        navigateHomePage(normalizedUser);
        return;
      }

      toast.error("Ocorreu um erro ao fazer login");
    },
    onError: () => {
      toast.error("Ocorreu um erro ao fazer login");
    },
  });

  return mutation;
};

export default useSignIn;
