import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getMonthClosure } from "services/Requests/companies";

const useQueryMonthClosure = (companyToken: string, month: string) => {
  const query = useQuery([queryKeys.monthClosure, month], () =>
    getMonthClosure({ companyToken, dateReferenceClose: month }),
  );
  return query;
};

export default useQueryMonthClosure;
