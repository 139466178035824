import { FC, useState } from "react";
import useMutationUserInfo from "./hooks/useMutationUserInfo";
import useMutationUpdateLastAccess from "./hooks/useMutationUpdateLastAccess";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { UserWithCompany } from "../../types/User";

import queryKeys, { permanentQueryKeys, KeyofQueryKeys } from "react-query/constants";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";
import { AuthContext } from ".";

import normalizeUser from "utils/normalizeUser";
import { Company } from "types/Company";
import getSubdomain from "utils/getSubdomain";
import {
  createAuthInfo,
  getPreviousUser,
  getUser,
  isUserAuthenticated,
  removeAuthInfo,
} from "./utils";

interface AuthProviderProps {
  children: React.ReactNode;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutateAsync: mutateGetUserInfo, isLoading: loadingChangeUser } = useMutationUserInfo();
  const { mutateAsync: mutationUpdateLastAccess, isLoading: loadingUpdateLastAccess } =
    useMutationUpdateLastAccess();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(isUserAuthenticated);
  const [user, setUser] = useState<UserWithCompany>(getUser);
  const [previousUser, setPreviousUser] = useState<UserWithCompany | null>(getPreviousUser);

  const removeTemporaryQueries = () => {
    Object.entries(queryKeys).forEach(([key, value]) => {
      const isPermanent = permanentQueryKeys.includes(key as KeyofQueryKeys);
      if (isPermanent) {
        return;
      }

      queryClient.removeQueries(value, { exact: false });
    });
  };

  const login = (user: UserWithCompany) => {
    setIsAuthenticated(true);
    createAuthInfo(user);
    setUser(user);

    if (!(user.userInfo.userType == "User" && getSubdomain() !== "app")) {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "y3wa4ede",
        name: user.userInfo.name,
        email: user.userInfo.userName,
        created_at: Date.now(),
      });
    }
  };

  const logout = () => {
    removeTemporaryQueries();

    setIsAuthenticated(false);
    setUser({} as UserWithCompany);
    setPreviousUser(null);
    window.Intercom("shutdown");
    removeAuthInfo();
  };

  const changeToPreviousUser = () => {
    if (!previousUser) return;

    removeTemporaryQueries();
    setUser(previousUser);
    setPreviousUser(null);

    localStorage.removeItem("previousUser");
    localStorage.setItem("user", JSON.stringify(previousUser));

    navigate("/marketplace?tab=contabilidade");
  };

  const loginTheClient = async (token: string) => {
    const userResponse = await mutateGetUserInfo({ companyToken: token });
    const previousUserStorage = Object.assign({}, user);

    const newUser = normalizeUser({
      ...userResponse,
      company: {
        ...user.company,
        token: userResponse.token,
      } as Company,
    });

    removeTemporaryQueries();
    setUser(newUser);
    if (!previousUser) {
      setPreviousUser(previousUserStorage);
    }

    localStorage.setItem("previousUser", JSON.stringify(previousUserStorage));
    localStorage.setItem("user", JSON.stringify(newUser));

    navigate("/dashboard");
  };

  const changeCompany = async (company: Company) => {
    await mutationUpdateLastAccess({ companyToken: company.token, id: user.userInfo.id });
    removeTemporaryQueries();
    navigate("/dashboard");
    setUser({ ...user, company: company });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setUser,
        login,
        logout,
        loginTheClient,
        changeToPreviousUser,
        previousUser,
        changeCompany,
      }}
    >
      {children}

      {(loadingChangeUser || loadingUpdateLastAccess) && <FullscreenLoadingIndicator />}
    </AuthContext.Provider>
  );
};
