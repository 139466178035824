import { FC } from "react";
import useMutationChangeStateOrderOpenCompany from "pages/Home/pages/Marketplace/hooks/useMutationChangeStateOrderOpenCompany";

import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { Order } from "types/OpenCompany";

interface DialogChangeStateOrderProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  order: Order;
}

const DialogChangeStateOrder: FC<DialogChangeStateOrderProps> = ({ onClose, order, ...props }) => {
  const { mutateAsync, isLoading } = useMutationChangeStateOrderOpenCompany();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleChangeStateOrder = async () => {
    await mutateAsync({ ...order, orderState: 11 });
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Migração da empresa &quot;{order.companyName}&quot;
      </DialogTitleWithCloseButton>
      <DialogContent>
        {order.cnpj > 1
          ? "Confirma a migração para a contabilidade?"
          : "Você ainda não cadastrou o CNPJ. Mesmo assim, deseja continuar a migração para a contabilidade?"}
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleChangeStateOrder}>
          Sim
        </LoadingButton>
        <Button disabled={isLoading} variant="outlined" onClick={handleClose}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogChangeStateOrder;
