import React, { useState } from "react";
import osaykLogo from "../../assets/osayk-logo.png";

type RenderLogo = React.FC<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
>;

const RenderLogo: RenderLogo = (props) => {
  const [isError, setIsError] = useState(false);

  return (
    <>
      <img
        {...props}
        src={!props.src || isError ? osaykLogo : props.src}
        onError={() => {
          setIsError(true);
        }}
      />
    </>
  );
};

export default RenderLogo;
