import { useContext } from "react";
import { ClientInformationContext } from ".";

export const useClientInformation = () => {
  const ctx = useContext(ClientInformationContext);

  if (!ctx) {
    throw new Error("useClientInformation must be used within a ClientInformationProvider");
  }

  return ctx;
};
