import { FC, useState } from "react";
import { useCompanyInfo } from "context/CompanyInfo";
import useMutationUploadImage from "../hooks/useMutationUploadImage";

import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { LoadingButton } from "@mui/lab";

import UploadImage from "../components/UploadImage";
import toast from "react-hot-toast";

interface TopImageProps {
  companyToken: string;
}

const TopImage: FC<TopImageProps> = ({ companyToken }) => {
  const { mutate, isLoading } = useMutationUploadImage();

  const [file, setFile] = useState<File | null>(null);
  const { imageTopDashboard } = useCompanyInfo();

  const handleUploadImage = () => {
    if (!file) {
      toast.error("Selecione alguma imagem");
      return;
    }

    mutate({
      file,
      companyToken,
    });
  };

  return (
    <Stack gap={3} flex={1} flexBasis={350}>
      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ borderBottom: 1, borderBottomColor: "divider" }}
      >
        Imagem do Topo
      </Typography>

      <UploadImage
        file={file}
        onChange={(file) => setFile(file)}
        label="Upload de imagem do Topo"
        initialImage={imageTopDashboard}
      />

      <Stack alignItems="end" justifyItems="end" bgcolor={grey[200]} p={2}>
        <LoadingButton
          disableElevation={false}
          variant="contained"
          loading={isLoading}
          onClick={handleUploadImage}
        >
          Salvar imagem
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default TopImage;
