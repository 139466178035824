import { FC, useMemo } from "react";

import { Box, Stack, Typography } from "@mui/material";

import { AxisOptions, Chart } from "react-charts";
import { RevenuesVsExpenses } from "types/Dashboard";
import { grey } from "@mui/material/colors";
import useChartData from "./useChartData";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface IncomeAndExpensesChartProps {
  revenuesVsExpenses: RevenuesVsExpenses;
}

const IncomeAndExpensesChart: FC<IncomeAndExpensesChartProps> = ({ revenuesVsExpenses }) => {
  const { colors, data, filterChart, handleToggleFilterSerie } = useChartData(revenuesVsExpenses);

  const primaryAxis = useMemo<AxisOptions<typeof data[number]["data"][number]>>(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    [],
  );

  const secondaryAxes = useMemo<AxisOptions<typeof data[number]["data"][number]>[]>(
    () => [
      {
        getValue: (datum) => datum.secondary,
        elementType: "bar",
        formatters: {
          tooltip: (value: number) => value && <>{formatCurrencyBRL(value)}</>,
          scale: (value: number) => value && <>{value.toLocaleString("pt-BR")}</>,
        } as object,
      },
    ],
    [],
  );

  return (
    <Box width={"100%"}>
      <Box height={200} position="relative">
        <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes,
            tooltip: false,
            defaultColors: colors,
          }}
          style={{ fontFamily: "Roboto, sans-serif", position: "relative" }}
        />
      </Box>

      <Stack direction="row" alignItems="center" justifyContent="center" mt={1} gap={6}>
        {revenuesVsExpenses.series.map(
          (serie, index) =>
            index + 1 < revenuesVsExpenses.series.length && (
              <Stack
                direction="row"
                key={serie.color}
                alignItems="center"
                gap={1}
                p={1}
                onClick={() => handleToggleFilterSerie(serie.name)}
                sx={{
                  boxShadow: filterChart.includes(serie.name) ? 0 : 2,
                  border: "1px solid",
                  borderColor: "divider",
                  cursor:
                    filterChart.length === 2 && !filterChart.includes(serie.name)
                      ? "default"
                      : "pointer",
                  bgcolor: grey[50],
                  transition: "all 300ms ease",
                  opacity: filterChart.includes(serie.name) ? 0.3 : 1,
                  "&:hover": {
                    bgcolor:
                      filterChart.length === 2 && !filterChart.includes(serie.name)
                        ? grey[50]
                        : grey[200],
                  },
                  "&:active": {
                    backgroundColor:
                      filterChart.length < 2 && !filterChart.includes(serie.name)
                        ? grey[500]
                        : "transparent",
                    backgroundSize: "100%",
                    transition: " background 0s",
                  },
                }}
              >
                <Box width={15} height={15} bgcolor={serie.color} />{" "}
                <Typography>{serie.name}</Typography>
              </Stack>
            ),
        )}
      </Stack>
    </Box>
  );
};

export default IncomeAndExpensesChart;
