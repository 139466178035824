import * as yup from "yup";

export const emptyNumberValidation = yup
  .number()
  .nullable()
  .transform((value: number | string) => {
    if (value === undefined || value === null || value === "") return null;
    return Number(value.toString().replace(/,/g, "."));
  })
  .test("onlyNumber", "Digite apenas números", (value) => {
    if (value === undefined || value === null) return true;
    return !isNaN(Number(value));
  })
  .test("isPositive", "Digite apenas números positivos", (value) => {
    if (value === undefined || value === null || isNaN(Number(value))) return true;
    return Number(value) >= 0;
  })
  .test("match", "Até 3 casas decimais", (value) => {
    if (value === undefined || value === null) return true;
    return /^[0-9]+(\.[0-9]{1,3})?$/.test(value.toString());
  })
  .typeError("Digite apenas números");
