import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import toast from "react-hot-toast";
import { createTemplateFinancialCategory } from "services/Requests/companies";

const useMutationCreateTemplateFinancial = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createTemplateFinancialCategory, {
    onSuccess(_, { id }) {
      toast.success(`Template ${id ? "editado" : "criado"} com sucesso`);
      queryClient.invalidateQueries(queryKeys.templateFinancial);
    },
  });
  return mutation;
};

export default useMutationCreateTemplateFinancial;
