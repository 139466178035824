import { FC } from "react";
import { useForm } from "react-hook-form";
import useMutationUpdatePartners from "../../Profile/components/CompanyData/hooks/useMutationUpdatePartners";

import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  useMediaQuery,
} from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import AddressFields from "components/AddressFields";
import BasicData from "./BasicData";
import BankInformation from "./BankInformation";

import { Bank } from "types/CommonData";
import { CompanyPartner } from "types/Company";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

export interface PartnerForm {
  street: string;
  neighborhood: string;
  zipCode: string;
  city: string;
  complement: string;
  state: string;
  streetNumber: number;
  cpfcnpj: string;
  name: string;
  share: number;
  email: string;
  telNumber: string;
  bank: Bank | null | number;
  agency: number;
  account: number;
}

interface DialogCreateUpdatePartnerProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  onClose: () => void;
  partner?: CompanyPartner;
}

const DialogCreateUpdatePartner: FC<DialogCreateUpdatePartnerProps> = ({
  companyToken,
  onClose,
  partner,
  ...props
}) => {
  const isTablet = useMediaQuery("(max-width:728px)");
  const { mutateAsync } = useMutationUpdatePartners();

  const inititalValues: Partial<PartnerForm> = {
    state: partner?.address.state || "",
    neighborhood: partner?.address.neighborhood || "",
    zipCode: partner?.address.zipCode || "",
    city: partner?.address.countyId || "",
    complement: partner?.address.complement || "",
    street: partner?.address.street || "",
    streetNumber: partner?.address.streetNumber ? Number(partner?.address.streetNumber) : undefined,
    cpfcnpj: partner?.cpfcnpj || "",
    name: partner?.name || "",
    share: partner?.share || undefined,
    email: partner?.email || "",
    telNumber: partner?.phoneAsString || "",
    bank: partner?.bankAccount.bankNumber,
    account: partner?.bankAccount.account ? Number(partner?.bankAccount.account) : undefined,
    agency: partner?.bankAccount.agency ? Number(partner?.bankAccount.agency) : undefined,
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm<PartnerForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: inititalValues,
  });

  const handleClose = () => {
    if (isSubmitting) return;
    reset(inititalValues);
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      companyToken,
      partner: {
        ...partner,
        address: {
          complement: data.complement,
          countyId: data.city,
          neighborhood: data.neighborhood,
          state: data.state,
          street: data.street,
          streetNumber: data.streetNumber?.toString(),
          zipCode: data.zipCode.replace(/\D/g, ""),
        },
        bankAccount: {
          account: data.account ? data.account.toString() : null,
          agency: data.agency ? data.agency.toString() : null,
          bankNumber: data.bank
            ? typeof data.bank === "number"
              ? data.bank
              : data.bank.id
            : undefined,
        },
        cpfcnpj: data.cpfcnpj.replace(/\D/g, ""),
        email: data.email,
        name: data.name,
        phone: data.telNumber.replace(/\D/g, ""),
        share: data.share,
        updateAction: partner ? "Update" : "Insert",
      },
    });
    handleClose();
  });

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="md" fullWidth fullScreen={isTablet}>
      <DialogTitleWithCloseButton onClose={handleClose}>Novo Sócio</DialogTitleWithCloseButton>

      <form onSubmit={onSubmit}>
        <DialogContent>
          <Stack gap={4}>
            <BasicData control={control} />
            <AddressFields control={control} setValue={setValue} />
            <BankInformation control={control} />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose} disabled={isSubmitting}>
            Cancelar
          </Button>
          <LoadingButton loading={isSubmitting} variant="contained" type="submit">
            Salvar
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreateUpdatePartner;
