import { FC } from "react";
import useMutationDeleteInvoice from "../hooks/useMutationDeleteInvoice";

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

interface DialogDeleteInvoiceStockControlProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  invoiceId: number;
}

const DialogDeleteInvoiceStockControl: FC<DialogDeleteInvoiceStockControlProps> = ({
  companyToken,
  invoiceId,
  onClose,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationDeleteInvoice();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync({
      companyToken,
      id: invoiceId,
    });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>Cancelar nota</DialogTitleWithCloseButton>
      <DialogContent>Deseja cancelar esta NF-e?</DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" loading={isLoading} onClick={handleDelete}>
          Sim
        </LoadingButton>
        <Button disabled={isLoading} variant="outlined" onClick={handleClose}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteInvoiceStockControl;
