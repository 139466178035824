import * as yup from "yup";
import { formattedInput } from "components/CurrencyInput";

const validationSchema = yup.object().shape({
  value: yup
    .string()
    .required("O valor é obrigatório")
    .test("validPrice", "Digite um valor válido", (value) => {
      if (!value) return false;
      const price = formattedInput(value);
      return price >= 0;
    }),

  dueDate: yup
    .date()
    .required("A data de vencimento é obrigatório")
    .typeError("Digite uma data correta"),

  description: yup.string().required("A descrição é obrigatório"),
});

export default validationSchema;
