import { FC, useState } from "react";
import useQueryFileHistoryForIntegrationCompany from "../../hooks/useQueryFileHistoryForIntegrationCompany";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { DateTime } from "luxon";
import { getUrlDownloadDrive } from "pages/Home/pages/Drive/utils/getUrl";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

const years = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
  return DateTime.local().minus({ year: i }).year.toString();
});

interface DialogFileHistoryProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
}

const DialogFileHistory: FC<DialogFileHistoryProps> = ({ onClose, companyToken, ...props }) => {
  const [year, setYear] = useState(DateTime.local().year.toString());
  const {
    data: fileHistory,
    isLoading,
    isError,
  } = useQueryFileHistoryForIntegrationCompany(companyToken, year);

  return (
    <Dialog {...props} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={onClose}>Arquivos da empresa</DialogTitleWithCloseButton>

      <DialogContent>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Selecione o layout</InputLabel>
          <Select label="Selecione o layout" value={year} onChange={(e) => setYear(e.target.value)}>
            {years.map((y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Divider sx={{ my: 2 }} />

        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={300}>
            <CircularProgress />
          </Stack>
        )}
        {isError && (
          <Typography>
            Ocorreu um erro ao carregar o histórico de arquivos. Tente novamente.
          </Typography>
        )}

        {fileHistory && !isError && !isLoading && (
          <Stack gap={0.5}>
            {fileHistory.documents.map((doc) => (
              <Box
                key={doc.downloadToken}
                sx={{
                  p: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  "&:last-child": {
                    borderBottom: 0,
                  },
                }}
              >
                <Link href={getUrlDownloadDrive(doc.downloadToken, "/Integracao")} target="_blank">
                  {doc.filename}
                </Link>
                <Typography
                  sx={{ ml: 0.5 }}
                  component="small"
                  variant="body2"
                  color="text.secondary"
                >
                  ({DateTime.fromISO(doc.createdOn).toFormat("dd/MM/yyyy HH:mm")})
                </Typography>
              </Box>
            ))}
          </Stack>
        )}

        {fileHistory && !isError && !isLoading && fileHistory.documents.length === 0 && (
          <Typography sx={{ mb: 1 }}>Nenhum arquivo encontrado.</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DialogFileHistory;
