import { FC, useState } from "react";

import Head from "components/Head";
import TabNavigation, { TabNavigationProps } from "components/TabNavigation";
import { Box, Stack, Typography } from "@mui/material";

import getSubdomain from "utils/getSubdomain";
import Services from "./Services";
import Contracts from "./Contracts";
import Emails from "./Emails";
import Appearance from "./Appearance";
import Solicitation from "./Solicitation";
import MonthClosure from "./MonthClosure";

const tabs: TabNavigationProps["tabs"] = [
  {
    name: "Serviços",
    slug: "servicos",
    Component: Services,
  },
  {
    name: "Emails",
    slug: "emails",
    Component: Emails,
  },
  {
    name: "Contratos",
    slug: "contratos",
    Component: Contracts,
  },
  {
    name: "Aparência",
    slug: "aparencia",
    Component: Appearance,
  },
  {
    name: "Solicitações",
    slug: "solicitacoes",
    Component: Solicitation,
  },
  {
    name: "Fechamento do Mês",
    slug: "fechamento-do-mes",
    Component: MonthClosure,
  },
];

const Settings: FC = () => {
  const [tabActive, setTabActive] = useState("1");

  const handleChangeTab = (newValue: string) => {
    setTabActive(newValue);
  };

  return (
    <>
      <Head title={`Configurações | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Configurações
        </Typography>
      </Stack>

      <Box mt={2} boxShadow={2} bgcolor="#fff">
        <TabNavigation tabActive={tabActive} tabs={tabs} handleChangeTab={handleChangeTab} />
      </Box>
    </>
  );
};

export default Settings;
