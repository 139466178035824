import { FC } from "react";
import useQueryInvoiceCorrection from "../hooks/useQueryInvoiceCorrection";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";

import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";
import DialogAfterData from "./DialogAfterData";

export interface DialogInvoiceCorrectionProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  movementId: number;
  onClose: () => void;
}

const DialogInvoiceCorrection: FC<DialogInvoiceCorrectionProps> = ({
  companyToken,
  movementId,
  onClose,
  ...props
}) => {
  const {
    data: invoiceCorrection,
    isLoading,
    isError,
  } = useQueryInvoiceCorrection(companyToken, movementId);

  if (isLoading) {
    return <FullscreenLoadingIndicator />;
  }

  if (isError) {
    return (
      <Dialog open maxWidth="xs" fullWidth onClose={onClose}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          Error ao buscar os dados da nota fiscal, tente novamente mais tarde.
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (invoiceCorrection && !isError && !isLoading) {
    return (
      <DialogAfterData
        {...props}
        companyToken={companyToken}
        movementId={movementId}
        onClose={onClose}
        invoiceCorrection={invoiceCorrection}
      />
    );
  }

  return <></>;
};

export default DialogInvoiceCorrection;
