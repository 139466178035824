import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { uploadMessageFiles } from "services/Requests/mailbox";

const useMutationUploadMessageFiles = (ticketId: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(uploadMessageFiles, {
    onSuccess() {
      queryClient.invalidateQueries([queryKeys.getTicketById, ticketId]);
    },
  });
  return mutation;
};

export default useMutationUploadMessageFiles;
