import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getMessage } from "services/Requests/communications";

const useQueryGetMessage = (companyToken: string, messageType: number) => {
  const query = useQuery([queryKeys.emailMessage, messageType], () =>
    getMessage({ companyToken, messageType }),
  );
  return query;
};

export default useQueryGetMessage;
