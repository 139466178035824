import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCompanyParticipant } from "services/Requests/companies";

const useQueryGetCompanyParticipant = (companyToken: string, participantId: number) => {
  return useQuery([queryKeys.individualCompanyParticipant, participantId], () =>
    getCompanyParticipant({
      companyToken,
      participantId,
    }),
  );
};

export default useQueryGetCompanyParticipant;
