import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getPaymentInfo } from "services/Requests/companies";
import getSubdomain from "utils/getSubdomain";

const useQueryPaymentInfo = (companyToken: string) => {
  const subdomain = getSubdomain();

  const query = useQuery([queryKeys.paymentInfo, companyToken], () =>
    getPaymentInfo({ companyToken, subdomain }),
  );
  return query;
};

export default useQueryPaymentInfo;
