import validateCNPJ from "utils/validateCNPJ";
import validateCPF from "utils/validateCPF";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  partners: yup.array().of(
    yup.object().shape({
      name: yup.string().required("O nome do sócio é obrigatório"),
      share: yup
        .number()
        .typeError("Digite apenar números")
        .required("A sua participação é obrigatória")
        .min(0.1, "O sócio deve ter alguma participação")
        .max(100, "A sua participação deve ser no máximo de 100%"),

      cpf: yup
        .string()
        .required("O CPF ou CNPJ é obrigatório!")
        .test("validType", "Digite corretamente um número de CPF ou CNPJ", (value) => {
          if (!value) return true;

          const valueOnlyNumber = value.replace(/\D/g, "");
          return valueOnlyNumber.length === 11 || valueOnlyNumber.length === 14;
        })
        .test("validCPF", "Digite um CPF válido", (value) => {
          if (!value) return true;

          const valueOnlyNumber = value.replace(/\D/g, "");
          if (valueOnlyNumber.length !== 11) return true;

          return validateCPF(valueOnlyNumber);
        })
        .test("validCNPJ", "Digite um CNPJ válido", (value) => {
          if (!value) return true;

          const valueOnlyNumber = value.replace(/\D/g, "");
          if (valueOnlyNumber.length !== 14) return true;

          return validateCNPJ(valueOnlyNumber);
        }),
    }),
  ),
});

export default validationSchema;
