import { FC, useState } from "react";

import { useMediaQuery } from "@mui/material";

import { NameOfRoles } from "..";
import { CompanyParticipants } from "types/Company";

import ClientListTable from "./ClientListTable";
import ClientListCard from "./ClientListCard";
import DialogCreateUpdateCompanyParticipant from "../../DialogCreateUpdateCompanyParticipant";

interface ClientTableProps {
  companyParticipants: CompanyParticipants[];
  isQueryError: boolean;
  role: NameOfRoles;
  companyToken: string;
}

const ClientTable: FC<ClientTableProps> = ({
  companyParticipants,
  isQueryError,
  role,
  companyToken,
}) => {
  const isTablet = useMediaQuery("(max-width:728px)");

  const [edittedCompanyParticipant, setEdittedCompanyParticipant] =
    useState<CompanyParticipants | null>(null);

  const propsList = {
    companyParticipants: companyParticipants,
    role: role,
    isQueryError: isQueryError,
    companyToken: companyToken,
    onEdit: (participant: CompanyParticipants) => setEdittedCompanyParticipant(participant),
  };

  return (
    <>
      {isTablet ? <ClientListCard {...propsList} /> : <ClientListTable {...propsList} />}

      {edittedCompanyParticipant && (
        <DialogCreateUpdateCompanyParticipant
          keepMounted={false}
          open
          onClose={() => setEdittedCompanyParticipant(null)}
          companyToken={companyToken}
          client={edittedCompanyParticipant}
          role={role}
        />
      )}
    </>
  );
};

export default ClientTable;
