import { FC, ChangeEvent } from "react";

import { Box, Checkbox, Divider, FormControlLabel, Stack, Typography } from "@mui/material";
import { Access } from "../utils/access";

interface AccessCheckboxProps {
  access: Access;
  activeAccess: number[];
  onChangeActiveAccess: (access: number[]) => void;
}

const AccessCheckbox: FC<AccessCheckboxProps> = ({
  access,
  activeAccess,
  onChangeActiveAccess,
}) => {
  const valueIsActive = (value: number) => activeAccess.includes(value);

  const isIndeterminate = () => {
    const anyChildrenAreActive = access.children.some((child) => valueIsActive(child.value));
    const allChildrenAreActive = access.children.every((child) => valueIsActive(child.value));

    return anyChildrenAreActive && !allChildrenAreActive;
  };

  const onChangeParentCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const isActive = e.target.checked;
    const valuesChildren = access.children.map((child) => child.value);

    const includeAccess = () => {
      const newAccess = [...activeAccess, ...valuesChildren, access.value];

      const removeDuplicates = [...new Set(newAccess)];
      onChangeActiveAccess(removeDuplicates);
    };

    const removeAccess = () => {
      const removedAccess = activeAccess.filter(
        (active) => !(active === access.value || valuesChildren.includes(active)),
      );
      onChangeActiveAccess(removedAccess);
    };

    if (isActive || isIndeterminate()) {
      includeAccess();
      return;
    }

    removeAccess();
  };

  const onChangeChildCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const isActive = e.target.checked;
    const childValue = Number(e.target.value);
    const valuesChildren = access.children.map((child) => child.value);

    const includeAccess = () => {
      const allChildrenAreDisabled = activeAccess.every(
        (active) => !valuesChildren.includes(active),
      );

      const newAccess = [...activeAccess, childValue];
      if (allChildrenAreDisabled) newAccess.push(access.value);
      const removeDuplicates = [...new Set(newAccess)];

      onChangeActiveAccess(removeDuplicates);
    };

    const removeAccess = () => {
      let removedAccess = activeAccess.filter((active) => active !== childValue);
      const anyChildrenAreActive = removedAccess.some((access) => valuesChildren.includes(access));

      if (!anyChildrenAreActive) {
        removedAccess = removedAccess.filter((active) => active !== access.value);
      }

      onChangeActiveAccess(removedAccess);
    };

    if (isActive) {
      includeAccess();
      return;
    }

    removeAccess();
  };

  return (
    <Box>
      <FormControlLabel
        label={
          <Typography variant="body1" fontWeight={600}>
            {access.label}
          </Typography>
        }
        control={
          <Checkbox
            checked={valueIsActive(access.value)}
            indeterminate={isIndeterminate()}
            onChange={onChangeParentCheckbox}
          />
        }
      />
      <Divider sx={{ mb: access.children.length > 0 ? 1 : 0 }} />

      {access.children.length > 0 && (
        <Stack ml={3} direction="row" flexWrap="wrap" gap={0.5}>
          {access.children.map((child) => (
            <FormControlLabel
              label={<Typography variant="body2">{child.label}</Typography>}
              key={child.value}
              control={
                <Checkbox
                  value={child.value}
                  size="small"
                  checked={valueIsActive(child.value)}
                  onChange={onChangeChildCheckbox}
                />
              }
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default AccessCheckbox;
