import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getLatestTransactions } from "services/Requests/dashboard";
import { ISODate } from "types/SemanticTypes";

const useQueryGetLatestTransactions = (
  companyToken: string,
  startDate: ISODate,
  endDate: ISODate,
  count: number,
) => {
  const query = useQuery(
    [queryKeys.latestTransactions, startDate, endDate, count],
    () =>
      getLatestTransactions({
        companyToken,
        count,
        endDate,
        startDate,
      }),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 2, // 2 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
    },
  );
  return query;
};

export default useQueryGetLatestTransactions;
