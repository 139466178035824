import { FC, useState } from "react";
import useQueryGetInvoiceConfig from "pages/Home/hooks/useQueryGetInvoiceConfig";
import { useQueryClient } from "react-query";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";

import FilterPeriod from "components/FilterPeriod";
import SearchInput from "components/SearchInput";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";

import { DateTime } from "luxon";
import { InvoiceStatus } from "types/Company";
import InvoiceForm from "./InvoiceForm";
import InvoiceController from "./InvoiceController";
import queryKeys from "react-query/constants";

const INVOICE_STATUS: InvoiceStatus[] = [
  "Aguardando Baixa",
  "Aguardando Emissão",
  "Autorizada",
  "Cancelada",
  "Cancelamento Negado",
  "Em Autorização",
  "Em Cancelamento",
  "Negada",
  "SolicitandoCancelamento",
];

interface InvoicesIssuedProps {
  companyToken: string;
  isStockControl?: boolean;
  issueInvoice?: boolean;
}

const InvoicesIssued: FC<InvoicesIssuedProps> = ({
  companyToken,
  isStockControl = false,
  issueInvoice = true,
}) => {
  const queryClient = useQueryClient();
  const { data: invoiceConfig, isLoading, isError } = useQueryGetInvoiceConfig(companyToken);

  const [date, setDate] = useState({
    start: DateTime.local().startOf("month").toISO(),
    end: DateTime.local().endOf("month").toISO(),
  });
  const [status, setStatus] = useState<InvoiceStatus | null>(null);
  const [search, setSearch] = useState("");
  const [showInvoiceForm, setShowInvoiceForm] = useState(false);

  return (
    <>
      {issueInvoice && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ mb: 1.5 }}
          onClick={() => setShowInvoiceForm(true)}
        >
          Emitir NFS-e
        </Button>
      )}

      <Stack direction="row" justifyContent="space-between" gap={1}>
        <Stack gap={1} direction="row">
          <FilterPeriod
            startDate={date.start}
            endDate={date.end}
            onChangeDates={setDate}
            label="Período"
          />
          <FormControl>
            <InputLabel>Status</InputLabel>
            <Select
              value={status ?? "all"}
              label="Status"
              size="small"
              onChange={(e) =>
                setStatus(e.target.value === "all" ? null : (e.target.value as InvoiceStatus))
              }
            >
              <MenuItem value="all">Todos</MenuItem>
              {INVOICE_STATUS.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <SearchInput value={search} onChange={setSearch} size="small" />
      </Stack>

      <Divider sx={{ my: 2 }} />

      <IconButton
        onClick={() => {
          queryClient.invalidateQueries(queryKeys.invoiceConfigStockControl, { exact: false });
          queryClient.invalidateQueries(queryKeys.invoices, { exact: false });
        }}
      >
        <RefreshIcon />
      </IconButton>

      <InvoiceController
        companyToken={companyToken}
        endDate={date.end}
        isStockControl={isStockControl}
        search={search}
        startDate={date.start}
        status={status}
      />

      {showInvoiceForm && issueInvoice && !!invoiceConfig && (
        <InvoiceForm
          open={showInvoiceForm}
          onClose={() => setShowInvoiceForm(false)}
          companyToken={companyToken}
          invoiceConfig={invoiceConfig}
        />
      )}

      {isLoading && showInvoiceForm && <FullscreenLoadingIndicator />}
      {isError && showInvoiceForm && (
        <Dialog open maxWidth="xs" fullWidth onClose={() => setShowInvoiceForm(false)}>
          <DialogTitle>Ocorreu um erro</DialogTitle>

          <DialogContent>
            Ao tentar acessar as configurações de NFS-e ocorreu um erro, tente novamente mais tarde.
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setShowInvoiceForm(false)}>Fechar</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default InvoicesIssued;
