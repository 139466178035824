import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { uploadFiles } from "services/Requests/mailbox";

const useMutationUploadFiles = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(uploadFiles, {
    onSuccess() {
      queryClient.invalidateQueries(queryKeys.getTicketById, { exact: false });
      queryClient.invalidateQueries(queryKeys.ticket, { exact: false });
      queryClient.invalidateQueries(queryKeys.chats, { exact: false });
    },
  });
  return mutation;
};

export default useMutationUploadFiles;
