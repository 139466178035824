import { TypesOfUsers } from "types/UserTypes";

export interface Access {
  value: number;
  label: string;
  children: Omit<Access, "children">[];
}

export const access: Access[] = [
  {
    value: 1,
    label: "Configurações",
    children: [
      {
        label: "Serviços",
        value: 2,
      },
      {
        label: "Emails",
        value: 3,
      },
      {
        label: "Contratos",
        value: 4,
      },
      {
        label: "Aparência",
        value: 5,
      },
      {
        label: "Solicitações",
        value: 6,
      },
      {
        label: "Fechamento do mês",
        value: 7,
      },
    ],
  },
  {
    value: 8,
    label: "Clientes",
    children: [
      {
        label: "Contabilidade",
        value: 9,
      },
      {
        label: "Legalização",
        value: 10,
      },
      {
        label: "Oportunidades",
        value: 11,
      },
    ],
  },
  {
    value: 12,
    label: "Gestão de Cobrança",
    children: [
      {
        label: "Painel",
        value: 13,
      },
      {
        label: "Extrato Financeiro",
        value: 14,
      },
      {
        label: "Assinaturas",
        value: 15,
      },
      {
        label: "Faturas",
        value: 16,
      },
      {
        label: "Antecipações",
        value: 17,
      },
      {
        label: "Taxas",
        value: 18,
      },
      {
        label: "Configurações",
        value: 19,
      },
      {
        label: "Multas e Descontos",
        value: 20,
      },
    ],
  },
  {
    value: 21,
    label: "Notas Fiscais",
    children: [
      {
        label: "Emitidas",
        value: 22,
      },
      {
        label: "Configurações",
        value: 23,
      },
    ],
  },
  {
    value: 24,
    label: "Cadastros",
    children: [
      {
        label: "Usuários",
        value: 25,
      },
      {
        label: "Perfis",
        value: 26,
      },
    ],
  },
  {
    value: 27,
    label: "Plano de contas",
    children: [
      {
        label: "Financeiro",
        value: 28,
      },
      {
        label: "Contábil",
        value: 29,
      },
      {
        label: "De -> Para",
        value: 30,
      },
    ],
  },
  {
    value: 31,
    label: "Solicitações",
    children: [],
  },
  {
    value: 32,
    label: "Minha Empresa",
    children: [
      {
        label: "Acessar",
        value: 33,
      },
      {
        label: "Configurações",
        value: 34,
      },
    ],
  },
];

export const accessUser: Access[] = [
  {
    value: 33,
    label: "Dashboard",
    children: [],
  },
  {
    value: 12,
    label: "Gestão de Cobrança",
    children: [
      {
        value: 13,
        label: "Painel",
      },
      {
        value: 14,
        label: "Extrato Financeiro",
      },
      {
        value: 16,
        label: "Faturas",
      },
      {
        value: 17,
        label: "Antecipações",
      },
      {
        value: 18,
        label: "Taxas",
      },
      {
        value: 19,
        label: "Configurações",
      },
      {
        value: 20,
        label: "Multas e Descontos",
      },
    ],
  },
  {
    value: 100,
    label: "Lançamentos",
    children: [],
  },
  {
    value: 101,
    label: "Controle de Estoque",
    children: [
      {
        value: 102,
        label: "Produtos",
      },
      {
        value: 103,
        label: "Movimentações",
      },
      {
        value: 104,
        label: "Saldo do Estoque",
      },
      {
        value: 105,
        label: "Nota Emitidas",
      },
      {
        value: 106,
        label: "Configurar NF-e",
      },
    ],
  },
  {
    value: 107,
    label: "Relatórios",
    children: [],
  },
  {
    value: 114,
    label: "Drive",
    children: [],
  },
  {
    value: 115,
    label: "Fechamento do Mês",
    children: [],
  },
  {
    value: 116,
    label: "NFS-e",
    children: [
      {
        value: 117,
        label: "Notas Fiscais",
      },
      {
        value: 118,
        label: "Configurações",
      },
    ],
  },
  {
    value: 119,
    label: "Cadastros",
    children: [
      {
        value: 120,
        label: "Contas",
      },
      {
        value: 121,
        label: "Clientes",
      },
      {
        value: 122,
        label: "Fornecedores",
      },
      {
        value: 123,
        label: "Categorias",
      },
      {
        value: 125,
        label: "Sócios",
      },
    ],
  },
  {
    value: 126,
    label: "Administrativo",
    children: [
      {
        value: 127,
        label: "Usuários",
      },
      {
        value: 128,
        label: "Empresas",
      },
      {
        value: 129,
        label: "Perfis",
      },
    ],
  },
  {
    value: 130,
    label: "Marketplace",
    children: [],
  },
];

export const getAccessData = (userType: TypesOfUsers): Access[] => {
  return userType !== "Accountant" && userType !== "Admin" ? accessUser : access;
};
