import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";
import useQueryGetTransaction from "pages/Home/pages/MonthClosure/hooks/useQueryGetTransaction";
import { FC } from "react";
import DialogConsult from "./DialogConsult";

interface DialogConsultInvoiceProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  invoiceId: number;
}

const DialogConsultInvoice: FC<DialogConsultInvoiceProps> = ({
  onClose,
  companyToken,
  invoiceId,
  ...props
}) => {
  const { data: invoice, isLoading, isError } = useQueryGetTransaction(companyToken, invoiceId);

  if (isLoading) {
    return <FullscreenLoadingIndicator />;
  }

  if (isError) {
    return (
      <Dialog maxWidth="xs" open onClose={onClose} fullWidth>
        <DialogTitle>Erro</DialogTitle>

        <DialogContent>
          Ocorreu um erro ao tentar acessar a informação da nota fiscal.
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (!isError && !isLoading && invoice) {
    return <DialogConsult {...props} onClose={onClose} transaction={invoice} />;
  }

  return null;
};

export default DialogConsultInvoice;
