import { DateTime } from "luxon";
import { useCallback } from "react";
import { useQuery, UseQueryResult } from "react-query";
import queryKeys from "react-query/constants";
import { getAllNotifications } from "services/Requests/notifications";
import { AllNotificationWithMonthAndYear, AllNotification } from "types/Notifications";
import comparatorSortByProperty from "utils/comparatorSortByProperty";
import stableSort from "utils/stableSort";

type CallbackSelectorData = (data: AllNotification[]) => AllNotificationWithMonthAndYear[];

type QueryType<T> = UseQueryResult<
  T extends true ? AllNotificationWithMonthAndYear[] : AllNotification[],
  unknown
>;

type Date = {
  start: string;
  end: string;
};

interface QueryFilters {
  date: {
    start?: string | null;
    end?: string | null;
  };
  type?: string;
  search?: string;
}

function useQueryAllNotifications<T extends boolean>(
  companyToken: string,
  normalize: T,
  filters?: QueryFilters,
): QueryType<T> {
  const withMonthAndYear = useCallback<CallbackSelectorData>(
    (data) => {
      const sortNotifications = stableSort(data, comparatorSortByProperty("desc", "date"));

      const filterNotifications = (data: AllNotification[]): AllNotification[] => {
        const isAppliedDateFilter = (date?: QueryFilters["date"]): date is Date => {
          return !!date && !!date.end && !!date.start;
        };

        const date = filters?.date;

        const applyDateFilter = (notification: AllNotification) => {
          if (isAppliedDateFilter(date)) {
            const dateNotification = DateTime.fromISO(notification.date);
            const startDate = DateTime.fromISO(date.start);
            const endDate = DateTime.fromISO(date.end);

            return dateNotification > startDate && dateNotification < endDate;
          }

          return true;
        };

        const applyTypeFilter = (notification: AllNotification) => {
          if (!filters?.type) return true;
          return notification.description === filters.type;
        };

        const applySearchFilter = (notification: AllNotification) => {
          if (!filters?.search) return true;
          return notification.message.toLowerCase().search(filters.search.toLowerCase()) !== -1;
        };

        return data.filter((n) => applyDateFilter(n) && applyTypeFilter(n) && applySearchFilter(n));
      };

      return filterNotifications(sortNotifications).map((notification) => ({
        ...notification,
        monthAndYear: DateTime.fromISO(notification.date).toFormat("LLLL/yyyy"),
      }));
    },
    [filters],
  );

  const query = useQuery(queryKeys.allNotifications, () => getAllNotifications({ companyToken }), {
    staleTime: 1000 * 60 * 1, // 1 minute
    select: normalize ? withMonthAndYear : undefined,
  });
  return query as QueryType<T>;
}

export default useQueryAllNotifications;
