import { ReactNode } from "react";
import { Order } from "../../types/SemanticTypes";
import {
  TableHead,
  TableRow,
  TableCell,
  TableCellProps,
  TableSortLabel,
  Checkbox,
} from "@mui/material";

interface HeadCells<T> {
  label: ReactNode;
  id: keyof T;
  sortable?: boolean;
  tableCellProps?: TableCellProps;
}

interface SortableTableHeadProps<T> {
  order: Order;
  headCells: HeadCells<T>[];
  orderBy: keyof T;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  tableCellProps?: TableCellProps;
  children?: React.ReactNode;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowsCount?: number;
  rowsSelected?: number;
}

function SortableTableHead<T>({
  tableCellProps,
  headCells,
  onRequestSort,
  order,
  orderBy,
  children,
  onSelectAllClick,
  rowsCount,
  rowsSelected,
}: SortableTableHeadProps<T>) {
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {rowsCount !== undefined && rowsSelected !== undefined && onSelectAllClick !== undefined && (
          <TableCell padding="checkbox" className="no-print" data-exclude="true">
            <Checkbox
              size="small"
              color="primary"
              indeterminate={rowsSelected > 0 && rowsSelected < rowsCount}
              checked={rowsCount > 0 && rowsSelected === rowsCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id as string | number}
            sortDirection={orderBy === headCell.id ? order : false}
            {...tableCellProps}
            {...headCell.tableCellProps}
          >
            {headCell.sortable || headCell.sortable === undefined ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  "@media print": {
                    svg: {
                      display: "none !important",
                    },
                  },
                }}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}

        {children}
      </TableRow>
    </TableHead>
  );
}

export default SortableTableHead;
