export const hasCPFLength = (cpf: string): boolean => {
  if (cpf.length > 11 || cpf.length < 11) {
    return false;
  }

  return true;
};

export const allDigitsAreEqual = (digits: string): boolean => {
  for (let i = 0; i < 10; i++) {
    if (digits === new Array(digits.length + 1).join(String(i))) {
      return true;
    }
  }

  return false;
};

export const calcFirstChecker = (firstNineDigits: string): number => {
  let sum = 0;

  for (let i = 0; i < 9; ++i) {
    sum += Number(firstNineDigits.charAt(i)) * (10 - i);
  }

  const lastSumChecker = sum % 11;
  return lastSumChecker < 2 ? 0 : 11 - lastSumChecker;
};

export const calcSecondChecker = (cpfWithChecker1: string): number => {
  let sum = 0;

  for (let i = 0; i < 10; ++i) {
    sum += Number(cpfWithChecker1.charAt(i)) * (11 - i);
  }

  const lastSumChecker2 = sum % 11;
  return lastSumChecker2 < 2 ? 0 : 11 - lastSumChecker2;
};

/**
 * @function Validate
 * @param  {string} value  CPF number
 *
 * @returns {boolean}                true = valid || false = invalid
 */
const validateCPF = (value: string): boolean => {
  if (typeof value !== "string") {
    return false;
  }

  const cleanCPF = String(value).replace(/[\s.-]/g, "");
  const firstNineDigits = cleanCPF.slice(0, 9);
  const checker = cleanCPF.slice(9, 11);

  if (!hasCPFLength(cleanCPF) || allDigitsAreEqual(cleanCPF)) {
    return false;
  }

  const checker1 = calcFirstChecker(firstNineDigits);
  const checker2 = calcSecondChecker(`${firstNineDigits}${checker1}`);

  return checker === `${checker1}${checker2}`;
};

export default validateCPF;
