import { useCallback } from "react";
import { useQuery } from "react-query";
import { NormalizedClient } from "types/Client";
import queryKeys from "../../../../../../../react-query/constants";
import { getClients, GetClientsPayload } from "../../../../../../../services/Requests/marketplace";

export type FilterTypeClient = "accountants" | "premium" | "freemium" | "lead" | "suspended";

const compareTypeUser: { [x: string]: string } = {
  accountants: "Accountant",
  lead: "User",
  freemium: "Freemium",
  premium: "Premium",
};

export const useQueryClients = (
  filterType: FilterTypeClient,
  statusClient: GetClientsPayload["verificationStatus"],
  search: string,
) => {
  const selectorFilters = useCallback(
    (data: NormalizedClient[]) => {
      const searchClient = (name: string) => {
        if (!search.trim()) return true;

        return name.toLowerCase().search(search.toLowerCase()) !== -1;
      };

      return data.filter((client) => {
        if (!(searchClient(client.companyName) || searchClient(client.owner))) return false;

        if (filterType === "suspended") {
          return client.status === "Suspended";
        }

        return client.userType === compareTypeUser[filterType];
      });
    },
    [filterType, search],
  );

  const query = useQuery(
    [queryKeys.clients, statusClient],
    () =>
      getClients({
        registrationStatus: -1,
        status: 0,
        verificationStatus: statusClient,
      }),
    {
      select: selectorFilters,
      staleTime: 1000 * 60 * 20, // 20 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
    },
  );

  return query;
};
