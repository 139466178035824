import { forwardRef, Ref } from "react";

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import useQueryPaymentModes from "pages/Home/hooks/useQueryPaymentModes";

interface PaymentModesSelectInnerProps extends SelectProps {
  FormControlProps?: FormControlProps;
  helperText?: string;
}

function PaymentModesSelectInner(
  { helperText, FormControlProps, ...props }: PaymentModesSelectInnerProps,
  ref: Ref<unknown>,
) {
  const { data: paymentTypes = [], isLoading, isError } = useQueryPaymentModes();

  return (
    <FormControl
      disabled={props.disabled}
      error={props.error}
      size={props.size}
      {...FormControlProps}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select {...props} ref={ref} value={props.value || ""}>
        <MenuItem disabled value="">
          Selecione:{" "}
        </MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && (
          <MenuItem disabled>Ocorreu um erro ao carregar as formas de pagamento</MenuItem>
        )}

        {paymentTypes.map((payment) => (
          <MenuItem key={payment.id} value={payment.id}>
            {payment.description}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const PaymentModesSelect = forwardRef(PaymentModesSelectInner);
export default PaymentModesSelect;
