import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { updateCategory } from "services/Requests/companies";

const useMutationUpdateCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCategory, {
    onSuccess() {
      toast.success("Categoria atualizada com sucesso");
      queryClient.invalidateQueries(queryKeys.financialCategories);
    },
  });

  return mutation;
};

export default useMutationUpdateCategory;
