import { useCallback } from "react";
import { useQuery } from "react-query";
import queryKeys from "react-query/constants";

import { municipalServiceByCompany } from "services/Requests/invoice";
import { MunicipalServiceResponse } from "types/Invoice";

const useQueryMunicipalService = (companyToken: string) => {
  const select = useCallback((data: MunicipalServiceResponse) => data?.result?.data, []);

  const query = useQuery(
    queryKeys.municipalServiceByCompany,
    () => municipalServiceByCompany({ companyToken }),
    {
      select,
      staleTime: 1000 * 60 * 20, // 20 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
    },
  );
  return query;
};

export default useQueryMunicipalService;
