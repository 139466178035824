import { FC, useMemo, useState } from "react";
import useMutationAddOrderOpenCompany from "../../hooks/useMutationAddOrderOpenCompany";
import { useForm, FormProvider, DefaultValues } from "react-hook-form";

import { Dialog, DialogContent } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import DialogMessageAfterAdd from "../../components/DialogMessageAfterAdd";
import DialogConfirmClose from "../../components/DialogConfirmClose";

import { ServiceDialogProps } from "..";
import TabsController from "./TabsController";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import toast from "react-hot-toast";

export interface FormFields {
  servicePremiumPriceId: string;
  iAgree: boolean;
}

const DialogPremiumOpening: FC<ServiceDialogProps> = ({
  onClose,
  companyToken,
  service,
  ...props
}) => {
  const { mutateAsync } = useMutationAddOrderOpenCompany();
  const [showMessageAfterAdd, setShowMessageAfterAdd] = useState(false);
  const [confirmExit, setConfirmExit] = useState(false);

  const defaultValues = useMemo<DefaultValues<FormFields>>(
    () => ({
      servicePremiumPriceId: service.premiumPrices.find((p) => p.isActive)?.id?.toString(),
      iAgree: false,
    }),
    [service],
  );
  const formMethods = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleClose = () => {
    if (formMethods.formState.isSubmitting) {
      return;
    }

    setShowMessageAfterAdd(false);
    onClose();
  };

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    const premiumPrice = service.premiumPrices.find(
      (premium) => premium.id === Number(data.servicePremiumPriceId),
    );

    if (!premiumPrice) {
      toast.error("Selecione um plano de serviço");
      return;
    }

    const isSuccess = await mutateAsync({
      companyToken,
      orderState: 0,
      typeOrder: 0,
      paymentState: 0,
      parcels: "0/1",
      typeService: 0,
      value: premiumPrice.priceInCents,
    });

    if (isSuccess) {
      setShowMessageAfterAdd(true);
    } else {
      toast.error("Ocorreu um erro ao criar o pedido");
    }
  });

  return (
    <FormProvider {...formMethods}>
      <Dialog
        {...props}
        open={props.open && !showMessageAfterAdd}
        onClose={() => setConfirmExit(true)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitleWithCloseButton onClose={() => setConfirmExit(true)}>
          Consultoria Premium - {service.groupName}
        </DialogTitleWithCloseButton>

        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(e);
          }}
        >
          <DialogContent>
            <TabsController companyToken={companyToken} service={service} />
          </DialogContent>
        </form>
      </Dialog>

      <DialogMessageAfterAdd
        title={`Consultoria Premium - ${service.groupName}`}
        message={service.serviceTexts.endText}
        open={showMessageAfterAdd}
        onClose={handleClose}
      />

      <DialogConfirmClose
        open={confirmExit}
        onClose={() => setConfirmExit(false)}
        onConfirm={handleClose}
      />
    </FormProvider>
  );
};

export default DialogPremiumOpening;
