import { FC, memo, useState } from "react";

import DialogClientDetail from "./DialogClientDetail";
import DialogChangeStatus from "./DialogChangeStatus";
import DialogSendBalance from "./DialogSendBalance";
import DialogReceiveBalance from "./DialogReceiveBalance";
import {
  TableRow,
  TableCell,
  Box,
  Stack,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Grow,
  Link,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MenuIcon from "@mui/icons-material/Menu";
import { red, green, yellow } from "@mui/material/colors";

import { NormalizedClient } from "../../../../../../../types/Client";
import { useQueryClientBalance } from "../hooks/useQueryClientBalance";
import { useAuth } from "context/Auth";
import toast from "react-hot-toast";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import useMutationChangeOwnerOsaykAccountant from "../hooks/useMutationChangeOwnerOsaykAccountant";

const clientStatusColor: { [x in NormalizedClient["verificationStatus"]]: string } = {
  "Em aprovação": yellow[700],
  "Não verificada": red[600],
  Aprovada: green[700],
};

interface ClientTableItemProps {
  client: NormalizedClient;
}

const ClientTableItem: FC<ClientTableItemProps> = ({ client }) => {
  const { mutateAsync: mutateChangeOwner, isLoading: isLoadingChange } =
    useMutationChangeOwnerOsaykAccountant();
  const [loadBalance, setLoadBalance] = useState(false);
  const {
    user: { company },
  } = useAuth();
  const { data, isLoading } = useQueryClientBalance(client.subdomain, loadBalance);

  const [confirmationChangeStatus, setConfirmationChangeStatus] = useState(false);
  const [sendBalanceIsOpen, setSendBalanceIsOpen] = useState(false);
  const [receiveBalanceIsOpen, setReceiveBalanceIsOpen] = useState(false);

  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  const [detailDialogIsOpen, setDetailDialogIsOpen] = useState(false);

  const handleCloseMenu = () => setAnchorElMenu(null);

  const handleChangeOwner = async () => {
    await mutateChangeOwner({
      ownerId: client.ownerId,
      payerCompanyId: client.payerCompanyId,
    });
    window.open(`https://${client.subdomain}.osayk.com.br/#/login`, "_blank");
  };

  return (
    <>
      <TableRow
        hover
        sx={{
          opacity: isLoadingChange ? 0.5 : 1,
          pointerEvents: isLoadingChange ? "none" : undefined,
        }}
      >
        <TableCell>
          <Tooltip title="Detalhes">
            <Link
              sx={{
                "&:hover": {
                  color: "GrayText",
                },
                cursor: "pointer",
                textDecoration: "none",
                color: "initial",
              }}
              onClick={() => setDetailDialogIsOpen(true)}
            >
              <Box
                display="inline-block"
                mr={0.5}
                component="span"
                borderRadius="50%"
                width={10}
                height={10}
                bgcolor={clientStatusColor[client.verificationStatus]}
              />
              {client.companyName || client.owner}
            </Link>
          </Tooltip>
        </TableCell>

        <TableCell>{!!client.companyName && client.owner}</TableCell>
        <TableCell>{client.ownerEmail}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{client.phone}</TableCell>
        <TableCell>{new Date(client.createdOn).toLocaleDateString()}</TableCell>

        <TableCell sx={{ position: "relative", whiteSpace: "nowrap" }}>
          <Grow in={isLoading}>
            <Stack
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              position="absolute"
              top={0}
              right={0}
            >
              <CircularProgress size={30} />
            </Stack>
          </Grow>

          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
            sx={{
              transition: "all .2 ease",
              opacity: isLoading ? 0.3 : 1,
            }}
          >
            {data ? data.balance : client.balance}
            {!loadBalance && client.subdomain && !data && (
              <Tooltip title="Carregar saldo do cliente">
                <Box>
                  <IconButton size="small" onClick={() => setLoadBalance(true)}>
                    <AutorenewIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
          </Stack>
        </TableCell>

        <TableCell>
          <IconButton onClick={(e) => setAnchorElMenu(e.currentTarget)}>
            <MenuIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <Menu
        open={menuIsOpen}
        anchorEl={anchorElMenu}
        onClick={handleCloseMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            if (!company) {
              toast.error(MESSAGE_NO_COMPANY);
            } else {
              setDetailDialogIsOpen(true);
            }
          }}
        >
          Detalhes
        </MenuItem>
        <MenuItem onClick={handleChangeOwner}>
          <a
            href={`https://${client.subdomain}.osayk.com.br/#/login`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.preventDefault()}
          >
            Acessar conta
          </a>
        </MenuItem>
        <MenuItem onClick={() => setConfirmationChangeStatus(true)}>
          {client.status === "Suspended" ? "Reativar" : "Suspender"} acesso
        </MenuItem>
        <MenuItem onClick={() => setSendBalanceIsOpen(true)}>Enviar Saldo para Osayk</MenuItem>
        <MenuItem
          onClick={() => {
            if (company) {
              setReceiveBalanceIsOpen(true);
            } else {
              toast.error("Empresa não localizada!");
            }
          }}
        >
          Receber Saldo de Osayk
        </MenuItem>
      </Menu>

      {receiveBalanceIsOpen && company && (
        <DialogReceiveBalance
          companyId={company.id}
          client={client}
          open={receiveBalanceIsOpen}
          onClose={() => setReceiveBalanceIsOpen(false)}
        />
      )}

      {sendBalanceIsOpen && (
        <DialogSendBalance
          client={client}
          open={sendBalanceIsOpen}
          onClose={() => setSendBalanceIsOpen(false)}
        />
      )}

      <DialogChangeStatus
        open={confirmationChangeStatus}
        onClose={() => setConfirmationChangeStatus(false)}
        clientInformation={client}
      />

      {detailDialogIsOpen && company && (
        <DialogClientDetail
          scroll="body"
          open={detailDialogIsOpen}
          onClose={() => setDetailDialogIsOpen(false)}
          clientInformation={client}
          companyToken={company.token}
        />
      )}
    </>
  );
};

export default memo(ClientTableItem);
