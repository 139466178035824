import { FC } from "react";
import useQueryProfile from "../hooks/useQueryProfile";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";
import useQueryCompanyPartners from "pages/Home/hooks/useQueryCompanyPartners";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";

import { Order as IOrder } from "types/OpenCompany";
import { translateTypeService } from "pages/Home/pages/Marketplace/components/Legalization/OrdersTableItem";
import OrderStatus from "./OrderStatus";
import CompanyType from "./CompanyType";

interface OrderProps {
  order: IOrder;
}

const Order: FC<OrderProps> = ({ order }) => {
  const { data: profile, isLoading: isLoadingProfile } = useQueryProfile(order.companyToken);
  const { data: company, isLoading: isLoadingCompany } = useQueryGetCompany(order.companyToken);
  const { data: companyPartners, isLoading: isLoadingPartners } = useQueryCompanyPartners(
    order.companyToken,
  );
  const isLoading = isLoadingProfile || isLoadingCompany || isLoadingPartners;

  return (
    <Box>
      <Typography variant="h5">{translateTypeService[order.typeService]}</Typography>

      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}

      {profile && company && companyPartners && !isLoading && (
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mt={2}>
          <Box>
            <Typography sx={{ borderBottom: 1, borderColor: "divider" }} variant="h6" gutterBottom>
              Dados do usuário
            </Typography>

            <Box border={1} borderColor="divider" p={2}>
              <Stack direction="row" gap={1} justifyContent="space-between">
                <Typography variant="body2" fontWeight={600}>
                  Nome:
                </Typography>
                <Typography variant="body2">{profile.name}</Typography>
              </Stack>
              <Stack direction="row" gap={1} justifyContent="space-between">
                <Typography variant="body2" fontWeight={600}>
                  CPF:
                </Typography>
                <Typography variant="body2">{profile.cpf}</Typography>
              </Stack>
              <Stack direction="row" gap={1} justifyContent="space-between">
                <Typography variant="body2" fontWeight={600}>
                  Email:
                </Typography>
                <Typography variant="body2">{profile.email}</Typography>
              </Stack>
              <Stack direction="row" gap={1} justifyContent="space-between">
                <Typography variant="body2" fontWeight={600}>
                  Telefone:
                </Typography>
                <Typography variant="body2">{profile.phone}</Typography>
              </Stack>
            </Box>
          </Box>

          <OrderStatus order={order} />

          <Box>
            <Typography sx={{ borderBottom: 1, borderColor: "divider" }} variant="h6" gutterBottom>
              Dados da Empresa
            </Typography>

            <Box border={1} borderColor="divider" p={2}>
              <Stack direction="row" gap={1} justifyContent="space-between">
                <Typography variant="body2" fontWeight={600}>
                  Área:
                </Typography>
                <Typography variant="body2">{company.companyActivity}</Typography>
              </Stack>
              <Stack direction="row" gap={1} justifyContent="space-between">
                <Typography variant="body2" fontWeight={600}>
                  Sócios:
                </Typography>
                <Typography variant="body2">{companyPartners?.length}</Typography>
              </Stack>
              <Stack direction="row" gap={1} justifyContent="space-between">
                <Typography variant="body2" fontWeight={600}>
                  Município:
                </Typography>
                <Typography variant="body2">{company.address.city}</Typography>
              </Stack>
              <Stack direction="row" gap={1} justifyContent="space-between">
                <Typography variant="body2" fontWeight={600}>
                  Estado:
                </Typography>
                <Typography variant="body2">{company.address.state}</Typography>
              </Stack>
            </Box>
          </Box>

          <CompanyType order={order} company={company} />
        </Box>
      )}
    </Box>
  );
};

export default Order;
