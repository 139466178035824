import { ChipProps } from "@mui/material";
import { TransactionsItem } from "types/Company";
import { LatestTransactions } from "types/Dashboard";

const getStatusColor = ({
  transactionType,
  paymentDate,
}: TransactionsItem | LatestTransactions): ChipProps["color"] => {
  if (transactionType === "Credit") {
    if (paymentDate) {
      return "success";
    } else {
      return "info";
    }
  } else {
    if (paymentDate) {
      return "error";
    } else {
      return "info";
    }
  }
};

export default getStatusColor;
