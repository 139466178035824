import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getTemplate } from "services/Requests/template";

const useQueryTemplate = (companyToken: string, id: number) => {
  const query = useQuery([queryKeys.templates, id], () => getTemplate({ companyToken, id }));
  return query;
};

export default useQueryTemplate;
