import { FC } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import BarCodeIcon from "assets/BarCodeIcon";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import ControllerInput from "components/ControllerInput";
import { cardNumberMask } from "utils/masks";
import { FormFields } from "..";
import MaskedInput from "react-text-mask";

interface PaymentMethodProps {
  onBack: () => void;
}

const PaymentMethod: FC<PaymentMethodProps> = ({ onBack }) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<FormFields>();
  const paymentOption = useWatch({
    control,
    name: "paymentOption",
  });

  return (
    <Box>
      <Box mx="auto" maxWidth="sm">
        <Typography variant="body1">
          Você poderá pagar através de boletos bancários ou automaticamente com seu cartão de
          crédito.
        </Typography>

        <Controller
          control={control}
          name="paymentOption"
          render={({ field, fieldState }) => (
            <FormControl fullWidth error={!!fieldState.error?.message}>
              <RadioGroup row sx={{ justifyContent: "center", gap: 2 }} {...field}>
                <FormControlLabel
                  value="card"
                  control={
                    <Radio
                      icon={<CreditCardIcon fontSize="large" sx={{ width: 120, height: 120 }} />}
                      checkedIcon={
                        <CreditCardIcon fontSize="large" sx={{ width: 120, height: 120 }} />
                      }
                    />
                  }
                  label="Cartão de crédito"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="bankslip"
                  control={
                    <Radio
                      icon={<BarCodeIcon fontSize="large" sx={{ width: 120, height: 120 }} />}
                      checkedIcon={
                        <BarCodeIcon fontSize="large" sx={{ width: 120, height: 120 }} />
                      }
                    />
                  }
                  label="Boleto bancário"
                  labelPlacement="bottom"
                />
              </RadioGroup>

              {!!fieldState.error?.message && (
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />

        {!!paymentOption && <Divider sx={{ my: 2 }} />}

        {paymentOption === "bankslip" && (
          <Typography variant="body1">O boleto será enviado para seu email de cadastro.</Typography>
        )}

        {paymentOption === "card" && (
          <Box display="grid" gap={2}>
            <Typography variant="body1">O valor será cobrado em seu cartão de crédito.</Typography>

            <Controller
              control={control}
              name="card.number"
              render={({ field, fieldState }) => (
                <MaskedInput
                  mask={cardNumberMask}
                  guide={false}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  render={(ref, props) => (
                    <TextField
                      fullWidth
                      label="Número do cartão"
                      variant="outlined"
                      inputRef={ref}
                      {...props}
                      sx={{ flex: 1 }}
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                    />
                  )}
                ></MaskedInput>
              )}
            />

            <ControllerInput
              control={control}
              name="card.name"
              inputProps={{ label: "Nome impresso no cartão" }}
            />

            <Typography variant="body1" sx={{ width: "100%", mb: -0.8 }}>
              Validade
            </Typography>

            <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr 2fr">
              <Controller
                control={control}
                name="card.monthValidity"
                render={({ field, fieldState }) => (
                  <DesktopDatePicker
                    views={["month"]}
                    label="Mês"
                    inputFormat="MM"
                    disableHighlightToday
                    {...field}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={fieldState.error?.message}
                        error={!!fieldState.error?.message || params.error}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name="card.yearValidity"
                render={({ field, fieldState }) => (
                  <DesktopDatePicker
                    views={["year"]}
                    label="Ano"
                    disablePast
                    disableHighlightToday
                    {...field}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={fieldState.error?.message}
                        error={!!fieldState.error?.message || params.error}
                      />
                    )}
                  />
                )}
              />

              <ControllerInput
                control={control}
                name="card.securityCode"
                inputProps={{
                  label: "Cód. de segurança",
                  type: "number",
                  sx: { mr: 1 },
                  inputProps: { maxLength: 3 },
                }}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Divider sx={{ mt: 4, mb: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Button
          variant="contained"
          type="button"
          startIcon={<NavigateBeforeIcon />}
          onClick={onBack}
        >
          Anterior
        </Button>
        <LoadingButton loading={isSubmitting} variant="contained" type="submit">
          Confirmar
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default PaymentMethod;
