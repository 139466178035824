import { useMemo } from "react";
import { Controller, DefaultValues, useForm } from "react-hook-form";
import useMutationAddAccountFromTo from "../../hooks/useMutationAddAccountFromTo";
import useMutationUpdateFromTo from "../../hooks/useMutationUpdateFromTo";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { classRepresentation, natureRepresentation } from "../../ChartOfAccountTableItem";
import {
  ChartOfAccountFromToGroupClass,
  ChartOfAccountFromToGroupNature,
  ChartOfAccountsFromToGroup,
} from "types/Accountant";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import useMutationAddCompanyAccountFromTo from "../../hooks/useMutationAddCompanyAccountFromTo";
import useMutationUpdateCompanyFromTo from "../../hooks/useMutationUpdateCompanyFromTo";

interface Form {
  description: string;
  account: string;
  fromDomain: string;
  class: ChartOfAccountFromToGroupClass;
  nature: ChartOfAccountFromToGroupNature;
}

const classType: ChartOfAccountFromToGroupClass[] = ["A", "P", "PL", "R"];
const natureType: ChartOfAccountFromToGroupNature[] = ["C", "D"];

interface CreateOrEditAccountProps<T extends boolean> extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  isCompany?: T;
  groupChartOfAccountsToFromId?: T extends false ? number : undefined;
  companyToken?: T extends true ? string : undefined;
  chartOfAccount?: ChartOfAccountsFromToGroup;
}

function CreateOrEditAccount<T extends boolean>({
  onClose,
  isCompany = false as T,
  groupChartOfAccountsToFromId,
  chartOfAccount,
  companyToken,
  ...props
}: CreateOrEditAccountProps<T>) {
  const { mutateAsync: mutateCreate } = useMutationAddAccountFromTo();
  const { mutateAsync: mutateCreateCompany } = useMutationAddCompanyAccountFromTo();

  const { mutateAsync: mutateUpdate } = useMutationUpdateFromTo();
  const { mutateAsync: mutateUpdateCompany } = useMutationUpdateCompanyFromTo();

  const defaultValues = useMemo<DefaultValues<Form>>(
    () => ({
      account: chartOfAccount?.account || "",
      class: chartOfAccount?.class || undefined,
      nature: chartOfAccount?.nature || undefined,
      description: chartOfAccount?.description || "",
      fromDomain: chartOfAccount?.fromDominio || "",
    }),
    [chartOfAccount],
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const handleClose = () => {
    if (isSubmitting) return;
    reset(defaultValues);
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    const basePayload = {
      account: data.account,
      class: data.class,
      description: data.description,
      fromDominio: data.fromDomain,
      nature: data.nature,
    };

    const runMutations = async () => {
      if (!chartOfAccount) {
        if (isCompany) {
          await mutateCreateCompany({
            ...basePayload,
            isAnalytic: false,
            companyToken: companyToken as string,
          });
        } else {
          await mutateCreate({
            ...basePayload,
            isAnalytic: false,
            groupChartOfAccountsToFromId: groupChartOfAccountsToFromId as number,
          });
        }
      } else {
        if (isCompany) {
          await mutateUpdateCompany({
            ...chartOfAccount,
            ...basePayload,
            companyToken: companyToken as string,
          });
        } else {
          await mutateUpdate({
            ...chartOfAccount,
            ...basePayload,
          });
        }
      }
    };

    await runMutations();
    handleClose();
  });

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Nova conta contábil
      </DialogTitleWithCloseButton>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          <Stack gap={2}>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  autoFocus
                  label="Descrição da conta"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  multiline
                  rows={3}
                />
              )}
            />

            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
              <Controller
                name="account"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Conta Analítica"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="fromDomain"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Conta Reduzida"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
              <Controller
                name="class"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl>
                    <InputLabel>Classificação</InputLabel>
                    <Select {...field} label="Classificação" value={field.value || ""}>
                      <MenuItem disabled value="">
                        Selecione:{" "}
                      </MenuItem>
                      {classType.map((classItem) => (
                        <MenuItem value={classItem} key={classItem}>
                          {classRepresentation[classItem]}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!fieldState.error?.message && (
                      <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                name="nature"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl>
                    <InputLabel>Natureza</InputLabel>
                    <Select {...field} label="Natureza" value={field.value || ""}>
                      <MenuItem disabled value="">
                        Selecione:{" "}
                      </MenuItem>
                      {natureType.map((natureItem) => (
                        <MenuItem value={natureItem} key={natureItem}>
                          {natureRepresentation[natureItem]}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!fieldState.error?.message && (
                      <FormHelperText error>{fieldState.error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton loading={isSubmitting} variant="contained" type="submit">
            Salvar
          </LoadingButton>
          <Button disabled={isSubmitting} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateOrEditAccount;
