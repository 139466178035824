import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getChats } from "services/Requests/mailbox";

const useQueryChat = (companyToken: string) => {
  const query = useQuery([queryKeys.chats, companyToken], () => getChats({ companyToken }));
  return query;
};

export default useQueryChat;
