import { FC, ReactNode } from "react";

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import DownloadIcon from "@mui/icons-material/Download";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { Transaction } from "types/Company";
import getInvoiceStatusColor from "pages/Home/utils/getInvoiceStatusColor";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { DateTime } from "luxon";
import { BASE_URL_DOWNLOAD_DOCUMENT } from "utils/constants";

interface DialogConsult extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  transaction: Transaction;
}

const DialogConsult: FC<DialogConsult> = ({ transaction, onClose, ...props }) => {
  const data: Record<string, ReactNode> = {
    "Tipo de lançamento": transaction.transactionType == "Credit" ? "Crédito" : "Débito",
    Status: (
      <Stack direction="row" gap={0.5}>
        {transaction.invoiceItems?.map((item) => (
          <Chip label={item.status} key={item.id} color={getInvoiceStatusColor(item.status)} />
        ))}
      </Stack>
    ),
    Mensagem: transaction.invoiceItems?.map((item) => item.error).join("\n"),
    "Nº da nota": transaction.invoiceItems?.map((item) => item.invoiceNumber).join("\n"),
    Arquivo: (
      <Stack gap={1}>
        {transaction.invoiceItems?.map((item) => (
          <Stack direction="row" key={item.id}>
            {!!item.path && (
              <Button
                variant="text"
                size="small"
                startIcon={<PictureAsPdfIcon fontSize="small" />}
                component="a"
                target="_blank"
                href={item.path}
              >
                PDF
              </Button>
            )}
            {!!item.pathXml && (
              <Button
                variant="text"
                size="small"
                startIcon={<SnippetFolderIcon fontSize="small" />}
                component="a"
                target="_blank"
                href={item.pathXml}
              >
                XML
              </Button>
            )}
          </Stack>
        ))}
      </Stack>
    ),
    "Serviço Prestado": transaction.invoiceItems?.map((item) => item.describeInvoice).join("\n"),
    "ISS (R$)": transaction.invoiceItems?.map((item) => item.iss).join("\n"),
    "IRPJ (R$)": transaction.invoiceItems?.map((item) => item.irpj).join("\n"),
    "INSS (R$)": transaction.invoiceItems?.map((item) => item.irop).join("\n"),
    "PIS (R$)": transaction.invoiceItems?.map((item) => item.pis).join("\n"),
    "CSLL (R$)": transaction.invoiceItems?.map((item) => item.csll).join("\n"),
    "COFINS (R$)": transaction.invoiceItems?.map((item) => item.cofins).join("\n"),
    "ISS Retido": transaction.invoiceItems
      ?.map((item) => (item.issWithHeld ? "Sim" : "Não"))
      .join("\n"),
    Valor: formatCurrencyBRL(transaction.value),
    "Emitida em": transaction.dateEmission
      ? DateTime.fromISO(transaction.dateEmission).toFormat("dd/MM/yyyy")
      : "",
    "Vence em": transaction.dueDate
      ? DateTime.fromISO(transaction.dueDate).toFormat("dd/MM/yyyy")
      : "",
    "Data de competência": transaction.referenceDate
      ? DateTime.fromISO(transaction.referenceDate).toFormat("dd/MM/yyyy")
      : "",
    [transaction.transactionType == "Credit" ? "Recebido" : "Pago" + "em"]: transaction.paymentDate
      ? DateTime.fromISO(transaction.paymentDate).toFormat("dd/MM/yyyy")
      : "",
    Categoria: transaction.categoryName ?? "-",
    [transaction.transactionType == "Credit" ? "Cliente" : "Fornecedor"]:
      transaction.participantName ?? "-",
    "Comprovante(s)": (
      <Stack gap={0.5}>
        {transaction.documents.map((document) => (
          <Button
            color="info"
            fullWidth
            target="_blank"
            href={`${BASE_URL_DOWNLOAD_DOCUMENT}?id=${document.downloadToken}&fullPath=/COMPROVANTES+DE+PAGAMENTO&preview=true`}
            variant="outlined"
            startIcon={<DownloadIcon />}
            key={document.downloadToken}
            sx={{ textTransform: "none" }}
          >
            {document.name}
          </Button>
        ))}
      </Stack>
    ),
    ID: transaction.id.toString(),
  };

  return (
    <Dialog {...props} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={onClose}>
        {transaction.description}
      </DialogTitleWithCloseButton>

      <DialogContent>
        {Object.entries(data).map(([title, content], index) => (
          <Box key={index} p={2} border={1} borderColor="divider">
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" fontWeight={600}>
                {title}
              </Typography>
              <Box alignSelf="end" justifySelf="end">
                {typeof content === "string" ? (
                  <Typography variant="body1" align="right">
                    {content}
                  </Typography>
                ) : (
                  content
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConsult;
