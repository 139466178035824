import { FC } from "react";
import { useAuth } from "context/Auth";

import { Typography } from "@mui/material";
import Head from "components/Head";

import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import Content from "./Content";

const Notification: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <>
      <Head title={`Notificações | ${getSubdomain()}`} />

      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Notificações
      </Typography>

      {!company ? (
        <Typography>{MESSAGE_NO_COMPANY}</Typography>
      ) : (
        <Content companyToken={company.token} />
      )}
    </>
  );
};

export default Notification;
