import { FC, useMemo } from "react";
import useQueryGetCompanyParticipant from "../../hooks/useQueryGetCompanyParticipant";

import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import AddressConsult from "../AddressConsult";
import BankConsult from "../BankConsult";
import ListComponent from "components/ListComponent";

const translateRoleParticipant: { [x: string]: string } = {
  Customer: "Cliente",
  Provider: "Fornecedor",
};

interface QueryParticipantProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  participantId: number;
  onClose: () => void;
  onEdit: () => void;
}

const QueryParticipant: FC<QueryParticipantProps> = ({
  companyToken,
  participantId,
  onClose,
  onEdit,
  ...props
}) => {
  const { data: companyParticipant, isLoading } = useQueryGetCompanyParticipant(
    companyToken,
    participantId,
  );

  const basicData = useMemo(() => {
    if (!companyParticipant) return null;
    const textForNoData = "N/D";

    return [
      {
        title: "Tipo",
        content: companyParticipant.roles
          .split(", ")
          .map((role) => translateRoleParticipant[role])
          .join(" / "),
      },
      {
        title: "CPF / CNPJ",
        content: companyParticipant.cpfcnpj === "--" ? textForNoData : companyParticipant.cpfcnpj,
      },
      {
        title: "Inscrição Municipal",
        content: companyParticipant.municipalEnrollment || textForNoData,
      },
      {
        title: "Inscrição Estadual",
        content: companyParticipant.stateRegistration || textForNoData,
      },
      {
        title: "Telefone",
        content: companyParticipant.phoneAsString || textForNoData,
      },
      {
        title: "Email",
        content: companyParticipant.email || textForNoData,
      },
    ];
  }, [companyParticipant]);

  return (
    <Dialog {...props} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{companyParticipant ? companyParticipant.name : "Consulta"}</DialogTitle>
      <DialogContent>
        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={500}>
            <CircularProgress />
          </Stack>
        )}

        {basicData && (
          <>
            <Typography variant="h6" fontWeight={400}>
              Dados Básicos
            </Typography>

            <Box component="ul" mt={1}>
              {basicData.map((data, index) => (
                <ListComponent key={index}>
                  <>
                    <Typography variant="body1" fontWeight={600}>
                      {data.title}
                    </Typography>
                    <Typography variant="body1">{data.content}</Typography>
                  </>
                </ListComponent>
              ))}
            </Box>
          </>
        )}

        {companyParticipant && (
          <>
            <AddressConsult
              address={{
                ...companyParticipant.address,
                countyAsString: companyParticipant.countyAsString,
              }}
            />

            <BankConsult
              bank={{
                ...companyParticipant.bankAccount,
                bankNameAsString: companyParticipant.bankNameAsString,
              }}
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          startIcon={<Edit />}
          onClick={() => {
            onEdit();
            onClose();
          }}
        >
          Editar
        </Button>
        <Button variant="contained" onClick={onClose} startIcon={<Close />}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QueryParticipant;
