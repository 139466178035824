import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { importToFrom } from "services/Requests/accountant";

const useMutationImportToFrom = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(importToFrom, {
    onSuccess(_, { companyToken }) {
      toast.success("Importado com sucesso!");
      queryClient.invalidateQueries([queryKeys.clientChartOfAccounts, companyToken]);
    },
  });
  return mutation;
};

export default useMutationImportToFrom;
