import { InvoiceConfigService } from "types/Company";
import { InvoiceConfiguration } from "types/Invoice";
import { DefaultValues } from "react-hook-form";
import { InvoiceServiceForm } from ".";

const citiesSpecialRules = [
  "Caldas Novas",
  "Canela",
  "Contagem",
  "Guarulhos",
  "Itaboraí",
  "Matão",
  "Mauá",
  "Olímpia",
  "Porto Seguro",
  "Rio de Janeiro",
  "Santo André",
  "São Bernardo do Campo",
  "São José dos Campos",
];

export const cityHasSpecialRule = (city: string) => citiesSpecialRules.includes(city);

export const specialMessage = (invoice: InvoiceConfiguration) => {
  if (
    invoice.optingSimple !== undefined &&
    invoice.optingSimple === 1 &&
    invoice.city === "São Paulo"
  ) {
    return true;
  }

  if (cityHasSpecialRule(invoice.city)) {
    return true;
  }

  return false;
};

export const createService = (
  service?: InvoiceConfigService,
  hasServiceList = false,
  isManual?: boolean,
): DefaultValues<InvoiceServiceForm> => {
  if (service) {
    isManual = service.isManual;
  } else {
    isManual = isManual && hasServiceList ? true : false;
  }

  return {
    descricao: service?.descricao ?? "",
    isManual,
    cnae: service?.cnae ?? "",
    codigo: service?.codigo ?? "",
    lc116: service?.lc116 ?? "",
    iss: service?.iss ?? 0,
    irpj: service?.irpj ?? 0,
    irop: service?.irop ?? 0,
    pis: service?.pis ?? 0,
    cofins: service?.cofins ?? 0,
    csll: service?.csll ?? 0,
    serviceId: service?.id,
  };
};
