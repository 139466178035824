import { FC, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useQueryGetGlobalCategoriesByGroup from "./hooks/useQueryGetGlobalCategoriesByGroup";

import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import Head from "components/Head";
import getSubdomain from "utils/getSubdomain";

import DialogCreateSyntheticCategory from "./components/DialogCreateOrUpdateSyntheticCategory";
import { FinancialCategoryIdProvider } from "./context/FinancialCategoryId";
import TemplateProvider from "./context/Template/TemplateProvider";
import FinancialCategories from "./FinancialCategories";

const FinancialItem: FC = () => {
  const params = useParams();
  const templateId = Number(params.id as string);
  const groupId = Number(params.groupId as string);
  const navigate = useNavigate();

  const { isLoading, isError, data } = useQueryGetGlobalCategoriesByGroup(templateId, true);

  const [dialogCreateCategoryIsOpen, setDialogCreateCategoryIsOpen] = useState(false);

  return (
    <>
      <Head title={`Financeiro | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h4" fontWeight="bold">
            Template
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Plano de Contas Financeiro
          </Typography>
        </Stack>

        <Button
          variant="contained"
          startIcon={<AddIcon fontSize="small" />}
          size="small"
          onClick={() => setDialogCreateCategoryIsOpen(true)}
        >
          Nova Categoria Sintética
        </Button>
        <Button
          variant="text"
          href="/financeiro"
          onClick={(e) => {
            e.preventDefault();
            navigate("/financeiro");
          }}
          size="small"
        >
          Lista de Templates
        </Button>
      </Stack>

      <Box mt={2} p={2} boxShadow={2} bgcolor="#fff">
        {isLoading && (
          <Stack height={450} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {isError && <Typography>Ocorreu um error ao buscar as categorias</Typography>}

        {data && (
          <FinancialCategoryIdProvider groupId={groupId} templateId={templateId}>
            <TemplateProvider data={data}>
              <FinancialCategories />
            </TemplateProvider>
          </FinancialCategoryIdProvider>
        )}
      </Box>

      <DialogCreateSyntheticCategory
        templateId={templateId}
        open={dialogCreateCategoryIsOpen}
        onClose={() => setDialogCreateCategoryIsOpen(false)}
      />
    </>
  );
};

export default FinancialItem;
