import { forwardRef, Ref } from "react";

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { FinancialCategory } from "types/Company";

interface CategorySelectInnerProps extends SelectProps<number | string | null> {
  categories: FinancialCategory[];
  FormControlProps?: FormControlProps;
  helperText?: string;
}

function CategorySelectInner(
  { categories, helperText, FormControlProps, ...props }: CategorySelectInnerProps,
  ref: Ref<unknown>,
) {
  return (
    <FormControl {...FormControlProps} size={props.size} error={props.error}>
      <InputLabel>{props.label}</InputLabel>
      <Select {...props} ref={ref} value={props.value ?? ""}>
        <MenuItem disabled value="">
          Selecione:{" "}
        </MenuItem>

        {categories.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const CategorySelect = forwardRef(CategorySelectInner);
export default CategorySelect;
