import { FC, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import AccoutInformation from "./AccoutInformation";
import BankFields from "./BankFields";
import AdditionalFields from "./AdditionalFields";
import { Bank } from "types/CommonData";
import { DateTime } from "luxon";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import useMutationCreateCompanyBankAccount from "../hooks/useMutationCreateCompanyBankAccount";
import { CreateCompanyBankAccountPayload } from "services/Requests/companies";
import { formattedInput } from "components/CurrencyInput";
import { CompanyBankAccounts } from "types/Company";
import useMutationUpdateCompanyBankAccount from "../hooks/useMutationUpdateCompanyBankAccount";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

export interface FormCreateAccount {
  accountType: "1" | "2";
  accountNick: string;
  balanceType: "1" | "2";
  accountBank?: Bank | null | number;
  accountBranch?: string;
  accountNumber?: string;
  accountBalance: string;
  accountDate: Date;
  accountDescription: string;
}

interface DialogCreateAccountProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  bankAccount?: CompanyBankAccounts;
}

const DialogCreateAccount: FC<DialogCreateAccountProps> = ({
  onClose,
  companyToken,
  bankAccount,
  ...props
}) => {
  const { mutateAsync: mutateCreate, isLoading: isLoadingCreate } =
    useMutationCreateCompanyBankAccount();
  const { mutateAsync: mutateUpdate, isLoading: isLoadingUpdate } =
    useMutationUpdateCompanyBankAccount();
  const isLoading = isLoadingCreate || isLoadingUpdate;

  const initialFields = useMemo<FormCreateAccount>(
    () => ({
      accountType: (bankAccount?.bankAccountType?.toString() as "1" | "2") || "1",
      accountNick: bankAccount?.alias || "",
      balanceType: (bankAccount?.initialBalanceType?.toString() as "1" | "2") || "1",
      accountBank: bankAccount?.bankNumber || null,
      accountBranch: bankAccount?.agency || "",
      accountNumber: bankAccount?.account || "",
      accountBalance: bankAccount
        ? formatCurrencyBRL(Math.abs(Number(bankAccount.initialBalance.replace(/,/g, "."))))
        : "",
      accountDate: bankAccount
        ? new Date(bankAccount.dateInitialBalance)
        : DateTime.now().toJSDate(),
      accountDescription: bankAccount?.description || "",
    }),
    [],
  );

  const { control, handleSubmit, watch, reset } = useForm<FormCreateAccount>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFields,
  });

  const handleClose = () => {
    if (isLoading) return;
    reset(initialFields);
    onClose();
  };

  const withBankAccount = watch("accountType") === "1";

  const onSubmit = handleSubmit(async (data) => {
    let dataForCreateAccount: CreateCompanyBankAccountPayload = {
      alias: data.accountNick,
      bankAccountType: data.accountType,
      companyToken,
      dateInitialBalance: data.accountDate.toISOString(),
      initialBalance: formattedInput(data.accountBalance),
      initialBalanceType: data.balanceType,
      description: data.accountDescription,
    };

    if (withBankAccount && data.accountBank) {
      dataForCreateAccount = {
        ...dataForCreateAccount,
        account: data.accountNumber,
        agency: data.accountBranch,
        bankNumber:
          typeof data.accountBank === "number"
            ? data.accountBank.toString()
            : data.accountBank.id.toString(),
      };
    }

    if (bankAccount) {
      await mutateUpdate({ ...dataForCreateAccount, token: bankAccount.downloadToken });
    } else {
      await mutateCreate(dataForCreateAccount);
    }
    handleClose();
  });

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Cadastrar nova Conta
      </DialogTitleWithCloseButton>

      <form onSubmit={onSubmit}>
        <DialogContent>
          <Controller
            name="accountType"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <RadioGroup sx={{ justifyContent: "space-between" }} {...field} row>
                  <FormControlLabel value="1" control={<Radio />} label="Conta Bancária" />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Outra conta não-bancária"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />

          <Stack gap={2} mt={2}>
            <AccoutInformation control={control} />
            <Collapse in={withBankAccount}>
              {withBankAccount && <BankFields control={control} />}
            </Collapse>
            <AdditionalFields control={control} />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton variant="contained" loading={isLoading} type="submit">
            Salvar
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreateAccount;
