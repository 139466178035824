import { FC, useState, ChangeEvent } from "react";
import useMutationReplyTicket from "pages/Home/hooks/useMutationReplyTicket";
import useMutationUploadFiles from "pages/Home/pages/Comunication/hooks/useMutationUploadFiles";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LoadingButton } from "@mui/lab";

import { DateTime } from "luxon";
import { Chat } from "types/Mailbox";
import Comments from "./Comments";

interface StatusInformationProps {
  title: string;
  content: string;
  flex?: number;
}

const StatusInformation = ({ title, content, flex = 1 }: StatusInformationProps) => (
  <Box flex={flex}>
    <Typography variant="body2" fontWeight={500}>
      {title}
    </Typography>
    <Typography variant="body1" color="text.secondary">
      {content}
    </Typography>
  </Box>
);

interface ContentProps {
  ticket: Chat;
  companyToken: string;
  onClose: () => void;
}

const Content: FC<ContentProps> = ({ onClose, companyToken, ticket }) => {
  const { mutateAsync: mutationReply, isLoading: loadingReply } = useMutationReplyTicket();
  const { mutateAsync: mutationUpload, isLoading: loadingUpload } = useMutationUploadFiles();
  const isLoading = loadingReply || loadingUpload;

  const [files, setFiles] = useState<File[]>([]);
  const [message, setMessage] = useState("");

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = e.target?.files;

    if (!files) return;
    setFiles(Array.from(files));
  };

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleReply = async () => {
    if (!message.trim()) return;

    const { id } = await mutationReply({
      message,
      companyToken,
      ticketId: ticket.id,
    });
    setMessage("");

    if (files.length === 0) return;
    await mutationUpload({
      companyToken,
      files,
      messageId: id,
    });
    setFiles([]);
  };

  return (
    <>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" fontWeight={500}>
            {ticket.subject}
          </Typography>
          <Typography variant="h6" color="text.secondary" fontWeight={400}>
            #{ticket.id.toString().padStart(7, "0")}
          </Typography>
        </Stack>
      </DialogTitle>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (isLoading) return;
          if (ticket.isClosed) return;
          handleReply();
        }}
      >
        <DialogContent>
          <Stack direction="row" gap={2} borderBottom={1} borderColor="divider" pb={2} mb={2}>
            <StatusInformation
              title="Situação"
              content={ticket.isClosed ? "Fechado" : "Aberto"}
              flex={0}
            />
            <StatusInformation
              title="Criado em"
              content={DateTime.fromISO(ticket.created).toFormat("dd/MM/yyyy HH:mm")}
              flex={1}
            />
            <StatusInformation
              title="Atualizada em"
              content={DateTime.fromISO(ticket.updated).toFormat("dd/MM/yyyy HH:mm")}
              flex={1}
            />
            <StatusInformation title="Grupo ou Responsável" content={ticket.responsable} flex={3} />
          </Stack>

          <Comments comments={ticket.messages} />

          {!ticket.isClosed && (
            <Box mt={2}>
              <TextField
                multiline
                rows={2}
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                inputProps={{ autoComplete: "off" }}
                placeholder="Enviar resposta"
                variant="filled"
                disabled={isLoading}
              />

              <Box mt={1}>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <input
                    multiple
                    disabled={isLoading}
                    type="file"
                    style={{
                      display: "none",
                    }}
                    onChange={handleChangeFile}
                  />
                  <Button
                    disabled={isLoading}
                    variant="contained"
                    component="span"
                    size="small"
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Anexar arquivo
                  </Button>
                </label>

                {!!files.length && (
                  <Box mt={1}>
                    {files.map((file, index) => (
                      <Stack direction="row" gap={0.5} alignItems="center" key={file.name}>
                        <Typography variant="body1">{file.name}</Typography>

                        <IconButton
                          disabled={isLoading}
                          size="small"
                          onClick={() => setFiles((prev) => prev.filter((_, i) => i !== index))}
                        >
                          <CloseRoundedIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          {!ticket.isClosed && (
            <LoadingButton loading={isLoading} variant="contained" type="submit">
              Enviar
            </LoadingButton>
          )}
          <Button
            onClick={handleClose}
            variant={!ticket.isClosed ? "outlined" : "contained"}
            disabled={isLoading}
          >
            Fechar
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default Content;
