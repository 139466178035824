import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getChartOfAccountsFromToGroup } from "services/Requests/accountant";

const useQueryGetChartOfAccountsFromToGroup = (groupId: number, term?: string) => {
  const queryKey = [queryKeys.chartOfAccountsFromToGroup, groupId];
  if (term?.trim()) queryKey.push(term);

  const query = useQuery(queryKey, () => getChartOfAccountsFromToGroup({ groupId, term }), {
    keepPreviousData: true,
  });
  return query;
};

export default useQueryGetChartOfAccountsFromToGroup;
