import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getFinancialSummary } from "services/Requests/companies";

const useQueryGetFinancialSummary = (companyToken: string, year: number) => {
  const query = useQuery([queryKeys.financialSummary, year], () =>
    getFinancialSummary({ CompanyToken: companyToken, Year: year }),
  );
  return query;
};

export default useQueryGetFinancialSummary;
