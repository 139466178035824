import { FC } from "react";

import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Search from "@mui/icons-material/Search";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { CompanyParticipants } from "types/Company";

interface ClientCardItemProps {
  client: CompanyParticipants;
  onQuery: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const ClientCardItem: FC<ClientCardItemProps> = ({ client, onDelete, onEdit, onQuery }) => {
  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h6" fontWeight={600} gutterBottom>
          {client.name}
        </Typography>
        <Typography color="text.secondary">CPF/CNPJ: {client.cpfcnpj || "-"}</Typography>
        {!!client.email && <Typography color="text.secondary">{client.email}</Typography>}
        {!!client.phoneAsString && (
          <Typography color="text.secondary">{client.phoneAsString}</Typography>
        )}
      </CardContent>
      <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"} p={1}>
        <Button
          variant="contained"
          onClick={onQuery}
          startIcon={<Search />}
          sx={{ flex: 1, flexBasis: "140px" }}
        >
          Consultar
        </Button>
        <Button
          variant="contained"
          onClick={onEdit}
          startIcon={<Edit />}
          sx={{ flex: 1, flexBasis: "140px" }}
        >
          Editar
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={onDelete}
          startIcon={<Delete />}
          sx={{ flex: 1, flexBasis: "140px" }}
        >
          Excluir
        </Button>
      </Stack>
    </Card>
  );
};

export default ClientCardItem;
