import { FC, useState, ChangeEvent, useEffect } from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import { DateTime } from "luxon";
import { Lead, Leads } from "types/Marketplace";
import { useAuth } from "context/Auth";
import DialogContact from "./components/DialogContact";

interface LeadsTableProps {
  leads: Leads;
}

const LeadsTable: FC<LeadsTableProps> = ({ leads }) => {
  const {
    user: { userInfo },
  } = useAuth();
  const isAdmin = userInfo.userType === "Admin";
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [leadToAddObs, setLeadToAddObs] = useState<null | Lead>(null);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (Math.ceil(leads.length / rowsPerPage) > page ? 0 : page) {
      setPage(0);
    }
  }, [leads, page, rowsPerPage]);

  return (
    <>
      <TableContainer sx={{ maxHeight: 450 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Nome</TableCell>
              {isAdmin && (
                <>
                  <TableCell>Email</TableCell>
                  <TableCell>Telefone</TableCell>
                  <TableCell>Formulário</TableCell>
                </>
              )}
              <TableCell>Campos</TableCell>
              {!isAdmin && <TableCell></TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {leads.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Nenhum resultado encontrado
                </TableCell>
              </TableRow>
            )}

            {leads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((lead) => (
              <TableRow hover key={lead.id}>
                <TableCell>{DateTime.fromISO(lead.createAt).toFormat("dd/MM/yyyy")}</TableCell>
                <TableCell sx={{ textTransform: "uppercase" }}>{lead.name}</TableCell>
                {isAdmin && (
                  <>
                    <TableCell>{lead.email}</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>{lead.phone}</TableCell>
                    <TableCell>{lead.identify}</TableCell>
                  </>
                )}
                <TableCell>
                  {Object.entries(lead.customFields).map(([field, result], index) => (
                    <Typography key={index}>
                      <Typography component="strong" fontWeight={600}>
                        {field}
                      </Typography>{" "}
                      {result}
                    </Typography>
                  ))}
                </TableCell>

                {!isAdmin && (
                  <TableCell>
                    <Button variant="contained" size="small" onClick={() => setLeadToAddObs(lead)}>
                      Contato
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!!leadToAddObs && (
        <DialogContact
          open={!!leadToAddObs}
          onClose={() => setLeadToAddObs(null)}
          lead={leadToAddObs}
        />
      )}

      <TablePagination
        labelRowsPerPage={"Linhas por páginas: "}
        labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={leads.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default LeadsTable;
