import { FC } from "react";

import { Chip, ChipProps, TableCell, TableRow } from "@mui/material";

import { DateTime } from "luxon";
import { TransactionsItem } from "types/Company";
import { ISODate } from "types/SemanticTypes";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface BillsToReceiveTableItemProps {
  transactionItem: TransactionsItem;
}

const BillsToReceiveTableItem: FC<BillsToReceiveTableItemProps> = ({ transactionItem }) => {
  const formatDate = (ISODate: ISODate) => {
    return DateTime.fromISO(ISODate).toFormat("dd/MM/yyyy");
  };

  const getLabelStatus = () => {
    const dateNow = DateTime.local().toISO();

    if (transactionItem.paymentDate) {
      return transactionItem.transactionType === "Credit" ? "Recebido" : "Pago";
    }

    if (dateNow > transactionItem.dueDate) return "Atrasado";

    return transactionItem.transactionType === "Credit" ? "A receber" : "A pagar";
  };

  const getStatusColor = (): ChipProps["color"] => {
    const dateNow = DateTime.local().toISO();

    if (transactionItem.paymentDate) return "success";
    if (dateNow > transactionItem.dueDate) return "warning";
    return "info";
  };

  return (
    <TableRow hover>
      <TableCell>{transactionItem.dueDate ? formatDate(transactionItem.dueDate) : "-"}</TableCell>
      <TableCell>
        {transactionItem.referenceDate ? formatDate(transactionItem.referenceDate) : "-"}
      </TableCell>
      <TableCell>{transactionItem.participantName}</TableCell>
      <TableCell>{transactionItem.description}</TableCell>
      <TableCell>{transactionItem.categoryName}</TableCell>
      <TableCell>
        <Chip size="small" label={getLabelStatus()} color={getStatusColor()} />
      </TableCell>
      <TableCell align="right" data-a-h="right">
        {formatCurrencyBRL(transactionItem.value)}
      </TableCell>
    </TableRow>
  );
};

export default BillsToReceiveTableItem;
