import { FC, FormEvent, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useMutationImportOFX from "../hooks/useMutationImportOFX";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";

import BankAccountSelect from "pages/Home/components/BankAccountSelect";

import formatBytes from "utils/formatBytes";
import validationSchema from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { ImportOFXResponse } from "types/Company";

interface ImportForm {
  ofxFile: FileList;
  bankId: number;
}

interface ImportFormProps {
  companyToken: string;
  onImport: (importResult: ImportOFXResponse) => void;
  hasImport: boolean;
  onRequestImport?: () => void;
}

const ImportForm: FC<ImportFormProps> = ({
  companyToken,
  onImport,
  hasImport,
  onRequestImport,
}) => {
  const [confirmReimport, setConfirmReimport] = useState(false);
  const [hasAlert, setHasAlert] = useState<number | null>(null);
  const { mutateAsync } = useMutationImportOFX();

  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<ImportForm>({
    resolver: yupResolver(validationSchema),
  });
  const fileList = watch("ofxFile");
  const file = fileList && fileList.length !== 0 ? fileList[0] : undefined;

  const onSubmit = handleSubmit(async (data) => {
    if (hasImport && !confirmReimport) {
      setConfirmReimport(true);
      return;
    } else {
      setConfirmReimport(false);
    }

    const file = data.ofxFile[0];
    if (!file) return;

    onRequestImport?.();
    const importResult = await mutateAsync({
      bankId: data.bankId,
      companyToken,
      file,
    });
    onImport(importResult);
    setHasAlert(importResult.hasAlert);
  });

  return (
    <>
      <Stack
        component="form"
        direction="row"
        gap={2}
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <Box>
          <label style={{ width: "fit-content" }}>
            <input
              accept=".ofx"
              type="file"
              style={{
                display: "none",
              }}
              {...register("ofxFile")}
              name="ofxFile"
            />
            <Button
              variant="contained"
              component="span"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              Anexar arquivo ofx
            </Button>
            {file && (
              <Typography sx={{ p: 0.5 }} variant="body2">
                {file.name} ({formatBytes(file.size)})
              </Typography>
            )}
          </label>
          {!!errors.ofxFile?.message && (
            <FormHelperText error>{errors.ofxFile?.message}</FormHelperText>
          )}
        </Box>

        <Controller
          control={control}
          name="bankId"
          render={({ fieldState, field }) => (
            <BankAccountSelect
              size="small"
              label="Conta para importação"
              companyToken={companyToken}
              {...field}
              sx={{ minWidth: 220 }}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />

        <LoadingButton
          loading={isSubmitting}
          type="submit"
          variant="contained"
          sx={{ height: "fit-content", ml: 1 }}
        >
          Importar
        </LoadingButton>
      </Stack>

      <Dialog
        open={confirmReimport}
        onClose={() => setConfirmReimport(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Reimportar extrato</DialogTitle>
        <DialogContent>
          Você não terminou de importar seus lançamentos, deseja continuar e fazer uma nova
          importação?
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => onSubmit()}>
            Sim
          </Button>
          <Button variant="outlined" onClick={() => setConfirmReimport(false)}>
            Não
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!hasAlert} onClose={() => setHasAlert(null)} maxWidth="sm" fullWidth>
        <DialogTitle color="info.main" sx={{ display: "flex", alignItems: "center" }}>
          <InfoIcon sx={{ mr: 0.5 }} /> Importação de lançamentos
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" color="info.main">
            Foram identificados{" "}
            <Typography color="inherit" variant="body1" component="strong" fontWeight={600}>
              {hasAlert} lançamentos
            </Typography>{" "}
            na importação, que podem ser preenchidos automaticamente de acordo com os lançamentos
            anteriores. Os mesmos se encontram destacados em amarelo.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => setHasAlert(null)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportForm;
