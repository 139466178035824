import { FC, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, MenuItem, TableCell, TableRow } from "@mui/material";

import { ChartOfAccountsFromToGroup } from "types/Accountant";
import DropdownMenu from "components/DropdownMenu";
import CreateOrEditAccount from "pages/Home/pages/AccountingItem/components/CreateOrEditAccount";
import DialogDeleteAccount from "pages/Home/pages/AccountingItem/components/DialogDeleteAccount";

interface AccountingItemProps {
  account: ChartOfAccountsFromToGroup;
  companyToken: string;
}

const AccountingItem: FC<AccountingItemProps> = ({ account, companyToken }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  return (
    <>
      <TableRow hover>
        <TableCell>{account.isAnalytic ? "Analítica" : "Sintética"}</TableCell>
        <TableCell>{account.account}</TableCell>
        <TableCell>{account.fromDominio}</TableCell>
        <TableCell>{account.description}</TableCell>
        <TableCell align="right">
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MenuIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        open={isOpen}
      >
        <MenuItem onClick={() => setIsEdit(true)}>Editar</MenuItem>
        <MenuItem onClick={() => setIsDelete(true)}>Excluir</MenuItem>
      </DropdownMenu>

      {isEdit && (
        <CreateOrEditAccount
          open={isEdit}
          onClose={() => setIsEdit(false)}
          isCompany
          companyToken={companyToken}
          chartOfAccount={account}
        />
      )}

      {isDelete && (
        <DialogDeleteAccount
          isCompany
          open={isDelete}
          onClose={() => setIsDelete(false)}
          chartOfAccount={account}
        />
      )}
    </>
  );
};

export default AccountingItem;
