import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { assignAccountant } from "services/Requests/admin";

const useMutationAssignAccountant = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(assignAccountant, {
    onSuccess() {
      toast.success("Associado com sucesso!");
      queryClient.invalidateQueries(queryKeys.ordersByAssignments, { exact: false });
      queryClient.invalidateQueries(queryKeys.companyAssignments, { exact: false });
    },
  });
  return mutation;
};

export default useMutationAssignAccountant;
