import { FC } from "react";
import { useDashboardPeriod } from "../contexts/DashboardPeriod";
import useQueryGetSummary from "../hooks/useQueryGetSummary";

import { CircularProgress, Box } from "@mui/material";
import CardFinancial from "./TableItemSummary";

interface FinancialSummaryProps {
  companyToken: string;
}

const FinancialSummary: FC<FinancialSummaryProps> = ({ companyToken }) => {
  const { date } = useDashboardPeriod();
  const { data: summary, isLoading } = useQueryGetSummary(companyToken, date.start, date.end);

  if (isLoading) return <CircularProgress />;

  if (!summary) return null;

  return (
    <Box gap={2} display="grid" gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))">
      <CardFinancial title="Saldo das Contas" value={summary.initialBalance} color="info" />
      <CardFinancial title="Contas a Pagar" value={summary.projectedExpenses} color="error" />
      <CardFinancial title="Contas a Receber" value={summary.projectedRevenues} color="success" />

      {/* mock data */}
      <CardFinancial title="Contas Pagas" value={summary.totalExpenses} color="error" />
      <CardFinancial title="Contas Recebidas" value={summary.totalRevenues} color="success" />
    </Box>
  );
};

export default FinancialSummary;
