import { FC } from "react";
import { Control, Controller, useWatch } from "react-hook-form";

import { Box, TextField } from "@mui/material";

import CitySelect from "pages/Home/components/CitySelect";
import StateSelect from "pages/Home/components/StateSelect";

import MaskedInput from "react-text-mask";
import { cnpjMask, cpfMask } from "utils/masks";
import { MovementForm } from "../utils/InterfacesForm";

interface CarrierProps {
  control: Control<MovementForm>;
  isConsultation: boolean;
}

const Carrier: FC<CarrierProps> = ({ control, isConsultation }) => {
  const carrierState = useWatch({
    control,
    name: "shipping.carrierState",
  });

  const maskBuilderCPFCNPJ = (v: string) => {
    return v.length <= 14 ? [...cpfMask, /\d/g] : cnpjMask;
  };

  return (
    <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" alignItems="center" gap={2} mt={2}>
      <Controller
        name={"shipping.carrierCpfCnpj"}
        control={control}
        render={({ fieldState, field }) => (
          <MaskedInput
            mask={maskBuilderCPFCNPJ(field.value || "")}
            guide={false}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            render={(ref, props) => (
              <TextField
                fullWidth
                label="CPF / CNPJ"
                inputRef={ref}
                {...props}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                disabled={isConsultation}
              />
            )}
          />
        )}
      />

      <Controller
        control={control}
        name={"shipping.carrierName"}
        render={({ field, fieldState }) => (
          <TextField
            label="Nome"
            {...field}
            sx={{ gridColumn: "span 3" }}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            disabled={isConsultation}
          />
        )}
      />
      <Controller
        control={control}
        name={"shipping.carrierStateRegistration"}
        render={({ field, fieldState }) => (
          <TextField
            label="Inscrição Estadual"
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            disabled={isConsultation}
          />
        )}
      />
      <Controller
        control={control}
        name={"shipping.carrierAddress"}
        render={({ field, fieldState }) => (
          <TextField
            label="Endereço"
            {...field}
            sx={{ gridColumn: "span 3" }}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            disabled={isConsultation}
          />
        )}
      />

      <Controller
        control={control}
        name="shipping.carrierState"
        render={({ field, fieldState }) => (
          <StateSelect
            {...field}
            label="Estado"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            FormControlProps={{ sx: { gridColumn: "span 2" } }}
            disabled={isConsultation}
          />
        )}
      />
      <Controller
        control={control}
        name="shipping.carrierCity"
        render={({ field, fieldState }) => (
          <CitySelect
            {...field}
            label="Cidade"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            stateId={carrierState}
            byCityName
            FormControlProps={{ sx: { gridColumn: "span 2" } }}
            disabled={isConsultation}
          />
        )}
      />
    </Box>
  );
};

export default Carrier;
