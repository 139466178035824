import { Dispatch, FC, SetStateAction, useState } from "react";
import useMutationChangeDocumentStatus from "../../../hooks/useMutationChangeDocumentStatus";
import { useClientInformation } from "../../../context/ClientInformation";

import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Download from "@mui/icons-material/Download";

import { BillingInfoAdmin } from "types/Billing";
import stringToSlug from "utils/stringToSlug";
import { BASE_URL_DOWNLOAD_DOCUMENT_DRIVE } from "utils/constants";
import { ChangeDocumentStatusPayload } from "services/Requests/admin";

type StatusDocument = ChangeDocumentStatusPayload["status"];

const getHrefDownloadDocument = (token: string | null) =>
  token ? `${BASE_URL_DOWNLOAD_DOCUMENT_DRIVE}?id=${token}&fullPath=/&preview=true` : undefined;

const statusDocumentParsing: { [x: string]: StatusDocument } = {
  [stringToSlug("Em aprovação")]: "Em aprovação",
  [stringToSlug("Aprovado")]: "Aprovado",
  [stringToSlug("Reenviar")]: "Reenviar",
  [stringToSlug("Não anexado")]: "Não anexado",
};

interface CopyOfDocumentsProps {
  billingInfo: BillingInfoAdmin;
}

const CopyOfDocuments: FC<CopyOfDocumentsProps> = ({ billingInfo }) => {
  const { isLoading, mutateAsync } = useMutationChangeDocumentStatus();
  const { payerCompanyId, token } = useClientInformation();

  const [CSDocument, setCSDocument] = useState(
    billingInfo.doc_status_cs
      ? stringToSlug(billingInfo.doc_status_cs)
      : stringToSlug("Não anexado"),
  );
  const [ISDocument, setISDocument] = useState(
    billingInfo.doc_status_is
      ? stringToSlug(billingInfo.doc_status_is)
      : stringToSlug("Não anexado"),
  );
  const [EFDocument, setEFDocument] = useState(
    billingInfo.doc_status_ef
      ? stringToSlug(billingInfo.doc_status_ef)
      : stringToSlug("Não anexado"),
  );

  const changeDocumentStatus = async (
    status: StatusDocument,
    type: ChangeDocumentStatusPayload["type"],
  ) => {
    return await mutateAsync({
      companyToken: token,
      payerCompanyId,
      status,
      type,
    });
  };

  const onChangeStatus = async (
    e: SelectChangeEvent<string>,
    setState: Dispatch<SetStateAction<string>>,
    type: ChangeDocumentStatusPayload["type"],
  ) => {
    const slugStatus = e.target.value;
    const status = statusDocumentParsing[slugStatus];

    try {
      await changeDocumentStatus(status, type);
      setState(slugStatus);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box flex={1} flexBasis="320px">
      <Box p={0.5} bgcolor={grey[200]}>
        <Typography variant="body1" align="center" fontWeight={600}>
          Cópia de documento
        </Typography>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        mt={1}
        position="relative"
      >
        {isLoading && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
              background: "#ffffff99",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Stack>
        )}

        <Typography variant="body1" fontWeight={600}>
          Contrato Social
        </Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <Select
            value={CSDocument}
            onChange={(e) => onChangeStatus(e, setCSDocument, "CS")}
            size="small"
            fullWidth
          >
            <MenuItem value={""} disabled>
              Selecione:
            </MenuItem>
            <MenuItem value={stringToSlug("Não anexado")}>Não anexado</MenuItem>
            <MenuItem value={stringToSlug("Em aprovação")}>Em aprovação</MenuItem>
            <MenuItem value={stringToSlug("Aprovado")}>Aprovado</MenuItem>
            <MenuItem value={stringToSlug("Reenviar")}>Reenviar</MenuItem>
          </Select>
          <IconButton
            size="small"
            disabled={!billingInfo.doc_status_cs_token}
            component="a"
            href={getHrefDownloadDocument(billingInfo.doc_status_cs_token)}
            target="_blank"
          >
            <Download fontSize="small" />
          </IconButton>
        </Stack>

        <Divider sx={{ my: 1, gridColumn: "span 2" }} />

        <Typography variant="body1" fontWeight={600}>
          Identidade dos Sócios
        </Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <Select
            value={ISDocument}
            onChange={(e) => onChangeStatus(e, setISDocument, "IS")}
            size="small"
            fullWidth
          >
            <MenuItem value="" disabled>
              Selecione:
            </MenuItem>
            <MenuItem value={stringToSlug("Não anexado")}>Não anexado</MenuItem>
            <MenuItem value={stringToSlug("Em aprovação")}>Em aprovação</MenuItem>
            <MenuItem value={stringToSlug("Aprovado")}>Aprovado</MenuItem>
            <MenuItem value={stringToSlug("Reenviar")}>Reenviar</MenuItem>
          </Select>
          <IconButton
            size="small"
            disabled={!billingInfo.doc_status_is_token}
            component="a"
            href={getHrefDownloadDocument(billingInfo.doc_status_is_token)}
            target="_blank"
          >
            <Download fontSize="small" />
          </IconButton>
        </Stack>

        <Divider sx={{ my: 1, gridColumn: "span 2" }} />

        <Typography variant="body1" fontWeight={600}>
          Endereço Fiscal
        </Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <Select
            value={EFDocument}
            onChange={(e) => onChangeStatus(e, setEFDocument, "EF")}
            size="small"
            fullWidth
          >
            <MenuItem value="" disabled>
              Selecione:
            </MenuItem>
            <MenuItem value={stringToSlug("Não anexado")}>Não anexado</MenuItem>
            <MenuItem value={stringToSlug("Em aprovação")}>Em aprovação</MenuItem>
            <MenuItem value={stringToSlug("Aprovado")}>Aprovado</MenuItem>
            <MenuItem value={stringToSlug("Reenviar")}>Reenviar</MenuItem>
          </Select>
          <IconButton
            size="small"
            disabled={!billingInfo.doc_status_ef_token}
            component="a"
            href={getHrefDownloadDocument(billingInfo.doc_status_ef_token)}
            target="_blank"
          >
            <Download fontSize="small" />
          </IconButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default CopyOfDocuments;
