import { FC, useEffect, useState } from "react";
import useQueryMovements from "../hooks/useQueryMovements";

import {
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import WithCompany from "components/WithCompany";
import SearchInput from "components/SearchInput";
import FilterPeriod from "components/FilterPeriod";
import MovementTableItem from "./MovementTableItem";

import { MovementOperationType } from "types/StockControl";
import { DateTime } from "luxon";

interface MovementsProps {
  companyToken: string;
}

const Movements: FC<MovementsProps> = ({ companyToken }) => {
  const [date, setDate] = useState({
    start: DateTime.local().startOf("month").toISO(),
    end: DateTime.local().endOf("month").toISO(),
  });
  const [operationType, setOperationType] = useState<MovementOperationType | null>(null);
  const [search, setSearch] = useState("");

  const {
    data: movements,
    isError,
    isLoading,
  } = useQueryMovements(companyToken, date.start, date.end, operationType, search);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!movements) return;

    if (Math.ceil(movements.length / rowsPerPage) > page ? 0 : page) {
      setPage(0);
    }
  }, [movements, page, rowsPerPage]);

  return (
    <>
      <Stack direction="row" gap={2} alignItems="center">
        <FilterPeriod
          startDate={date.start}
          endDate={date.end}
          onChangeDates={(newDates) => setDate(newDates)}
          label="Período"
          size="medium"
          disabled={isError || isLoading}
        />

        <FormControl disabled={isError || isLoading}>
          <InputLabel>Operação</InputLabel>
          <Select
            disabled={isError || isLoading}
            value={operationType || "all"}
            onChange={(e) =>
              setOperationType(
                e.target.value === "all" ? null : (e.target.value as MovementOperationType),
              )
            }
            label="Operação"
          >
            <MenuItem value="all">Todos</MenuItem>
            <MenuItem value="Entrada">Entrada</MenuItem>
            <MenuItem value="Saída">Saída</MenuItem>
          </Select>
        </FormControl>

        <SearchInput value={search} onChange={(newSearch) => setSearch(newSearch)} />
      </Stack>

      <Divider sx={{ my: 2 }} />

      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {isError && <Typography>Ocorreu um error ao tentar pegar suas movimentações</Typography>}

      {movements && !isLoading && !isError && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Data</TableCell>
                  <TableCell>Id</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell>Produto</TableCell>
                  <TableCell>Cliente/Fornecedor</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="right">Valor unitário (R$)</TableCell>
                  <TableCell align="right">Valor total (R$)</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {movements.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={11}>Nenhum resultado</TableCell>
                  </TableRow>
                )}

                {movements
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((movement) => (
                    <MovementTableItem
                      key={movement.id}
                      movement={movement}
                      companyToken={companyToken}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            labelRowsPerPage={"Linhas por páginas: "}
            labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={movements.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default WithCompany(Movements);
