import { FC } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { Viewer } from "@react-pdf-viewer/core";
import {
  getUrlDownloadDrive,
  getUrlPreviewDocumentDrive,
} from "pages/Home/pages/Drive/utils/getUrl";

interface RequiredFields {
  iAgree: boolean;
}

interface ContractTermsProps {
  downloadToken: string;
  onBack: () => void;
  onNext?: () => void;
}

const ContractTerms: FC<ContractTermsProps> = ({ downloadToken, onBack, onNext }) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<RequiredFields>();
  const iAgree = useWatch({
    control,
    name: "iAgree",
  });

  const ErrorDocument = () => (
    <Typography variant="body1" color="error" align="center">
      Não foi possível carregar o documento.
    </Typography>
  );

  return (
    <Box>
      <Typography variant="body1">
        Leia atentamente o nosso Contrato de Prestação de Serviços e aceite os termos para
        prosseguir.
      </Typography>

      <Box
        mt={0.5}
        mb={2}
        maxHeight={500}
        sx={{ border: 1, borderColor: "divider", overflow: "auto", py: 2 }}
      >
        <Viewer
          fileUrl={getUrlPreviewDocumentDrive(downloadToken, "/Contratos+Servicos")}
          renderError={ErrorDocument}
        />
      </Box>

      <Stack
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        sx={{
          flexDirection: {
            md: "row",
            sm: "column",
          },
        }}
      >
        <Link
          target="_blank"
          href={getUrlDownloadDrive(downloadToken, "/Contratos+Servicos")}
          sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
        >
          <PictureAsPdfIcon fontSize="small" />
          Contrato em PDF
        </Link>

        <Controller
          name="iAgree"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl
              error={!!fieldState.error?.message}
              component="fieldset"
              variant="standard"
            >
              <FormGroup>
                <FormControlLabel
                  sx={{ m: 0, height: "auto" }}
                  control={
                    <Checkbox
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      checked={field.value}
                      value={field.value}
                      inputRef={field.ref}
                    />
                  }
                  label="Concordo com o Contrato de Prestação de Serviços"
                />
              </FormGroup>
              {!!fieldState.error?.message && (
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Stack>

      <Divider sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Button
          variant="contained"
          type="button"
          startIcon={<NavigateBeforeIcon />}
          onClick={onBack}
        >
          Anterior
        </Button>
        <LoadingButton
          loading={isSubmitting}
          variant="contained"
          type={onNext ? "button" : "submit"}
          onClick={onNext}
          endIcon={onNext ? <NavigateNextIcon /> : undefined}
          disabled={!iAgree}
        >
          {onNext ? "Próximo" : "Confirmar"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default ContractTerms;
