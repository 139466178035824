import { FC, useState } from "react";
import useDebounce from "hooks/useDebounce";
import useQueryGetCompanyParticipants from "../../hooks/useQueryGetCompanyParticipants";

import Search from "@mui/icons-material/Search";
import { CircularProgress, InputAdornment, Stack, TextField } from "@mui/material";

import ClientList from "./ClientTable";
import { RoleCompanyParticipant } from "types/Company";

export type NameOfRoles = "client" | "customer";

export const nameOfRolesToNumber: { [x in NameOfRoles]: RoleCompanyParticipant } = {
  client: 1,
  customer: 2,
};

export const translateNameOfRoles: { [x in NameOfRoles]: string } = {
  client: "clientes",
  customer: "fornecedores",
};

interface RegistryClientsListProps {
  companyToken: string;
  role: NameOfRoles;
}

const RegistryClientsList: FC<RegistryClientsListProps> = ({ companyToken, role }) => {
  const [searchClient, setSearchClient] = useState("");
  const searchValue = useDebounce(searchClient, 500);

  const {
    data: companyParticipants,
    isLoading,
    isError,
  } = useQueryGetCompanyParticipants(companyToken, searchValue, nameOfRolesToNumber[role]);

  if (isLoading) {
    return (
      <Stack height={400} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <TextField
        size="small"
        fullWidth
        value={searchClient}
        onChange={(e) => setSearchClient(e.target.value)}
        InputProps={{
          placeholder: `Buscar ${translateNameOfRoles[role]}`,
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize="small" />
            </InputAdornment>
          ),
        }}
      />

      {companyParticipants && (
        <ClientList
          companyParticipants={companyParticipants}
          role={role}
          isQueryError={isError}
          companyToken={companyToken}
        />
      )}
    </>
  );
};
export default RegistryClientsList;
