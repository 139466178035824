import { FC, useState } from "react";
import useMutationUpdateCompanyData from "pages/Home/pages/Profile/components/CompanyData/hooks/useMutationUpdateCompanyData";

import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Order } from "types/OpenCompany";
import { CompanyInformation } from "types/Company";

const getIdCompanyType: Record<string, number> = {
  LTDA: 0,
  SLU: 1,
  IndividualEntrepreneur: 2,
};

interface CompanyTypeProps {
  order: Order;
  company: CompanyInformation;
}

const CompanyType: FC<CompanyTypeProps> = ({ order, company }) => {
  const { mutate, isLoading } = useMutationUpdateCompanyData();
  const [selectedCompanyType, setSelectedCompanyType] = useState<number>(
    getIdCompanyType[order.companyType || "LTDA"],
  );

  const handleChangeCompanyType = () => {
    if (selectedCompanyType === null) return;

    mutate({
      ...company,
      companyType: selectedCompanyType,
      companyTypeId: selectedCompanyType,
      companyToken: order.companyToken,
    });
  };

  return (
    <Box>
      <Typography sx={{ borderBottom: 1, borderColor: "divider" }} variant="h6" gutterBottom>
        Definir tipo da Empresa
      </Typography>

      <Box border={1} borderColor="divider" p={2}>
        <Select
          value={selectedCompanyType ?? ""}
          onChange={(e) => setSelectedCompanyType(Number(e.target.value))}
          fullWidth
        >
          <MenuItem value="" disabled>
            Selecione:{" "}
          </MenuItem>

          <MenuItem value={0}>LTDA</MenuItem>
          <MenuItem value={1}>SLU</MenuItem>
          <MenuItem value={2}>Empresário Individual</MenuItem>
        </Select>

        <Stack mt={1} alignItems="center" justifyContent="center">
          <LoadingButton
            disabled={selectedCompanyType === null}
            variant="contained"
            onClick={handleChangeCompanyType}
            loading={isLoading}
          >
            Salvar
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default CompanyType;
