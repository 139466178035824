import { FC, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import useMutationSetPaymentMethod from "pages/Home/pages/Services/hooks/useMutationSetPaymentMethod";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import BarCodeIcon from "assets/BarCodeIcon";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";

import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";
import ControllerInput from "components/ControllerInput";
import { ProductByPlan } from "types/Plan";
import MaskedInput from "react-text-mask";
import { cardNumberMask } from "utils/masks";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import { getPaymentInfoProfile } from "services/Requests/companies";
import getSubdomain from "utils/getSubdomain";
import toast from "react-hot-toast";

const MESSAGE_ERROR_CARD_TRANSACTION =
  "Ocorreu um erro na geração do token.\nPedimos desculpas pelo incômodo, o pagamento não foi efetuado.\n\nPor favor, entre em contato conosco para solucionarmos este problema.";

interface PaymentProps {
  onPrev: () => void;
  onFinish: () => void;
  companyToken: string;
  plan: ProductByPlan;
}

const Payment: FC<PaymentProps> = ({ onPrev, plan, companyToken, onFinish }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutateAsync: mutationSetPayment } = useMutationSetPaymentMethod();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const paymentOption = useWatch({
    control,
    name: "paymentOption",
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsSubmitting(true);

    const cardTransaction = async () => {
      const paymentInfo = await getPaymentInfoProfile({
        companyToken,
        subdomain: getSubdomain(),
        isAdmin: true,
      });

      if (!paymentInfo.currentMethod?.iuguAccountId) {
        toast.error(MESSAGE_ERROR_CARD_TRANSACTION);
        setIsSubmitting(false);
        return;
      }

      const year = data.card.yearValidity?.getFullYear()?.toString();
      const month = (new Date(data.card.monthValidity).getMonth() + 1).toString().padStart(2, "0");

      const splittedName = data.card.name.split(/[\s ]+/);

      const firstName = splittedName[0];
      splittedName.shift();
      const lastName = splittedName.join(" ");
      const cardNumber = data.card.number.replace(/ /g, "");

      Iugu.setAccountID(paymentInfo.currentMethod?.iuguAccountId);
      Iugu.setTestMode(false);
      Iugu.setup();

      Iugu.createPaymentToken(
        {
          month,
          year,
          first_name: firstName,
          last_name: lastName,
          number: cardNumber,
          verification_value: data.card.securityCode,
        },
        async (response) => {
          if (response.errors) {
            toast.error("Verifique as informações do cartão");
            setIsSubmitting(false);
            return;
          }

          if (!response.id) {
            toast.error(MESSAGE_ERROR_CARD_TRANSACTION);
            setIsSubmitting(false);
            return;
          }

          await mutationSetPayment({
            paymentToken: response.id,
            companyToken,
            paymentMethod: "card",
            planId: plan.planId,
            description: "Cartão",
            planIdentifier: plan.identifier,
            value: plan.price,
          });

          setIsSubmitting(false);
          onFinish();
        },
      );
    };

    const bankslipTransaction = async () => {
      await mutationSetPayment({
        companyToken,
        description: "Boleto",
        paymentMethod: "bankslip",
        planId: plan.planId,
        planIdentifier: plan.identifier,
        value: plan.price,
      });
      setIsSubmitting(false);
      onFinish();
    };

    if (data.paymentOption === "card") {
      try {
        await cardTransaction();
      } catch (e) {
        console.error("[6zA1] Error on card transaction", e);
        setIsSubmitting(false);
      }
    } else {
      try {
        await bankslipTransaction();
      } catch (e) {
        console.error("[6zA1] Error on bankslip transaction", e);
        setIsSubmitting(false);
      }
    }
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit(e);
      }}
    >
      <Typography variant="h6" fontWeight={600} sx={{ borderBottom: 1, borderColor: "divider" }}>
        Escolha o método de pagamento mais conveniente para você
      </Typography>

      <Box mx="auto" maxWidth="sm" mt={2}>
        <Typography variant="body1">
          Você poderá pagar suas mensalidades através de boletos bancários ou automaticamente com
          seu cartão de crédito.
        </Typography>

        <Controller
          control={control}
          name="paymentOption"
          render={({ field, fieldState }) => (
            <FormControl fullWidth error={!!fieldState.error?.message}>
              <RadioGroup row sx={{ justifyContent: "center", gap: 2 }} {...field}>
                <FormControlLabel
                  value="card"
                  control={
                    <Radio
                      icon={<CreditCardIcon fontSize="large" sx={{ width: 120, height: 120 }} />}
                      checkedIcon={
                        <CreditCardIcon fontSize="large" sx={{ width: 120, height: 120 }} />
                      }
                    />
                  }
                  label="Cartão de crédito"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="bankslip"
                  control={
                    <Radio
                      icon={<BarCodeIcon fontSize="large" sx={{ width: 120, height: 120 }} />}
                      checkedIcon={
                        <BarCodeIcon fontSize="large" sx={{ width: 120, height: 120 }} />
                      }
                    />
                  }
                  label="Boleto bancário"
                  labelPlacement="bottom"
                />
              </RadioGroup>

              {!!fieldState.error?.message && (
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />

        {!!paymentOption && <Divider sx={{ my: 2 }} />}

        {paymentOption === "bankslip" && (
          <>
            <Typography variant="body1" gutterBottom>
              Os boletos serão enviados mensalmente para seu email de cadastro.
            </Typography>
            <Typography variant="body1">
              Para sua comodidade, sugerimos utilizar o cartão de crédito para o pagamento do
              serviço. Sua mensalidade será cobrada automaticamente e poderá ser cancelada a
              qualquer momento.
            </Typography>
          </>
        )}

        {paymentOption === "card" && (
          <Box display="grid" gap={2}>
            <Typography variant="body1">
              O valor será cobrado a cada mês de seu cartão de crédito.
            </Typography>

            <Controller
              control={control}
              name="card.number"
              render={({ field, fieldState }) => (
                <MaskedInput
                  mask={cardNumberMask}
                  guide={false}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  render={(ref, props) => (
                    <TextField
                      fullWidth
                      label="Número do cartão"
                      variant="outlined"
                      inputRef={ref}
                      {...props}
                      sx={{ flex: 1 }}
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                    />
                  )}
                ></MaskedInput>
              )}
            />

            <ControllerInput
              control={control}
              name="card.name"
              inputProps={{ label: "Nome impresso no cartão" }}
            />

            <Typography variant="body1" sx={{ width: "100%", mb: -0.8 }}>
              Validade
            </Typography>

            <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr 2fr">
              <Controller
                control={control}
                name="card.monthValidity"
                render={({ field, fieldState }) => (
                  <DesktopDatePicker
                    views={["month"]}
                    label="Mês"
                    inputFormat="MM"
                    disableHighlightToday
                    {...field}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={fieldState.error?.message}
                        error={!!fieldState.error?.message || params.error}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name="card.yearValidity"
                render={({ field, fieldState }) => (
                  <DesktopDatePicker
                    views={["year"]}
                    label="Ano"
                    disablePast
                    disableHighlightToday
                    {...field}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={fieldState.error?.message}
                        error={!!fieldState.error?.message || params.error}
                      />
                    )}
                  />
                )}
              />

              <ControllerInput
                control={control}
                name="card.securityCode"
                inputProps={{
                  label: "Cód. de segurança",
                  type: "number",
                  sx: { mr: 1 },
                  inputProps: { maxLength: 3 },
                }}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Divider sx={{ mt: 4, mb: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Button
          variant="contained"
          type="button"
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onPrev}
        >
          Anterior
        </Button>
        <LoadingButton loading={isSubmitting} variant="contained" type="submit">
          Finalizar cadastro
        </LoadingButton>
      </Stack>

      {isSubmitting && <FullscreenLoadingIndicator />}
    </form>
  );
};

export default Payment;
