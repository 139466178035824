import { FC } from "react";
import { useDrive } from "./context/Drive";

import { Box, Typography } from "@mui/material";
import { CompanyDocumentsDrive } from "types/Company";

import DocumentsDriveListItemIcon from "./DocumentsDriveListItemIcon";
import DocumentsDriveTable from "./DocumentsDriveTable";

interface DriveListProps {
  companyDocumentsDrive: CompanyDocumentsDrive;
}

const DriveList: FC<DriveListProps> = ({ companyDocumentsDrive }) => {
  const { listingByIcons } = useDrive();

  return (
    <>
      {listingByIcons ? (
        <Box
          display="grid"
          rowGap={3}
          columnGap={2}
          gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
          p={2}
        >
          {companyDocumentsDrive.map((documentDrive) => (
            <DocumentsDriveListItemIcon key={documentDrive.id} documentDrive={documentDrive} />
          ))}
          {companyDocumentsDrive.length === 0 && <Typography>Não há arquivos</Typography>}
        </Box>
      ) : (
        <Box p={2}>
          <DocumentsDriveTable companyDocumentsDrive={companyDocumentsDrive} />
        </Box>
      )}
    </>
  );
};

export default DriveList;
