import { FC } from "react";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";

import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BankAccountItem from "./BankAccountItem";

interface BankAccountsProps {
  companyToken: string;
}

const BankAccounts: FC<BankAccountsProps> = ({ companyToken }) => {
  const { data: bankAccounts, isLoading, isError } = useQueryGetCompanyBankAccounts(companyToken);

  return (
    <>
      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {isError && <Typography>Ocorreu um erro ao carregar as contas do banco.</Typography>}

      {bankAccounts && !isLoading && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Dados</TableCell>
                <TableCell>Conta Contábil</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {bankAccounts.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>Nenhuma conta do banco encontrada</TableCell>
                </TableRow>
              )}
              {bankAccounts.map((bankAccount) => (
                <BankAccountItem
                  bankAccount={bankAccount}
                  key={bankAccount.id}
                  companyToken={companyToken}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default BankAccounts;
