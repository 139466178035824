import { FC } from "react";

import { Chip, CircularProgress, Stack, Typography } from "@mui/material";
import CardWrapper, { CardContent } from "../CardWrapper";

import BankData, { FieldSubmit } from "components/BankData";
import DocumentCopy from "./DocumentCopy";

import useQueryInfoAccount from "pages/Home/hooks/useQueryInfoAccount";
import useMutationUpdateAccountAutomatic from "../../hooks/useMutationUpdateAccountAutomatic";

interface RegistrationForBillingProps {
  companyToken: string;
}

const RegistrationForBilling: FC<RegistrationForBillingProps> = ({ companyToken }) => {
  const { data, isLoading } = useQueryInfoAccount({ companyToken });
  const { mutate, isLoading: updateIsLoading } = useMutationUpdateAccountAutomatic();

  const handleSubmit = (data: FieldSubmit) => {
    mutate({
      ag: data.agency,
      agDg: data.digitAgency,
      bank: data.bank,
      cc: data.account,
      ccDg: data.digitAccount,
      companyToken,
      personType: data.personType,
    });
  };

  return (
    <CardWrapper
      title={
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="h5">Cadastro para Faturamento</Typography>

          <Chip
            label={data?.verification_status === "2" ? "Conta verificada" : "Conta não verificada"}
            color={data?.verification_status === "2" ? "info" : "error"}
          />
        </Stack>
      }
    >
      <CardContent>
        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={200}>
            <CircularProgress />
          </Stack>
        )}

        {data && (
          <Stack direction="row" flexWrap="wrap" gap={4} mt={2}>
            <BankData
              billingInfo={data}
              onSubmit={handleSubmit}
              hasPersonType={false}
              isLoading={updateIsLoading}
            />
            <DocumentCopy billingInfo={data} companyToken={companyToken} />
          </Stack>
        )}
      </CardContent>
    </CardWrapper>
  );
};

export default RegistrationForBilling;
