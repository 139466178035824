import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { sendEmailTest } from "services/Requests/communications";

const useMutationSendEmailTest = () => {
  const mutation = useMutation(sendEmailTest, {
    onSuccess() {
      toast.success("Email enviado com sucesso!");
    },
  });

  return mutation;
};

export default useMutationSendEmailTest;
