import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { createTemplateAccountanting } from "services/Requests/companies";

const useMutationCreateTemplateAccountanting = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createTemplateAccountanting, {
    onSuccess(_, { id }) {
      toast.success(`Template contábil ${id ? "editado" : "criado"} com sucesso`);
      queryClient.invalidateQueries(queryKeys.templateAccountanting);
    },
  });
  return mutation;
};

export default useMutationCreateTemplateAccountanting;
