import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getUnpresented } from "services/Requests/notifications";

const useQueryUnpresented = () => {
  const query = useQuery(queryKeys.unpresented, getUnpresented, {
    staleTime: 1000 * 60 * 1, // 1 minute
  });
  return query;
};

export default useQueryUnpresented;
