import { FC, useState, memo } from "react";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";

import {
  Checkbox,
  Chip,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Menu as DropdownMenu,
  MenuItem,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import MenuIcon from "@mui/icons-material/Menu";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import { DateTime } from "luxon";
import { ISODate } from "types/SemanticTypes";
import { TransactionsItemNormalize } from "types/Company";

import { INVOICE_BASE_URL } from "utils/constants";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import getLabelStatus from "pages/Home/utils/getLabelStatus";
import getStatusColor from "pages/Home/utils/getStatusColor";
import getInvoiceStatusColor from "pages/Home/utils/getInvoiceStatusColor";

import Receipts from "../components/Receipts";
import CancelRelease from "../components/CancelRelease";
import DownloadProvisioningDialog from "pages/Home/components/DownloadProvisioningDialog";
import DialogReleaseDelete from "pages/Home/components/DialogReleaseDelete";
import DialogReleaseCredit from "pages/Home/components/DialogReleaseCredit";
import DialogReleaseDebit from "pages/Home/components/DialogReleaseDebit";
import getOrderStatusLabel from "pages/Home/utils/getOrderStatusLabel";
import getOrderColorByStatus from "pages/Home/utils/getOrderColorByStatus";

interface ReleasesTableItemProps {
  transactionItem: TransactionsItemNormalize;
  isSelected: boolean;
  onToggleSelected: () => void;
  companyToken: string;
  isLastOfDay: boolean;
  totalBalance: number;
}

const ReleasesTableItem: FC<ReleasesTableItemProps> = ({
  transactionItem,
  isSelected,
  onToggleSelected,
  companyToken,
  isLastOfDay,
  totalBalance,
}) => {
  const [cloneDialogIsOpen, setCloneDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [deleteRelease, setDeleteRelease] = useState(false);
  const [cancelRelease, setCancelRelease] = useState(false);
  const [receiptsIsShow, setReceiptsIsShow] = useState(false);
  const [downloadProvisioningIsShow, setDownloadProvisioningIsShow] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  const { data: bankAccounts } = useQueryGetCompanyBankAccounts(companyToken);

  const formatDate = (ISODate: ISODate) => {
    if (!ISODate) return "-";
    return DateTime.fromISO(ISODate).toFormat("dd/MM/yyyy");
  };

  const getBankName = (bankID: number) => {
    if (!bankID || !bankAccounts) return "-";

    return bankAccounts.find((bank) => bank.id === bankID)?.alias || "-";
  };

  const handleCloseDialog = () => {
    setEditDialogIsOpen(false);
    setCloneDialogIsOpen(false);
  };

  return (
    <>
      <TableRow
        selected={isSelected}
        hover
        sx={{
          "@media print": {
            bgcolor: "initial !important",
            td: {
              px: 1,
              py: 0.5,
            },
          },
        }}
      >
        <TableCell padding="checkbox" className="no-print" data-exclude="true">
          <Checkbox size="small" color="primary" checked={isSelected} onChange={onToggleSelected} />
        </TableCell>

        <TableCell>
          <Stack gap={0.5} alignItems="center" justifyContent="flex-end" direction="row">
            {transactionItem.qttDocuments > 0 && (
              <Tooltip title={`${transactionItem.qttDocuments} comprovante(s)`}>
                <IconButton
                  size="small"
                  className="no-print"
                  onClick={() => setReceiptsIsShow(true)}
                >
                  <FilePresentIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}

            {formatDate(transactionItem.dueDate)}
          </Stack>
        </TableCell>
        <TableCell>{formatDate(transactionItem.referenceDate)}</TableCell>
        <TableCell>{transactionItem.categoryName || "-"}</TableCell>
        <TableCell>{transactionItem.recipient || "-"}</TableCell>
        <TableCell>{transactionItem.description || "-"}</TableCell>
        <TableCell>{getBankName(transactionItem.companyBankAccountId)}</TableCell>
        <TableCell
          align="right"
          data-a-h="right"
          sx={{
            color: transactionItem.transactionType == "Debt" ? "error.main" : "success.main",
            "@media print": { fontSize: "10px" },
          }}
        >
          {formatCurrencyBRL(
            (transactionItem.transactionType == "Debt" ? -1 : 1) * transactionItem.value,
          )}
        </TableCell>

        {/* for excel and print */}
        <TableCell
          sx={{ opacity: 0, fontSize: "10px", "@media print": { opacity: 1 } }}
          align="right"
          data-a-h="right"
        >
          {formatCurrencyBRL(totalBalance)}
        </TableCell>

        <TableCell>{transactionItem.invoiceNumber}</TableCell>
        <TableCell>
          <Stack direction="row" flexWrap="wrap" gap={0.5}>
            <Chip
              size="small"
              label={getLabelStatus(transactionItem) + " "}
              color={getStatusColor(transactionItem)}
            />
            {transactionItem.isInvoice && (
              <Tooltip title={transactionItem.invoiceStatus}>
                <a href={transactionItem.pathInvoice || undefined} target="_blank" rel="noreferrer">
                  <Chip
                    size="small"
                    color={getInvoiceStatusColor(transactionItem.invoiceStatus)}
                    clickable={!!transactionItem.pathInvoice}
                    label={"NFS-e "}
                  />
                </a>
              </Tooltip>
            )}
            {transactionItem.orderId && (
              <Tooltip title={getOrderStatusLabel(transactionItem.orderStatus)}>
                <a
                  href={
                    transactionItem.secureId
                      ? INVOICE_BASE_URL + transactionItem.secureId
                      : undefined
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Chip
                    color={getOrderColorByStatus(transactionItem.orderStatus)}
                    size="small"
                    clickable={typeof transactionItem.secureId === "string"}
                    label={"Fatura "}
                  />
                </a>
              </Tooltip>
            )}
          </Stack>
        </TableCell>

        <TableCell align="right" className="no-print" data-exclude="true">
          <IconButton size="small" onClick={(e) => setAnchorElMenu(e.currentTarget)}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      {isLastOfDay && (
        <TableRow
          sx={{ bgcolor: grey[300], fontStyle: "italic" }}
          data-exclude="true"
          className="no-print"
        >
          <TableCell colSpan={2} />
          <TableCell>{formatDate(transactionItem.dueDate)}</TableCell>
          <TableCell>Saldo</TableCell>
          <TableCell colSpan={4} />
          <TableCell
            align="right"
            data-a-h="right"
            sx={{ color: totalBalance > 0 ? "success.main" : "error.main" }}
          >
            {formatCurrencyBRL(totalBalance)}
          </TableCell>
          <TableCell colSpan={3} />
        </TableRow>
      )}

      <DropdownMenu
        anchorEl={anchorElMenu}
        open={menuIsOpen}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {!transactionItem.paymentDate && (
          <MenuItem onClick={() => setDownloadProvisioningIsShow(true)}>
            Baixar Provisionamento
          </MenuItem>
        )}
        {transactionItem.orderStatus === "pending" && (
          <MenuItem onClick={() => setCancelRelease(true)}>Cancelar Fatura</MenuItem>
        )}
        {!transactionItem.isInvoice && (
          <MenuItem onClick={() => setCloneDialogIsOpen(true)}>Clonar Lançamento</MenuItem>
        )}
        <MenuItem onClick={() => setEditDialogIsOpen(true)}>Editar</MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteRelease(true);
          }}
        >
          Excluir
        </MenuItem>
      </DropdownMenu>

      {receiptsIsShow && (
        <Receipts
          open={receiptsIsShow}
          onClose={() => setReceiptsIsShow(false)}
          companyToken={companyToken}
          id={transactionItem.id}
        />
      )}

      {downloadProvisioningIsShow && (
        <DownloadProvisioningDialog
          open={downloadProvisioningIsShow}
          onClose={() => setDownloadProvisioningIsShow(false)}
          companyToken={companyToken}
          id={transactionItem.id}
        />
      )}

      <CancelRelease
        open={cancelRelease}
        onClose={() => setCancelRelease(false)}
        companyToken={companyToken}
        id={transactionItem.id}
      />

      <DialogReleaseDelete
        open={deleteRelease}
        onClose={() => setDeleteRelease(false)}
        companyToken={companyToken}
        id={transactionItem.id}
        hasParcel={transactionItem.parcel > 1}
      />

      {(cloneDialogIsOpen || editDialogIsOpen) && (
        <>
          {transactionItem.transactionType === "Credit" ? (
            <DialogReleaseCredit
              open
              onClose={handleCloseDialog}
              companyToken={companyToken}
              transactionId={transactionItem.id}
              isEdit={editDialogIsOpen}
            />
          ) : (
            <DialogReleaseDebit
              open
              onClose={handleCloseDialog}
              companyToken={companyToken}
              transactionId={transactionItem.id}
              isEdit={editDialogIsOpen}
            />
          )}
        </>
      )}
    </>
  );
};

export default memo(ReleasesTableItem);
