import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import useMutationCancelBillByAccountant from "pages/Home/pages/CollectionManagement/UsersBills/hooks/useMutationCancelBillByAccountant";
import { FC } from "react";

interface CancelReleaseProps extends Omit<DialogProps, "id" | "onClose"> {
  companyToken: string;
  id: number;
  onClose: () => void;
}

const CancelRelease: FC<CancelReleaseProps> = ({ companyToken, id, onClose, ...props }) => {
  const { mutateAsync, isLoading } = useMutationCancelBillByAccountant();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const cancelRelease = async () => {
    await mutateAsync({
      companyToken,
      id: id.toString(),
    });
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} {...props} fullWidth maxWidth="xs">
      <DialogTitleWithCloseButton onClose={handleClose}>Cancelar fatura</DialogTitleWithCloseButton>
      <DialogContent>Tem certeza que deseja cancelar essa fatura?</DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" loading={isLoading} onClick={cancelRelease}>
          Sim
        </LoadingButton>
        <Button variant="outlined" onClick={handleClose}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelRelease;
