import endpoints from "../endpoints";
import api from "../api";
import { ISODate } from "types/SemanticTypes";
import {
  LatestTransactions,
  PieChartData,
  PieChartResponse,
  RevenuesVsExpenses,
  Summary,
} from "types/Dashboard";
import { TransactionType } from "types/CommonData";
import generateRandomColor from "utils/generateRandomColor";

export interface GetSummaryPayload {
  companyToken: string;
  endDate: ISODate;
  startDate: ISODate;
}

export const getSummary = async (payload: GetSummaryPayload): Promise<Summary> => {
  const { data } = await api.post<Summary>(endpoints.dashboard.getSummary, payload);
  return data;
};

export interface GetLatestTransactionsPayload {
  companyToken: string;
  count: number;
  endDate: ISODate;
  startDate: ISODate;
}

export const getLatestTransactions = async (
  payload: GetLatestTransactionsPayload,
): Promise<LatestTransactions[]> => {
  const { data } = await api.post<LatestTransactions[]>(
    endpoints.dashboard.getLatestTransactions,
    payload,
  );
  return data;
};

export interface GetPieChartPayload {
  companyToken: string;
  group: "Customer" | "Category";
  startDate: ISODate;
  endDate: ISODate;
  type: TransactionType;
}

export const getPieChart = async (payload: GetPieChartPayload): Promise<PieChartData> => {
  const { data } = await api.post<PieChartResponse>(endpoints.dashboard.getPieChart, payload);

  const pieData = data.labels.map((label, index) => ({
    label: label || (payload.type === "Credit" ? "Receita " : "Despesa " + (index + 1)),
    color: data.colors[index] || generateRandomColor(),
    data: data.data[index],
  }));

  return {
    total: data.total,
    pieData,
  };
};

export interface GetRevenuesVsExpensesPayload {
  companyToken: string;
  endDate: ISODate;
  startDate: ISODate;
  provision: boolean;
}

export const getRevenuesVsExpenses = async (
  payload: GetRevenuesVsExpensesPayload,
): Promise<RevenuesVsExpenses> => {
  const { data } = await api.post<RevenuesVsExpenses>(
    endpoints.dashboard.getRevenuesVsExpenses,
    payload,
  );
  return data;
};
