import { CompanyPartner, NewCompanyPartner } from "types/Company";
import { PartnerFields } from "..";

const partnersEdited = (
  partners: PartnerFields[],
  responsible: number,
  oldPartners: CompanyPartner[] | undefined,
): CompanyPartner[] => {
  if (!oldPartners) return [];

  return oldPartners.map((partner) => {
    const partnerEdited = partners.find((p) => p.partnerId === partner.id);
    const partnerEditedIndex = partners.findIndex((p) => p.partnerId === partner.id);

    if (!partnerEdited) {
      return {
        ...partner,
        updateAction: "Delete",
      };
    }

    const edited: CompanyPartner = {
      ...partner,
      name: partnerEdited.name,
      share: partnerEdited.share,
      prolabore: partnerEdited.wageCompesation,
      profession: partnerEdited.occupation,
      civilState: partnerEdited.maritalStatus,
      propertyRegime: partnerEdited.communityProperty,
      cpfcnpj: partnerEdited.cpf.replace(/\D/g, ""),
      address: {
        ...partner.address,
        state: partnerEdited.state,
        street: partnerEdited.street,
        countyId: partnerEdited.city,
        neighborhood: partnerEdited.neighborhood,
        zipCode: partnerEdited.zipCode.replace(/\D/g, ""),
        complement: partnerEdited.complement,
        streetNumber: partnerEdited.streetNumber.toString(),
      },
      inCharge: partnerEditedIndex === responsible,
      updateAction: "Update",
    };

    return edited;
  });
};

const newCompanyPartner = (
  partner: PartnerFields,
  responsible: number,
  index: number,
): NewCompanyPartner => ({
  name: partner.name,
  share: partner.share,
  prolabore: partner.wageCompesation,
  profession: partner.occupation,
  civilState: partner.maritalStatus,
  propertyRegime: partner.communityProperty,
  cpfcnpj: partner.cpf.replace(/\D/g, ""),
  address: {
    state: partner.state,
    street: partner.street,
    countyId: partner.city,
    neighborhood: partner.neighborhood,
    zipCode: partner.zipCode.replace(/\D/g, ""),
    complement: partner.complement,
    streetNumber: partner.streetNumber.toString(),
  },
  inCharge: index === responsible,
  updateAction: "Insert",
});

const partnerField = (partner?: CompanyPartner) => ({
  partnerId: partner?.id,
  name: partner?.name || "",
  share: partner?.share || 0,
  wageCompesation: Boolean(partner?.prolabore),
  occupation: partner?.profession || "",
  maritalStatus: partner?.civilState,
  communityProperty: partner?.propertyRegime,
  cpf: partner?.cpfcnpj || "",
  street: partner?.address?.street || "",
  neighborhood: partner?.address?.neighborhood || "",
  zipCode: partner?.address?.zipCode || "",
  city: partner?.address?.countyId || "",
  complement: partner?.address?.complement || "",
  state: partner?.address?.state || "",
  streetNumber: partner ? Number(partner.address.streetNumber) : 0,
  inCharge: partner?.inCharge || false,
});

const buildData = {
  partnersEdited,
  newCompanyPartner,
  partnerField,
};

export default buildData;
