import { useQuery } from "react-query";
import { getSyntheticAccounts } from "services/Requests/dre";


const useQueryGetSyntheticAccounts = (companyToken: string) => {
  const query = useQuery("", () =>
  getSyntheticAccounts(companyToken),
  );
  return query;
};

export default useQueryGetSyntheticAccounts;
