import { FC, useState } from "react";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";
import useQueryCompanyPartners from "pages/Home/hooks/useQueryCompanyPartners";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import TabPanel, { TabPanelProps } from "components/TabPanel";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import MyCompany from "../../Profile/components/CompanyData/MyCompany";
import PartnerForm from "../../PartnerForm";

import { CONTRACT_TERM, URL_CONTRACT_PROVISION_OF_ACCOUNTING_SERVICES } from "utils/constants";

interface DialogViewDetailsProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  typeService: "premium" | "free";
  companyToken: string;
}

const DialogViewDetails: FC<DialogViewDetailsProps> = ({ typeService, companyToken, ...props }) => {
  const { data: company, isLoading } = useQueryGetCompany(companyToken);
  const { data: partners, isLoading: loadingPartners } = useQueryCompanyPartners(companyToken);
  const [activeTab, setActiveTab] = useState(0);

  const tabPanelProps: TabPanelProps = {
    keepMounted: true,
    value: activeTab,
    sx: {
      p: 2,
    },
    index: 0,
  };

  return (
    <Dialog {...props} maxWidth="md" fullWidth>
      <DialogTitleWithCloseButton onClose={props.onClose}>
        {typeService == "premium" ? "Consultoria Premium" : "Faça você mesmo"}
      </DialogTitleWithCloseButton>

      <DialogContent>
        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={500}>
            <CircularProgress />
          </Stack>
        )}

        {!isLoading && company && (
          <>
            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
              sx={{
                borderBottom: 1,
                borderBottomColor: "divider",
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Inicio" />
              <Tab label="Sua empresa" />
              <Tab label="Sócios" />
              <Tab label="Contrato" />
            </Tabs>

            <TabPanel {...tabPanelProps} index={0}>
              <Typography
                variant="h5"
                fontWeight={600}
                sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
              >
                Area escolhida
              </Typography>

              <TextField value={company.companyActivity} disabled fullWidth />
            </TabPanel>

            <TabPanel {...tabPanelProps} index={1}>
              <MyCompany disabledForm companyInformation={company} />
            </TabPanel>

            <TabPanel {...tabPanelProps} index={2}>
              {loadingPartners && (
                <Stack alignItems="center" justifyContent="center">
                  <CircularProgress />
                </Stack>
              )}

              {!loadingPartners && partners && partners.length > 0 && (
                <PartnerForm disabledForm partners={partners} companyInformation={company} />
              )}

              {!loadingPartners && (!partners || partners.length === 0) && (
                <Typography>Não há sócios cadastrados</Typography>
              )}
            </TabPanel>

            <TabPanel {...tabPanelProps} index={3}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Leia atentamente o nosso Contrato de Prestação de Serviços e aceite os termos para
                prosseguir com a migração de sua empresa.
              </Typography>

              <TextField
                value={CONTRACT_TERM}
                InputProps={{
                  readOnly: true,
                }}
                multiline
                maxRows={20}
                fullWidth
              />

              <Link
                target="_blank"
                href={URL_CONTRACT_PROVISION_OF_ACCOUNTING_SERVICES}
                sx={{ display: "flex", gap: 0.5, alignItems: "center", mt: 4 }}
              >
                <PictureAsPdfIcon fontSize="small" />
                Contrato em PDF
              </Link>
            </TabPanel>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} variant="contained">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogViewDetails;
