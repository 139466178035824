import { FC, useEffect } from "react";

interface HeadProps {
  title: string;
  description?: string;
}

const Head: FC<HeadProps> = ({ title, description }) => {
  useEffect(() => {
    document.title = title;

    const meta = document.querySelector("meta[name='description']");
    if (description && meta) {
      meta.setAttribute("content", description);
    }
  }, [title]);

  return <></>;
};

export default Head;
