import { FC } from "react";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { MovementForm } from "../utils/InterfacesForm";
import PaymentTypesSelect from "pages/Home/components/PaymentTypesSelect";
import { defaultMovementPayment } from "../utils/getDefaultValuesForm";
import { Box, Button } from "@mui/material";
import PaymentItem from "./PaymentItem";
import AddIcon from "@mui/icons-material/Add";

interface PaymentControllerProps {
  control: Control<MovementForm>;
  isConsultation: boolean;
}

const PaymentController: FC<PaymentControllerProps> = ({ control, isConsultation }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "payment",
  });

  const handleAddNewItem = () => {
    append(defaultMovementPayment);
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  return (
    <>
      <Controller
        name="paymentType"
        control={control}
        render={({ field, fieldState }) => (
          <PaymentTypesSelect
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            label="Tipo"
            disabled={isConsultation}
          />
        )}
      />

      <Box display="grid" gap={2} mt={2}>
        {fields.map((field, index) => (
          <PaymentItem
            key={field.id}
            index={index}
            control={control}
            isConsultation={isConsultation}
            onRemove={() => handleRemoveItem(index)}
          />
        ))}
      </Box>

      {!isConsultation && (
        <Button sx={{ mt: 2 }} startIcon={<AddIcon />} onClick={handleAddNewItem}>
          Adicionar pagamento
        </Button>
      )}
    </>
  );
};

export default PaymentController;
