import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";

import { updateProfile } from "services/Requests/companies";

const useMutationUpdateProfile = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateProfile, {
    onSuccess() {
      toast.success("Perfil editado com sucesso");
      queryClient.invalidateQueries(queryKeys.companyProfile, { exact: false });
    },
  });
  return mutation;
};

export default useMutationUpdateProfile;
