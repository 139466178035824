import { FC, useState } from "react";
import { useAuth } from "context/Auth";

import { Button, Checkbox, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { ImportOFXTransaction } from "types/Company";
import DialogMassActionExtracts from "./DialogMassActionExtracts";

interface MassActionProps {
  selectIds: string[];
  itemsLength: number;
  onSelectAll: () => void;
  onMarkOffAll: () => void;
  extracts: ImportOFXTransaction[];
  reloadList?: () => void;
}

const MassAction: FC<MassActionProps> = ({
  selectIds,
  itemsLength,
  onSelectAll,
  onMarkOffAll,
  extracts,
  reloadList,
}) => {
  const {
    user: { company },
  } = useAuth();

  const totalSelected = selectIds.length;
  const [actionSelected, setActionSelected] = useState("");
  const [extractsSelected, setExtractsSelected] = useState<null | ImportOFXTransaction[]>(null);

  const handleChange = (checked: boolean) => {
    if (checked) {
      onSelectAll();
    } else {
      onMarkOffAll();
    }
  };

  const handleSubmit = () => {
    setExtractsSelected(() => {
      return extracts.filter((e) => selectIds.includes(e.ofxId));
    });
  };

  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <Checkbox
          sx={{ height: "fit-content" }}
          size="small"
          checked={totalSelected !== 0 && totalSelected === itemsLength}
          indeterminate={totalSelected !== 0 && totalSelected !== itemsLength}
          onChange={(_, checked) => handleChange(checked)}
        />
        <FormControl size="small">
          <InputLabel>Ações em massa</InputLabel>
          <Select
            label="Ações em massa"
            sx={{ minWidth: 180 }}
            value={actionSelected}
            disabled={!company}
            onChange={(e) => setActionSelected(e.target.value)}
          >
            <MenuItem disabled value="">
              Ações ({totalSelected})
            </MenuItem>
            <MenuItem value="reconcile">Conciliar</MenuItem>
            <MenuItem value="ignore">Ignorar</MenuItem>
          </Select>
        </FormControl>
        <Button
          sx={{ ml: 2 }}
          size="small"
          variant="contained"
          disabled={!actionSelected || totalSelected === 0 || !company}
          onClick={handleSubmit}
        >
          Lançamentos selecionados
        </Button>
      </Stack>

      {extractsSelected && company && (
        <DialogMassActionExtracts
          selectIds={selectIds}
          open={!!extractsSelected}
          onClose={() => setExtractsSelected(null)}
          extracts={extractsSelected}
          companyToken={company.token}
          onCloseAfterReconcile={onMarkOffAll}
          isIgnore={actionSelected === "ignore"}
          reloadList={reloadList}
        />
      )}
    </>
  );
};

export default MassAction;
