import { useCallback } from "react";
import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getProducts } from "services/Requests/stockControl";
import { Products } from "types/StockControl";

const useQueryProducts = (companyToken: string, search = "") => {
  const select = useCallback(
    (data: Products) => {
      if (!search) return data;

      const searchProduct = (name: string) => {
        if (!search.trim()) return true;

        return name.toLowerCase().search(search.toLowerCase()) !== -1;
      };

      return data.filter((p) => searchProduct(p.name));
    },
    [search],
  );

  const query = useQuery(queryKeys.stockControlProducts, () => getProducts({ companyToken }), {
    select,
  });
  return query;
};

export default useQueryProducts;
