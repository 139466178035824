import { FC, useState } from "react";

import { Box, Tab, Tabs, Theme, useMediaQuery } from "@mui/material";

import TabPanel, { TabPanelProps } from "components/TabPanel";
import { ServicePlan } from "types/ServiceAndPlan";

import ContractTerms from "../../components/ContractTerms";
import CompanyTab from "../../components/CompanyTab";

interface TabsControllerProps {
  companyToken: string;
  service: ServicePlan;
}

const TabsController: FC<TabsControllerProps> = ({ companyToken, service }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [activeTab, setActiveTab] = useState(0);

  const handleNext = () => setActiveTab(activeTab + 1);
  const handleBack = () => setActiveTab(activeTab - 1);

  const tabPanelProps: TabPanelProps = {
    keepMounted: true,
    value: activeTab,
    sx: {
      p: isMobile ? 2 : 3,
    },
    index: 0,
  };

  return (
    <Box>
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{
          borderBottom: 1,
          borderBottomColor: "divider",
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Sobre Você" />
        <Tab label="Contrato" disabled={activeTab < 1} />
      </Tabs>

      <TabPanel {...tabPanelProps} index={0}>
        <CompanyTab companyToken={companyToken} onNext={handleNext} otherForm isPF />
      </TabPanel>
      <TabPanel value={activeTab} index={1} keepMounted>
        <ContractTerms downloadToken={service.downloadToken} onBack={handleBack} />
      </TabPanel>
    </Box>
  );
};

export default TabsController;
