import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

import queryKeys from "react-query/constants";
import getSubdomain from "utils/getSubdomain";
import { cancelBill } from "services/Requests/billing";
import { Bill } from "types/Billing";

const useMutationCancelBill = () => {
  const subdomain = getSubdomain();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload: { payerCompanyId: number; id: string; companyToken: string }) =>
      cancelBill({ subdomain, ...payload }),
    {
      onSuccess(_, { payerCompanyId, id }) {
        const allBills = queryClient.getQueryData<Bill[]>([queryKeys.clientBills, payerCompanyId]);

        if (!allBills || allBills.length === 0) {
          queryClient.invalidateQueries([queryKeys.clientBills, payerCompanyId]);
        } else {
          const newBillsAfterCancel = allBills.map((bill) => {
            if (bill.id === id) {
              return {
                ...bill,
                status: "Cancelada",
              };
            }

            return bill;
          });

          queryClient.setQueryData([queryKeys.clientBills, payerCompanyId], newBillsAfterCancel);
        }

        toast.success("Fatura cancelada com sucesso!");
        queryClient.invalidateQueries([queryKeys.clientDetails, payerCompanyId]);
      },
    },
  );

  return mutation;
};

export default useMutationCancelBill;
