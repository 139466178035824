import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { FC, useState } from "react";
import FeesTableItem from "./FeesTableItem";
import { BillingSubsAccounts } from "types/Billing";

interface FeesTableProps {
  subsAccounts: BillingSubsAccounts[];
}

const FeesTable: FC<FeesTableProps> = ({ subsAccounts }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Cliente</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Boleto R$</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Saque Conta R$</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Aquisição Pgto CC %</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Antecipação %</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>Criado em</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {subsAccounts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((account) => (
                <FeesTableItem key={account.companyId} subAccount={account} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage={"Linhas por páginas: "}
        labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={subsAccounts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default FeesTable;
