import { FC, useEffect, useMemo, useRef, useState } from "react";
import useMutationCreateMovement from "../../hooks/useMutationCreateMovement";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import AdditionalInformation from "./Fields/AdditionalInformation";
import BasicOperations from "./Fields/BasicOperations";
import Shipping from "./Fields/Shipping";
import TabPanel from "components/TabPanel";
import ItemsController from "./Fields/ItemsController";

import { FullMovement, InvoiceConfig } from "types/StockControl";
import validationSchema from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";

import {
  CreateMovementItem,
  CreateMovementPayload,
  CreateMovementShipping,
} from "services/Requests/stockControl";
import { MovementForm } from "./utils/InterfacesForm";
import getDefaultValuesForm from "./utils/getDefaultValuesForm";
import { AxiosError } from "axios";
import PaymentController from "./Fields/PaymentController";
import * as buildData from "./utils/buildData";

interface DialogMovementProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  isConsultation: boolean;
  invoiceConfig?: InvoiceConfig;
  movement?: FullMovement;
}

export const DialogMovement: FC<DialogMovementProps> = ({
  onClose,
  companyToken,
  invoiceConfig,
  movement,
  isConsultation,
  ...props
}) => {
  const { mutateAsync, error, isError } = useMutationCreateMovement();
  const errorMessage = (error as AxiosError<{ message: string }>)?.response?.data?.message;

  const keepDialog = useRef(false);
  const [tabActive, setTabActive] = useState(0);
  const isTablet = useMediaQuery("(max-width:800px)");

  const defaultValues = useMemo(
    () => getDefaultValuesForm(movement, invoiceConfig),
    [movement, invoiceConfig],
  );

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<MovementForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const sendInvoice = watch("sendInvoice");
  const isInvoice = sendInvoice === "1" || sendInvoice === "2";

  const handleClose = () => {
    if (isSubmitting) return;
    reset(defaultValues);
    setTabActive(0);
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    if (isConsultation) return;
    if (data.items.length === 0) return;

    let shipping: CreateMovementShipping | undefined = undefined;
    const transformToISODate = (date: Date) => DateTime.fromJSDate(date).toISO();

    const nFType = (() => {
      if (data.sendInvoice === "2") return "NFCe";
      if (data.sendInvoice === "1") return "NFe";
      return "";
    })();

    if (nFType === "NFe" || nFType === "NFCe") {
      shipping = buildData.movementShipping(data.shipping);
    }

    const items: CreateMovementItem[] = buildData.movementItems(data.items, movement?.items);

    let createMovemenPayload: CreateMovementPayload = {
      id: movement?.id || 0,
      companyToken,
      dueDate: transformToISODate(data.dueDate),
      finalConsumer: data.finalConsumer,
      generateTransaction: data.generateTransaction,
      items,
      movementDate: transformToISODate(data.movementDate),
      nature: data.nature,
      notes: data.notes,
      operationType: data.operationType === "Entrada" ? 1 : 0,
      presenceIndicator: Number(data.presenceIndicator),
      showFinalConsumer: data.finalConsumer === "1",
      participantId: data.participantId,
      totalValue: items.reduce((acc, item) => acc + item.totalValue, 0),
      shipping,
      nFType: nFType,
      nfType: nFType,
      sendInvoice: data.sendInvoice === "1" || data.sendInvoice === "2",
      sendNFe: data.sendInvoice === "1",
      sendNFCe: data.sendInvoice === "2",
      paymentType: null,
      payments: [],
    };

    if (nFType === "NFCe") {
      createMovemenPayload = {
        ...createMovemenPayload,
        paymentType: data.paymentType,
        payments: buildData.createPayments(data.payment, data.paymentType, movement?.payments),
      };
    }

    await mutateAsync(createMovemenPayload);

    if (keepDialog.current && !movement) {
      keepDialog.current = false;
      setTabActive(0);
      reset(defaultValues);
      return;
    } else {
      handleClose();
    }
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "operationType") {
        setValue("participantId", undefined);
        setValue("nature", value.operationType === "Entrada" ? "Compra" : "Venda");

        if (value.operationType === "Entrada" && value.sendInvoice === "2") {
          setValue("sendInvoice", "0");
        }
      }

      if (name === "sendInvoice") {
        if (value.sendInvoice === "0") {
          setTabActive(0);
        }

        if (value.sendInvoice === "1" && tabActive === 2) {
          setTabActive(0);
        }

        if (value.sendInvoice === "2") {
          setValue("finalConsumer", "1");
        }

        setValue("presenceIndicator", "" as "0" | "1");
      }

      if (name === "shipping.recipientState") {
        setValue("shipping.recipientCity", null);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="lg" fullWidth fullScreen={isTablet}>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {isConsultation ? "Movimentação" : "Cadastre uma movimentação"}
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          if (isConsultation) return;
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
        noValidate
      >
        <DialogContent sx={{ minHeight: "80vh" }}>
          <BasicOperations
            control={control}
            companyToken={companyToken}
            isConsultation={isConsultation}
          />
          <Divider sx={{ my: 2 }} />

          <Tabs value={tabActive} onChange={(_, newValue) => setTabActive(newValue)}>
            <Tab label="Itens" />
            <Tab label="Frete" disabled={sendInvoice === "0"} />
            <Tab label="Pagamento" disabled={sendInvoice !== "2"} />
          </Tabs>

          <TabPanel
            value={tabActive}
            index={0}
            sx={{ border: 1, borderColor: "divider" }}
            keepMounted
          >
            <ItemsController
              control={control}
              companyToken={companyToken}
              setValue={setValue}
              isConsultation={isConsultation}
            />
          </TabPanel>
          {isInvoice && (
            <TabPanel
              value={tabActive}
              index={1}
              sx={{ border: 1, borderColor: "divider" }}
              keepMounted
            >
              <Shipping control={control} isConsultation={isConsultation} />
            </TabPanel>
          )}

          {sendInvoice === "2" && (
            <TabPanel
              value={tabActive}
              index={2}
              sx={{ border: 1, borderColor: "divider" }}
              keepMounted
            >
              <PaymentController control={control} isConsultation={isConsultation} />
            </TabPanel>
          )}

          <Divider sx={{ my: 2 }} />

          <AdditionalInformation control={control} isConsultation={isConsultation} />

          {isError && !!errorMessage && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          {!isConsultation && (
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              type="submit"
              onClick={() => {
                const haveError = Object.keys(errors).length;
                if (haveError) {
                  toast.error("Verifique todos os campos e corrija os erros");
                }
              }}
            >
              Salvar
            </LoadingButton>
          )}
          {!movement && !isConsultation && (
            <LoadingButton
              variant="outlined"
              loading={isSubmitting}
              type="submit"
              onClick={() => {
                const haveError = Object.keys(errors).length;
                if (haveError) {
                  toast.error("Verifique todos os campos e corrija os erros");
                }
                keepDialog.current = true;
              }}
            >
              Salvar e cadastrar outro
            </LoadingButton>
          )}
          <Button disabled={isSubmitting} onClick={handleClose}>
            {isConsultation ? "Fechar" : "Cancelar"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
