import { FC, useState } from "react";

import { IconButton, Stack, Typography, MenuItem } from "@mui/material";
import { grey } from "@mui/material/colors";

import { DREAnalytical } from "types/ManagerialIncomeStatement";
import DialogCreateOrEditAnalytical from "./components/DialogCreateOrEditAnalytical";
import { Menu } from "@mui/icons-material";
import DialogDeleteAnalytical from "./components/DialogDeleteAnalytical";
import DropdownMenu from "components/DropdownMenu";
import { useAuth } from "context/Auth";

const Analytical: FC<{ dre: DREAnalytical }> = ({ dre }) => {
  const [editAnalytical, setEditAnalytical] = useState(false);
  const [deleteAnalytical, setDeleteAnalytical] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);
  const { user: { company }, } = useAuth();

  return (
    <>
      <Stack
        bgcolor={grey[50]}
        p={1}
        pl={4}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        borderBottom={1}
        borderColor="divider"
      >
        <Typography>{dre.title}</Typography>
        <IconButton color="primary" onClick={(e) => setAnchorElMenu(e.currentTarget)}>
          <Menu />
        </IconButton>
        <DropdownMenu
          anchorEl={anchorElMenu}
          open={menuIsOpen}
          onClick={() => setAnchorElMenu(null)}
          onClose={() => setAnchorElMenu(null)}
        >
          <MenuItem onClick={() => setEditAnalytical(true)}>Editar</MenuItem>
          <MenuItem onClick={() => setDeleteAnalytical(true)}>Excluir</MenuItem>
        </DropdownMenu>
      </Stack>

      {editAnalytical && (
        <DialogCreateOrEditAnalytical
          open={editAnalytical}
          onClose={() => setEditAnalytical(false)}
          dre={dre}
          parentId={dre.parentId}
          company={company}
        />
      )}

      {deleteAnalytical && (
        <DialogDeleteAnalytical
          open={deleteAnalytical}
          onClose={() => setDeleteAnalytical(false)}
          dre={dre}
          parentId={dre.parentId}
          company={company}
        />
      )}
    </>
  );
};

export default Analytical;
