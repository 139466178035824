import { FC } from "react";

import { Button, Stack } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";

interface InvoiceDownloadProps {
  pathInvoice: string | null;
  pathXmlInvoice: string | null;
}

const InvoiceDownload: FC<InvoiceDownloadProps> = ({ pathInvoice, pathXmlInvoice }) => {
  return (
    <Stack>
      {!!pathInvoice && (
        <Button
          variant="text"
          size="small"
          startIcon={<PictureAsPdfIcon fontSize="small" />}
          component="a"
          target="_blank"
          href={pathInvoice}
        >
          PDF
        </Button>
      )}
      {!!pathXmlInvoice && (
        <Button
          variant="text"
          size="small"
          startIcon={<SnippetFolderIcon fontSize="small" />}
          component="a"
          target="_blank"
          href={pathXmlInvoice}
        >
          XML
        </Button>
      )}
    </Stack>
  );
};

export default InvoiceDownload;
