import { FC, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import useMutationCreateCategory from "../hooks/useMutationCreateCategory";
import useQueryGetFinancialCategories from "../../../hooks/useQueryGetFinancialCategories";

import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  TextField,
} from "@mui/material";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import ControllerInput from "components/ControllerInput";
import { FinancialCategory } from "types/Company";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

interface FinancialCategoryWithParentName extends Omit<FinancialCategory, "children"> {
  parentName: string;
}

const getFinancialCategoriesByGroup = (
  financialCategories: FinancialCategory[],
): FinancialCategoryWithParentName[] => {
  return financialCategories
    .map((category) => {
      return category.children.map((child) => ({ ...child, parentName: category.name }));
    })
    .flat();
};

interface CreateCategoryForm {
  name: string;
  globalCategoryId: FinancialCategoryWithParentName | number;
}

interface DialogCreateCategoryProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  onClose: () => void;
  globalCategoryId?: number;
}

const DialogCreateCategory: FC<DialogCreateCategoryProps> = ({
  onClose,
  companyToken,
  globalCategoryId,
  ...props
}) => {
  const isDefaultGlobalCategoryId = !!globalCategoryId;

  const { mutateAsync } = useMutationCreateCategory();
  const { data: financialCategories = [], isLoading } =
    useQueryGetFinancialCategories(companyToken);
  const financialCategoriesWithGroup = useMemo(
    () => getFinancialCategoriesByGroup(financialCategories),
    [financialCategories],
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<CreateCategoryForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      globalCategoryId,
    },
  });

  const handleClose = () => {
    if (isSubmitting) return;
    reset({
      name: "",
      globalCategoryId,
    });
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      companyToken,
      globalCategoryId:
        typeof data.globalCategoryId === "number"
          ? data.globalCategoryId
          : data.globalCategoryId.id,
      name: data.name,
    });
    handleClose();
  });

  const getValue = (value: FinancialCategoryWithParentName | number) => {
    if (typeof value !== "number") return value;

    return financialCategoriesWithGroup.find((c) => c.id === value);
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Adicionar categoria personalizada
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          <Stack gap={2}>
            <ControllerInput
              control={control}
              name="name"
              inputProps={{ label: "Nome da categoria", fullWidth: true }}
            />

            <Controller
              control={control}
              name="globalCategoryId"
              render={({ field, fieldState }) => (
                <Autocomplete
                  disabled={isDefaultGlobalCategoryId}
                  value={getValue(field.value)}
                  ref={field.ref}
                  onBlur={field.onBlur}
                  onChange={(_, value) => field.onChange(value)}
                  options={financialCategoriesWithGroup}
                  getOptionLabel={(option) => option.name}
                  groupBy={(option) => option.parentName}
                  isOptionEqualToValue={(option, value) => option?.id === value.id}
                  autoHighlight
                  noOptionsText="Sem opção"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Escolha a categoria pai"
                      helperText={fieldState.error?.message}
                      error={!!fieldState.error?.message}
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: "off",
                        endAdornment: (
                          <>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" loading={isSubmitting} type="submit">
            Salvar
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose} disabled={isSubmitting}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreateCategory;
