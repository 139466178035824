import { FC } from "react";
import useMutationDeleteOrder from "pages/Home/pages/Marketplace/hooks/useMutationDeleteOrder";

import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { Order } from "types/OpenCompany";

interface DialogDeleteOrderProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  order: Order;
}

const DialogDeleteOrder: FC<DialogDeleteOrderProps> = ({ onClose, order, ...props }) => {
  const { mutateAsync, isLoading } = useMutationDeleteOrder();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync(order);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>Deletar pedido</DialogTitleWithCloseButton>
      <DialogContent>
        Confirma a deleção do pedido da empresa &quot;{order.companyName}&quot;?
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleDelete}>
          Sim
        </LoadingButton>
        <Button disabled={isLoading} variant="outlined" onClick={handleClose}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteOrder;
