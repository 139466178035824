import { FC } from "react";
import { IconButton } from "@mui/material";
import { useSlate } from "slate-react";
import { IconType, TextFormat } from "../../slate";
import { isMarkActive, toggleMark } from "../../utils/functions";

interface MarkButtonProps {
  format: TextFormat;
  Icon: IconType;
}

const MarkButton: FC<MarkButtonProps> = ({ format, Icon }) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);

  return (
    <IconButton
      size="small"
      color={isActive ? "primary" : "default"}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon
        fontSize="small"
        sx={{
          transition: "all 500ms ease",
          transform: `scale(${isActive ? "1.1" : "1"})`,
        }}
      />
    </IconButton>
  );
};

export default MarkButton;
