import { FC } from "react";
import useMutationDeleteFinancialCategory from "../../hooks/useMutationDeleteFinancialCategory";

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

interface DialogDeleteSyntheticCategoryProps extends Omit<DialogProps, "onClose" | "id"> {
  onClose: () => void;
  id: number;
  isCompany: boolean;
}

const DialogDeleteSyntheticCategory: FC<DialogDeleteSyntheticCategoryProps> = ({
  onClose,
  id,
  isCompany,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationDeleteFinancialCategory(isCompany);

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync({ id });
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>Excluir conta</DialogTitleWithCloseButton>

      <DialogContent>Deseja excluir esta conta?</DialogContent>

      <DialogActions>
        <LoadingButton onClick={handleDelete} loading={isLoading} variant="contained">
          Sim
        </LoadingButton>
        <Button variant="outlined" onClick={handleClose} disabled={isLoading}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteSyntheticCategory;
