import { FC, PropsWithChildren, useState } from "react";
import { DashboardPeriodContext } from ".";
import { DateTime } from "luxon";

export const DashboardPeriodProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [date, setDate] = useState({
    start: DateTime.local().startOf("month").toISO(),
    end: DateTime.local().endOf("month").toISO(),
  });

  return (
    <DashboardPeriodContext.Provider
      value={{
        date,
        setDate,
      }}
    >
      {children}
    </DashboardPeriodContext.Provider>
  );
};
