import { FC } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { DateTime } from "luxon";
import { ISODate } from "types/SemanticTypes";
import { LoadingButton } from "@mui/lab";
import useMutationCloseMonth from "../hooks/useMutationCloseMonth";

interface DialogConfirmationCloseMonthProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  monthToClose: ISODate;
  onClose: () => void;
}

const DialogConfirmationCloseMonth: FC<DialogConfirmationCloseMonthProps> = ({
  companyToken,
  monthToClose,
  onClose,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationCloseMonth();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleCloseMonth = async () => {
    await mutateAsync({
      companyToken,
      dateReferenceClose: monthToClose,
    });
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Fechamento do mês
      </DialogTitleWithCloseButton>

      <DialogContent>
        Confirma o fechamento de {DateTime.fromISO(monthToClose).toFormat("LLLL / yyyy")}?
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" disabled={isLoading}>
          Não
        </Button>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleCloseMonth}>
          Sim
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmationCloseMonth;
