import { FC, useState } from "react";

import DialogService from "./DialogService";
import DialogDeleteService from "./DialogDeleteService";
import { IconButton, MenuItem, TableCell, TableRow, Menu as DropdownMenu } from "@mui/material";
import Menu from "@mui/icons-material/Menu";

import { useAuth } from "context/Auth";
import toast from "react-hot-toast";
import { DateTime } from "luxon";

import { CustomService } from "types/ServiceAndPlan";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface TableItemProps {
  service: CustomService;
}

const TableItem: FC<TableItemProps> = ({ service }) => {
  const {
    user: { company },
  } = useAuth();

  const [updateService, setUpdateService] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [dialogDeleteService, setDialogDeleteService] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUpdate = () => {
    if (!company) {
      toast.error("Empresa não localizada, não é possível editar");
      return;
    }
    setUpdateService(true);
  };

  const handleOpenDeleteService = () => {
    if (!company) {
      toast.error("Empresa não localizada, não é possível excluir");
      return;
    }
    setDialogDeleteService(true);
  };

  return (
    <>
      <TableRow hover>
        <TableCell>{service.name}</TableCell>
        <TableCell>{DateTime.fromISO(service.createdOn).toFormat("dd/MM/yyyy")}</TableCell>
        <TableCell>{formatCurrencyBRL(service.value)}</TableCell>
        <TableCell>{service.isActive ? "Ativo" : "Inativo"}</TableCell>
        <TableCell>
          <IconButton size="small" onClick={handleClick}>
            <Menu fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleOpenUpdate}>Editar</MenuItem>
        <MenuItem onClick={handleOpenDeleteService}>Excluir</MenuItem>
      </DropdownMenu>

      {updateService && company && (
        <DialogService
          companyToken={company.token}
          open={updateService}
          onClose={() => setUpdateService(false)}
          service={service}
        />
      )}

      {company && (
        <DialogDeleteService
          open={dialogDeleteService}
          onClose={() => setDialogDeleteService(false)}
          companyToken={company.token}
          serviceId={service.id}
        />
      )}
    </>
  );
};

export default TableItem;
