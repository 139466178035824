import { FC } from "react";
import useQueryGetPenaltiesAndDiscounts from "../hooks/useQueryGetPenaltiesAndDiscounts";

import PenaltiesAndDiscountsForm from "./PenaltiesAndDiscountsForm";

import { Box, CircularProgress, Collapse, LinearProgress, Stack } from "@mui/material";

interface PenaltiesAndDiscountsQueryProps {
  companyToken: string;
}

const PenaltiesAndDiscountsQuery: FC<PenaltiesAndDiscountsQueryProps> = ({ companyToken }) => {
  const {
    data: penaltiesAndDiscounts,
    isLoading,
    isFetching,
  } = useQueryGetPenaltiesAndDiscounts(companyToken);

  return (
    <Box>
      <Collapse in={!isLoading && isFetching}>
        <LinearProgress />
      </Collapse>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={300}>
          <CircularProgress />
        </Stack>
      )}

      {penaltiesAndDiscounts && (
        <PenaltiesAndDiscountsForm
          penaltiesAndDiscounts={penaltiesAndDiscounts}
          companyToken={companyToken}
        />
      )}
    </Box>
  );
};

export default PenaltiesAndDiscountsQuery;
