import { FC } from "react";

import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ControllerInput from "components/ControllerInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

import { Configurations } from "types/Communications";
import useMutationSetConfiguration from "../hooks/useMutationSetConfiguration";

interface ConfigurationFormProps {
  configuration: Configurations;
  companyToken: string;
}

const ConfigurationForm: FC<ConfigurationFormProps> = ({ configuration, companyToken }) => {
  const { mutate, isLoading } = useMutationSetConfiguration();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      sender: configuration.sender,
      email: configuration.email,
    },
  });

  const onSubmit = handleSubmit((data) => {
    mutate({
      ...configuration,
      companyToken,
      email: data.email,
      sender: data.sender,
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack direction="row" gap={2} flexWrap="wrap">
        <ControllerInput
          name="email"
          control={control}
          inputProps={{
            type: "email",
            label: "Email de envio (remetente)",
            sx: { flex: 1, flexBasis: "330px" },
          }}
        />
        <ControllerInput
          name="sender"
          control={control}
          inputProps={{
            label: "Nome que deve aparecer no campo de envio (remetente)",
            sx: { flex: 1, flexBasis: "330px" },
          }}
        />
      </Stack>

      <Stack alignItems="end" justifyContent="center" mt={1}>
        <LoadingButton loading={isLoading} type="submit" variant="contained">
          Salvar
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default ConfigurationForm;
