import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { openTicket } from "services/Requests/mailbox";

const useMutationOpenTicket = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(openTicket, {
    onSuccess(_, { ticketId }) {
      toast.success("Ticket aberto com sucesso");

      queryClient.invalidateQueries(queryKeys.allChats, {
        exact: false,
      });
      queryClient.invalidateQueries([queryKeys.getTicketById, ticketId]);
    },
  });
  return mutation;
};

export default useMutationOpenTicket;
