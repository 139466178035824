import { ChipProps } from "@mui/material";
import { OrderStatus } from "types/Company";

const getOrderColorByStatus = (status: OrderStatus): ChipProps["color"] => {
  const labels: { [x in OrderStatus]: ChipProps["color"] } = {
    canceled: "error",
    paid: "info",
    pending: "warning",
    refunded: "error",
    waiting: "default",
    expired: "default",
  };

  return labels[status];
};

export default getOrderColorByStatus;
