import { FC } from "react";
import { Control, Controller } from "react-hook-form";

import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";

import { PenaltiesAndDiscountsForm } from "./PenaltiesAndDiscountsForm";
import ControllerInput from "components/ControllerInput";

interface PenaltyFieldProps {
  control: Control<PenaltiesAndDiscountsForm>;
}

const PenaltyField: FC<PenaltyFieldProps> = ({ control }) => {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 1 }}
      >
        Cobrança de Multas
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Alguns negócios cobram multa em caso de atraso nos pagamentos, configure uma automação para
        detectar esses casos. O sistema irá automaticamente ajustar os valores e detectar os casos
        pagos sem a multa.
      </Typography>

      <Stack gap={2} mt={3}>
        <ControllerInput
          control={control}
          name="penaltiesPercent"
          inputProps={{
            label: "Percentual do total da fatura a ser aplicado como multa",
            type: "number",
            inputProps: {
              step: "0.1",
            },
          }}
        />

        <Controller
          control={control}
          name={"penaltiesMora"}
          render={({ field }) => (
            <FormControlLabel
              sx={{ m: 0, height: "auto", maxWidth: "60%" }}
              control={
                <Checkbox
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  checked={field.value}
                  value={field.value}
                  inputRef={field.ref}
                />
              }
              label={
                <Typography>
                  Juros de mora{" "}
                  <Typography component="span" variant="body2" color="text.secondary">
                    (Cobrar juros por dia de atraso. 1% ao mês. Juros de mora é uma taxa percentual
                    sobre o atraso do pagamento).
                  </Typography>
                </Typography>
              }
            />
          )}
        />
      </Stack>
    </Box>
  );
};

export default PenaltyField;
