import { FC, useEffect, useState, useRef } from "react";

import {
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import { Order } from "types/SemanticTypes";
import { Transactions, TransactionsItem } from "types/Company";
import stableSort from "utils/stableSort";
import comparatorSortByProperty from "utils/comparatorSortByProperty";

import PaidBillsTableItem from "./PaidBillsTableItem";
import PrintLandscape from "components/PrintLandscape";
import SortableTableHead from "components/SortableTableHead";
import InfoToPrint from "pages/Home/components/InfoToPrint";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import TableActions from "pages/Home/components/TableActions";

type TransactionItemKeys = keyof TransactionsItem;

interface HeadCells {
  label: string;
  id: TransactionItemKeys;
  sortable?: boolean;
  tableCellProps?: TableCellProps | Record<string, string>;
}

const headCells: HeadCells[] = [
  {
    label: "Competência",
    id: "referenceDate",
    tableCellProps: { ["data-f-bold"]: "true" },
  },
  {
    label: "Pagamento",
    id: "paymentDate",
    tableCellProps: { ["data-f-bold"]: "true" },
  },
  {
    label: "Fornecedor",
    id: "participantName",
    tableCellProps: { ["data-f-bold"]: "true" },
  },
  {
    label: "Descrição",
    id: "description",
    tableCellProps: { ["data-f-bold"]: "true" },
  },
  {
    label: "Categoria",
    id: "categoryName",
    tableCellProps: { ["data-f-bold"]: "true" },
  },
  {
    label: "Conta",
    id: "companyBankAccountId",
    tableCellProps: { ["data-f-bold"]: "true" },
  },
  {
    label: "Valor",
    id: "value",
    tableCellProps: { align: "right", ["data-a-h"]: "right", ["data-f-bold"]: "true" },
  },
];

interface PaidBillsTableProps {
  transactions: Transactions;
  companyToken: string;
  totalBills?: number;
}

const PaidBillsTable: FC<PaidBillsTableProps> = ({ transactions, companyToken, totalBills }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);

  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<TransactionItemKeys>("dueDate");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortHandler = (property: TransactionItemKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (Math.ceil(transactions.items.length / rowsPerPage) > page ? 0 : page) {
      setPage(0);
    }
  }, [transactions, page, rowsPerPage]);

  return (
    <>
      <PrintLandscape>
        <TableContainer
          sx={{ mb: 1, "@media print": { "*": { fontSize: "90%" } } }}
          ref={containerRef}
        >
          <InfoToPrint />

          <Table size="small" ref={tableRef}>
            <SortableTableHead
              headCells={headCells}
              onRequestSort={(e, property) => sortHandler(property)}
              order={order}
              orderBy={orderBy}
            />

            <TableBody>
              {transactions.items.length === 0 && (
                <TableRow>
                  <TableCell colSpan={headCells.length}>Nenhum lançamento cadastrado.</TableCell>
                </TableRow>
              )}

              {stableSort(transactions.items, comparatorSortByProperty(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((transactionItem) => (
                  <PaidBillsTableItem
                    key={transactionItem.id}
                    transactionItem={transactionItem}
                    companyToken={companyToken}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PrintLandscape>

      <TablePagination
        labelRowsPerPage={"Linhas por páginas: "}
        labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={transactions.items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Divider sx={{ mb: 2, mt: 3 }} />

      {!!totalBills && (
        <Stack alignItems="end" justifyContent="end">
          <Typography>
            Total de Contas Pagas:{" "}
            <Typography component="strong" sx={{ fontWeight: 600 }}>
              {formatCurrencyBRL(totalBills)}
            </Typography>
          </Typography>
        </Stack>
      )}

      {containerRef.current && tableRef.current && (
        <TableActions
          mt={1}
          p={2}
          containerTableRef={containerRef.current}
          tableRef={tableRef.current}
          nameExcel="transacoes"
          nameTable="transacoes"
        />
      )}
    </>
  );
};

export default PaidBillsTable;
