import { FC, PropsWithChildren } from "react";
import { Stack } from "@mui/material";

const ListComponent: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <Stack
    component="li"
    sx={{ border: 1, borderColor: "divider" }}
    p={2}
    alignItems="center"
    justifyContent="space-between"
    direction="row"
  >
    {children}
  </Stack>
);

export default ListComponent;
