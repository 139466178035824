import { useAuth } from "context/Auth";
import { useCallback } from "react";
import { useQuery } from "react-query";

import queryKeys from "react-query/constants";
import { getCompaniesPost } from "services/Requests/companies";
import { Company } from "types/Company";

const useQueryCompaniesPost = (companyToken: string, search = "") => {
  const {
    user: { company },
  } = useAuth();

  const selectFn = useCallback(
    (data: Company[]) => {
      const searchClient = (name: string) => {
        if (!search.trim()) return true;

        return name.toLowerCase().search(search.toLowerCase()) !== -1;
      };

      const isEqualCompany = (c: Company) => {
        if (!company) return false;
        return c.id === company.id;
      };

      return data.filter((c) => searchClient(c.name || "") && !isEqualCompany(c));
    },
    [search, company],
  );
  const query = useQuery(queryKeys.companiesPost, () => getCompaniesPost({ companyToken }), {
    select: selectFn,
  });
  return query;
};

export default useQueryCompaniesPost;
