import { DateTime } from "luxon";
import { TransactionsItem } from "types/Company";
import { LatestTransactions } from "types/Dashboard";

const getLabelStatus = (transactionItem: TransactionsItem | LatestTransactions) => {
  const dateNow = DateTime.local().toISO();

  if (transactionItem.paymentDate) {
    return transactionItem.transactionType === "Credit" ? "Recebido" : "Pago";
  }

  if (dateNow > transactionItem.dueDate) return "Atrasado";

  return transactionItem.transactionType === "Credit" ? "A receber" : "A pagar";
};

export default getLabelStatus;
