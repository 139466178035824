import { FC } from "react";

import { Button, Stack } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import CalculatorPlan from "../CalculatorPlan";
import { ServicePlan } from "types/ServiceAndPlan";

interface CalculatorTabProps {
  onNext: () => void;
  plan: ServicePlan;
}

const CalculatorTab: FC<CalculatorTabProps> = ({ onNext, plan }) => {
  return (
    <>
      <CalculatorPlan isDefaultPlan plans={plan} />

      <Stack direction="row" justifyContent="end" alignItems="end" mt={3}>
        <Button variant="contained" endIcon={<NavigateNextIcon />} onClick={onNext}>
          Próximo passo
        </Button>
      </Stack>
    </>
  );
};

export default CalculatorTab;
