import { Box, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { CompanyInformationAddress } from "types/Company";

interface Address extends CompanyInformationAddress {
  countyAsString: string | null;
}

interface AddressConsultProps {
  address: Address;
}

const AddressConsult: FC<AddressConsultProps> = ({ address }) => {
  const addressWithlabels = useMemo(() => {
    const addressWithLabels: { [x: string]: string } = {};

    if (address.street) addressWithLabels.Logradouro = address.street;
    if (address.streetNumber) addressWithLabels["Número"] = address.streetNumber.toString();
    if (address.complement) addressWithLabels.Complemento = address.complement;
    if (address.neighborhood) addressWithLabels.Bairro = address.neighborhood;
    if (address.zipCode) addressWithLabels.Cep = address.zipCode;
    if (address.countyAsString && address.state)
      addressWithLabels.Estado = `${address.countyAsString} - ${address.state}`;

    return addressWithLabels;
  }, [address]);

  return (
    <>
      <Typography variant="h6" fontWeight={400} sx={{ mt: 2 }}>
        Endereço
      </Typography>
      <Box mt={1} border={1} borderColor="divider" p={2}>
        {Object.keys(addressWithlabels).length > 0 ? (
          <>
            {Object.entries(addressWithlabels).map((addressEntry, index) => (
              <Typography variant="body1" key={index}>
                {addressEntry[0]}: {addressEntry[1]}
              </Typography>
            ))}
          </>
        ) : (
          "Endereço não informado"
        )}
      </Box>
    </>
  );
};

export default AddressConsult;
