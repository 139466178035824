import { FC } from "react";

import { TableContainer, TableHead, TableRow, TableCell, Table, TableBody } from "@mui/material";
import SubscriptionTableItem from "./SubscriptionTableItem";

import { Subscription } from "types/Subscription";

interface SubscriptionsTableProps {
  subscriptionList: Subscription[];
}

const SubscriptionsTable: FC<SubscriptionsTableProps> = ({ subscriptionList }) => {
  return (
    <TableContainer sx={{ mt: 2, overflowY: "auto" }}>
      <Table sx={{ minWidth: "700px" }}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell>Plano</TableCell>
            <TableCell>Início</TableCell>
            <TableCell>Vencimento</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptionList.length === 0 && (
            <TableRow>
              <TableCell colSpan={6}>Nenhum plano</TableCell>
            </TableRow>
          )}

          {subscriptionList.map((subscription) => (
            <SubscriptionTableItem key={subscription.id} subscription={subscription} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubscriptionsTable;
