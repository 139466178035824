import { FC } from "react";
import useQueryGetTransaction from "pages/Home/pages/MonthClosure/hooks/useQueryGetTransaction";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { BASE_URL_DOWNLOAD_DOCUMENT } from "utils/constants";

interface ReceiptsProps extends Omit<DialogProps, "onClose" | "id"> {
  companyToken: string;
  id: number;
  onClose: () => void;
}

const Receipts: FC<ReceiptsProps> = ({ companyToken, id, onClose, ...props }) => {
  const { data: transaction, isLoading } = useQueryGetTransaction(companyToken, id);

  return (
    <Dialog onClose={onClose} {...props} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={onClose}>
        Comprovante(s)
        {transaction && (
          <Typography color="text.secondary" variant="body2">
            {transaction.description}
          </Typography>
        )}
      </DialogTitleWithCloseButton>

      <DialogContent>
        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={200}>
            <CircularProgress />
          </Stack>
        )}

        <Stack gap={0.5}>
          {transaction &&
            transaction.documents.map((document) => (
              <Button
                color="info"
                fullWidth
                target="_blank"
                href={`${BASE_URL_DOWNLOAD_DOCUMENT}?id=${document.downloadToken}&fullPath=/COMPROVANTES+DE+PAGAMENTO&preview=true`}
                variant="outlined"
                startIcon={<DownloadIcon />}
                key={document.downloadToken}
                sx={{ textTransform: "none" }}
              >
                {document.name}
              </Button>
            ))}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Receipts;
