import { MutationFunction } from "react-query";
import {
  CategoryWithRelease,
  Company,
  CompanyBankAccounts,
  CompanyDocumentDriveResponse,
  CompanyDocumentsDrive,
  CompanyInformation,
  CompanyParticipants,
  CompanyPartner,
  CompanyProfile,
  CompanyProfileResponse,
  DateTypeFilter,
  FinancialCategory,
  FinancialSummary,
  GetGlobalCategoriesByGroupResponse,
  ImportOFXResponse,
  IndividualCompanyParticipant,
  InvoiceConfig,
  MonthClosure,
  PaymentInfo,
  RoleCompanyParticipant,
  TemplateAccountanting,
  TemplateFinancial,
  Transaction,
  TransactionMatch,
  Transactions,
  TransactionsItem,
  TransactionsNormalize,
} from "types/Company";
import { TransactionType } from "types/CommonData";

import { ISODate } from "types/SemanticTypes";
import { DeepPartial } from "types/Utils";
import getFormData from "utils/objectToFormData";

import api from "services/api";
import endpoints from "../endpoints";
import publicApi from "../publicApi";
import { getFileExtension } from "pages/Home/pages/Drive/utils/fileType";

interface GetCompanyLastAccessPayload {
  token: string;
}

export const getCompanyLastAccess = async ({
  token,
}: GetCompanyLastAccessPayload): Promise<Company> => {
  const { data } = await publicApi.get(endpoints.companies.lastAccess, {
    headers: {
      authorization: `Osayk ${token}`,
    },
  });

  return data[0];
};

export interface UpdateVerifyAccountPayload {
  ag: string;
  agDg: string;
  bank: string;
  cc: string;
  ccDg: string;
  payerCompanyId: number;
  personType: string;
  subdomain: string;
  op?: string;
}

export const updateVerifyAccount = async (payload: UpdateVerifyAccountPayload) => {
  const { data } = await api.post(endpoints.companies.updateVerifyAccount, payload);
  return data;
};

export interface GetProfilesPayload {
  subdomain?: string;
  companyToken?: string;
}

export const getProfiles = async (payload: GetProfilesPayload): Promise<CompanyProfile[]> => {
  const { data } = await api.post<CompanyProfileResponse[]>(endpoints.companies.profiles, payload);

  const normalizedCompanyProfiles: CompanyProfile[] = data.map((profile) => {
    return {
      ...profile,
      areas: profile.areas ? profile.areas.map((area) => area.value) : [],
    };
  });
  return normalizedCompanyProfiles;
};

export interface GetCompanyPayload {
  companyToken: string;
}

export const getCompany = async (payload: GetCompanyPayload): Promise<CompanyInformation> => {
  const { data } = await api.post<CompanyInformation>(endpoints.companies.company, payload);
  return data;
};

export interface GetPaymentInfoProfilePayload {
  companyToken: string;
  subdomain: string;
  isAdmin: boolean;
}

export const getPaymentInfoProfile = async (
  payload: GetPaymentInfoProfilePayload,
): Promise<PaymentInfo> => {
  const endpoint = payload.isAdmin
    ? endpoints.companies.paymentInfo
    : endpoints.companies.paymentInfoProfile;
  const { data } = await api.post<PaymentInfo>(endpoint, payload);
  return data;
};

export interface GetPaymentInfoPayload {
  companyToken: string;
  subdomain: string;
}

export const getPaymentInfo = async (
  payload: GetPaymentInfoPayload,
): Promise<PaymentInfo["currentMethod"]> => {
  const { data } = await api.post<PaymentInfo>(endpoints.companies.paymentInfo, payload);
  return data.currentMethod;
};

export interface UpdateCompanyData extends CompanyInformation {
  subdomain: string;
}

export const updateCompanyData = async (payload: UpdateCompanyData): Promise<boolean> => {
  const { data } = await api.post(endpoints.companies.update, payload);
  return data;
};

export interface GetCompanyPartnersPayload {
  companyToken: string;
}

export const getCompanyPartners = async (
  payload: GetCompanyPartnersPayload,
): Promise<CompanyPartner[]> => {
  const { data } = await api.post<CompanyPartner[]>(endpoints.companies.companyPartners, payload);
  return data;
};

export interface UpdateCompanyPartnersPaylaod {
  companyToken: string;
  partners?: DeepPartial<CompanyPartner>[];
  partner?: DeepPartial<CompanyPartner>;
}

export const updateCompanyPartners = async (payload: UpdateCompanyPartnersPaylaod) => {
  const endpoint = payload.partner
    ? endpoints.companies.updateCompanyPartner
    : endpoints.companies.updateCompanyPartners;

  await api.post(endpoint, payload);
};

const paymentMethodIds: { [x in SetPaymentMethodProfilePayload["paymentMethod"]]: number } = {
  bankslip: 1,
  card: 2,
};

export interface SetPaymentMethodProfilePayload {
  companyToken: string;
  description: "Cartão" | "Boleto";
  paymentMethod: "card" | "bankslip";
  paymentToken?: string;
  subdomain: string;
}

export const setPaymentMethodProfile = async (payload: SetPaymentMethodProfilePayload) => {
  await api.post(endpoints.companies.paymentMethodProfile, {
    ...payload,
    paymentMethod: paymentMethodIds[payload.paymentMethod],
  });
};

export interface SetPaymentMethodPayload extends SetPaymentMethodProfilePayload {
  typeService?: number;
  planId?: number;
  planIdentifier?: string;
  value?: number;
}

export const setPaymentMethod = async (payload: SetPaymentMethodPayload) => {
  await api.post(endpoints.companies.paymentMethod, {
    ...payload,
    paymentMethod: paymentMethodIds[payload.paymentMethod],
  });
};

export interface UploadDocumentsApproveDrivePayload {
  companyToken: string;
  fullPath: "EMPRESA/CONTRATO SOCIAL" | "EMPRESA/OUTROS DOCUMENTOS" | "SÓCIOS";
  file: File;
}

export const uploadDocumentsApproveDrive = async (payload: UploadDocumentsApproveDrivePayload) => {
  const { data } = await api.post(
    endpoints.companies.uploadDocumentsApproveDrive,
    getFormData(payload),
  );
  return data;
};

export interface UpdateAccountAutomaticPayload {
  ag: string;
  agDg: string;
  bank: string;
  cc: string;
  ccDg: string;
  companyToken: string;
  personType: string;
}

export const updateAccountAutomatic = async (payload: UpdateAccountAutomaticPayload) => {
  await api.post(endpoints.companies.updateAccountAutomatic, payload);
};

export const getTemplatesFinancialCategories = async (): Promise<TemplateFinancial[]> => {
  const { data } = await api.post<TemplateFinancial[]>(
    endpoints.companies.templatesFinancialCategories,
  );
  return data;
};

export const getTemplatesAccountanting = async (): Promise<TemplateAccountanting[]> => {
  const { data } = await api.post<TemplateAccountanting[]>(
    endpoints.companies.templatesAccountanting,
  );
  return data;
};

export interface CreateTemplateFinancialCategoryPayload {
  groupChartOfAccountsToFromId: number;
  name: string;
  id?: number;
}

export const createTemplateFinancialCategory = async (
  payload: CreateTemplateFinancialCategoryPayload,
): Promise<TemplateFinancial> => {
  const { data } = await api.post<TemplateFinancial>(
    endpoints.companies.createTemplateFinancialCategory,
    payload,
  );
  return data;
};

export interface CreateTemplateAccountantingPayload {
  name: string;
  mask: string;
  id?: number;
}

export const createTemplateAccountanting = async (payload: CreateTemplateAccountantingPayload) => {
  await api.post(endpoints.companies.createTemplateAccountanting, payload);
};

export interface CopyTemplateFinancialPayload {
  id: number;
}

export const copyTemplateFinancial = async (payload: CopyTemplateFinancialPayload) => {
  await api.post(endpoints.companies.copyTemplateFinincialCategory, payload);
};

export interface DeleteTemplateFinancialPayload {
  id: number;
}

export const deleteTemplateFinancial = async (payload: DeleteTemplateFinancialPayload) => {
  await api.post(endpoints.companies.deleteTemplateFinancialCategory, payload);
};

export interface DeleteTemplateAccountantingPayload {
  id: number;
}

export const deleteTemplateAccountanting = async (payload: DeleteTemplateAccountantingPayload) => {
  await api.post(endpoints.companies.deleteTemplateAccountanting, payload);
};

export interface GetCompanyParticipantsPayload {
  companyToken: string;
  role: RoleCompanyParticipant | "";
}

export const getCompanyParticipants = async (
  payload: GetCompanyParticipantsPayload,
): Promise<CompanyParticipants[]> => {
  const { data } = await api.post<CompanyParticipants[]>(
    endpoints.companies.companyParticipants,
    payload,
  );
  return data;
};

export interface CreateCompanyParticipantPayload {
  companyToken: string;
  registerAsSupplier: boolean;
  name: string;
  role: RoleCompanyParticipant;
  address?: {
    complement?: string;
    countyId?: string;
    neighborhood?: string;
    state?: string;
    street?: string;
    streetNumber?: string;
    zipCode?: string;
  };
  complement?: string;
  countyId?: string;
  neighborhood?: string;
  state?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  bankAccount?: {
    account?: string | null;
    agency?: string | null;
    bankNumber?: number | null;
  };
  account?: string;
  agency?: string;
  bankNumber?: number;
  cpfcnpj?: string;
  email?: string;
  municipalEnrollment?: string;
  phone?: string;
  stateRegistration?: string;
}

export const createCompanyParticipants = async ({
  registerAsSupplier,
  role,
  ...payload
}: CreateCompanyParticipantPayload) => {
  await api.post(endpoints.companies.createCompanyParticipants, {
    ...payload,
    roles: registerAsSupplier ? 3 : role,
    foreign: !payload.cpfcnpj,
  });
};

export interface UpdateCompanyParticipantPayload extends CreateCompanyParticipantPayload {
  id: number;
}

export const updateCompanyParticipant = async ({
  registerAsSupplier,
  role,
  ...payload
}: UpdateCompanyParticipantPayload) => {
  await api.post(endpoints.companies.updateCompanyParticipant, {
    ...payload,
    roles: registerAsSupplier ? 3 : role,
    foreign: !payload.cpfcnpj,
  });
};

export interface ValidateParticipantCPFCNPJPayload {
  companyToken: string;
  cpfcnpj: string;
}

export const validateParticipantCPFCNPJ = async (payload: ValidateParticipantCPFCNPJPayload) => {
  await api.post(endpoints.companies.validateParticipantCPFCNPJ, payload);
};

export interface DeleteParticipantPayload {
  companyToken: string;
  participantId: number;
}

export const deleteParticipant = async (payload: DeleteParticipantPayload) => {
  await api.post(endpoints.companies.deleteParticipant, payload);
};

export interface GetCompanyParticipantPayload {
  companyToken: string;
  participantId: number;
}

export const getCompanyParticipant = async (
  payload: GetCompanyParticipantPayload,
): Promise<IndividualCompanyParticipant> => {
  const { data } = await api.post<IndividualCompanyParticipant>(
    endpoints.companies.getCompanyParticipant,
    payload,
  );
  return data;
};

export interface GetCompanyBankAccountsPayload {
  companyToken: string;
}

export const getCompanyBankAccounts = async (
  payload: GetCompanyBankAccountsPayload,
): Promise<CompanyBankAccounts[]> => {
  const { data } = await api.post<CompanyBankAccounts[]>(
    endpoints.companies.getCompanyBankAccounts,
    payload,
  );
  return data;
};

export interface CreateCompanyBankAccountPayload {
  alias: string;
  bankAccountType: string;
  companyToken: string;
  dateInitialBalance: string;
  initialBalance: number;
  initialBalanceType: string;
  account?: string;
  agency?: string;
  bankNumber?: string;
  description?: string;
}

export const createCompanyBankAccount = async (payload: CreateCompanyBankAccountPayload) => {
  await api.post(endpoints.companies.createCompanyBankAccount, payload);
};

export interface UpdateCompanyBankAccountPayload extends CreateCompanyBankAccountPayload {
  token: string;
}

export const updateCompanyBankAccount = async (payload: UpdateCompanyBankAccountPayload) => {
  await api.post(endpoints.companies.updateCompanyBankAccount, payload);
};

export interface DeleteCompanyBankAccountPayload {
  id: string;
}

export const deleteCompanyBankAccount = async (payload: DeleteCompanyBankAccountPayload) => {
  await api.get(endpoints.companies.deleteCompanyBankAccount, {
    params: payload,
  });
};

export interface GetFinancialCategoriesPayload {
  companyToken: string;
}

export const getFinancialCategories = async (
  payload: GetFinancialCategoriesPayload,
): Promise<FinancialCategory[]> => {
  const { data } = await api.post<FinancialCategory[]>(
    endpoints.companies.getFinancialCategories,
    payload,
  );
  return data;
};

export interface CreateCategoryPayload {
  companyToken: string;
  globalCategoryId: number;
  name: string;
}

export const createCategory = async (
  payload: CreateCategoryPayload,
): Promise<FinancialCategory> => {
  const { data } = await api.post(endpoints.companies.createCategory, payload);
  return data;
};

export interface DeleteCategoryPayload {
  companyToken: string;
  categoryId: number;
}

export const deleteCategory = async (payload: DeleteCategoryPayload) => {
  await api.post(endpoints.companies.deleteCategory, payload);
};

export interface UpdateCategoryPayload {
  categoryId: number;
  color: null;
  companyToken: string;
  costCenterId: null;
  creditAccountCashAsset: null;
  creditAccountProvision: null;
  debitAccountCashAsset: null;
  debitAccountProvision: null;
  globalCategoryId: number | null;
  isVisibleFinancialReport: boolean;
  name: string;
  order: number;
  parentCategoryId: null;
}

export const updateCategory = async (payload: UpdateCategoryPayload) => {
  await api.post(endpoints.companies.updateCategory, payload);
};

export interface GetCompanyPartnerDetailPayload {
  partner: DeepPartial<CompanyPartner>;
}

export const getCompanyPartnerDetail = async (
  payload: GetCompanyPartnerDetailPayload,
): Promise<CompanyPartner> => {
  const { data } = await api.post(endpoints.companies.getCompanyPartnerDetail, payload);
  return data;
};

export interface CreateProfilePayload {
  areas: number[];
  name: string;
  subdomain?: string;
  companyToken?: string;
}

export const createProfile = async ({ areas, ...payload }: CreateProfilePayload) => {
  const normalizeAreas = areas.map((area) => ({
    value: area,
  }));

  await api.post(endpoints.companies.createProfile, { areas: normalizeAreas, ...payload });
};

export interface UpdateProfilePayload extends CreateProfilePayload {
  id: number;
}

export const updateProfile = async ({ areas, ...payload }: UpdateProfilePayload) => {
  const normalizeAreas = areas.map((area) => ({
    value: area,
  }));

  await api.post(endpoints.companies.updateProfile, { areas: normalizeAreas, ...payload });
};

export interface GetOpenMonthsPayload {
  companyToken: string;
}

export const getOpenMonths = async (payload: GetOpenMonthsPayload): Promise<string[]> => {
  const { data } = await api.post<string[]>(endpoints.companies.getOpenMonths, payload);
  return data;
};

export interface GetMonthClosurePayload {
  companyToken: string;
  dateReferenceClose: string;
}

export const getMonthClosure = async (payload: GetMonthClosurePayload): Promise<MonthClosure> => {
  const { data } = await api.post<MonthClosure>(endpoints.companies.getMonthClosure, payload);
  return data;
};

export interface GetTransactionPayload {
  companyToken: string;
  id: number;
}

export const getTransaction = async (payload: GetTransactionPayload): Promise<Transaction> => {
  const { data } = await api.post<Transaction>(endpoints.companies.getTransaction, payload);
  return data;
};

export interface CloseMonthPayload {
  companyToken: string;
  dateReferenceClose: ISODate;
}

export const closeMonth = async (payload: CloseMonthPayload) => {
  await api.post(endpoints.companies.closeMonth, payload);
};

export interface ReopenMonthPayload {
  companyToken: string;
  date: ISODate;
}

export const reopenMonth = async (payload: ReopenMonthPayload) => {
  await api.post(endpoints.companies.reopenMonth, payload);
};

export interface GetFinancialSummaryPayload {
  CompanyToken: string;
  Year: number;
}

export const getFinancialSummary = async (
  payload: GetFinancialSummaryPayload,
): Promise<FinancialSummary> => {
  const { data } = await api.post<FinancialSummary>(
    endpoints.companies.getFinancialSummary,
    payload,
  );
  return data;
};

export type TransactionAction = "FC" | "RE" | "AR" | "AP" | "PA" | "TM" | "PR" | "APR" | "AP";

export interface GetTransactionsPayload {
  companyToken: string;
  endDate: ISODate;
  startDate: ISODate;
  action: TransactionAction | null;
  bankAccountId?: null | number;
  dateType?: DateTypeFilter | null;
  categoryId?: number | null;
  globalCategoryId?: number | null;
}

export const getTransactions = async (payload: GetTransactionsPayload): Promise<Transactions> => {
  const { data } = await api.post<Transactions>(endpoints.companies.getTransactions, payload);
  return data;
};

export interface GetTransactionsWithPaginationPayload extends GetTransactionsPayload {
  currentPage: number;
  pageSize: number;
  term: string;
}

export const getTransactionsWithPagination = async (
  payload: GetTransactionsWithPaginationPayload,
): Promise<TransactionsNormalize> => {
  const { data } = await api.post<TransactionsNormalize>(
    endpoints.companies.getTransactionsWithPagination,
    payload,
  );
  return {
    ...data,
    items: data.items.map((item) => ({
      ...item,
      recipient: item.partnerName || item.participantName,
    })),
  };
};

export interface ParcialTransactionPayload {
  companyBankAccountId: number;
  companyParticipantId: number;
  companyToken: string;
  description: string;
  globalCategoryId: number;
  paymentDate: ISODate;
  transactionId: number;
  value: number;
  categoryId?: number;
}

export const parcialTransaction = async (payload: ParcialTransactionPayload) => {
  await api.post(endpoints.companies.parcialTransaction, payload);
};

export interface GetInvoiceConfigPayload {
  companyToken: string;
}

export const getInvoideConfig = async (
  payload: GetInvoiceConfigPayload,
): Promise<InvoiceConfig> => {
  const { data } = await api.post<InvoiceConfig>(endpoints.companies.getInvoiceConfig, payload);
  return data;
};

export interface InvoiceItemCreateTransaction {
  aliquota: number;
  cnae?: string;
  cofins: number;
  csll: number;
  describeInvoice: string;
  irop: number;
  irpj: number;
  isManual: number;
  iss: number;
  issWithHeld: number;
  lc116: string;
  notes: string;
  outros: number;
  pis: number;
  quantity: number;
  sendInvoiceLater: number;
  serviceCodeId: null;
  serviceInvoiceCode: string;
  serviceInvoiceId: number;
  typeTaxation: string;
  unitValue: number;
}

export interface CreateTransactionPayload {
  isPartner?: boolean;
  repeat?: "noParcel" | "parcelForever" | "parcel";
  dueDate?: string;
  referenceDate?: string;
  partnerParticipantId?: number | undefined;
  companyParticipantId?: number;
  companyBankAccountId?: number | "";
  description: string;
  value: number;
  retainedValue?: number;
  globalCategoryId: number | null;
  categoryId?: number | "";
  periodicity: number;
  parcel?: string | number;
  createBilling?: number;
  sendInvoiceLater?: string;
  daysBefore?: string;
  transactionType: string;
  isInvoice?: boolean;
  companyToken: string;
  paymentDate?: string;
  aliquota?: number;
  cnae?: string;
  cofins?: number;
  csll?: number;
  description2?: string;
  invoiceItems?: InvoiceItemCreateTransaction[];
  irop?: number;
  irpj?: number;
  isManual?: boolean;
  iss?: number;
  issWithHeld?: number;
  issueDate?: string;
  lc116?: string;
  notes?: string;
  outros?: number;
  pis?: number;
  serviceCode?: string;
  serviceCodeId?: number;
  state?: string;
  docNumber?: string;
  descDocument?: string;
}

export const createTransaction: MutationFunction<Transaction, CreateTransactionPayload> = async (
  payload,
) => {
  const { data } = await api.post(endpoints.companies.createTransaction, payload);
  return data;
};

export interface UploadReceiptPayload {
  companyToken: string;
  transactionId: number;
  files: File[] | FileList;
}

export const uploadReceipt = async ({
  files,
  companyToken,
  transactionId,
}: UploadReceiptPayload) => {
  files = Array.from(files);

  const formData = new FormData();
  formData.append("companyToken", companyToken);
  formData.append("transactionId", transactionId.toString());

  files.forEach((file) => {
    formData.append("file", file);
  });

  await api.post(endpoints.companies.uploadReceipt, formData);
};

export interface DeleteTransactionPayload {
  companyToken: string;
  id: number;
}

export const deleteTransaction = async (payload: DeleteTransactionPayload) => {
  await api.post(endpoints.companies.deleteTransaction, payload);
};

export interface DeleteRecurrentTransactionPayload extends DeleteTransactionPayload {
  allRecurrents: 0 | 1;
}

export const deleteRecurrentTransaction = async (payload: DeleteRecurrentTransactionPayload) => {
  await api.post(endpoints.companies.deleteRecurrentTransaction, payload);
};

export interface DeleteTransactionsPayload extends Omit<DeleteTransactionPayload, "id"> {
  ids: number[];
}

export const deleteTransactions = async (payload: DeleteTransactionsPayload) => {
  await api.post(endpoints.companies.deleteTransactions, payload);
};

export interface MergeDeleteTransactionPayload
  extends Omit<DeleteRecurrentTransactionPayload, "allRecurrents" | "id">,
    Omit<DeleteTransactionsPayload, "ids"> {
  allRecurrents?: 0 | 1;
  ids?: number[];
  id?: number;
}

export const mergeDeleteTransaction = async ({
  allRecurrents,
  companyToken,
  id,
  ids,
}: MergeDeleteTransactionPayload) => {
  const runFn = async () => {
    if (allRecurrents === undefined && ids) {
      await deleteTransactions({ companyToken, ids });
    }

    if (allRecurrents === undefined && id) {
      await deleteTransaction({ companyToken, id });
    }

    if (allRecurrents && id) {
      await deleteRecurrentTransaction({ allRecurrents, companyToken, id });
    }
  };

  await runFn();
};

export interface SettleTransactionsPayload {
  bankId: number;
  companyToken: string;
  settlementDate: ISODate;
  transactionIds: number[];
}

export const settleTransactions = async (payload: SettleTransactionsPayload) => {
  await api.post(endpoints.companies.settleTransactions, payload);
};

export interface GetBalancePayload {
  companyToken: string;
  id: number;
}

export const getBalance = async (payload: GetBalancePayload): Promise<FinancialSummary> => {
  const { data } = await api.post<FinancialSummary>(endpoints.companies.getBalance, payload);
  return data;
};

export interface UpdateTransactionPayload extends CreateTransactionPayload {
  id: number;
  UpdateType: number;
}

export const updateTransaction = async (
  payload: UpdateTransactionPayload,
): Promise<Transaction> => {
  const { data } = await api.post<Transaction>(endpoints.companies.updateTransaction, payload);
  return data;
};

export interface ImportOFXPayload {
  bankId: number;
  companyToken: string;
  file: File;
}

export const importOFX = async (payload: ImportOFXPayload): Promise<ImportOFXResponse> => {
  const formData = new FormData();
  formData.append("bankId", payload.bankId.toString());
  formData.append("companyToken", payload.companyToken);
  formData.append("file", payload.file);

  const { data } = await api.post<ImportOFXResponse>(endpoints.companies.importOFX, formData);
  return data;
};

export interface GetTransactionsSearchPayload {
  action: TransactionAction | null;
  companyToken: string;
  endDate: ISODate;
  startDate: ISODate;
  term: string;
  bankAccountId?: number;
}

export const getTransactionsSearch = async (
  payload: GetTransactionsSearchPayload,
): Promise<TransactionsItem[]> => {
  const { data } = await api.post<TransactionsItem[]>(endpoints.companies.getTransactionsSearch, {
    ...payload,
    categoryId: 0,
    globalCategoryId: 0,
    sort: "asc",
  });
  return data;
};

export interface CreateOrUpdateTransactionOFXSearchPayload {
  companyToken: string;
  oFXDescription: string;
  ofxId: string;
  paymentDate: ISODate;
  transactions: TransactionsItem[];
  value: number;
}

export const createOrUpdateTransactionOFXSearch = async (
  payload: CreateOrUpdateTransactionOFXSearchPayload,
) => {
  await api.post(endpoints.companies.createOrUpdateTransactionOFXSearch, payload);
};

export interface CreateOrUpdateTransactionOFXPayload {
  companyToken: string;
  oFXDescription: string;
  ofxId: string;
  paymentDate: ISODate;
  transactions?: TransactionsItem[];
  value: number;
  categoryId?: null | number;
  companyBankAccountId?: number | null;
  companyParticipantId?: null | number;
  companyTransferBankAccountId?: number;
  description?: string;
  descriptionOFX?: string;
  dueDate?: ISODate;
  dueDate2?: ISODate;
  globalCategoryId?: number | null;
  isInvoice?: boolean;
  issueDate?: ISODate;
  ofxDescription?: string;
  ofxIgnore?: boolean;
  partnerParticipantId?: null | number;
  transactionId?: number | null;
  transactionType?: TransactionType;
  id?: number | string;
}

export const createOrUpdateTransactionOFX = async (
  payload: CreateOrUpdateTransactionOFXPayload,
): Promise<Transaction> => {
  const { data } = await api.post<Transaction>(
    endpoints.companies.createOrUpdateTransactionOFX,
    payload,
  );
  return data;
};

export interface GetTransactionsMatchPayload {
  companyToken: string;
  date: ISODate;
  id: string;
  value: number;
}

export const getTransactionsMatch = async (
  payload: GetTransactionsMatchPayload,
): Promise<TransactionMatch> => {
  const { data } = await api.post<TransactionMatch>(
    endpoints.companies.getTransactionsMatch,
    payload,
  );
  return data;
};

export interface GetGlobalCategoriesByGroupPayload {
  groupId: number;
}

export const getGlobalCategoriesByGroup = async (
  payload: GetGlobalCategoriesByGroupPayload,
): Promise<GetGlobalCategoriesByGroupResponse> => {
  const { data } = await api.post<GetGlobalCategoriesByGroupResponse>(
    endpoints.companies.getGlobalCategoriesByGroup,
    payload,
  );
  return data;
};

export interface CreateFinancialCategoryPayload {
  groupId: number;
  name: string;
  nature?: "debit" | "credit";
  parentCategoryId?: number;
}

export const createFinancialCategory = async (payload: CreateFinancialCategoryPayload) => {
  await api.post(endpoints.companies.createFinancialCategory, payload);
};

export interface UpdateFinancialCategoryPayload {
  groupId: number;
  id: number;
  isVisibleFinancialReport: boolean;
  isPartnerActivity?: boolean;
  name: string;
  nature?: "debit" | "credit";
  parentId?: null | number;
  parentCategoryId?: number;
  type: "container" | "item";
  creditCx?: null | string;
  creditProv?: null | string;
  debitCx?: null | string;
  debitProv?: null | string;
  column?: unknown[];
  dreId?: null | number;
}

export const updateFinancialCategory = async (payload: UpdateFinancialCategoryPayload) => {
  if (payload.type === "container") {
    payload = {
      ...payload,
      column: [[]],
    };
  }

  await api.post(endpoints.companies.updateFinancialCategory, payload);
};

export interface DeleteFinancialCategoryPayload {
  id: number;
}

export const deleteFinancialCategory = async (payload: DeleteFinancialCategoryPayload) => {
  await api.post(endpoints.companies.deleteFinancialCategory, payload);
};

export interface GetCompanyDocumentsDrivePayload {
  companyToken: string;
  contentType?: null | ".doc" | ".pdf" | ".jpg" | ".txt";
  fileName?: string;
  parentId?: string | null;
  endDate?: null | ISODate;
  startDate?: null | ISODate;
}

export const getCompanyDocumentsDrive = async ({
  companyToken,
  contentType,
  endDate,
  fileName,
  parentId,
  startDate,
}: GetCompanyDocumentsDrivePayload): Promise<CompanyDocumentsDrive> => {
  const PATH_SEPARATOR = "||||";
  const { data } = await api.post<CompanyDocumentDriveResponse[]>(
    endpoints.companies.getCompanyDocumentsDrive,
    {
      companyToken,
      contentType: contentType || "",
      fileName: fileName || "",
      endDate: endDate || null,
      parentId: parentId || null,
      startDate: startDate || null,
    },
  );

  return data.map((d) => ({
    ...d,
    typeNormalize: getFileExtension(d.name),
    fullPath: PATH_SEPARATOR + d.name,
  }));
};

export interface CreateFolderDrivePayload {
  companyToken: string;
  fileName: string;
  parentId?: string;
}

export const createFolderDrive = async (payload: CreateFolderDrivePayload) => {
  await api.post(endpoints.companies.createFolderDrive, payload);
};

export interface DeleteDocumentDrivePayload {
  documentToken: string;
  fullPath: string;
}

export const deleteDocumentDrive = async (payload: DeleteDocumentDrivePayload) => {
  await api.post(endpoints.companies.deleteDocumentDrive, payload);
};

export interface UploadDocumentsDrivePayload {
  companyToken: string;
  parentId?: string;
  value?: number;
  referenceDate: string;
  dueDate?: string;
  fileList: FileList | File[];
}

export const uploadDocumentsDrive = async ({
  companyToken,
  parentId = "",
  value,
  referenceDate,
  dueDate,
  fileList,
}: UploadDocumentsDrivePayload) => {
  const formData = new FormData();
  formData.append("companyToken", companyToken);
  formData.append("parentId", parentId);
  if (dueDate) formData.append("dueDate", dueDate);
  formData.append("value", value?.toString() || "");
  formData.append("referenceDate", referenceDate);

  Array.from(fileList).forEach((file, index) => {
    formData.append(`file-${index}`, file);
  });

  await api.post(endpoints.companies.uploadDocumentsDrive, formData);
};

export interface RenameAndMoveDocumentDrivePayload {
  destinationFileName: string;
  destinationFullPath: string;
  documentToken: string;
  dueDate?: string;
  fullPath: string;
  referenceDate: string;
  value?: number;
  parentId?: string;
}

export const renameAndMoveDocumentDrive = async (payload: RenameAndMoveDocumentDrivePayload) => {
  await api.post(endpoints.companies.renameAndMoveDocumentDrive, {
    ...payload,
    value: payload?.value ?? "",
    dueDate: payload?.dueDate ?? "",
  });
};

export interface GetCompaniesPostPayload {
  companyToken: string;
}

export const getCompaniesPost = async (payload: GetCompaniesPostPayload): Promise<Company[]> => {
  const { data } = await api.post<Company[]>(endpoints.companies.getCompaniesPost, payload);
  return data;
};

export interface UpdateLastAccessPayload {
  companyToken: string;
  id: number;
}

export const updateLastAccess = async (payload: UpdateLastAccessPayload) => {
  await api.post(endpoints.companies.updateLastAccess, payload);
};

export type UpdateCompanyProfilePayload = UpdateCompanyData;

export const updateCompanyProfile = async (
  payload: UpdateCompanyProfilePayload,
): Promise<boolean> => {
  const { data } = await api.post(endpoints.companies.updateCompanyProfile, payload);
  return data;
};

export const getTemplatesToFrom = async (): Promise<TemplateAccountanting[]> => {
  const { data } = await api.post<TemplateAccountanting[]>(endpoints.companies.getTemplatesToFrom);
  return data;
};

export interface GetCategoriesWithReleasesPayload {
  companyToken: string;
}

export const getCategoriesWithReleases = async (
  payload: GetCategoriesWithReleasesPayload,
): Promise<CategoryWithRelease> => {
  const { data } = await api.post<CategoryWithRelease>(
    endpoints.companies.getCategoriesWithReleases,
    payload,
  );
  return data;
};

export interface UpdateCompanyBankAccountAccountantingPayload extends CompanyBankAccounts {
  companyToken: string;
}

export const updateCompanyBankAccountAccountanting = async (
  payload: UpdateCompanyBankAccountAccountantingPayload,
) => {
  await api.post(endpoints.companies.updateCompanyBankAccountAccountanting, payload);
};

export interface ResendPaymentMethodPayload {
  companyToken: string;
}

export const resendPaymentMethod = async (payload: ResendPaymentMethodPayload) => {
  await api.post(endpoints.companies.resendPaymentMethod, payload);
};

export interface ChangeOwnerOsaykAccountantPayload {
  ownerId: number;
  payerCompanyId: number;
}

export const changeOwnerOsaykAccountant = async (payload: ChangeOwnerOsaykAccountantPayload) => {
  await api.post(endpoints.companies.changeOwnerOsaykAccountant, payload);
};

export interface GetInvoicesPayload {
  companyToken: string;
  startDate: ISODate;
  endDate: ISODate;
}

export const getInvoices = async (payload: GetInvoicesPayload): Promise<Transactions> => {
  const { data } = await api.post<Transactions>(endpoints.companies.getInvoices, payload);
  return data;
};

export interface CreateTransactionAccountantPayload {
  companyParticipantId?: number;
  transactionType: "Credit" | "Debt";
  dueDate: string;
  paymentDate: string;
  repeat: "noParcel";
  periodicity: string;
  isPartner: boolean;
  serviceOtherState: 1 | 0;
  companyBankAccountId: null;
  description: string;
  value: number;
  isInvoice: boolean;
  iss: number;
  irpj: number;
  irop: number;
  cofins: number;
  pis: number;
  csll: number;
  outros: number;
  aliquota: number;
  serviceCode: string;
  cnae: string;
  lc116: string;
  isManual: boolean;
  issWithHeld: number;
  invoiceItems: {
    serviceCodeId: null;
    quantity: 1;
    unitValue: number;
    typeTaxation: "";
    issWithHeld: number;
    iss: number;
    irpj: number;
    irop: number;
    pis: number;
    cofins: number;
    csll: number;
    outros: number;
    aliquota: number;
    serviceInvoiceId: number;
    describeInvoice: string;
    serviceInvoiceCode: string;
    sendInvoiceLater: number;
    serviceCity?: string;
    serviceUF?: string;
    cnae: string;
    lc116: string;
    isManual: 1 | 0;
    serviceOtherState: number;
  }[];
  companyToken: string;
  issueDate: string;
  serviceCity?: string;
  serviceUF?: string;
  notes: string;
}

export const createTransactionAccountant = async (payload: CreateTransactionAccountantPayload) => {
  await api.post(endpoints.companies.createTransactionAccountant, payload);
};

export interface UpdatePaymentMethodPayload {
  companyToken: string;
  numberEmployees?: number;
  numberPartners?: number;
  numberProlabore?: number;
  tributationType: number;
  value?: number;
}

export const updatePaymentMethod = async (payload: UpdatePaymentMethodPayload) => {
  await api.post(endpoints.companies.updatePaymentMethod, payload);
};

export interface UpdateIntegratorAccountPayload {
  companyToken: string;
  id: number;
  integratorAccountId: string;
}

export const updateIntegratorAccount = async (payload: UpdateIntegratorAccountPayload) => {
  await api.post(endpoints.companies.updateIntegratorAccount, payload);
};
