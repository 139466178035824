import { formattedInput } from "components/CurrencyInput";
import { MovementFormItem } from "./InterfacesForm";

const calculateTotalItem = (item: MovementFormItem) => {
  return (
    formattedInput(item.unitValue) * item.quantity -
    formattedInput(item.discount) +
    formattedInput(item.otherExpenses)
  );
};

export default calculateTotalItem;
