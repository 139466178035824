import { FC, useState } from "react";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";

import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import CompanyAccountTableItem from "./CompanyAccountTableItem";
import SortableTableHead from "components/SortableTableHead";
import stableSort from "utils/stableSort";
import { Order } from "types/SemanticTypes";
import { CompanyBankAccounts } from "types/Company";
import comparatorSortByProperty from "utils/comparatorSortByProperty";
import DialogCreateAccount from "../DialogCreateAccount";

type CompanyBankAccountsKeys = keyof CompanyBankAccounts;

interface HeadCells {
  label: string;
  id: CompanyBankAccountsKeys;
  sortable: boolean;
}

const headCells: HeadCells[] = [
  {
    id: "alias",
    label: "Apelido",
    sortable: true,
  },
  {
    id: "dateInitialBalance",
    label: "Data início",
    sortable: true,
  },
  {
    id: "bankName",
    label: "Banco",
    sortable: true,
  },
  {
    id: "agency",
    label: "Agência",
    sortable: true,
  },
  {
    id: "account",
    label: "Conta",
    sortable: true,
  },
  {
    id: "description",
    label: "Descrição",
    sortable: true,
  },
  {
    id: "initialBalance",
    label: "Saldo (R$)",
    sortable: true,
  },
  {
    id: "integratorAccountId",
    label: "Integração",
    sortable: true,
  },
];

interface CompanyAccountTableProps {
  companyToken: string;
}

const CompanyAccountTable: FC<CompanyAccountTableProps> = ({ companyToken }) => {
  const { data: bankAccounts, isLoading, isError } = useQueryGetCompanyBankAccounts(companyToken);

  const [edittedBank, setEdittedBank] = useState<CompanyBankAccounts | null>(null);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<CompanyBankAccountsKeys>("alias");

  const sortHandler = (property: CompanyBankAccountsKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (isLoading) {
    return (
      <Stack height={400} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <TableContainer>
        <Table size="small">
          <SortableTableHead
            headCells={headCells}
            onRequestSort={(e, property) => sortHandler(property)}
            order={order}
            orderBy={orderBy}
          >
            <TableCell align="center"></TableCell>
          </SortableTableHead>

          <TableBody>
            {bankAccounts && bankAccounts.length === 0 && (
              <TableRow>
                <TableCell colSpan={headCells.length + 1}>Nenhum cadastrado</TableCell>
              </TableRow>
            )}
            {isError && (
              <TableRow>
                <TableCell colSpan={headCells.length + 1}>
                  Ocorreu um error ao buscar seus clientes
                </TableCell>
              </TableRow>
            )}

            {bankAccounts &&
              stableSort(bankAccounts, comparatorSortByProperty(order, orderBy)).map(
                (bankAccount) => (
                  <CompanyAccountTableItem
                    key={bankAccount.id}
                    bankAccount={bankAccount}
                    onEdit={() => setEdittedBank(bankAccount)}
                    companyToken={companyToken}
                  />
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>

      {edittedBank && (
        <DialogCreateAccount
          open
          onClose={() => setEdittedBank(null)}
          companyToken={companyToken}
          bankAccount={edittedBank}
        />
      )}
    </>
  );
};

export default CompanyAccountTable;
