import { FC } from "react";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";

import { TableCell, TableRow } from "@mui/material";

import { TransactionsItem } from "types/Company";
import { ISODate } from "types/SemanticTypes";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { DateTime } from "luxon";

interface BillsReceivedTableItemProps {
  transactionItem: TransactionsItem;
  companyToken: string;
}

const BillsReceivedTableItem: FC<BillsReceivedTableItemProps> = ({
  transactionItem,
  companyToken,
}) => {
  const { data: bankAccounts } = useQueryGetCompanyBankAccounts(companyToken);

  const formatDate = (ISODate: ISODate) => {
    return DateTime.fromISO(ISODate).toFormat("dd/MM/yyyy");
  };

  const getBankName = (bankID: number) => {
    if (!bankID || !bankAccounts) return "-";

    return bankAccounts.find((bank) => bank.id === bankID)?.alias || "-";
  };

  return (
    <TableRow hover>
      <TableCell>
        {transactionItem.referenceDate ? formatDate(transactionItem.referenceDate) : "-"}
      </TableCell>
      <TableCell>
        {transactionItem.paymentDate ? formatDate(transactionItem.paymentDate) : "-"}
      </TableCell>
      <TableCell>{transactionItem.participantName}</TableCell>
      <TableCell>{transactionItem.description}</TableCell>
      <TableCell>{transactionItem.categoryName}</TableCell>
      <TableCell>{getBankName(transactionItem.companyBankAccountId)}</TableCell>
      <TableCell align="right" data-a-h="right">
        {formatCurrencyBRL(transactionItem.value)}
      </TableCell>
    </TableRow>
  );
};

export default BillsReceivedTableItem;
