import { FC } from "react";
import useSignUp from "./hooks/useSignUp";
import { useNavigate } from "react-router-dom";
import { useCompanyInfo } from "../../../context/CompanyInfo";

import { Checkbox, FormControlLabel, Link, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Head from "../../../components/Head";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import MaskedInput from "react-text-mask";
import validationSchema from "./validationSchema";
import { LoadingButton } from "@mui/lab";
import getSubdomain from "../../../utils/getSubdomain";
import AuthWrapper from "../AuthWrapper";

const Register: FC<{ accountant?: boolean }> = ({ accountant = false }) => {
  const { typeSubdomain } = useCompanyInfo();
  const navigate = useNavigate();
  const { mutate, isLoading } = useSignUp();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fullName: "",
      email: "",
      cellPhone: "",
      telephone: "",
      password: "",
      confirmPassword: "",
      terms: "",
    },
    mode: "onTouched",
  });

  const onSubmit = handleSubmit((data) => {
    mutate({
      fullName: data.fullName,
      email: data.email,
      password: data.password,
      telephone: data.telephone.replace(/-/g, ""),
      cellPhone: data.cellPhone.replace(/[-,(,), ]/g, ""),
      type: typeSubdomain === "admin" && accountant ? "accountant" : "user",
    });
  });

  return (
    <AuthWrapper>
      <Head title={`Cadastro | ${getSubdomain()}`} />

      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        {!accountant ? "Cadastro de Usuário" : "Cadastro de Empresa"}
      </Typography>
      {typeSubdomain === "accountant" && !accountant && (
        <Typography variant="body1">
          Você já tem uma conta?{" "}
          <Link
            sx={{ cursor: "pointer" }}
            href="/login"
            onClick={(e) => {
              e.preventDefault();
              navigate("/login");
            }}
          >
            Entre
          </Link>
        </Typography>
      )}

      <Box component="form" mt={4} onSubmit={onSubmit}>
        <Stack gap={2} width="100%">
          <Controller
            name="fullName"
            control={control}
            render={({ fieldState, field }) => (
              <TextField
                fullWidth
                label="Nome completo"
                variant="outlined"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ fieldState, field }) => (
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            name="cellPhone"
            control={control}
            render={({ fieldState, field }) => (
              <MaskedInput
                mask={[
                  "(",
                  /[1-9]/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="Celular"
                    variant="outlined"
                    inputRef={ref}
                    {...props}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              ></MaskedInput>
            )}
          />

          <Controller
            name="telephone"
            control={control}
            render={({ fieldState, field }) => (
              <MaskedInput
                mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="Telefone fixo"
                    variant="outlined"
                    inputRef={ref}
                    {...props}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ fieldState, field }) => (
              <TextField
                fullWidth
                label="Senha"
                variant="outlined"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                type="password"
              />
            )}
          />

          <Controller
            name="confirmPassword"
            control={control}
            render={({ fieldState, field }) => (
              <TextField
                fullWidth
                label="Repita a senha"
                variant="outlined"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                type="password"
              />
            )}
          />
        </Stack>

        <FormControlLabel
          control={
            <Controller
              name="terms"
              control={control}
              render={({ fieldState, field }) => (
                <Checkbox
                  {...field}
                  sx={{
                    color: fieldState.error ? "#d32f2f" : "rgba(0, 0, 0, 0.6)",
                  }}
                />
              )}
            />
          }
          sx={{ my: 2 }}
          label={
            <>
              <Typography variant="body1">
                Eu li e concordo com os{" "}
                <Link
                  sx={{ cursor: "pointer" }}
                  href="https://osayk.com.br/termos-de-uso/"
                  target="_blank"
                >
                  Termos de Uso do site
                </Link>
              </Typography>

              {!!errors.terms?.message && (
                <Typography variant="body1" color="error" fontSize="small">
                  {errors.terms?.message}
                </Typography>
              )}
            </>
          }
        />

        <LoadingButton
          loading={isLoading}
          variant="contained"
          fullWidth
          disableElevation
          type="submit"
        >
          Cadastrar
        </LoadingButton>
      </Box>
    </AuthWrapper>
  );
};

export default Register;
