import { FC } from "react";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";
import useQueryCompanyPartners from "pages/Home/hooks/useQueryCompanyPartners";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import PartnerForm from "pages/Home/pages/PartnerForm";

interface PartnerTabProps {
  companyToken: string;
  onNext: () => void;
  onBack: () => void;
}

const PartnerTab: FC<PartnerTabProps> = ({ companyToken, onNext, onBack }) => {
  const {
    data: companyInformation,
    isLoading: loadingCompany,
    isError: errorCompany,
  } = useQueryGetCompany(companyToken);
  const {
    data: partners,
    isLoading: loadingPartners,
    isError: errorPartner,
  } = useQueryCompanyPartners(companyToken);
  const isLoading = loadingCompany || loadingPartners;
  const isError = errorCompany || errorPartner;

  return (
    <Box>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}
      {companyInformation && partners && !isLoading && !isError && (
        <PartnerForm
          companyInformation={companyInformation}
          partners={partners}
          onChangePage={onBack}
          onClose={onNext}
        />
      )}

      {isError && (
        <Typography>
          Ocorreu um erro ao carregar os dados da empresa. Por favor, tente novamente mais tarde.
        </Typography>
      )}
    </Box>
  );
};

export default PartnerTab;
