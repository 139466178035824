import { FC, useState } from "react";
import useQueryGetFinancialCategories from "../../../hooks/useQueryGetFinancialCategories";

import {
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
  Menu as DropdownMenu,
  MenuItem,
} from "@mui/material";
import CategoryItem from "./CategoryItem";
import { FinancialCategory } from "types/Company";
import DialogRemoveCategory from "./DialogRemoveCategory";
import DialogEditCategory from "./DialogEditCategory";

const LoadingFallback = () => (
  <Box>
    <Skeleton variant="rectangular" height={30} />
    <Stack mt={1} pl={4} gap={1}>
      <Skeleton variant="rectangular" height={20} />
      <Skeleton variant="rectangular" height={20} />
      <Skeleton variant="rectangular" height={20} />
      <Skeleton variant="rectangular" height={20} />
    </Stack>
  </Box>
);

interface CategoryListProps {
  companyToken: string;
}

const CategoryList: FC<CategoryListProps> = ({ companyToken }) => {
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  const [confirmationDeleteIsShow, setConfirmationDeleteIsShow] = useState(false);
  const [editting, setEditting] = useState(false);
  const [categoryClicked, setCategoryClicked] = useState<null | FinancialCategory>(null);

  const {
    data: financialCategories,
    isLoading,
    isError,
    isFetching,
  } = useQueryGetFinancialCategories(companyToken);

  if (isLoading) {
    return <LoadingFallback />;
  }

  if (isError) {
    return (
      <Typography>
        Ocorreu um error ao tentar acessar suas categorias. Tente mais tarde novamente
      </Typography>
    );
  }

  return (
    <Box>
      {financialCategories && financialCategories.length === 0 && (
        <Typography>Nenhuma categoria cadastrada</Typography>
      )}

      <Stack sx={{ overflowX: "auto" }} position="relative">
        {isFetching && !isLoading && (
          <Stack
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={20}
            left={0}
            width="100%"
          >
            <CircularProgress size={20} />
          </Stack>
        )}

        {financialCategories &&
          financialCategories.map((category) => (
            <CategoryItem
              category={category}
              key={category.id}
              indent={0}
              onClickMenu={(el, category) => {
                setAnchorElMenu(el);
                setCategoryClicked(category);
              }}
            />
          ))}
      </Stack>

      {categoryClicked && (
        <>
          <DialogRemoveCategory
            open={confirmationDeleteIsShow}
            onClose={() => {
              setConfirmationDeleteIsShow(false);
              setCategoryClicked(null);
            }}
            companyToken={companyToken}
            categoryId={categoryClicked.id}
          />

          <DialogEditCategory
            open={editting}
            onClose={() => {
              setEditting(false);
              setCategoryClicked(null);
            }}
            companyToken={companyToken}
            category={categoryClicked}
          />
        </>
      )}

      <DropdownMenu
        anchorEl={anchorElMenu}
        open={menuIsOpen && !!categoryClicked}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => setEditting(true)}>Editar</MenuItem>
        <MenuItem onClick={() => setConfirmationDeleteIsShow(true)}>Excluir</MenuItem>
      </DropdownMenu>
    </Box>
  );
};

export default CategoryList;
