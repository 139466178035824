import { useMutation, useQueryClient } from "react-query";
import { DateTime } from "luxon";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { reopenMonth } from "services/Requests/companies";

const useMutationReopenMonth = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(reopenMonth, {
    onSuccess(_, { date }) {
      toast.success("Mês reaberto com sucesso");

      const formattedMonth = DateTime.fromISO(date).toFormat("yyyy/MM/dd");
      queryClient.invalidateQueries([queryKeys.monthClosure, formattedMonth]);
    },
  });
  return mutation;
};

export default useMutationReopenMonth;
