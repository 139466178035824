import { FC, useState } from "react";

import useQueryOsaykPlanByProduct from "./hooks/useQueryOsaykPlanByProduct";

import { Box, CircularProgress, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ProductByPlan } from "types/Plan";
import Plan from "./Plan";
import CompanyWorkflow from "./CompanyWorkflow";

const periodicity = [1, 3, 6, 12] as const;
export type Periodicity = typeof periodicity[number];
export const periodicityLabels: Record<Periodicity, string> = {
  1: "Mensal",
  3: "Trimestral",
  6: "Semestral",
  12: "Anual",
};

interface PlansProps {
  companyToken: string;
}

const Plans: FC<PlansProps> = ({ companyToken }) => {
  const [period, setPeriod] = useState<Periodicity>(1);
  const { data: plans, isLoading } = useQueryOsaykPlanByProduct(companyToken, true);

  const [selectedPlan, setSelectedPlan] = useState<ProductByPlan | null>(null);

  return (
    <>
      <Stack alignItems="center" justifyContent="center">
        <ToggleButtonGroup
          color="primary"
          value={period}
          exclusive
          onChange={(event, newPeriod) => {
            if (newPeriod) {
              setPeriod(newPeriod);
            }
          }}
        >
          {periodicity.map((periodicity) => (
            <ToggleButton key={periodicity} value={periodicity}>
              {periodicityLabels[periodicity]}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>

      <Box mt={4}>
        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={400}>
            <CircularProgress />
          </Stack>
        )}

        {!isLoading && !!plans && (
          <Box display="grid" gridTemplateColumns="repeat( auto-fit, minmax(280px, 1fr) )" gap={2}>
            {plans.map((plan) => (
              <Plan
                key={plan.identifier}
                plan={plan}
                periodicity={period}
                onSelectPlan={setSelectedPlan}
              />
            ))}
          </Box>
        )}
      </Box>

      {!!selectedPlan && (
        <CompanyWorkflow
          open={!!selectedPlan}
          plan={selectedPlan}
          onClose={() => setSelectedPlan(null)}
          companyToken={companyToken}
        />
      )}
    </>
  );
};

export default Plans;
