import { FC, useEffect } from "react";

import {
  Typography,
  Divider,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { red } from "@mui/material/colors";

import MaskedInput from "react-text-mask";
import { FieldValue, Controller, useWatch } from "react-hook-form";

import { zipCodeMask } from "utils/masks";
import ControllerInput from "components/ControllerInput";

import useQueryCounties from "pages/Home/hooks/useQueryCounties";
import { Counties } from "types/CommonData";
import StateSelect from "pages/Home/components/StateSelect";

interface NamesFields {
  street: string;
  neighborhood: string;
  zipCode: string;
  city: string;
  complement: string;
  state: string;
  streetNumber: string;
}

const defaultNamesFields = {
  street: "street",
  neighborhood: "neighborhood",
  zipCode: "zipCode",
  city: "city",
  complement: "complement",
  state: "state",
  streetNumber: "streetNumber",
};

interface AddressFormProps {
  control: FieldValue<{ [x: string]: any }>;
  onChangeCounty?: (county?: Counties) => void;
  namesFields?: NamesFields;
  disabled?: boolean;
}

const AddressForm: FC<AddressFormProps> = ({
  control,
  onChangeCounty,
  namesFields = defaultNamesFields,
  disabled = false,
}) => {
  const state = useWatch({
    control,
    name: namesFields.state,
  });
  const city = useWatch({
    control,
    name: namesFields.city,
  });

  const counties = useQueryCounties(state);

  useEffect(() => {
    if (!counties.data) return;

    onChangeCounty?.(counties.data.find((county) => county.id === city));
  }, [counties]);

  return (
    <>
      <Typography variant="subtitle1" fontWeight={600} gutterBottom>
        Endereço
      </Typography>
      <Divider sx={{ mb: 3 }} />

      <Stack gap={3}>
        <Stack gap={3} direction="row">
          <Controller
            name={namesFields.street}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                label="Logradouro"
                sx={{ flex: 1 }}
                disabled={disabled}
                InputLabelProps={{
                  shrink: field.value ? true : undefined,
                }}
              />
            )}
          />

          <Stack gap={2} direction="row" flex={1}>
            <Controller
              name={namesFields.streetNumber}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  label="Número"
                  type="number"
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  sx={{ flex: 1 }}
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: field.value ? true : undefined,
                  }}
                />
              )}
            />

            <Controller
              name={namesFields.complement}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  label="Complemento (opcional)"
                  sx={{ flex: 2 }}
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: field.value ? true : undefined,
                  }}
                />
              )}
            />
          </Stack>
        </Stack>

        <Stack gap={3} direction="row">
          <ControllerInput
            control={control}
            name={namesFields.neighborhood}
            inputProps={{ label: "Bairro", sx: { flex: 1 }, disabled }}
          />

          <Controller
            control={control}
            name={namesFields.zipCode}
            render={({ field, fieldState }) => (
              <MaskedInput
                mask={zipCodeMask}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={disabled}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="CEP"
                    variant="outlined"
                    inputRef={ref}
                    {...props}
                    sx={{ flex: 1 }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    disabled={disabled}
                    InputLabelProps={{
                      shrink: field.value ? true : undefined,
                    }}
                  />
                )}
              ></MaskedInput>
            )}
          />
        </Stack>

        <Stack gap={3} direction="row">
          <Controller
            name={namesFields.state}
            control={control}
            render={({ field, fieldState }) => (
              <StateSelect
                {...field}
                label="Estado"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                FormControlProps={{ disabled, sx: { flex: 1 } }}
              />
            )}
          />

          <FormControl
            fullWidth
            sx={{ flex: 1 }}
            disabled={disabled || !counties || counties?.isLoading}
          >
            <InputLabel shrink={city ? true : undefined}>Município</InputLabel>
            <Controller
              name={namesFields.city}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);

                      const value = e.target.value;
                      if (!value || !counties?.data) {
                        onChangeCounty?.();
                        return;
                      }
                      onChangeCounty?.(counties.data.find((county) => county.id === value));
                    }}
                    label="Município"
                    error={!!fieldState.error?.message}
                    endAdornment={
                      counties?.isLoading ? (
                        <CircularProgress sx={{ position: "relative", left: "-20px" }} size={20} />
                      ) : undefined
                    }
                  >
                    <MenuItem disabled value="">
                      Selecione:
                    </MenuItem>

                    {counties?.data &&
                      counties.data.map((county) => (
                        <MenuItem value={county.id} key={county.id}>
                          {county.name}{" "}
                        </MenuItem>
                      ))}
                  </Select>

                  {!!fieldState.error?.message && (
                    <Typography
                      variant="body2"
                      sx={{ ml: 2, mt: "3px", fontSize: "0.75rem" }}
                      color={red[700]}
                    >
                      {fieldState.error.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
};

export default AddressForm;
