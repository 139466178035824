import { useMutation, useQueryClient } from "react-query";
import { UpdateCompanyPayload, updateCompany } from "services/Requests/openCompany";

import toast from "react-hot-toast";
import getSubdomain from "utils/getSubdomain";
import queryKeys from "react-query/constants";
import errorHandlerCompany from "utils/errorHandlerCompany";

const useMutationUpdateCompany = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<UpdateCompanyPayload, "subdomain">) =>
      updateCompany({
        ...payload,
        subdomain,
      }),
    {
      onSuccess(data, { companyToken }) {
        if (data) {
          toast.success("Dados da empresa atualizados com sucesso!");
          queryClient.invalidateQueries([queryKeys.company, companyToken]);
          queryClient.invalidateQueries(queryKeys.ordersByAssignments, { exact: false });
        }
      },
      onError: errorHandlerCompany,
    },
  );
  return mutation;
};

export default useMutationUpdateCompany;
