import { FC } from "react";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import Head from "components/Head";

import getSubdomain from "utils/getSubdomain";
import ManagerialIncomeStatementSummary from "./ManagerialIncomeStatementSummary";

const ManagerialIncomeStatementReport: FC = () => {
  return (
    <>
      <Head title={`DRE Gerencial | ${getSubdomain()}`} />

      <Typography variant="h4" fontWeight="bold">
        Relatórios DRE Gerencial
      </Typography>

      <Box mt={2} boxShadow={2} sx={{ p: 3 }}>
        <ManagerialIncomeStatementSummary />
      </Box>
    </>
  );
};

export default ManagerialIncomeStatementReport;
