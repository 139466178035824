import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { updateTemplate } from "services/Requests/template";

const useMutationUpdateTemplate = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateTemplate, {
    onSuccess: () => {
      toast.success("Template editado com sucesso");
      queryClient.invalidateQueries(queryKeys.templates);
    },
    onError: () => {
      toast.error("Erro ao editar template, tente novamente mais tarde");
    },
  });

  return mutation;
};

export default useMutationUpdateTemplate;
