import { createContext } from "react";
import { CompanyDocumentDrive } from "types/Company";
import { Paths } from "../../utils/path";

export interface DriveContextInterface {
  listingByIcons: boolean;
  toggleListingByIcons: () => void;
  documentDriveToDelete: CompanyDocumentDrive | null;
  handleChangeDocumentToDelete: (document: CompanyDocumentDrive | null) => void;
  documentDriveToEdit: CompanyDocumentDrive | null;
  handleChangeDocumentToEdit: (document: CompanyDocumentDrive | null) => void;
  documentDriveToMove: CompanyDocumentDrive | null;
  handleChangeDocumentToMove: (document: CompanyDocumentDrive | null) => void;
  paths: Paths;
  onChangePaths: (newPaths: Paths) => void;
  addNewPath: (document: CompanyDocumentDrive) => void;
}

export const DriveContext = createContext<DriveContextInterface>({} as DriveContextInterface);
