import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import {
  setPaymentMethodProfile,
  SetPaymentMethodProfilePayload,
} from "services/Requests/companies";
import getSubdomain from "utils/getSubdomain";
import queryKeys from "react-query/constants";

const useMutationSetPaymentMethodProfile = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<SetPaymentMethodProfilePayload, "subdomain">) =>
      setPaymentMethodProfile({
        ...payload,
        subdomain,
      }),
    {
      onSuccess(_, { companyToken }) {
        toast.success(
          "Método de pagamento alterado com sucesso, agora aguarde a confirmação do cartão",
        );
        queryClient.invalidateQueries([queryKeys.paymentInfo, companyToken]);
        queryClient.invalidateQueries([queryKeys.paymentInfoProfile, companyToken]);
      },
    },
  );
  return mutation;
};

export default useMutationSetPaymentMethodProfile;
