import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { createCompanyFinancialCategory } from "services/Requests/accountant";

const useMutationCreateCompanyFinancialCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createCompanyFinancialCategory, {
    onSuccess(_, { companyToken }) {
      toast.success("Categoria criada");
      queryClient.invalidateQueries([queryKeys.clientChartOfAccounts, companyToken]);
    },
  });

  return mutation;
};

export default useMutationCreateCompanyFinancialCategory;
