import { FC, useMemo } from "react";
import useQueryPrefectureByCompany from "../hooks/useQueryPrefectureByCompany";
import useQueryInvoiceConfiguration from "../hooks/useQueryInvoiceConfiguration";
import useQueryMunicipalService from "pages/Home/hooks/useQueryMunicipalService";

import { CircularProgress, Stack, Typography } from "@mui/material";
import InvoiceSetup from "pages/Home/components/InvoiceSetup";
import WithCompany from "components/WithCompany";
import { AxiosError } from "axios";

const Configuration: FC<{
  companyToken: string;
  isClient?: boolean;
  invoiceType?: "NFS-e" | "NF-e" | "NFC-e";
}> = ({ companyToken, isClient, invoiceType }) => {
  const {
    data: invoiceConfiguration,
    isLoading: loadingConfiguration,
    isError,
  } = useQueryInvoiceConfiguration(companyToken);
  const {
    data: prefecture,
    isLoading: loadingPrefecture,
    error,
  } = useQueryPrefectureByCompany(companyToken);
  const { data, isLoading: loadingMunicipalService } = useQueryMunicipalService(companyToken);
  const hasServiceList = useMemo(() => {
    return data ? data.length > 0 : false;
  }, [data]);

  const isLoading = loadingPrefecture || loadingConfiguration || loadingMunicipalService;

  if (isLoading) {
    return (
      <Stack height={400} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isError) {
    return <Typography>Não foi possível obter as configurações da sua empresa</Typography>;
  }

  if (invoiceConfiguration && !isLoading && !isError) {
    return (
      <InvoiceSetup
        invoiceConfiguration={invoiceConfiguration}
        prefecture={prefecture}
        prefectureError={(error as AxiosError<{ message: string }>)?.response?.data.message}
        companyToken={companyToken}
        hasServiceList={hasServiceList}
        isClient={isClient}
        invoiceType={invoiceType}
      />
    );
  }

  return <></>;
};

export default WithCompany(Configuration);
