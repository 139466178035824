import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

type IconType = OverridableComponent<SvgIconTypeMap<object, "svg">> & {
  muiName: string;
};

export const fileIcons: Record<string, IconType> = {
  dir: FolderIcon,
  jpg: ImageIcon,
  jpeg: ImageIcon,
  png: ImageIcon,
  gif: ImageIcon,
  pdf: PictureAsPdfIcon,
  doc: ArticleIcon,
  docx: ArticleIcon,
  txt: TextSnippetIcon,
  default: InsertDriveFileIcon,
};

export const getFileExtension = (filename: string): string => {
  const extension = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  return extension === "" ? "dir" : extension;
};

export const visibleTypes = ["jpg", "jpeg", "png", "gif", "pdf"];
