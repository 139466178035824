import { FC } from "react";
import { useCompanyInfo } from "../../context/CompanyInfo";

import { Box } from "@mui/system";
import { Grid, Stack, useMediaQuery } from "@mui/material";

import RenderLogo from "../../components/RenderLogo";

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper: FC<AuthWrapperProps> = ({ children }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:600px) and (max-width:980px)");

  const { pathImage, companyStyles } = useCompanyInfo();

  return (
    <Box>
      <Grid container spacing={2} sx={{ height: "102vh", overflow: "auto" }}>
        <Grid
          item
          xs={isTablet || isMobile ? 12 : 6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            background: companyStyles.loginBg,
          }}
        >
          <Box
            width="100%"
            sx={{
              maxWidth: isTablet ? "600px" : "100%",
              my: isTablet ? 4 : 0,
              px: isTablet || isMobile ? 2 : 0,
            }}
          >
            <Box
              sx={{
                p: isMobile ? 2 : isTablet ? 5 : 8,
                boxShadow: isTablet ? 1 : "none",
              }}
            >
              {(isTablet || isMobile) && (
                <Box mb={2}>
                  <RenderLogo src={pathImage || ""} alt="logo da empresa" height={30} />
                </Box>
              )}

              {children}
            </Box>
          </Box>
        </Grid>

        {!isTablet && !isMobile && (
          <Grid item xs={6} sx={{ backgroundColor: "#e4e4e4", padding: "0 !important" }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ position: "relative", overflow: "hidden", height: "100%", p: 6 }}
            >
              <svg
                style={{ position: "absolute", top: "0", left: "0", pointerEvents: "none" }}
                viewBox="0 0 960 540"
                width="100%"
                height="100%"
                preserveAspectRatio="xMidYMax slice"
                xmlns="http://www.w3.org/2000/svg"
              >
                <Box
                  component="g"
                  sx={{ color: "#e8e8e8" }}
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="100"
                >
                  <circle r="234" cx="196" cy="23" />
                  <circle r="234" cx="790" cy="491" />
                </Box>
              </svg>

              <Box
                component="svg"
                sx={{
                  position: "absolute",
                  top: "-48px",
                  left: "-48px",
                  opacity: "0.2",
                }}
                viewBox="0 0 220 192"
                width="220px"
                height="192px"
                fill="none"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
              </Box>

              <Box sx={{ zIndex: 10, position: "relative" }} width="100%">
                <Stack alignItems="center" justifyItems="center">
                  <RenderLogo src={pathImage || ""} alt="logo da empresa" height={80} />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AuthWrapper;
