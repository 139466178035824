import { FC, useState } from "react";
import useQueryCategoriesWithReleases from "../../../hooks/useQueryCategoriesWithReleases";

import { Button } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import DialogTransfer from "./DialogTransfer";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";

interface TransferProps {
  companyToken: string;
}

const Transfer: FC<TransferProps> = ({ companyToken }) => {
  const [showTransfer, setShowTransfer] = useState(false);
  const { data, isLoading: loadingCategoriesWithReleases } =
    useQueryCategoriesWithReleases(companyToken);

  return (
    <>
      <Button
        startIcon={<FiberManualRecordIcon fontSize="small" />}
        variant="contained"
        onClick={() => setShowTransfer(true)}
        size="small"
      >
        Transferir lançamentos
      </Button>

      {loadingCategoriesWithReleases && showTransfer && <FullscreenLoadingIndicator />}

      {showTransfer && data && (
        <DialogTransfer
          open={showTransfer}
          onClose={() => setShowTransfer(false)}
          companyToken={companyToken}
          categoriesWithReleases={data}
        />
      )}
    </>
  );
};

export default Transfer;
