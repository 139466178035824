import * as yup from "yup";
import { formattedInput } from "components/CurrencyInput";

const validationSchema = yup.object().shape({
  value: yup
    .string()
    .required("Por favor, informe o valor.")
    .test("isValidNumber", "Por favor, informe um valor numérico.", (v) => {
      if (!v) return false;
      const numericValue = formattedInput(v);
      return !isNaN(numericValue);
    }),

  parcel: yup
    .number()
    .nullable()
    .transform((value) => {
      if (value === undefined || value === null || isNaN(value)) return null;
      return typeof value === "string" ? Number(value) : value;
    })
    .test("validatePositive", "Apenas números positivos", (v) => {
      if (v === null || v === undefined) return true;
      return v >= 0;
    }),

  dueDate: yup
    .date()
    .required("A data de vencimento é obrigatório")
    .typeError("Digite uma data válida"),
  referenceDate: yup
    .date()
    .required("A data de compotência é obrigatório")
    .typeError("Digite uma data válida"),
  paymentDate: yup.date().notRequired().nullable(true).typeError("Digite uma data válida"),
});

export default validationSchema;
