import { useMutation, useQueryClient } from "react-query";
import { importExcel } from "services/Requests/accountant";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";

const useMutationImportExcel = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(importExcel, {
    onSuccess() {
      toast.success("Contas importadas com sucesso.");
      queryClient.invalidateQueries(queryKeys.chartOfAccountsFromToGroup, {
        exact: false,
      });
    },
  });
  return mutation;
};

export default useMutationImportExcel;
