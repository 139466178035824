import { useMutation, useQueryClient } from "react-query";
import { DateTime } from "luxon";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { closeMonth } from "services/Requests/companies";

const useMutationCloseMonth = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(closeMonth, {
    onSuccess(_, { dateReferenceClose }) {
      toast.success("Mês fechado com sucesso");

      const formattedMonth = DateTime.fromISO(dateReferenceClose).toFormat("yyyy/MM/dd");
      queryClient.invalidateQueries([queryKeys.monthClosure, formattedMonth]);
    },
  });
  return mutation;
};

export default useMutationCloseMonth;
