import { FC, PropsWithChildren } from "react";
import { Box, BoxProps, Stack, Typography } from "@mui/material";

interface CardWrapperProps extends Omit<BoxProps, "title"> {
  title: string | React.ReactNode;
}

const CardWrapper: FC<PropsWithChildren<CardWrapperProps>> = ({ title, children, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        borderTop: "4px solid",
        borderTopColor: "primary.main",
        bgcolor: "#fff",
        boxShadow: 1,
        height: "min-content",
        borderRadius: 1,
        ...props.sx,
      }}
    >
      {typeof title === "string" ? (
        <Typography variant="h5" p={2} sx={{ borderBottom: 1, borderBottomColor: "divider" }}>
          {title}
        </Typography>
      ) : (
        <Box p={2} sx={{ borderBottom: 1, borderBottomColor: "divider" }}>
          {title}
        </Box>
      )}

      {children}
    </Box>
  );
};

export const CardContent: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box {...props} p={2}>
      {children}
    </Box>
  );
};

export const CardAction: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Stack
      direction="row"
      gap={1}
      p={2}
      flexWrap="wrap"
      sx={{ borderTop: 1, borderTopColor: "divider" }}
    >
      {children}
    </Stack>
  );
};

export default CardWrapper;
