import { FC } from "react";
import { Control, Controller, Path, useWatch } from "react-hook-form";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import StateSelect from "../../StateSelect";
import CurrencyInput from "components/CurrencyInput";
import CompanyClientSelect from "../components/CompanyClientSelect";

import { InvoiceFieldsForm } from ".";
import { InvoiceConfig } from "types/Company";

const taxes: { name: Path<InvoiceFieldsForm>; label: string }[] = [
  {
    name: "iss",
    label: "ISS (%)",
  },
  {
    name: "irpj",
    label: "IRPJ (%)",
  },
  {
    name: "irop",
    label: "INSS (%)",
  },
  {
    name: "pis",
    label: "PIS (%)",
  },
  {
    name: "cofins",
    label: "COFINS (%)",
  },
  {
    name: "csll",
    label: "CSLL (%)",
  },
];

interface FormProps {
  control: Control<InvoiceFieldsForm>;
  invoiceConfig: InvoiceConfig;
}

const Form: FC<FormProps> = ({ control, invoiceConfig }) => {
  const serviceOtherState = useWatch({
    control,
    name: "serviceOtherState",
  });

  return (
    <Box display="grid" gap={2}>
      <Controller
        control={control}
        name="companyParticipantId"
        render={({ field, fieldState }) => (
          <CompanyClientSelect
            {...field}
            helperText={fieldState.error?.message}
            error={!!fieldState.error?.message}
            label="Cliente"
            FormControlProps={{ fullWidth: true }}
          />
        )}
      />

      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
        <Controller
          control={control}
          name="value"
          render={({ field, fieldState }) => (
            <CurrencyInput
              {...field}
              inputProps={{
                helperText: fieldState.error?.message,
                error: !!fieldState.error?.message,
                label: "Valor",
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="issueDate"
          render={({ field, fieldState }) => (
            <TextField
              value={field.value}
              disabled
              label="Data de Emissão"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Box>

      <Controller
        control={control}
        name="serviceCodeId"
        render={({ field, fieldState }) => (
          <FormControl>
            <InputLabel error={!!fieldState.error?.message}>Serviço Prestado</InputLabel>
            <Select
              label="Serviço Prestado"
              {...field}
              error={!!fieldState.error?.message}
              value={field.value ?? ""}
            >
              <MenuItem disabled value="">
                Selecione:{" "}
              </MenuItem>
              {invoiceConfig.services.map((service) => (
                <MenuItem value={service.id} key={service.id}>
                  {service.descricao}
                </MenuItem>
              ))}
            </Select>
            {!!fieldState.error?.message && (
              <FormHelperText error>{fieldState.error?.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Box>
        <Typography variant="body1">Retenção de Tributos</Typography>
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={1.5} mt={1.5}>
          {taxes.map((t) => (
            <Controller
              name={t.name}
              control={control}
              key={t.name}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t.label}
                  type="number"
                  inputProps={{
                    min: "0.00",
                  }}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          ))}
        </Box>
      </Box>

      <Controller
        name="issWithHeld"
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel>ISS Retido</FormLabel>
            <RadioGroup {...field} row>
              <FormControlLabel value={1} control={<Radio size="small" />} label="Sim" />
              <FormControlLabel value={0} control={<Radio size="small" />} label="Não" />
            </RadioGroup>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Descrição"
            rows={3}
            multiline
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="notes"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Informações adicionais"
            rows={3}
            multiline
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        name="serviceOtherState"
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel>Mudar local de prestação do serviço</FormLabel>
            <RadioGroup {...field} row>
              <FormControlLabel value={0} control={<Radio size="small" />} label="Não" />
              <FormControlLabel value={1} control={<Radio size="small" />} label="Sim" />
            </RadioGroup>
          </FormControl>
        )}
      />

      {serviceOtherState === "1" && (
        <Box display="grid" gridTemplateColumns="1fr 2fr" gap={2}>
          <Controller
            control={control}
            name="serviceUF"
            render={({ field, fieldState }) => (
              <StateSelect
                {...field}
                helperText={fieldState.error?.message}
                error={!!fieldState.error?.message}
                label="UF"
                showOnlyCode
              />
            )}
          />

          <Controller
            control={control}
            name="serviceCity"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Cidade (nome ou cód. IBGE)"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default Form;
