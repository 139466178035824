import useMutationCreateOrUpdateTransaction from "../../hooks/useMutationCreateOrUpdateTransaction";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
} from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { FC, useState } from "react";
import { ImportOFXTransaction } from "types/Company";

interface DialogMassActionExtractsProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  extracts: ImportOFXTransaction[];
  isIgnore: boolean;
  onCloseAfterReconcile?: () => void;
  selectIds: string[];
  reloadList?: () => void;
}

const DialogMassActionExtracts: FC<DialogMassActionExtractsProps> = ({
  extracts,
  onClose,
  onCloseAfterReconcile,
  companyToken,
  isIgnore,
  selectIds,
  reloadList,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationCreateOrUpdateTransaction(true);
  const [isStart, setIsStart] = useState(false);
  const [success, setSuccess] = useState(0);
  const [errors, setErrors] = useState(0);

  function handleClose() {
    if (isLoading) return;
    if (isStart) {
      setIsStart(false);
      setSuccess(0);
      setErrors(0);
      onCloseAfterReconcile?.();
      reloadList && reloadList();
    }

    onClose();
  }

  const handleReconcile = async () => {
    const promises = extracts.map((extract) => {
      mutateAsync({
        companyToken,
        ofxDescription: extract.description2,
        oFXDescription: extract.description2,
        ofxId: extract.ofxId,
        ofxIgnore: isIgnore,
        paymentDate: extract.date,
        transactionId: extract.transactionId,
        transactionType: extract.type.toLowerCase() === "credit" ? "Credit" : "Debt",
        value: extract.transactionValue,
        categoryId: extract.categoryId,
        companyBankAccountId: extract.companyBankAccountId,
        companyParticipantId: extract.companyParticipantId,
        companyTransferBankAccountId: extract.companyTransferBankAccount
          ? extract.companyBankAccountId
          : undefined,
        description: extract.description2,
        descriptionOFX: extract.description2,
        dueDate: extract.date,
        dueDate2: extract.date,
        globalCategoryId: extract.globalCategoryId,
        isInvoice: false,
        issueDate: extract.date,
        partnerParticipantId: extract.partnerParticipantId,
        id: extract.id,
      })
        .then(() => setSuccess((prev) => prev + 1))
        .catch(() => setErrors((prev) => prev + 1));
    });

    await Promise.all(promises);
  };

  const normalise = (value: number) => ((value - 0) * 100) / (extracts.length - 0);
  const percentOfPromises = Math.round(normalise(success + errors));

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Ação em massa: {isIgnore ? "Ignorar" : "Conciliar"}
      </DialogTitleWithCloseButton>

      <DialogContent>
        {!isStart ? (
          <Typography>
            Tem certeza em {isIgnore ? "ignorar" : "conciliar"}{" "}
            <Typography component="span" fontWeight={600}>
              {selectIds.length} lançamentos?
            </Typography>
          </Typography>
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="center" gap={4} my={2}>
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                variant="determinate"
                color="primary"
                size={80}
                value={percentOfPromises}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="caption" component="div" color="text.secondary">
                  {`${percentOfPromises}%`}
                </Typography>
              </Box>
            </Box>

            <Box>
              <Typography variant="body1" color="success.main">
                Sucessos: {success}
              </Typography>
              <Typography variant="body1" color={errors === 0 ? "text.secondary" : "error.main"}>
                Erros: {errors}
              </Typography>
            </Box>
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        {!isStart ? (
          <>
            <Button
              variant="contained"
              onClick={() => {
                setIsStart(true);
                handleReconcile();
              }}
            >
              Sim
            </Button>

            <Button onClick={handleClose} disabled={isLoading} variant="outlined">
              Não
            </Button>
          </>
        ) : (
          <Button onClick={handleClose} disabled={isLoading} variant="contained">
            Fechar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogMassActionExtracts;
