import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getTicket } from "services/Requests/mailbox";

const useQueryTicket = (id: number, companyToken: string) => {
  const query = useQuery([queryKeys.ticket, id], () => getTicket({ companyToken, id }));
  return query;
};

export default useQueryTicket;
