import { useQuery } from "react-query";

import getSubdomain from "utils/getSubdomain";
import { getPlans } from "services/Requests/marketplace";
import queryKeys from "react-query/constants";

export const useQueryPlans = () => {
  const subdomain = getSubdomain();

  const query = useQuery(queryKeys.plans, () => getPlans({ subdomain }), {
    staleTime: 1000 * 60 * 20, // 20 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
  });
  return query;
};
