import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { uploadContract } from "services/Requests/contract";

const useMutationUploadContract = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(uploadContract, {
    onSuccess() {
      toast.success("Contrato enviado com sucesso");
      queryClient.invalidateQueries(queryKeys.contracts);
    },
  });
  return mutation;
};

export default useMutationUploadContract;
