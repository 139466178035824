import { FC, useMemo } from "react";

import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Transactions } from "types/Company";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { TransactionAction } from "services/Requests/companies";

interface ProvisionedTableProps {
  transaction: Transactions;
  actionFilter: TransactionAction | null;
}

const ProvisionedTable: FC<ProvisionedTableProps> = ({ transaction, actionFilter }) => {
  const provisionedData = useMemo(() => {
    const expectedEndPeriod = (transaction?.aReceber || 0) - Math.abs(transaction?.aPagar || 0);
    const total = transaction.balance + expectedEndPeriod;

    if (actionFilter === null || actionFilter === undefined) {
      return {
        aPagar: transaction?.aPagar || 0,
        aReceber: transaction?.aReceber || 0,
        expectedEndPeriod,
        total,
      };
    }

    return {
      aPagar:
        actionFilter === "AP" || actionFilter === "APR" || actionFilter === "TM"
          ? transaction?.aPagar || 0
          : 0,
      aReceber:
        actionFilter === "AR" || actionFilter === "APR" || actionFilter === "TM"
          ? transaction?.aReceber || 0
          : 0,
      expectedEndPeriod: actionFilter === "APR" || actionFilter === "TM" ? expectedEndPeriod : 0,
      total: actionFilter === "TM" ? transaction?.balancePeriod || 0 : 0,
    };
  }, [transaction]);

  const getColor = (value: number) => {
    if (value >= 0) return "success.main";
    return "error.main";
  };

  return (
    <Card sx={{ "@media print": { boxShadow: "none" } }}>
      <CardContent>
        <Typography variant="h6">Provisionado</Typography>

        <TableContainer sx={{ mt: 1 }}>
          <Table size="small">
            <TableBody>
              <TableRow hover>
                <TableCell>Contas a Pagar</TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, color: getColor(provisionedData.aPagar) }}
                >
                  {formatCurrencyBRL(provisionedData.aPagar)}
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Contas a Receber</TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, color: getColor(provisionedData.aReceber) }}
                >
                  {formatCurrencyBRL(provisionedData.aReceber)}
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Total de contas a pagar e a receber</TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, color: getColor(provisionedData.expectedEndPeriod) }}
                >
                  {formatCurrencyBRL(provisionedData.expectedEndPeriod)}
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Saldo atual das contas + provisionado</TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: 600, color: getColor(provisionedData.total) }}
                >
                  {formatCurrencyBRL(provisionedData.total)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ProvisionedTable;
