import { FC, useMemo } from "react";
import { Control, useWatch } from "react-hook-form";

import { TextField } from "@mui/material";

import calculateTotalItem from "../utils/calculateTotalItem";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { MovementForm } from "../utils/InterfacesForm";

interface TotalValueProps {
  control: Control<MovementForm>;
}

const TotalValue: FC<TotalValueProps> = ({ control }) => {
  const items = useWatch({
    control,
    name: "items",
  });

  const totalItems = useMemo(() => {
    return items.reduce((acc, item) => acc + calculateTotalItem(item), 0);
  }, [items]);

  return (
    <TextField
      label="Valor total (R$)"
      value={formatCurrencyBRL(totalItems)}
      fullWidth
      InputProps={{ readOnly: true }}
      sx={{ gridColumn: "4/-1" }}
    />
  );
};

export default TotalValue;
