import { useContext } from "react";
import { CompanyInfoContext } from ".";

export const useCompanyInfo = () => {
  const ctx = useContext(CompanyInfoContext);

  if (!ctx) {
    throw new Error("useCompanyInfo must be used within a CompanyInfoProvider");
  }

  return ctx;
};
