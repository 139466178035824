import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { withdraw3rd } from "services/Requests/billing";

const useMutationWithdraw3rdOsayk = () => {
  const mutation = useMutation(withdraw3rd, {
    onSuccess() {
      toast.success("Solicitação realizada com sucesso!");
    },
  });
  return mutation;
};

export default useMutationWithdraw3rdOsayk;
