import { FC, useMemo } from "react";
import useMutationUploadDocumentsDrive from "../../hooks/useMutationUploadDocumentsDrive";
import { DefaultValues, useForm, Controller } from "react-hook-form";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import CurrencyInput, { formattedInput } from "components/CurrencyInput";
import { DateTime } from "luxon";
import formatBytes from "utils/formatBytes";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

const minDate = DateTime.local(2000);
const maxDate = DateTime.now().plus({ years: 3 }).endOf("year");

interface NewFileForm {
  file: FileList;
  referenceDate: Date;
  dueDate: Date | null;
  value: string;
}

interface DialogNewFileProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  parentId?: string;
}

const DialogNewFile: FC<DialogNewFileProps> = ({ onClose, companyToken, parentId, ...props }) => {
  const { mutateAsync } = useMutationUploadDocumentsDrive();

  const defaultValues = useMemo<DefaultValues<NewFileForm>>(
    () => ({
      file: undefined,
      referenceDate: DateTime.local().toJSDate(),
      dueDate: null,
      value: undefined,
    }),
    [],
  );

  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<NewFileForm>({ defaultValues, resolver: yupResolver(validationSchema) });
  const fileList = watch("file");

  const handleClose = () => {
    if (isSubmitting) return;
    reset(defaultValues);
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      companyToken,
      fileList: data.file,
      referenceDate: DateTime.fromJSDate(data.referenceDate).toFormat("01/MM/yyyy"),
      dueDate: data.dueDate ? DateTime.fromJSDate(data.dueDate).toFormat("dd/MM/yyyy") : undefined,
      parentId,
      value: data.value ? formattedInput(data.value) : undefined,
    });
    handleClose();
  });

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="md" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Upload de Arquivos
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            p={2}
            sx={{ border: "2px dashed", borderColor: "divider" }}
          >
            <label style={{ width: "fit-content" }}>
              <input
                multiple
                type="file"
                style={{
                  display: "none",
                }}
                {...register("file")}
                name="file"
              />
              <Button
                variant="contained"
                component="span"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                Escolher arquivo
              </Button>

              {fileList && fileList.length !== 0 && (
                <Box mt={1}>
                  {Array.from(fileList).map((file) => (
                    <Typography color="text.secondary" variant="body2" key={file.name}>
                      {file.name} ({formatBytes(file.size)})
                    </Typography>
                  ))}
                </Box>
              )}
            </label>
            {!!errors.file?.message && (
              <FormHelperText error>{errors.file?.message}</FormHelperText>
            )}
          </Stack>

          <Stack direction="row" gap={1} mt={2} flexWrap="wrap">
            <Controller
              name="referenceDate"
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  label="Competência"
                  inputFormat="MM/yyyy"
                  views={["month", "year"]}
                  minDate={minDate}
                  maxDate={maxDate}
                  {...field}
                  allowSameDateSelection
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ flex: "1 220px" }}
                      helperText={fieldState.error?.message}
                      error={params.error || !!fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="dueDate"
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  label="Vencimento"
                  inputFormat="dd/MM/yyyy"
                  minDate={minDate}
                  maxDate={maxDate}
                  {...field}
                  allowSameDateSelection
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ flex: 1 }}
                      helperText={fieldState.error?.message}
                      error={params.error || !!fieldState.error?.message}
                    />
                  )}
                />
              )}
            />

            <Controller
              control={control}
              name="value"
              render={({ field, fieldState }) => (
                <CurrencyInput
                  {...field}
                  inputProps={{
                    sx: { flex: 1 },
                    label: "Valor",
                    error: !!fieldState.error?.message,
                    helperText: fieldState.error?.message,
                  }}
                />
              )}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton loading={isSubmitting} type="submit" variant="contained">
            Upload
          </LoadingButton>
          <Button variant="outlined" disabled={isSubmitting} onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogNewFile;
