import { FC, useState } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import DialogCreateTemplate from "./components/DialogCreateOrEditTemplate";
import Head from "components/Head";
import AddIcon from "@mui/icons-material/Add";

import getSubdomain from "utils/getSubdomain";
import TemplateList from "./TemplateList";

const Layouts: FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Head title={`Templates Exportação | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="h4" fontWeight="bold">
          Lista de Layouts
        </Typography>
        <Typography variant="h6" fontWeight={100} color="text.secondary">
          Exportação
        </Typography>

        <Button
          startIcon={<AddIcon fontSize="small" />}
          variant="contained"
          size="small"
          onClick={() => setOpen(true)}
          sx={{ ml: 1 }}
        >
          Novo layout
        </Button>
      </Stack>

      <Box mt={2} p={2} boxShadow={2} borderRadius={1} bgcolor="#fff">
        <TemplateList />
      </Box>

      <DialogCreateTemplate open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default Layouts;
