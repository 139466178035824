import { FC } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogProps,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useMutationCloseTicket from "../../hooks/useMutationCloseTicket";

interface DialogCloseTicketProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  ticketId: number;
}

const DialogCloseTicket: FC<DialogCloseTicketProps> = ({
  onClose,
  companyToken,
  ticketId,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationCloseTicket();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleCloseTicket = async () => {
    await mutateAsync({
      companyToken,
      ticketId,
    });
    handleClose();
  };

  return (
    <Dialog maxWidth="sm" fullWidth {...props} onClose={handleClose}>
      <DialogTitle>Concluir solicitação</DialogTitle>

      <DialogContent>Deseja concluir esta solicitação?</DialogContent>

      <DialogActions>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleCloseTicket}>
          Sim
        </LoadingButton>
        <Button disabled={isLoading} variant="outlined" onClick={handleClose}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCloseTicket;
