import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { deleteCategory } from "services/Requests/companies";

const useMutationDeleteCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteCategory, {
    onSuccess() {
      toast.success("Categoria deletada com sucesso");
      queryClient.invalidateQueries(queryKeys.financialCategories);
    },
  });
  return mutation;
};

export default useMutationDeleteCategory;
