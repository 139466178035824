import { FC } from "react";
import { Controller, Control } from "react-hook-form";

import { Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import ControllerInput from "components/ControllerInput";
import CurrencyInput from "components/CurrencyInput";
import BankAccountSelect from "../../BankAccountSelect";
import { ReleaseCreditForm } from "../Form";

interface BankFormProps {
  control: Control<ReleaseCreditForm>;
  companyToken: string;
}

const BankForm: FC<BankFormProps> = ({ companyToken, control }) => {
  return (
    <>
      <Controller
        control={control}
        name="companyBankAccount"
        render={({ fieldState, field }) => (
          <BankAccountSelect
            label="Conta"
            companyToken={companyToken}
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <ControllerInput control={control} name="description" inputProps={{ label: "Descrição" }} />

      <Controller
        control={control}
        name="value"
        render={({ field, fieldState }) => (
          <CurrencyInput
            {...field}
            inputProps={{
              label: "Valor",
              error: !!fieldState.error?.message,
              helperText: fieldState.error?.message,
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="retainedValue"
        render={({ field, fieldState }) => (
          <CurrencyInput
            {...field}
            inputProps={{
              label: "Tributos retidos",
              error: !!fieldState.error?.message,
              helperText: fieldState.error?.message,
              InputProps: {
                endAdornment: (
                  <Tooltip title="O valor digitado será descontado somente na emissão de cobrança">
                    <HelpIcon color="info" sx={{ cursor: "default" }} />
                  </Tooltip>
                ),
              },
            }}
          />
        )}
      />
    </>
  );
};

export default BankForm;
