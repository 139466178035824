import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import useQueryGetGroupChartOfAccounts from "./hooks/useQueryGetGroupChartOfAccounts";

import { CircularProgress, Grow, Stack, Typography } from "@mui/material";

import getSubdomain from "utils/getSubdomain";
import Head from "components/Head";
import Page from "./Page";

const AccountingItem: FC = () => {
  const params = useParams();
  const { isLoading, data, isError } = useQueryGetGroupChartOfAccounts(Number(params.id as string));

  return (
    <>
      <Head title={`Plano de contas | ${getSubdomain()}`} />

      {isLoading && (
        <Stack height="80vh" alignItems="center" justifyContent="center">
          <Grow in={isLoading}>
            <CircularProgress size={50} />
          </Grow>
        </Stack>
      )}

      {isError && (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "100%",
            width: "100%",
            p: 5,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              color: "text.primary",
            }}
          >
            Template não encontrado
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "text.secondary",
            }}
          >
            Tente mais tarde ou verifique a url.{" "}
            <Link style={{ textDecoration: "underline" }} to="/contabil">
              Clique aqui
            </Link>{" "}
            para voltar a lista
          </Typography>
        </Stack>
      )}

      {!isLoading && data && <Page groupChart={data} />}
    </>
  );
};

export default AccountingItem;
