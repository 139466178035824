import { FC, useState } from "react";
import { useAuth } from "context/Auth";

import Add from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";

import { MESSAGE_NO_COMPANY } from "utils/constants";
import getSubdomain from "utils/getSubdomain";
import Head from "components/Head";
import CategoryList from "./CategoryList";
import DialogCreateCategory from "./DialogCreateCategory";

const Categories: FC = () => {
  const {
    user: { company },
  } = useAuth();

  const [dialogCreateCategoryIsOpen, setDialogCreateCategoryIsOpen] = useState(false);

  return (
    <>
      <Head title={`Categorias | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Categorias
        </Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<Add fontSize="small" />}
          disabled={!company}
          onClick={() => setDialogCreateCategoryIsOpen(true)}
        >
          Nova categoria personalizada
        </Button>
      </Stack>

      <Box boxShadow={1} p={2} mt={2}>
        {company ? (
          <>
            <CategoryList companyToken={company.token} />
            <DialogCreateCategory
              companyToken={company.token}
              keepMounted={false}
              open={dialogCreateCategoryIsOpen}
              onClose={() => setDialogCreateCategoryIsOpen(false)}
            />
          </>
        ) : (
          <Typography variant="body2">{MESSAGE_NO_COMPANY}</Typography>
        )}
      </Box>
    </>
  );
};

export default Categories;
