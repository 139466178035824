import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { updateFinancialCategory } from "services/Requests/companies";

const useMutationUpdateFinancialCategory = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateFinancialCategory, {
    onSuccess(_, { groupId }) {
      toast.success("Categoria atualizada com sucesso");
      queryClient.invalidateQueries([queryKeys.globalCategoriesByGroup, groupId]);
    },
  });

  return mutation;
};

export default useMutationUpdateFinancialCategory;
