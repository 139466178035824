import { FC } from "react";
import { useForm, Controller } from "react-hook-form";
import useMutationDuplicateBillByAccountant from "../hooks/useMutationDuplicateBillByAccountant";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import CurrencyInput, { formattedInput } from "components/CurrencyInput";
import ControllerInput from "components/ControllerInput";

import { UserBill } from "types/Billing";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { DateTime } from "luxon";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

interface EditBillForm {
  value: string;
  dueDate: DateTime;
  description: string;
}

interface DialogEditBill extends Omit<DialogProps, "onClose"> {
  bill: UserBill;
  onClose: () => void;
  companyToken: string;
}

const DialogEditBill: FC<DialogEditBill> = ({ bill, onClose, companyToken, ...props }) => {
  const isTablet = useMediaQuery("(max-width:728px)");

  const { mutateAsync } = useMutationDuplicateBillByAccountant();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<EditBillForm>({
    resolver: yupResolver(validationSchema) as any,
    defaultValues: {
      value: formatCurrencyBRL(bill.value),
      dueDate: DateTime.fromISO(bill.dueDate),
      description: bill.description,
    },
  });

  const handleClose = () => {
    if (isSubmitting) return;
    onClose();
  };

  const onSubmit = handleSubmit(async (data: any) => {
    const dueDate: Date = data.dueDate;

    await mutateAsync({
      companyToken,
      id: bill.id,
      due_date: dueDate.toDateString(),
      item: {
        description: data.description,
        price_cents: formattedInput(data.value) * 100,
      },
    });
    onClose();
  });

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>Editar Fatura</DialogTitleWithCloseButton>

      <DialogContent sx={{ border: 1, borderColor: "divider" }}>
        <Box p={2} border={1} borderColor="divider" component="form" onSubmit={onSubmit}>
          <Stack direction={isTablet ? "column" : "row"} flexWrap="wrap" gap={2}>
            <Controller
              control={control}
              name="value"
              render={({ field, fieldState }) => (
                <CurrencyInput
                  {...field}
                  inputProps={{
                    label: "Valor",
                    error: !!fieldState.error?.message,
                    helperText: fieldState.error?.message,
                    sx: { flex: 1 },
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="dueDate"
              render={({ field, fieldState }) => (
                <DesktopDatePicker
                  label="Vencimento"
                  inputFormat="dd/MM/yyyy"
                  {...field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ flex: 1 }}
                      helperText={fieldState.error?.message}
                      error={params.error || !!fieldState.error?.message}
                    />
                  )}
                />
              )}
            />

            <ControllerInput
              control={control}
              name="description"
              inputProps={{
                label: "Descrição",
                sx: { flex: 2 },
              }}
            />
          </Stack>

          <Stack mt={1} direction="row" justifyContent="flex-end">
            <LoadingButton loading={isSubmitting} variant="contained" type="submit" size="small">
              Gerar segunda via
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={handleClose} disabled={isSubmitting}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogEditBill;
