import { useCallback } from "react";
import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getClient } from "services/Requests/marketplace";
import { NormalizedClient } from "types/Client";

const useQueryClient = (companyToken: string) => {
  const select = useCallback((clients: NormalizedClient[]) => clients[0], []);

  const query = useQuery([queryKeys.getClient, companyToken], () => getClient({ companyToken }), {
    select,
  });
  return query;
};

export default useQueryClient;
