import { FC, useMemo } from "react";
import { useAuth } from "context/Auth";
import useMutationUpdateTemplate from "../../hooks/useMutationUpdateTemplate";
import useMutationCreateTemplate from "../../hooks/useMutationCreateTemplate";
import { DefaultValues, useForm, Controller } from "react-hook-form";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import { Template } from "types/Template";

interface Fields {
  name: string;
  type: number;
  file: string;
  separate: string;
  final: string;
}

interface DialogCreateOrEditTemplateProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  template?: Template;
}

const DialogCreateOrEditTemplate: FC<DialogCreateOrEditTemplateProps> = ({
  onClose,
  template,
  ...props
}) => {
  const {
    user: { company },
  } = useAuth();
  const { mutateAsync: mutateCreate } = useMutationCreateTemplate();
  const { mutateAsync: mutateUpdate } = useMutationUpdateTemplate();

  const defaultValues = useMemo<DefaultValues<Fields>>(
    () => ({
      name: template?.name ?? "",
      type: template?.type ?? 1,
      file: template?.extension ?? "txt",
      separate: template?.separate ?? "",
      final: template?.delimiter ?? "",
    }),
    [template],
  );

  const {
    control,
    reset,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<Fields>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleClose = () => {
    if (isSubmitting) return;
    reset();
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    const payload = {
      companyToken: company?.token ?? null,
      name: data.name,
      type: data.type?.toString(),
      extension: data.file,
      separate: data.separate ?? "",
      delimiter: data.final ?? "",
    };

    const runMutation = async () => {
      if (template) {
        await mutateUpdate({
          ...payload,
          id: template.id,
        });
      } else {
        await mutateCreate(payload);
      }
    };

    await runMutation();
    handleClose();
  });

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {template ? "Editar" : "Novo"} template
      </DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent sx={{ display: "grid", gap: 2 }}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Nome do template"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="type"
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState.error?.message}>
                <InputLabel>Tipo do template</InputLabel>
                <Select {...field} label="Tipo do template">
                  <MenuItem value={1}>Trailer</MenuItem>
                  <MenuItem value={2}>Header e Trailer</MenuItem>
                </Select>
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="file"
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState.error?.message}>
                <InputLabel>Formato do arquivo</InputLabel>
                <Select {...field} label="Formato do arquivo">
                  <MenuItem value="txt">Texto</MenuItem>
                  <MenuItem value="csv">CSV</MenuItem>
                  <MenuItem value="xls">XLS</MenuItem>
                </Select>
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="separate"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Separador"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message ?? "(ex: ; , |)"}
              />
            )}
          />

          <Controller
            control={control}
            name="final"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Finalizador"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message ?? "(ex: ; , |)"}
              />
            )}
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" loading={isSubmitting} type="submit">
            Salvar
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose} disabled={isSubmitting}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreateOrEditTemplate;
