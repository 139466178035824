import { FC, useEffect, useMemo } from "react";
import { useForm, DefaultValues, Controller } from "react-hook-form";

import { Box, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import MaskedInput from "react-text-mask";
import CurrencyInput, { formattedInput } from "components/CurrencyInput";

import { CompanyInformation } from "types/Company";
import { cnpjMask, cnaeMask } from "utils/masks";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import CompanyActivitiesSelect from "../../CompanyActivitiesSelect";
import CNAEsByActivitySelect from "../../CNAEsByActivitySelect";
import AddressFields from "components/AddressFields";
import useMutationUpdateCompanyProfile from "../hooks/useMutationUpdateCompanyProfile";

interface CompanyForm {
  codeCompany: string;
  cnaeCompany: string;
  companyName: string;
  cnpj: string;
  socialCapital: string;
  companyActivityId: number;
  companyCNAEs: string[];

  street: string;
  neighborhood: string;
  zipCode: string;
  city: string;
  complement: string;
  state: string;
  streetNumber: number;
}

interface FormProps {
  company: CompanyInformation;
}

const Form: FC<FormProps> = ({ company }) => {
  const { mutateAsync } = useMutationUpdateCompanyProfile();

  const defaultValues = useMemo<DefaultValues<CompanyForm>>(
    () => ({
      cnaeCompany: company.activity,
      codeCompany: company.code,
      companyName: company.companyName,
      cnpj: company.cnpj,
      socialCapital: formatCurrencyBRL(company.socialCapital),
      companyActivityId: company.companyActivityId ?? undefined,
      companyCNAEs: company.companyCNAEs,
      state: company.state,
      city: company.countyId,
      complement: company.complement,
      neighborhood: company.neighborhood,
      street: company.street,
      streetNumber: Number(company.streetNumber),
      zipCode: company.zipCode,
    }),
    [company],
  );

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    watch,
  } = useForm<CompanyForm>({
    defaultValues,
  });

  const companyActivityId = watch("companyActivityId");

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name === "companyActivityId") {
        setValue("companyCNAEs", []);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      ...company,
      companyName: data.companyName,
      cnpj: company.cnpj,
      socialCapital: formattedInput(data.socialCapital),
      companyActivityId: data.companyActivityId,
      companyCNAEs: data.companyCNAEs,

      street: data.street,
      neighborhood: data.neighborhood,
      zipCode: data.zipCode,
      countyId: data.city,
      complement: data.complement,
      state: data.state,
      streetNumber: data.streetNumber?.toString(),

      code: data.codeCompany,
      activity: data.cnaeCompany,

      address: {
        ...company.address,
        street: data.street,
        neighborhood: data.neighborhood,
        zipCode: data.zipCode,
        countyId: data.city,
        complement: data.complement,
        state: data.state,
        streetNumber: data.streetNumber?.toString(),
      },
    });
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit(e);
      }}
    >
      <Typography
        variant="h6"
        fontWeight={400}
        sx={{ borderBottom: 1, borderBottomColor: "divider" }}
      >
        Informações da empresa
      </Typography>

      <Stack gap={2} mt={3}>
        <Box display="grid" gap={2} gridTemplateColumns="repeat(3, 1fr)">
          <Controller
            control={control}
            name="codeCompany"
            render={({ field, fieldState }) => (
              <TextField
                label="Código"
                {...field}
                helperText={fieldState.error?.message}
                error={!!fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="cnaeCompany"
            render={({ field, fieldState }) => (
              <MaskedInput
                mask={cnaeMask}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="CNAE"
                    inputRef={ref}
                    {...props}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message ?? "Formato ex.: 00.00-0/00"}
                  />
                )}
              />
            )}
          />
        </Box>

        <Controller
          control={control}
          name="companyName"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Nome da empresa"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />

        <Box display="grid" gap={2} gridTemplateColumns="repeat(3, 1fr)">
          <Controller
            control={control}
            name="cnpj"
            render={({ field, fieldState }) => (
              <MaskedInput
                mask={cnpjMask}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="CNPJ"
                    inputRef={ref}
                    {...props}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />

          <Controller
            control={control}
            name="socialCapital"
            render={({ field, fieldState }) => (
              <CurrencyInput
                {...field}
                inputProps={{
                  label: "Capital social",
                  error: !!fieldState.error?.message,
                  helperText: fieldState.error?.message,
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="companyActivityId"
            render={({ field, fieldState }) => (
              <CompanyActivitiesSelect
                {...field}
                label="Área"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>

        <Controller
          control={control}
          name="companyCNAEs"
          render={({ field, fieldState }) => (
            <CNAEsByActivitySelect
              {...field}
              activityId={companyActivityId}
              label="Definição das atividades"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
            />
          )}
        />

        <AddressFields control={control} setValue={setValue} />
      </Stack>

      <Stack alignItems="end" justifyContent="end" mt={2}>
        <LoadingButton loading={isSubmitting} variant="contained" type="submit">
          Salvar
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default Form;
