import { FC } from "react";

import { Box, Typography } from "@mui/material";

import Head from "components/Head";
import Configuration from "../Invoices/Configuration";
import getSubdomain from "utils/getSubdomain";

const ConfigurationNFSe: FC = () => {
  return (
    <>
      <Head title={`NFS-e Configurações | ${getSubdomain()}`} />

      <Typography variant="h4" fontWeight="bold" sx={{ mr: 1 }}>
        NFS-e Configurações
      </Typography>

      <Box boxShadow={1} p={2} mt={2}>
        <Configuration isClient />
      </Box>
    </>
  );
};

export default ConfigurationNFSe;
