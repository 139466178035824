import { FC } from "react";
import useQueryGetConfiguration from "../hooks/useQueryGetConfiguration";
import ConfigurationForm from "./ConfigurationForm";
import { Box, Skeleton, Stack, Typography } from "@mui/material";

interface ConfigurationProps {
  companyToken: string;
}

const Configuration: FC<ConfigurationProps> = ({ companyToken }) => {
  const { data, isLoading } = useQueryGetConfiguration(companyToken);

  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 2 }}
      >
        Configure sua marca nas mensagens por email
      </Typography>

      {isLoading && (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={2}
            flexWrap="wrap"
          >
            <Skeleton variant="rectangular" sx={{ flex: 1, flexBasis: "330px", height: 56 }} />
            <Skeleton variant="rectangular" sx={{ flex: 1, flexBasis: "330px", height: 56 }} />
          </Stack>
          <Stack alignItems="end" justifyContent="center" mt={1}>
            <Skeleton variant="rectangular" height={36} width={86} />
          </Stack>
        </>
      )}

      {data && <ConfigurationForm configuration={data} companyToken={companyToken} />}
    </Box>
  );
};

export default Configuration;
