import React, { ChangeEvent, FC } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

interface UploadImageProps {
  file: File | null;
  onChange: (file: File | null) => void;
  label: string;
  initialImage?: string | null;
}

const UploadImage: FC<UploadImageProps> = ({ file, label, onChange, initialImage }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target?.files[0];

    if (!file) onChange(null);
    onChange(file);
  };

  return (
    <Box>
      <Typography>{label}</Typography>

      <Stack direction="row" alignItems="start" gap={2} mt={1} mb={2}>
        <Button
          sx={{ width: "fit-content" }}
          variant="contained"
          color="secondary"
          component="label"
        >
          <input
            hidden
            accept="image/png, image/gif, image/jpeg"
            type="file"
            onChange={handleChange}
          />
          {file ? "Alterar arquivo" : "Procurar"}
        </Button>

        {file || initialImage ? (
          <img
            width={200}
            height={50}
            style={{
              objectFit: "contain",
              border: 1,
              borderColor: grey[300],
              borderStyle: "dashed",
            }}
            src={file ? URL.createObjectURL(file) : initialImage || ""}
            alt="preview imagem"
          />
        ) : (
          <Typography variant="body1">Nenhuma imagem</Typography>
        )}
      </Stack>

      <Typography variant="body1" color={grey[500]}>
        Apenas arquivos JPG, GIF ou PNG, resolução máxima de 200x50 pixels.
      </Typography>
    </Box>
  );
};

export default UploadImage;
