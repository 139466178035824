import { FinancialCategoryByGroupWithChildren } from "types/Company";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  Move_Column = "Move_Column",
  Move_Card = "Move_Card",
  Add_Card_To_Column = "Add_Card_To_Column",
  Set_Data = "Set_Data",
}

type TemplatePayload = {
  [Types.Move_Column]: {
    indexFrom: number;
    indexTo: number;
  };
  [Types.Move_Card]: {
    indexFrom: number;
    indexFromColumn: number;
    indexTo: number;
    indexToColumn: number;
  };
  [Types.Add_Card_To_Column]: {
    index: number;
    indexColumn: number;
    indexToColumn: number;
  };
  [Types.Set_Data]: {
    data: FinancialCategoryByGroupWithChildren[];
  };
};

export type TemplateActions = ActionMap<TemplatePayload>[keyof ActionMap<TemplatePayload>];
