import { FC, useEffect } from "react";
import useMutationParcialTransaction from "pages/Home/hooks/useMutationParcialTransaction";
import { useForm, Controller } from "react-hook-form";

import {
  Alert,
  Box,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import { CompanyParticipants, Transaction } from "types/Company";

import CategorySelect from "../CategorySelect";
import CustomCategorySelect from "../CustomCategorySelect";
import ControllerInput from "components/ControllerInput";
import BankAccountSelect from "../BankAccountSelect";
import RecipientAutocomplete from "../RecipientAutocomplete";
import CurrencyInput, { formattedInput } from "components/CurrencyInput";

import { DateTime } from "luxon";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import toast from "react-hot-toast";

interface Form {
  category: number;
  customCategory: number | undefined;
  description: string;
  bank: number;
  companyParticipant: CompanyParticipants;
  paymentDate: Date;
  value: string;
}

interface DialogFormProps {
  transaction: Transaction;
  onClose: () => void;
  companyToken: string;
}

const DialogForm: FC<DialogFormProps> = ({ transaction, onClose, companyToken }) => {
  const { mutateAsync } = useMutationParcialTransaction();

  const handleClose = () => {
    onClose();
  };
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    defaultValues: {
      category: transaction.globalCategoryId || undefined,
      customCategory: transaction.categoryId || undefined,
      description: transaction.description || "",
      bank: transaction.companyBankAccountId || undefined,
      companyParticipant:
        transaction.companyParticipantId && transaction.participantName
          ? ({
              id: transaction.companyParticipantId,
              name: transaction.participantName,
            } as CompanyParticipants)
          : undefined,
      paymentDate: new Date(),
      value: formatCurrencyBRL(transaction.value),
    },
  });
  const categoryId = watch("category");
  const value = watch("value");

  useEffect(() => {
    setValue("customCategory", undefined);
  }, [categoryId]);

  const onSubmit = handleSubmit(async (data) => {
    const value = formattedInput(data.value);
    const paymentDateISO = DateTime.fromJSDate(data.paymentDate).toISO();

    if (value > Math.abs(transaction.value)) {
      toast.error("Valor maior que o valor do lançamento, verifique.");
      return;
    }

    await mutateAsync({
      companyBankAccountId: data.bank,
      companyParticipantId: data.companyParticipant.id,
      companyToken,
      description: data.description,
      categoryId: data.customCategory,
      globalCategoryId: data.category,
      paymentDate: paymentDateISO,
      transactionId: transaction.id,
      value,
    });
    handleClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <Collapse in={formattedInput(value) < Math.abs(transaction.value)}>
          <Alert severity="warning" sx={{ mb: 1 }}>
            Será criado um novo lançamento no valor de{" "}
            {formatCurrencyBRL(Math.abs(transaction.value) - formattedInput(value))}
          </Alert>
        </Collapse>

        <Typography variant="body1" fontWeight={600}>
          Dados da provisão:
        </Typography>

        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mt={2}>
          <Controller
            control={control}
            name="category"
            render={({ fieldState, field }) => (
              <CategorySelect
                label="Categoria"
                companyToken={companyToken}
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="customCategory"
            render={({ fieldState, field }) => (
              <CustomCategorySelect
                parentId={categoryId}
                label="Categoria personalizada"
                companyToken={companyToken}
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <ControllerInput
            name="description"
            control={control}
            inputProps={{ label: "Descrição" }}
          />

          <Controller
            control={control}
            name="bank"
            render={({ fieldState, field }) => (
              <BankAccountSelect
                label="Conta"
                companyToken={companyToken}
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="companyParticipant"
            render={({ fieldState, field }) => (
              <RecipientAutocomplete
                sx={{ gridColumn: "1/-1" }}
                companyToken={companyToken}
                value={field.value}
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={(_, value) => field.onChange(value)}
                textFieldProps={{
                  error: !!fieldState.error?.message,
                  helperText: fieldState.error?.message,
                }}
              />
            )}
          />

          <TextField
            label="Vence em"
            value={
              transaction.dueDate
                ? DateTime.fromISO(transaction.dueDate).toFormat("dd/MM/yyyy")
                : ""
            }
            disabled
          />
          <TextField label="Valor" value={formatCurrencyBRL(transaction.value)} disabled />

          <Divider sx={{ gridColumn: "1/-1", my: 1 }} />

          <Typography variant="body1" fontWeight={600} sx={{ gridColumn: "1/-1" }}>
            Digite o valor total ou parcial para dar baixa na provisão:
          </Typography>

          <Controller
            name="paymentDate"
            control={control}
            render={({ field, fieldState }) => (
              <DatePicker
                label={`${transaction.transactionType === "Debt" ? "Pago" : "Recebido"} em`}
                inputFormat="dd/MM/yyyy"
                {...field}
                allowSameDateSelection
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={fieldState.error?.message}
                    error={params.error || !!fieldState.error?.message}
                  />
                )}
              />
            )}
          />

          <Controller
            name="value"
            control={control}
            render={({ field, fieldState }) => (
              <CurrencyInput
                {...field}
                inputProps={{
                  label: "Valor",
                  error: !!fieldState.error?.message,
                  helperText: fieldState.error?.message,
                }}
              />
            )}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton type="submit" loading={isSubmitting} variant="contained">
          Salvar
        </LoadingButton>
        <Button variant="outlined" disabled={isSubmitting} onClick={handleClose}>
          Cancelar
        </Button>
      </DialogActions>
    </form>
  );
};

export default DialogForm;
