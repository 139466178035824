import { FC } from "react";

import { Form } from ".";
import { Control } from "react-hook-form";
import {
  Box,
  Stack,
  Typography,
} from "@mui/material";

import { ActiveAndContract, PaymentMethodFields, RecurrenceFields } from "../../Fields";

interface PropertiesAndBillingProps {
  control: Control<Form>;
  companyToken: string;
}

const PropertiesAndBilling: FC<PropertiesAndBillingProps> = ({ control, companyToken }) => {
  return (
    <Box>
      <Box mb={4}>
        <ActiveAndContract control={control} companyToken={companyToken} />
      </Box>

      <Typography
        variant="h5"
        sx={{ borderBottom: 1, borderBottomColor: "divider", fontWeight: 600, mb: 2 }}
      >
        Cobrança
      </Typography>

      <Stack direction="row" gap={2} mb={4}>
        <PaymentMethodFields control={control} />
        <RecurrenceFields control={control} />
      </Stack>
    </Box>
  );
};

export default PropertiesAndBilling;
