import { FC } from "react";
import { useAuth } from "context/Auth";

import { Control } from "react-hook-form";
import { CreatePlanForm } from ".";

import ControllerInput from "components/ControllerInput";
import { ActiveAndContract } from "../../Fields";
import { Stack } from "@mui/material";

interface InformationPlanProps {
  control: Control<CreatePlanForm>;
}

const InformationPlan: FC<InformationPlanProps> = ({ control }) => {
  const {
    user: { company },
  } = useAuth();

  return (
    <Stack gap={2}>
      <ControllerInput
        control={control}
        name="name"
        inputProps={{ label: "Nome do plano", fullWidth: true }}
      />

      <ActiveAndContract control={control} companyToken={company!.token} />
    </Stack>
  );
};

export default InformationPlan;
