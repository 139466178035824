import { FC, useState } from "react";

import { IconButton, MenuItem, TableCell, TableRow } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DropdownMenu from "components/DropdownMenu";
import DialogCreateOrUpdateAdminUser from "pages/Home/components/DialogCreateOrUpdateAdminUser";
import DialogSendPassowordUser from "pages/Home/components/DialogSendPasswordUser";

import { UserCompany } from "types/Admin";
import DialogBlockOrUnBlockUser from "pages/Home/components/DialogBlockOrUnBlockUser";
import DialogDeleteAdminUser from "pages/Home/components/DialogDeleteAdminUser";

interface UserTableItemProps {
  companyToken: string;
  userCompany: UserCompany;
}

const UserTableItem: FC<UserTableItemProps> = ({ companyToken, userCompany }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const [isEdit, setIsEdit] = useState(false);
  const [isReSentPassword, setIsReSentPassword] = useState(false);
  const [blockOrUnBlockIsOpen, setBlockOrUnBlockIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  return (
    <>
      <TableRow hover selected={isOpen}>
        <TableCell>{userCompany.name}</TableCell>
        <TableCell>{userCompany.login}</TableCell>
        <TableCell align="right">
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MenuIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setIsEdit(true)}>Editar</MenuItem>
        <MenuItem onClick={() => setIsReSentPassword(true)}>Reenviar senha</MenuItem>
        {userCompany.status === "Active" && (
          <MenuItem onClick={() => setBlockOrUnBlockIsOpen(true)}>Bloquear</MenuItem>
        )}
        {userCompany.status === "Block" && (
          <MenuItem onClick={() => setBlockOrUnBlockIsOpen(true)}>Desbloquear</MenuItem>
        )}
        <MenuItem onClick={() => setIsDelete(true)}>Excluir</MenuItem>
      </DropdownMenu>

      {isEdit && (
        <DialogCreateOrUpdateAdminUser
          open={isEdit}
          onClose={() => setIsEdit(false)}
          companyToken={companyToken}
          userType={"Guest"}
          user={{ ...userCompany, email: userCompany.login }}
        />
      )}

      {isReSentPassword && (
        <DialogSendPassowordUser
          open={isReSentPassword}
          onClose={() => setIsReSentPassword(false)}
          companyToken={companyToken}
          user={userCompany}
        />
      )}

      {blockOrUnBlockIsOpen && (
        <DialogBlockOrUnBlockUser
          open={blockOrUnBlockIsOpen}
          onClose={() => setBlockOrUnBlockIsOpen(false)}
          user={userCompany}
          userType="Guest"
        />
      )}

      {isDelete && (
        <DialogDeleteAdminUser
          open={isDelete}
          onClose={() => setIsDelete(false)}
          user={userCompany}
          userType="Guest"
        />
      )}
    </>
  );
};

export default UserTableItem;
