import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCNAEsByActivity } from "services/Requests/openCompany";

const useQueryCNAEsByActivity = (activityId?: number) => {
  const query = useQuery(
    [queryKeys.CNAEsByActivity, activityId],
    () => getCNAEsByActivity({ companyActivityId: activityId || 0 }),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!activityId,
    },
  );
  return query;
};

export default useQueryCNAEsByActivity;
