import { FC, useState } from "react";
import Menu from "@mui/icons-material/Menu";
import {
  IconButton,
  TableCell,
  TableRow,
  Menu as DropdownMenu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Group } from "types/Mailbox";
import useMutationUpdateGroup from "../hooks/useMutationUpdateGroup";
import { useAuth } from "context/Auth";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";

interface GroupTableItemProps {
  group: Group;
  onEdit: () => void;
}

const GroupTableItem: FC<GroupTableItemProps> = ({ group, onEdit }) => {
  const {
    user: { company },
  } = useAuth();

  const { isLoading, mutateAsync } = useMutationUpdateGroup();
  const [confirmationDeleteGroup, setConfirmationDeleteGroup] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickInDelete = () => {
    if (!company) {
      toast("Empresa não localzada, não é possível realizar a exclusão", {
        icon: "⚠️",
      });
      return;
    }

    setConfirmationDeleteGroup(true);
  };

  const handleCloseConfirmation = () => {
    if (isLoading) return;
    setConfirmationDeleteGroup(false);
  };

  const deleteThisGroup = async () => {
    if (!company) return;

    await mutateAsync({
      companyToken: company?.token,
      groupId: group.id,
      isDeleted: true,
    });
    handleCloseConfirmation();
  };

  return (
    <>
      <TableRow>
        <TableCell>{group.description}</TableCell>
        <TableCell align="right">
          <IconButton onClick={handleClick}>
            <Menu />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => onEdit()}>Editar</MenuItem>
        <MenuItem onClick={handleClickInDelete}>Excluir</MenuItem>
      </DropdownMenu>

      <Dialog
        open={confirmationDeleteGroup}
        onClose={handleCloseConfirmation}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Excluir grupo</DialogTitle>
        <DialogContent>Deseja excluir este grupo?</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseConfirmation}>
            Não
          </Button>
          <LoadingButton variant="contained" loading={isLoading} onClick={deleteThisGroup}>
            Sim
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GroupTableItem;
