import { forwardRef, Ref, useMemo } from "react";
import useQueryGetFinancialCategories from "pages/Home/hooks/useQueryGetFinancialCategories";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { FinancialCategory } from "types/Company";

interface FinancialCategoryWithIndent extends FinancialCategory {
  indent: number;
}

interface CategorySelectInnerProps extends SelectProps {
  companyToken: string;
  helperText?: string;
}

function CategorySelectInner(
  { companyToken, helperText, ...props }: CategorySelectInnerProps,
  ref?: Ref<unknown>,
) {
  const {
    data: financialCategories = [],
    isLoading,
    isError,
  } = useQueryGetFinancialCategories(companyToken);

  const financialCategoryWithIndent = useMemo(() => {
    const generateChildren = (
      category: FinancialCategory[],
      indent: number,
    ): FinancialCategoryWithIndent[] => {
      return category
        .map((c) => {
          if (!c.parentUId) {
            return [{ ...c, indent: indent }, generateChildren(c.children, indent + 1)];
          }
          return [{ ...c, indent: indent }];
        })
        .flat(Infinity) as FinancialCategoryWithIndent[];
    };

    return generateChildren(financialCategories, 1);
  }, [financialCategories]);

  return (
    <FormControl size={props.size}>
      <InputLabel>{props.label}</InputLabel>
      <Select ref={ref} {...props} value={props.value || ""}>
        <MenuItem disabled value="">
          Selecione:{" "}
        </MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um erro ao buscar as categorias</MenuItem>}

        {financialCategoryWithIndent.map((category) => (
          <MenuItem
            disabled={!category.parentUId}
            key={category.id}
            value={category.id}
            sx={{
              pl: 2 * category.indent,
              opacity: "1 !important",
            }}
          >
            {!!category.parentUId && <ListItemIcon sx={{ minWidth: 18 }}>●</ListItemIcon>}
            {category.name}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const CategorySelect = forwardRef(CategorySelectInner);
export default CategorySelect;
