import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { sendPasswordUser } from "services/Requests/admin";

const useMutationSendPasswordUser = () => {
  const mutation = useMutation(sendPasswordUser, {
    onSuccess() {
      toast.success("Senha reenviada com sucesso");
    },
  });
  return mutation;
};

export default useMutationSendPasswordUser;
