import { forwardRef, ForwardRefRenderFunction } from "react";
import { TextField, TextFieldProps } from "@mui/material";

import MaskedInput, { MaskedInputProps } from "react-text-mask";
import { createNumberMask } from "text-mask-addons";

export const formattedInput = (value = ""): number => {
  return Number(
    value
      .replace(/[^0-9,\-.]/g, "")
      .replace(/(R\$)|\./g, "")
      .trim()
      .replace(/,/g, "."),
  );
};

interface CurrencyInputProps extends Partial<MaskedInputProps> {
  numberMask?: Partial<{
    prefix: string;
    suffix: string;
    includeThousandsSeparator: boolean;
    thousandsSeparatorSymbol: string;
    allowDecimal: boolean;
    decimalSymbol: string;
    decimalLimit: number;
    requireDecimal: boolean;
    allowNegative: boolean;
    allowLeadingZeroes: boolean;
    integerLimit: number;
  }>;
  inputProps?: TextFieldProps;
}

const CurrencyInput: ForwardRefRenderFunction<MaskedInput, CurrencyInputProps> = (
  { numberMask, inputProps, ...props },
  ref,
) => {
  const currencyMask = createNumberMask({
    prefix: "R$ ",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    decimalLimit: 2,
    integerLimit: 8,
    allowNegative: false,
    allowLeadingZeroes: false,
    ...numberMask,
  });

  return (
    <MaskedInput
      {...props}
      ref={ref}
      mask={currencyMask}
      inputMode="numeric"
      render={(ref, props) => <TextField inputRef={ref} {...inputProps} {...props} />}
    />
  );
};

export default forwardRef(CurrencyInput);
