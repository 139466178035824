import { FC, ReactNode } from "react";
import { FinancialCategoryIdInterface, FinancialCategoryIdContext } from ".";

interface FinancialCategoryIdProviderProps extends FinancialCategoryIdInterface {
  children: ReactNode;
}

export const FinancialCategoryIdProvider: FC<FinancialCategoryIdProviderProps> = ({
  children,
  ...props
}) => (
  <FinancialCategoryIdContext.Provider value={props}>
    {children}
  </FinancialCategoryIdContext.Provider>
);
