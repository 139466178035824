import { FC } from "react";
import { useAuth } from "context/Auth";

import Head from "components/Head";
import CardWrapper from "./components/CardWrapper";
import { Box, Typography } from "@mui/material";

import UserData from "./components/UserData";
import CompanyData from "./components/CompanyData";
import PaymentMethods from "./components/PaymentMethods";
import InvoiceHistory from "./components/InvoiceHistory";
import Contract from "./components/Contract";
import RegistrationForBilling from "./components/RegistrationForBilling";
import getSubdomain from "utils/getSubdomain";

const Profile: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <>
      <Head title={`Meu Perfil | ${getSubdomain()}`} />
      <Typography variant="h4" fontWeight="bold">
        Perfil
      </Typography>

      {!company ? (
        <Typography variant="subtitle1">
          Você precisa está vinculado à uma empresa para acessar as informações dessa página!
        </Typography>
      ) : (
        <Box
          mt={2}
          display="grid"
          gridTemplateColumns="repeat(auto-fit, minmax(500px, 1fr))"
          gap={2}
        >
          <CardWrapper title="Dados do Usuário">
            <UserData companyToken={company.token} />
          </CardWrapper>

          <CardWrapper title="Dados da Empresa">
            <CompanyData companyToken={company.token} />
          </CardWrapper>

          <CardWrapper title="Métodos de Pagamento">
            <PaymentMethods companyToken={company.token} />
          </CardWrapper>

          <CardWrapper title="Histórico de Faturas">
            <InvoiceHistory companyToken={company.token} />
          </CardWrapper>

          <CardWrapper title="Contrato">
            <Contract />
          </CardWrapper>

          <RegistrationForBilling companyToken={company.token} />
        </Box>
      )}
    </>
  );
};

export default Profile;
