import { FC } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  TextField,
} from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import BankAccountSelect from "../BankAccountSelect";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import useMutationSettleTransactions from "pages/Home/hooks/useMutationSettleTransactions";

interface DialogSettlementFormProps extends Omit<DialogProps, "onClose"> {
  transactionIds: number[];
  companyToken: string;
  onClose: () => void;
}

const DialogSettlementForm: FC<DialogSettlementFormProps> = ({
  onClose,
  companyToken,
  transactionIds,
  ...props
}) => {
  const { mutateAsync } = useMutationSettleTransactions();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<{ paymentDate: Date; bankId: number }>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      paymentDate: DateTime.local().toJSDate(),
      bankId: undefined,
    },
  });

  const handleClose = () => {
    if (isSubmitting) return;
    reset({
      paymentDate: DateTime.local().toJSDate(),
      bankId: undefined,
    });
    onClose();
  };

  const onSubmit = handleSubmit(async ({ bankId, paymentDate }) => {
    await mutateAsync({
      bankId,
      companyToken,
      settlementDate: DateTime.fromJSDate(paymentDate).toISO(),
      transactionIds,
    });
    handleClose();
  });

  return (
    <Dialog onClose={handleClose} {...props} fullWidth maxWidth="md">
      <DialogTitleWithCloseButton onClose={handleClose}>Pagar e receber</DialogTitleWithCloseButton>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
            <Controller
              name={"paymentDate"}
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  label="Data de pagamento"
                  inputFormat="dd/MM/yyyy"
                  maxDate={DateTime.local().endOf("day")}
                  minDate={DateTime.local().minus({ year: 1 })}
                  {...field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={fieldState.error?.message}
                      error={params.error || !!fieldState.error?.message}
                    />
                  )}
                />
              )}
            />

            <Controller
              name={"bankId"}
              control={control}
              render={({ field, fieldState }) => (
                <BankAccountSelect
                  label="Conta"
                  companyToken={companyToken}
                  sx={{ minWidth: 240 }}
                  {...field}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            type="submit"
            startIcon={<CheckIcon />}
          >
            Conciliar
          </LoadingButton>
          <Button
            variant="outlined"
            disabled={isSubmitting}
            onClick={handleClose}
            startIcon={<CloseIcon />}
          >
            Fechar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogSettlementForm;
