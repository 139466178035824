import { FC, useState } from "react";

import Menu from "@mui/icons-material/Menu";
import {
  IconButton,
  TableCell,
  TableRow,
  Menu as DropdownMenu,
  MenuItem,
  Link,
} from "@mui/material";
import DeleteContract from "./DeleteContract";

import { BASE_URL_DOWNLOAD_DOCUMENT } from "utils/constants";
import { Contract } from "types/Contract";
import { DateTime } from "luxon";

interface ContractTableItemProps {
  contract: Contract;
  companyToken: string;
}

const ContractTableItem: FC<ContractTableItemProps> = ({ contract, companyToken }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableRow hover>
        <TableCell>{DateTime.fromISO(contract.createdOn).toFormat("dd/MM/yyyy")}</TableCell>
        <TableCell>{contract.filename}</TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={handleClick}>
            <Menu />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>
          <Link
            href={`${BASE_URL_DOWNLOAD_DOCUMENT}?id=${contract.downloadToken}&fullPath=/Contratos+Servicos&preview=true`}
            target="_blank"
            color="text.primary"
            sx={{ textDecoration: "none" }}
          >
            Visualizar
          </Link>
        </MenuItem>
        <MenuItem onClick={() => setConfirmDelete(true)}>Excluir</MenuItem>
      </DropdownMenu>

      <DeleteContract
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        companyToken={companyToken}
        documentToken={contract.downloadToken}
      />
    </>
  );
};

export default ContractTableItem;
