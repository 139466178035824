import { FC, useState } from "react";
import { useSlate } from "slate-react";

import { IconButton, Popover, Stack, TextField } from "@mui/material";
import LinkOff from "@mui/icons-material/LinkOff";
import Link from "@mui/icons-material/Link";
import Add from "@mui/icons-material/Add";

import { isBlockActive } from "../../utils/functions";
import { insertLink, removeLink } from "../../utils/link";

export const ButtonLink: FC = () => {
  const editor = useSlate();
  const isActive = isBlockActive(editor, "link", "type");

  const [url, setUrl] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    setUrl("");
  };

  return (
    <>
      <IconButton
        size="small"
        color={isActive ? "primary" : "default"}
        onMouseDown={(event) => {
          event.preventDefault();
          if (isActive) {
            removeLink(editor);
            return;
          }
          setAnchorEl(event.currentTarget);
        }}
      >
        {isActive ? (
          <LinkOff
            fontSize="small"
            sx={{
              transition: "all 500ms ease",
              transform: `scale(${isActive ? "1.1" : "1"})`,
            }}
          />
        ) : (
          <Link
            fontSize="small"
            sx={{
              transition: "all 500ms ease",
              transform: `scale(${isActive ? "1.1" : "1"})`,
            }}
          />
        )}
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack direction="row" p={2} alignItems="center" gap={1} width={350}>
          <TextField
            fullWidth
            size="medium"
            label="Digite a url"
            placeholder="https://www.site.com"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            autoFocus
          />
          <IconButton
            size="medium"
            color="primary"
            onClick={() => {
              insertLink(editor, url);
              handleClose();
            }}
          >
            <Add fontSize="medium" />
          </IconButton>
        </Stack>
      </Popover>
    </>
  );
};
