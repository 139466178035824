import { useMutation, useQueryClient } from "react-query";

import { createSubscription, CreateSubscriptionPayload } from "services/Requests/billing";
import getSubdomain from "utils/getSubdomain";
import queryKeys from "react-query/constants";
import toast from "react-hot-toast";

const useMutationCreateSubscription = () => {
  const subdomain = getSubdomain();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload: Omit<CreateSubscriptionPayload, "subdomain">) =>
      createSubscription({
        ...payload,
        subdomain,
      }),
    {
      onSuccess(_, { payerCompanyId }) {
        queryClient.invalidateQueries([queryKeys.clientDetails, payerCompanyId]);
        queryClient.invalidateQueries([queryKeys.clientBills, payerCompanyId]);

        toast.success(
          "Solicitação para criação de assinatura registrada com sucesso!\nPor favor aguarde um pouco para a solicitação ser processada.",
        );
      },
    },
  );

  return mutation;
};

export default useMutationCreateSubscription;
