import { FC } from "react";
import { useAuth } from "context/Auth";

import { Typography } from "@mui/material";
import Head from "components/Head";

import MonthList from "./MonthList";
import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";

const MonthClosure: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <>
      <Head title={`Fechamento do mês | ${getSubdomain()}`} />

      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Fechamento do mês
      </Typography>

      {company ? (
        <MonthList companyToken={company.token} />
      ) : (
        <Typography>{MESSAGE_NO_COMPANY}</Typography>
      )}
    </>
  );
};

export default MonthClosure;
