import { forwardRef, Ref } from "react";
import useQueryModalities from "pages/Home/hooks/useQueryModalities";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface ModalitiesSelectInnerProps extends SelectProps {
  helperText?: string;
}

function ModalitiesSelectInner(
  { helperText, ...props }: ModalitiesSelectInnerProps,
  ref?: Ref<unknown>,
) {
  const { data: modalities = [], isLoading, isError } = useQueryModalities();

  return (
    <FormControl size={props.size}>
      <InputLabel>{props.label}</InputLabel>
      <Select ref={ref} {...props} value={props.value || ""}>
        <MenuItem disabled value="">
          Selecione:{" "}
        </MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um erro ao buscar as modalidades</MenuItem>}

        {modalities.map((modality) => (
          <MenuItem key={modality.id} value={modality.id}>
            {modality.description}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const ModalitiesSelect = forwardRef(ModalitiesSelectInner);
export default ModalitiesSelect;
