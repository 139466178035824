import { FC, useState } from "react";

import MaskedInput from "react-text-mask";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";

import ControllerInput from "components/ControllerInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

import { cardNumberMask } from "utils/masks";
import useQueryPaymentInfo from "../../../hooks/useQueryPaymentInfo";
import useMutationSetPaymentMethodProfile from "../../../hooks/useMutationSetPaymentMethodProfile";
import toast from "react-hot-toast";

interface DialogChangePaymentForCreditProps extends DialogProps {
  companyToken: string;
}

const DialogChangePaymentForCredit: FC<DialogChangePaymentForCreditProps> = ({
  companyToken,
  onClose,
  ...props
}) => {
  const { data: paymentInfo, isLoading } = useQueryPaymentInfo(companyToken);
  const { mutateAsync } = useMutationSetPaymentMethodProfile();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cardNumber: "",
      name: "",
      monthValidity: "",
      yearValidity: "",
      securityCode: "",
    },
  });

  const close = () => {
    onClose?.({}, "backdropClick");
    reset();
    setIsSubmitting(false);
  };

  const setPayment = async (token: string) => {
    await mutateAsync({
      companyToken,
      description: "Cartão",
      paymentMethod: "card",
      paymentToken: token,
    });
    close();
  };

  const onSubmit = handleSubmit((data: any) => {
    if (!paymentInfo || !paymentInfo.iuguAccountId) {
      toast.error("Informações da conta não foram encontradas!");
      return;
    }
    setIsSubmitting(true);

    const year = data.yearValidity.getFullYear();
    const month = (new Date(data.monthValidity).getMonth() + 1).toString().padStart(2, "0");

    const splittedName = data.name.split(/[\s ]+/);

    const firstName = splittedName[0];
    splittedName.shift();
    const lastName = splittedName.join(" ");
    const cardNumber = data.cardNumber.replace(/ /g, "");

    Iugu.setAccountID(paymentInfo.iuguAccountId);
    Iugu.setTestMode(false);
    Iugu.setup();

    Iugu.createPaymentToken(
      {
        month,
        year,
        first_name: firstName,
        last_name: lastName,
        number: cardNumber,
        verification_value: data.securityCode,
      },
      async (response) => {
        if (response.errors) {
          console.log(response.errors);
          toast.error("Erro ao processar o cartão de crédito.");
          setIsSubmitting(false);
        }

        if (response.id) {
          setPayment(response.id);
        }
      },
    );
  });

  return (
    <Dialog maxWidth="sm" fullWidth onClose={close} {...props}>
      <DialogTitle>Dados do cartão de crédito</DialogTitle>

      <form onSubmit={onSubmit}>
        <DialogContent>
          {isLoading ? (
            <Stack height={250} alignItems="center" justifyContent="center">
              <CircularProgress />
            </Stack>
          ) : (
            <Stack gap={2}>
              <Controller
                control={control}
                name="cardNumber"
                render={({ field, fieldState }) => (
                  <MaskedInput
                    mask={cardNumberMask}
                    guide={false}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    render={(ref, props) => (
                      <TextField
                        fullWidth
                        label="Número do cartão"
                        variant="outlined"
                        inputRef={ref}
                        {...props}
                        sx={{ flex: 1 }}
                        error={!!fieldState.error?.message}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  ></MaskedInput>
                )}
              />

              <ControllerInput
                control={control}
                name="name"
                inputProps={{ label: "Nome impresso no cartão" }}
              />

              <Typography variant="body1" sx={{ width: "100%", mb: -0.8 }}>
                Validade
              </Typography>

              <Stack direction="row" gap={1}>
                <Stack direction="row" gap={0.5} flex={1} flexWrap="wrap">
                  <Controller
                    control={control}
                    name="monthValidity"
                    render={({ field, fieldState }) => (
                      <DesktopDatePicker
                        views={["month"]}
                        label="Mês"
                        inputFormat="MM"
                        disableHighlightToday
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ flex: 1 }}
                            helperText={fieldState.error?.message}
                            error={!!fieldState.error?.message || params.error}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="yearValidity"
                    render={({ field, fieldState }) => (
                      <DesktopDatePicker
                        views={["year"]}
                        label="Ano"
                        disablePast
                        disableHighlightToday
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ flex: 1 }}
                            helperText={fieldState.error?.message}
                            error={!!fieldState.error?.message || params.error}
                          />
                        )}
                      />
                    )}
                  />
                </Stack>

                <ControllerInput
                  control={control}
                  name="securityCode"
                  inputProps={{
                    label: "Cód. de segurança",
                    sx: { flex: 1 },
                    type: "number",
                    inputProps: { maxLength: 3 },
                  }}
                />
              </Stack>
            </Stack>
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            Cadastrar cartão
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogChangePaymentForCredit;
