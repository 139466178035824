import { formattedInput } from "components/CurrencyInput";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("O nome do plano é obrigatório"),
  isActive: yup.boolean(),
  agreement: yup.string(),
  services: yup.array().of(
    yup.object().shape({
      isActive: yup.boolean(),
      price: yup
        .string()
        .required("O preço do plano é obrigatório")
        .test("validPrice", "Digite um preço válido", (value) => {
          if (!value) return false;
          const price = formattedInput(value);
          return price >= 0;
        }),
    }),
  ),
});

export default validationSchema;
