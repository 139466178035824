import { FC, ChangeEvent, CSSProperties } from "react";
import useMutationAddForm from "../hooks/useMutationAddForm";
import useMutationRemoveForm from "../hooks/useMutationRemoveForm";

import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { ClientsConfigLead } from "types/Marketplace";

interface ConfigLeadProps {
  configLead: ClientsConfigLead;
  style: CSSProperties;
  formName: number;
}

const ConfigLead: FC<ConfigLeadProps> = ({ configLead, style, formName }) => {
  const { mutateAsync: mutateAdd, isLoading: loadingAdd } = useMutationAddForm();
  const { mutateAsync: mutateRemove, isLoading: loadingRemove } = useMutationRemoveForm();
  const isLoading = loadingAdd || loadingRemove;

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (isLoading) return;

    const mutate = e.target.checked ? mutateAdd : mutateRemove;
    await mutate({ formName, ownerId: configLead.payerCompanyId });
  };

  return (
    <Stack
      direction={"row"}
      alignItems="center"
      gap={1}
      style={style}
      sx={{
        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? "none" : "all",
      }}
    >
      <Checkbox
        size="small"
        disabled={isLoading}
        checked={configLead.selectedForm}
        onChange={handleChange}
      />

      <Box overflow="hidden">
        <Typography
          variant="body2"
          sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
        >
          {!configLead.companyName ? configLead.owner : configLead.companyName}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
        >
          {configLead.cnpj} - {configLead.ownerEmail}
        </Typography>
      </Box>
    </Stack>
  );
};

export default ConfigLead;
