import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getBalance } from "services/Requests/companies";

const useQueryGetBalance = (companyToken: string, id = 0) => {
  const query = useQuery([queryKeys.balance, id], () => getBalance({ companyToken, id }), {
    enabled: !!id,
  });
  return query;
};

export default useQueryGetBalance;
