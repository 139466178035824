import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { createTicket, CreateTicketPayload } from "services/Requests/mailbox";
import getSubdomain from "utils/getSubdomain";

const useMutationCreateTicket = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<CreateTicketPayload, "subdomain">) => createTicket({ ...payload, subdomain }),
    {
      onSuccess() {
        toast.success("Mensagem enviada com sucesso.");
        queryClient.invalidateQueries(queryKeys.allChats, { exact: false });
        queryClient.invalidateQueries(queryKeys.chats, { exact: false });
      },
    },
  );
  return mutation;
};

export default useMutationCreateTicket;
