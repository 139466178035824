import { FC } from "react";
import useQueryOpenMonths from "./hooks/useQueryOpenMonths";

import MonthClosureItem from "./MonthClosureItem";
import { CircularProgress, Stack } from "@mui/material";

interface MonthListProps {
  companyToken: string;
}

const MonthList: FC<MonthListProps> = ({ companyToken }) => {
  const { data: openMonths, isLoading } = useQueryOpenMonths(companyToken);

  return (
    <>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {openMonths &&
        openMonths.map((month) => (
          <MonthClosureItem key={month} companyToken={companyToken} month={month} />
        ))}
    </>
  );
};

export default MonthList;
