import { FC, useEffect, useMemo } from "react";
import useMutationCreateTransactionAccountant from "../hooks/useMutationCreateTransactionAccountant";
import { DefaultValues, useForm } from "react-hook-form";

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import Form from "./Form";

import { DateTime } from "luxon";
import { InvoiceConfig } from "types/Company";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import createInvoiceData from "./createInvoiceData";

export interface InvoiceFieldsForm {
  companyParticipantId: number;
  value: string;
  issueDate: string;
  serviceCodeId: number;
  iss: number;
  irpj: number;
  irop: number;
  pis: number;
  cofins: number;
  csll: number;
  issWithHeld: "1" | "0";
  description: string;
  notes: string;
  serviceOtherState: "1" | "0";
  serviceUF: string;
  serviceCity: string;
}

interface InvoiceFormProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  invoiceConfig: InvoiceConfig;
}

const InvoiceForm: FC<InvoiceFormProps> = ({ onClose, invoiceConfig, companyToken, ...props }) => {
  const { mutateAsync } = useMutationCreateTransactionAccountant();

  const defaultValues = useMemo<DefaultValues<InvoiceFieldsForm>>(
    () => ({
      companyParticipantId: undefined,
      value: formatCurrencyBRL(0),
      issueDate: DateTime.local().toFormat("dd/MM/yyyy"),
      serviceCodeId: undefined,
      cofins: 0,
      csll: 0,
      irop: 0,
      irpj: 0,
      iss: 0,
      pis: 0,
      issWithHeld: "0",
      description: invoiceConfig.description,
      notes: invoiceConfig.notes,
      serviceOtherState: "0",
      serviceUF: undefined,
      serviceCity: undefined,
    }),
    [],
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue,
  } = useForm<InvoiceFieldsForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const onSubmit = handleSubmit(async (data) => {
    const service = invoiceConfig.services.find((s) => s.id === data.serviceCodeId);
    if (!service) return;

    await mutateAsync(createInvoiceData(data, service, companyToken));
    onClose();
  });

  const handleClose = () => {
    if (isSubmitting) return;
    onClose();
  };

  useEffect(() => {
    const resetTributesByServiceCodeId = (serviceCodeId: number) => {
      const service = invoiceConfig.services.find((s) => s.id === serviceCodeId);
      if (!service) return;

      setValue("iss", service.iss);
      setValue("cofins", service.cofins);
      setValue("csll", service.csll);
      setValue("irop", service.irop);
      setValue("irpj", service.irpj);
      setValue("pis", service.pis);
    };

    const subscription = watch((value, { name }) => {
      if (name === "serviceCodeId") {
        resetTributesByServiceCodeId(value.serviceCodeId as number);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Emitir Nota Fiscal
      </DialogTitleWithCloseButton>

      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(e);
        }}
      >
        <DialogContent>
          <Form control={control} invoiceConfig={invoiceConfig} />
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
            Emitir
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose} disabled={isSubmitting}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InvoiceForm;
