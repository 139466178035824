import * as yup from "yup";

const validationShema = yup.object().shape({
  charge: yup
    .number()
    .required("Este campo é obrigatório")
    .typeError("Digite apenas números")
    .integer("Digite apenas números inteiro"),
  expire: yup
    .number()
    .required("Este campo é obrigatório")
    .typeError("Digite apenas números")
    .integer("Digite apenas números inteiro")
    .test({
      name: "charge",
      exclusive: false,
      params: {},
      message: "A soma total não pode ultrapassar de 30 dias",
      test: function (value) {
        const charge: number = this.parent.charge;
        if (value === undefined || charge === undefined) return true;

        return charge * 3 + value * 1 <= 30;
      },
    }),
  invoiceName: yup.string().required("Este campo é obrigatório"),
});

export default validationShema;
