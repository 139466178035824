import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { Control, Controller, Path, useWatch } from "react-hook-form";

import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import { periodicity, periodicityWithoutNoRecorrence } from "../AdditionalServices/DialogService";
import ControllerInput from "components/ControllerInput";

const week = {
  Domingo: 1,
  Segunda: 2,
  Terça: 3,
  Quarta: 4,
  Quinta: 5,
  Sexta: 6,
  Sábado: 7,
};

interface RecurrenceFields {
  periodicity: number;
  parcel?: string;
  isSameDayExp?: boolean;
  periodParcel?: number;
  daysAfter?: string;
}

interface RecurrenceFieldsProps<T extends RecurrenceFields = RecurrenceFields> {
  control: Control<T>;
  activeAfterDays?: boolean;
  alwaysShowIsSameDayExp?: boolean;
}

export function RecurrenceFields<T extends RecurrenceFields = RecurrenceFields>({
  control,
  activeAfterDays = true,
  alwaysShowIsSameDayExp = false,
}: RecurrenceFieldsProps<T>) {
  const periodicityValue = useWatch({
    control,
    name: "periodicity" as Path<T>,
  }) as number;

  return (
    <Box flex={2}>
      <Stack direction="row" gap={2}>
        <FormControl fullWidth sx={{ flex: 1 }}>
          <InputLabel>Recorrência</InputLabel>
          <Controller
            name={"periodicity" as Path<T>}
            control={control}
            render={({ field, fieldState }) => (
              <>
                <Select
                  {...field}
                  value={field.value || ""}
                  label="Recorrência"
                  fullWidth
                  MenuProps={{ sx: { maxHeight: "50vh" } }}
                  error={!!fieldState.error?.message}
                >
                  <MenuItem value="" disabled>
                    Selecione:
                  </MenuItem>

                  {Object.entries(
                    activeAfterDays ? periodicity : periodicityWithoutNoRecorrence,
                  ).map((p) => (
                    <MenuItem key={p[1].toString()} value={p[1]}>
                      {capitalizeFirstLetter(p[0])}
                    </MenuItem>
                  ))}
                </Select>
                {!!fieldState.error?.message && (
                  <Typography variant="body1">{fieldState.error?.message}</Typography>
                )}
              </>
            )}
          />
        </FormControl>

        {[1, 3, 6, 12].includes(periodicityValue) && (
          <Stack direction="row" alignItems="center" gap={0.5} flex={1}>
            <Typography sx={{ whiteSpace: "nowrap" }}>Todo dia</Typography>{" "}
            <ControllerInput
              control={control}
              name="parcel"
              inputProps={{
                type: "number",
                inputMode: "numeric",
                inputProps: {
                  maxLength: 2,
                },
              }}
            />
          </Stack>
        )}

        {periodicityValue === 7 && (
          <Stack direction="row" alignItems="center" gap={0.5} flex={1}>
            <Typography>Toda(o)</Typography>
            <FormControl fullWidth>
              <Controller
                name={"periodParcel" as Path<T>}
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <Select
                      {...field}
                      value={field.value || ""}
                      fullWidth
                      MenuProps={{ sx: { maxHeight: "50vh" } }}
                      error={!!fieldState.error?.message}
                    >
                      <MenuItem value="" disabled>
                        Selecione:
                      </MenuItem>

                      {Object.entries(week).map((day) => (
                        <MenuItem key={day[1].toString()} value={day[1]}>
                          {capitalizeFirstLetter(day[0])}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!fieldState.error?.message && (
                      <Typography variant="body1">{fieldState.error?.message}</Typography>
                    )}
                  </>
                )}
              />
            </FormControl>
          </Stack>
        )}
      </Stack>

      {periodicityValue === -1 && activeAfterDays && (
        <Stack direction="row" mt={2} alignItems="center" gap={0.5}>
          <Typography>Vencimento na data da adesão + </Typography>{" "}
          <ControllerInput control={control} name="daysAfter" inputProps={{ size: "small" }} />
          <Typography>dias</Typography>
        </Stack>
      )}

      {(periodicityValue === 1 || periodicityValue === 7 || alwaysShowIsSameDayExp) && (
        <Stack direction="row" mt={2} alignItems="center" gap={0.5}>
          <Typography>ou</Typography>{" "}
          <Controller
            control={control}
            name={"isSameDayExp" as Path<T>}
            render={({ field }) => (
              <FormControlLabel
                sx={{ m: 0, height: "auto" }}
                control={
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={!!field.value}
                    value={!!field.value}
                    inputRef={field.ref}
                  />
                }
                label="Vencimento exatamente na data da adesão"
              />
            )}
          />
        </Stack>
      )}
    </Box>
  );
}
