import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import toast from "react-hot-toast";

import axios, { AxiosError } from "axios";
import getSubdomain from "utils/getSubdomain";
import { updateUser, UpdateUserPayload } from "services/Requests/admin";

const useMutationUpdateUser = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<UpdateUserPayload, "subdomain">) => updateUser({ ...payload, subdomain }),
    {
      onSuccess() {
        toast.success("Usuário editado com sucesso!");
        queryClient.invalidateQueries(queryKeys.userAdminList);
        queryClient.invalidateQueries(queryKeys.usersCompanies);
      },
      onError(error: AxiosError<{ message: string }>) {
        if (axios.isAxiosError(error) && error.response?.data?.message) {
          toast.error(error.response?.data.message);
        } else {
          toast.error("Ocorreu um erro ao editar o usuário, tente de novo mais tarde");
        }
      },
    },
  );
  return mutation;
};

export default useMutationUpdateUser;
