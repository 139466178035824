import { FC, useState } from "react";

import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DialogCreateOrUpdateSyntheticCategory from "pages/Home/pages/FinancialItem/components/DialogCreateOrUpdateSyntheticCategory";

interface NewCategoryProps {
  companyToken: string;
}

const NewCategory: FC<NewCategoryProps> = ({ companyToken }) => {
  const [showCreateCategory, setShowCreateCategory] = useState(false);

  return (
    <>
      <Button
        startIcon={<AddIcon fontSize="small" />}
        variant="contained"
        onClick={() => setShowCreateCategory(true)}
        size="small"
      >
        Nova Categoria Sintética
      </Button>

      {showCreateCategory && (
        <DialogCreateOrUpdateSyntheticCategory
          isCompany
          open={showCreateCategory}
          onClose={() => setShowCreateCategory(false)}
          companyToken={companyToken}
        />
      )}
    </>
  );
};

export default NewCategory;
