import { FC, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useAuth } from "context/Auth";

import AdminStandardizedPlans from "./AdminStandardizedPlans";
import AccountantStandardizedPlans from "./AccountantStandardizedPlans";
import DialogEditPlan from "./DialogEditPlan";

import { Service, ServiceAndPlan, ServicePlan } from "types/ServiceAndPlan";
import toast from "react-hot-toast";
import { MESSAGE_NO_COMPANY } from "utils/constants";

interface StandardizedPlansProps {
  serviceAndPlan: ServiceAndPlan;
  isIuguActivate: boolean;
}

const StandardizedPlans: FC<StandardizedPlansProps> = ({ isIuguActivate, serviceAndPlan }) => {
  const {
    user: { userInfo, company },
  } = useAuth();

  const [serviceInEditing, setServiceInEditing] = useState<Service | ServicePlan | null>(null);
  const dialogEditingIsOpen = Boolean(serviceInEditing);

  const handleCloseService = () => setServiceInEditing(null);
  const handleOpenEditService = (service: Service | ServicePlan) => {
    if (!company) {
      toast.error(MESSAGE_NO_COMPANY);
      return;
    }
    setServiceInEditing(service);
  };

  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={600}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 1 }}
      >
        Planos padronizados
      </Typography>

      {userInfo.userType === "Admin" ? (
        <AdminStandardizedPlans
          isIuguActivate={isIuguActivate}
          serviceAndPlan={serviceAndPlan}
          onEditService={handleOpenEditService}
        />
      ) : (
        <AccountantStandardizedPlans
          isIuguActivate={isIuguActivate}
          serviceAndPlan={serviceAndPlan}
          onEditService={handleOpenEditService}
        />
      )}

      {company && serviceInEditing && (
        <DialogEditPlan
          keepMounted={false}
          companyToken={company.token}
          open={dialogEditingIsOpen}
          service={serviceInEditing}
          onClose={handleCloseService}
        />
      )}
    </Box>
  );
};

export default StandardizedPlans;
