import { FC } from "react";
import useQueryOrdersByCompany from "./hooks/useQueryOrdersByCompany";

import {
  Box,
  CircularProgress,
  Collapse,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SolicitationTableItem from "./SolicitationTableItem";

interface PageProps {
  companyToken: string;
}

const Page: FC<PageProps> = ({ companyToken }) => {
  const { data: orders, isLoading, isFetching, isError } = useQueryOrdersByCompany(companyToken);

  return (
    <>
      {isError && <Typography sx={{ p: 2 }}>Ocorreu um erro ao buscar os pedidos</Typography>}
      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
      <Collapse in={!isLoading && isFetching}>
        <LinearProgress sx={{ mb: 1 }} />
      </Collapse>

      {orders && !isError && !isLoading && (
        <Box p={2}>
          {orders.length > 0 ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Data do Pedido</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Serviço</TableCell>
                    <TableCell>Valor(R$)</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {orders.map((order) => (
                    <SolicitationTableItem
                      key={order.id}
                      order={order}
                      companyToken={companyToken}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>Nenhum pedido encontrado</Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default Page;
