import { useQuery } from "react-query";
import { getProfilePost } from "services/Requests/registration";
import queryKeys from "react-query/constants";

const useQueryGetProfile = (companyToken: string) => {
  const query = useQuery([queryKeys.profile, companyToken], () => getProfilePost({ companyToken }));
  return query;
};

export default useQueryGetProfile;
