import { FC, memo } from "react";
import { useClientInformation } from "../../../context/ClientInformation";
import useQueryInfoAccountAdmin from "../../../hooks/useQueryInfoAccountAdmin";
import BankData, { FieldSubmit } from "components/BankData";
import CopyOfDocuments from "./CopyOfDocuments";

import { Box, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { UpdateVerifyAccountPayload } from "services/Requests/companies";
import useMutationUpdateVerifyAccount from "../../../hooks/useMutationUpdateVerifyAccount";

const InvoiceManagement: FC = () => {
  const clientInformation = useClientInformation();
  const { data, isLoading, isFetching } = useQueryInfoAccountAdmin(
    clientInformation.payerCompanyId,
  );

  const { mutate, isLoading: updateIsLoading } = useMutationUpdateVerifyAccount();
  const { payerCompanyId } = useClientInformation();

  if (!data || isLoading)
    return (
      <Stack alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );

  const onSubmit = (data: FieldSubmit) => {
    let payload: Omit<UpdateVerifyAccountPayload, "subdomain"> = {
      ag: data.agency,
      agDg: data.digitAgency,
      cc: data.account,
      ccDg: data.digitAccount,
      bank: data.bank,
      personType: data.personType,
      payerCompanyId,
    };

    if (data.operation) {
      payload = {
        ...payload,
        op: data.operation,
      };
    }

    mutate(payload);
  };

  return (
    <Box position="relative">
      <Stack alignItems="center" justifyContent="center">
        <Chip
          label={
            data.verification_status === "2"
              ? "Conta verificada"
              : "Conta não enviada para verificação"
          }
          sx={{ mx: "auto" }}
          color={data.verification_status === "2" ? "success" : "error"}
        />
      </Stack>

      {!isLoading && isFetching && (
        <Box position="absolute" top={0} right={0}>
          <CircularProgress size={15} />
        </Box>
      )}

      <Stack direction="row" flexWrap="wrap" gap={4} mt={2}>
        <BankData billingInfo={data} onSubmit={onSubmit} isLoading={updateIsLoading} />

        <CopyOfDocuments billingInfo={data} />
      </Stack>
    </Box>
  );
};

export default memo(InvoiceManagement);
