import { FC } from "react";
import { useExtract } from "../context/Extract";

import { Box, Button, Stack, TextField, Typography } from "@mui/material";

import CategorySelect from "pages/Home/components/CategorySelect";
import CompanyPartnersSelect from "pages/Home/components/CompanyPartnersSelect";
import CustomCategorySelect from "pages/Home/components/CustomCategorySelect";
import RecipientAutocomplete from "pages/Home/components/RecipientAutocomplete";

import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { TransactionType } from "types/CommonData";
// import FileInput from "./FileInput";
import { ImportOFXTransaction } from "types/Company";

interface SearchTabProps {
  companyToken: string;
  transactionType: TransactionType;
  extract: ImportOFXTransaction;
}

const SearchTab: FC<SearchTabProps> = ({ extract, companyToken, transactionType }) => {
  const { enableEditExtract, onChangeExtract, requestSave } = useExtract();
  const forPartner = extract.forPartiner;
  const value = extract.transactionValue;
  const check = extract.check;

  return (
    <Box>
      <Stack alignItems="flex-end" mb={1}>
        <Typography variant="body1" color={value < 0 ? "error.main" : "success.main"}>
          {formatCurrencyBRL(value)}
        </Typography>
      </Stack>

      <Stack gap={1.5}>
        <TextField
          value={extract.description2}
          onChange={(e) => onChangeExtract(extract, "description2", e.target.value)}
          size="small"
          label="Descrição"
          disabled={check}
          onBlur={() => requestSave(extract)}
        />

        <CategorySelect
          label="Categoria principal"
          size="small"
          disabled={check}
          companyToken={companyToken}
          value={extract.globalCategoryId}
          onChange={(e) => {
            onChangeExtract(extract, "globalCategoryId", e.target.value as number);
            onChangeExtract(extract, "categoryId", null);
          }}
          onBlur={() => requestSave(extract)}
        />

        <CustomCategorySelect
          label="Categoria personalizada"
          parentId={extract.globalCategoryId}
          size="small"
          disabled={check}
          companyToken={companyToken}
          withCreateCategory
          value={extract.categoryId}
          onChange={(e) => onChangeExtract(extract, "categoryId", e.target.value as number)}
          onBlur={() => requestSave(extract)}
        />

        {forPartner ? (
          <CompanyPartnersSelect
            label={"Sócio"}
            companyToken={companyToken}
            value={extract.partnerParticipantId}
            disabled={check}
            size="small"
            onChange={(e) =>
              onChangeExtract(extract, "partnerParticipantId", e.target.value as number)
            }
            onBlur={() => requestSave(extract)}
          />
        ) : (
          <RecipientAutocomplete
            companyToken={companyToken}
            value={extract.companyParticipantId}
            role={transactionType === "Credit" ? 1 : 2}
            withCreateParticipant
            disabled={check}
            size="small"
            textFieldProps={{
              label: transactionType === "Credit" ? "Cliente" : "Fornecedor",
              size: "small",
            }}
            onChange={(_, newValue) =>
              onChangeExtract(extract, "companyParticipantId", newValue ? newValue.id : null)
            }
            onBlur={() => requestSave(extract)}
          />
        )}

        <Stack direction="row" gap={1}>
          <Button
            sx={{ width: "fit-content" }}
            variant="contained"
            size="small"
            color="info"
            onClick={() => enableEditExtract(extract)}
            disabled={!check}
          >
            Editar
          </Button>

          {/* <FileInput /> */}
        </Stack>
      </Stack>
    </Box>
  );
};

export default SearchTab;
