import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { useAuth } from "context/Auth";

import { addUser, AddUserPayload } from "services/Requests/admin";
import toast from "react-hot-toast";
import getSubdomain from "utils/getSubdomain";
import axios, { AxiosError } from "axios";

export type DataForAddUser = Omit<AddUserPayload, "subdomain" | "companyToken">;

const useMutationAddUser = () => {
  const subdomain = getSubdomain();
  const {
    user: { company },
  } = useAuth();

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload: DataForAddUser) =>
      addUser({
        ...payload,
        companyToken: company!.token,
        subdomain,
      }),
    {
      onSuccess() {
        toast.success("Usuário cadastrado com sucesso!");
        queryClient.invalidateQueries(queryKeys.userAdminList);
        queryClient.invalidateQueries(queryKeys.usersCompanies);
      },
      onError(error: AxiosError<{ message: string }>) {
        if (axios.isAxiosError(error) && error.response?.data?.message) {
          toast.error(error.response?.data.message);
        } else {
          toast.error("Ocorreu um erro ao adicionar um usuário, tente de novo mais tarde");
        }
      },
    },
  );
  return mutation;
};

export default useMutationAddUser;
