import { FC, useState } from "react";
import useDebounce from "hooks/useDebounce";
import useQueryCompanyProfiles from "pages/Home/hooks/useQueryCompanyProfiles";

import {
  CircularProgress,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import Search from "@mui/icons-material/Search";

import DialogUpdateProfile from "../DialogUpdateCreateProfile";
import SortableTableHead from "components/SortableTableHead";
import ProfileItem from "./ProfileItem";

import { Order } from "types/SemanticTypes";
import stableSort from "utils/stableSort";
import { CompanyProfile } from "types/Company";
import comparatorSortByProperty from "utils/comparatorSortByProperty";
import { useAuth } from "context/Auth";

type CompanyProfileKeys = keyof CompanyProfile;

interface HeadCells {
  label: string;
  id: CompanyProfileKeys;
  sortable: boolean;
}

const headCells: HeadCells[] = [
  {
    id: "name",
    label: "Nome",
    sortable: true,
  },
];

const ProfileList: FC = () => {
  const {
    user: { userInfo, company },
  } = useAuth();
  const [searchProfile, setSearchProfile] = useState("");
  const search = useDebounce(searchProfile, 150);

  const [edittedProfile, setEdittedProfile] = useState<CompanyProfile | null>(null);

  const {
    data: profiles,
    isLoading,
    isError,
  } = useQueryCompanyProfiles(
    search,
    userInfo.userType !== "Accountant" && userInfo.userType !== "Admin"
      ? company?.token
      : undefined,
  );

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<CompanyProfileKeys>("name");

  const sortHandler = (property: CompanyProfileKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" height={300}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <TextField
        size="small"
        fullWidth
        value={searchProfile}
        onChange={(e) => setSearchProfile(e.target.value)}
        disabled={isError}
        InputProps={{
          placeholder: "Buscar perfils",
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize="small" />
            </InputAdornment>
          ),
        }}
      />

      <TableContainer sx={{ mt: 2 }}>
        <Table size="small">
          <SortableTableHead
            headCells={headCells}
            onRequestSort={(e, property) => sortHandler(property)}
            order={order}
            orderBy={orderBy}
          >
            <TableCell align="center"></TableCell>
          </SortableTableHead>

          {profiles && (
            <TableBody>
              {profiles.length === 0 && (
                <TableRow>
                  <TableCell colSpan={headCells.length + 1}>Nenhum perfil encontrado</TableCell>
                </TableRow>
              )}
              {isError && (
                <TableRow>
                  <TableCell colSpan={headCells.length + 1}>
                    Ocorreu um error ao buscar perfils
                  </TableCell>
                </TableRow>
              )}

              {stableSort(profiles, comparatorSortByProperty(order, orderBy)).map((profile) => (
                <ProfileItem
                  key={profile.id}
                  profile={profile}
                  onEdit={() => setEdittedProfile(profile)}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {edittedProfile && (
        <DialogUpdateProfile
          keepMounted={false}
          open={!!edittedProfile}
          profile={edittedProfile || undefined}
          onClose={() => setEdittedProfile(null)}
        />
      )}
    </>
  );
};

export default ProfileList;
