export interface Path {
  name: string;
  id: string;
}

export type Paths = Path[];

export const SEPARATOR_TO_SERVICE = "||||";

export const pathToDisplay = (paths: Paths, pathSeparator = "/") => {
  const pathNames = paths.map((path) => path.name);
  const joinPaths = pathNames.join(pathSeparator);

  return pathSeparator + joinPaths;
};
