import endpoints from "../endpoints";
import api from "../api";

import { Bank, CNAEs, Counties, States } from "types/CommonData";

export const getListCNAEs = async (): Promise<CNAEs[]> => {
  const { data } = await api.get<CNAEs[]>(endpoints.commonData.CNAEs);
  return data;
};

export interface GetCountiesParams {
  id: string;
}

export const getCounties = async (params: GetCountiesParams): Promise<Counties[]> => {
  const { data } = await api.get<Counties[]>(endpoints.commonData.countries, {
    params,
  });
  return data;
};

export const getBanks = async (): Promise<Bank[]> => {
  const { data } = await api.get<Bank[]>(endpoints.commonData.banks);
  return data;
};

export const getCountryStates = async (): Promise<States[]> => {
  const { data } = await api.get<States[]>(endpoints.commonData.countryStates);
  return data;
};

export interface GuessCountyParms {
  name: string;
  state: string;
}

export const guessCounty = async (params: GuessCountyParms): Promise<Counties | null> => {
  const { data } = await api.get(endpoints.commonData.guessCounty, {
    params,
  });
  return data;
};
