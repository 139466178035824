import { FC, useState } from "react";
import useMutationConnectToken from "../CompanyAccount/hooks/useMutationConnectToken";
import useMutationGetIntegratorAccount from "./hooks/useMutationGetIntegratorAccount";
import useMutationBankingIntegratorTransaction from "./hooks/useMutationBankingIntegratorTransaction";
import useMutationUpdateAccountKey from "./hooks/useMutationUpdateAccountKey";

import { MainContent } from "./Control";
import { Stack } from "@mui/material";
import { DateTime } from "luxon";
import { LoadingButton } from "@mui/lab";
import FilterPeriod from "components/FilterPeriod";

import BankAccountSelect from "pages/Home/components/BankAccountSelect";
import Extracts from "../ImportRelease/Extracts";
import { AxiosError } from "axios";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";
import toast from "react-hot-toast";
import { PluggyConnect } from "react-pluggy-connect";

interface ImportProps {
  companyToken: string;
}

const Import: FC<ImportProps> = ({ companyToken }) => {
  const {
    mutateAsync: mutateConnectToken,
    isLoading: loadingConnectToken,
    data: dataConnect,
  } = useMutationConnectToken();
  const {
    mutateAsync: mutateGetIntegratorAccount,
    isLoading: loadingIntegrator,
    data: dataIntegrator,
  } = useMutationGetIntegratorAccount();
  const { mutateAsync: mutateUpdateAccountKey, isLoading: loadingUpdateKey } =
    useMutationUpdateAccountKey();
  const [renewIntegrateIsOpen, setRenewIntegrateIsOpen] = useState(false);

  const {
    mutateAsync: mutateBankingIntegrator,
    isLoading,
    data,
  } = useMutationBankingIntegratorTransaction();

  const isLoadingRenewIntegrate = loadingConnectToken || loadingIntegrator;

  const [period, setPeriod] = useState({
    start: DateTime.local().startOf("month").toISO(),
    end: DateTime.local().endOf("month").toISO(),
  });
  const [bankAccount, setBankAccount] = useState<null | number>(null);

  const handleImport = async () => {
    if (!bankAccount) return;

    try {
      await mutateBankingIntegrator({
        bankAccountId: bankAccount,
        companyToken,
        to: DateTime.fromISO(period.end).toFormat("yyyy-MM-dd"),
        from: DateTime.fromISO(period.start).toFormat("yyyy-MM-dd"),
      });
    } catch (err) {
      const error = (err as AxiosError<{ message: string[] }>).response?.data?.message;

      if (!error || !error.includes("required")) {
        console.log("[3Ac9] Error on import transaction", err);
        return;
      }

      renewIntegrate();
    }
  };

  async function renewIntegrate() {
    if (!bankAccount) return;

    try {
      await mutateConnectToken();
      await mutateGetIntegratorAccount({
        bankAccountId: bankAccount,
        companyToken,
      });
      setRenewIntegrateIsOpen(true);
    } catch (err) {
      console.log("[3Ac9] Error renew integrate", err);
      toast.error("Ocorreu um erro ao tentar iniciar a integração. Tente novamente mais tarde.");
    }
  }

  const handleSucessPluggy = async () => {
    try {
      if (bankAccount) {
        await mutateUpdateAccountKey({
          bankAccountId: bankAccount,
          companyToken,
        });

        handleImport();
      }
    } catch (err) {
      console.log("[3Ac9] Error update account key", err);
      toast.error("Ocorreu um erro ao tentar atualizar a chave da conta.");
    } finally {
      setRenewIntegrateIsOpen(false);
    }
  };

  const handleErrorPluggyConnect = () => {
    toast.error("Falha ao tentar processar a atualização da conta integrada.");
    setRenewIntegrateIsOpen(false);
  };

  return (
    <>
      <MainContent>
        <Stack direction="row" gap={2} alignItems="center">
          <FilterPeriod
            label="Período"
            onChangeDates={setPeriod}
            startDate={period.start}
            endDate={period.end}
            disabled={isLoading}
          />
          <BankAccountSelect
            size="small"
            label="Conta para importação"
            companyToken={companyToken}
            value={bankAccount}
            onlyIntegrated
            onChange={(e) => setBankAccount(e.target.value as number)}
            sx={{ minWidth: 220 }}
            disabled={isLoading}
          />

          <LoadingButton
            loading={isLoading}
            variant="contained"
            sx={{ ml: 1 }}
            disabled={!bankAccount}
            onClick={handleImport}
          >
            Importar
          </LoadingButton>
        </Stack>
      </MainContent>

      {!!data && (
        <MainContent>
          <Extracts companyToken={companyToken} importResult={data} reloadList={handleImport} />
        </MainContent>
      )}

      {(isLoadingRenewIntegrate || loadingUpdateKey) && <FullscreenLoadingIndicator />}

      {renewIntegrateIsOpen && !!dataIntegrator?.itemId && !!dataConnect?.accessToken && (
        <PluggyConnect
          connectToken={dataConnect?.accessToken}
          updateItem={dataIntegrator?.itemId}
          onError={handleErrorPluggyConnect}
          onSuccess={handleSucessPluggy}
          onClose={() => setRenewIntegrateIsOpen(false)}
        />
      )}
    </>
  );
};

export default Import;
