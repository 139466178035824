import { FC, useState } from "react";
import { useExtract } from "../context/Extract";

import { Box, Tab, Tabs } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { grey } from "@mui/material/colors";

import TabPanel from "components/TabPanel";
import NewExtractTab from "./NewExtractTab";
import SearchTab from "./SearchTab";
import ReleaseTabItem from "./ReleaseTabItem";

import DialogSearchRelease from "../components/DialogSearchRelease";
import { ImportOFXTransaction } from "types/Company";

export interface ExtractForm {
  description2: string;
  companyBankAccountId: number;
  transferBetweenAccounts: boolean;
  globalCategoryId: number;
  categoryId: number | null;
  forPartner: boolean;
  companyParticipantId: null | number;
  partnerParticipantId: null | number;
}

interface ExtractTabNavigationProps {
  companyToken: string;
  extract: ImportOFXTransaction;
}

const ExtractTabNavigation: FC<ExtractTabNavigationProps> = ({ extract, companyToken }) => {
  const { onChangeExtract } = useExtract();
  const [activeTab, setActiveTab] = useState(0);
  const [searchReleaseDialog, setSearchReleaseDialog] = useState(false);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    onChangeExtract(extract, "transactionId", null);
  };

  return (
    <Box>
      <Tabs color="primary" value={activeTab} onChange={handleChange}>
        <Tab label="Novo" sx={{ minHeight: "fit-content" }} />
        <Tab
          label={`Lançamentos${
            extract.countTransactions === 0 ? "" : " " + extract.countTransactions
          }`}
          disabled={extract.check || extract.countTransactions === 0}
          sx={{ minHeight: "fit-content" }}
        />
        <Tab
          label="Buscar"
          icon={<SearchIcon fontSize="small" />}
          iconPosition="start"
          sx={{ minHeight: "fit-content" }}
          onClick={() => {
            if (!extract.check) {
              setSearchReleaseDialog(true);
            }
          }}
        />
      </Tabs>

      <TabPanel
        value={activeTab}
        index={0}
        bgcolor={grey[200]}
        borderRadius="0 0 4px 4px"
        border={1}
        borderColor={grey[300]}
      >
        <NewExtractTab
          extract={extract}
          transactionType={extract.type === "CREDIT" ? "Credit" : "Debt"}
          companyToken={companyToken}
        />
      </TabPanel>

      <TabPanel
        value={activeTab}
        index={1}
        bgcolor={grey[200]}
        borderRadius="0 0 4px 4px"
        border={1}
        borderColor={grey[300]}
        keepMounted={false}
      >
        <ReleaseTabItem extract={extract} companyToken={companyToken} />
      </TabPanel>

      <TabPanel
        value={activeTab}
        index={2}
        bgcolor={grey[200]}
        borderRadius="0 0 4px 4px"
        border={1}
        borderColor={grey[300]}
      >
        <SearchTab
          extract={extract}
          transactionType={extract.type === "CREDIT" ? "Credit" : "Debt"}
          companyToken={companyToken}
        />
      </TabPanel>

      <DialogSearchRelease
        extract={extract}
        open={searchReleaseDialog}
        onClose={() => setSearchReleaseDialog(false)}
        companyToken={companyToken}
      />
    </Box>
  );
};

export default ExtractTabNavigation;
