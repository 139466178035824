import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getTransaction } from "services/Requests/companies";

const useQueryGetTransaction = (companyToken: string, id = 0) => {
  const query = useQuery([queryKeys.transaction, id], () => getTransaction({ companyToken, id }), {
    enabled: !!id,
  });
  return query;
};

export default useQueryGetTransaction;
