import { FC, useEffect, useMemo, useState } from "react";
import useQueryGetTransactionsSearch from "../../hooks/useQueryGetTransactionsSearch";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import FilterPeriod from "components/FilterPeriod";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import BankAccountSelect from "pages/Home/components/BankAccountSelect";
import SearchInput from "components/SearchInput";

import { DateTime } from "luxon";
import { ImportOFXTransaction } from "types/Company";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import TableRelease from "./TableRelease";
import useMutationCreateOrUpdateTransaction from "../../hooks/useMutationCreateOrUpdateTransaction";

interface DialogSearchReleaseProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  extract: ImportOFXTransaction;
  companyToken: string;
}

const DialogSearchRelease: FC<DialogSearchReleaseProps> = ({
  onClose,
  extract,
  companyToken,
  ...props
}) => {
  const { isLoading: mutateIsLoading, mutateAsync } = useMutationCreateOrUpdateTransaction();
  const [releaseSelected, setReleaseSelected] = useState<number[]>([]);

  const [date, setDate] = useState({
    start: DateTime.local().startOf("month").toISO(),
    end: DateTime.local().endOf("month").toISO(),
  });
  const [bankAccountId, setBankAccountId] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState("");

  const {
    data: transactions = [],
    isLoading,
    isFetching,
  } = useQueryGetTransactionsSearch({
    transactionType: extract.type === "CREDIT" ? "Credit" : "Debt",
    action: null,
    companyToken,
    endDate: date.end,
    startDate: date.start,
    term: search,
    bankAccountId,
  });

  const handleClose = () => {
    if (mutateIsLoading) return;
    onClose();
  };

  useEffect(() => {
    setReleaseSelected([]);
  }, [bankAccountId, search, date]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setReleaseSelected(
        transactions.reduce((acc: number[], t) => {
          if (t.ofxId) return acc;
          return [...acc, t.id];
        }, []),
      );
      return;
    }

    setReleaseSelected([]);
  };

  const handleSelectRelease = (releaseId: number) => {
    setReleaseSelected((prev) => {
      const releaseExist = prev.includes(releaseId);

      if (releaseExist) {
        return prev.filter((p) => p !== releaseId);
      } else {
        return [...prev, releaseId];
      }
    });
  };

  const selectedReleases = useMemo(
    () => transactions.filter((t) => releaseSelected.includes(t.id)),
    [releaseSelected, transactions],
  );
  const totalValueSelectedReleases = useMemo(
    () => selectedReleases.reduce((acc, t) => acc + t.value, 0),
    [selectedReleases],
  );

  const onCreateTransactionOFX = async () => {
    if (totalValueSelectedReleases < Math.abs(extract.transactionValue)) return;

    await mutateAsync({
      companyToken,
      oFXDescription: extract.description2,
      ofxId: extract.ofxId,
      paymentDate: extract.date,
      transactions: selectedReleases,
      value: extract.transactionValue,
    });
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="lg" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Buscar Lançamentos
      </DialogTitleWithCloseButton>

      <DialogContent>
        <Typography gutterBottom variant="body1" fontWeight={500}>
          Lançamento para a conciliação
        </Typography>

        <Stack direction="row" gap={1} justifyContent="space-between">
          <Typography>{extract.description2}</Typography>
          <Typography>Data: {DateTime.fromISO(extract.date).toFormat("dd/MM/yyyy")}</Typography>
          <Typography>Valor: {formatCurrencyBRL(extract.transactionValue)}</Typography>
        </Stack>

        <Divider sx={{ my: 2 }} />

        <Typography gutterBottom variant="body1" fontWeight={500}>
          Buscar lançamentos
        </Typography>
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2} mb={1}>
          <FilterPeriod
            size="medium"
            endDate={date.end}
            startDate={date.start}
            onChangeDates={setDate}
          />
          <BankAccountSelect
            label="Contas"
            sx={{ minWidth: 120 }}
            companyToken={companyToken}
            value={bankAccountId}
            onChange={(e) => setBankAccountId(Number(e.target.value))}
          />
          <SearchInput value={search} onChange={(newValue) => setSearch(newValue)} />
        </Stack>

        {totalValueSelectedReleases < Math.abs(extract.transactionValue) && (
          <Alert severity="error" sx={{ mb: 1 }}>
            O valor total não pode ser menor que o valor pago ou recebido.
          </Alert>
        )}

        {totalValueSelectedReleases > Math.abs(extract.transactionValue) && (
          <Alert severity="warning" sx={{ mb: 1 }}>
            Será criado um novo lançamento, devido a diferença, no valor de{" "}
            {formatCurrencyBRL(Math.abs(extract.transactionValue) - totalValueSelectedReleases)}.
          </Alert>
        )}

        <Typography fontWeight={600} align="right">
          Total selecionado: {formatCurrencyBRL(totalValueSelectedReleases)}
        </Typography>

        <Divider sx={{ mt: 2 }} />

        {!isLoading && isFetching && <LinearProgress sx={{ mt: 0.5 }} />}

        <Box mt={1}>
          {isLoading && (
            <Stack height={300} alignItems="center" justifyContent="center">
              <CircularProgress />
            </Stack>
          )}
          {!isLoading && (
            <TableRelease
              companyToken={companyToken}
              transactionsItem={transactions}
              onSelectAllClick={handleSelectAllClick}
              releaseSelected={releaseSelected}
              onSelectRelease={handleSelectRelease}
            />
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={onCreateTransactionOFX}
          loading={mutateIsLoading}
          disabled={totalValueSelectedReleases < Math.abs(extract.transactionValue)}
          variant="contained"
        >
          Conciliar
        </LoadingButton>
        <Button disabled={mutateIsLoading} variant="outlined" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSearchRelease;
