import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { updateVerifyAccount, UpdateVerifyAccountPayload } from "services/Requests/companies";
import getSubdomain from "utils/getSubdomain";

const useMutationUpdateVerifyAccount = () => {
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<UpdateVerifyAccountPayload, "subdomain">) =>
      updateVerifyAccount({
        ...payload,
        subdomain,
      }),
    {
      onSuccess(data) {
        if (data.success) {
          toast.success(
            "Conta enviada para verificação! O processo de aprovação tem um prazo máximo de 48hrs.",
          );
        }
      },
    },
  );
  return mutation;
};

export default useMutationUpdateVerifyAccount;
