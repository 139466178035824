import { useContext } from "react";
import { ExtractContext } from ".";

export const useExtract = () => {
  const ctx = useContext(ExtractContext);

  if (!ctx) {
    throw new Error("useExtract must be used within a ExtractProvider");
  }

  return ctx;
};
