import { useQuery } from "react-query";

import { getListCNAEs } from "services/Requests/commonData";
import queryKeys from "react-query/constants";

const useQueryGetCNAEs = () => {
  const query = useQuery(queryKeys.CNAEsList, getListCNAEs, {
    staleTime: 1000 * 60 * 20, // 20 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
  });
  return query;
};

export default useQueryGetCNAEs;
