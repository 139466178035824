import { FC, useState, MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import useQueryUnpresented from "pages/Home/hooks/useQueryUnpresented";
import useMutationMarkAsPresented from "pages/Home/hooks/useMutationMarkAsPresented";
import { useCompanyInfo } from "context/CompanyInfo";

import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import DropdownMenu from "components/DropdownMenu";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const Notification: FC = () => {
  const {
    companyStyles: { headerLinksColor },
  } = useCompanyInfo();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const { isLoading, data: unpresented, isFetching } = useQueryUnpresented();
  const { mutate: markAsPresented } = useMutationMarkAsPresented();

  const handleNavigate: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    navigate("/notificacoes");
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    markAsPresented();
  };

  return (
    <>
      <IconButton
        sx={{ height: "100%", borderRadius: 0 }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Badge badgeContent={unpresented?.length || 0} color="warning" max={99}>
          <NotificationsIcon sx={{ color: headerLinksColor }} />
        </Badge>
      </IconButton>

      <DropdownMenu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{ sx: { p: 0 } }}
        PaperProps={{
          sx: {
            width: 350,
          },
        }}
      >
        <Collapse in={!isLoading && isFetching}>
          <LinearProgress />
        </Collapse>

        <Typography sx={{ p: 2, pb: 0 }} align="center">
          Você tem {unpresented?.length || 0} notificações novas
        </Typography>

        <Divider sx={{ mt: 2 }} />
        {isLoading && (
          <Stack height={400} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {unpresented && !isLoading && (
          <Box maxHeight={500} sx={{ overflowY: "auto" }}>
            {unpresented.map((notification) => (
              <MenuItem
                key={notification.id}
                component="a"
                href="/notificacoes"
                onClick={handleNavigate}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  display: "block",
                }}
              >
                <CalendarMonthIcon sx={{ mr: 1 }} />
                {notification.message}
              </MenuItem>
            ))}
          </Box>
        )}
        {unpresented && unpresented.length > 0 && <Divider />}

        <Button
          variant="text"
          fullWidth
          component="a"
          href="/notificacoes"
          onClick={handleNavigate}
        >
          Ver todas as notificações
        </Button>
      </DropdownMenu>
    </>
  );
};

export default Notification;
