import { useMutation } from "react-query";
import { importClients } from "services/Requests/marketplace";
import toast from "react-hot-toast";

const useMutationImportClients = () => {
  const mutation = useMutation(importClients, {
    onSuccess(importsClients) {
      const somethingWorked = importsClients.some((importClient) => importClient.status === "ok");
      const everyoneWorked = importsClients.every((importClient) => importClient.status === "ok");

      if (everyoneWorked) {
        toast("Clientes importados com sucesso!");
        return;
      }

      if (somethingWorked) {
        toast("Alguns clientes importados com sucesso, existem clientes fora do padrão!");
        return;
      }

      toast.error("Clientes inválidos ou fora do padrão!");
    },
  });
  return mutation;
};

export default useMutationImportClients;
