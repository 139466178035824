import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { updateCompanyBankAccountAccountanting } from "services/Requests/companies";

const useMutationUpdateCompanyBankAccountAccountanting = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCompanyBankAccountAccountanting, {
    onSuccess(_, { companyToken }) {
      toast.success("Banco atualizado com sucesso");
      queryClient.invalidateQueries([queryKeys.companyBankAccounts, companyToken]);
    },
  });
  return mutation;
};

export default useMutationUpdateCompanyBankAccountAccountanting;
