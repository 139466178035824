import { FC, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { TableCell, TableRow, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { BillingSubsAccounts } from "types/Billing";
import useMutationUpdateSpreads from "../../hooks/useMutationUpdateSpreads";

interface FeesTableItemProps {
  subAccount: BillingSubsAccounts;
}

const FeesTableItem: FC<FeesTableItemProps> = ({ subAccount }) => {
  const [bank, setBank] = useState(subAccount.bankSlipCents);
  const [credit, setCredit] = useState(subAccount.creditCardPercent);

  const { mutate, isLoading } = useMutationUpdateSpreads();

  const handleUpdateSpreads = () => {
    mutate({
      ccAdvanceSpread: 4.62,
      companyId: subAccount.companyId,
      creditCardSpread: credit,
      invoiceSpread: (bank * 100).toString(),
      withdrawSpread: 300,
    });
  };

  return (
    <TableRow>
      <TableCell>{subAccount.name}</TableCell>
      <TableCell>
        <TextField
          value={bank}
          onChange={(e) => setBank(Number(e.target.value))}
          size="small"
          type="number"
        />
      </TableCell>
      <TableCell>R$ 3,00</TableCell>
      <TableCell>
        <TextField
          value={credit}
          onChange={(e) => setCredit(Number(e.target.value))}
          size="small"
          type="number"
        />
      </TableCell>
      <TableCell>4.62</TableCell>
      <TableCell>{DateTime.fromISO(subAccount.createdDate).toFormat("dd/MM/yyyy")}</TableCell>
      <TableCell>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          size="small"
          disabled={!bank || !credit || bank < 0 || credit < 0}
          onClick={handleUpdateSpreads}
        >
          Atualizar
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
};

export default FeesTableItem;
