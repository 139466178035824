import { FC, useState } from "react";

import { Checkbox, IconButton, MenuItem, TableCell, TableRow } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import DialogConsultMovement from "pages/Home/pages/InventoryControl/components/DialogUpdateOrCreateMovement";
import InvoiceDownload from "../components/InvoiceDownload";
import DropdownMenu from "components/DropdownMenu";

import { DateTime } from "luxon";
import { InvoiceStockControl } from "types/StockControl";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import DialogInvoiceCorrection from "../DialogInvoiceCorrection";
import DialogDeleteInvoiceStockControl from "../DialogDeleteInvoiceStockControl";

interface InvoiceTableItemStockControlProps {
  invoice: InvoiceStockControl;
  onSelect: () => void;
  selected: boolean;
  companyToken: string;
}

const InvoiceTableItemStockControl: FC<InvoiceTableItemStockControlProps> = ({
  invoice,
  onSelect,
  selected,
  companyToken,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuIsOpen = Boolean(anchorEl);

  const [consultIsOpen, setConsultIsOpen] = useState(false);
  const [correctionIsOpen, setCorrectionIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);

  return (
    <>
      <TableRow hover selected={menuIsOpen || selected}>
        <TableCell padding="checkbox" className="no-print">
          <Checkbox size="small" color="primary" checked={selected} onChange={onSelect} />
        </TableCell>
        <TableCell>{invoice.id}</TableCell>
        <TableCell>{invoice.invoiceNumber != "0" ? invoice.invoiceNumber : "-"}</TableCell>
        <TableCell>
          {invoice.emissionDate
            ? DateTime.fromISO(invoice.emissionDate).toFormat("dd/MM/yyyy")
            : "-"}
        </TableCell>
        <TableCell>{invoice.participantName}</TableCell>
        <TableCell>
          {invoice.description.length > 50
            ? invoice.description.substring(0, 50) + "..."
            : invoice.description}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            color: invoice.operationType === "Entrada" ? "error.main" : "success.main",
            whiteSpace: "nowrap",
          }}
        >
          {invoice.value
            ? formatCurrencyBRL(invoice.value * (invoice.operationType === "Entrada" ? -1 : 1))
            : "-"}
        </TableCell>
        <TableCell align="center">{invoice.invoiceStatus}</TableCell>
        <TableCell>
          <InvoiceDownload
            pathInvoice={invoice.pathInvoice}
            pathXmlInvoice={invoice.pathXmlInvoice}
          />
        </TableCell>
        <TableCell align="right">
          <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        open={menuIsOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setConsultIsOpen(true)}>Consultar</MenuItem>
        {invoice.invoiceStatus === "Autorizada" && (
          <MenuItem onClick={() => setDeleteIsOpen(true)}>Cancelar a nota</MenuItem>
        )}
        {invoice.invoiceStatus === "Autorizada" && (
          <MenuItem onClick={() => setCorrectionIsOpen(true)}>Emitir Carta de Correção</MenuItem>
        )}
      </DropdownMenu>

      {consultIsOpen && (
        <DialogConsultMovement
          open={consultIsOpen}
          onClose={() => setConsultIsOpen(false)}
          companyToken={companyToken}
          isConsultation
          movementId={invoice.id}
        />
      )}

      {correctionIsOpen && (
        <DialogInvoiceCorrection
          open={correctionIsOpen}
          onClose={() => setCorrectionIsOpen(false)}
          companyToken={companyToken}
          movementId={invoice.id}
        />
      )}

      {deleteIsOpen && (
        <DialogDeleteInvoiceStockControl
          open={deleteIsOpen}
          onClose={() => setDeleteIsOpen(false)}
          companyToken={companyToken}
          invoiceId={invoice.id}
        />
      )}
    </>
  );
};

export default InvoiceTableItemStockControl;
