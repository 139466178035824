import { forwardRef, Ref } from "react";

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { IPI_CST } from "utils/constants";

interface IpiCstSelectInnerProps extends SelectProps<string | null> {
  helperText?: string;
  FormControlProps?: FormControlProps;
}

function IpiCstSelectInner(
  { helperText, FormControlProps, ...props }: IpiCstSelectInnerProps,
  ref?: Ref<unknown>,
) {
  return (
    <FormControl error={props.error} size={props.size} {...FormControlProps}>
      <InputLabel>{props.label}</InputLabel>
      <Select ref={ref} {...props} value={props.value || ""}>
        <MenuItem value="">Selecione: </MenuItem>

        {IPI_CST.map((ipiCst) => (
          <MenuItem key={ipiCst.code} value={ipiCst.code}>
            ({ipiCst.code.padStart(2, "0")}) {ipiCst.label}
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const IpiCstSelect = forwardRef(IpiCstSelectInner);
export default IpiCstSelect;
