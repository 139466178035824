import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getSpreads, GetSpreadsPayload } from "services/Requests/billing";
import getSubdomain from "utils/getSubdomain";

const useQuerySpreads = (payload: Omit<GetSpreadsPayload, "subdomain">, enabled?: boolean) => {
  const subdomain = getSubdomain();

  let queryKey: string[] | string = queryKeys.clientSpreads;
  if (payload.accountantCompanyId) queryKey = [queryKey, queryKeys.clientSpreads];

  const query = useQuery(queryKey, () => getSpreads({ ...payload, subdomain }), {
    enabled,
  });
  return query;
};

export default useQuerySpreads;
