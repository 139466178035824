import { FC, PropsWithChildren, useEffect, useReducer } from "react";
import useMutationOrderGlobalCategories from "../../hooks/useMutationOrderGlobalCategories";

import { FinancialCategoryByGroupWithChildren } from "types/Company";
import { TemplateContext, TemplateReducer, Types } from ".";
import FullscreenLoadingIndicator from "components/FullscreenLoadingIndicator";

interface TemplateProviderProps extends PropsWithChildren<unknown> {
  data: FinancialCategoryByGroupWithChildren[];
  isCompany?: boolean;
  companyId?: number;
  companyToken?: string;
}

const TemplateProvider: FC<TemplateProviderProps> = ({
  data,
  children,
  isCompany = false,
  companyToken,
  companyId,
}) => {
  const [financialCategories, dispatch] = useReducer(TemplateReducer, data);
  const { mutateAsync, isLoading } = useMutationOrderGlobalCategories(isCompany);

  useEffect(() => {
    dispatch({
      type: Types.Set_Data,
      payload: {
        data,
      },
    });
  }, [data]);

  const handleEndDragItem = () => {
    if (JSON.stringify(data) === JSON.stringify(financialCategories)) return;

    const allChildren = financialCategories.map((f) => f.children).flat();

    mutateAsync({
      container: allChildren.map((child) => ({
        id: child.id,
      })),
    });
  };

  const handleEndDragContainer = () => {
    if (JSON.stringify(data) === JSON.stringify(financialCategories)) return;

    mutateAsync({
      container: financialCategories.map((category) => ({
        id: category.id,
      })),
    });
  };

  return (
    <>
      <TemplateContext.Provider
        value={{
          financialCategories,
          dispatch,
          onEndDragItem: handleEndDragItem,
          onEndDragContainer: handleEndDragContainer,
          isCompany,
          companyToken,
          companyId,
        }}
      >
        {children}
      </TemplateContext.Provider>
      {isLoading && <FullscreenLoadingIndicator />}
    </>
  );
};

export default TemplateProvider;
