import { FC, useEffect } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import HelpIcon from "@mui/icons-material/Help";

import MaskedInput from "react-text-mask";
import AddressForm from "../Profile/components/CompanyData/components/AddressForm";
import ControllerInput from "components/ControllerInput";

import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from "react-hook-form";
import { PartnerFormField } from ".";
import { cnpjMask, cpfMask } from "utils/masks";

const maritalStatusTranslate = [
  "Solteiro(a)",
  "Casado(a)",
  "Divorciado(a)",
  "Viúvo(a)",
  "Separado(a)",
  "Companheiro(a)",
];

const civilState = [
  "Comunhão parcial de bens",
  "Comunhão universal de bens",
  "Separação total de bens",
  "Participação final nos aquestos",
];

interface PartnerProps {
  control: Control<PartnerFormField, unknown>;
  field: FieldArrayWithId<PartnerFormField, "partners", "id">;
  index: number;
  setValue: UseFormSetValue<PartnerFormField>;
  watch: UseFormWatch<PartnerFormField>;
  disabled?: boolean;
}

const Partner: FC<PartnerProps> = ({
  control,
  field,
  index,
  setValue,
  watch,
  disabled = false,
}) => {
  const maritalStatus = useWatch({
    control,
    name: `partners.${index}.maritalStatus`,
  });

  const maskBuilder = (v: string) => {
    return v.length <= 14 ? [...cpfMask, /\d/g] : cnpjMask;
  };

  useEffect(() => {
    const nameState = `partners.${index}.state`;
    const nameCity = `partners.${index}.city` as const;

    const subscription = watch((_, { name, type }) => {
      if (name !== nameState || type !== "change") return;

      setValue(nameCity, "");
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Box sx={{ p: 4, border: 1, borderColor: "divider", pt: 6 }}>
      <Stack gap={2}>
        <Stack direction="row" gap={2} flexWrap="wrap">
          <ControllerInput
            control={control}
            name={`partners[${index}].name`}
            inputProps={{
              label: "Nome",
              sx: { flex: 2, minWidth: 200 },
              disabled,
            }}
          />
          <ControllerInput
            control={control}
            name={`partners[${index}].share`}
            inputProps={{
              label: "Participação",
              type: "number",
              sx: { width: 100 },
              InputProps: {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              },
              disabled,
            }}
          />

          <Box flexShrink={0}>
            <Controller
              name={`partners.${index}.wageCompesation`}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ m: 0, height: "auto" }}
                  disabled={disabled}
                  control={
                    <Checkbox
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      checked={field.value}
                      value={field.value}
                      inputRef={field.ref}
                      disabled={disabled}
                    />
                  }
                  label={
                    <Stack direction="row" gap={0.5} alignItems="center">
                      <Typography>Receberá pro-labore</Typography>
                      <Tooltip title="Sócio receberá remuneração mensal por trabalho administrativo realizado para a empresa.">
                        <HelpIcon fontSize="small" sx={{ color: grey[600] }} />
                      </Tooltip>
                    </Stack>
                  }
                />
              )}
            />
          </Box>
        </Stack>

        <Stack direction="row" gap={2}>
          <ControllerInput
            name={`partners[${index}].occupation`}
            control={control}
            inputProps={{
              label: "Profissão",
              sx: { flex: 2 },
              disabled,
            }}
          />

          <Controller
            name={`partners.${index}.maritalStatus`}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl sx={{ flex: 1 }} disabled={disabled}>
                <InputLabel>Estado civil</InputLabel>
                <Select
                  {...field}
                  value={field.value ?? ""}
                  label="Estado civil"
                  size="small"
                  fullWidth
                  sx={{ height: "100%" }}
                  MenuProps={{ sx: { maxHeight: "50vh" } }}
                  error={!!fieldState.error?.message}
                >
                  <MenuItem value="" disabled>
                    Selecione:
                  </MenuItem>
                  {maritalStatusTranslate.map((marital, index) => (
                    <MenuItem value={index} key={index}>
                      {marital}
                    </MenuItem>
                  ))}
                </Select>
                {!!fieldState.error?.message && (
                  <Typography variant="body1">{fieldState.error?.message}</Typography>
                )}
              </FormControl>
            )}
          />

          <Controller
            name={`partners.${index}.communityProperty`}
            control={control}
            render={(props) => (
              <FormControl
                sx={{ flex: 1 }}
                disabled={
                  disabled || !maritalStatus || (maritalStatus !== 5 && maritalStatus !== 1)
                }
              >
                <InputLabel>Regime de bens</InputLabel>
                <Select
                  {...props.field}
                  value={props.field.value ?? ""}
                  label="Regime de bens"
                  size="small"
                  fullWidth
                  sx={{ height: "100%" }}
                  MenuProps={{ sx: { maxHeight: "50vh" } }}
                  error={!!props.fieldState.error?.message}
                >
                  <MenuItem value="" disabled>
                    Selecione:
                  </MenuItem>
                  {civilState.map((state, index) => (
                    <MenuItem value={index} key={index}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
                {!!props.fieldState.error?.message && (
                  <Typography variant="body1">{props.fieldState.error?.message}</Typography>
                )}
              </FormControl>
            )}
          />
        </Stack>

        <Stack flexWrap="wrap" direction="row" gap={2}>
          <Controller
            name={`partners.${index}.cpf`}
            control={control}
            render={({ fieldState, field }) => (
              <MaskedInput
                disabled={disabled}
                mask={maskBuilder(field.value)}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    disabled={disabled}
                    fullWidth
                    label="CPF / CNPJ"
                    variant="outlined"
                    inputRef={ref}
                    {...props}
                    sx={{ flex: 1, maxWidth: "250px", minWidth: "145px" }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              ></MaskedInput>
            )}
          />

          <Controller
            name="responsible"
            control={control}
            render={(props) => (
              <FormControlLabel
                value={field.id}
                disabled={disabled}
                control={
                  <Radio
                    onChange={() => props.field.onChange(index)}
                    checked={props.field.value === index}
                  />
                }
                label={
                  <Stack direction="row" gap={0.5} alignItems="center">
                    <Typography>Sócio responsável perante a Receita Federal</Typography>
                    <Tooltip title="Você deve indicar um dos sócios para responder pela empresa junto a Receita Federal.">
                      <HelpIcon fontSize="small" sx={{ color: grey[600] }} />
                    </Tooltip>
                  </Stack>
                }
              />
            )}
          />
        </Stack>
      </Stack>

      <Box mt={2}>
        <AddressForm
          control={control}
          namesFields={{
            street: `partners.${index}.street`,
            neighborhood: `partners.${index}.neighborhood`,
            zipCode: `partners.${index}.zipCode`,
            city: `partners.${index}.city`,
            complement: `partners.${index}.complement`,
            state: `partners.${index}.state`,
            streetNumber: `partners.${index}.streetNumber`,
          }}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};

export default Partner;
