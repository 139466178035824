import { FC } from "react";
import { Control, UseFormRegister, useWatch, Controller } from "react-hook-form";

import { Box, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";

import { InvoiceForm, InvoiceType } from ".";
import { InvoiceConfiguration } from "types/Invoice";
import formatBytes from "utils/formatBytes";

interface CertificateProps {
  control: Control<InvoiceForm>;
  invoiceConfiguration: InvoiceConfiguration;
  register: UseFormRegister<InvoiceForm>;
  invoiceType: InvoiceType;
}

const Certificate: FC<CertificateProps> = ({
  control,
  invoiceConfiguration,
  register,
  invoiceType,
}) => {
  const file = useWatch({
    control,
    name: "file",
  });

  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={600}
        borderBottom={1}
        borderColor="divider"
        sx={{ mb: 2 }}
      >
        Certificado digital
      </Typography>

      <Typography variant="body1">
        Para emitir{" "}
        {invoiceType === "NFS-e"
          ? "Nota Fiscal de Serviços Eletrônica (NFS-e)"
          : "Nota Fiscal Eletrônica de Produto (NF-e)"}{" "}
        é necessário o envio do seu certificado do tipo PJ A1.
      </Typography>

      {!!invoiceConfiguration.certificateName && (
        <Box
          p={2}
          mt={2}
          bgcolor={grey[100]}
          border={2}
          borderColor={
            invoiceConfiguration.certificateMessage !== null ? "error.light" : "warning.light"
          }
        >
          <Typography variant="body1" fontWeight={600}>
            {invoiceConfiguration.certificateMessage !== null
              ? "Certificado expirado"
              : "Dados do certificado em uso"}
          </Typography>

          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ wordWrap: "break-word", mt: 0.5 }}
          >
            {invoiceConfiguration.certificateName}
            <br />
            Vencimento em {invoiceConfiguration.certificateDueDate}
          </Typography>
        </Box>
      )}

      <Box mt={1.5}>
        <Typography variant="body1" fontWeight={600}>
          Enviar novo arquivo de certificado
        </Typography>

        <Stack direction="row" gap={1} alignItems="center">
          <label style={{ width: "fit-content" }}>
            <input
              type="file"
              style={{
                display: "none",
              }}
              {...register("file")}
              name="file"
            />
            <LoadingButton
              variant="contained"
              component="span"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              Selecionar arquivo
            </LoadingButton>
          </label>

          {!!file && file.length !== 0 && (
            <Typography color="text.secondary" variant="body2">
              {file[0].name} ({formatBytes(file[0].size)})
            </Typography>
          )}
        </Stack>
      </Box>

      <Controller
        name="certificatePassword"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={!!fieldState.error?.message}
            helperText={
              fieldState.error?.message ??
              "Observação: o certificado será instalado de maneira automática. Nenhuma interação humana ocorrerá."
            }
            type="password"
            label="Senha do certificado"
            sx={{ mt: 1.5, width: "50%" }}
          />
        )}
      />
    </Box>
  );
};

export default Certificate;
