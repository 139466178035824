import { Control, Controller, Path } from "react-hook-form";
import { FormControl, FormLabel, FormControlLabel, Checkbox } from "@mui/material";

interface PaymentMethodFields {
  creditCard: boolean;
  bankSlip: boolean;
}

interface PaymentMethodFieldsProps<T extends PaymentMethodFields = PaymentMethodFields> {
  control: Control<T>;
}

export function PaymentMethodFields<T extends PaymentMethodFields = PaymentMethodFields>({
  control,
}: PaymentMethodFieldsProps<T>) {
  return (
    <FormControl component="fieldset" variant="standard" sx={{ flex: 1 }}>
      <FormLabel component="legend">Formas de pagamento</FormLabel>
      <Controller
        control={control}
        name={"creditCard" as Path<T>}
        render={({ field }) => (
          <FormControlLabel
            sx={{ m: 0, height: "auto" }}
            control={
              <Checkbox
                onBlur={field.onBlur}
                onChange={field.onChange}
                checked={field.value}
                value={field.value}
                inputRef={field.ref}
              />
            }
            label="Cartão de crédito"
          />
        )}
      />
      <Controller
        control={control}
        name={"bankSlip" as Path<T>}
        render={({ field }) => (
          <FormControlLabel
            sx={{ m: 0, height: "auto" }}
            control={
              <Checkbox
                onBlur={field.onBlur}
                onChange={field.onChange}
                checked={field.value}
                value={field.value}
                inputRef={field.ref}
              />
            }
            label="Boleto bancário"
          />
        )}
      />
    </FormControl>
  );
}
