import { FC } from "react";
import useQueryServicesAndPlansClient from "./hooks/useQueryServicesAndPlansClient";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";

import WithCompany from "components/WithCompany";
import ServiceCard from "./ServiceCard";

const ServicesList: FC<{ companyToken: string }> = ({ companyToken }) => {
  const { data, isLoading } = useQueryServicesAndPlansClient(companyToken);

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" height={400}>
        <CircularProgress />
      </Stack>
    );
  }

  if (data) {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        }}
        gap={3}
      >
        {data.premiumService && data.premiumService.isActive && (
          <ServiceCard service={data.premiumService} companyToken={companyToken} />
        )}
        {data.defaultServices.map((service) => (
          <ServiceCard key={service.id} service={service} companyToken={companyToken} />
        ))}
        {data.plans
          .filter((plan) => plan.isActive)
          .map((plan) => (
            <ServiceCard key={plan.planId} service={plan} companyToken={companyToken} />
          ))}
        {data.customServices.map((service) => (
          <ServiceCard key={service.id} service={service} companyToken={companyToken} />
        ))}
      </Box>
    );
  }

  return (
    <Box>
      <Typography>Ocorreu um erro ao carregar os serviços</Typography>
    </Box>
  );
};

export default WithCompany(ServicesList);
