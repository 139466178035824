import { Dispatch, SetStateAction, createContext } from "react";
import { ImportOFXTransaction } from "types/Company";

export interface IExtractContext {
  extracts: ImportOFXTransaction[];
  setExtractsState?: Dispatch<SetStateAction<ImportOFXTransaction[]>>
  onChangeExtract: <T extends keyof ImportOFXTransaction>(
    extract: ImportOFXTransaction, name: T, value: ImportOFXTransaction[T]
  ) => void;
  enableEditExtract: (extract: ImportOFXTransaction) => void;
  fileList: File[];
  onChangeFile: (newFileList: File[]) => void;
  requestSave: (extract: ImportOFXTransaction) => void;
}

export const ExtractContext = createContext<IExtractContext>({} as IExtractContext);
