import { FC, useState } from "react";

import DialogChangePaymentForCredit from "./DialogChangePaymentForCredit";

import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { PaymentMethod } from "types/Company";

import { useAuth } from "context/Auth";
import { CardContent } from "../CardWrapper";
import useQueryPaymentInfoProfile from "../../hooks/useQueryPaymentInfoProfile";

const paymentInfo: {
  [x in PaymentMethod]: {
    name: string;
    Icon: JSX.Element;
  };
} = {
  BankSlip: {
    name: "boleto bancário",
    Icon: <QrCodeIcon />,
  },
  CreditCard: {
    name: "cartão de crédito",
    Icon: <CreditCardIcon />,
  },
  NotSet: {
    name: "boleto bancário",
    Icon: <QrCodeIcon />,
  },
};

interface PaymentMethodsProps {
  companyToken: string;
}

const PaymentMethods: FC<PaymentMethodsProps> = ({ companyToken }) => {
  const {
    user: { userInfo },
  } = useAuth();
  const { data, isLoading } = useQueryPaymentInfoProfile(
    companyToken,
    userInfo.userType === "Admin",
  );
  const [dialogChangePaymentIsOpen, setDialogChangePaymentIsOpen] = useState(false);

  return (
    <CardContent>
      {isLoading && (
        <Stack height={100} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {data && data.currentMethod && (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" gap={1}>
            {paymentInfo[data.currentMethod.paymentMethod].Icon}
            <Typography variant="body1" textTransform="capitalize">
              {paymentInfo[data.currentMethod.paymentMethod].name}
            </Typography>
          </Stack>

          <Button
            variant="contained"
            size="small"
            onClick={() => setDialogChangePaymentIsOpen(true)}
          >
            Alterar para{" "}
            {data.currentMethod.paymentMethod === "BankSlip" ? "cartão de crédito" : "boleto"}
          </Button>
        </Stack>
      )}

      {data && !data?.currentMethod && (
        <Typography variant="body1">Nenhuma método de pagamento</Typography>
      )}

      <DialogChangePaymentForCredit
        companyToken={companyToken}
        open={dialogChangePaymentIsOpen}
        onClose={() => setDialogChangePaymentIsOpen(false)}
      />
    </CardContent>
  );
};

export default PaymentMethods;
