import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { deleteInvoice } from "services/Requests/stockControl";

const useMutationDeleteInvoice = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteInvoice, {
    onSuccess(_, { companyToken }) {
      toast.success("Nota cancelada com sucesso");
      queryClient.invalidateQueries([queryKeys.invoiceConfigStockControl, companyToken], {
        exact: false,
      });
    },
  });

  return mutation;
};

export default useMutationDeleteInvoice;
