import { FC } from "react";

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { FormCreateAccount } from ".";
import { Control, Controller } from "react-hook-form";
import ControllerInput from "components/ControllerInput";

interface AccoutInformationProps {
  control: Control<FormCreateAccount>;
}

const AccoutInformation: FC<AccoutInformationProps> = ({ control }) => {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <ControllerInput
        name="accountNick"
        control={control}
        inputProps={{
          label: "Apelido para a Conta",
          sx: { flex: 1 },
        }}
      />

      <Controller
        name="balanceType"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ flex: 1 }}>
            <FormLabel>Situação da conta</FormLabel>
            <RadioGroup {...field} row>
              <FormControlLabel value="1" control={<Radio />} label="Positivo" />
              <FormControlLabel value="2" control={<Radio />} label="Negativo" />
            </RadioGroup>
          </FormControl>
        )}
      />
    </Stack>
  );
};

export default AccoutInformation;
