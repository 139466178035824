import { useMutation, useQueryClient } from "react-query";

import toast from "react-hot-toast";
import { createBill, CreateBillPayload } from "services/Requests/billing";
import getSubdomain from "utils/getSubdomain";
import queryKeys from "react-query/constants";

const useMutationCreateBill = (showToast = true) => {
  const query = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<CreateBillPayload, "subdomain">) =>
      createBill({
        ...payload,
        subdomain,
      }),
    {
      onSuccess(_, variables) {
        if (showToast) {
          toast.success("Fatura avulsa criada com sucesso!");
        }
        query.invalidateQueries([queryKeys.clientBills, variables.payerCompanyId]);
      },
    },
  );
  return mutation;
};

export default useMutationCreateBill;
