import { useMutation } from "react-query";
import { downloadBatch } from "services/Requests/invoice";
import createAndDownloadBlobFile from "utils/createAndDownloadBlobFile";

const useMutationDownloadBatch = () => {
  const mutation = useMutation(downloadBatch, {
    onSuccess(arrayBuffer) {
      createAndDownloadBlobFile(arrayBuffer, `NF-Lote-${Date.now()}`, "zip");
    },
  });

  return mutation;
};

export default useMutationDownloadBatch;
