import { FC, useState } from "react";

import { DRE } from "types/ManagerialIncomeStatement";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { grey } from "@mui/material/colors";
import Analytical from "./Analytical";
import DialogCreateOrEditAnalytical from "./components/DialogCreateOrEditAnalytical";
import { useAuth } from "context/Auth";

const Synthetic: FC<{ dre: DRE }> = ({ dre }) => {
  const [createAnalytical, setCreateAnalytical] = useState(false);
  const { user: { company }, } = useAuth();
  return (
    <>
      <Box bgcolor={grey[300]} borderRadius={1} borderBottom={1} borderColor="divider">
        <Stack p={1} direction="row" alignItems="center" justifyContent="space-between" gap={2}>
          <Typography fontWeight={600} textTransform="uppercase">{dre.account}</Typography>
          <IconButton color="primary" onClick={() => setCreateAnalytical(true)}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Stack>

        <Box>
          {dre?.children?.map((item) => (
            <Analytical dre={item} key={item.id} />
          ))}
        </Box>
      </Box>

      <DialogCreateOrEditAnalytical
        open={createAnalytical}
        onClose={() => setCreateAnalytical(false)}
        parentId={dre.id}
        company={company}
      />
    </>
  );
};

export default Synthetic;
