import endpoints from "../endpoints";
import api from "../api";
import { Contract } from "types/Contract";
import getFormData from "utils/objectToFormData";

export interface GetContractsPayload {
  companyToken: string;
}

export const getContracts = async (payload: GetContractsPayload): Promise<Contract[]> => {
  const { data } = await api.post<Contract[]>(endpoints.contract.getContracts, payload);
  return data;
};

export interface UploadContractPayload {
  companyToken: string;
  file: File;
}

export const uploadContract = async (payload: UploadContractPayload) => {
  await api.post(endpoints.contract.uploadContracts, getFormData(payload));
};

export interface DeleteContractPayload {
  documentToken: string;
  companyToken: string;
}

export const deleteContract = async (payload: DeleteContractPayload) => {
  await api.post(endpoints.contract.delete, {
    ...payload,
    fullPath: "Contratos+Servicos",
  });
};
