import endpoints from "../endpoints";
import api from "../api";
import {
  ChartOfAccountFromToGroupClass,
  ChartOfAccountFromToGroupNature,
  ChartOfAccountsFromToGroup,
  GlobalAccountanting,
  GlobalCategoryType,
  GroupChartOfAccounts,
  ImportAccount,
} from "types/Accountant";
import getFormData from "utils/objectToFormData";
import { CategoryItem, GetGlobalCategoriesByGroupResponse } from "types/Company";
import { DeleteFinancialCategoryPayload, UpdateFinancialCategoryPayload } from "./companies";

export interface GetGroupChartOfAccountsFromToPayload {
  id: number;
}

export const getGroupChartOfAccountsFromTo = async (
  payload: GetGroupChartOfAccountsFromToPayload,
): Promise<GroupChartOfAccounts> => {
  const { data } = await api.post<GroupChartOfAccounts>(
    endpoints.accountant.getGroupChartOfAccountsFromTo,
    payload,
  );
  return data;
};

export interface GetChartOfAccountsFromToGroupPayload {
  groupId: number;
  term?: string;
}

export const getChartOfAccountsFromToGroup = async (
  payload: GetChartOfAccountsFromToGroupPayload,
): Promise<ChartOfAccountsFromToGroup[]> => {
  const { data } = await api.post<ChartOfAccountsFromToGroup[]>(
    endpoints.accountant.getChartOfAccountsFromToGroup,
    payload,
  );
  return data;
};

export interface GetClientChartOfAccountsPayload {
  companyToken: string;
}

export const getClientChartOfAccounts = async (
  payload: GetClientChartOfAccountsPayload,
): Promise<GetGlobalCategoriesByGroupResponse> => {
  const { data } = await api.post<GetGlobalCategoriesByGroupResponse>(
    endpoints.accountant.getClientChartOfAccounts,
    payload,
  );
  return data;
};

export interface GetCompanyGlobalAccountantingPayload {
  companyToken: string;
}

export const getCompanyGlobalAccountanting = async (
  payload: GetCompanyGlobalAccountantingPayload,
): Promise<GlobalAccountanting[]> => {
  const { data } = await api.post<GlobalAccountanting[]>(
    endpoints.accountant.getCompanyGlobalAccountanting,
    payload,
  );
  return data;
};

export interface AddAccountFromToPayload {
  account: string;
  class: ChartOfAccountFromToGroupClass;
  description: string;
  fromDominio: string;
  groupChartOfAccountsToFromId: number;
  isAnalytic: boolean;
  nature: ChartOfAccountFromToGroupNature | null;
}

export const addAccountFromTo = async (payload: AddAccountFromToPayload) => {
  await api.post(endpoints.accountant.addAccountFromTo, payload);
};

export interface DeleteAccountsFromToPayload {
  ids: number[];
}

export const deleteAccountsFromTo = async (payload: DeleteAccountsFromToPayload) => {
  await api.post(endpoints.accountant.deleteAccountsFromTo, payload);
};

export type UpdateFromToPayload = ChartOfAccountsFromToGroup;

export const updateFromTo = async (payload: UpdateFromToPayload) => {
  await api.post(endpoints.accountant.updateFromTo, payload);
};

export type DeleteAccountFromToPayload = ChartOfAccountsFromToGroup;

export const deleteAccountFromTo = async (payload: DeleteAccountFromToPayload) => {
  await api.post(endpoints.accountant.deleteAccountFromTo, payload);
};

export interface DeleteAllAccountsFromToPayload {
  groupId: number;
}

export const deleteAllAccountsFromTo = async (payload: DeleteAllAccountsFromToPayload) => {
  await api.post(endpoints.accountant.deleteAllAccountsFromTo, payload);
};

export interface ImportFileExcelPayload {
  groupId: number;
  file: File;
}

export const importFileExcel = async ({
  file,
  groupId,
}: ImportFileExcelPayload): Promise<ImportAccount[]> => {
  const initialValues = {
    finalPosition1: 1,
    finalPosition2: 1,
    finalPosition3: 1,
    finalPosition4: 1,
    hasFooter: false,
    hasHeader: false,
    initialPosition1: 1,
    initialPosition2: 1,
    initialPosition3: 1,
    initialPosition4: 1,
  };

  const formData = getFormData(initialValues);
  formData.append("groupId", groupId.toString());
  formData.append("file", file);

  const { data } = await api.post<ImportAccount[]>(endpoints.accountant.importFileExcel, formData);
  return data;
};

export interface ImportExcelPayload {
  groupId: number;
  accounts: ImportAccount[];
}

export const importExcel = async (payload: ImportExcelPayload) => {
  await api.post(endpoints.accountant.importExcel, payload);
};

export interface GetGlobalAccountantingPayload {
  groupId: number;
}

export const getGlobalAccountanting = async (
  payload: GetGlobalAccountantingPayload,
): Promise<GlobalAccountanting[]> => {
  const { data } = await api.post<GlobalAccountanting[]>(
    endpoints.accountant.getGlobalAccountanting,
    payload,
  );
  return data;
};

export interface UpdateGlobalCategoriesAccountPayload {
  account: string | null;
  accountId: number | null;
  id: number;
  type: GlobalCategoryType;
}

export const updateGlobalCategoriesAccount = async (
  payload: UpdateGlobalCategoriesAccountPayload,
) => {
  await api.post(endpoints.accountant.updateGlobalCategoriesAccount, payload);
};

export interface TransferFinancialCategoryPayload {
  id: number;
  newId: number;
}

export const transferFinancialCategory = async (payload: TransferFinancialCategoryPayload) => {
  await api.post(endpoints.accountant.transferFinancialCategory, payload);
};

export interface UpdateOrderGlobalCategoriesPayload {
  container: { id: number }[];
}

export const updateOrderGlobalCategories = async (payload: UpdateOrderGlobalCategoriesPayload) => {
  await api.post(endpoints.accountant.updateOrderGlobalCategories, payload);
};

export interface ImportToFromPayload {
  companyToken: string;
  id: number;
}

export const importToFrom = async (payload: ImportToFromPayload) => {
  await api.post(endpoints.accountant.importToFrom, payload);
};

export interface MoveReleasesFinancialPayload {
  companyToken: string;
  categories: CategoryItem[];
}

export const moveReleasesFinancial = async (payload: MoveReleasesFinancialPayload) => {
  await api.post(endpoints.accountant.moveReleasesFinancial, payload);
};

export interface CreateCompanyFinancialCategoryPayload {
  companyToken: string;
  name: string;
  nature?: "debit" | "credit";
  parentCategoryId?: number;
}

export const createCompanyFinancialCategory = async (
  payload: CreateCompanyFinancialCategoryPayload,
) => {
  await api.post(endpoints.accountant.createCompanyFinancialCategory, payload);
};

export type UpdateCompanyOrderGlobalCategoriesPayload = UpdateOrderGlobalCategoriesPayload;

export const updateCompanyOrderGlobalCategories = async (
  payload: UpdateCompanyOrderGlobalCategoriesPayload,
) => {
  await api.post(endpoints.accountant.updateCompanyOrderGlobalCategories, payload);
};

export interface UpdateCompanyFinancialCategoryPayload
  extends Omit<UpdateFinancialCategoryPayload, "groupId"> {
  companyToken: string;
  companyId: number;
}

export const updateCompanyFinancialCategory = async (
  payload: UpdateCompanyFinancialCategoryPayload,
) => {
  if (payload.type === "container") {
    payload = {
      ...payload,
      column: [[]],
    };
  }

  await api.post(endpoints.accountant.updateCompanyFinancialCategory, payload);
};

export type DeleteCompanyFinancialCategoryByIdPayload = DeleteFinancialCategoryPayload;

export const deleteCompanyFinancialCategoryById = async (
  payload: DeleteCompanyFinancialCategoryByIdPayload,
) => {
  await api.post(endpoints.accountant.deleteCompanyFinancialCategoryById, payload);
};

export interface UpdateCompanyChartOfAccountsPayload {
  categoryId: number | null;
  companyToken: string;
  creditAccount?: string | null;
  creditAccountCx?: string | null;
  debtAccount?: string | null;
  debtAccountCx?: string | null;
  id: number;
  name: string;
  type: number;
}

export const updateCompanyChartOfAccounts = async (
  payload: UpdateCompanyChartOfAccountsPayload,
) => {
  await api.post(endpoints.accountant.updateCompanyChartOfAccounts, payload);
};

export interface GetCompanyChartOfAccountsFromToPayload {
  companyToken: string;
}

export const getCompanyChartOfAccountsFromTo = async (
  payload: GetCompanyChartOfAccountsFromToPayload,
) => {
  const { data } = await api.post<ChartOfAccountsFromToGroup[]>(
    endpoints.accountant.getCompanyChartOfAccountsFromTo,
    payload,
  );
  return data;
};

export interface getCompanyChartOfAccountsFromToByTermPayload {
  companyToken: string;
  term: string;
}

export const getCompanyChartOfAccountsFromToByTerm = async (
  payload: getCompanyChartOfAccountsFromToByTermPayload,
) => {
  const { data } = await api.post<ChartOfAccountsFromToGroup[]>(
    endpoints.accountant.getCompanyChartOfAccountsFromToByTerm,
    payload,
  );
  return data;
};

export interface AddCompanyAccountFromToPayload
  extends Omit<AddAccountFromToPayload, "groupChartOfAccountsToFromId"> {
  companyToken: string;
}

export const addCompanyAccountFromTo = async (payload: AddCompanyAccountFromToPayload) => {
  await api.post(endpoints.accountant.addCompanyAccountFromTo, payload);
};

export interface UpdateCompanyFromToPayload extends ChartOfAccountsFromToGroup {
  companyToken: string;
}

export const updateCompanyFromTo = async (payload: UpdateCompanyFromToPayload) => {
  await api.post(endpoints.accountant.updateCompanyFromTo, payload);
};

export type DeleteCompanyAccountFromToPayload = ChartOfAccountsFromToGroup;

export const deleteCompanyAccountFromTo = async (payload: DeleteCompanyAccountFromToPayload) => {
  await api.post(endpoints.accountant.deleteCompanyAccountFromTo, payload);
};
