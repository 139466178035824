import { FC } from "react";
import useMutationUpdateCompanyActivate from "../hooks/useMutationUpdateCompanyActivate";

import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import useMutationUpdateCompanyStatus from "../hooks/useMutationUpdateCompanyStatus";

interface DialogUpdateCompanyStatusProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyId: number;
  type: "activate" | "deactivate";
}

const DialogUpdateCompanyStatus: FC<DialogUpdateCompanyStatusProps> = ({
  onClose,
  companyId,
  type,
  ...props
}) => {
  const { mutateAsync: mutateActivate, isLoading: loadingActivate } =
    useMutationUpdateCompanyActivate();
  const { mutateAsync: mutateDeactivate, isLoading: loadingDeactivate } =
    useMutationUpdateCompanyStatus();
  const isLoading = loadingActivate || loadingDeactivate;

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleActivate = async () => {
    const runMutate = async () => {
      if (type === "activate") {
        await mutateActivate({
          companyId,
          status: 0,
        });
      } else {
        mutateDeactivate({
          companyId,
          status: -1,
        });
      }
    };

    await runMutate();
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {type === "activate" ? "Ativação" : "Inativação"} da empresa
      </DialogTitleWithCloseButton>

      <DialogContent>
        Confirma a {type === "activate" ? "ativação" : "inativação"} da empresa?
      </DialogContent>

      <DialogActions>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleActivate}>
          Sim
        </LoadingButton>
        <Button disabled={isLoading} variant="outlined" onClick={handleClose}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUpdateCompanyStatus;
