import { FC } from "react";
import useMutationDeleteUser from "../UsersContent/hooks/useMutationDeleteUser";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { UserAdmin } from "types/User";
import { UserTypeContent, userTypeTranslate } from "../UsersContent";
import { UserCompany } from "types/Admin";

interface DialogDeleteAdminUser extends DialogProps {
  user: UserAdmin | UserCompany;
  userType: UserTypeContent;
}

const DialogDeleteAdminUser: FC<DialogDeleteAdminUser> = ({
  user,
  onClose,
  userType,
  ...props
}) => {
  const { isLoading, mutateAsync } = useMutationDeleteUser();

  const handleCloseConfirmationDelete = () => {
    if (isLoading || !onClose) return;
    onClose({}, "escapeKeyDown");
  };

  const handleDeleteUser = async () => {
    try {
      await mutateAsync({
        ...user,
        profileId: user.profileId || null,
      });
      handleCloseConfirmationDelete();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth {...props} onClose={handleCloseConfirmationDelete}>
      <DialogTitle>Excluir {userTypeTranslate[userType]}</DialogTitle>

      <DialogContent dividers>
        Tem certeza que deseja excluir o {userTypeTranslate[userType]}?
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} variant="outlined" onClick={handleCloseConfirmationDelete}>
          Não
        </Button>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleDeleteUser}>
          Sim
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteAdminUser;
