import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { changeStateOrderOpenCompany } from "services/Requests/openCompany";

const useMutationChangeStateOrderOpenCompany = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(changeStateOrderOpenCompany, {
    onSuccess() {
      toast.success("Migrado com sucesso!");
      queryClient.invalidateQueries(queryKeys.ordersByAssignments, { exact: false });
    },
  });
  return mutation;
};

export default useMutationChangeStateOrderOpenCompany;
