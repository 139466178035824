import produce, { setAutoFreeze } from "immer";
import { FinancialCategoryByGroupWithChildren } from "types/Company";
import { TemplateActions, Types } from ".";

export const TemplateReducer = (
  financialCategories: FinancialCategoryByGroupWithChildren[],
  action: TemplateActions,
) => {
  switch (action.type) {
    case Types.Move_Column: {
      const { indexFrom, indexTo } = action.payload;

      return produce(financialCategories, (draft) => {
        setAutoFreeze(false);
        const dragged = draft[indexFrom];

        draft.splice(indexFrom, 1);
        draft.splice(indexTo, 0, dragged);
      });
    }

    case Types.Move_Card: {
      const { indexFrom, indexFromColumn, indexTo, indexToColumn } = action.payload;

      return produce(financialCategories, (draft) => {
        setAutoFreeze(false);
        const dragged = draft[indexFromColumn].children[indexFrom];

        draft[indexFromColumn].children.splice(indexFrom, 1);
        draft[indexToColumn].children.splice(indexTo, 0, dragged);
      });
    }

    case Types.Add_Card_To_Column: {
      setAutoFreeze(false);
      const { index, indexColumn, indexToColumn } = action.payload;

      return produce(financialCategories, (draft) => {
        const dragged = draft[indexColumn].children[index];

        draft[indexColumn].children.splice(index, 1);
        draft[indexToColumn].children.push(dragged);
      });
    }

    case Types.Set_Data: {
      return action.payload.data;
    }

    default: {
      return financialCategories;
    }
  }
};

export default TemplateReducer;
