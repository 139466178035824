import { FC } from "react";

import { Box, FormControlLabel, InputAdornment, Radio, TextField, Typography } from "@mui/material";

import MaskedInput from "react-text-mask";
import ControllerInput from "components/ControllerInput";

import { Control, Controller, FieldArrayWithId } from "react-hook-form";
import { cnpjMask, cpfMask } from "utils/masks";
import { Form } from "./PartnerForm";

interface PartnerItemProps {
  control: Control<Form>;
  field: FieldArrayWithId<Form, "partners", "id">;
  index: number;
}

const PartnerItem: FC<PartnerItemProps> = ({ control, field, index }) => {
  const maskBuilder = (v: string) => {
    return v.length <= 14 ? [...cpfMask, /\d/g] : cnpjMask;
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", py: 3, pt: 4 }}>
      <Box display="grid" gridTemplateColumns="2fr 1fr" gap={2} alignItems="start">
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
          <ControllerInput
            control={control}
            name={`partners[${index}].name`}
            inputProps={{
              label: "Nome",
              sx: { gridColumn: "1/-1" },
            }}
          />

          <Controller
            name={`partners.${index}.cpf`}
            control={control}
            render={({ fieldState, field }) => (
              <MaskedInput
                mask={maskBuilder(field.value)}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="CPF / CNPJ"
                    variant="outlined"
                    inputRef={ref}
                    {...props}
                    sx={{ flex: 1, maxWidth: "250px", minWidth: "145px" }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              ></MaskedInput>
            )}
          />

          <ControllerInput
            control={control}
            name={`partners[${index}].share`}
            inputProps={{
              label: "Participação",
              type: "number",
              sx: { width: 100 },
              InputProps: {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              },
            }}
          />
        </Box>

        <Controller
          name="responsible"
          control={control}
          render={(props) => (
            <FormControlLabel
              value={field.id}
              control={
                <Radio
                  size="small"
                  onChange={() => props.field.onChange(index)}
                  checked={props.field.value === index}
                />
              }
              label={
                <Typography variant="body1">Sócio responsável perante a Receita Federal</Typography>
              }
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default PartnerItem;
