import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getInfoAccountAdmin } from "services/Requests/billing";
import getSubdomain from "utils/getSubdomain";

const useQueryInfoAccountAdmin = (payerCompanyId: number) => {
  const subdomain = getSubdomain();
  const query = useQuery([queryKeys.infoAccountAdmin, payerCompanyId], () =>
    getInfoAccountAdmin({ payerCompanyId, subdomain }),
  );
  return query;
};

export default useQueryInfoAccountAdmin;
