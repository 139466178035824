import { FC, useLayoutEffect, useRef } from "react";

import { Box, Link, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { DateTime } from "luxon";
import { ChatMessage } from "types/Mailbox";
import { BASE_URL_DOWNLOAD_DOCUMENT } from "utils/constants";

interface CommentsProps {
  comments: ChatMessage[];
}

const Comments: FC<CommentsProps> = ({ comments }) => {
  const listRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (!listRef.current) return;

    listRef.current.scrollTop = listRef.current.scrollHeight;
  }, [comments]);

  return (
    <Stack maxHeight={325} overflow="auto" ref={listRef}>
      {comments.map((comment, index, arr) => (
        <Box
          key={comment.messageId}
          border={1}
          borderBottom={index === arr.length - 1 ? 1 : 0}
          borderColor="divider"
          p={2}
          bgcolor={grey[200]}
        >
          <Typography align="right" sx={{ mb: 2 }}>
            {comment.authorName} escreveu em{" "}
            {DateTime.fromISO(comment.created).toFormat("dd/MM/yyyy HH:mm:ss")}
          </Typography>

          {comment.documents.length > 0 && (
            <Stack direction="row" justifyContent="space-between" mb={2} gap={1}>
              <Typography fontWeight={500} sx={{ flex: 1 }}>
                Anexo(s)
              </Typography>

              <Stack alignItems="end" gap={0.5} flex={1}>
                {comment.documents.map((document) => (
                  <Link
                    align="right"
                    href={`${BASE_URL_DOWNLOAD_DOCUMENT}/?id=${document.downloadToken}&fullPath=/COMPROVANTES+DE+PAGAMENTO&preview=true`}
                    target="_blank"
                    key={document.downloadToken}
                  >
                    {document.name}
                  </Link>
                ))}
              </Stack>
            </Stack>
          )}

          <Typography>{comment.message}</Typography>
        </Box>
      ))}
    </Stack>
  );
};

export default Comments;
