import { createContext } from "react";

interface SideMenuContextInterface {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCompany: boolean;
  setIsCompany: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SideMenuContext = createContext<SideMenuContextInterface>(
  {} as SideMenuContextInterface,
);
