import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { addAccountFromTo } from "services/Requests/accountant";

const useMutationAddAccountFromTo = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(addAccountFromTo, {
    onSuccess(_, { groupChartOfAccountsToFromId }) {
      toast.success("Nova conta contábil criada com sucesso!");
      queryClient.invalidateQueries([
        queryKeys.chartOfAccountsFromToGroup,
        groupChartOfAccountsToFromId,
      ]);
    },
  });
  return mutation;
};

export default useMutationAddAccountFromTo;
