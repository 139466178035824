import { FC, useEffect, useState } from "react";
import useQueryGetCompanyBankAccounts from "pages/Home/hooks/useQueryGetCompanyBankAccounts";
import { useNavigate } from "react-router-dom";

import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import Import from "./Import";

interface MainContent extends Omit<BoxProps, "children"> {
  children: React.ReactNode;
}

export const MainContent: FC<MainContent> = ({ children, ...props }) => (
  <Box
    bgcolor="background.paper"
    mt={2}
    p={2}
    borderTop={2}
    borderColor="primary.main"
    borderRadius={1}
    boxShadow={1}
    {...props}
  >
    {children}
  </Box>
);

interface ControlProps {
  companyToken: string;
}

const Control: FC<ControlProps> = ({ companyToken }) => {
  const { data: banks, isLoading, isError } = useQueryGetCompanyBankAccounts(companyToken, true);
  const navigate = useNavigate();

  const [tutorialIsOpen, setTutorialIsOpen] = useState(false);
  const [tutotalHasBeenOpened, setTutotalHasBeenOpened] = useState(false);
  const handleClose = () => {
    setTutorialIsOpen(false);
    setTutotalHasBeenOpened(true);
  };

  useEffect(() => {
    if (!banks || tutotalHasBeenOpened) return;

    if (banks.length === 0) {
      setTutorialIsOpen(true);
    }
  }, [banks, tutotalHasBeenOpened]);

  return (
    <>
      {isLoading && (
        <MainContent>
          <Stack height={300} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        </MainContent>
      )}

      {isError && <Typography>Ocorreu um erro ao carregar suas contas bancárias</Typography>}

      {!!banks && !isLoading && !isError && banks.length === 0 && (
        <MainContent>
          <Button
            onClick={() => {
              setTutorialIsOpen(true);
            }}
            variant="contained"
          >
            Primeira importação
          </Button>
        </MainContent>
      )}

      {!!banks && !isLoading && !isError && banks.length > 0 && (
        <Import companyToken={companyToken} />
      )}

      <Dialog open={tutorialIsOpen} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitleWithCloseButton onClose={handleClose}>
          Importação automática
        </DialogTitleWithCloseButton>
        <DialogContent>
          <Typography>
            Para realizar a importação automática, é necessário integrar sua conta bancária:
          </Typography>
          <Typography>
            1. Realize a integração de suas contas através do menu <b>Cadastros &gt; Contas</b>;
          </Typography>
          <Typography>
            2. Por fim, na conta bancária desejada, selecione a opção <b>Integrar conta</b>;
          </Typography>
          <Typography>
            3. Siga os passos, após esse procedimento, você poderá importar os lançamentos
            automáticamente.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column", gap: 1 }}>
          <Button variant="contained" fullWidth onClick={() => navigate("/contas")}>
            Integrar conta
          </Button>
          <Button fullWidth onClick={handleClose} startIcon={<Close />}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Control;
