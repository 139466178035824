import { FC, memo, useState } from "react";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import InvoiceTableItem from "./InvoiceTableItem";
import { Bill } from "types/Billing";

interface InvoicesTableProps {
  bills: Bill[];
  payerCompanyId: number;
  companyToken: string;
  onInvoiceSelected: (invoice: Bill) => void;
}

const InvoicesTable: FC<InvoicesTableProps> = ({
  bills,
  payerCompanyId,
  companyToken,
  onInvoiceSelected,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Vencimento</TableCell>
              <TableCell>Pagamento</TableCell>
              <TableCell>Ciclo</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>ID</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {bills.length === 0 && (
              <TableRow>
                <TableCell colSpan={9}>Nenhuma Fatura</TableCell>
              </TableRow>
            )}
            {bills.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((bill) => (
              <InvoiceTableItem
                key={bill.id}
                bill={bill}
                payerCompanyId={payerCompanyId}
                companyToken={companyToken}
                onInvoiceSelected={onInvoiceSelected}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage={"Linhas por páginas: "}
        labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={bills.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default memo(InvoicesTable);
