import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { FC } from "react";
import useMutationDeleteMovement from "../../hooks/useMutationDeleteMovement";

interface DialogDeleteMovementProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  movementId: number;
  itemId: number;
  companyToken: string;
}

const DialogDeleteMovement: FC<DialogDeleteMovementProps> = ({
  onClose,
  movementId,
  itemId,
  companyToken,
  ...props
}) => {
  const { isLoading, mutateAsync } = useMutationDeleteMovement();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync({
      companyToken,
      id: movementId,
      itemId,
    });
    onClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Excluir movimentação
      </DialogTitleWithCloseButton>

      <DialogContent>Deseja excluir esta movimentação?</DialogContent>

      <DialogActions>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleDelete}>
          Sim
        </LoadingButton>
        <Button onClick={handleClose} variant="outlined" disabled={isLoading}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteMovement;
