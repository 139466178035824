import { FC, useState } from "react";
import useQueryProducts from "../hooks/useQueryProducts";

import {
  CircularProgress,
  Stack,
  Table,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";

import WithCompany from "components/WithCompany";
import ProductItem from "./ProductItem";
import SearchInput from "components/SearchInput";

interface ProductsProps {
  companyToken: string;
}

const ProductsInner: FC<ProductsProps> = ({ companyToken }) => {
  const [search, setSearch] = useState("");
  const { data: products, isLoading, isError } = useQueryProducts(companyToken, search);

  return (
    <>
      <SearchInput
        value={search}
        onChange={(newSearch) => setSearch(newSearch)}
        fullWidth
        label="Buscar produtos"
      />

      <Divider sx={{ my: 2 }} />

      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {isError && <Typography>Ocorreu um error ao tentar pegar seus produtos</Typography>}

      {products && !isLoading && !isError && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell align="right">Estoque inicial</TableCell>
                <TableCell align="right">Valor médio unitário (R$)</TableCell>
                <TableCell align="right">Estoque mínimo</TableCell>
                <TableCell align="right">Valor total inicial (R$)</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>

            <TableBody>
              {products.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7}>Nenhum produto encontrado</TableCell>
                </TableRow>
              )}

              {products.map((product) => (
                <ProductItem product={product} key={product.id} companyToken={companyToken} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default WithCompany(ProductsInner);
