import * as yup from "yup";

const periodicityValueForParcel = [1, 3, 6, 12];
const periodicityValueForDaysAfter = [-1];

const emptyValidation = yup
  .number()
  .nullable()
  .transform((value) => {
    if (value === undefined || value === null || isNaN(value)) return null;
    return value;
  });

const dayValidation = yup
  .number()
  .nullable()
  .transform((value) => {
    if (value === undefined || value === null || isNaN(value)) return null;
    return value;
  })
  .typeError("Informe apenas número")
  .test("dayValidate", "Digite um dia válido", (value) => {
    if (value === undefined || value === null) return true;
    return value >= 1 && value <= 31;
  });

const validationSchema = yup.object().shape({
  name: yup.string().required("Informe o nome do serviço"),
  value: yup.string().required("Informe o valor do serviço"),
  typeClient: yup.string().required("Informe o tipo de cliente do serviço"),
  periodicity: yup.number(),
  parcel: yup
    .number()
    .nullable()
    .when("periodicity", (periodicity) => {
      if (periodicityValueForParcel.includes(periodicity)) {
        return dayValidation;
      }

      return emptyValidation;
    }),
  daysAfter: yup
    .number()
    .nullable()
    .when("periodicity", (periodicity) => {
      if (periodicityValueForDaysAfter.includes(periodicity)) {
        return dayValidation;
      }

      return emptyValidation;
    }),
});

export default validationSchema;
