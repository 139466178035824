import { FC } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FinancialResponse } from "types/Billing";
import stringToSlug from "utils/stringToSlug";

interface FinancialTableProps {
  financial: FinancialResponse;
}

const FinancialTable: FC<FinancialTableProps> = ({ financial }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Dt. Ocorrência</TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell align="right">Valor</TableCell>
            <TableCell align="right">Saldo</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {financial.transactions.length === 0 && (
            <TableRow>
              <TableCell colSpan={5}>Nenhum registro encontrado</TableCell>
            </TableRow>
          )}

          {financial.transactions.map((transaction, i) => (
            <TableRow key={transaction.entryDate + stringToSlug(transaction.description) + i} hover>
              <TableCell>{transaction.entryDate}</TableCell>
              <TableCell>{transaction.customer}</TableCell>
              <TableCell>{transaction.description}</TableCell>
              <TableCell
                align="right"
                sx={{
                  color: transaction.type === "credit" ? "success.main" : "error.main",
                  whiteSpace: "nowrap",
                }}
              >
                {transaction.value}
              </TableCell>
              <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                {transaction.balance}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FinancialTable;
