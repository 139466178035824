import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getPaymentTypes } from "services/Requests/stockControl";

const useQueryPaymentTypes = () => {
  const query = useQuery(queryKeys.paymentTypes, () => getPaymentTypes());
  return query;
};

export default useQueryPaymentTypes;
