import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCompanyActivities } from "services/Requests/openCompany";

const useQueryCompanyActivities = () => {
  const query = useQuery(queryKeys.companyActivities, getCompanyActivities, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
  return query;
};

export default useQueryCompanyActivities;
