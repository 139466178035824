import { FC, memo, useState } from "react";

import { IconButton, MenuItem, Select, TableCell, TableRow, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ImportAccount } from "types/Accountant";

interface ImportAccountsTableItemProps {
  importAccount: ImportAccount;
  onChangeAccount: (account: ImportAccount) => void;
  onDeleteAccount: () => void;
}

const ImportAccountsTableItemInner: FC<ImportAccountsTableItemProps> = ({
  importAccount,
  onChangeAccount,
  onDeleteAccount,
}) => {
  const [account, setAccount] = useState(importAccount);

  function changeAccount<T extends keyof ImportAccount>(key: T, value: ImportAccount[T]) {
    setAccount((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  const handleUpdate = () => {
    if (JSON.stringify(importAccount) === JSON.stringify(account)) return;
    onChangeAccount(account);
  };

  return (
    <TableRow hover>
      <TableCell>
        <Select
          fullWidth
          size="small"
          value={account.type}
          onBlur={handleUpdate}
          onChange={(e) => changeAccount("type", e.target.value as ImportAccount["type"])}
        >
          <MenuItem disabled value="">
            Selecione:{" "}
          </MenuItem>
          <MenuItem value="Sintética">Sintética</MenuItem>
          <MenuItem value="Analítica">Analítica</MenuItem>
        </Select>
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          value={account.account}
          size="small"
          onBlur={handleUpdate}
          onChange={(e) => {
            changeAccount("account", e.target.value);
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          value={account.accountMin}
          size="small"
          onBlur={handleUpdate}
          onChange={(e) => {
            changeAccount("accountMin", e.target.value);
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          value={account.description}
          size="small"
          onBlur={handleUpdate}
          onChange={(e) => {
            changeAccount("description", e.target.value);
          }}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={onDeleteAccount}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const ImportAccountsTableItem = memo(ImportAccountsTableItemInner);
export default ImportAccountsTableItem;
