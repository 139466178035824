import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import { GroupChartOfAccounts } from "types/Accountant";
import ChartOfAccountsControl from "./ChartOfAccountsControl";
import CreateOrEditAccount from "./components/CreateOrEditAccount";
import DialogDeleteAccount from "./components/DialogDeleteAccount";
import DialogImportAccounts from "./components/DialogImportAccounts";

interface PageProps {
  groupChart: GroupChartOfAccounts;
}

const Page: FC<PageProps> = ({ groupChart }) => {
  const navigate = useNavigate();
  const [createAccountIsOpen, setCreateAccountIsOpen] = useState(false);
  const [deleteAllAccountsIsOpen, setDeleteAllAccountsIsOpen] = useState(false);
  const [importIsOpen, setImportIsOpen] = useState(false);

  return (
    <>
      <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h4" fontWeight="bold">
            {groupChart.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Plano de Contas Contábil
          </Typography>
        </Stack>

        <Button
          variant="contained"
          startIcon={<AddIcon fontSize="small" />}
          size="small"
          onClick={() => setCreateAccountIsOpen(true)}
        >
          Nova conta contábil
        </Button>
        <Button
          variant="contained"
          startIcon={<ImportExportIcon fontSize="small" />}
          size="small"
          onClick={() => setImportIsOpen(true)}
        >
          Importar plano
        </Button>
        <Button variant="text" size="small" onClick={() => setDeleteAllAccountsIsOpen(true)}>
          Excluir todas as contas
        </Button>
        <Button
          variant="text"
          href="/contabil"
          onClick={(e) => {
            e.preventDefault();
            navigate("/contabil");
          }}
          size="small"
        >
          Lista de Templates
        </Button>
      </Stack>

      <Box mt={2} boxShadow={2} bgcolor="#fff" position="relative">
        <ChartOfAccountsControl groupChart={groupChart} />
      </Box>

      <CreateOrEditAccount
        open={createAccountIsOpen}
        onClose={() => setCreateAccountIsOpen(false)}
        groupChartOfAccountsToFromId={groupChart.id}
      />

      <DialogDeleteAccount
        open={deleteAllAccountsIsOpen}
        onClose={() => setDeleteAllAccountsIsOpen(false)}
        groupId={groupChart.id}
      />

      <DialogImportAccounts
        open={importIsOpen}
        onClose={() => setImportIsOpen(false)}
        groupId={groupChart.id}
      />
    </>
  );
};

export default Page;
