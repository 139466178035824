import { FC } from "react";

import { Box, Stack, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";

import BankAutoComplete from "components/BankAutoComplete";
import ControllerInput from "components/ControllerInput";
import { PartnerForm } from ".";

interface BankInformationProps {
  control: Control<PartnerForm>;
}

const BankInformation: FC<BankInformationProps> = ({ control }) => {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={400}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 2 }}
      >
        Informações Bancárias
      </Typography>

      <Stack direction="row" gap={2} flexWrap="wrap">
        <Controller
          control={control}
          name="bank"
          render={({ field, fieldState }) => (
            <BankAutoComplete
              sx={{ flex: 1 }}
              value={field.value}
              ref={field.ref}
              onBlur={field.onBlur}
              onChange={(_, value) => field.onChange(value)}
              textFieldProps={{
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message,
              }}
            />
          )}
        />

        <Stack direction="row" flex={1} gap={2} flexWrap="wrap">
          <ControllerInput
            control={control}
            name="agency"
            inputProps={{
              label: "Agência",
              sx: { flex: 1 },
              type: "number",
            }}
          />
          <ControllerInput
            control={control}
            name="account"
            inputProps={{
              label: "Conta",
              sx: { flex: 1 },
              type: "number",
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default BankInformation;
