import { FC, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import SortableTableHead from "components/SortableTableHead";
import ClientItem from "./ClientItem";

import { NameOfRoles, translateNameOfRoles } from "..";
import { CompanyParticipants } from "types/Company";
import { Order } from "types/SemanticTypes";
import stableSort from "utils/stableSort";
import comparatorSortByProperty from "utils/comparatorSortByProperty";

type CompanyParticipantsKeys = keyof CompanyParticipants;

interface HeadCells {
  label: string;
  id: CompanyParticipantsKeys;
  sortable: boolean;
}

const headCells: HeadCells[] = [
  {
    id: "name",
    label: "Nome",
    sortable: true,
  },
  {
    id: "phone",
    label: "Telefone",
    sortable: false,
  },
  {
    id: "email",
    label: "Email",
    sortable: true,
  },
  {
    id: "cpfcnpj",
    label: "CPF / CNPJ",
    sortable: false,
  },
];

interface ClientListTableProps {
  companyParticipants: CompanyParticipants[];
  onEdit: (participant: CompanyParticipants) => void;
  role: NameOfRoles;
  isQueryError: boolean;
  companyToken: string;
}

const ClientListTable: FC<ClientListTableProps> = ({
  companyParticipants,
  onEdit,
  role,
  isQueryError,
  companyToken,
}) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<CompanyParticipantsKeys>("name");

  const sortHandler = (property: CompanyParticipantsKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer sx={{ mt: 2 }}>
      <Table size="small">
        <SortableTableHead
          headCells={headCells}
          onRequestSort={(e, property) => sortHandler(property)}
          order={order}
          orderBy={orderBy}
        >
          <TableCell align="center"></TableCell>
        </SortableTableHead>

        <TableBody>
          {companyParticipants.length === 0 && (
            <TableRow>
              <TableCell colSpan={headCells.length + 1}>
                {translateNameOfRoles[role]} não foram cadastrados
              </TableCell>
            </TableRow>
          )}
          {isQueryError && (
            <TableRow>
              <TableCell colSpan={headCells.length + 1}>
                Ocorreu um error ao buscar seus {translateNameOfRoles[role]}
              </TableCell>
            </TableRow>
          )}

          {stableSort(companyParticipants, comparatorSortByProperty(order, orderBy)).map(
            (client) => (
              <ClientItem
                key={client.id}
                client={client}
                onEdit={() => onEdit(client)}
                companyToken={companyToken}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientListTable;
