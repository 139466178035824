import { FC, useState } from "react";

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import MassAction from "../components/MassAction";
import { InvoiceStockControl } from "types/StockControl";
import InvoiceTableItemStockControl from "./InvoiceTableItemStockControl";

interface InvoiceTableStockControlProps {
  invoice: InvoiceStockControl[];
  companyToken: string;
}

const InvoiceTableStockControl: FC<InvoiceTableStockControlProps> = ({ invoice, companyToken }) => {
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string[]>([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectInvoice = (id: string) => {
    setSelectedInvoiceId((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return prev.concat(id);
    });
  };

  return (
    <>
      <MassAction invoice={invoice} selectedInvoiceId={selectedInvoiceId} />

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" className="no-print" data-exclude="true">
                <Checkbox
                  size="small"
                  color="primary"
                  indeterminate={
                    selectedInvoiceId.length > 0 && selectedInvoiceId.length < invoice.length
                  }
                  checked={invoice.length > 0 && selectedInvoiceId.length === invoice.length}
                  onChange={(_, isChecked) => {
                    setSelectedInvoiceId(isChecked ? invoice.map((i) => i.invoiceNumber) : []);
                  }}
                />
              </TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Nº</TableCell>
              <TableCell>Data emissão</TableCell>
              <TableCell>Cliente/Fornecedor</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell align="right">Valor (R$)</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell />
              <TableCell align="right" />
            </TableRow>
          </TableHead>

          <TableBody>
            {invoice.length === 0 && (
              <TableRow>
                <TableCell colSpan={10}>Nenhum NFS-e encontrado</TableCell>
              </TableRow>
            )}

            {invoice.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
              <InvoiceTableItemStockControl
                invoice={item}
                key={item.id}
                selected={selectedInvoiceId.includes(item.invoiceNumber)}
                onSelect={() => handleSelectInvoice(item.invoiceNumber)}
                companyToken={companyToken}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage={"Linhas por páginas: "}
        labelDisplayedRows={({ from, to, count }) => from + "-" + to + " de " + count}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={invoice.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default InvoiceTableStockControl;
