import { useCallback } from "react";
import { useQuery } from "react-query";
import queryKeys from "react-query/constants";

import { getProfiles } from "services/Requests/companies";
import { CompanyProfile } from "types/Company";
import getSubdomain from "utils/getSubdomain";

const useQueryCompanyProfiles = (search = "", companyToken?: string) => {
  const selectSearch = useCallback(
    (data: CompanyProfile[]) => {
      if (!search) return data;

      const searchClient = (name: string) => {
        if (!search.trim()) return true;

        return name.toLowerCase().search(search.toLowerCase()) !== -1;
      };

      return data.filter((profile) => searchClient(profile.name));
    },
    [search],
  );

  const subdomain = !companyToken ? getSubdomain() : undefined;

  const queryKey = [queryKeys.companyProfile];
  if (companyToken) queryKey.push(companyToken);
  if (subdomain) queryKey.push(subdomain);

  const query = useQuery(queryKey, () => getProfiles({ subdomain, companyToken }), {
    select: selectSearch,
  });
  return query;
};

export default useQueryCompanyProfiles;
