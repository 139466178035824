import { FC, useState } from "react";
import toast from "react-hot-toast";

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Stack,
  useMediaQuery,
} from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { LoadingButton } from "@mui/lab";

import { Group } from "types/Mailbox";
import ControllerInput from "components/ControllerInput";
import useQueryGetUsersAdm from "pages/Home/hooks/useQueryGetUsersAdm";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import useMutationCreateGroup from "../hooks/useMutationCreateGroup";
import useMutationUpdateGroup from "../hooks/useMutationUpdateGroup";

interface CreateOrUpdateGroupProps extends DialogProps {
  companyToken: string;
  group?: Group;
}

const CreateOrUpdateGroup: FC<CreateOrUpdateGroupProps> = ({
  group,
  companyToken,
  onClose,
  ...props
}) => {
  const isMobile = useMediaQuery("(max-width:450px)");

  const { data, isLoading } = useQueryGetUsersAdm();
  const { mutateAsync: mutateUpdate, isLoading: isLoadingUpdateGroup } = useMutationUpdateGroup();
  const { mutateAsync: mutateCreate, isLoading: isLoadingCreateGroup } = useMutationCreateGroup();

  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>(group?.accountants || []);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: group?.description || "",
    },
  });

  const handleClose = () => {
    if (isLoading || isLoadingUpdateGroup) return;
    onClose?.({}, "backdropClick");
  };

  const onSubmit = handleSubmit(async (data) => {
    if (!selectedUsersIds.length) {
      toast("Selecione pelo menos um usuário", {
        icon: "⚠️",
      });
      return;
    }

    if (group) {
      await mutateUpdate({
        accountants: selectedUsersIds,
        companyToken: companyToken,
        groupId: group.id,
        name: data.name,
      });
      handleClose();
    } else {
      await mutateCreate({
        accountants: selectedUsersIds,
        companyToken: companyToken,
        name: data.name,
      });
      handleClose();
    }
  });

  const addUserIdInUserSelectedUsers = (id: number) => {
    setSelectedUsersIds((prev) => [...prev, id]);
  };

  const removeUserIdFromSelectedUsers = (id: number) => {
    setSelectedUsersIds((prev) => prev.filter((prev) => prev !== id));
  };

  const handleChangeSelectedUser = (id: number) => {
    if (selectedUsersIds.includes(id)) {
      removeUserIdFromSelectedUsers(id);
    } else {
      addUserIdInUserSelectedUsers(id);
    }
  };

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="sm" fullWidth fullScreen={isMobile}>
      <DialogTitleWithCloseButton onClose={handleClose}>
        {group ? "Editar" : "Cadastrar"} grupo
      </DialogTitleWithCloseButton>

      <form onSubmit={onSubmit}>
        <DialogContent>
          {isLoading && (
            <Stack alignItems="center" justifyContent="center" height={300}>
              <CircularProgress />
            </Stack>
          )}

          {data && (
            <>
              <ControllerInput
                control={control}
                name="name"
                inputProps={{ label: "Nome do grupo", fullWidth: true }}
              />

              <FormControl component="fieldset" variant="standard" sx={{ mt: 2 }} fullWidth>
                <FormLabel component="legend">Contadores</FormLabel>
                <FormGroup sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                  {data.map((user) => (
                    <FormControlLabel
                      key={user.id}
                      control={
                        <Checkbox
                          checked={selectedUsersIds.includes(user.id)}
                          onChange={() => handleChangeSelectedUser(user.id)}
                          name={user.name}
                        />
                      }
                      label={user.name}
                    />
                  ))}
                </FormGroup>
                <FormHelperText>Selecione pelo menos um usuário</FormHelperText>
              </FormControl>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isLoadingCreateGroup || isLoadingUpdateGroup}
            variant="contained"
            type="submit"
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateOrUpdateGroup;
