import { FC, SetStateAction, Dispatch, useMemo } from "react";
import useQueryGetFinancialCategories from "pages/Home/hooks/useQueryGetFinancialCategories";

import {
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FinancialCategory } from "types/Company";

interface FinancialCategoryWithIndent extends FinancialCategory {
  indent: number;
}

interface CategoryFilterProps {
  companyToken: string;
  categoryFilter: FinancialCategory | null;
  setCategoryFilter: Dispatch<SetStateAction<FinancialCategory | null>>;
}

const CategoryFilter: FC<CategoryFilterProps> = ({
  companyToken,
  categoryFilter,
  setCategoryFilter,
}) => {
  const {
    data: financialCategories = [],
    isLoading,
    isError,
  } = useQueryGetFinancialCategories(companyToken);

  const financialCategoryWithIndent = useMemo(() => {
    const generateChildren = (
      category: FinancialCategory[],
      indent: number,
    ): FinancialCategoryWithIndent[] => {
      return category
        .map((c) => [{ ...c, indent: indent }, generateChildren(c.children, indent + 1)])
        .flat(Infinity) as FinancialCategoryWithIndent[];
    };

    return generateChildren(financialCategories, 1);
  }, [financialCategories]);

  const handleChange = (e: SelectChangeEvent<number>) => {
    const id = Number(e.target.value);

    const selectedCategory = financialCategoryWithIndent.find((c) => c.id === id);
    setCategoryFilter(selectedCategory || null);
  };

  return (
    <FormControl>
      <InputLabel>Categoria</InputLabel>
      <Select
        value={categoryFilter?.id || 0}
        onChange={handleChange}
        label="Categoria"
        MenuProps={{ sx: { maxHeight: "50vh" } }}
      >
        <MenuItem value={0}>Todos as categorias</MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um erro ao buscar as categorias</MenuItem>}

        {financialCategoryWithIndent.map((category) => (
          <MenuItem key={category.id} value={category.id} sx={{ pl: 2 * category.indent }}>
            <ListItemIcon sx={{ minWidth: 18 }}>●</ListItemIcon>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CategoryFilter;
