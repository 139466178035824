import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { postProducts } from "services/Requests/stockControl";

const useMutationPostProducts = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(postProducts, {
    onSuccess(_, { id }) {
      toast.success(`Produto ${id ? "atualizado" : "criado"} com sucesso`);
      queryClient.invalidateQueries(queryKeys.stockControlProducts);
    },
    onError(error: AxiosError<{ message: string }>) {
      if (error.response?.data.message) {
        toast.error(error.response?.data.message);
        return;
      }
      toast.error("Ocorreu um error ao tentar criar um produto");
    },
  });
  return mutation;
};

export default useMutationPostProducts;
