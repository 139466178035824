import endpoints from "../endpoints";
import api from "../api";
import { Configurations, ConfigurationsResponse } from "types/Configurations";

export interface GetConfigurationsMonthClosurePayload {
  subdomain: string;
}

export const getConfigurationsMonthClosure = async (
  payload: GetConfigurationsMonthClosurePayload,
): Promise<Configurations> => {
  const { data } = await api.post<ConfigurationsResponse>(
    endpoints.configurations.configurationMonthClosure,
    payload,
  );

  return {
    ...data,
    value: data.value === "True" ? true : false,
  };
};

export interface ToggleConfigurationsMonthClosurePayload {
  blockReleases: boolean;
  subdomain: string;
}

export const toggleConfigurationsMonthClosure = async (
  payload: ToggleConfigurationsMonthClosurePayload,
) => {
  await api.post(endpoints.configurations.changeConfigurationMonthClosure, payload);
};

export interface GetConfigurationsBlockReleasesPayload {
  subdomain: string;
}

export const getConfigurationsBlockReleases = async (
  payload: GetConfigurationsBlockReleasesPayload,
): Promise<Configurations> => {
  const { data } = await api.post<ConfigurationsResponse>(
    endpoints.configurations.getConfigurationBlockReleases,
    payload,
  );

  return {
    ...data,
    value: data.value === "True" ? true : false,
  };
};

export interface ToggleConfigurationsBlockReleasesPayload {
  blockReleases: boolean;
  subdomain: string;
}

export const toggleConfigurationsBlockReleases = async (
  payload: ToggleConfigurationsBlockReleasesPayload,
) => {
  await api.post(endpoints.configurations.blockReleases, payload);
};
