import { DateTime } from "luxon";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  paymentDate: yup
    .date()
    .required("A data de pagamento é obrigatório")
    .typeError("Digite uma data correta")
    .max(
      DateTime.local().endOf("day").toJSDate(),
      "A data de pagamento não pode ser maior que hoje",
    )
    .min(DateTime.local().minus({ year: 1 }), "A data de pagamento tem o intervalo de um ano"),
  bankId: yup
    .number()
    .required("A conta do banco é obrigatório")
    .typeError("A conta do banco é obrigatório"),
});

export default validationSchema;
