import { FC, useState } from "react";
import { useAuth } from "context/Auth";

import { Box, Button, Stack, Typography } from "@mui/material";
import Head from "components/Head";
import Add from "@mui/icons-material/Add";

import DialogNewTicket from "../../components/DialogNewTicket";
import ComunicationTable from "./ComunicationTable";

import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";

const Comunication: FC = () => {
  const [createTicketIsOpen, setCreateTicketIsOpen] = useState(false);
  const {
    user: { company },
  } = useAuth();

  return (
    <>
      <Head title={`Solicitações | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Solicitações
        </Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<Add fontSize="small" />}
          disabled={!company}
          onClick={() => {
            if (company) setCreateTicketIsOpen(true);
          }}
        >
          Nova solicitação
        </Button>
      </Stack>

      <Box boxShadow={1} mt={2}>
        {company ? <ComunicationTable companyToken={company.token} /> : MESSAGE_NO_COMPANY}
      </Box>

      {createTicketIsOpen && company && (
        <DialogNewTicket
          open={createTicketIsOpen}
          onClose={() => setCreateTicketIsOpen(false)}
          companyToken={company.token}
        />
      )}
    </>
  );
};

export default Comunication;
