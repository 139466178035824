import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getTicketById } from "services/Requests/mailbox";

const useQueryGetTicketById = (companyId: number, id?: number) => {
  const query = useQuery(
    [queryKeys.getTicketById, id],
    () =>
      getTicketById({
        id: id || 0,
        companyId,
      }),
    {
      enabled: !!id,
    },
  );
  return query;
};

export default useQueryGetTicketById;
