import { FC, useState } from "react";

import { IconButton, Stack, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { Subscription } from "types/Subscription";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import FormEditSubItems from "./FormEditSubItems";

interface SubItemsProps {
  subscription: Subscription;
}

const SubItems: FC<SubItemsProps> = ({ subscription }) => {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <Stack sx={{ mt: 1, mb: 2 }} direction="row" alignItems="center" gap={1} pr={isEdit ? 2 : 0}>
      {subscription.subitems.length !== 0 && !subscription.suspended && (
        <Stack
          width="48px"
          height="100%"
          flexShrink={0}
          alignItems="center"
          justifyContent="center"
        >
          <IconButton size="small" onClick={() => setIsEdit((prev) => !prev)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Stack>
      )}

      {!isEdit && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Descrição subitem</TableCell>
              <TableCell>Preço</TableCell>
              <TableCell>Situação</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {subscription.subitems.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>Nenhum subitem</TableCell>
              </TableRow>
            )}
            {subscription.subitems.map((subitem) => (
              <TableRow key={subitem.id} sx={{ "&:last-child td": { border: 0 } }}>
                <TableCell scope="row">{subitem.description}</TableCell>
                <TableCell>{formatCurrencyBRL(subitem.price_cents / 100)}</TableCell>
                <TableCell>{subitem.recurrent ? "Recorrente" : "Cobrança única"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {isEdit && (
        <FormEditSubItems
          subscription={subscription}
          onAfterUpdate={() => {
            setIsEdit(false);
          }}
        />
      )}
    </Stack>
  );
};

export default SubItems;
