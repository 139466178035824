import { FC, MouseEvent, useState } from "react";
import { useDrive } from "../context/Drive";

import {
  Box,
  CardActionArea,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DeleteIcon from "@mui/icons-material/Delete";
import WithLinkWrapper from "components/WithLinkWrapper";

import { CompanyDocumentDrive } from "types/Company";
import { fileIcons, visibleTypes } from "../utils/fileType";
import { getUrlDownloadDrive, getUrlPreviewDocumentDrive } from "../utils/getUrl";

interface DocumentsDriveListItemIconProps {
  documentDrive: CompanyDocumentDrive;
}

const DocumentsDriveListItemIcon: FC<DocumentsDriveListItemIconProps> = ({ documentDrive }) => {
  const {
    handleChangeDocumentToDelete,
    handleChangeDocumentToEdit,
    handleChangeDocumentToMove,
    addNewPath,
  } = useDrive();

  const FileIcon = fileIcons[documentDrive.typeNormalize] || fileIcons.default;
  const isVisible = visibleTypes.includes(documentDrive.typeNormalize);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <>
      <Box onContextMenu={handleContextMenu} height="100%">
        <WithLinkWrapper
          href={isVisible ? getUrlPreviewDocumentDrive(documentDrive.downloadToken) : undefined}
          target="_blank"
        >
          <CardActionArea
            disabled={!isVisible && documentDrive.type !== "dir"}
            sx={{ height: "100%" }}
            onClick={() => {
              if (documentDrive.type === "dir") {
                addNewPath(documentDrive);
              }
            }}
          >
            <Stack
              alignItems="center"
              gap={1}
              p={2}
              sx={{
                boxShadow: 0,
                bgcolor: "background.paper",
                borderRadius: 1,
                "&:hover": { boxShadow: 2 },
                transition: "box-shadow 300ms ease",
              }}
              height="100%"
            >
              <FileIcon color={documentDrive.userType !== "Accountant" ? "warning" : undefined} />
              <Typography
                align="center"
                sx={{ overflowWrap: "break-word", width: "100%", height: "100%" }}
              >
                {documentDrive.name}
              </Typography>
            </Stack>
          </CardActionArea>
        </WithLinkWrapper>
      </Box>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        onClick={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
        }
      >
        {documentDrive.type !== "dir" && (
          <MenuItem
            component="a"
            href={getUrlDownloadDrive(documentDrive.downloadToken)}
            target="_blank"
          >
            <ListItemIcon>
              <FileDownloadIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Download</Typography>
          </MenuItem>
        )}
        {visibleTypes.includes(documentDrive.typeNormalize) && (
          <MenuItem
            component="a"
            href={getUrlPreviewDocumentDrive(documentDrive.downloadToken)}
            target="_blank"
          >
            <ListItemIcon>
              <PreviewIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Visualizar</Typography>
          </MenuItem>
        )}
        {documentDrive.type !== "dir" && (
          <MenuItem
            disabled={documentDrive.rights !== "A"}
            onClick={() => handleChangeDocumentToEdit(documentDrive)}
          >
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Editar detalhes</Typography>
          </MenuItem>
        )}
        {documentDrive.type !== "dir" && (
          <MenuItem
            disabled={documentDrive.rights !== "A"}
            onClick={() => handleChangeDocumentToMove(documentDrive)}
          >
            <ListItemIcon>
              <DriveFileMoveIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Mover</Typography>
          </MenuItem>
        )}
        <MenuItem
          disabled={documentDrive.rights !== "A"}
          onClick={() => handleChangeDocumentToDelete(documentDrive)}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Deletar</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default DocumentsDriveListItemIcon;
