import { formattedInput } from "components/CurrencyInput";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("O nome do plano é obrigatório"),
  bankSlip: yup.boolean(),
  creditCard: yup.boolean(),
  price: yup
    .string()
    .required("O preço do plano é obrigatório")
    .test("validPrice", "Digite um preço válido", (value) => {
      if (!value) return false;
      const price = formattedInput(value);
      return price >= 0;
    }),
  useLicenses: yup
    .number()
    .typeError("Digite apenas números")
    .required("A quantidade de licenças são obrigatório")
    .integer("Digite apenas números inteiros")
    .test("positive", "Digite apenas números positivos", (value) => {
      if (!value) return true;
      return value >= 0;
    }),
  unitPrice: yup
    .string()
    .required("O preço do plano unitário é obrigatório")
    .test("validPrice", "Digite um preço válido", (value) => {
      if (!value) return false;
      const price = formattedInput(value);
      return price >= 0;
    }),
  priceDescription: yup.string().required("A descrição do preço é obrigatório"),
  isSameDayExp: yup.boolean(),
  parcel: yup
    .number()
    .transform((v) => {
      return v === "" || isNaN(v) ? null : v;
    })
    .nullable(true)
    .when("isSameDayExp", {
      is: false,
      then: yup
        .number()
        .nullable(false)
        .typeError("Digite apenas números")
        .test("invalidDay", "Digite um dia válido", (value) => {
          if (!value) return true;
          return value >= 1 && value <= 31;
        })
        .integer("Digite apenas números inteiros")
        .required("O vencimento é obrigatório"),
    }),

  paymentRecurrence: yup.array().of(
    yup.object().shape({
      periodicity: yup.number().required("O período da cobrança é obrigatório"),
      percentDiscount: yup
        .number()
        .transform((v) => {
          return v === "" || isNaN(v) ? 0 : v;
        })
        .typeError("Digite apenas números")
        .required("A porcentagem do desconto é obrigatório")
        .test(
          "validPorcentage",
          "A porcentagem não pode ser menor que zero ou maior que 99",
          (value) => {
            if (!value) return true;
            return value >= 0 && value <= 99;
          },
        ),
      recurrencePriceDescription: yup.string().required("A descrição da recorrência é obrigatório"),
    }),
  ),
  isManualBilling: yup.boolean(),
});

export default validationSchema;
