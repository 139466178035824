import { forwardRef, Ref } from "react";
import useQueryForms from "../../hooks/useQueryForms";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface FilterFormProps extends SelectProps<string | undefined> {
  isFilter?: boolean;
  helperText?: string;
}

function FilterFormInner(
  { isFilter = true, helperText, ...props }: FilterFormProps,
  ref?: Ref<unknown>,
) {
  const { data: forms, isLoading, isError } = useQueryForms();

  return (
    <FormControl error={props.error} size={props.size}>
      <InputLabel error={props.error}>{props.label}</InputLabel>
      <Select ref={ref} {...props} value={props.value || "all"}>
        <MenuItem value="all">{isFilter ? "Todos" : "Selecione o formulário"}</MenuItem>
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um erro ao carregar os formulários</MenuItem>}

        {!!forms &&
          forms.map((form) => (
            <MenuItem key={form} value={form}>
              {form}
            </MenuItem>
          ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const FilterForm = forwardRef(FilterFormInner);
export default FilterForm;
