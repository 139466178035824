import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { deleteCompany } from "services/Requests/admin";

const useMutationDeleteCompany = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteCompany, {
    onSuccess() {
      toast.success("Empresa deletada com sucesso!");
      queryClient.invalidateQueries(queryKeys.companyAssignments, {
        exact: false,
      });
    },
  });
  return mutation;
};

export default useMutationDeleteCompany;
