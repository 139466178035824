import { FC, useState } from "react";
import { useDashboardPeriod } from "../contexts/DashboardPeriod";
import useQueryPieChartData from "../hooks/useQueryPieChartData";

import {
  Box,
  CircularProgress,
  Collapse,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { GetPieChartPayload } from "services/Requests/dashboard";

import { PieChart } from "react-minimal-pie-chart";
import { TransactionType } from "types/CommonData";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

type FilterGroup = GetPieChartPayload["group"];

interface RevenuesOrExpensesProps {
  companyToken: string;
  type: TransactionType;
}

const RevenuesOrExpenses: FC<RevenuesOrExpensesProps> = ({ companyToken, type }) => {
  const [filterGroup, setFilterGroup] = useState<FilterGroup>("Customer");
  const [hovered, setHovered] = useState<null | number>(null);

  const { date } = useDashboardPeriod();
  const {
    isLoading,
    isFetching,
    data: pieChartData,
  } = useQueryPieChartData({
    companyToken,
    startDate: date.start,
    endDate: date.end,
    group: filterGroup,
    type,
  });

  const pieChartWithPercentage = pieChartData?.pieData?.map((pie) => ({
    title: pie.label,
    value: Number(pie.data.toFixed(2)),
    color: pie.color,
    percentage: Number(((pie.data / pieChartData.total) * 100).toFixed(2)),
  }));
  const selectedPie = hovered !== null ? pieChartWithPercentage?.[hovered] : null;

  return (
    <Box p={2} borderRadius={1} border={1} borderColor="divider">
      <Collapse in={isFetching && !isLoading}>
        <LinearProgress />
      </Collapse>

      <Box p={2}>
        {isLoading && (
          <Stack height={300} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {pieChartWithPercentage && pieChartWithPercentage.length > 0 && (
          <Stack
            flexWrap={"wrap-reverse"}
            direction="row"
            alignItems="start"
            gap={2}
            sx={{ opacity: isFetching && !isLoading ? 0.5 : 1, transition: "opacity 500ms ease" }}
          >
            <Tooltip
              open={!!selectedPie}
              title={
                selectedPie ? `${selectedPie.title} ${formatCurrencyBRL(selectedPie.value)}` : ""
              }
              sx={{
                bgcolor: "background.paper",
                color: "text.primary",
              }}
              TransitionProps={{
                timeout: {
                  enter: 500,
                  exit: 0,
                },
              }}
              followCursor
              arrow
            >
              <Box sx={{ backgroundColor: "transparent" }} width={200} position="relative">
                <PieChart
                  radius={50}
                  lineWidth={30}
                  animate
                  labelPosition={100 - 30 / 2}
                  labelStyle={{
                    fill: "#fff",
                    opacity: 0.75,
                    pointerEvents: "none",
                  }}
                  data={pieChartWithPercentage.map((pie) => ({
                    title: pie.title,
                    value: pie.value,
                    color: pie.color,
                  }))}
                  onMouseOver={(_, index) => {
                    setHovered(index);
                  }}
                  onMouseOut={() => {
                    setHovered(null);
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  variant="h6"
                  fontWeight={300}
                >
                  {type === "Credit" ? "Receitas" : "Despesas"}
                </Typography>
              </Box>
            </Tooltip>

            <Box flex={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                borderBottom={1}
                borderColor="divider"
                mb={2}
              >
                <RadioGroup
                  row
                  value={filterGroup}
                  onChange={(e) => setFilterGroup(e.target.value as FilterGroup)}
                >
                  <FormControlLabel
                    value="Customer"
                    control={<Radio size="small" />}
                    label={type === "Credit" ? "Cliente" : "Fornecedor"}
                  />
                  <FormControlLabel
                    value="Category"
                    control={<Radio size="small" />}
                    label="Categoria"
                  />
                </RadioGroup>
              </Stack>

              <Table size="small">
                <TableBody>
                  {pieChartWithPercentage.map((pie, index) => (
                    <TableRow key={pie.color + pie.value.toString() + index}>
                      <TableCell align="right" sx={{ border: 0 }}>
                        {pie.title}
                      </TableCell>
                      <TableCell align="right" sx={{ border: 0, whiteSpace: "nowrap" }}>
                        {formatCurrencyBRL(pie.value)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Stack>
        )}

        {pieChartWithPercentage && pieChartWithPercentage.length === 0 && (
          <Typography>Nenhuma {type === "Credit" ? "receita" : "despesa"} no período</Typography>
        )}
      </Box>
    </Box>
  );
};

export default RevenuesOrExpenses;
