import { FC } from "react";
import { Control, Controller } from "react-hook-form";

import { TextField, Typography } from "@mui/material";
import { ProductForm } from ".";

import PisCstSelect from "pages/Home/components/PisCstSelect";
import IpiCstSelect from "pages/Home/components/IpiCstSelect";

interface TaxesAndCodesFieldsProps {
  control: Control<ProductForm>;
}

const TaxesAndCodesFields: FC<TaxesAndCodesFieldsProps> = ({ control }) => {
  return (
    <>
      <Controller
        control={control}
        name="ncm"
        render={({ field, fieldState }) => (
          <TextField
            label="NCM"
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="cest"
        render={({ field, fieldState }) => (
          <TextField
            label="CEST"
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="ean"
        render={({ field, fieldState }) => (
          <TextField
            label="EAN"
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="cfop"
        render={({ field, fieldState }) => (
          <TextField
            label="CFOP"
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="taxBenefit"
        render={({ field }) => (
          <TextField
            label="Código Benefício Fiscal"
            inputProps={{
              maxLength: 8,
            }}
            {...field}
          />
        )}
      />

      <Typography
        variant="h6"
        fontWeight="400"
        sx={{ gridColumn: "1/-1", borderBottom: 1, borderBottomColor: "divider" }}
      >
        Impostos
      </Typography>

      <Controller
        control={control}
        name="pisCst"
        render={({ field, fieldState }) => (
          <PisCstSelect
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            label="PIS CST"
          />
        )}
      />

      <Controller
        control={control}
        name="pis"
        render={({ field, fieldState }) => (
          <TextField
            label="PIS (%)"
            {...field}
            type="number"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="icmsCst"
        render={({ field, fieldState }) => (
          <TextField
            label="ICMS/CSOSN CST"
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="icms"
        render={({ field, fieldState }) => (
          <TextField
            label="ICMS/CSOSN (%)"
            {...field}
            type="number"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="cofinsCst"
        render={({ field, fieldState }) => (
          <PisCstSelect
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            label="COFINS CST"
          />
        )}
      />

      <Controller
        control={control}
        name="cofins"
        render={({ field, fieldState }) => (
          <TextField
            label="COFINS (%)"
            {...field}
            type="number"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="ipiCst"
        render={({ field, fieldState }) => (
          <IpiCstSelect
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            label="IPI CST"
          />
        )}
      />

      <Controller
        control={control}
        name="ipi"
        render={({ field, fieldState }) => (
          <TextField
            label="IPI (%)"
            {...field}
            type="number"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </>
  );
};

export default TaxesAndCodesFields;
