import { FC, useState } from "react";
import useMutationChangeStateOrderOpenCompany from "pages/Home/pages/Marketplace/hooks/useMutationChangeStateOrderOpenCompany";

import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Order, OrderState } from "types/OpenCompany";
import { LoadingButton } from "@mui/lab";

import { translateOrderState } from "pages/Home/pages/Marketplace/components/Legalization/OrdersTableItem";

interface OrderStatusProps {
  order: Order;
}

const OrderStatus: FC<OrderStatusProps> = ({ order }) => {
  const { mutate, isLoading } = useMutationChangeStateOrderOpenCompany();
  const orderStates = Object.keys(translateOrderState)
    .map(Number)
    .filter((o) => o !== 4 && o !== 11) as OrderState[];

  const [selectedOrderState, setSelectedOrderState] = useState<OrderState | null>(
    orderStates.includes(order.orderState) ? order.orderState : null,
  );

  const handleChangeOrderState = () => {
    if (selectedOrderState === null) return;

    mutate({
      ...order,
      orderState: selectedOrderState,
    });
  };

  return (
    <Box>
      <Typography sx={{ borderBottom: 1, borderColor: "divider" }} variant="h6" gutterBottom>
        Status
      </Typography>

      <Box border={1} borderColor="divider" p={2}>
        <Select
          value={selectedOrderState ?? ""}
          onChange={(e) => setSelectedOrderState((Number(e.target.value) as OrderState) ?? null)}
          fullWidth
        >
          <MenuItem value="">Selecione: </MenuItem>

          {orderStates.map((orderState) => (
            <MenuItem key={orderState} value={orderState}>
              {translateOrderState[orderState]}
            </MenuItem>
          ))}
        </Select>

        <Stack mt={1} alignItems="center" justifyContent="center">
          <LoadingButton
            disabled={selectedOrderState === null}
            variant="contained"
            onClick={handleChangeOrderState}
            loading={isLoading}
          >
            Salvar
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default OrderStatus;
