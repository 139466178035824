import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import SortableTableHead from "components/SortableTableHead";
import { FC, useState } from "react";
import { UserCompany, UsersCompanies } from "types/Admin";
import { Order } from "types/SemanticTypes";
import comparatorSortByProperty from "utils/comparatorSortByProperty";
import stableSort from "utils/stableSort";
import UserTableItem from "./UserTableItem";

type UserCompanyKeys = keyof UserCompany;

interface HeadCells {
  label: string;
  id: UserCompanyKeys;
}

const headCells: HeadCells[] = [
  {
    label: "Nome",
    id: "name",
  },
  {
    label: "	Login/Email",
    id: "login",
  },
];

interface UsersTableProps {
  companyToken: string;
  usersCompanies: UsersCompanies;
}

const UsersTable: FC<UsersTableProps> = ({ usersCompanies, companyToken }) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<UserCompanyKeys>("name");

  const sortHandler = (property: UserCompanyKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table size="small">
        <SortableTableHead
          headCells={headCells}
          onRequestSort={(e, property) => sortHandler(property)}
          order={order}
          orderBy={orderBy}
        >
          <TableCell align="center"></TableCell>
        </SortableTableHead>

        <TableBody>
          {usersCompanies.length === 0 && (
            <TableRow>
              <TableCell colSpan={headCells.length + 1}>
                <Typography variant="body1">Nenhum resultado encontrado</Typography>
              </TableCell>
            </TableRow>
          )}

          {stableSort(usersCompanies, comparatorSortByProperty(order, orderBy)).map((user) => (
            <UserTableItem key={user.id} companyToken={companyToken} userCompany={user} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
