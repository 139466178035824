import { Layout } from "types/Template";

export const defaultLayoutItems: Layout[] = [
  {
    type: "item",
    id: 0,
    name: "Identificador",
    columname: "Identificador",
    parentId: 0,
    format: "",
    length: "",
    direction: "",
    complete: "",
  },
  {
    type: "item",
    id: 1,
    name: "Data de Vencimento",
    columname: "Data de Vencimento",
    parentId: 0,
    format: "dd/MM/yyyy",
    length: "",
    direction: "",
    complete: "",
  },
  {
    type: "item",
    id: 2,
    name: "Data de Pagamento/Recebimento",
    columname: "Data de Pagamento/Recebimento",
    parentId: 0,
    format: "dd/MM/yyyy",
    length: "",
    direction: "",
    complete: "",
  },
  {
    type: "item",
    id: 3,
    name: "Conta Débito",
    columname: "Conta Débito",
    parentId: 0,
    format: "",
    length: "",
    direction: "",
    complete: "",
  },
  {
    type: "item",
    id: 4,
    name: "Conta Crédito",
    columname: "Conta Crédito",
    parentId: 0,
    format: "",
    length: "",
    direction: "",
    complete: "",
  },
  {
    type: "item",
    id: 5,
    name: "Conta Débito (Reduzida)",
    columname: "Conta Débito (Reduzida)",
    parentId: 0,
    format: "",
    length: "",
    direction: "",
    complete: "",
  },
  {
    type: "item",
    id: 6,
    name: "Conta Crédito (Reduzida)",
    columname: "Conta Crédito (Reduzida)",
    parentId: 0,
    format: "",
    length: "",
    direction: "",
    complete: "",
  },
  {
    type: "item",
    id: 7,
    name: "Valor",
    columname: "Valor",
    parentId: 0,
    format: "0,00",
    length: "",
    direction: "",
    complete: "",
  },
  {
    type: "item",
    id: 8,
    name: "Histórico/Descrição",
    columname: "Histórico/Descrição",
    parentId: 0,
    format: "",
    length: "",
    direction: "",
    complete: "",
  },
  {
    type: "item",
    id: 9,
    name: "Fixo",
    columname: "Fixo",
    parentId: 0,
    format: "",
    length: "",
    direction: "",
    complete: "",
  },
  {
    type: "item",
    id: 10,
    name: "Sequencial",
    columname: "Sequencial",
    parentId: 0,
    format: "",
    length: "",
    direction: "",
    complete: "",
  },
];
