import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCompanyGlobalAccountanting } from "services/Requests/accountant";

const useQueryCompanyGlobalAccountanting = (companyToken: string) => {
  const query = useQuery([queryKeys.companyGlobalAccountanting, companyToken], () =>
    getCompanyGlobalAccountanting({ companyToken }),
  );
  return query;
};

export default useQueryCompanyGlobalAccountanting;
