import { FC, useState } from "react";
import { useAuth } from "context/Auth";

import { Box, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

import ManagerialIncomeStatementTable from "./ManagerialIncomeStatementTable";

import { MESSAGE_NO_COMPANY } from "utils/constants";

import { DateTime } from "luxon";

const defaultYears = [0, 1, 2, 3, 4].map((mult) => DateTime.local().minus({ years: mult }).year);

const ManagerialIncomeStatementSumary: FC = () => {
  const [year, setYear] = useState(defaultYears[0]);

  const {
    user: { company },
  } = useAuth();

  return (
    <Box>
      {company ? (
        <>
          <FormControl>
            <InputLabel>Ano</InputLabel>
            <Select value={year} label="Ano" onChange={(e) => setYear(Number(e.target.value))}>
              {defaultYears.map((year) => (
                <MenuItem value={year} key={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Divider sx={{ my: 3 }} variant="middle" />

          <ManagerialIncomeStatementTable />
        </>
      ) : (
        <Typography>{MESSAGE_NO_COMPANY}</Typography>
      )}
    </Box>
  );
};

export default ManagerialIncomeStatementSumary;
