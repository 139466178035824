import { FC, useState } from "react";
import useSignIn from "./hooks/useSignIn";
import { useCompanyInfo } from "../../../context/CompanyInfo";
import { useNavigate } from "react-router-dom";

import { Box, Checkbox, FormControlLabel, Link, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Head from "../../../components/Head";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationScheme";
import getSubdomain from "../../../utils/getSubdomain";
import AuthWrapper from "../AuthWrapper";

const Login: FC = () => {
  const { typeSubdomain } = useCompanyInfo();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  const { mutate, isLoading } = useSignIn();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onTouched",
  });

  const onSubmit = handleSubmit((data) => {
    mutate({
      email: data.email,
      password: data.password,
      rememberMe,
    });
  });

  return (
    <AuthWrapper>
      <Head title={`Entrar | ${getSubdomain()}`} />

      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        Entrar
      </Typography>
      {typeSubdomain === "accountant" && (
        <Typography variant="body1">
          Não tem conta?{" "}
          <Link
            sx={{ cursor: "pointer" }}
            href="/cadastro"
            onClick={(e) => {
              e.preventDefault();
              navigate("/cadastro");
            }}
          >
            Cadastre-se
          </Link>
        </Typography>
      )}

      <Box component="form" mt={4} onSubmit={onSubmit}>
        <Stack gap={2} width="100%">
          <Controller
            name="email"
            control={control}
            render={({ fieldState, field }) => (
              <TextField
                fullWidth
                label="Email ou nome de usuário"
                variant="outlined"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ fieldState, field }) => (
              <TextField
                fullWidth
                label="Senha"
                variant="outlined"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                type="password"
              />
            )}
          />
        </Stack>

        <Stack my={3} direction="row" justifyContent="space-between" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
            }
            label="Lembrar de mim"
          />

          {typeSubdomain === "accountant" && (
            <Link
              sx={{ cursor: "pointer" }}
              href="/redefinir-senha"
              onClick={(e) => {
                e.preventDefault();
                navigate("/redefinir-senha");
              }}
            >
              Esqueceu a senha?
            </Link>
          )}
        </Stack>

        <LoadingButton
          loading={isLoading}
          variant="contained"
          fullWidth
          disableElevation
          type="submit"
        >
          Entrar
        </LoadingButton>
      </Box>
    </AuthWrapper>
  );
};

export default Login;
