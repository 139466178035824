import { useCallback } from "react";
import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getMovements } from "services/Requests/stockControl";
import { ISODate } from "types/SemanticTypes";
import { Movement, MovementOperationType, Movements } from "types/StockControl";

const useQueryMovements = (
  companyToken: string,
  startDate: ISODate,
  endDate: ISODate,
  operationType: MovementOperationType | null = null,
  search = "",
) => {
  const select = useCallback(
    (data: Movements) => {
      const filterOperationType = (movement: Movement) => {
        if (!operationType) return true;
        return movement.operationType === operationType;
      };
      const searchMovement = (name: string) => {
        if (!search.trim()) return true;

        return name.toLowerCase().search(search.toLowerCase()) !== -1;
      };

      return data.filter(
        (movement) =>
          filterOperationType(movement) &&
          (searchMovement(movement.productName) ||
            searchMovement(movement.participantName || "") ||
            searchMovement(movement.productCode)),
      );
    },
    [operationType, search],
  );

  const query = useQuery(
    [queryKeys.stockControlMovements, startDate, endDate],
    () => getMovements({ companyToken, endDate, startDate }),
    {
      select,
    },
  );
  return query;
};

export default useQueryMovements;
