import { FC } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";

import ControllerInput from "components/ControllerInput";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { PlanForm } from ".";

import CurrencyInput from "components/CurrencyInput";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

const zeroPrice = formatCurrencyBRL(0);

interface TabContentProps {
  control: Control<PlanForm>;
  index: number;
}

const TabContent: FC<TabContentProps> = ({ control, index }) => {
  const planField = useFieldArray({
    control,
    name: `tierPrice.${index}.calcIntervals`,
  });

  const handleAddField = () => {
    planField.append({
      from: zeroPrice,
      until: zeroPrice,
      price: zeroPrice,
    });
  };

  return (
    <Stack gap={2}>
      <Controller
        control={control}
        name={`tierPrice.${index}.active`}
        render={({ field }) => (
          <FormControlLabel
            sx={{ m: 0, height: "auto" }}
            control={
              <Checkbox
                onBlur={field.onBlur}
                onChange={field.onChange}
                checked={!!field.value}
                value={!!field.value}
                inputRef={field.ref}
              />
            }
            label="Ativado"
          />
        )}
      />

      <ControllerInput
        control={control}
        name={`tierPrice.${index}.name`}
        inputProps={{ label: "Nome" }}
      />

      <Typography
        variant="h5"
        sx={{ borderBottom: 1, borderBottomColor: "divider", fontWeight: 600, mb: 1, mt: 2 }}
      >
        Planos de contabilidade
      </Typography>

      <Stack gap={2}>
        {planField.fields.map((field, indexField) => (
          <Stack key={field.id} direction="row" gap={1} sx={{ "& > div": { flex: 1 } }}>
            <Controller
              name={`tierPrice.${index}.calcIntervals.${indexField}.from`}
              control={control}
              render={({ field, fieldState }) => (
                <CurrencyInput
                  {...field}
                  inputProps={{
                    size: "small",
                    label: "De",
                    error: !!fieldState.error?.message,
                    helperText: fieldState.error?.message,
                  }}
                />
              )}
            />

            <Controller
              name={`tierPrice.${index}.calcIntervals.${indexField}.until`}
              control={control}
              render={({ field, fieldState }) => (
                <CurrencyInput
                  {...field}
                  inputProps={{
                    size: "small",
                    label: "Até",
                    error: !!fieldState.error?.message,
                    helperText: fieldState.error?.message,
                  }}
                />
              )}
            />

            <Controller
              name={`tierPrice.${index}.calcIntervals.${indexField}.price`}
              control={control}
              render={({ field, fieldState }) => (
                <CurrencyInput
                  {...field}
                  inputProps={{
                    size: "small",
                    label: "Valor",
                    error: !!fieldState.error?.message,
                    helperText: fieldState.error?.message,
                  }}
                />
              )}
            />

            <Tooltip title="Remover faixa">
              <Box flexShrink={0} component="aside">
                <IconButton
                  sx={{ flexShrink: 0 }}
                  disabled={planField.fields.length === 1}
                  onClick={() => {
                    planField.remove(indexField);
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Tooltip>
          </Stack>
        ))}
        <Button variant="text" startIcon={<Add />} onClick={handleAddField}>
          Inserir nova faixa de cobrança
        </Button>
      </Stack>
    </Stack>
  );
};

export default TabContent;
