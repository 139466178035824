import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { createFolderDrive } from "services/Requests/companies";

const useMutationCreateFolderDrive = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(createFolderDrive, {
    onSuccess() {
      toast.success("Diretório criado com sucesso");
      queryClient.invalidateQueries(queryKeys.companyDocumentsDrive, { exact: false });
    },
  });
  return mutation;
};

export default useMutationCreateFolderDrive;
