import { FC, useEffect } from "react";
import { useAuth } from "context/Auth";
import { useNavigate } from "react-router-dom";
import useNavigateHomePage from "hooks/useNavigateHomePage";

import {
  Breadcrumbs,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import UploadIcon from "@mui/icons-material/Upload";
import HelpIcon from "@mui/icons-material/Help";

import getSubdomain from "utils/getSubdomain";
import Head from "components/Head";
import Control from "./Control";

const IntegratedImport: FC = () => {
  const {
    user: { company },
  } = useAuth();
  const navigatePrimaryPath = useNavigateHomePage();
  const navigate = useNavigate();

  useEffect(() => {
    if (!company?.hasAppBankingIntegrator) {
      navigatePrimaryPath();
    }
  }, [company]);

  return (
    <>
      <Head title={`Importação automática | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Importação automática
          <Tooltip
            placement="right"
            title={
              <>
                <Typography color="background.paper">
                  Siga estes passos para importar o seu extrato bancário:
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{ color: "background.paper" }}
                      primary="- Abra uma nova janela e entre no site do seu banco."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{ color: "background.paper" }}
                      primary="- Exporte seu extrato bancário para o formato .OFX."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{ color: "background.paper" }}
                      primary="- Selecione o arquivo exportado e a conta bancária nos campos ao lado."
                    />
                  </ListItem>
                </List>
              </>
            }
          >
            <HelpIcon sx={{ ml: 0.5 }} fontSize="medium" color="primary" />
          </Tooltip>
        </Typography>

        <Breadcrumbs>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigatePrimaryPath();
            }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/lancamentos"
            onClick={(e) => {
              e.preventDefault();
              navigate("/lancamentos");
            }}
          >
            <AttachMoneyIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Lançamentos
          </Link>
          <Typography sx={{ display: "flex", alignItems: "center" }} color="text.primary">
            <UploadIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Importação
          </Typography>
        </Breadcrumbs>
      </Stack>

      {!!company?.token && <Control companyToken={company.token} />}
    </>
  );
};

export default IntegratedImport;
