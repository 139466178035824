import { FC, useState } from "react";
import { useAuth } from "context/Auth";

import { Box, Button, Stack, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";

import Head from "components/Head";
import DialogCreatePartner from "./DialogCreateUpdatePartner";
import PartnersList from "./PartnersList";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import getSubdomain from "utils/getSubdomain";

const PartnersDetails: FC = () => {
  const {
    user: { company },
  } = useAuth();

  const [createPartnerIsOpen, setCreatePartnerIsOpen] = useState(false);

  return (
    <>
      <Head title={`Sócios | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Sócios
        </Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<Add fontSize="small" />}
          disabled={!company}
          onClick={() => setCreatePartnerIsOpen(true)}
        >
          Novo sócio
        </Button>
      </Stack>

      <Box boxShadow={1} p={2} mt={2}>
        {company ? (
          <>
            <PartnersList companyToken={company.token} />
            <DialogCreatePartner
              open={createPartnerIsOpen}
              onClose={() => setCreatePartnerIsOpen(false)}
              companyToken={company.token}
            />
          </>
        ) : (
          <Typography variant="body2">{MESSAGE_NO_COMPANY}</Typography>
        )}
      </Box>
    </>
  );
};

export default PartnersDetails;
