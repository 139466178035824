import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getClientsConfigLeads, GetClientsConfigLeadsPayload } from "services/Requests/marketplace";

const useQueryClientsConfigLeads = ({ form, name }: GetClientsConfigLeadsPayload) => {
  const queryKey: (string | number)[] = [queryKeys.clientsConfigLeads, form];
  if (name) queryKey.push(name);

  const query = useQuery(queryKey, () => getClientsConfigLeads({ form, name }));
  return query;
};

export default useQueryClientsConfigLeads;
