import { FC, useMemo, useState } from "react";
import useQueryAllNotifications from "./hooks/useQueryAllNotifications";

import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import SearchInput from "components/SearchInput";
import FilterPeriod from "components/FilterPeriod";
import AllNotifications from "pages/Home/components/AllNotifications";

interface ContentProps {
  companyToken: string;
}

const Content: FC<ContentProps> = ({ companyToken }) => {
  const [date, setDate] = useState<{ start?: null | string; end?: null | string }>({
    start: null,
    end: null,
  });
  const [type, setType] = useState<undefined | string>(undefined);
  const [search, setSearch] = useState<string>("");

  const { data: allNotifications, isLoading } = useQueryAllNotifications(companyToken, true, {
    date,
    type,
    search,
  });
  const typesNotifications = useMemo(
    () => (allNotifications ? [...new Set(allNotifications.map((n) => n.description))] : []),
    [allNotifications],
  );

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        gap={2}
        bgcolor="#fff"
        mb={2}
        borderTop={2}
        borderColor="primary.main"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <FilterPeriod
            erasable
            startDate={date.start || undefined}
            endDate={date.end || undefined}
            onChangeDates={(newDates) => setDate(newDates)}
            label="Data"
          />

          <FormControl>
            <InputLabel>Tipo</InputLabel>
            <Select
              size="small"
              value={type || "all"}
              label="Tipo"
              onChange={(e) => {
                setType(e.target.value === "all" ? undefined : e.target.value);
              }}
            >
              <MenuItem value="all">Todos</MenuItem>
              {typesNotifications.map((type) => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <SearchInput
          value={search}
          onChange={(newSearch) => setSearch(newSearch)}
          size="small"
          label="Buscar notificações"
        />
      </Stack>

      {isLoading && (
        <Stack
          alignItems="center"
          justifyContent="center"
          height={300}
          boxShadow={2}
          bgcolor="#fff"
        >
          <CircularProgress />
        </Stack>
      )}

      {allNotifications && allNotifications.length > 0 && (
        <AllNotifications data={allNotifications} />
      )}

      {allNotifications && allNotifications.length === 0 && (
        <Typography>Nenhuma notificaçao</Typography>
      )}
    </>
  );
};

export default Content;
