import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { renameAndMoveDocumentDrive } from "services/Requests/companies";

const useMutationEditFileDrive = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(renameAndMoveDocumentDrive, {
    onSuccess() {
      toast.success("Arquivo atualizado com sucesso");
      queryClient.invalidateQueries(queryKeys.companyDocumentsDrive, { exact: false });
    },
  });
  return mutation;
};

export default useMutationEditFileDrive;
