import { FC, useMemo, useState } from "react";
import { DefaultValues, useForm, Controller } from "react-hook-form";
import useMutationEditFileDrive from "../../hooks/useMutationEditFileDrive";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

import CurrencyInput, { formattedInput } from "components/CurrencyInput";
import DialogSelectFolder from "../DialogSelectFolder";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

import { CompanyDocumentDrive } from "types/Company";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { Paths, pathToDisplay, SEPARATOR_TO_SERVICE } from "../../utils/path";
import { DateTime } from "luxon";

const minDate = DateTime.local(2000);
const maxDate = DateTime.now().plus({ years: 3 }).endOf("year");

interface EditFileForm {
  fileName: string;
  referenceDate: Date;
  dueDate: Date | null;
  value: string;
  destinationFullPath: Paths;
}

interface DialogEditFileProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  onMove?: (paths: Paths) => void;
  companyToken: string;
  companyDocumentDrive: CompanyDocumentDrive;
  isMove: boolean;
  fullPath: Paths;
}

const DialogEditFile: FC<DialogEditFileProps> = ({
  onClose,
  companyDocumentDrive,
  companyToken,
  isMove,
  onMove,
  fullPath,
  ...props
}) => {
  const { mutateAsync } = useMutationEditFileDrive();

  const [dialogSelectFolderIsOpen, setDialogSelectFolderIsOpen] = useState(false);

  const defaultValues = useMemo<DefaultValues<EditFileForm>>(() => {
    const formatDate = (date: string, monthBefore = false): Date =>
      DateTime.fromFormat(date.split(" ")[0], monthBefore ? "MM/dd/yyyy" : "dd/MM/yyyy").toJSDate();
    const formatValue = (value: string) => formatCurrencyBRL(Number(value.replace(",", ".")));

    return {
      fileName: companyDocumentDrive.name,
      referenceDate: companyDocumentDrive.referenceDate
        ? formatDate(companyDocumentDrive.referenceDate, true)
        : DateTime.local().toJSDate(),
      dueDate: companyDocumentDrive.dueDate ? formatDate(companyDocumentDrive.dueDate) : null,
      value: companyDocumentDrive.value ? formatValue(companyDocumentDrive.value) : undefined,
      destinationFullPath: fullPath,
    };
  }, [companyDocumentDrive]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<EditFileForm>({ defaultValues, resolver: yupResolver(validationSchema) });
  const destinationFullPath = watch("destinationFullPath");
  const fileName = watch("fileName");

  const handleClose = () => {
    if (isSubmitting) return;
    reset(defaultValues);
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    const fullPathToService: Paths = [
      ...fullPath,
      { id: companyDocumentDrive.id, name: companyDocumentDrive.name },
    ];

    await mutateAsync({
      destinationFileName: data.fileName,
      destinationFullPath:
        data.destinationFullPath.length === 0
          ? ""
          : pathToDisplay(data.destinationFullPath, SEPARATOR_TO_SERVICE),
      documentToken: companyDocumentDrive.downloadToken,
      referenceDate: DateTime.fromJSDate(data.referenceDate).toFormat("01/MM/yyyy"),
      dueDate: data.dueDate ? DateTime.fromJSDate(data.dueDate).toFormat("MM/dd/yyyy") : undefined,
      fullPath: pathToDisplay(fullPathToService, SEPARATOR_TO_SERVICE),
      value: data.value ? formattedInput(data.value) : undefined,
      parentId:
        data.destinationFullPath.length === 0
          ? undefined
          : data.destinationFullPath[data.destinationFullPath.length - 1].id,
    });

    if (isMove && onMove) {
      onMove(data.destinationFullPath);
    }
    handleClose();
  });

  return (
    <>
      <Dialog onClose={handleClose} {...props} maxWidth="md" fullWidth>
        <DialogTitleWithCloseButton onClose={handleClose}>
          Editar detalhes
        </DialogTitleWithCloseButton>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSubmit(e);
          }}
        >
          <DialogContent>
            <Controller
              control={control}
              name="fileName"
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  label="Nome do arquivo:"
                  {...field}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            {isMove && (
              <Box p={2} border={1} borderTop={0} borderColor="info.main">
                <Typography variant="body1" color="success.main">
                  <Typography variant="inherit" component="strong" fontWeight={600}>
                    Origem:
                  </Typography>{" "}
                  {pathToDisplay(fullPath) +
                    (fullPath.length === 0 ? "" : "/") +
                    companyDocumentDrive.name}
                </Typography>
                <Typography variant="body1" color="success.main" display="inline-block">
                  <Typography variant="inherit" component="strong" fontWeight={600}>
                    Destino:
                  </Typography>{" "}
                  {pathToDisplay(destinationFullPath) +
                    (destinationFullPath.length === 0 ? "" : "/") +
                    fileName}
                </Typography>

                <Button
                  onClick={() => setDialogSelectFolderIsOpen(true)}
                  variant="contained"
                  size="small"
                  sx={{ ml: 2, display: "inline-block" }}
                >
                  Alterar
                </Button>
              </Box>
            )}

            <Stack direction="row" gap={1} mt={2} flexWrap="wrap">
              <Controller
                name="referenceDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DatePicker
                    label="Competência"
                    inputFormat="MM/yyyy"
                    views={["month", "year"]}
                    minDate={minDate}
                    maxDate={maxDate}
                    {...field}
                    allowSameDateSelection
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ flex: "1 220px" }}
                        helperText={fieldState.error?.message}
                        error={params.error || !!fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="dueDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DatePicker
                    label="Vencimento"
                    inputFormat="dd/MM/yyyy"
                    minDate={minDate}
                    maxDate={maxDate}
                    {...field}
                    allowSameDateSelection
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ flex: 1 }}
                        helperText={fieldState.error?.message}
                        error={params.error || !!fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name="value"
                render={({ field, fieldState }) => (
                  <CurrencyInput
                    {...field}
                    inputProps={{
                      sx: { flex: 1 },
                      label: "Valor",
                      error: !!fieldState.error?.message,
                      helperText: fieldState.error?.message,
                    }}
                  />
                )}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <LoadingButton loading={isSubmitting} type="submit" variant="contained">
              Upload
            </LoadingButton>
            <Button variant="outlined" disabled={isSubmitting} onClick={handleClose}>
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <DialogSelectFolder
        open={dialogSelectFolderIsOpen}
        onClose={() => setDialogSelectFolderIsOpen(false)}
        initialPaths={fullPath}
        companyToken={companyToken}
        onSelectPath={(newPaths) => {
          setValue("destinationFullPath", newPaths);
          setDialogSelectFolderIsOpen(false);
        }}
      />
    </>
  );
};

export default DialogEditFile;
