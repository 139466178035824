import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { updateCompanyFromTo } from "services/Requests/accountant";

const useMutationUpdateCompanyFromTo = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCompanyFromTo, {
    onSuccess(_, { companyToken }) {
      toast.success("Conta contábil atualizada com sucesso!");
      queryClient.invalidateQueries([queryKeys.companyChartOfAccountsFromTo, companyToken]);
      queryClient.invalidateQueries([queryKeys.chartOfAccountsFromToGroup, companyToken], {
        exact: false,
      });
    },
  });
  return mutation;
};

export default useMutationUpdateCompanyFromTo;
