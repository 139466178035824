import { FC, useState } from "react";

import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import SortableTableHead from "components/SortableTableHead";
import OrdersTableItem from "./OrdersTableItem";

import { Orders, Order } from "types/OpenCompany";
import { Order as FilterOrder } from "types/SemanticTypes";

import comparatorSortByProperty from "utils/comparatorSortByProperty";
import stableSort from "utils/stableSort";

type OrderKeys = keyof Order;

interface HeadCells {
  label: string;
  id: OrderKeys;
}

const headCells: HeadCells[] = [
  {
    id: "createdOn",
    label: "Data do Pedido",
  },
  {
    id: "typeService",
    label: "Serviço",
  },
  {
    id: "companyName",
    label: "Empresa",
  },
  {
    id: "companyType",
    label: "Tipo",
  },
  {
    id: "orderState",
    label: "Status",
  },
];

interface OrdersTableProps {
  orders: Orders;
}

const OrdersTable: FC<OrdersTableProps> = ({ orders }) => {
  const [order, setOrder] = useState<FilterOrder>("asc");
  const [orderBy, setOrderBy] = useState<OrderKeys>("createdOn");

  const sortHandler = (property: OrderKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer sx={{ mt: 2 }}>
      <Table size="small">
        <SortableTableHead
          headCells={headCells}
          onRequestSort={(e, property) => sortHandler(property)}
          order={order}
          orderBy={orderBy}
        >
          <TableCell></TableCell>
          <TableCell></TableCell>
        </SortableTableHead>

        <TableBody>
          {orders.length === 0 && (
            <TableRow>
              <TableCell colSpan={headCells.length + 2}>Nenhum pedido encontrado</TableCell>
            </TableRow>
          )}

          {stableSort(orders, comparatorSortByProperty(order, orderBy)).map((order) => (
            <OrdersTableItem key={order.id} order={order} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersTable;
