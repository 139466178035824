import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getDefaultAccountabilityPlan } from "services/Requests/plan";

const useQueryGetDefaultAccountabilityPlan = (companyToken: string, planId?: number) => {
  if (!planId) return;

  const query = useQuery([queryKeys.defaultAccountabilityPlan, planId], () =>
    getDefaultAccountabilityPlan({
      CompanyToken: companyToken,
      planId,
    }),
  );
  return query;
};

export default useQueryGetDefaultAccountabilityPlan;
