import { Box, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { InvoiceForm } from ".";

interface AccessProps {
  control: Control<InvoiceForm>;
  requestLoginPass: boolean;
  requestToken: boolean;
  requestPhrasePass: boolean;
  specialMessage: boolean;
}

const Access: FC<AccessProps> = ({
  control,
  requestLoginPass,
  requestPhrasePass,
  requestToken,
  specialMessage,
}) => {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={600}
        borderBottom={1}
        borderColor="divider"
        sx={{ mb: 2 }}
      >
        Acesso na prefeitura
      </Typography>

      <Box display="grid" mt={2} gridTemplateColumns="1fr 1fr" gap={2}>
        {requestLoginPass && (
          <>
            <Controller
              control={control}
              name="prefectureLogin"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error?.message}
                  label="Usuário"
                  autoComplete="off"
                  inputProps={{
                    autoComplete: "off",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="prefecturePass"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error?.message}
                  label="Senha"
                  type="password"
                  autoComplete="new-password"
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </>
        )}

        {requestToken && (
          <Controller
            control={control}
            name="prefectureToken"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                helperText={fieldState.error?.message}
                error={!!fieldState.error?.message}
                label="Chave de acesso ao serviço de integração"
              />
            )}
          />
        )}

        {requestPhrasePass && (
          <>
            <Controller
              control={control}
              name="prefecturePhrase"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error?.message}
                  label="Frase secreta"
                />
              )}
            />

            <Controller
              control={control}
              name="prefecturePass"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error?.message}
                  label="Senha"
                  type="password"
                  autoComplete="new-password"
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </>
        )}
      </Box>

      {(requestLoginPass || requestToken || requestPhrasePass) && (
        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
          <strong>Observação:</strong> essas informações não serão armazenadas em nosso servidor.{" "}
          {specialMessage && (
            <strong>
              É possível escolher entre informar o Certificado Digital ou o Usuário/Senha de acesso
              a prefeitura.
            </strong>
          )}
        </Typography>
      )}
    </Box>
  );
};

export default Access;
