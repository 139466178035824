import { FC } from "react";

import { Box, Typography } from "@mui/material";

import RequestControl from "./RequestControl";
import Head from "components/Head";
import getSubdomain from "utils/getSubdomain";

const Requests: FC = () => {
  return (
    <>
      <Head title={`Solicitações | ${getSubdomain()}`} />

      <Typography variant="h4" fontWeight="bold">
        Solicitações
      </Typography>

      <Box boxShadow={1} p={2} mt={2}>
        <RequestControl />
      </Box>
    </>
  );
};

export default Requests;
