import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { NameSerieRevenuesVsExpenses, RevenuesVsExpenses } from "types/Dashboard";

interface DataChart {
  label: string;
  data: {
    primary: string | number | Date | null;
    secondary: number | null;
    radius?: number | undefined;
  }[];
}

const useChartData = (revenuesVsExpenses: RevenuesVsExpenses) => {
  const [filterChart, setFiterChart] = useState<NameSerieRevenuesVsExpenses[]>([
    "Receitas X Despesas",
  ]);

  const handleToggleFilterSerie = (serie: NameSerieRevenuesVsExpenses) => {
    if (filterChart.length === 2 && !filterChart.includes(serie)) {
      return;
    }

    if (filterChart.includes(serie)) {
      setFiterChart((prev) => prev.filter((p) => p !== serie));
    } else {
      setFiterChart((prev) => [...prev, serie]);
    }
  };

  const filterSeries = useMemo(() => {
    return revenuesVsExpenses.series.filter((serie) => !filterChart.includes(serie.name));
  }, [filterChart, revenuesVsExpenses]);

  const colors = useMemo<string[]>(() => {
    return filterSeries.map((serie) => serie.color);
  }, [filterSeries]);

  const data = useMemo<DataChart[]>(() => {
    const categories = revenuesVsExpenses.categories;

    return filterSeries.map((serie) => ({
      label: serie.name,
      data: serie.data.map((d, index) => ({
        primary: categories[index],
        secondary: d,
      })),
    }));
  }, [filterSeries, revenuesVsExpenses]);

  return { colors, data, handleToggleFilterSerie, filterChart };
};

export default useChartData;
