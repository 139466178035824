import { FC, useRef } from "react";

import { Box, SxProps, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";

import SyntheticCategoryUI from "./SyntheticCategoryUI";
import { FinancialCategoryByGroupWithChildren } from "types/Company";

import { useDrag, useDrop, XYCoord } from "react-dnd";
import { CONTAINER } from "../utils/DragDropConstants";
import type { Identifier } from "dnd-core";
import { useTemplate } from "../context/Template/useTemplate";
import { Types } from "../context/Template";

interface DragContainer {
  id: number;
  indexColumn: number;
}

interface SyntheticCategoryProps {
  financialCategory: FinancialCategoryByGroupWithChildren;
  indexColumn: number;
}

const SyntheticCategory: FC<SyntheticCategoryProps> = ({ financialCategory, indexColumn }) => {
  const { dispatch, onEndDragContainer } = useTemplate();
  const columnRef = useRef<HTMLDivElement | null>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: CONTAINER,
    item: () => ({
      id: financialCategory.id,
      indexColumn,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging: (monitor) => monitor.getItem().id === financialCategory.id,
    end: onEndDragContainer,
  });

  const [{ handlerId }, drop] = useDrop<DragContainer, void, { handlerId: Identifier | null }>({
    accept: CONTAINER,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover(item, monitor) {
      if (!columnRef.current) return;

      const dragIndex = item.indexColumn;
      const hoverIndex = indexColumn;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = columnRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset() as XYCoord;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      dispatch({
        type: Types.Move_Column,
        payload: {
          indexFrom: dragIndex,
          indexTo: hoverIndex,
        },
      });

      item.indexColumn = hoverIndex;
    },
  });

  drop(preview(columnRef));

  const styleDragging: SxProps<Theme> = {
    pointerEvents: "none",
    cursor: "grabbing",
    borderStyle: "dashed",
    borderwidth: "4px",
    borderColor: "divider",
    background: "transparent",

    "& > *": {
      opacity: 0,
    },
  };

  return (
    <>
      <Box
        ref={columnRef}
        border={1}
        borderColor={grey[200]}
        boxShadow={0.5}
        data-handler-id={handlerId}
        sx={isDragging ? styleDragging : undefined}
      >
        <SyntheticCategoryUI
          financialCategory={financialCategory}
          handleProps={{
            ref: drag,
          }}
          indexColumn={indexColumn}
        />
      </Box>
    </>
  );
};

export default SyntheticCategory;
