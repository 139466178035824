import { FC } from "react";

import { Box, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { cnpjMask, cpfMask, telephoneMask, telephoneMaskWithFiveDigit } from "utils/masks";
import { PartnerForm } from ".";

import { Control, Controller } from "react-hook-form";
import ControllerInput from "components/ControllerInput";
import MaskedInput from "react-text-mask";

interface BasicDataProps {
  control: Control<PartnerForm>;
}

const BasicData: FC<BasicDataProps> = ({ control }) => {
  const maskBuilderCPFCNPJ = (v: string) => {
    return v.length <= 14 ? [...cpfMask, /\d/g] : cnpjMask;
  };

  const maskBuilderTelephone = (v: string) => {
    return v.length <= 14 ? [...telephoneMask, /\d/g] : telephoneMaskWithFiveDigit;
  };

  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight={400}
        sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 2 }}
      >
        Dados Básicos
      </Typography>

      <Stack gap={2}>
        <Controller
          name={"cpfcnpj"}
          control={control}
          render={({ fieldState, field }) => (
            <MaskedInput
              mask={maskBuilderCPFCNPJ(field.value)}
              guide={false}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              render={(ref, props) => (
                <TextField
                  fullWidth
                  label="CPF / CNPJ (obrigatório)"
                  variant="outlined"
                  inputRef={ref}
                  {...props}
                  sx={{ flex: 1 }}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            ></MaskedInput>
          )}
        />

        <ControllerInput
          control={control}
          name="name"
          inputProps={{ label: "Nome (obrigatório)" }}
        />

        <ControllerInput
          control={control}
          name={"share"}
          inputProps={{
            label: "Participação",
            type: "number",
            sx: { width: 150 },
            InputProps: {
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            },
          }}
        />

        <Stack gap={2} direction="row">
          <ControllerInput
            control={control}
            name={"email"}
            inputProps={{
              label: "Email",
              type: "email",
              sx: { flex: 1 },
            }}
          />

          <Controller
            name="telNumber"
            control={control}
            render={({ fieldState, field }) => (
              <MaskedInput
                mask={maskBuilderTelephone(field.value)}
                guide={false}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label="DDD + Telefone"
                    variant="outlined"
                    inputRef={ref}
                    placeholder="(00) 00000-0000"
                    {...props}
                    sx={{ flex: 1 }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              ></MaskedInput>
            )}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default BasicData;
