const endpoints = {
  template: {
    exportToAlterdataTXT: "/Template/ExportToAlterdataTXT",
    exportToDominio: "/Template/ExportToDominio",
    exportToQuestorTxt: "/Template/ExportToQuestorTxt",
    exportToQuestorCsv: "/Template/ExportToQuestorCsv",
    exportToSAGE: "/Template/ExportToSAGE",
    getTemplates: "/Template/GetTemplates",
    getTemplate: "/Template/GetTemplate",
    createTemplate: "/Template/Create",
    updateTemplate: "/Template/Update",
    deleteTemplate: "/Template/Delete",
    updateLayout: "/Template/UpdateLayout",
  },
  marketplace: {
    styles: "/marketplace/lookandfeel",
    pathImage: "/marketplace/GetPathImage",
    typeSubdomain: "/marketplace/GetKey",
    clients: "/Marketplace/GetClients",
    clientDetails: "/Marketplace/GetClientDetails",
    clientPaymentMethod: "/Marketplace/GetClientPaymentMethod",
    allSubAccounts: "/Marketplace/GetAllSubAccounts",
    plans: "/Plan/GetPlans",
    import: "/imports/clients",
    servicesAndPlans: "/Marketplace/GetServicesAndPlans",
    updateCustomService: "/Marketplace/CreateUpdateCustomService",
    deleteCustomService: "/Marketplace/DeleteCustomService",
    updateDefaultService: "/Marketplace/UpdateDefaultService",
    updateDefaultPlan: "/Marketplace/UpdateDefaultPlan",
    getAllSubs: "/Marketplace/GetAllSubs",
    getLeads: "/Marketplace/GetLeads",
    getForms: "/Marketplace/GetForms",
    getGroupsForm: "/Marketplace/GetGroupsForm",
    saveGroupForm: "/Marketplace/SaveGroupForm",
    getClientsConfigLeads: "/Marketplace/GetClientsConfigLeads",
    addForm: "/Marketplace/AddForm",
    removeForm: "/Marketplace/RemoveForm",
    addViewLeadForm: "/Marketplace/AddViewLeadForm",
    getClient: "/Marketplace/GetClient",
    getServicesAndPlansClient: "/Marketplace/GetServicesAndPlansClient",
  },
  registration: {
    forgotPassword: "/Registration/RemindPassword",
    checkAvailability: "/Registration/CheckAvailability",
    simpleRegistration: "/Registration/SimpleRegistration",
    changeSimplePassword: "/Registration/ChangeSimplePassword",
    signIn: "/Login/Form",
    profilePost: "/Registration/GetProfilePost",
    getProfile: "/Registration/GetProfile",
    updateProfile: "/Registration/UpdateProfile",
    updateProfileToken: "/Registration/UpdateProfileToken",
    getUserInfo: "/Login/GetUserInfo",
  },
  companies: {
    lastAccess: "/Companies/GetCompanyLastAccess",
    updateVerifyAccount: "/Companies/UpdateVerifyAccount",
    profiles: "/Companies/GetProfiles",
    company: "/Companies/GetCompany",
    paymentInfo: "/Companies/GetPaymentInfo",
    paymentInfoProfile: "/Companies/GetPaymentInfoProfile",
    update: "/Companies/Update",
    companyPartners: "/Companies/GetCompanyPartners",
    updateCompanyPartners: "/Companies/UpdateCompanyPartners",
    updateCompanyPartner: "/Companies/UpdateCompanyPartner",
    paymentMethodProfile: "/Companies/SetPaymentMethodProfile",
    paymentMethod: "/Companies/SetPaymentMethod",
    uploadDocumentsApproveDrive: "/companies/UploadDocumentsApproveDrive",
    updateAccountAutomatic: "/Companies/UpdateAccountAutomatic",
    templatesFinancialCategories: "/Companies/GetTemplatesFinancialCategories",
    templatesAccountanting: "/Companies/GetTemplatesAccountanting",
    createTemplateFinancialCategory: "/Companies/CreateTemplateFinancialCategory",
    createTemplateAccountanting: "/Companies/CreateTemplateAccountanting",
    copyTemplateFinincialCategory: "/Companies/CopyTemplatesFinancialCategories",
    deleteTemplateFinancialCategory: "/Companies/DeleteTemplateFinancialCategory",
    deleteTemplateAccountanting: "/Companies/DeleteTemplateAccountanting",
    companyParticipants: "/Companies/GetCompanyParticipants",
    createCompanyParticipants: "/Companies/CreateCompanyParticipant",
    updateCompanyParticipant: "/Companies/UpdateCompanyParticipant",
    validateParticipantCPFCNPJ: "/Companies/ValidateParticipantCPFCNPJ",
    deleteParticipant: "/Companies/DeleteParticipant",
    getCompanyParticipant: "/Companies/GetCompanyParticipant",
    getCompanyBankAccounts: "/Companies/GetCompanyBankAccounts",
    createCompanyBankAccount: "/Companies/CreateCompanyBankAccount",
    updateCompanyBankAccount: "/Companies/UpdateCompanyBankAccount",
    deleteCompanyBankAccount: "/Companies/DeleteCompanyBankAccount",
    getFinancialCategories: "/Companies/GetFinancialCategories",
    createCategory: "/Companies/CreateCategory",
    deleteCategory: "/Accountant/DeleteCategory",
    updateCategory: "/Companies/UpdateCategory",
    getCompanyPartnerDetail: "/Companies/GetCompanyPartnerDetail",
    createProfile: "/Companies/CreateProfile",
    updateProfile: "/Companies/UpdateProfile",
    getOpenMonths: "/Companies/GetOpenMonths",
    getMonthClosure: "/Companies/GetMonthClosure",
    getTransaction: "/Companies/GetTransaction",
    closeMonth: "/Companies/CloseMonth",
    reopenMonth: "/Companies/ReopenMonth",
    getFinancialSummary: "/Companies/GetFinancialSummary",
    getTransactions: "/Companies/GetTransactions",
    getTransactionsWithPagination: "/Companies/GetTransactionsWithPagination",
    parcialTransaction: "/Companies/ParcialTransaction",
    getInvoiceConfig: "/Companies/GetInvoiceConfig",
    createTransaction: "/Companies/CreateTransaction",
    uploadReceipt: "/companies/uploadReceipt",
    deleteTransaction: "/Companies/DeleteTransaction",
    deleteRecurrentTransaction: "/Companies/DeleteRecurrentTransaction",
    deleteTransactions: "/Companies/DeleteTransactions",
    settleTransactions: "/Companies/SettleTransactions",
    getBalance: "/Companies/GetBalance",
    updateTransaction: "/Companies/UpdateTransaction",
    importOFX: "/Companies/ImportOFX",
    getTransactionsSearch: "/Companies/GetTransactionsSearch/",
    createOrUpdateTransactionOFX: "/Companies/CreateOrUpdateTransactionOFX",
    createOrUpdateTransactionOFXSearch: "/Companies/CreateOrUpdateTransactionOFX_Search",
    getTransactionsMatch: "/Companies/GetTransactionsMatch",
    getGlobalCategoriesByGroup: "/Companies/GetGlobalCategoriesByGroup",
    createFinancialCategory: "/Companies/CreateFinancialCategory",
    updateFinancialCategory: "/Companies/UpdateFinancialCategory",
    deleteFinancialCategory: "/Companies/DeleteFinancialCategory",
    getCompanyDocumentsDrive: "/Companies/GetCompanyDocumentsDrive",
    createFolderDrive: "/Companies/CreateFolderDrive",
    deleteDocumentDrive: "/Companies/DeleteDocumentDrive",
    uploadDocumentsDrive: "/Companies/UploadDocumentsDrive",
    renameAndMoveDocumentDrive: "/Companies/RenameAndMoveDocumentDrive",
    getCompaniesPost: "/Companies/GetCompaniesPost",
    updateLastAccess: "/Companies/UpdateLastAccess",
    updateCompanyProfile: "/Companies/UpdateCompanyProfile",
    getTemplatesToFrom: "/Companies/GetTemplatesToFrom",
    getCategoriesWithReleases: "/Companies/GetCategoriesWithReleases",
    updateCompanyBankAccountAccountanting: "/Companies/UpdateCompanyBankAccountAccountanting",
    resendPaymentMethod: "/Companies/ResendPaymentMethod",
    changeOwnerOsaykAccountant: "/Companies/ChangeOwnerOsaykAccountant",
    getInvoices: "/Companies/GetInvoices",
    createTransactionAccountant: "/Companies/CreateTransactionAccountant",
    updatePaymentMethod: "/Companies/UpdatePaymentMethod",
    updateIntegratorAccount: "/Companies/UpdateIntegratorAccount",
  },
  admin: {
    changeDocumentStatus: "/Admin/ChangeDocumentStatus",
    updateCompanyRegistrationStatus: "/Admin/UpdateCompanyRegistrationStatus",
    usersAdmList: "/Admin/GetUsersAdmList",
    usersAdm: "/Admin/GetUsersAdm",
    addUser: "/Admin/AddUser",
    updateUser: "/Admin/UpdateUser",
    deleteUser: "/Admin/DeleteUser",
    blockUser: "/Admin/BlockUser",
    unBlockUser: "/Admin/UnBlockUser",
    sendPasswordUser: "/Admin/SendPasswordUser",
    getCompanyAssignments: "/Admin/GetCompanyAssignments",
    getUsersCompanies: "/Admin/GetUsersCompanies",
    assignAccountant: "/Admin/AssignAccountant",
    updateCompanyActivate: "/Admin/UpdateCompanyActivate",
    deleteCompany: "/Admin/DeleteCompany",
    generatePassDefault: "/Admin/GeneratePassDefault",
    updateCompanyStatus: "/Admin/UpdateCompanyStatus",
  },
  billing: {
    infosIUGU: "/Billing/GetInfosIUGU",
    infoAccount: "/Billing/GetInfoAccount",
    infoAccountAdmin: "/Billing/GetInfoAccountAdmin",
    bills: "/Billing/GetBills",
    cancelBill: "/Billing/CancelBill",
    createBill: "/Billing/Create",
    cancelSubscription: "/Billing/CancelSubscription",
    activeSubscription: "/Billing/ActiveSubscription",
    deleteSubscription: "/Billing/DeleteSubscription",
    createSubscription: "/Billing/CreateSubscription",
    spreads: "/Billing/GetSpreads",
    updateSpreads: "/Billing/UpdateSpreads",
    duplicateBill: "/Billing/DuplicateBill",
    getUsersBills: "/Billing/GetUsersBills",
    cancelBillByAccountant: "/Billing/CancelBillByAccountant",
    duplicateBillByAccountant: "/Billing/DuplicateBillByAccountant",
    overdueSettings: "/Billing/GetOverdueSettings",
    getDunning: "/Billing/GetDunning",
    updateDunning: "/Billing/UpdateDunning",
    getPenaltiesAndDiscounts: "/Billing/GetPenaltiesAndDiscounts",
    updatePenaltiesAndDiscounts: "/Billing/UpdatePenaltiesAndDiscounts",
    withdrawOsayk: "/Billing/WithdrawOsayk",
    withdraw3rd: "/Billing/Withdraw3rd",
    withdraw: "/Billing/Withdraw",
    updateSubscription: "/Billing/UpdateSubscription",
    getFinancial: "/Billing/GetFinancial",
    getAllTransactions: "/Billing/GetAllTransactions",
    requestAdvancements: "/Billing/RequestAdvancements",
  },
  commonData: {
    CNAEs: "/CommonData/CNAEs",
    countries: "/CommonData/Counties",
    banks: "/CommonData/Banks",
    countryStates: "/CommonData/CountryStates",
    guessCounty: "/CommonData/GuessCounty",
  },
  mailbox: {
    getGroups: "/mailbox/GetGroups",
    createGroups: "/mailbox/CreateGroup",
    updateGroup: "/mailbox/UpdateGroup",
    getAllChats: "/mailbox/GetAllChats",
    getTicketById: "/mailbox/GetTicketById",
    getTicket: "/mailbox/GetTicket",
    openTicket: "/mailbox/OpenTicket",
    closeTicketByCompanyId: "/mailbox/CloseTicketByCompanyId",
    replyTicketByCompanyId: "/mailbox/ReplyTicketByCompanyId",
    uploadByCompanyId: "/mailbox/UploadByCompanyId",
    upload: "/mailbox/upload",
    getChats: "/mailbox/GetChats",
    getCompanyGroups: "/mailbox/GetCompanyGroups",
    createTicket: "/mailbox/CreateTicket",
    closeTicket: "/mailbox/closeticket",
    replyTicket: "/mailbox/ReplyTicket",
  },
  notifications: {
    getAllNotifications: "/notifications/GetAll",
    getUnpresented: "/notifications/GetUnpresented",
    markAsPresented: "/notifications/MarkAsPresented",
    getAllNotificationsToAccountant: "/notifications/GetAllToAccountant",
    markAsPresentedToAccountant: "/notifications/MarkAsPresentedToAccountant",
  },
  contract: {
    getContracts: "/Contract/GetContracts",
    uploadContracts: "/Contract/UploadContract",
    delete: "/Contract/Delete",
  },
  accountantPlan: {
    save: "/AccountantPlan/Save",
  },
  accountant: {
    getGroupChartOfAccountsFromTo: "/Accountant/GetGroupChartOfAccountsFromTo",
    getChartOfAccountsFromToGroup: "/Accountant/GetChartOfAccountsFromToGroup",
    getClientChartOfAccounts: "/Accountant/GetClientChartOfAccounts",
    getCompanyGlobalAccountanting: "/Accountant/GetCompanyGlobalAccountanting",
    addAccountFromTo: "/Accountant/AddAccountFromTo",
    deleteAccountsFromTo: "/Accountant/DeleteAccountsFromTo",
    deleteAccountFromTo: "/Accountant/DeleteAccountFromTo",
    deleteAllAccountsFromTo: "/Accountant/DeleteAllAccountsFromTo",
    updateFromTo: "/Accountant/UpdateFromTo",
    importFileExcel: "/Accountant/ImportFileExcel",
    importExcel: "/Accountant/ImportExcel",
    getGlobalAccountanting: "/Accountant/GetGlobalAccountanting",
    updateGlobalCategoriesAccount: "/Accountant/UpdateGlobalCategoriesAccount",
    transferFinancialCategory: "/Accountant/TransferFinancialCategory",
    updateOrderGlobalCategories: "/Accountant/UpdateOrderGlobalCategories",
    importToFrom: "/Accountant/ImportToFrom",
    moveReleasesFinancial: "/Accountant/MoveReleasesFinancial",
    createCompanyFinancialCategory: "/Accountant/CreateCompanyFinancialCategory",
    updateCompanyOrderGlobalCategories: "/Accountant/UpdateCompanyOrderGlobalCategories",
    updateCompanyFinancialCategory: "/Accountant/UpdateCompanyFinancialCategory",
    deleteCompanyFinancialCategoryById: "/Accountant/DeleteCompanyFinancialCategoryById",
    updateCompanyChartOfAccounts: "/Accountant/UpdateCompanyChartOfAccounts",
    getCompanyChartOfAccountsFromTo: "/Accountant/GetCompanyChartOfAccountsFromTo",
    getCompanyChartOfAccountsFromToByTerm: "/Accountant/GetCompanyChartOfAccountsFromToByTerm",
    addCompanyAccountFromTo: "/Accountant/AddCompanyAccountFromTo",
    updateCompanyFromTo: "/Accountant/UpdateCompanyFromTo",
    deleteCompanyAccountFromTo: "/Accountant/DeleteCompanyAccountFromTo",
  },
  configurations: {
    configurationMonthClosure: "/Configurations/GetConfigurationsMonthClosure",
    changeConfigurationMonthClosure: "/Configurations/ConfigurationsMonthClosure",
    getConfigurationBlockReleases: "/Configurations/GetConfigurationsBlockReleases",
    blockReleases: "/Configurations/BlockReleases",
  },
  communications: {
    configuration: "/communications/GetConfiguration",
    setConfiguration: "/communications/configuration",
    messageTypes: "/communications/messagetypes",
    message: "/communications/message",
    sendEmailTest: "/communications/sendemailtest",
  },
  files: {
    uploadImage: "/files/UploadImage",
    uploadImageLogin: "/files/UploadImageLogin",
    listFilesIntegration: "/Files/ListFilesIntegration",
    getFileHistoryForIntegrationCompany: "/Files/GetFileHistoryForIntegrationCompany",
  },
  plan: {
    defaultAccountabilityPlan: "/Plan/GetDefaultAccountabilityPlan",
    osaykPlanByProduct: "/Plan/GetOsaykPlanByProduct",
  },
  dashboard: {
    getSummary: "/Dashboard/GetSummary",
    getLatestTransactions: "/Dashboard/GetLatestTransactions",
    getPieChart: "/Dashboard/GetPieChart",
    getRevenuesVsExpenses: "/Dashboard/GetRevenuesVsExpenses",
  },
  stockControl: {
    products: "/StockControl/Products",
    product: "/StockControl/Product",
    deleteProduct: "/StockControl/DeleteProduct",
    balance: "/StockControl/Balance",
    movements: "/StockControl/Movements",
    modalities: "/StockControl/Modalities",
    invoiceConfig: "/StockControl/InvoiceConfig",
    movement: "/StockControl/Movement",
    deleteMovement: "/StockControl/DeleteMovement",
    invoices: "/StockControl/Invoices",
    corrections: "/StockControl/Corrections",
    correction: "/StockControl/Correction",
    deleteCorrection: "/StockControl/DeleteCorrection",
    deleteInvoice: "/StockControl/DeleteInvoice",
    presenceIndicatorNFe: "/StockControl/PresenceIndicatorNFe",
    presenceIndicatorNFCe: "/StockControl/PresenceIndicatorNFCe",
    paymentTypes: "/StockControl/PaymentTypes",
    paymentModes: "/StockControl/PaymentModes",
  },
  openCompany: {
    ordersByAssignments: "/OpenCompany/GetOrdersByAssignments",
    getLegals: "/OpenCompany/GetLegals",
    deleteOrder: "/OpenCompany/DeleteOrder",
    changeStateOrderOpenCompany: "/OpenCompany/ChangeStateOrderOpenCompany",
    getProfile: "/OpenCompany/GetProfile",
    getCompanyActivities: "/OpenCompany/GetCompanyActivities",
    getCNAEsByActivity: "/OpenCompany/GetCNAEsByActivity",
    updateCompany: "/OpenCompany/UpdateCompany",
    addOrderOpenCompany: "/OpenCompany/AddOrderOpenCompany",
    getOrdersByCompany: "/OpenCompany/GetOrdersByCompany",
  },
  invoice: {
    downloadBatch: "/Invoice/DownloadBatch",
    getConfiguration: "/Invoice/GetConfiguration",
    prefectureByCompany: "/Invoice/PrefectureByCompany",
    uploadLogo: "/Invoice/UploadLogo",
    municipalServiceByCompany: "/Invoice/MunicipalServiceByCompany",
    createConfiguration: "/Invoice/CreateConfiguration",
  },
  dre: {
    getDRE: "/DRE/GetDre",
    getSyntheticAccounts: "/DRE/GetSyntheticAccounts",
    addOrUpdateSubAccount: "/DRE/AddOrUpdateSubAccount",
    removeSubAccount: "/DRE/DeleteSubAccount",
    getAnalitycalAccounts: "/DRE/GetAnalitycalAccounts",
  },
  accountantApps: {
    getAccountantApps: "/AccountantApps/GetAccountantApps",
    saveAccountantApps: "/AccountantApps/SaveAccountantApps",
  },
  bankingIntegrator: {
    getConnectToken: "/BankingIntegrator/GetConnectToken",
    getAccount: "/BankingIntegrator/GetAccount",
    linkAccount: "/BankingIntegrator/LinkAccount",
    getTransactions: "/BankingIntegrator/GetTransactions",
    getIntegratorAccount: "/BankingIntegrator/GetIntegratorAccount",
    updateAccountKey: "/BankingIntegrator/UpdateAccountKey",
  },
  external: {
    receitaws: "https://receitaws.com.br/v1/cnpj",
  },
} as const;

export default endpoints;
