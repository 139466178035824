import { FC } from "react";

import { Box, Stack, SxProps, TextField } from "@mui/material";
import { Theme } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";

import { DateTime } from "luxon";
import { Correction } from "types/StockControl";
import useMutationDeleteCorrection from "../hooks/useMutationDeleteCorrection";
import { LoadingButton } from "@mui/lab";

const style: SxProps<Theme> = {
  pb: 3,
  borderBottom: 1,
  borderColor: "divider",
};

interface CorrectionIssuedProps {
  correction: Correction;
  isLast: boolean;
  companyToken: string;
  movementId: number;
}

const CorrectionIssued: FC<CorrectionIssuedProps> = ({
  correction,
  companyToken,
  movementId,
  isLast,
}) => {
  const { mutate, isLoading } = useMutationDeleteCorrection();

  const handleDeleteCorrection = (correction: Correction) => {
    mutate({
      companyToken,
      movementId,
      id: correction.id,
    });
  };

  return (
    <Box display="grid" gap={2} key={correction.id} sx={!isLast ? style : undefined}>
      <Stack alignItems="end">
        <LoadingButton
          onClick={() => handleDeleteCorrection(correction)}
          variant="text"
          size="small"
          startIcon={<DeleteIcon fontSize="small" />}
          loading={isLoading}
        >
          Excluir
        </LoadingButton>
      </Stack>

      <Box display="grid" gridTemplateColumns="1fr 3fr" gap={2}>
        <TextField value={correction.number ?? ""} disabled label="Número" />
        <TextField value={correction.accessKey ?? ""} disabled label="Chave de Acesso" />
      </Box>
      <TextField value={correction.description ?? ""} disabled label="Descrição" />

      <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
        <TextField value={correction.correctionStatus ?? ""} disabled label="Status" />
        <TextField value={correction.error ?? ""} disabled label="Erro" />
        <TextField value={correction.protocol ?? ""} disabled label="Protocolo" />
        <TextField
          value={DateTime.fromISO(correction.createdOn).toFormat("dd/MM/yyyy")}
          disabled
          label="Data de cadastro"
        />
      </Box>
    </Box>
  );
};

export default CorrectionIssued;
