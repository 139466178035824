import { FC, MouseEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Control, Controller, Path, useWatch } from "react-hook-form";

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import ControllerInput from "components/ControllerInput";

import { ReleaseCreditForm } from "../Form";
import { InvoiceConfig } from "types/Company";
import { useAuth } from "context/Auth";

const taxes: { name: Path<ReleaseCreditForm>; label: string }[] = [
  {
    name: "invoice.iss",
    label: "ISS (%)",
  },
  {
    name: "invoice.irpj",
    label: "IRPJ (%)",
  },
  {
    name: "invoice.irop",
    label: "INSS (%)",
  },
  {
    name: "invoice.pis",
    label: "PIS (%)",
  },
  {
    name: "invoice.cofins",
    label: "COFINS (%)",
  },
  {
    name: "invoice.csll",
    label: "CSLL (%)",
  },
];

interface InvoiceFormProps {
  control: Control<ReleaseCreditForm>;
  invoiceConfig: InvoiceConfig;
}

const InvoiceForm: FC<InvoiceFormProps> = ({ control, invoiceConfig }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [dialogConfigInvoiceIsOpen, setDialogConfigInvoiceIsOpen] = useState(false);
  const invoiceHasBeenConfigured = invoiceConfig.integration === 1;

  const paymentOrigin = useWatch({
    control,
    name: "paymentOrigin",
  });
  const isInvoice = useWatch({
    control,
    name: "isInvoice",
  });

  const handleClickConfigNFS: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    navigate(e.currentTarget.pathname);
  };

  return (
    <Stack gap={2} overflow="hidden">
      <Controller
        name="isInvoice"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            disabled={paymentOrigin === "partner"}
            sx={{ m: 0, height: "auto" }}
            control={
              <Checkbox
                onBlur={field.onBlur}
                onChange={(e) => {
                  if (invoiceHasBeenConfigured) {
                    field.onChange(e);
                  } else {
                    setDialogConfigInvoiceIsOpen(true);
                  }
                }}
                checked={field.value}
                value={field.value}
                inputRef={field.ref}
              />
            }
            label="Gerar nota fiscal eletrônica (NFS-e)"
          />
        )}
      />

      <Controller
        name="invoice.when"
        control={control}
        render={({ field }) => (
          <FormControl disabled={!isInvoice}>
            <RadioGroup {...field}>
              <FormControlLabel value={0} control={<Radio size="small" />} label="Emitir agora" />
              <FormControlLabel
                value={1}
                control={<Radio size="small" />}
                label="Emitir na baixa do lançamento"
              />
            </RadioGroup>
          </FormControl>
        )}
      />

      <Controller
        name="invoice.issueDate"
        control={control}
        render={({ field, fieldState }) => (
          <DatePicker
            disabled={!isInvoice}
            label="Nota emitida em"
            inputFormat="dd/MM/yyyy"
            {...field}
            allowSameDateSelection
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={fieldState.error?.message}
                error={params.error || !!fieldState.error?.message}
              />
            )}
          />
        )}
      />

      <Controller
        name="invoice.serviceCodeId"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl disabled={!isInvoice}>
            <InputLabel error={!!fieldState.error?.message}>Serviço Prestado</InputLabel>
            <Select
              label="Serviço Prestado"
              {...field}
              error={!!fieldState.error?.message}
              value={field.value || ""}
            >
              <MenuItem disabled value="">
                Selecione:{" "}
              </MenuItem>
              {invoiceConfig.services.map((service) => (
                <MenuItem value={service.id} key={service.id}>
                  {service.descricao}
                </MenuItem>
              ))}
            </Select>
            {!!fieldState.error?.message && (
              <FormHelperText error>{fieldState.error?.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Box>
        <Typography variant="body1">Retenção de Tributos</Typography>
        <Stack direction="row" gap={1} mt={1} flexWrap="wrap">
          {taxes.map((t) => (
            <Controller
              name={t.name}
              control={control}
              key={t.name}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t.label}
                  disabled={!isInvoice}
                  sx={{ minWidth: 100, flex: 1 }}
                  type="number"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          ))}
        </Stack>
      </Box>

      <Controller
        name="invoice.issWithHeld"
        control={control}
        render={({ field }) => (
          <FormControl disabled={!isInvoice}>
            <FormLabel>ISS Retido</FormLabel>
            <RadioGroup {...field} row>
              <FormControlLabel value={1} control={<Radio size="small" />} label="Sim" />
              <FormControlLabel value={0} control={<Radio size="small" />} label="Não" />
            </RadioGroup>
          </FormControl>
        )}
      />

      <ControllerInput
        name="invoice.descriptionNfe"
        control={control}
        inputProps={{ label: "Discriminação", disabled: !isInvoice, multiline: true, rows: 2 }}
      />

      <Collapse in={isInvoice}>
        <ControllerInput
          name="invoice.notes"
          control={control}
          inputProps={{
            fullWidth: true,
            label: "Informações adicionais",
            disabled: !isInvoice,
            multiline: true,
            rows: 2,
          }}
        />
      </Collapse>

      <Dialog
        open={dialogConfigInvoiceIsOpen}
        onClose={() => setDialogConfigInvoiceIsOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Emissão de nota fiscal eletrônica</DialogTitle>

        <DialogContent>
          Você ainda não fez as configurações necessárias para a emissão da nota fiscal. Deseja
          fazer isso agora?
        </DialogContent>

        <DialogActions>
          <Button
            component="a"
            href={user.userInfo.userType === "User" ? "/nfs-e/configurar" : "/configuracoes-nfs-e"}
            variant="contained"
            onClick={handleClickConfigNFS}
          >
            Sim
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="outlined"
            onClick={() => setDialogConfigInvoiceIsOpen(false)}
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default InvoiceForm;
