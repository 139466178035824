import { FC } from "react";
import { useAuth } from "context/Auth";
import { useParams } from "react-router-dom";

import { Typography } from "@mui/material";
import Head from "components/Head";

import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import Page from "./Page";

const Layout: FC = () => {
  const {
    user: { company },
  } = useAuth();
  const params = useParams();
  const layoutId = Number(params.id as string);

  return (
    <>
      <Head title={`Layout | ${getSubdomain()}`} />

      {!company && (
        <Typography variant="h4" textAlign="center">
          {MESSAGE_NO_COMPANY}
        </Typography>
      )}

      {!layoutId && !!company && (
        <Typography variant="h4" textAlign="center">
          Layout não encontrado
        </Typography>
      )}

      {!!layoutId && !!company && <Page id={layoutId} companyToken={company.token} />}
    </>
  );
};

export default Layout;
