import { FC } from "react";
import useQueryGetCompany from "pages/Home/hooks/useQueryGetCompany";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { Order as IOrder } from "types/OpenCompany";
import Form from "./Form";

interface CompanyProps {
  order: IOrder;
}

const Company: FC<CompanyProps> = ({ order }) => {
  const { data: company, isLoading, isError } = useQueryGetCompany(order.companyToken);

  return (
    <Box>
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" height={400}>
          <CircularProgress />
        </Stack>
      )}

      {isError && (
        <Typography>
          Ocorreu um erro ao tentar pegar os dados da empresa, por favor, tente mais tarde
        </Typography>
      )}

      {!isLoading && company && <Form company={company} />}
    </Box>
  );
};

export default Company;
