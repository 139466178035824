import { FC } from "react";
import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CardContent } from "../CardWrapper";
import InvoiceTableItem from "./InvoiceTableItem";

import useQueryPaymentInfoProfile from "../../hooks/useQueryPaymentInfoProfile";
import { useAuth } from "context/Auth";

interface InvoiceHistoryProps {
  companyToken: string;
}

const InvoiceHistory: FC<InvoiceHistoryProps> = ({ companyToken }) => {
  const {
    user: { userInfo },
  } = useAuth();
  const { data, isLoading, isSuccess, isFetching } = useQueryPaymentInfoProfile(
    companyToken,
    userInfo.userType === "Admin",
  );
  const bills = data?.bills;

  return (
    <CardContent position="relative">
      {isFetching && !isLoading && (
        <CircularProgress size={20} sx={{ position: "absolute", top: "-32px", right: "32px" }} />
      )}

      {isLoading && (
        <Stack height={100} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {isSuccess && !bills && <Typography variant="body1">Histórico não disponível</Typography>}

      {bills && (
        <TableContainer sx={{ maxHeight: "450px", overflowY: "auto" }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>Vencimento</TableCell>
                <TableCell>Pagamento</TableCell>
                <TableCell>Valor R$</TableCell>
                <TableCell>Serviço</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={{ fontSize: "13px" }}>
              {bills.map((bill) => (
                <InvoiceTableItem key={bill.id} bill={bill} />
              ))}

              {bills.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6}>Nenhuma fatura</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </CardContent>
  );
};

export default InvoiceHistory;
