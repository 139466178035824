import { FC } from "react";
import { Control, Controller } from "react-hook-form";

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { InvoiceForm, InvoiceType } from ".";
import Logo from "./components/Logo";

import { InvoiceConfiguration, PrefectureInvoice } from "types/Invoice";

interface CompanyProps {
  control: Control<InvoiceForm>;
  prefecture: PrefectureInvoice | undefined;
  invoiceConfiguration: InvoiceConfiguration;
  companyToken: string;
  invoiceType: InvoiceType;
}

const Company: FC<CompanyProps> = ({
  control,
  prefecture,
  invoiceConfiguration,
  companyToken,
  invoiceType,
}) => {
  return (
    <Box>
      <Typography variant="h6" fontWeight={600} borderBottom={1} borderColor="divider">
        Cadastre as configurações da sua empresa
      </Typography>

      <Box display="grid" gap={2} mt={2}>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
          <Controller
            control={control}
            name="insc"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={`Inscrição ${invoiceType === "NFC-e" ? "Estadual" : "Municipal"}`}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                inputProps={{
                  maxLength: 22,
                }}
              />
            )}
          />

          {!!prefecture && prefecture.regimesEspecialTributacao !== null && (
            <Controller
              control={control}
              name="especial"
              render={({ field, fieldState }) => (
                <FormControl error={!!fieldState.error?.message}>
                  <InputLabel>Regime especial de tributação</InputLabel>
                  <Select {...field} label="Regime especial de tributação">
                    {prefecture.regimesEspecialTributacao.map((regime) => (
                      <MenuItem key={regime.codigo} value={regime.codigo}>
                        {regime.nome}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!fieldState.error?.message && (
                    <FormHelperText error>{!!fieldState.error?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          )}
        </Box>

        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
          <Controller
            control={control}
            name="cultural"
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState.error?.message}>
                <InputLabel>Incentivo cultural</InputLabel>
                <Select {...field} label="Incentivo cultural">
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="0">Não</MenuItem>
                  <MenuItem value="1">Sim</MenuItem>
                </Select>
                {!!fieldState.error?.message && (
                  <FormHelperText error>{!!fieldState.error?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="optingSimple"
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState.error?.message}>
                <InputLabel>Simples nacional</InputLabel>
                <Select {...field} label="Simples nacional">
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="0">Não</MenuItem>
                  <MenuItem value="1">Sim</MenuItem>
                </Select>
                {!!fieldState.error?.message && (
                  <FormHelperText error>{!!fieldState.error?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Box>

        {invoiceType !== "NFC-e" && (
          <Logo
            eNotasId={invoiceConfiguration.eNotasId}
            companyToken={companyToken}
            idInvoice={invoiceConfiguration.id}
          />
        )}
      </Box>
    </Box>
  );
};

export default Company;
