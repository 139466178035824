import { FC } from "react";

import { Avatar, Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Download from "@mui/icons-material/Download";

import { DateTime } from "luxon";
import { Chat, ChatInformation, ChatMessage } from "types/Mailbox";
import { BASE_URL_DOWNLOAD_DOCUMENT } from "utils/constants";

interface ChatItemProps {
  ticket: Chat;
  message: ChatMessage;
  chatInformation: ChatInformation;
}

const ChatItem: FC<ChatItemProps> = ({ message, chatInformation, ticket }) => {
  const direction = message.userId === message.authorId ? "left" : "right";

  return (
    <Stack
      key={message.messageId}
      direction="row"
      gap={1}
      justifyContent={direction === "left" ? "flex-start" : "flex-end"}
    >
      {direction === "left" && (
        <Tooltip title={chatInformation.companyName}>
          <Avatar
            sx={{ bgcolor: chatInformation.color, width: 30, height: 30, fontSize: "0.875rem" }}
          >
            {chatInformation.initials}
          </Avatar>
        </Tooltip>
      )}

      <Stack maxWidth="50%" alignItems={direction === "left" ? "flex-start" : "flex-end"}>
        <Box
          width="fit-content"
          px={2}
          py={1}
          sx={{
            borderRadius: direction === "left" ? "0 4px 4px 4px" : "4px 0 4px 4px",
          }}
          height="min-content"
          bgcolor={direction === "left" ? grey[300] : grey[200]}
        >
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            {message.message}
          </Typography>
        </Box>

        <Stack
          direction={direction === "left" ? "row-reverse" : "row"}
          gap={0.5}
          alignItems="center"
          flexWrap="wrap"
          mt={0.5}
        >
          {message.documents.map((document) => (
            <Tooltip key={document.downloadToken} title={document.name}>
              <a
                href={`${BASE_URL_DOWNLOAD_DOCUMENT}/?id=${document.downloadToken}&fullPath=/COMPROVANTES+DE+PAGAMENTO&preview=true`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton size="small">
                  <Download sx={{ fontSize: "0.7rem" }} />
                </IconButton>
              </a>
            </Tooltip>
          ))}

          <Typography variant="body2" sx={{ fontSize: "0.675rem", mt: 0.5 }} color="text.secondary">
            {DateTime.fromISO(message.created).toFormat("dd/MM/yy HH:mm")}
          </Typography>
        </Stack>
      </Stack>

      {direction === "right" && (
        <Avatar sx={{ bgcolor: ticket.color, width: 30, height: 30, fontSize: "0.875rem" }}>
          {ticket.initials}
        </Avatar>
      )}
    </Stack>
  );
};

export default ChatItem;
