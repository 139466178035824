import { FC, useState } from "react";
import { useCompanyInfo } from "context/CompanyInfo";

import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import Undo from "@mui/icons-material/Undo";

import { CompanyStyles } from "types/Company";
import ColorField from "./ColorField";
import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";
import useMutationUpdateCompanyStyles from "pages/Home/hooks/useMutationUpdateCompanyStyles";

interface ColorsDetails {
  label: string;
  property: keyof CompanyStyles;
}

const modifiableColors: {
  title: string;
  colors: ColorsDetails[];
}[] = [
  {
    title: "Estrutura",
    colors: [
      {
        label: "Barra superior (header)",
        property: "headerBg",
      },
      {
        label: "Logotipo (fundo)",
        property: "logoBg",
      },
      {
        label: "Links da barra superior",
        property: "headerLinksColor",
      },
      {
        label: "Barra lateral - Fundo",
        property: "sidebarBg",
      },
      {
        label: "Barra lateral - Links (texto)",
        property: "sidebarLinkColor",
      },
      {
        label: "Barra lateral - Links (fundo/hover)",
        property: "sidebarLinkHoverBg",
      },
      {
        label: "Barra lateral - Links (borda esquerda)",
        property: "sidebarLinkHoverBorder",
      },
      {
        label: "Barra lateral - Fundo do Submenu",
        property: "sidebarSubmenuBg",
      },
      {
        label: "Login - Fundo",
        property: "loginBg",
      },
    ],
  },
  {
    title: "Textos",
    colors: [
      {
        label: "Cor dos textos",
        property: "bodyColor",
      },
      {
        label: "Títulos",
        property: "h1Color",
      },
      {
        label: "Subtítulos",
        property: "h4Color",
      },
      {
        label: "Links",
        property: "linksColor",
      },
      {
        label: "Links (hover)",
        property: "linksHoverColor",
      },
      {
        label: "Campos de texto - Bordas - Foco",
        property: "formInputFocusBorderColor",
      },
    ],
  },
  {
    title: "Botões",
    colors: [
      {
        label: "Botões padrão - Fundo",
        property: "buttonDefaultBg",
      },
      {
        label: "Botões padrão - Texto",
        property: "buttonDefaultColor",
      },
      {
        label: "Botões padrão - Bordas",
        property: "buttonDefaultBorderColor",
      },
      {
        label: "Botões primários - Fundo",
        property: "buttonPrimaryBg",
      },
      {
        label: "Botões primários - Texto",
        property: "buttonPrimaryColor",
      },
      {
        label: "Botões primários - Bordas",
        property: "buttonPrimaryBorderColor",
      },
    ],
  },
];

interface ColorsProps {
  companyToken: string;
}

const Colors: FC<ColorsProps> = ({ companyToken }) => {
  const { mutate, isLoading } = useMutationUpdateCompanyStyles();
  const { companyStyles } = useCompanyInfo();
  const [colors, setColors] = useState<CompanyStyles>(companyStyles);

  const handleChangeColor = (newValue: string, modified: keyof CompanyStyles) => {
    setColors((prev) => ({
      ...prev,
      [modified]: newValue,
    }));
  };

  const undoChanges = () => {
    setColors(companyStyles);
  };

  const handleChangeCompanyStyles = () => {
    mutate({
      companyToken,
      styles: colors,
    });
  };

  return (
    <>
      <Stack
        direction="row"
        gap={4}
        flexWrap="wrap"
        sx={{ pointerEvents: isLoading ? "none" : "all" }}
      >
        {modifiableColors.map((attribute, index) => (
          <Box flex={1} flexBasis={"280px"} key={index}>
            <Typography
              variant="h6"
              fontWeight={600}
              sx={{ borderBottom: 1, borderBottomColor: "divider", mb: 1 }}
            >
              {attribute.title}
            </Typography>

            <Box display="grid" gridTemplateColumns="2fr 1fr" gap={1} alignItems="center">
              {attribute.colors.map((color) => (
                <ColorField
                  key={color.property}
                  label={color.label}
                  initialValue={colors[color.property]}
                  onChange={(newValue) => {
                    handleChangeColor(newValue, color.property);
                  }}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Stack>

      <Stack
        mt={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor={grey[200]}
        p={2}
      >
        <Tooltip title="Desfazer modificações">
          <IconButton onClick={undoChanges} color="primary">
            <Undo />
          </IconButton>
        </Tooltip>
        <LoadingButton
          disableElevation={false}
          variant="contained"
          loading={isLoading}
          onClick={handleChangeCompanyStyles}
        >
          Salvar
        </LoadingButton>
      </Stack>
    </>
  );
};

export default Colors;
