import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getTemplates } from "services/Requests/template";
import getSubdomain from "utils/getSubdomain";

const useQueryTemplates = () => {
  const subdomain = getSubdomain();
  const query = useQuery(queryKeys.templates, () => getTemplates({ subdomain }));
  return query;
};

export default useQueryTemplates;
