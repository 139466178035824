import { FC, useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import InfoToPrint from "pages/Home/components/InfoToPrint";
import PrintLandscape from "components/PrintLandscape";
import ManagerialIncomeStatementTableItem from "./ManagerialIncomeStatementTableItem";
import { ClientDRETable } from "./mock";
import TableActions from "pages/Home/components/TableActions";

const tableHeadCells = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
  "Ano",
];

const ManagerialIncomeStatementTable: FC = () => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>();
  const [tableRef, setTableRef] = useState<HTMLTableElement | null>();

  return (
    <Box>
      <PrintLandscape>
        <TableContainer
          sx={{ "@media print": { "*": { fontSize: "90%" } } }}
          ref={(newRef) => setContainerRef(newRef)}
        >
          <InfoToPrint />

          <Table ref={(newRef) => setTableRef(newRef)} size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                {tableHeadCells.map((cell) => (
                  <TableCell key={cell} align="center" data-a-h="center">
                    {cell}
                  </TableCell>
                ))}
              </TableRow> 
            </TableHead>

            <TableBody>
              {ClientDRETable.sort((a, b) => a.orderFinReport - b.orderFinReport).map((summary) => (
                <ManagerialIncomeStatementTableItem
                  key={summary.categoriaSintetica}
                  managerialIncomeStatementSummaryByTypeDTO={summary}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PrintLandscape>

      {containerRef && tableRef && (
        <TableActions
          mt={2}
          p={2}
          containerTableRef={containerRef}
          tableRef={tableRef}
          nameExcel="resumo-DRE-gerencial"
          nameTable="relatório"
        />
      )}
    </Box>
  );
};

export default ManagerialIncomeStatementTable;
