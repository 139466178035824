import { useCallback } from "react";
import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCompanyAssignments, GetCompanyAssignmentsPayload } from "services/Requests/admin";
import { CompanyAssignmentsResponse } from "types/Admin";

const useQueryCompanyAssignments = ({
  registrationStatus,
  status,
  search,
}: GetCompanyAssignmentsPayload & { search?: string }) => {
  const selectSearch = useCallback(
    (data: CompanyAssignmentsResponse) => {
      if (!search) return data;

      const searchName = (name: string) => {
        if (!search.trim()) return true;

        return name.toLowerCase().search(search.toLowerCase()) !== -1;
      };

      return {
        ...data,
        companies: data.companies.filter(
          (company) => searchName(company.ownerName) || searchName(company.name),
        ),
      };
    },
    [search],
  );

  const query = useQuery(
    [queryKeys.companyAssignments, status, registrationStatus],
    () => getCompanyAssignments({ registrationStatus, status }),
    {
      select: selectSearch,
    },
  );
  return query;
};

export default useQueryCompanyAssignments;
