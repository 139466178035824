import { FC, useMemo, useState } from "react";
import useQueryCompanyDocumentsDrive from "../../hooks/useQueryCompanyDocumentsDrive";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { grey } from "@mui/material/colors";

import SortableTableHead from "components/SortableTableHead";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import PathNavigation from "../PathNavigation";

import { CompanyDocumentDrive } from "types/Company";
import comparatorSortByProperty from "utils/comparatorSortByProperty";
import stableSort from "utils/stableSort";
import { Order } from "types/SemanticTypes";
import { Paths } from "../../utils/path";

type CompanyDocumentDriveKeys = keyof CompanyDocumentDrive;

interface DialogSelectFolderProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  initialPaths: Paths;
  companyToken: string;
  onSelectPath: (paths: Paths) => void;
}

const DialogSelectFolder: FC<DialogSelectFolderProps> = ({
  initialPaths,
  companyToken,
  onSelectPath,
  ...props
}) => {
  const [paths, setPaths] = useState(initialPaths);
  const currentParentId = useMemo(() => paths[paths.length - 1]?.id || null, [paths]);

  const {
    data: companyDocumentsDrive,
    isLoading,
    isFetching,
  } = useQueryCompanyDocumentsDrive({
    companyToken,
    parentId: currentParentId,
    onlyDirectory: true,
  });

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<CompanyDocumentDriveKeys>("name");

  const sortHandler = (property: CompanyDocumentDriveKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleAddPath = (document: CompanyDocumentDrive) => {
    setPaths((prev) => [...prev, { id: document.id, name: document.name }]);
  };

  const handleRemoveCurrentPath = () => {
    setPaths((prev) => prev.slice(0, prev.length - 1));
  };

  const dispatchCallbackSelectPath = (document: CompanyDocumentDrive) => {
    onSelectPath([...paths, { id: document.id, name: document.name }]);
  };

  return (
    <Dialog {...props} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={props.onClose}>
        Selecione a pasta de destino
      </DialogTitleWithCloseButton>

      <DialogContent>
        <Box p={2} bgcolor={grey[200]}>
          <PathNavigation
            paths={paths}
            onChangePaths={(newPaths) => setPaths(newPaths)}
            maxItems={4}
          />
        </Box>

        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={400}>
            <CircularProgress />
          </Stack>
        )}

        <Collapse in={!isLoading && isFetching}>
          <LinearProgress />
        </Collapse>

        {companyDocumentsDrive && !isLoading && (
          <TableContainer sx={{ mt: 1, maxHeight: 400 }}>
            <Table size="small" stickyHeader>
              <SortableTableHead
                headCells={[{ id: "name", label: "Nome", sortable: true }]}
                onRequestSort={(e, property) => sortHandler(property)}
                order={order}
                orderBy={orderBy}
              >
                <TableCell align="right"></TableCell>
              </SortableTableHead>

              <TableBody>
                {stableSort(companyDocumentsDrive, comparatorSortByProperty(order, orderBy)).map(
                  (document) => (
                    <TableRow key={document.id} hover>
                      <TableCell onClick={() => handleAddPath(document)} sx={{ cursor: "pointer" }}>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <FolderIcon fontSize="small" />
                          {document.name}
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => dispatchCallbackSelectPath(document)}
                        >
                          Selecionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ),
                )}
                {companyDocumentsDrive.length === 0 && (
                  <TableRow hover>
                    <TableCell>Esta pasta não contém subpastas</TableCell>
                    <TableCell align="right">
                      <Button variant="contained" size="small" onClick={handleRemoveCurrentPath}>
                        voltar
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} variant="contained">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSelectFolder;
