import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getOpenMonths } from "services/Requests/companies";

const useQueryOpenMonths = (companyToken: string) => {
  const query = useQuery(queryKeys.openMonths, () => getOpenMonths({ companyToken }));
  return query;
};

export default useQueryOpenMonths;
