import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";

import toast from "react-hot-toast";
import getSubdomain from "utils/getSubdomain";
import { withdraw, WithdrawPayload } from "services/Requests/billing";

const useMutationWithdraw = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload: Omit<WithdrawPayload, "subdomain">) =>
      withdraw({ ...payload, subdomain: getSubdomain() }),
    {
      onSuccess({ amount, id }, { companyToken }) {
        if (id === "-1" && amount === 0) {
          toast.error("Saldo insuficiente para retirada!");
        } else {
          toast.success("Solicitação realizada com sucesso!");
          queryClient.invalidateQueries([queryKeys.infoAccount, companyToken]);
        }
      },
    },
  );
  return mutation;
};

export default useMutationWithdraw;
