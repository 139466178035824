import * as yup from "yup";

const validationSchema = yup.object().shape({
  paymentOption: yup.string(),

  card: yup.object().when("paymentOption", {
    is: "card",
    then: yup.object().shape({
      number: yup
        .string()
        .required("O número do cartão é obrigatório")
        .test("creditCardIsValid", "Digite um cartão válido", (value) => {
          if (!value) return true;

          return value.replace(/\D/g, "").length === 16;
        }),

      name: yup.string().required("O nome é obrigatório"),

      monthValidity: yup
        .string()
        .required("O mês da validade é obrigatório")
        .typeError("O mês da validade é obrigatório")
        .test("testDate", "Digite um mês válido", (value) => {
          if (!value) return true;

          return value !== "Invalid DateTime";
        }),

      yearValidity: yup
        .date()
        .required("O ano da validade é obrigatória")
        .typeError("Digite o ano da validade corretamente")
        .test("verifyYearExist", "Digite um ano válido", (value) => {
          if (!value) return true;
          return value?.getFullYear() <= 2099;
        }),

      securityCode: yup
        .number()
        .required("O código de segurança é obrigatório")
        .typeError("Digite apenas números no código de segurança")
        .test("numberlength", "O código de segurança deve ter 3 digitos", (value) => {
          if (!value) return true;
          return value.toString().length === 3;
        })
        .positive("Apenas números positivos")
        .integer("Apenas números inteiros"),
    }),
  }),
});

export default validationSchema;
