import * as yup from "yup";
import validateCNPJ from "utils/validateCNPJ";

const validationSchema = yup.object().shape({
  cnpj: yup
    .string()
    .required("CNPJ é obrigatório")
    .typeError("CNPJ é obrigatório")
    .test("cnpj", "CNPJ inválido", (cnpj) => validateCNPJ(cnpj || "")),

  name: yup.string().required("Razão social é obrigatório").typeError("Razao social é obrigatório"),

  chosenSubd: yup
    .string()
    .required("Subdomínio é obrigatório")
    .typeError("Subdomínio é obrigatório")
    .matches(/^[a-z\s]+$/, "O subdomínio deve conter apenas letras minúsculas"),

  backoffice: yup
    .string()
    .typeError("Sistema para contabilidade é obrigatório")
    .required("Sistema para contabilidade é obrigatório"),

  systemName: yup.string().when("backoffice", {
    is: "Outro",
    then: yup
      .string()
      .required("Nome do sistema é obrigatório")
      .typeError("Nome do sistema é obrigatório"),
    otherwise: yup.string().notRequired().nullable(),
  }),

  street: yup.string().required("O logadouro é obrigatório").typeError("O logadouro é obrigatório"),
  neighborhood: yup.string().required("O bairro é obrigatório").typeError("O bairro é obrigatório"),
  zipCode: yup
    .string()
    .required("O CEP é obrigatório")
    .typeError("O CEP é obrigatório")
    .test("cep validation", "Digite um CEP válido", (value) => {
      if (!value) return true;
      return value.replace(/\D/g, "").length === 8;
    }),
  city: yup.string().required("O município é obrigatório").typeError("O município é obrigatório"),
  complement: yup.string().nullable(),
  state: yup.string().required("O estado é obrigatório").typeError("O estado é obrigatório"),
  streetNumber: yup
    .number()
    .typeError("Digite apenas números")
    .positive("Digite apenas números positivos")
    .integer("Digite apenas números inteiros"),
});

export default validationSchema;
