import { FC, useState } from "react";

import { KeyboardArrowUp } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  Stack,
  Select,
  MenuItem,
  Box,
  Typography,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import { DateTime } from "luxon";
import { Chat, StatusRequest } from "types/Mailbox";
import { LoadingButton } from "@mui/lab";
import useMutationOpenTicket from "../hooks/useMutationOpenTicket";
import useMutationCloseTicket from "../hooks/useMutationCloseTicket";

interface StatusInformationProps {
  title: string;
  content: string;
}

const StatusInformation = ({ title, content }: StatusInformationProps) => (
  <Box flex={1}>
    <Typography variant="body2" fontWeight={500}>
      {title}
    </Typography>
    <Typography variant="body1" color="text.secondary">
      {content}
    </Typography>
  </Box>
);

interface ChatStatusProps {
  ticket: Chat;
  onOpenOrCloseTicket: () => void;
  companyId: number;
}

const ChatStatus: FC<ChatStatusProps> = ({ ticket, onOpenOrCloseTicket, companyId }) => {
  const { mutateAsync: mutateOpenTicket, isLoading: isLoadingOpen } = useMutationOpenTicket();
  const { mutateAsync: mutateCloseTicket, isLoading: isLoadingClose } = useMutationCloseTicket();
  const isLoading = isLoadingOpen || isLoadingClose;

  const [collapsed, setCollapsed] = useState(true);
  const [action, setAction] = useState<null | StatusRequest>(null);

  const handleOpenOrCloseTicket = async () => {
    if (!action) return;

    if (action === "closed") {
      await mutateCloseTicket({
        companyId,
        ticketId: ticket.id,
      });
    }

    if (action === "open") {
      await mutateOpenTicket({
        companyId,
        ticketId: ticket.id,
      });
    }

    setAction(null);
    onOpenOrCloseTicket();
  };

  return (
    <>
      <Box position="relative">
        <Collapse in={collapsed} sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Stack direction="row" gap={2} py={2} px={4}>
            <FormControl sx={{ flex: 1 }}>
              <InputLabel>Situação</InputLabel>
              <Select
                value={ticket.isClosed ? "closed" : "open"}
                onChange={(e) => setAction(e.target.value as StatusRequest)}
                label="Situação"
              >
                <MenuItem value="open">Aberta</MenuItem>
                <MenuItem value="closed">Encerrada</MenuItem>
              </Select>
            </FormControl>

            <StatusInformation
              title="Criado em:"
              content={DateTime.fromISO(ticket.created).toFormat("dd/MM/yy HH:mm")}
            />
            <StatusInformation
              title="Atualizado em:"
              content={DateTime.fromISO(ticket.updated).toFormat("dd/MM/yy HH:mm")}
            />
            <StatusInformation title="Cliente:" content={ticket.authorName} />
          </Stack>
        </Collapse>

        <IconButton
          onClick={() => setCollapsed((prev) => !prev)}
          sx={{
            position: "absolute",
            top: -24,
            right: 16,
            bgcolor: "primary.main",
            color: "text.primary",
            "&:hover": {
              bgcolor: "primary.main",
            },
          }}
        >
          <KeyboardArrowUp
            sx={{
              transform: `rotate(${collapsed ? "180deg" : "0deg"})`,
              transition: "transform 300ms ease",
            }}
          />
        </IconButton>
      </Box>

      <Dialog
        open={!!action}
        onClose={() => {
          if (isLoading) return;
          setAction(null);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {action === "open" ? "Reabrir solicitação" : "Encerrar solicitação"}
        </DialogTitle>

        <DialogContent>
          {action === "open"
            ? "Deseja reabrir esta solicitação?"
            : "Deseja encerrar esta solicitação?"}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" disabled={isLoading} onClick={() => setAction(null)}>
            Não
          </Button>
          <LoadingButton onClick={handleOpenOrCloseTicket} loading={isLoading} variant="contained">
            Sim
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatStatus;
