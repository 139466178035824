import { useCallback } from "react";
import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCompanyBankAccounts } from "services/Requests/companies";
import { CompanyBankAccounts } from "types/Company";

const useQueryGetCompanyBankAccounts = (companyToken: string, onlyIntegrated = false) => {
  const select = useCallback(
    (data: CompanyBankAccounts[]) => {
      if (onlyIntegrated) {
        return data.filter((account) => account.integratorAccountId !== null);
      }
      return data;
    },
    [onlyIntegrated],
  );

  const query = useQuery(
    [queryKeys.companyBankAccounts, companyToken],
    () => getCompanyBankAccounts({ companyToken }),
    {
      select,
    },
  );
  return query;
};

export default useQueryGetCompanyBankAccounts;
