import { FC, useState } from "react";

import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import SortableTableHead from "components/SortableTableHead";

import { CompanyPartner } from "types/Company";
import { Order } from "types/SemanticTypes";
import stableSort from "utils/stableSort";
import comparatorSortByProperty from "utils/comparatorSortByProperty";

import PartnersTableItem from "./PartnersTableItem";

type CompanyPartnerKeys = keyof CompanyPartner;

interface HeadCells {
  label: string;
  id: CompanyPartnerKeys;
  sortable: boolean;
}

const headCells: HeadCells[] = [
  {
    id: "name",
    label: "Nome",
    sortable: true,
  },
  {
    id: "cpfcnpj",
    label: "CPF / CNPJ",
    sortable: false,
  },
];

interface PartnersTableProps {
  companyPartners: CompanyPartner[];
  isQueryError: boolean;
  onEdit: (partner: CompanyPartner) => void;
  onDelete: (partner: CompanyPartner) => void;
  onConsult: (partner: CompanyPartner) => void;
}

const PartnersTable: FC<PartnersTableProps> = ({
  companyPartners,
  isQueryError,
  onEdit,
  onDelete,
  onConsult,
}) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<CompanyPartnerKeys>("name");

  const sortHandler = (property: CompanyPartnerKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table size="small">
        <SortableTableHead
          headCells={headCells}
          onRequestSort={(e, property) => sortHandler(property)}
          order={order}
          orderBy={orderBy}
        >
          <TableCell align="center"></TableCell>
        </SortableTableHead>

        <TableBody>
          {companyPartners.length === 0 && (
            <TableRow>
              <TableCell colSpan={headCells.length + 1}>Sócios não foram cadastrados</TableCell>
            </TableRow>
          )}
          {isQueryError && (
            <TableRow>
              <TableCell colSpan={headCells.length + 1}>
                Ocorreu um error ao buscar seus sócios
              </TableCell>
            </TableRow>
          )}

          {stableSort(companyPartners, comparatorSortByProperty(order, orderBy)).map((partner) => (
            <PartnersTableItem
              partner={partner}
              key={partner.id}
              onEdit={() => onEdit(partner)}
              onDelete={() => onDelete(partner)}
              onConsult={() => onConsult(partner)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PartnersTable;
