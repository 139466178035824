import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { deleteCorrection } from "services/Requests/stockControl";

const useMutationDeleteCorrection = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteCorrection, {
    onSuccess(_, { companyToken, movementId }) {
      toast.success("Carta deletada com sucesso");
      queryClient.invalidateQueries([queryKeys.invoiceCorrections, companyToken, movementId]);
    },
  });

  return mutation;
};

export default useMutationDeleteCorrection;
