import { FC } from "react";
import { Control, Controller } from "react-hook-form";

import { DatePicker } from "@mui/x-date-pickers";
import { Box, TextField } from "@mui/material";
import { DateTime } from "luxon";

import { TransferAccountsForm } from ".";
import CurrencyInput from "components/CurrencyInput";
import BankAccountSelect from "pages/Home/components/BankAccountSelect";
import CategorySelect from "pages/Home/components/CategorySelect";

interface DestinyFormProps {
  companyToken: string;
  control: Control<TransferAccountsForm>;
}

const DestinyForm: FC<DestinyFormProps> = ({ control, companyToken }) => {
  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
      <Controller
        control={control}
        name="accountDestination"
        render={({ fieldState, field }) => (
          <BankAccountSelect
            sx={{ minWidth: 250 }}
            label="Conta Destino"
            companyToken={companyToken}
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="amount"
        render={({ field, fieldState }) => (
          <CurrencyInput
            {...field}
            inputProps={{
              label: "Valor R$",
              error: !!fieldState.error?.message,
              helperText: fieldState.error?.message,
            }}
          />
        )}
      />

      <Controller
        name={"date"}
        control={control}
        render={({ field, fieldState }) => (
          <DatePicker
            label="Data"
            inputFormat="dd/MM/yyyy"
            minDate={DateTime.local().minus({ year: 10 })}
            disableFuture
            {...field}
            allowSameDateSelection
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={fieldState.error?.message}
                error={params.error || !!fieldState.error?.message}
              />
            )}
          />
        )}
      />

      <Controller
        control={control}
        name={"globalCategoryId"}
        render={({ fieldState, field }) => (
          <CategorySelect
            label="Categoria principal"
            companyToken={companyToken}
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </Box>
  );
};

export default DestinyForm;
