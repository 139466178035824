import { FinancialSummaryByTypeDTO } from "types/Company";

export const ClientDRETable: FinancialSummaryByTypeDTO[] = [
  {
    categoriaSintetica: "Receita Operacional",
    isCategoriaSinteticaVisible: true,
    orderFinReport: 1,
    summary: [
      {
        categoryName: "Vendas de Serviços (analítica)",
        globalCategoryId: 123,
        isVisibleInFinancialReport: true,
        months: [
          {
            month: 1,
            totalMonth: 100,
          },
          {
            month: 2,
            totalMonth: 100,
          },
          {
            month: 3,
            totalMonth: 100,
          },
          {
            month: 4,
            totalMonth: 100,
          },
          {
            month: 5,
            totalMonth: 100,
          },
          {
            month: 6,
            totalMonth: 100,
          },
          {
            month: 7,
            totalMonth: 100,
          },
          {
            month: 8,
            totalMonth: 100,
          },
          {
            month: 9,
            totalMonth: 100,
          },
          {
            month: 10,
            totalMonth: 100,
          },
          {
            month: 11,
            totalMonth: 100,
          },
          {
            month: 12,
            totalMonth: 100,
          },
        ],
        order: 1,
        totalYear: 1200,
      },
      {
        categoryName: "Vendas de Mercadorias (analítica)",
        globalCategoryId: 123,
        isVisibleInFinancialReport: true,
        months: [
          {
            month: 1,
            totalMonth: 100,
          },
          {
            month: 2,
            totalMonth: 100,
          },
          {
            month: 3,
            totalMonth: 100,
          },
          {
            month: 4,
            totalMonth: 100,
          },
          {
            month: 5,
            totalMonth: 100,
          },
          {
            month: 6,
            totalMonth: 100,
          },
          {
            month: 7,
            totalMonth: 100,
          },
          {
            month: 8,
            totalMonth: 100,
          },
          {
            month: 9,
            totalMonth: 100,
          },
          {
            month: 10,
            totalMonth: 100,
          },
          {
            month: 11,
            totalMonth: 100,
          },
          {
            month: 12,
            totalMonth: 100,
          },
        ],
        order: 1,
        totalYear: 3600,
      },
    ],
    totalMonths: null,
    totalName: "total",
    type: null,
  },
  {
    categoriaSintetica: "Deduções da Receita",
    isCategoriaSinteticaVisible: true,
    orderFinReport: 1,
    summary: [
      {
        categoryName: "Impostos sobre vendas (analítica)",
        globalCategoryId: 123,
        isVisibleInFinancialReport: true,
        months: [
          {
            month: 1,
            totalMonth: 10,
          },
          {
            month: 2,
            totalMonth: 10,
          },
          {
            month: 3,
            totalMonth: 10,
          },
          {
            month: 4,
            totalMonth: 10,
          },
          {
            month: 5,
            totalMonth: 10,
          },
          {
            month: 6,
            totalMonth: 10,
          },
          {
            month: 7,
            totalMonth: 10,
          },
          {
            month: 8,
            totalMonth: 10,
          },
          {
            month: 9,
            totalMonth: 10,
          },
          {
            month: 10,
            totalMonth: 10,
          },
          {
            month: 11,
            totalMonth: 10,
          },
          {
            month: 12,
            totalMonth: 10,
          },
        ],
        order: 1,
        totalYear: 120,
      },
    ],
    totalMonths: null,
    totalName: "total",
    type: null,
  },
  {
    categoriaSintetica: "RECEITA OPERACIONAL LIQUIDA",
    isCategoriaSinteticaVisible: true,
    orderFinReport: 1,
    summary: [],
    totalMonths: null,
    totalName: "total",
    type: null,
  },
  {
    categoriaSintetica: "Custo Operacional",
    isCategoriaSinteticaVisible: true,
    orderFinReport: 1,
    summary: [
      {
        categoryName: "Custo com serviços prestados",
        globalCategoryId: 123,
        isVisibleInFinancialReport: true,
        months: [
          {
            month: 1,
            totalMonth: 30,
          },
          {
            month: 2,
            totalMonth: 30,
          },
          {
            month: 3,
            totalMonth: 30,
          },
          {
            month: 4,
            totalMonth: 30,
          },
          {
            month: 5,
            totalMonth: 30,
          },
          {
            month: 6,
            totalMonth: 30,
          },
          {
            month: 7,
            totalMonth: 30,
          },
          {
            month: 8,
            totalMonth: 30,
          },
          {
            month: 9,
            totalMonth: 30,
          },
          {
            month: 10,
            totalMonth: 30,
          },
          {
            month: 11,
            totalMonth: 30,
          },
          {
            month: 12,
            totalMonth: 30,
          },
        ],
        order: 1,
        totalYear: 360,
      },
      {
        categoryName: "Custo com Produtos Produzidos",
        globalCategoryId: 123,
        isVisibleInFinancialReport: true,
        months: [
          {
            month: 1,
            totalMonth: 40,
          },
          {
            month: 2,
            totalMonth: 40,
          },
          {
            month: 3,
            totalMonth: 40,
          },
          {
            month: 4,
            totalMonth: 40,
          },
          {
            month: 5,
            totalMonth: 40,
          },
          {
            month: 6,
            totalMonth: 40,
          },
          {
            month: 7,
            totalMonth: 40,
          },
          {
            month: 8,
            totalMonth: 40,
          },
          {
            month: 9,
            totalMonth: 40,
          },
          {
            month: 10,
            totalMonth: 40,
          },
          {
            month: 11,
            totalMonth: 40,
          },
          {
            month: 12,
            totalMonth: 40,
          },
        ],
        order: 1,
        totalYear: 480,
      },
    ],
    totalMonths: null,
    totalName: "total",
    type: null,
  },
  {
    categoriaSintetica: "LUCRO OPERACIONAL BRUTO",
    isCategoriaSinteticaVisible: true,
    orderFinReport: 1,
    summary: [],
    totalMonths: null,
    totalName: "total",
    type: null,
  },
  {
    categoriaSintetica: "Despesas Operacionais e Admnistrativas",
    isCategoriaSinteticaVisible: true,
    orderFinReport: 1,
    summary: [
      {
        categoryName: "Despesa Com Venda das Mercadorias",
        globalCategoryId: 123,
        isVisibleInFinancialReport: true,
        months: [
          {
            month: 1,
            totalMonth: 8,
          },
          {
            month: 2,
            totalMonth: 8,
          },
          {
            month: 3,
            totalMonth: 8,
          },
          {
            month: 4,
            totalMonth: 8,
          },
          {
            month: 5,
            totalMonth: 8,
          },
          {
            month: 6,
            totalMonth: 8,
          },
          {
            month: 7,
            totalMonth: 8,
          },
          {
            month: 8,
            totalMonth: 8,
          },
          {
            month: 9,
            totalMonth: 8,
          },
          {
            month: 10,
            totalMonth: 8,
          },
          {
            month: 11,
            totalMonth: 8,
          },
          {
            month: 12,
            totalMonth: 8,
          },
        ],
        order: 1,
        totalYear: 96,
      },
      {
        categoryName: "Despesa Com Prestações de Serviços",
        globalCategoryId: 123,
        isVisibleInFinancialReport: true,
        months: [
          {
            month: 1,
            totalMonth: 2,
          },
          {
            month: 2,
            totalMonth: 2,
          },
          {
            month: 3,
            totalMonth: 2,
          },
          {
            month: 4,
            totalMonth: 2,
          },
          {
            month: 5,
            totalMonth: 2,
          },
          {
            month: 6,
            totalMonth: 2,
          },
          {
            month: 7,
            totalMonth: 2,
          },
          {
            month: 8,
            totalMonth: 2,
          },
          {
            month: 9,
            totalMonth: 2,
          },
          {
            month: 10,
            totalMonth: 2,
          },
          {
            month: 11,
            totalMonth: 2,
          },
          {
            month: 12,
            totalMonth: 2,
          },
        ],
        order: 1,
        totalYear: 24,
      },
      {
        categoryName: "Despesa das Produções dos Produtos",
        globalCategoryId: 123,
        isVisibleInFinancialReport: true,
        months: [
          {
            month: 1,
            totalMonth: 5,
          },
          {
            month: 2,
            totalMonth: 5,
          },
          {
            month: 3,
            totalMonth: 5,
          },
          {
            month: 4,
            totalMonth: 5,
          },
          {
            month: 5,
            totalMonth: 5,
          },
          {
            month: 6,
            totalMonth: 5,
          },
          {
            month: 7,
            totalMonth: 5,
          },
          {
            month: 8,
            totalMonth: 5,
          },
          {
            month: 9,
            totalMonth: 5,
          },
          {
            month: 10,
            totalMonth: 5,
          },
          {
            month: 11,
            totalMonth: 5,
          },
          {
            month: 12,
            totalMonth: 5,
          },
        ],
        order: 1,
        totalYear: 60,
      },
      {
        categoryName: "Despesa Com Marketing",
        globalCategoryId: 123,
        isVisibleInFinancialReport: true,
        months: [
          {
            month: 1,
            totalMonth: 3,
          },
          {
            month: 2,
            totalMonth: 3,
          },
          {
            month: 3,
            totalMonth: 3,
          },
          {
            month: 4,
            totalMonth: 3,
          },
          {
            month: 5,
            totalMonth: 3,
          },
          {
            month: 6,
            totalMonth: 3,
          },
          {
            month: 7,
            totalMonth: 3,
          },
          {
            month: 8,
            totalMonth: 3,
          },
          {
            month: 9,
            totalMonth: 3,
          },
          {
            month: 10,
            totalMonth: 3,
          },
          {
            month: 11,
            totalMonth: 3,
          },
          {
            month: 12,
            totalMonth: 3,
          },
        ],
        order: 1,
        totalYear: 36,
      },
    ],
    totalMonths: null,
    totalName: "total",
    type: null,
  },
];
