import { FC, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Link,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import { UserBill } from "types/Billing";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { LoadingButton } from "@mui/lab";
import useMutationCancelBillByAccountant from "../hooks/useMutationCancelBillByAccountant";
import { useAuth } from "context/Auth";

interface BillTableItemProps {
  userBill: UserBill;
  onEdit: () => void;
}

const BillTableItem: FC<BillTableItemProps> = ({ userBill, onEdit }) => {
  const {
    user: { company },
  } = useAuth();
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  const { isLoading, mutateAsync } = useMutationCancelBillByAccountant();
  const [confirmationCancelBill, setConfirmationCancelBill] = useState(false);

  const handleCloseConfirmationCancel = () => {
    if (isLoading) return;
    setConfirmationCancelBill(false);
  };

  const handleCancelBill = async () => {
    if (!company) return;

    await mutateAsync({
      companyToken: company.token,
      id: userBill.id,
    });
    setConfirmationCancelBill(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>{userBill.issueDateDesc}</TableCell>
        <TableCell>{userBill.dueDateDesc}</TableCell>
        <TableCell>{userBill.payDateDesc}</TableCell>
        <TableCell>{userBill.name}</TableCell>
        <TableCell>{userBill.description}</TableCell>
        <TableCell>{userBill.paymentMethod}</TableCell>
        <TableCell>{userBill.status}</TableCell>
        <TableCell>{formatCurrencyBRL(userBill.value)}</TableCell>
        <TableCell>
          <IconButton size="small" onClick={(e) => setAnchorElMenu(e.currentTarget)}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      <Menu
        anchorEl={anchorElMenu}
        open={menuIsOpen}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ p: 0 }}>
          <Link
            href={userBill.detailsUrl}
            target="_blank"
            sx={{ textDecoration: "none", px: 2, py: 0.5, color: "text.primary" }}
          >
            Visualizar fatura
          </Link>
        </MenuItem>
        {userBill.status === "Em aberto" && <MenuItem onClick={onEdit}>Editar</MenuItem>}
        {userBill.status === "Em aberto" && (
          <MenuItem onClick={() => setConfirmationCancelBill(true)} disabled={!company}>
            Cancelar
          </MenuItem>
        )}
      </Menu>

      <Dialog
        open={confirmationCancelBill}
        onClose={handleCloseConfirmationCancel}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Cancelar fatura</DialogTitle>
        <DialogContent>Tem certeza que deseja cancelar essa fatura?</DialogContent>
        <DialogActions>
          <Button disabled={isLoading} variant="outlined" onClick={handleCloseConfirmationCancel}>
            Não
          </Button>
          <LoadingButton loading={isLoading} variant="contained" onClick={handleCancelBill}>
            Sim
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BillTableItem;
