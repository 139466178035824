import { FC, useState } from "react";
import useQueryCompanyDocumentsDrive from "../../hooks/useQueryCompanyDocumentsDrive";
import useMutationCreateFolderDrive from "../../hooks/useMutationCreateFolderDrive";

import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  LinearProgress,
  TextField,
} from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";

interface DialogNewFolderProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  parentId?: string;
}

const DialogNewFolder: FC<DialogNewFolderProps> = ({
  parentId = "",
  companyToken,
  onClose,
  ...props
}) => {
  const [alert, setAlert] = useState<string | null>(null);
  const [folderName, setFolderName] = useState("");

  const { mutateAsync, isLoading } = useMutationCreateFolderDrive();
  const { data: companyDocumentsDrive, isLoading: isLoadingDocuments } =
    useQueryCompanyDocumentsDrive({
      companyToken,
    });

  const handleClose = () => {
    if (isLoading) return;
    setFolderName("");
    setAlert(null);
    onClose();
  };

  const handleSubmit = async () => {
    if (!companyDocumentsDrive) return;

    if (!folderName.trim()) {
      setAlert("O nome da pasta é obrigatório");
      return;
    }

    // eslint-disable-next-line quotes
    const invalidCharacters = ["\\", "/", ":", "*", "?", '"', "<", ">", "|"];
    const splittedFolderName = folderName.split("");
    const hasInvalidCharacter = splittedFolderName.some((s) => invalidCharacters.includes(s));

    if (hasInvalidCharacter) {
      setAlert(`Caracteres inválidos: ${invalidCharacters.join("")}`);
      return;
    }

    const allFolderNamesInLowerCase = companyDocumentsDrive.map((d) => d.name.toLowerCase().trim());
    const folderNameExist = allFolderNamesInLowerCase.includes(folderName.trim().toLowerCase());
    if (folderNameExist) {
      setAlert("Não é permitido pasta com mesmo nome");
      return;
    }

    setAlert(null);
    await mutateAsync({
      companyToken,
      fileName: folderName.trim(),
      parentId,
    });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>Criar pasta</DialogTitleWithCloseButton>
      {isLoadingDocuments && <LinearProgress />}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <DialogContent>
          <Collapse
            in={!!alert}
            timeout={{
              enter: 200,
              exit: 0,
            }}
          >
            <Alert severity="warning" onClose={() => setAlert(null)} sx={{ mb: 2 }}>
              {alert}
            </Alert>
          </Collapse>

          <TextField
            fullWidth
            name={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            label="Nome da pasta"
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            Criar
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose} disabled={isLoading}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogNewFolder;
