import { FC } from "react";
import useQueryGetMessage from "../hooks/useQueryGetMessage";

import { Box, CircularProgress, Grow, Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import Error from "@mui/icons-material/Error";

import EmailMessageForm from "./EmailMessageForm";

interface EmailMessageProps {
  companyToken: string;
  idMessage: number;
}

const EmailMessage: FC<EmailMessageProps> = ({ companyToken, idMessage }) => {
  const { data, isLoading, isError } = useQueryGetMessage(companyToken, idMessage);

  return (
    <Box flex={1} p={4}>
      {isError && (
        <Stack height="100%" alignItems="center" justifyContent="center" gap={1}>
          <Error fontSize="large" sx={{ color: red[200] }} />
          <Typography sx={{ width: 320, lineHeight: 1.2 }} textAlign="center">
            Ocorreu um error em tentar acessar informações desse modelo de email
          </Typography>
        </Stack>
      )}

      {isLoading && (
        <Stack minHeight={500} height="100%" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {data && (
        <Grow in>
          <Box>
            <EmailMessageForm message={data} companyToken={companyToken} />
          </Box>
        </Grow>
      )}
    </Box>
  );
};

export default EmailMessage;
