import { DefaultValues } from "react-hook-form";
import {
  FullMovement,
  FullMovementItem,
  FullMovementPayment,
  InvoiceConfig,
  TypeInvoice,
} from "types/StockControl";
import {
  MovementForm,
  MovementFormItem,
  MovementFormPayment,
  SendInvoiceType,
} from "./InterfacesForm";
import { DateTime } from "luxon";
import { defaultMovementItem } from "../Fields/ItemsController";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

export const defaultMovementPayment: DefaultValues<MovementFormPayment> = {
  paymentId: undefined,
  value: formatCurrencyBRL(0),
  paymentMode: "",
};

const getDefaultValuesMovementPayment = (
  payments: FullMovementPayment[],
): DefaultValues<MovementFormPayment[]> => {
  return payments.map(
    (payment): DefaultValues<MovementFormPayment> => ({
      paymentId: payment.id,
      value: formatCurrencyBRL(payment.value),
      paymentMode: payment.paymentMode,
    }),
  );
};

const getDefaultValuesMovementItem = (
  items: FullMovementItem[],
): DefaultValues<MovementFormItem[]> => {
  return items.map(
    (item): DefaultValues<MovementFormItem> => ({
      cfop: item.cfop,
      cofins: item.cofins,
      cofinsCst: item.cofinsCst,
      discount: formatCurrencyBRL(item.discount || 0),
      icms: item.icms,
      icmsCst: item.icmsCst,
      ipi: item.ipi,
      ipiCst: item.ipiCst,
      ncm: item.ncm || "",
      notes: item.notes,
      otherExpenses: formatCurrencyBRL(item.otherExpenses),
      pis: item.pis,
      pisCst: item.pisCst,
      productId: item.productId,
      quantity: item.quantity,
      unitValue: formatCurrencyBRL(item.unitValue),
      itemId: item.id,
      taxBenefit: item.taxBenefit ?? "",
      taxSource: item?.taxSource ?? "",
      municipalTax: item?.municipalTax ?? null,
      federalTax: item?.federalTax ?? null,
      stateTax: item?.stateTax ?? null,
    }),
  );
};

const typeInvoiceToSendInvoice: Record<TypeInvoice, SendInvoiceType> = {
  NFCe: "2",
  NFe: "1",
};

const ISODateToJSDate = (ISODate?: string) => {
  if (!ISODate) return DateTime.local().toJSDate();
  return DateTime.fromISO(ISODate).toJSDate();
};

type GetDefaultValuesForm = (
  movement?: FullMovement,
  invoiceConfig?: InvoiceConfig,
) => DefaultValues<MovementForm>;

const getDefaultValuesForm: GetDefaultValuesForm = (movement, invoiceConfig) => ({
  operationType: movement?.operationType || "Entrada",
  participantId: movement?.participantId,
  sendInvoice: movement?.nfType ? typeInvoiceToSendInvoice?.[movement.nfType] ?? "0" : "0",
  nature: movement?.nature || "Compra",
  movementDate: ISODateToJSDate(movement?.movementDate),
  dueDate: ISODateToJSDate(movement?.dueDate),
  notes: movement?.notes || invoiceConfig?.description || "",
  presenceIndicator: movement?.presenceIndicator,
  finalConsumer: movement?.finalConsumer?.toString() as "0" | "1" | undefined,
  generateTransaction: movement ? movement.generateTransaction : true,
  items: movement ? getDefaultValuesMovementItem(movement.items) : [defaultMovementItem],
  payment: movement?.payments
    ? getDefaultValuesMovementPayment(movement.payments)
    : [defaultMovementPayment],
  paymentType: movement?.paymentType ?? "0",
  shipping: {
    carrierAddress: movement?.shipping?.carrierAddress || undefined,
    carrierCity: movement?.shipping?.carrierCity || undefined,
    carrierCpfCnpj: movement?.shipping?.carrierCpfCnpj || "",
    carrierName: movement?.shipping?.carrierName || "",
    carrierState: movement?.shipping?.carrierState || "",
    carrierStateRegistration: movement?.shipping?.carrierStateRegistration || "",
    carrierUsesIssuerData: movement?.shipping?.carrierUsesIssuerData || undefined,
    deliveryAddress: movement?.shipping?.deliveryAddress as "0" | "1" | "2" | undefined,
    grossWeight: movement?.shipping?.grossWeight,
    mark: movement?.shipping?.mark || undefined,
    modality: movement?.shipping?.modality,
    netWeight: movement?.shipping?.netWeight,
    numeration: movement?.shipping?.numeration || undefined,
    quantity: Number(movement?.shipping?.quantity) || 0,
    recipientCity: movement?.shipping?.recipientCity,
    recipientComplement: movement?.shipping?.recipientComplement || undefined,
    recipientCpfCnpj: movement?.shipping?.recipientCpfCnpj,
    recipientNeighborhood: movement?.shipping?.recipientNeighborhood || undefined,
    recipientNumber: Number(movement?.shipping?.recipientNumber) || undefined,
    recipientState: movement?.shipping?.recipientState || undefined,
    recipientStreet: movement?.shipping?.recipientStreet || undefined,
    recipientZipCode: movement?.shipping?.recipientZipCode || undefined,
    unityOfMeasure: movement?.shipping?.unityOfMeasure || undefined,
    value: formatCurrencyBRL(movement?.shipping?.value || 0),
  },
});

export default getDefaultValuesForm;
