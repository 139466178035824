import { FC } from "react";
import { useDashboardPeriod } from "./contexts/DashboardPeriod";

import { Box } from "@mui/material";
import FilterPeriod from "components/FilterPeriod";

const SelectPeriod: FC = () => {
  const { date, setDate } = useDashboardPeriod();

  return (
    <Box mb={2}>
      <FilterPeriod
        endDate={date.end}
        startDate={date.start}
        onChangeDates={(values) => setDate(values)}
      />
    </Box>
  );
};

export default SelectPeriod;
