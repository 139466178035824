import { FC, memo, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from "@mui/material";

import { CompanyDocumentDrive, CompanyDocumentsDrive } from "types/Company";
import { Order } from "types/SemanticTypes";

import DocumentDriveTableItem from "./DocumentDriveTableItem";
import SortableTableHead from "components/SortableTableHead";
import stableSort from "utils/stableSort";
import comparatorSortByProperty from "utils/comparatorSortByProperty";

type CompanyDocumentDriveKeys = keyof CompanyDocumentDrive;

interface HeadCells {
  label: string;
  id: CompanyDocumentDriveKeys;
  sortable: boolean;
}

const headCells: HeadCells[] = [
  {
    id: "name",
    label: "Nome",
    sortable: true,
  },
  {
    id: "typeNormalize",
    label: "Tipo",
    sortable: true,
  },
  {
    id: "date",
    label: "Data de Criação",
    sortable: true,
  },
  {
    id: "referenceDate",
    label: "Competência",
    sortable: true,
  },
  {
    id: "dueDate",
    label: "Vencimento",
    sortable: true,
  },
  {
    id: "value",
    label: "Valor (R$)",
    sortable: true,
  },
  {
    id: "rights",
    label: "Permissão",
    sortable: false,
  },
];

interface DocumentsDriveTableProps {
  companyDocumentsDrive: CompanyDocumentsDrive;
}

const DocumentsDriveTable: FC<DocumentsDriveTableProps> = ({ companyDocumentsDrive }) => {
  const isDesktop = useMediaQuery("(min-width:998px)");

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<CompanyDocumentDriveKeys>("name");

  const sortHandler = (property: CompanyDocumentDriveKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table size="small">
        <SortableTableHead
          headCells={isDesktop ? headCells : [...headCells].slice(0, 2)}
          onRequestSort={(e, property) => sortHandler(property)}
          order={order}
          orderBy={orderBy}
        >
          <TableCell align="right"></TableCell>
        </SortableTableHead>

        <TableBody>
          {companyDocumentsDrive.length === 0 && (
            <TableRow>
              <TableCell colSpan={headCells.length + 1}>Não há arquivos</TableCell>
            </TableRow>
          )}

          {stableSort(companyDocumentsDrive, comparatorSortByProperty(order, orderBy)).map(
            (documentDrive) => (
              <DocumentDriveTableItem key={documentDrive.id} documentDrive={documentDrive} />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(DocumentsDriveTable);
