import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCompanyInfo } from "../../../context/CompanyInfo";
import useForgotPassword from "./hooks/useForgotPassword";

import Head from "../../../components/Head";
import { Button, Stack, Typography, Box, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationScheme";
import getSubdomain from "../../../utils/getSubdomain";
import AuthWrapper from "../AuthWrapper";

const Forgot: FC = () => {
  const { typeSubdomain } = useCompanyInfo();
  const navigate = useNavigate();
  const { mutate, isLoading } = useForgotPassword();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
    mode: "onTouched",
  });

  const onSubmit = handleSubmit((data) => {
    mutate({ email: data.email });
  });

  useEffect(() => {
    if (typeSubdomain === "admin") {
      navigate("/login");
    }
  }, [typeSubdomain]);

  return (
    <AuthWrapper>
      <Head title={`Esqueci minha senha | ${getSubdomain()}`} />

      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        Esqueci minha senha
      </Typography>
      <Button
        startIcon={<KeyboardBackspaceIcon />}
        size="small"
        sx={{ mt: 1 }}
        href="/login"
        onClick={(e) => {
          e.preventDefault();
          navigate("/login");
        }}
      >
        Voltar para o login
      </Button>

      <Box component="form" mt={4} onSubmit={onSubmit}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Digite seu endereço de email. Você receberá uma nova senha via email.
        </Typography>

        <Stack gap={2} width="100%">
          <Controller
            name="email"
            control={control}
            render={({ fieldState, field }) => (
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>

        <LoadingButton
          loading={isLoading}
          sx={{ mt: 3 }}
          variant="contained"
          fullWidth
          disableElevation
          type="submit"
        >
          Obter nova senha
        </LoadingButton>
      </Box>
    </AuthWrapper>
  );
};

export default Forgot;
