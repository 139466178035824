import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getConfigurationsBlockReleases } from "services/Requests/configuration";
import getSubdomain from "utils/getSubdomain";

const useQueryConfigBlockRelease = () => {
  const subdomain = getSubdomain();

  const query = useQuery(queryKeys.configBlockRelease, () =>
    getConfigurationsBlockReleases({ subdomain }),
  );
  return query;
};

export default useQueryConfigBlockRelease;
