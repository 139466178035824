import { FC } from "react";
import { useExtract } from "../../context/Extract";
import useQueryGetTransactionsMatch from "../../hooks/useQueryGetTransactionsMatch";

import { CircularProgress, Stack } from "@mui/material";
import ControlReleases from "./ControlReleases";
import { ImportOFXTransaction } from "types/Company";

interface ReleaseTabItemProps {
  companyToken: string;
  extract: ImportOFXTransaction;
}

const ReleaseTabItem: FC<ReleaseTabItemProps> = ({ extract, companyToken }) => {
  const { isLoading, data: transactionsMatch } = useQueryGetTransactionsMatch({
    companyToken,
    id: extract.id,
    date: extract.date,
    value: extract.transactionValue,
  });

  return (
    <>
      {isLoading && (
        <Stack height={250} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {!isLoading && transactionsMatch && (
        <ControlReleases
          extract={extract}
          transactionMatch={transactionsMatch}
          companyToken={companyToken}
        />
      )}
    </>
  );
};

export default ReleaseTabItem;
