import { FC, useState, useEffect } from "react";
import useQueryGetAllChats from "../hooks/useQueryGetAllChats";

import {
  Avatar,
  Box,
  LinearProgress,
  ListItemIcon,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import ChatContent from "./ChatContent";
import { DateTime } from "luxon";
import { ChatInformation, StatusRequest } from "types/Mailbox";

interface ChatProps {
  statusRequest: StatusRequest;
  lastDays: number;
}

const Chat: FC<ChatProps> = ({ lastDays, statusRequest }) => {
  const { data, isLoading, isFetching } = useQueryGetAllChats(statusRequest, lastDays);
  const chats = data?.chats || [];

  const [selectedChat, setSelectedChat] = useState<undefined | ChatInformation>();

  useEffect(() => {
    setSelectedChat(undefined);
  }, [lastDays, statusRequest]);

  return (
    <Stack direction="row" boxShadow={2} height={700}>
      <Box
        sx={{
          borderRight: 1,
          flexShrink: 0,
          borderColor: "divider",
          height: "100%",
          width: 325,
          overflowY: "auto",
        }}
      >
        {isFetching && !isLoading && <LinearProgress />}

        {chats.length > 0 && (
          <MenuList
            sx={{
              position: "relative",
              borderRight: 1,
              borderTop: 1,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            {chats.map((chat) => (
              <MenuItem
                key={chat.id}
                sx={{ borderBottom: 1, borderColor: "divider" }}
                selected={selectedChat?.id === chat.id}
                onClick={() => setSelectedChat(chat)}
              >
                <ListItemIcon>
                  <Avatar sx={{ bgcolor: chat.color }}>{chat.initials}</Avatar>
                </ListItemIcon>
                <Stack pl={1.5} pr={3} overflow="hidden">
                  <Typography
                    fontWeight={500}
                    variant="body1"
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {chat.subject}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {chat.responsable}
                  </Typography>
                </Stack>

                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ fontSize: "0.675rem", position: "absolute", top: 4, right: 4 }}
                >
                  {DateTime.fromISO(chat.updated).toFormat("dd/MM/yy HH:mm")}
                </Typography>
              </MenuItem>
            ))}
          </MenuList>
        )}

        {chats.length === 0 && !isLoading && (
          <Stack alignItems="center" justifyContent="center" p={4}>
            <Typography sx={{ p: 2, bgcolor: grey[200] }} variant="body2" color="text.secondary">
              Nenhuma solicitação com o filtro selecionado
            </Typography>
          </Stack>
        )}

        {isLoading && (
          <Stack gap={0.5}>
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
          </Stack>
        )}
      </Box>

      {selectedChat ? (
        <ChatContent chat={selectedChat} onOpenOrCloseTicket={() => setSelectedChat(undefined)} />
      ) : (
        <Box mx="auto" my={4} px={4}>
          <Typography sx={{ p: 2, bgcolor: grey[200] }} variant="body2" color="text.secondary">
            Nenhuma mensagem selecionada
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default Chat;
