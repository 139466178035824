import { FC, useMemo, useEffect, ChangeEvent, useState, useRef } from "react";
import { useForm, DefaultValues } from "react-hook-form";
import useMutationCreateTransaction from "pages/Home/hooks/useMutationCreateTransaction";
import useMutationUploadReceipt from "pages/Home/hooks/useMutationUploadReceipt";
import useMutationUpdateTransaction from "pages/Home/hooks/useMutationUpdateTransaction";

import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import { LoadingButton } from "@mui/lab";
import { CloseRounded } from "@mui/icons-material";

import RadioForm from "../DialogReleaseCredit/components/RadioForm";
import DatesForm from "../DialogReleaseCredit/components/DatesForm";
import CategoryForm from "../DialogReleaseCredit/components/CategoryForm";
import PeriodicityForm from "../DialogReleaseCredit/components/PeriodicityForm";
import DescriptionForm from "./DescriptionForm";

import { DateTime } from "luxon";
import { CompanyParticipants, Transaction } from "types/Company";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { BASE_URL_DOWNLOAD_DOCUMENT } from "utils/constants";
import getPayload from "./utils/getPayload";
import { UpdateTransactionPayload } from "services/Requests/companies";

const today = DateTime.local().endOf("day").toJSDate();

export interface ReleaseDebitForm {
  isInvoice: boolean;
  paymentType: "receive" | "received";
  paymentOrigin: "participant" | "partner";
  paymentDate: Date | null;
  companyParticipant: CompanyParticipants | null | number;
  partner: number | null;
  dueDate: Date;
  referenceDate: Date;
  companyBankAccountId?: number;
  description: string;
  value: string;
  docNumber: string;
  descDocument: string;
  globalCategoryId: number;
  categoryId: number | null;
  periodicity: number;
  parcel: number | undefined;
  updateType?: number;
}

interface FormProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  companyToken: string;
  isEdit: boolean;
  transaction?: Transaction;
}

const Form: FC<FormProps> = ({ isEdit, companyToken, onClose, transaction, ...props }) => {
  const firstRender = useRef(true);
  const isMobile = useMediaQuery("(max-width:600px)");

  const [files, setFiles] = useState<File[]>([]);
  const [keepDialog, setKeepDialog] = useState(false);

  const mutationCreate = useMutationCreateTransaction();
  const mutationUpdate = useMutationUpdateTransaction();

  const mutateAsync = isEdit ? mutationUpdate.mutateAsync : mutationCreate.mutateAsync;
  const error = isEdit ? mutationUpdate.error : mutationCreate.error;
  console.log(error?.response?.data.message);
  const isError = isEdit ? mutationUpdate.isError : mutationCreate.isError;
  const editRecurrence =
    isEdit && transaction && (transaction.parcel > 0 || transaction.periodicity > 0);

  const { mutateAsync: mutateUpload } = useMutationUploadReceipt();

  const defaultValues = useMemo<DefaultValues<ReleaseDebitForm>>(
    () => ({
      isInvoice: false,
      paymentType: transaction ? (transaction.paymentDate ? "received" : "receive") : "receive",
      paymentOrigin: transaction
        ? transaction.partnerParticipantId
          ? "partner"
          : "participant"
        : "participant",
      paymentDate: transaction?.paymentDate
        ? DateTime.fromISO(transaction?.paymentDate).toJSDate()
        : null,
      companyParticipant: transaction?.companyParticipantId || undefined,
      partner: transaction?.partnerParticipantId || undefined,
      dueDate: transaction?.dueDate ? DateTime.fromISO(transaction?.dueDate).toJSDate() : today,
      referenceDate: transaction?.referenceDate
        ? DateTime.fromISO(transaction?.referenceDate).toJSDate()
        : today,
      companyBankAccountId: transaction?.companyBankAccountId || undefined,
      description: transaction?.description || "",
      value: transaction?.value !== undefined ? formatCurrencyBRL(transaction?.value) : "",
      docNumber: transaction?.docNumber || "",
      descDocument: transaction?.descDocument || "",
      globalCategoryId: transaction?.globalCategoryId || undefined,
      categoryId: transaction?.categoryId || null,
      periodicity: transaction?.periodicity || undefined,
      parcel: transaction?.parcel || undefined,
      updateType: editRecurrence ? 1 : undefined,
    }),
    [],
  );

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<ReleaseDebitForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const globalCategoryId = watch("globalCategoryId");

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setValue("categoryId", null);

    return () => {
      firstRender.current = true;
    };
  }, [globalCategoryId]);

  const handleClose = () => {
    if (isSubmitting) return;
    setFiles([]);
    reset(defaultValues);
    onClose();
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = e.target?.files;

    if (!files) return;
    setFiles(Array.from(files));
  };

  const onSubmit = handleSubmit(async (data) => {
    const payload = getPayload(isEdit, data, companyToken, transaction?.id);

    try {
      const { id } = await mutateAsync(payload as UpdateTransactionPayload);

      if (!id) return;

      const uploadFile = async () => {
        if (files.length > 0) {
          await mutateUpload({ companyToken, transactionId: id, files });
        }
      };

      await uploadFile();

      if (keepDialog) {
        setKeepDialog(false);
        setFiles([]);
        reset(defaultValues);
      } else {
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="md" fullWidth fullScreen={isMobile}>
      <DialogTitleWithCloseButton onClose={handleClose}>Débito</DialogTitleWithCloseButton>

      <Collapse in={isError}>
        <Alert severity="warning">
          {error?.response?.data.message || "Ocorreu um error inesperado"}
        </Alert>
      </Collapse>

      <form onSubmit={onSubmit}>
        <DialogContent sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
          <RadioForm control={control} transactionType="Debt" />
          <DatesForm control={control} companyToken={companyToken} transactionType="Debt" />
          <DescriptionForm control={control} companyToken={companyToken} />
          <p />
          <CategoryForm control={control} companyToken={companyToken} />
          <PeriodicityForm control={control} editRecurrence={editRecurrence} />

          <Box gridColumn="-1/1">
            <label style={{ display: "flex", alignItems: "center", width: "fit-content" }}>
              <input
                multiple
                type="file"
                style={{
                  display: "none",
                }}
                onChange={handleChangeFile}
              />
              <Button
                variant="contained"
                component="span"
                size="small"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                Anexar comprovante
              </Button>
            </label>

            {!!files.length && (
              <Box ml={2} mt={1}>
                {files.map((file, index) => (
                  <Stack direction="row" gap={1} alignItems="center" key={file.name}>
                    <Typography variant="body1">{file.name}</Typography>

                    <IconButton
                      size="small"
                      onClick={() => setFiles((prev) => prev.filter((_, i) => i !== index))}
                    >
                      <CloseRounded fontSize="small" />
                    </IconButton>
                  </Stack>
                ))}
              </Box>
            )}
          </Box>

          {transaction && transaction.documents.length > 0 && (
            <Box gridColumn="-1/1" mt={1} p={2} border={1} borderColor="divider">
              <Stack direction="row" justifyContent="space-between">
                <Typography>Comprovante(s)</Typography>

                <Box>
                  {transaction.documents.map((doc) => (
                    <Link
                      key={doc.downloadToken}
                      textAlign="right"
                      display="block"
                      target="_blank"
                      href={`${BASE_URL_DOWNLOAD_DOCUMENT}?id=${doc.downloadToken}&fullPath=/COMPROVANTES+DE+PAGAMENTO&preview=true`}
                    >
                      {doc.name}
                    </Link>
                  ))}
                </Box>
              </Stack>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" loading={isSubmitting} variant="contained">
            Salvar
          </LoadingButton>
          {!isEdit && (
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="outlined"
              onClick={() => setKeepDialog(true)}
            >
              Salvar e cadastrar outro
            </Button>
          )}
          <Button disabled={isSubmitting} onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Form;
