import { FC } from "react";
import useQueryGetTransaction from "pages/Home/pages/MonthClosure/hooks/useQueryGetTransaction";

import Form from "./Form";
import { CircularProgress, DialogProps, Modal, Stack } from "@mui/material";

interface DialogReleaseDebitProps extends Omit<DialogProps, "onClose"> {
  companyToken: string;
  onClose: () => void;
  transactionId?: number;
  isEdit?: boolean;
}

const DialogReleaseDebit: FC<DialogReleaseDebitProps> = ({
  companyToken,
  onClose,
  transactionId,
  isEdit = false,
  ...props
}) => {
  const { isLoading: isLoadingTransaction, data: transaction } = useQueryGetTransaction(
    companyToken,
    transactionId,
  );
  const hasData = transactionId ? !!transaction : true;

  return (
    <>
      {isLoadingTransaction && (
        <Modal open={props.open} onClose={onClose}>
          <Stack p={3} alignItems="center" justifyContent="center" height="100vh">
            <CircularProgress />
          </Stack>
        </Modal>
      )}

      {hasData && !isLoadingTransaction && props.open && (
        <Form
          isEdit={isEdit}
          onClose={onClose}
          {...props}
          companyToken={companyToken}
          transaction={transaction}
        />
      )}
    </>
  );
};

export default DialogReleaseDebit;
