import { FC, useMemo, useState } from "react";

import { Search } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import toast from "react-hot-toast";
import TableItem from "./TableItem";
import DialogService from "./DialogService";

import { useAuth } from "context/Auth";
import useDebounce from "hooks/useDebounce";
import { CustomService } from "types/ServiceAndPlan";

type UsersFilter = "Client" | "Accountant";

interface AdditionalServicesProps {
  services: CustomService[];
  filterServices: UsersFilter;
  onFilterServices: (type: UsersFilter) => void;
}

const AdditionalServices: FC<AdditionalServicesProps> = ({
  services,
  filterServices,
  onFilterServices,
}) => {
  const {
    user: { userInfo, company },
  } = useAuth();
  const [createServiceIsOpen, setCreateServiceIsOpen] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const search = useDebounce(searchValue, 200);

  const filterService = useMemo(() => {
    return services.filter((service) => {
      if (!search.trim()) return true;

      return service.name.toLowerCase().search(search.toLowerCase()) !== -1;
    });
  }, [search, services]);

  const handleOpenServiceModal = () => {
    if (!company) {
      toast.error("Empresa não localizada, não é possível editar");
      return;
    }
    setCreateServiceIsOpen(true);
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between">
        <Button variant="contained" startIcon={<Add />} onClick={handleOpenServiceModal}>
          Novo Serviço
        </Button>

        <TextField
          size="small"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            placeholder: "Buscar serviços",
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Typography variant="h6" fontWeight={600} sx={{ mt: 2 }}>
        Serviços adicionais
      </Typography>
      {!!search && (
        <Typography variant="body1" sx={{ fontSize: 14 }}>
          Procurando por
          <Typography variant="body1" display="inline" fontWeight={600}>
            {` "${search}"`}
          </Typography>
        </Typography>
      )}

      <Divider />

      {userInfo.userType === "Admin" && (
        <FormControl sx={{ mt: 1 }}>
          <RadioGroup
            row
            value={filterServices}
            onChange={(e) => onFilterServices(e.target.value as UsersFilter)}
          >
            <FormControlLabel value="Client" control={<Radio />} label="Clientes" />
            <FormControlLabel value="Accountant" control={<Radio />} label="Contadores" />
          </RadioGroup>
        </FormControl>
      )}

      <TableContainer sx={{ mt: 2, mb: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Criado em</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Status</TableCell>
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>

          <TableBody>
            {filterService.map((service) => (
              <TableItem key={service.id} service={service} />
            ))}
            {filterService.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>Nenhum serviço cadastrado</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {company && (
        <DialogService
          keepMounted={false}
          companyToken={company.token}
          open={createServiceIsOpen}
          onClose={() => setCreateServiceIsOpen(false)}
        />
      )}
    </Box>
  );
};

export default AdditionalServices;
