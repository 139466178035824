import { FC, useState } from "react";
import { useQueryClientBalance } from "../hooks/useQueryClientBalance";
import useMutationWithdraw3rdOsayk from "../hooks/useMutationWithdraw3rdOsayk";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { LoadingButton } from "@mui/lab";

import CurrencyInput, { formattedInput } from "components/CurrencyInput";
import { NormalizedClient } from "types/Client";
import toast from "react-hot-toast";

interface DialogSendBalanceProps extends DialogProps {
  client: NormalizedClient;
}

const DialogSendBalance: FC<DialogSendBalanceProps> = ({ onClose, client, ...props }) => {
  const { data, isLoading, isError } = useQueryClientBalance(client.subdomain, true);
  const { mutateAsync, isLoading: loadingWithdraw } = useMutationWithdraw3rdOsayk();
  const [value, setValue] = useState("");

  const handleClose = () => {
    if (loadingWithdraw) return;
    onClose?.({}, "backdropClick");
  };

  const handleWithdraw = async () => {
    const formattedValue = formattedInput(value);

    if (!formattedValue || formattedValue <= 0) {
      toast("Digite um valor válido", {
        icon: "⚠️",
      });
      return;
    }

    await mutateAsync({ amount: formattedValue, subdomain: client.subdomain });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Solicitar Transferência</DialogTitle>

      <DialogContent dividers>
        {isLoading && (
          <Stack alignItems="center" justifyContent="center" height={200}>
            <CircularProgress size={30} />
          </Stack>
        )}
        {isError && (
          <Typography variant="body1">
            Error ao carregar os dados de saldo do cliente, tente mais tarde ou entre em contado com
            o administrador do sistema.
          </Typography>
        )}

        {data && !isLoading && !isError && (
          <Stack gap={3}>
            <TextField
              value={client.companyName}
              label="Transferir de: "
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />

            <Stack direction="row" gap={3}>
              <TextField
                label="Saldo Disponível"
                value={data.balance_available_for_withdraw}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ flex: 1 }}
              />

              <CurrencyInput
                inputProps={{
                  label: "Valor para transferir",
                  sx: { flex: 1 },
                }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Stack>

            <TextField
              value="Osayk"
              label="Transferir para: "
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton
          loading={loadingWithdraw}
          startIcon={<CurrencyExchangeIcon />}
          variant="contained"
          onClick={handleWithdraw}
        >
          Transferir
        </LoadingButton>
        <Button
          startIcon={<Close />}
          disabled={loadingWithdraw}
          variant="outlined"
          onClick={handleClose}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSendBalance;
