import * as yup from "yup";
import { DateTime } from "luxon";
import { formattedInput } from "components/CurrencyInput";

const validationSchema = yup.object().shape({
  value: yup
    .string()
    .required("O valor é obrigatório")
    .test("verifyLessThanOne", "Digite um valor maior que 1", (value) => {
      if (!value) return true;
      const numberValue = formattedInput(value);

      return Number(numberValue) >= 1;
    }),
  dueDate: yup
    .string()
    .nullable()
    .test("verifyWrongFormatDate", "Digite uma data correta", (value) => {
      if (!value) return true;
      return DateTime.fromISO(value).isValid;
    })
    .test("dateIsNotFuture", "A data não deve ser no passado", (value) => {
      if (!value) return true;
      const date = DateTime.fromISO(value);
      return date.isValid && date >= DateTime.now().startOf("day");
    }),
  description: yup.string().required("A descrição é obrigatória"),
});

export default validationSchema;
