import { FC, useState, ReactNode, useCallback } from "react";

import { Stack, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

import CompanyAssignmentsTableItem from "./CompanyAssignmentsTableItem";
import SortableTableHead from "components/SortableTableHead";

import { CompanyAssignmentsResponse, CompanyAssignments } from "types/Admin";
import { Order } from "types/SemanticTypes";
import stableSort from "utils/stableSort";
import comparatorSortByProperty from "utils/comparatorSortByProperty";

type CompanyAssignmentsKeys = keyof CompanyAssignments;

interface HeadCells {
  label: ReactNode;
  id: CompanyAssignmentsKeys;
  sortable?: boolean;
}

const headCells: HeadCells[] = [
  {
    label: (
      <Stack alignItems="center" justifyContent="center">
        <EmailIcon fontSize="small" />
      </Stack>
    ),
    id: "totalMessagesUnread",
    sortable: false,
  },
  {
    label: "Código",
    id: "code",
  },
  {
    label: "Empresa",
    id: "name",
  },
  {
    label: "CNPJ / CPF",
    id: "cnpj",
  },
  {
    label: "Responsável",
    id: "ownerName",
  },
  {
    label: "Data",
    id: "createdOn",
  },
  {
    label: "Status",
    id: "status",
  },
  {
    label: "Contador Responsável",
    id: "employees",
    sortable: false,
  },
];

interface CompanyAssignmentsTableProps {
  companyAssignments: CompanyAssignmentsResponse;
  registrationStatus: number;
}

const CompanyAssignmentsTable: FC<CompanyAssignmentsTableProps> = ({
  companyAssignments,
  registrationStatus,
}) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<CompanyAssignmentsKeys>("name");

  const sortHandler = (property: CompanyAssignmentsKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getAccountantId = useCallback(
    (companyId: number) => {
      return companyAssignments.assignments.find((a) => a.companyId === companyId)?.accountantId;
    },
    [companyAssignments.assignments],
  );

  return (
    <TableContainer>
      <Table size="small">
        <SortableTableHead
          headCells={headCells}
          onRequestSort={(e, property) => sortHandler(property)}
          order={order}
          orderBy={orderBy}
        >
          <TableCell align="center"></TableCell>
        </SortableTableHead>

        <TableBody>
          {companyAssignments.companies.length === 0 && (
            <TableRow>
              <TableCell colSpan={headCells.length + 1}>Nenhuma empresa encontrada</TableCell>
            </TableRow>
          )}

          {stableSort(companyAssignments.companies, comparatorSortByProperty(order, orderBy)).map(
            (company) => (
              <CompanyAssignmentsTableItem
                key={company.code}
                companyAssignments={company}
                accountantId={getAccountantId(company.id)}
                accountants={companyAssignments.accountants}
                registrationStatus={registrationStatus}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompanyAssignmentsTable;
