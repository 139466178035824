import { FC } from "react";

import ControllerInput from "components/ControllerInput";
import MaskedInput from "react-text-mask";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

import useMutationUpdateProfile from "../../hooks/useMutationUpdateProfile";
import { useAuth } from "context/Auth";
import { Profile } from "types/User";

interface DialogUpdateUserData extends DialogProps {
  profile: Profile;
}

const DialogUpdateUserData: FC<DialogUpdateUserData> = ({ profile, onClose, ...props }) => {
  const {
    user: { company },
  } = useAuth();
  const { mutateAsync, isLoading } = useMutationUpdateProfile();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: profile.name,
      login: profile.email,
      cellPhone: profile.phone,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await mutateAsync({
        ...profile,
        email: data.login,
        name: data.name,
        phone: data.cellPhone.replace(/\D/g, ""),
        companyToken: company!.token,
      });
      onClose?.({}, "backdropClick");
    } catch (e) {
      console.log(e);
    }
  });

  return (
    <Dialog onClose={onClose} maxWidth="sm" fullWidth {...props}>
      <DialogTitle>Editar dados cadastrais</DialogTitle>

      <form onSubmit={onSubmit}>
        <DialogContent dividers>
          <Stack gap={2}>
            <ControllerInput control={control} name="name" inputProps={{ label: "Nome" }} />
            <ControllerInput
              control={control}
              name="login"
              inputProps={{ label: "Login / Email" }}
            />
            <Controller
              name="cellPhone"
              control={control}
              render={({ fieldState, field }) => (
                <MaskedInput
                  mask={[
                    "(",
                    /[1-9]/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  guide={false}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  render={(ref, props) => (
                    <TextField
                      fullWidth
                      label="DDD + Telefone"
                      variant="outlined"
                      inputRef={ref}
                      placeholder="(00) 00000-0000"
                      {...props}
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                    />
                  )}
                ></MaskedInput>
              )}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton loading={isLoading} variant="contained" type="submit">
            Atualizar
          </LoadingButton>
          <Button variant="outlined">Cancelar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogUpdateUserData;
