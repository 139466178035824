import { CircularProgress, Stack, useTheme } from "@mui/material";
import { FC } from "react";
import { createPortal } from "react-dom";

const FullscreenLoadingIndicator: FC = () => {
  const theme = useTheme();

  return createPortal(
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
      position="fixed"
      top={0}
      right={0}
      zIndex={theme.zIndex.tooltip + 1}
      bgcolor={"text.disabled"}
    >
      <CircularProgress />
    </Stack>,
    document.getElementById("root") as HTMLElement,
  );
};

export default FullscreenLoadingIndicator;
