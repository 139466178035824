import { FC, useState } from "react";

import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";

import Chat from "./Chat";
import { StatusRequest } from "types/Mailbox";

const RequestControl: FC = () => {
  const [statusRequest, setStatusRequest] = useState<StatusRequest>("open");
  const [lastDays, setLastDays] = useState(7);

  return (
    <Box>
      <Stack direction="row" gap={1}>
        <FormControl>
          <InputLabel>Status</InputLabel>
          <Select
            value={statusRequest}
            label="Status"
            onChange={(e) => setStatusRequest(e.target.value as StatusRequest)}
          >
            <MenuItem value="open">Abertas</MenuItem>
            <MenuItem value="closed">Encerradas</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>Período</InputLabel>
          <Select
            value={lastDays}
            label="Período"
            onChange={(e) => setLastDays(Number(e.target.value))}
          >
            <MenuItem value={7}>Há 7 dias</MenuItem>
            <MenuItem value={15}>Há 15 dias</MenuItem>
            <MenuItem value={30}>Há 30 dias</MenuItem>
            <MenuItem value={0}>Todos</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Box mt={2}>
        <Chat lastDays={lastDays} statusRequest={statusRequest} />
      </Box>
    </Box>
  );
};

export default RequestControl;
