import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { deleteFinancialCategory } from "services/Requests/companies";
import { deleteCompanyFinancialCategoryById } from "services/Requests/accountant";

const useMutationDeleteFinancialCategory = (isCompany = false) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    isCompany ? deleteCompanyFinancialCategoryById : deleteFinancialCategory,
    {
      onSuccess() {
        toast.success("Categoria deletada com sucesso");
        if (isCompany) {
          queryClient.invalidateQueries(queryKeys.clientChartOfAccounts, { exact: false });
        } else {
          queryClient.invalidateQueries(queryKeys.globalCategoriesByGroup, { exact: false });
        }
      },
    },
  );

  return mutation;
};

export default useMutationDeleteFinancialCategory;
