import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import queryKeys from "react-query/constants";
import { closeTicketByCompany } from "services/Requests/mailbox";

const useMutationCloseTicket = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(closeTicketByCompany, {
    onSuccess(_, { ticketId }) {
      toast.success("Ticket encerrado com sucesso");

      queryClient.invalidateQueries(queryKeys.allChats, {
        exact: false,
      });
      queryClient.invalidateQueries(queryKeys.chats, {
        exact: false,
      });
      queryClient.invalidateQueries([queryKeys.getTicketById, ticketId]);
    },
  });
  return mutation;
};

export default useMutationCloseTicket;
