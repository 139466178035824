import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("O nome do template é obrigatório"),
  mask: yup
    .string()
    .required("A máscara contábil é obrigatório")
    .test("onlyNumbersAndDots", "A máscara é composta apenas de zeros e pontos", (value) => {
      if (!value) return true;

      return value.split("").every((letter) => letter === "0" || letter === ".");
    }),
});

export default validationSchema;
