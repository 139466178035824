import { useCallback } from "react";
import { useQuery } from "react-query";

import queryKeys from "react-query/constants";
import { getBalance } from "services/Requests/stockControl";
import { Balances } from "types/StockControl";

const useQueryBalance = (companyToken: string, search = "") => {
  const select = useCallback(
    (data: Balances) => {
      if (!search) return data;

      const searchBalance = (name: string) => {
        if (!search.trim()) return true;

        return name.toLowerCase().search(search.toLowerCase()) !== -1;
      };

      return data.filter((p) => searchBalance(p.name) || searchBalance(p.code));
    },
    [search],
  );

  const query = useQuery(queryKeys.stockControlBalance, () => getBalance({ companyToken }), {
    select,
  });
  return query;
};

export default useQueryBalance;
