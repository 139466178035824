import Drive from "pages/Home/pages/Drive";
import { FC } from "react";
import { Order } from "types/OpenCompany";

interface DocumentationProps {
  order: Order;
}

const Documentation: FC<DocumentationProps> = ({ order }) => {
  return <Drive isPage={false} companyToken={order.companyToken} />;
};

export default Documentation;
