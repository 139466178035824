import { FC } from "react";
import useQueryProfile from "../../hooks/useQueryProfile";

import { CircularProgress, Stack, Typography } from "@mui/material";
import Form from "./Form";

interface AboutProps {
  onNext: () => void;
}

const About: FC<AboutProps> = ({ onNext }) => {
  const { data: profile, isLoading, isError } = useQueryProfile();

  return (
    <>
      {isLoading && (
        <Stack height={500} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}

      {isError && !isLoading && (
        <Typography>Ocorreu um erro ao carregar os dados, tente novamente mais tarde</Typography>
      )}

      {!isLoading && !isError && profile && <Form profile={profile} onNext={onNext} />}
    </>
  );
};

export default About;
