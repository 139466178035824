import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { parcialTransaction } from "services/Requests/companies";
import queryKeys from "react-query/constants";

const useMutationParcialTransaction = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(parcialTransaction, {
    onSuccess(_, { transactionId }) {
      toast.success("Baixa realizada com sucesso");
      queryClient.invalidateQueries(queryKeys.transactions, { exact: false });
      queryClient.invalidateQueries([queryKeys.transaction, transactionId]);
    },
  });

  return mutation;
};

export default useMutationParcialTransaction;
