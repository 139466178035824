import * as yup from "yup";
import validateCPF from "utils/validateCPF";

const validationSchema = yup.object().shape({
  name: yup.string().typeError("O nome é obrigatório").required("O nome é obrigatório"),
  cpf: yup
    .string()
    .typeError("O CPF é obrigatório")
    .required("O CPF é obrigatório")
    .test("validCPF", "Digite um CPF válido", (value) => {
      if (!value) return false;

      const valueOnlyNumber = value.replace(/\D/g, "");
      if (valueOnlyNumber.length !== 11) return false;

      return validateCPF(valueOnlyNumber);
    }),
  crc: yup.string().typeError("O CRC é obrigatório").required("O CRC é obrigatório"),
  telNumber: yup.mixed().nullable().notRequired(),
});

export default validationSchema;
