import { FC, useState } from "react";
import useQueryGetContracts from "pages/Home/hooks/useQueryGetContracts";
import useMutationUpdateDefaultService from "../../../hooks/useMutationUpdateDefaultService";
import { useFieldArray, useForm } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";

import { formattedInput } from "components/CurrencyInput";
import { HTMLStringToDescendant, toHTMLString } from "components/TextEditor/utils/functions";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

import { Descendant } from "slate";
import { Service, ServicePremiumPrice } from "types/ServiceAndPlan";
import formatCurrencyBRL from "utils/formatCurrencyBRL";
import getSubdomain from "utils/getSubdomain";

import GeneralForm from "./GeneralForm";

export interface FormEditPlan {
  name: string;
  isActive: boolean;
  agreement: string;
  services: {
    name: string;
    serviceId: number;
    price: string;
    isActive: boolean;
  }[];
}

interface ServiceFormProps {
  service: Service;
  onClose: () => void;
  companyToken: string;
}

const ServiceForm: FC<ServiceFormProps> = ({ service, onClose, companyToken }) => {
  const { data: contracts } = useQueryGetContracts(companyToken);
  const { mutateAsync, isLoading: loadingUpdate } = useMutationUpdateDefaultService();

  const [startText, setStartText] = useState<Descendant[]>(() => {
    return HTMLStringToDescendant(service.serviceTexts.startText);
  });
  const [successPlan, setSuccessPlan] = useState<Descendant[]>(() => {
    return HTMLStringToDescendant(service.serviceTexts.endText);
  });

  const { control, handleSubmit } = useForm<FormEditPlan>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: service.groupName || service.name,
      agreement: service.filename || "",
      isActive: service.isActive,
      services: service.premiumPrices.map((premium) => ({
        name: premium.companyType,
        serviceId: premium.id,
        price: formatCurrencyBRL(premium.priceInCents),
        isActive: premium.isActive,
      })),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "services",
  });

  const handleClose = () => {
    if (loadingUpdate) return;
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    const contract =
      data.agreement && contracts
        ? contracts.find((contract) => contract.filename === data.agreement)
        : null;

    const newPremiumPrices: ServicePremiumPrice[] = service.premiumPrices.map((premium) => {
      const editted = data.services.find((service) => service.serviceId === premium.id);

      if (!editted) return premium;

      return {
        ...premium,
        priceInCents: formattedInput(editted.price),
        isActive: editted.isActive,
      };
    });

    await mutateAsync({
      agreement: {
        downloadToken: contract?.downloadToken || null,
        filename: contract?.filename || null,
      },
      companyToken,
      contractId: service.contractId,
      contractName: service.contractName,
      daysToExpire: service.daysToExpire,
      documentToken: contract?.downloadToken || "",
      downloadToken: contract?.downloadToken || null,
      endText: toHTMLString(successPlan),
      startText: toHTMLString(startText),
      filename: contract?.filename || null,
      global: service.global,
      id: service.id,
      isActive: data.isActive,
      iuguPlanId: service.iuguPlanId,
      name: data.name,
      premiumPrices: newPremiumPrices,
      serviceTexts: {
        endText: toHTMLString(successPlan),
        startText: toHTMLString(startText),
      },
      serviceType: service.serviceType,
      status: service.status,
      subdomain: getSubdomain(),
      userType: service.userType,
      valueCents: service.valueCents,
      groupName: service.groupName,
    });
    handleClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <GeneralForm
          companyToken={companyToken}
          control={control}
          fields={fields}
          service={service}
          startText={startText}
          setStartText={setStartText}
          successPlan={successPlan}
          setSuccessPlan={setSuccessPlan}
          onlyContract={service.global}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          loading={loadingUpdate}
          variant="contained"
          disabled={!contracts}
        >
          Salvar
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default ServiceForm;
