const formatCurrencyBRL = (currency?: number | string) => {
  currency = Number(currency);

  if (isNaN(currency) || currency === undefined) {
    return "";
  }

  return currency.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
};

export default formatCurrencyBRL;
