import { FC, useMemo } from "react";
import { useForm, Controller, useWatch, DefaultValues } from "react-hook-form";
import useMutationUpdateCompany from "../../hooks/useMutationUpdateCompany";
import useQueryCNAEsByActivity from "pages/Home/hooks/useQueryCNAEsByActivity";

import {
  Box,
  Button,
  Collapse,
  Divider,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import CompanyActivitiesSelect from "pages/Home/components/CompanyActivitiesSelect";
import CurrencyInput, { formattedInput } from "components/CurrencyInput";
import AddressFields from "components/AddressFields";

import { CompanyInformation } from "types/Company";
import formatCurrencyBRL from "utils/formatCurrencyBRL";

interface CompanyFormFields {
  companyActivityId: number;
  companyName: string;
  socialCapital: string;
  companyName1: string;
  companyName2: string;

  street: string;
  neighborhood: string;
  zipCode: string;
  city: string;
  complement: string;
  state: string;
  streetNumber: number;
}

interface FormCompanyProps {
  company: CompanyInformation;
  onNext: () => void;
  onBack?: () => void;
}

const FormCompany: FC<FormCompanyProps> = ({ company, onNext, onBack }) => {
  const { mutateAsync } = useMutationUpdateCompany();

  const defaultValues = useMemo<DefaultValues<CompanyFormFields>>(
    () => ({
      companyActivityId: company.companyActivityId ?? undefined,
      companyName: company.companyName ?? "",
      socialCapital: formatCurrencyBRL(company.socialCapital ?? 0),
      companyName1: company.companyName1 ?? "",
      companyName2: company.companyName2 ?? "",
      street: company.address.street ?? "",
      neighborhood: company.address.neighborhood ?? "",
      zipCode: company.address.zipCode ?? "",
      city: company.address.countyId ?? "",
      complement: company.address.complement ?? "",
      state: company.address.state ?? "",
      streetNumber: Number(company.address.streetNumber ?? 0),
    }),
    [company],
  );

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CompanyFormFields>({
    defaultValues,
  });

  const companyActivityId = useWatch({
    control,
    name: "companyActivityId",
  });
  const { data: cnaes, isLoading } = useQueryCNAEsByActivity(companyActivityId);

  const onSubmit = handleSubmit(async (data) => {
    const address = {
      street: data.street,
      neighborhood: data.neighborhood,
      zipCode: data.zipCode,
      countyId: data.city,
      complement: data.complement,
      state: data.state,
      streetNumber: data?.streetNumber?.toString(),
    };

    await mutateAsync({
      ...company,
      ...address,
      companyActivityId: data.companyActivityId,
      socialCapital: formattedInput(data.socialCapital),
      companyName: data.companyName,
      companyName1: data.companyName1,
      companyName2: data.companyName2,
      address: {
        ...company.address,
        ...address,
      },
    });
    onNext();
  });

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Typography variant="h6" sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
        Mais sobre sua futura empresa
      </Typography>

      <Typography variant="body1" color="text.secondary">
        Fique tranquilo caso tenha dúvidas ou não possua algumas das informações solicitadas, pois
        nossos contadores irão analisar seu pedido e entrarão em contato para lhe ajudar a escolher
        o melhor modelo de empresa.
      </Typography>

      <Controller
        name="companyActivityId"
        control={control}
        render={({ field, fieldState }) => (
          <CompanyActivitiesSelect
            label="Escolha abaixo a área que você deseja abrir sua empresa:"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            FormControlProps={{
              fullWidth: true,
              sx: { mt: 4 },
            }}
            {...field}
          />
        )}
      />

      <Box mt={1}>
        <Collapse in={isLoading}>
          <LinearProgress />
        </Collapse>

        {cnaes && (
          <Box>
            <Typography
              variant="body1"
              fontWeight={600}
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              Relação de atividades da área selecionada:
            </Typography>

            <TableContainer sx={{ maxHeight: 400 }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Código</TableCell>
                    <TableCell>Atividade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cnaes.map((cnae) => (
                    <TableRow key={cnae.code} hover>
                      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                        {cnae.code}
                      </TableCell>
                      <TableCell>{cnae.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>

      <Divider sx={{ mt: 4, mb: 2 }} />

      <Controller
        control={control}
        name="companyName"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            label="Nome pretendido da empresa"
            fullWidth
          />
        )}
      />

      <Box display="grid" gridTemplateColumns="1fr 3fr" mt={2} gap={2}>
        <Controller
          control={control}
          name="socialCapital"
          render={({ field, fieldState }) => (
            <CurrencyInput
              {...field}
              inputProps={{
                label: "Capital social",
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message,
              }}
            />
          )}
        />

        <Typography variant="body2" color="text.secondary">
          Capital social é o montante necessário para se constituir e iniciar as atividades de uma
          nova empresa enquanto esta não gera recursos suficientes para se sustentar. O valor do
          capital social deve ser estipulado baseado num plano de negócios elaborado pelos
          interessados previamente.
        </Typography>
      </Box>

      <Divider sx={{ mt: 4, mb: 2 }} />

      <Typography variant="body1">
        Sugira outros dois possíveis nomes para a empresa, no caso de a primeira opção não poder ser
        usada
      </Typography>

      <Box display="grid" mt={2} mb={2} gap={2} gridTemplateColumns="1fr 1fr">
        <Controller
          control={control}
          name="companyName1"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              label="Nome alternativo"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="companyName2"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              label="Outro nome alternativo"
              fullWidth
            />
          )}
        />
      </Box>

      <AddressFields control={control} setValue={setValue} />

      <Divider sx={{ mt: 4, mb: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent={onBack ? "space-between" : "end"}>
        {!!onBack && (
          <Button
            variant="contained"
            type="button"
            startIcon={<NavigateBeforeIcon />}
            onClick={onBack}
          >
            Anterior
          </Button>
        )}
        <LoadingButton
          loading={isSubmitting}
          onClick={() => onSubmit()}
          variant="contained"
          endIcon={<NavigateNextIcon />}
        >
          Próximo passo
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default FormCompany;
