import * as yup from "yup";
import { banksAttributes } from ".";

const bankHaveThisAttribute = (
  bank: string,
  attribute: "digitAgency" | "digitAccount" | "operations",
) => {
  const entriesBank = Object.entries(banksAttributes);

  return entriesBank.some((entrie) => {
    if (bank !== entrie[0]) return false;
    return entrie[1][attribute];
  });
};

const validationSchema = yup.object().shape({
  bank: yup.string().required("Selecione um banco"),
  agency: yup.string().required("O número da agência é obrigatório"),
  digitAgency: yup.string().when("bank", (bank) => {
    if (bankHaveThisAttribute(bank, "digitAgency")) {
      return yup.string().required("O dígito da agência é obrigatório");
    }
    return yup.string();
  }),
  account: yup.string().required("O número da conta é obrigatório"),
  digitAccount: yup.string().when("bank", (bank) => {
    if (bankHaveThisAttribute(bank, "digitAccount")) {
      return yup.string().required("O dígito da conta é obrigatório");
    }
    return yup.string();
  }),
  operations: yup.string().when("bank", (bank) => {
    if (bankHaveThisAttribute(bank, "operations")) {
      return yup.string().required("A operação é obrigatório");
    }
    return yup.string();
  }),
});

export default validationSchema;
