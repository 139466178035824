import { FC } from "react";

import { Box, Stack, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import CheckboxMonthClosure from "./CheckboxMonthClosure";
import CheckboxBlockRelease from "./CheckboxBlockRelease";

const MonthClosure: FC = () => {
  const theme = useTheme();

  return (
    <Box position="relative">
      <Typography variant="body1" maxWidth={theme.breakpoints.keys[1]} color={grey[600]}>
        Marcando a opção abaixo, os lançamentos ficarão bloqueados quando o mês for fechado. Mas
        caso queira liberar novamente para edição, será necessário reabrir o mês, na tela de
        &quot;Fechamento do mês&quot; do seu cliente.
      </Typography>

      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        gap={1}
        flexWrap="wrap"
      >
        <CheckboxMonthClosure />
        <CheckboxBlockRelease />
      </Stack>
    </Box>
  );
};

export default MonthClosure;
