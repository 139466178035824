import * as yup from "yup";
import { DateTime } from "luxon";

const minDate = DateTime.local(2000).toJSDate();
const maxDate = DateTime.now().plus({ years: 3 }).endOf("year");

const validationSchema = yup.object().shape({
  file: yup.mixed().test("testType", "O arquivo de importação é obrigatório", (v) => {
    if (!(v instanceof FileList)) {
      return false;
    }
    return v.length > 0;
  }),
  referenceDate: yup
    .date()
    .required("Informe a data")
    .typeError("Digite uma data válida")
    .min(minDate, "Digite uma data maior que a data mínima")
    .max(maxDate, "Digite uma data menor que a data máxima"),
  dueDate: yup
    .date()
    .notRequired()
    .nullable(true)
    .typeError("Digite uma data válida")
    .min(minDate, "Digite uma data maior que a data mínima")
    .max(maxDate, "Digite uma data menor que a data máxima"),
  value: yup.string(),
});

export default validationSchema;
