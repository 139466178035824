import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { addViewLeadForm } from "services/Requests/marketplace";

const useMutationAddViewLeadForm = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(addViewLeadForm, {
    onSuccess() {
      queryClient.invalidateQueries(queryKeys.leads, { exact: false });
    },
  });
  return mutation;
};

export default useMutationAddViewLeadForm;
