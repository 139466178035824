import { FC } from "react";

import {
  Box,
  CircularProgress,
  Grow,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ContractTableItem from "./ContractTableItem";
import useQueryGetContracts from "pages/Home/hooks/useQueryGetContracts";

interface ContractTableProps {
  companyToken: string;
  searchContract: string;
}

const ContractTable: FC<ContractTableProps> = ({ companyToken, searchContract }) => {
  const {
    data: contracts,
    isLoading,
    isError,
    isFetching,
  } = useQueryGetContracts(companyToken, searchContract);

  return (
    <Box position="relative">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Stack alignItems="center" justifyContent="center">
                    <CircularProgress />
                  </Stack>
                </TableCell>
              </TableRow>
            )}
            {contracts && !contracts.length && (
              <TableRow>
                <TableCell colSpan={3}>Nenhum contrato cadastrado</TableCell>
              </TableRow>
            )}
            {isError && (
              <TableRow>
                <TableCell colSpan={3}>Ocorreu um error ao carregar os contratos</TableCell>
              </TableRow>
            )}

            {contracts &&
              contracts.map((contract) => (
                <ContractTableItem
                  contract={contract}
                  key={contract.downloadToken}
                  companyToken={companyToken}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grow in={isFetching && !isLoading}>
        <Stack
          position="absolute"
          top={50}
          left={0}
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <CircularProgress />
        </Stack>
      </Grow>
    </Box>
  );
};

export default ContractTable;
