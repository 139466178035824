import { FC } from "react";
import useQueryTemplates from "./hooks/useQueryTemplates";

import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import TemplateTableItem from "./TemplateTableItem";

const TemplateList: FC = () => {
  const { data: templates, isLoading, isError } = useQueryTemplates();

  return (
    <>
      {isLoading && (
        <Stack height={400} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      {isError && <Typography>Erro ao carregar os layouts</Typography>}

      {!isLoading && !isError && !!templates && (
        <TableContainer>
          <Table size="small">
            <TableBody>
              {templates.map((template) => (
                <TemplateTableItem key={template.id} template={template} />
              ))}
              {templates.length === 0 && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography>Nenhum layout encontrado</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default TemplateList;
