import { FC } from "react";

import { Controller, ControllerProps, FieldValue } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";

interface ControllerInputProps extends Omit<ControllerProps, "render" | "control"> {
  inputProps?: TextFieldProps;
  control: FieldValue<{ [x: string]: any }>;
}

const ControllerInput: FC<ControllerInputProps> = ({ inputProps, ...controllerProps }) => {
  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...inputProps}
          error={!!fieldState.error?.message}
          helperText={fieldState.error?.message || inputProps?.helperText}
          InputLabelProps={{
            ...inputProps?.InputLabelProps,
            shrink: field.value ? true : undefined,
          }}
        />
      )}
    />
  );
};

export default ControllerInput;
