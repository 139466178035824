import { useQuery } from "react-query";
import queryKeys from "react-query/constants";
import { getCountryStates } from "services/Requests/commonData";

const useQueryStates = () => {
  return useQuery(queryKeys.states, getCountryStates, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export default useQueryStates;
