import { ChangeEvent, FC, useEffect, useState } from "react";
import useQueryGetMonthClosure from "./hooks/useQueryGetMonthClosure";
import useMutationToggleMonthClosure from "./hooks/useMutationToggleMonthClosure";

import { Checkbox, FormControlLabel } from "@mui/material";

const CheckboxMonthClosure: FC = () => {
  const { isLoading, data } = useQueryGetMonthClosure();
  const { mutate, isLoading: isLoadingToggle } = useMutationToggleMonthClosure();

  const [blockEdit, setBlockEdit] = useState(false);

  useEffect(() => {
    if (!data) return;
    setBlockEdit(data.value);
  }, [data]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isLoadingToggle) return;

    setBlockEdit(e.target.checked);
    mutate(e.target.checked);
  };

  return (
    <FormControlLabel
      sx={{ mt: 1 }}
      control={
        <Checkbox
          disabled={isLoading || isLoadingToggle}
          checked={blockEdit}
          onChange={handleChange}
        />
      }
      label="Bloquear edição de lançamentos"
    />
  );
};

export default CheckboxMonthClosure;
