import { FC } from "react";
import { useAuth } from "context/Auth";

import { Box, Typography } from "@mui/material";
import getSubdomain from "utils/getSubdomain";

import Head from "components/Head";
import Plans from "./Plans";
import { MESSAGE_NO_COMPANY } from "utils/constants";

const OnlineAccounting: FC = () => {
  const {
    user: { company },
  } = useAuth();

  return (
    <>
      <Head title={`Contabilidade Online | ${getSubdomain()}`} />

      <Box pt={4}>
        <Typography align="center" variant="h4" fontWeight={600} gutterBottom>
          Plataforma Osayk
        </Typography>
        {company ? <Plans companyToken={company?.token} /> : <>{MESSAGE_NO_COMPANY}</>}
      </Box>
    </>
  );
};

export default OnlineAccounting;
