import { FC, useState } from "react";
import { TextField } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

const PeriodDate: FC<Omit<DatePickerProps<DateTime>, "renderInput">> = ({ ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      {...props}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      allowSameDateSelection
      renderInput={(params) => (
        <TextField
          sx={{ "*": { cursor: "pointer !important", caretColor: "transparent" } }}
          onClick={() => setOpen(true)}
          onKeyDown={(e) => e.preventDefault()}
          InputProps={{ ...params.InputProps, readOnly: true }}
          {...params}
        />
      )}
    />
  );
};

export default PeriodDate;
