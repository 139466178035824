import { FC, useState } from "react";
import { useAuth } from "context/Auth";

import { Box, Button, Stack, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";
import Head from "components/Head";

import UsersContent from "../../components/UsersContent";
import getSubdomain from "utils/getSubdomain";
import { MESSAGE_NO_COMPANY } from "utils/constants";
import DialogCreateOrUpdateAdminUser from "pages/Home/components/DialogCreateOrUpdateAdminUser";

const UserRegistrations: FC = () => {
  const {
    user: { company },
  } = useAuth();
  const [createAccountantDialog, setCreateAccountantDialog] = useState(false);

  return (
    <>
      <Head title={`Cadastrar Contador | ${getSubdomain()}`} />

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h4" fontWeight="bold">
          Cadastrar Contador
        </Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<Add fontSize="small" />}
          onClick={() => setCreateAccountantDialog(true)}
        >
          Adicionar novo contador
        </Button>
      </Stack>

      <Box boxShadow={1} p={2} mt={2}>
        {company ? (
          <>
            <UsersContent companyToken={company.token} userType="accountant" />
            <DialogCreateOrUpdateAdminUser
              companyToken={company.token}
              userType="accountant"
              open={createAccountantDialog}
              onClose={() => setCreateAccountantDialog(false)}
            />
          </>
        ) : (
          <Typography>{MESSAGE_NO_COMPANY}</Typography>
        )}
      </Box>
    </>
  );
};

export default UserRegistrations;
