import { forwardRef, Ref } from "react";
import useQueryCNAEsByActivity from "pages/Home/hooks/useQueryCNAEsByActivity";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface CNAEsByActivitySelectInnerProps extends SelectProps<string[]> {
  activityId?: number;
  helperText?: string;
}

function CNAEsByActivitySelectInner(
  { helperText, activityId, ...props }: CNAEsByActivitySelectInnerProps,
  ref?: Ref<unknown>,
) {
  const { data: CNAEs, isLoading, isError } = useQueryCNAEsByActivity(activityId);

  return (
    <FormControl size={props.size} error={props.error}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        ref={ref}
        {...props}
        displayEmpty
        multiple
        value={props.value || []}
        renderValue={(selected) => {
          return Array.isArray(selected) ? selected.join(", ") : selected;
        }}
      >
        {isLoading && <MenuItem disabled>Carregando...</MenuItem>}
        {isError && <MenuItem disabled>Ocorreu um erro, tente mais tarde</MenuItem>}
        {!!CNAEs && CNAEs.length === 0 && (
          <MenuItem disabled>Nenhuma atividade encontrada nesta área</MenuItem>
        )}

        {CNAEs &&
          !isLoading &&
          CNAEs.map(({ code, name }) => (
            <MenuItem key={code} value={code}>
              {code} - {name}
            </MenuItem>
          ))}
      </Select>
      {!!helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const CNAEsByActivitySelect = forwardRef(CNAEsByActivitySelectInner);
export default CNAEsByActivitySelect;
