import { FC, memo, useEffect, useMemo, useState } from "react";
import { Control, Controller, useWatch } from "react-hook-form";

import { MovementForm } from "../utils/InterfacesForm";
import { Box, TextField } from "@mui/material";

import ProductSelect from "pages/Home/components/ProductSelect";
import PisCstSelect from "pages/Home/components/PisCstSelect";
import IpiCstSelect from "pages/Home/components/IpiCstSelect";

import formatCurrencyBRL from "utils/formatCurrencyBRL";
import CurrencyInput from "components/CurrencyInput";
import calculateTotalItem from "../utils/calculateTotalItem";

interface MovementItemProps {
  control: Control<MovementForm>;
  index: number;
  companyToken: string;
  requestUpdate: (index: number, productId: number) => void;
  isConsultation: boolean;
}

const MovementItem: FC<MovementItemProps> = ({
  control,
  index,
  companyToken,
  requestUpdate,
  isConsultation,
}) => {
  const sendInvoice = useWatch({
    control,
    name: "sendInvoice",
  });
  const isInvoice = useMemo(() => sendInvoice === "1", [sendInvoice]);
  const isConsumerInvoice = useMemo(() => sendInvoice === "2", [sendInvoice]);
  const isNotInvoice = useMemo(() => sendInvoice === "0", [sendInvoice]);

  const [totalProduct, setProduct] = useState(0);

  const item = useWatch({
    control,
    name: `items.${index}`,
  });

  const handleCalculateTotalProduct = () => {
    if (isNotInvoice) return;
    setProduct(calculateTotalItem(item));
  };

  useEffect(() => {
    handleCalculateTotalProduct();
  }, [isNotInvoice]);

  return (
    <Box display="grid" gap={2} gridTemplateColumns="repeat(5, 1fr)" alignItems="center">
      <Controller
        control={control}
        name={`items.${index}.productId`}
        render={({ field, fieldState }) => (
          <ProductSelect
            companyToken={companyToken}
            label="Produto"
            FormControlProps={{ sx: { gridColumn: !isNotInvoice ? "1/5" : "1/-1" } }}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              requestUpdate(index, e.target.value as number);
            }}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            disabled={isConsultation}
          />
        )}
      />

      {!isNotInvoice && (
        <Controller
          control={control}
          name={`items.${index}.cfop`}
          render={({ field, fieldState }) => (
            <TextField
              label="CFOP"
              sx={{ gridColumn: "5/-1" }}
              {...field}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              disabled={isConsultation}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name={`items.${index}.unitValue`}
        render={({ field, fieldState }) => (
          <CurrencyInput
            {...field}
            onBlur={() => {
              field.onBlur();
              handleCalculateTotalProduct();
            }}
            inputProps={{
              label: "Valor unitário (R$)",
              helperText: fieldState.error?.message,
              error: !!fieldState.error?.message,
              sx: { gridColumn: "span 2" },
            }}
            disabled={isConsultation}
          />
        )}
      />

      <Controller
        control={control}
        name={`items.${index}.quantity`}
        render={({ field, fieldState }) => (
          <TextField
            label="Quantidade"
            {...field}
            onBlur={() => {
              field.onBlur();
              handleCalculateTotalProduct();
            }}
            inputProps={{
              min: "0",
            }}
            type="number"
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            disabled={isConsultation}
          />
        )}
      />

      <Controller
        control={control}
        name={`items.${index}.discount`}
        render={({ field, fieldState }) => (
          <CurrencyInput
            {...field}
            onBlur={() => {
              field.onBlur();
              handleCalculateTotalProduct();
            }}
            inputProps={{
              label: "Desconto total (R$)",
              helperText: fieldState.error?.message,
              error: !!fieldState.error?.message,
            }}
            disabled={isConsultation}
          />
        )}
      />

      <Controller
        control={control}
        name={`items.${index}.otherExpenses`}
        render={({ field, fieldState }) => (
          <CurrencyInput
            {...field}
            onBlur={() => {
              field.onBlur();
              handleCalculateTotalProduct();
            }}
            inputProps={{
              label: "Outras despesas (R$)",
              helperText: fieldState.error?.message,
              error: !!fieldState.error?.message,
            }}
            disabled={isConsultation}
          />
        )}
      />

      {!isNotInvoice && (
        <>
          <Controller
            control={control}
            name={`items.${index}.pisCst`}
            render={({ field, fieldState }) => (
              <PisCstSelect
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                label="PIS CST"
                FormControlProps={{ sx: { gridColumn: "span 2" } }}
                disabled={isConsultation}
              />
            )}
          />

          <Controller
            control={control}
            name={`items.${index}.pis`}
            render={({ field, fieldState }) => (
              <TextField
                label="PIS (%)"
                {...field}
                type="number"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                disabled={isConsultation}
              />
            )}
          />

          <Controller
            control={control}
            name={`items.${index}.icmsCst`}
            render={({ field, fieldState }) => (
              <TextField
                label="ICMS CST/CSOSN"
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                disabled={isConsultation}
              />
            )}
          />

          <Controller
            control={control}
            name={`items.${index}.icms`}
            render={({ field, fieldState }) => (
              <TextField
                label="ICMS/CSOSN (%)"
                {...field}
                type="number"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                disabled={isConsultation}
              />
            )}
          />

          <Controller
            control={control}
            name={`items.${index}.cofinsCst`}
            render={({ field, fieldState }) => (
              <PisCstSelect
                {...field}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                label="COFINS CST"
                FormControlProps={{ sx: { gridColumn: "span 2" } }}
                disabled={isConsultation}
              />
            )}
          />

          <Controller
            control={control}
            name={`items.${index}.cofins`}
            render={({ field, fieldState }) => (
              <TextField
                label="COFINS (%)"
                {...field}
                type="number"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                disabled={isConsultation}
              />
            )}
          />

          {isInvoice && (
            <>
              <Controller
                control={control}
                name={`items.${index}.ipiCst`}
                render={({ field, fieldState }) => (
                  <IpiCstSelect
                    {...field}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    label="IPI CST"
                    disabled={isConsultation}
                  />
                )}
              />

              <Controller
                control={control}
                name={`items.${index}.ipi`}
                render={({ field, fieldState }) => (
                  <TextField
                    label="IPI (%)"
                    {...field}
                    type="number"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    disabled={isConsultation}
                  />
                )}
              />
            </>
          )}

          <Controller
            control={control}
            name={`items.${index}.taxBenefit`}
            render={({ field }) => (
              <TextField
                label="Código Benefício Fiscal"
                inputProps={{
                  maxLength: 8,
                }}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{
                  shrink: field.value ? true : undefined,
                }}
                disabled={isConsultation}
                {...field}
              />
            )}
          />

          {isConsumerInvoice && (
            <>
              <Controller
                control={control}
                name={`items.${index}.municipalTax`}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Tributo Municipal (%)"
                    {...field}
                    type="number"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{
                      shrink: field.value ? true : undefined,
                    }}
                    disabled={isConsultation}
                  />
                )}
              />
              <Controller
                control={control}
                name={`items.${index}.stateTax`}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Tributo Estadual (%)"
                    {...field}
                    type="number"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{
                      shrink: field.value ? true : undefined,
                    }}
                    disabled={isConsultation}
                  />
                )}
              />
              <Controller
                control={control}
                name={`items.${index}.federalTax`}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Tributo Federal (%)"
                    {...field}
                    type="number"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{
                      shrink: field.value ? true : undefined,
                    }}
                    disabled={isConsultation}
                  />
                )}
              />

              <Controller
                control={control}
                name={`items.${index}.taxSource`}
                render={({ field }) => (
                  <TextField
                    label="Fonte"
                    inputProps={{
                      maxLength: 10,
                    }}
                    {...field}
                    InputLabelProps={{
                      shrink: field.value ? true : undefined,
                    }}
                    sx={{ gridColumn: "span 2" }}
                    disabled={isConsultation}
                  />
                )}
              />
            </>
          )}

          <Controller
            control={control}
            name={`items.${index}.notes`}
            render={({ field, fieldState }) => (
              <TextField
                label="Informações adicionais ao produto"
                sx={{ gridColumn: "span 4" }}
                {...field}
                value={field.value ?? ""}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                InputLabelProps={{
                  shrink: field.value ? true : undefined,
                }}
                disabled={isConsultation}
              />
            )}
          />

          <TextField
            label="Total (R$)"
            value={formatCurrencyBRL(totalProduct)}
            InputProps={{
              readOnly: true,
            }}
          />
        </>
      )}
    </Box>
  );
};

export default memo(MovementItem);
