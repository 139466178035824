import { FC, useState } from "react";
import { IconButton, TableCell, TableRow, Menu as DropdownMenu, MenuItem } from "@mui/material";
import { CompanyParticipants } from "types/Company";
import Menu from "@mui/icons-material/Menu";

interface ClientTableItemProps {
  client: CompanyParticipants;
  onQuery: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const ClientTableItem: FC<ClientTableItemProps> = ({ client, onQuery, onEdit, onDelete }) => {
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(anchorElMenu);

  return (
    <>
      <TableRow>
        <TableCell>{client.name}</TableCell>
        <TableCell>{client.phoneAsString || "-"}</TableCell>
        <TableCell>{client.email || "-"}</TableCell>
        <TableCell>{client.cpfcnpj}</TableCell>
        <TableCell align="right">
          <IconButton onClick={(e) => setAnchorElMenu(e.currentTarget)}>
            <Menu />
          </IconButton>
        </TableCell>
      </TableRow>

      <DropdownMenu
        anchorEl={anchorElMenu}
        open={menuIsOpen}
        onClick={() => setAnchorElMenu(null)}
        onClose={() => setAnchorElMenu(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={onQuery}>Consultar</MenuItem>
        <MenuItem onClick={onEdit}>Editar</MenuItem>
        <MenuItem onClick={onDelete}>Excluir</MenuItem>
      </DropdownMenu>
    </>
  );
};

export default ClientTableItem;
