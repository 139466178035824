import { FC, useEffect, useMemo, useState } from "react";
import { useForm, DefaultValues, useFieldArray } from "react-hook-form";
import useMutationUpdatePartners from "pages/Home/pages/Profile/components/CompanyData/hooks/useMutationUpdatePartners";

import { Box, Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import DeleteIcon from "@mui/icons-material/Delete";

import { CompanyPartner, NewCompanyPartner } from "types/Company";
import * as partner from "./utils/partner";
import { grey, red } from "@mui/material/colors";
import PartnerItem from "./PartnerItem";

import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";

export interface PartnerFields {
  partnerId?: number;
  name: string;
  share: number;
  cpf: string;
}

export interface Form {
  partners: PartnerFields[];
  responsible: number;
}

interface PartnerFormProps {
  onNext: () => void;
  onPrev: () => void;
  partners: CompanyPartner[];
  companyToken: string;
}

const PartnerForm: FC<PartnerFormProps> = ({ onNext, onPrev, partners, companyToken }) => {
  const { mutateAsync } = useMutationUpdatePartners();

  const defaultValues = useMemo<DefaultValues<Form>>(
    () => ({
      responsible: partners.length > 0 ? partners.findIndex((p) => p.inCharge) : 0,
      partners:
        partners.length > 0 ? partners.map(partner.create) : [partner.create({ share: 100 })],
    }),
    [partners],
  );

  const [percentPartners, setPercentPartners] = useState(
    () => defaultValues.partners?.reduce((acc, p) => acc + (p?.share ?? 0), 0) ?? 0,
  );

  const {
    control,
    watch,
    getValues,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<Form>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "partners",
  });

  const onSubmit = handleSubmit(async (data) => {
    const partnersEdited = partner.edited(data.partners, data.responsible, partners);

    const newPartners = (data.partners as PartnerFields[])
      .map((p, index): NewCompanyPartner | undefined =>
        p.partnerId !== undefined ? undefined : partner.insert(p, data.responsible, index),
      )
      .filter((p) => !!p) as NewCompanyPartner[];

    await mutateAsync({
      companyToken,
      partners: [...partnersEdited, ...newPartners],
    });

    onNext();
  });

  useEffect(() => {
    const subscription = watch(() => {
      const fields = getValues("partners");

      if (!fields) return;
      const total = fields.reduce((acc, field) => acc + Number(field.share), 0);
      setPercentPartners(total);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit(e);
      }}
    >
      <Typography variant="h6" fontWeight={600} sx={{ borderBottom: 1, borderColor: "divider" }}>
        Dados dos sócios da sua empresa
      </Typography>

      <Stack mb={2}>
        {fields.map((field, index) => (
          <Box key={field.id} position="relative">
            {fields.length !== 1 && (
              <Button
                color="error"
                size="small"
                startIcon={<DeleteIcon fontSize="small" />}
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                }}
                onClick={() => remove(index)}
              >
                Excluir
              </Button>
            )}

            <PartnerItem control={control} field={field} index={index} />
          </Box>
        ))}
      </Stack>
      <Button size="small" onClick={() => append(partner.create())}>
        Adicionar Sócio
      </Button>

      <Stack
        alignItems="center"
        justifyContent="center"
        p={2}
        mt={4}
        bgcolor={grey[200]}
        sx={{
          border: 1,
          borderColor: percentPartners > 100 ? red[400] : grey[200],
        }}
      >
        <Typography>Total de participação dos sócios: {percentPartners.toFixed(2)}%</Typography>
        {percentPartners !== 100 && (
          <Typography variant="body1" color={red[400]}>
            A porcentagem de todos os sócios tem que ser igual a 100%
          </Typography>
        )}
      </Stack>

      <Stack mt={4} direction="row" alignItems="center" justifyContent="space-between">
        <Button variant="contained" startIcon={<KeyboardArrowLeftIcon />} onClick={onPrev}>
          Anterior
        </Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          type="submit"
          endIcon={<KeyboardArrowRightIcon />}
        >
          Próximo passo: Contrato
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default PartnerForm;
