import { FC, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Button,
  Stack,
  useMediaQuery,
  Box,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFieldArray, useForm } from "react-hook-form";

import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import InformationPlan from "./InformationPlan";
import ChargeFields from "./ChargeFields";
import PaymentRecurrence from "./PaymentRecurrence";

import formatCurrencyBRL from "utils/formatCurrencyBRL";
import { RequiredFields } from "../../Fields";
import { HTMLString } from "types/SemanticTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validationSchema";
import useMutationSavePlan from "../../../hooks/useMutationSavePlan";
import toast from "react-hot-toast";
import { formattedInput } from "components/CurrencyInput";
import { Descendant } from "slate";
import { EMPTY } from "components/TextEditor/utils/constants";
import TextEditor from "components/TextEditor";
import { toHTMLString } from "components/TextEditor/utils/functions";

export const periodicity = {
  Trimestral: 3,
  Semestral: 6,
  Anual: 12,
};

interface PaymentRecurrence {
  periodicity: typeof periodicity[keyof typeof periodicity];
  percentDiscount: number;
  recurrencePriceDescription: string;
}

export interface CreatePlanForm extends RequiredFields {
  name: string;
  bankSlip: boolean;
  creditCard: boolean;
  price: string;
  useLicenses: number;
  unitPrice: string;
  parcel: number;
  isSameDayExp: boolean;
  priceDescription: string;
  paymentRecurrence: PaymentRecurrence[];
  isManualBilling: boolean;
  introPlan: HTMLString;
  successPlan: HTMLString;
}

const emptyPrice = formatCurrencyBRL(0.0);

interface DialogCreatePlanProps extends DialogProps {
  companyToken: string;
}

const DialogCreatePlan: FC<DialogCreatePlanProps> = ({ onClose, companyToken, ...props }) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  const { mutateAsync, isLoading } = useMutationSavePlan();

  const [introPlan, setIntroPlan] = useState<Descendant[]>(EMPTY);
  const [successPlan, setSuccessPlan] = useState<Descendant[]>(EMPTY);

  const { handleSubmit, control, reset } = useForm<CreatePlanForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      isActive: false,
      agreement: "",
      bankSlip: false,
      creditCard: false,
      price: emptyPrice,
      useLicenses: 0,
      unitPrice: emptyPrice,
      parcel: 1,
      isSameDayExp: false,
      priceDescription: "",
      paymentRecurrence: [],
      isManualBilling: false,
    },
  });
  const fieldArray = useFieldArray({
    control,
    name: "paymentRecurrence",
  });

  const handleClose = () => {
    if (isLoading) return;
    reset();
    onClose?.({}, "backdropClick");
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      await mutateAsync({
        name: data.name,
        isActive: data.isActive,
        bankSlip: data.bankSlip,
        creditCard: data.creditCard,
        description: toHTMLString(introPlan),
        endText: toHTMLString(successPlan),
        documentToken: data.agreement,
        interval: data.parcel || 0,
        intervalType: "months",
        isAutomaticBilling: !data.isManualBilling,
        isExpirationDateCreationDay: data.isSameDayExp,
        payableWith: null,
        paymentRecurrences: data.paymentRecurrence.map((recurrence) => ({
          priceDescription: recurrence.recurrencePriceDescription,
          percentDiscount: recurrence.percentDiscount,
          periodicity: recurrence.periodicity,
        })),
        payrollValue: 0,
        planId: 0,
        price: formattedInput(data.price),
        priceDescription: data.priceDescription,
        proLaboreValue: 0,
        unitPrice: formattedInput(data.unitPrice),
        useLicenses: data.useLicenses,
        usePayroll: false,
        useProLabore: false,
        companyToken,
      });
      handleClose();
    } catch (e) {
      console.log(e);
    }
  });

  return (
    <Dialog onClose={handleClose} {...props} maxWidth="md" fullScreen={isMobile} fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Criação de plano
      </DialogTitleWithCloseButton>

      <form onSubmit={onSubmit}>
        <DialogContent sx={{ p: isMobile ? 3 : 4 }}>
          <Stack gap={4}>
            <InformationPlan control={control} />
            <ChargeFields control={control} />
            <PaymentRecurrence control={control} paymentRecurrences={fieldArray} />

            <Box>
              <InputLabel sx={{ mb: 0.5, pl: 2, fontWeight: 600 }}>Descrição</InputLabel>
              <TextEditor value={introPlan} onChange={(newValue) => setIntroPlan(newValue)} />
            </Box>
            <Box>
              <InputLabel sx={{ mb: 0.5, pl: 2, fontWeight: 600 }}>Texto de conclusão</InputLabel>
              <TextEditor value={successPlan} onChange={(newValue) => setSuccessPlan(newValue)} />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" disabled={isLoading} onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton loading={isLoading} variant="contained" type="submit">
            Salvar
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreatePlan;
