import { FC } from "react";

import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import DialogTitleWithCloseButton from "components/DialogTitleWithCloseButton";
import useMutationDeleteCategory from "../hooks/useMutationDeleteCategory";

interface DialogRemoveCategory extends Omit<DialogProps, "onClose"> {
  categoryId: number;
  companyToken: string;
  onClose: () => void;
}

const DialogRemoveCategory: FC<DialogRemoveCategory> = ({
  categoryId,
  companyToken,
  onClose,
  ...props
}) => {
  const { mutateAsync, isLoading } = useMutationDeleteCategory();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync({ categoryId, companyToken });
    handleClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onClose={handleClose}>
        Excluir subcategoria
      </DialogTitleWithCloseButton>

      <DialogContent>
        Todos os lançamentos relacionados a esta subcategoria serão excluídos. Deseja continuar?
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" disabled={isLoading}>
          Não
        </Button>
        <LoadingButton onClick={handleDelete} loading={isLoading} variant="contained">
          Sim
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogRemoveCategory;
