import * as yup from "yup";
import validateCNPJ from "utils/validateCNPJ";
import validateCPF from "utils/validateCPF";

const emptyValidation = yup
  .string()
  .nullable()
  .transform((value) => {
    if (value === undefined || value === null || value === "") return null;
    return value;
  })
  .test("onlyNumber", "Digite apenas números", (value) => {
    if (value === undefined || value === null || value === "") return true;
    return !isNaN(Number(value));
  })
  .test("isPositive", "Digite apenas números positivos", (value) => {
    if (value === undefined || value === null || value === "" || isNaN(Number(value))) return true;

    return Number(value) > 0;
  });

const validationSchema = yup.object().shape({
  cpfcnpj: yup
    .string()
    .required("Digite um cpf ou cnpj")
    .test("cpfOrCnpjIsValid", "Digite um cpf ou cnpj válido", (value) => {
      if (value === undefined || value === null || value === "") return true;

      if (value.length <= 14) {
        const cpfIsValid = validateCPF(value);
        return cpfIsValid;
      }

      const cnpjIsValid = validateCNPJ(value);
      return cnpjIsValid;
    }),

  name: yup.string().required("Digite o nome do sócio"),

  share: yup
    .number()
    .typeError("Digite apenas números")
    .required("A sua participação é obrigatória")
    .min(0.1, "O sócio deve ter alguma participação")
    .max(100, "A sua participação deve ser no máximo de 100%")
    .positive("Apenas números positivos"),

  email: yup.string().email("Digite um email válido"),

  telNumber: yup
    .string()
    .nullable()
    .test("telNumberIsValid", "Digite um telefone válido", (value) => {
      if (value === undefined || value === null || value === "") return true;

      const telNumberClean = value.replace(/\D/g, "");
      return telNumberClean.length === 10 || telNumberClean.length === 11;
    }),

  zipCode: yup.string().test("cep validation", "Digite um CEP válido", (value) => {
    if (value === undefined || value === null || value === "") return true;

    const cleanZipCode = value.replace(/\D/g, "");
    return cleanZipCode.length === 8;
  }),

  bank: yup.mixed().required("Selecione um banco do sócio"),

  agency: emptyValidation,
  account: emptyValidation,
});

export default validationSchema;
