import { useMutation, useQueryClient } from "react-query";
import queryKeys from "react-query/constants";
import { replyTicket, ReplyTicketPayload } from "services/Requests/mailbox";
import getSubdomain from "utils/getSubdomain";

const useMutationReplyTicket = () => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();

  const mutation = useMutation(
    (payload: Omit<ReplyTicketPayload, "subdomain">) => replyTicket({ ...payload, subdomain }),
    {
      onSuccess(_, { ticketId }) {
        queryClient.invalidateQueries([queryKeys.getTicketById, ticketId]);
        queryClient.invalidateQueries([queryKeys.ticket, ticketId]);
      },
    },
  );
  return mutation;
};

export default useMutationReplyTicket;
