import { UserWithCompany } from "../types/User";
import { TypesOfUsers } from "types/UserTypes";

import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ArticleIcon from "@mui/icons-material/Article";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CalculateIcon from "@mui/icons-material/Calculate";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import ListIcon from "@mui/icons-material/List";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import userTypeComplies from "./userTypeComplies";
import checkUserPermission from "./checkUserPermission";
import getSubdomain from "./getSubdomain";

/* eslint-disable @typescript-eslint/ban-types */

export type IconType = OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  muiName: string;
};

export interface SideMenuItem {
  title: string;
  Icon?: IconType;
  link?: string;
  index?: number;
  type?: TypesOfUsers[] | TypesOfUsers;
  forNewUser?: boolean;
  ignoreRootSubdomain?: boolean;
  children?: {
    title: string;
    link: string;
    index?: number;
  }[];
}

type GetSideMenuItems = (user: UserWithCompany, isAccounting: boolean) => SideMenuItem[];

const getSideMenuItems: GetSideMenuItems = (user, isAccounting) => {
  const subdomain = getSubdomain();

  let allItemsCompany: SideMenuItem[] = [
    {
      title: "Configurações",
      Icon: SettingsIcon,
      link: "/configuracoes",
      index: 1,
      type: ["Accountant", "Admin"],
      forNewUser: false,
    },
    {
      title: "Dashboard",
      Icon: DashboardIcon,
      link: "/dashboard",
      type: "User",
      index: 33,
      forNewUser: false,
    },
    {
      title: "Serviços",
      Icon: BookmarkIcon,
      link: "/servicos",
      type: ["Guest", "User"],
      forNewUser: true,
    },
    {
      title: "Meus pedidos",
      Icon: ShoppingCartIcon,
      link: "/pedidos",
      type: ["Guest", "User"],
      forNewUser: true,
      ignoreRootSubdomain: true,
    },
    {
      title: "Drive",
      Icon: CloudDownloadIcon,
      link: "/drive",
      type: ["Guest", "User"],
      forNewUser: true,
      ignoreRootSubdomain: true,
    },
    {
      title: "Clientes",
      Icon: PersonIcon,
      link: "/marketplace",
      index: 8,
      type: ["Accountant", "Admin"],
      forNewUser: false,
    },
    {
      title: "Gestão de Cobrança",
      Icon: AccountBalanceWalletIcon,
      link: "/gestao-de-cobranca",
      index: 12,
      type: ["Accountant", "Admin", "User"],
      forNewUser: false,
    },
    {
      title: "Lançamentos",
      Icon: AttachMoneyIcon,
      link: "/lancamentos",
      type: "User",
      index: 100,
      forNewUser: false,
    },
    {
      title: "Controle de Estoque",
      Icon: BarChartIcon,
      link: "/controle-de-estoque",
      type: "User",
      index: 101,
      forNewUser: false,
    },
    {
      title: "Relatórios",
      type: "User",
      index: 107,
      Icon: ListIcon,
      forNewUser: false,
      children: [
        {
          title: "Financeiros",
          link: "/relatorios/resumo-financeiro",
        },
        {
          title: "DRE Gerencial",
          link: "/relatorios/dre-gerencial",
        },
      ],
    },
    {
      title: "Drive",
      Icon: CloudDownloadIcon,
      link: "/drive",
      type: "User",
      index: 114,
      forNewUser: false,
    },
    {
      title: "Fechamento do Mês",
      Icon: CalendarMonthIcon,
      link: "/fechamento-do-mes",
      type: "User",
      index: 115,
      forNewUser: false,
    },
    {
      title: "NFS-e",
      type: "User",
      index: 116,
      Icon: ArticleIcon,
      forNewUser: false,
      children: [
        {
          title: "Notas Fiscais",
          link: "/nfs-e/notas-fiscais",
          index: 117,
        },
        {
          title: "Configurações",
          link: "/nfs-e/configurar",
          index: 118,
        },
      ],
    },
    {
      title: "Notas Fiscais",
      Icon: ArticleIcon,
      link: "/notas-fiscais",
      index: 21,
      type: ["Accountant", "Admin"],
      forNewUser: false,
    },
    {
      title: "Cadastros",
      type: "Accountant",
      index: 24,
      Icon: AppRegistrationIcon,
      forNewUser: false,
      children: [
        {
          title: "Usuários",
          link: "/cadastros-usuarios",
          index: 25,
        },
        {
          title: "Perfis",
          link: "/cadastros-perfis",
          index: 26,
        },
      ],
    },
    {
      title: "Usuários",
      Icon: GroupIcon,
      link: "/usuarios",
      type: "Admin",
      forNewUser: false,
    },
    {
      title: "Plano de Contas",
      index: 27,
      type: ["Accountant", "Admin"],
      Icon: CalculateIcon,
      forNewUser: false,
      children: [
        {
          title: "Financeiro",
          link: "/financeiro",
          index: 28,
        },
        {
          title: "DRE Gerencial",
          link: "/dre-gerencial",
        },
        {
          title: "Contábil",
          link: "/contabil",
          index: 29,
        },
        {
          title: "Layouts",
          link: "/layout-exportacao",
          index: 131,
        },
      ],
    },
    {
      title: "Solicitações",
      Icon: ChatBubbleIcon,
      link: "/solicitacoes",
      index: 31,
      type: ["Accountant", "Admin"],
      forNewUser: false,
    },

    {
      title: "Cadastros",
      type: "User",
      index: 119,
      Icon: AppRegistrationIcon,
      forNewUser: false,
      children: [
        {
          title: "Contas",
          link: "/contas",
          index: 120,
        },
        {
          title: "Clientes",
          link: "/clientes",
          index: 121,
        },
        {
          title: "Fornecedores",
          link: "/fornecedores",
          index: 122,
        },
        {
          title: "Categorias",
          link: "/categorias",
          index: 123,
        },
        {
          title: "Sócios",
          link: "/socios",
          index: 124,
        },
      ],
    },
    {
      title: "Administrativo",
      type: "User",
      index: 126,
      Icon: SettingsIcon,
      forNewUser: false,
      children: [
        {
          title: "Usuários",
          link: "/administrativo-usuarios",
          index: 127,
        },
        {
          title: "Empresas",
          link: "/administrativo-empresas",
          index: 128,
        },
        {
          title: "Perfis",
          link: "/administrativo-perfis",
          index: 129,
        },
      ],
    },
    {
      title: "Marketplace",
      Icon: ShoppingCartIcon,
      link: "/servicos",
      index: 130,
      type: "User",
      forNewUser: false,
    },
  ];

  let allItemsAccounting: SideMenuItem[] = [
    {
      title: "Usuários",
      Icon: GroupIcon,
      link: "/usuarios-contabilidade",
      type: "Admin",
    },
    {
      title: "Dashboard",
      Icon: DashboardIcon,
      link: "/dashboard",
    },
    {
      title: "Lançamentos",
      Icon: AttachMoneyIcon,
      link: "/lancamentos",
    },
    {
      title: "Controle de Estoque",
      Icon: BarChartIcon,
      link: "/controle-de-estoque",
    },
    {
      title: "Drive",
      Icon: CloudDownloadIcon,
      link: "/drive",
    },
    {
      title: "Fechamento do Mês",
      Icon: CalendarMonthIcon,
      link: "/fechamento-do-mes",
    },
    {
      title: "NFS-e",
      Icon: ArticleIcon,
      children: [
        {
          title: "Notas Fiscais",
          link: "/nfs-e",
        },
        {
          title: "Configurações",
          link: "/configuracoes-nfs-e",
        },
      ],
    },
    {
      title: "Relatórios",
      Icon: ListIcon,
      children: [
        {
          title: "Financeiros",
          link: "/financeiros",
        },
      ],
    },
    {
      title: "Cadastros",
      Icon: AppRegistrationIcon,
      children: [
        {
          title: "Contas",
          link: "/contas",
        },
        {
          title: "Clientes",
          link: "/clientes",
        },
        {
          title: "Fornecedores",
          link: "/fornecedores",
        },
        {
          title: "Categorias",
          link: "/categorias",
        },
        {
          title: "Sócios",
          link: "/socios",
        },
      ],
    },
  ];

  const filterItems = (items: SideMenuItem[]) => {
    return items
      .filter((item) => {
        if (item.type && !userTypeComplies(item.type, user.userInfo.userType)) {
          return false;
        }

        if (item.index && !checkUserPermission(user, item.index.toString())) return false;

        const isNewUser =
          !userTypeComplies(["Accountant", "Admin"], user.userInfo.userType) &&
          (user.company?.cnpj == null || subdomain == "app");

        if (!isNewUser && item?.forNewUser === true) {
          return false;
        }

        if (isNewUser && item?.forNewUser === false) {
          return false;
        }

        if (subdomain === "app" && item?.ignoreRootSubdomain === true) {
          return false;
        }

        return true;
      })
      .map((item) => {
        if (item.children && item.children.length > 0) {
          return {
            ...item,
            children: item.children.filter((child) => {
              if (child.index && !checkUserPermission(user, child.index.toString())) {
                return false;
              } else {
                return true;
              }
            }),
          };
        }

        return item;
      });
  };

  allItemsCompany = filterItems(allItemsCompany);
  allItemsAccounting = filterItems(allItemsAccounting);

  return isAccounting ? allItemsAccounting : allItemsCompany;
};

export default getSideMenuItems;
